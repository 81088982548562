import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsFileEarmarkPdfFill } from 'react-icons/bs';
import { FaTrashCan } from 'react-icons/fa6';
import AddButton from '../components/addButtons';
import UploadFileModal from './uploadFileModal';
import {
  MARKETING_SCOPE,
  PUBLIC_SCOPE,
  WEB_APP_SCOPE,
} from '../contants';
import RemoveModal from './removeModal';
import toastMessage from '../../../functions/toastMessage';
import { userInstance } from '../../../config/axios';

const DataCard = (props) => {
  const { title, handleDeleteData } = props;
  const isMouseOver = false;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '4px 8px',
      }}
    >
      <div className="d-flex gap-2 align-items-center">
        <BsFileEarmarkPdfFill size={18} />
        <span style={{ fontSize: '14px' }}>
          {title}
        </span>
      </div>
      <div className="d-flex gap-2 align-items-center">
        <button
          type="button"
          onClick={handleDeleteData}
          style={{ background: 'transparent', border: 'none' }}
        >
          {!isMouseOver && <FaTrashCan size={16} />}
        </button>
      </div>
    </div>
  );
};

DataCard.propTypes = {
  title: PropTypes.string.isRequired,
  handleDeleteData: PropTypes.func.isRequired,
};

const BlankDataCard = () => {
  const emptyContent = 'No Data';
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '4px 8px',
      }}
    >
      <span style={{ fontSize: '14px' }}>
        {emptyContent}
      </span>
    </div>
  );
};

const UploadFiles = (props) => {
  const {
    uploadedFiles,
    setUploadedFiles,
    getUploadedFiles,
  } = props;
  const [show, setShow] = useState(false);
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [selectedScope, setSelectedScope] = useState(PUBLIC_SCOPE);

  const handleRemoveModalClose = () => setRemoveModalShow(false);
  const handleRemoveModalShow = (data) => {
    setSelectedItem(data);
    setRemoveModalShow(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteItem = async (id) => {
    try {
      setIsRemoving(true);

      let resonse = await userInstance().delete(`/chatbotSettings/deleteUploadedFile?id=${id}`);
      if (resonse.status === 200) {
        let _uploadedFiles = uploadedFiles.slice();
        let findIndex = uploadedFiles.findIndex((item) => item._id === id);
        if (findIndex !== -1) {
          _uploadedFiles.splice(findIndex, 1);
          setUploadedFiles(_uploadedFiles);
          handleRemoveModalClose();
          toastMessage('success', 'Successfully deleted!');
        } else {
          toastMessage('error', 'There is no uploaded file with this id.');
        }
      }
    } catch (error) {
      console.log('error', error);
    }
    setIsRemoving(false);
  };

  // Handle change event to update the selected scope.
  const handleScopeChange = (event) => {
    setSelectedScope(event.target.value);
  };

  let filteredUploadedFiles = uploadedFiles.filter((item) => item.scope === selectedScope);

  return (
    <>
      <div className="chatbot-settings-form-sub-container p-4">
        <div>
          <span className="chatbot-settings-form-sub-title">Upload Files</span>
        </div>
        <div className="d-flex gap-3 mt-3">
          <div className="d-flex align-self-center gap-2">
            <input
              type="radio"
              name="chatbot-settings-file-options-radio-btn"
              value={PUBLIC_SCOPE}
              checked={selectedScope === PUBLIC_SCOPE}
              onChange={handleScopeChange}
            />
            <span className="chatbot-settings-form-label">Public</span>
          </div>
          <div className="d-flex align-self-center gap-2">
            <input
              type="radio"
              name="chatbot-settings-file-options-radio-btn"
              value={MARKETING_SCOPE}
              checked={selectedScope === MARKETING_SCOPE}
              onChange={handleScopeChange}
            />
            <span className="chatbot-settings-form-label">Marketing</span>
          </div>
          <div className="d-flex align-self-center gap-2">
            <input
              type="radio"
              name="chatbot-settings-file-options-radio-btn"
              value={WEB_APP_SCOPE}
              checked={selectedScope === WEB_APP_SCOPE}
              onChange={handleScopeChange}
            />
            <span className="chatbot-settings-form-label">Web App</span>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div>
            <span>File List</span>
          </div>
          <AddButton handleClickBtn={handleShow} />
        </div>
        <div
          className="mt-2"
          style={{
            height: '412px',
            overflowX: 'hidden',
            overflowY: filteredUploadedFiles.length > 0 ? 'scroll' : 'hidden',
            paddingRight: filteredUploadedFiles.length > 0 ? '8px' : 0,
          }}
        >
          <div className="d-flex flex-column gap-2">
            {
              filteredUploadedFiles.length > 0 ? filteredUploadedFiles.map((item) => (
                <DataCard
                  key={item._id}
                  title={item.title}
                  handleDeleteData={() => handleRemoveModalShow(item)}
                />
              )) : (
                <BlankDataCard />
              )
            }
          </div>
        </div>
      </div>
      <UploadFileModal show={show} handleClose={handleClose} setUploadedFiles={setUploadedFiles} getUploadedFiles={getUploadedFiles} scope={selectedScope} />
      <RemoveModal show={removeModalShow} handleClose={handleRemoveModalClose} title="Are you sure to remove this file?" icon={<BsFileEarmarkPdfFill size={18} />} selectedItem={selectedItem} handleDeleteItem={handleDeleteItem} isRemoving={isRemoving} />
    </>
  );
};

UploadFiles.propTypes = {
  uploadedFiles: PropTypes.arrayOf.isRequired,
  setUploadedFiles: PropTypes.func.isRequired,
  getUploadedFiles: PropTypes.func.isRequired,
};

export default UploadFiles;
