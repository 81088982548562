import React, { useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DeleteWaterTestPopup = ({ setWaterTestPopup, handleWaterTestPopuncheck }) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal chemical-popup') {
            setWaterTestPopup(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="duration-picker time-picker-modal chemical-popup"

    >
      <div ref={wrapperRef}>
        <div className="time-keeper-popup duration-popup">
          <h6>
            Remove Water Test
          </h6>

          <div className="event-popup-btn footer-btn view-btn">
            <Button
              className="appointment-btn btn-edit"
              type="button"
              onClick={() => { setWaterTestPopup(false); }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="appointment-btn btn-save"
              onClick={() => handleWaterTestPopuncheck()}
            >
              Confirm
            </Button>
          </div>

          {/* <div className="time-keeper-btn">
              <Button className="appointment-btn btn-edit" onClick={() => setWaterTestPopup(false)}>Cancel</Button>
              <Button onClick={() => handleWaterTestPopuncheck()}>Yes</Button>
            </div> */}
        </div>
      </div>
    </div>

  );
};
DeleteWaterTestPopup.propTypes = {
  setWaterTestPopup: PropTypes.isRequired,
  handleWaterTestPopuncheck: PropTypes.isRequired,
};
export default DeleteWaterTestPopup;
