import React, { useEffect, useState } from 'react';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import {
  DATA_MANAGEMENT_TAB,
  GENERAL_TAB,
  DefaultGeneralSettings,
} from './contants';
import { userInstance } from '../../config/axios';
import GeneralSettings from './general';
import DataManagement from './dataManagement';
import './index.css';

const ChatbotSettings = () => {
  const [settingsTab, setSettingsTab] = useState(GENERAL_TAB);
  const [generalData, setGeneralData] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedTexts, setUploadedTexts] = useState([]);
  const [advertisements, setAdvertisements] = useState([]);

  const getGeneralData = async () => {
    try {
      let response = await userInstance().get('chatbotSettings/getGeneralSettings');
      if (response.status === 200) {
        let _generalData = response.data.generalSettings;
        if (_generalData?.length > 0) {
          setGeneralData(_generalData[0]);
        } else {
          setGeneralData(DefaultGeneralSettings);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getUploadedFiles = async () => {
    try {
      let response = await userInstance().get('/chatbotSettings/getUploadedFiles');
      if (response.status === 200) {
        let _uploadedFiles = response.data.uploadedFiles;
        if (_uploadedFiles) {
          setUploadedFiles(_uploadedFiles);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getUploadedTexts = async () => {
    try {
      let response = await userInstance().get('/chatbotSettings/getUploadedTexts');
      if (response.status === 200) {
        let _uploadedTexts = response.data.uploadedTexts;
        if (_uploadedTexts) {
          setUploadedTexts(_uploadedTexts);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getAdvertiesments = async () => {
    try {
      let response = await userInstance().get('/chatbotSettings/getAdvertisements');
      if (response.status === 200) {
        let _advertisements = response.data.advertisements;
        if (_advertisements) {
          setAdvertisements(_advertisements);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getGeneralData();
    getUploadedFiles();
    getUploadedTexts();
    getAdvertiesments();
  }, []);

  return (
    <AdminWrapper>
      <div className="staff-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-titles-mob" style={{ display: 'flex', justifyContent: 'center' }}>
                <h3>Chatbot Settings</h3>
              </div>
            </div>
          </div>
          <div className="client-main-contact">
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-1">
                <button type="button" className={settingsTab === GENERAL_TAB ? 'chatbot-settings-mode-button-selected' : 'chatbot-settings-mode-button'} onClick={() => setSettingsTab(GENERAL_TAB)}>General</button>
                <button type="button" className={settingsTab === DATA_MANAGEMENT_TAB ? 'chatbot-settings-mode-button-selected' : 'chatbot-settings-mode-button'} onClick={() => setSettingsTab(DATA_MANAGEMENT_TAB)}>Data Management</button>
              </div>
            </div>
            <div><span className="chatbot-settings-divider" /></div>
            {
              settingsTab === GENERAL_TAB ? (
                <GeneralSettings
                  generalData={generalData}
                  setGeneralData={setGeneralData}
                  getGenerateData={getGeneralData}
                />
              ) : (
                <DataManagement
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  getUploadedFiles={getUploadedFiles}
                  uploadedTexts={uploadedTexts}
                  setUploadedTexts={setUploadedTexts}
                  getUploadedTexts={getUploadedTexts}
                  advertisements={advertisements}
                  setAdvertisements={setAdvertisements}
                  getAdvertiesments={getAdvertiesments}
                />
              )
            }
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default ChatbotSettings;
