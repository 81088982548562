import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import DateModal from '../appointment/dateModal';

const TestStripConfirm = ({
  setTestValueStatus, testValueStatus, handleTestStripValueChange, testConfirmValue, handleConfirmTestStrip,
}) => {
  console.log('hdhfhfgdhdj');
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            setTestValueStatus(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="duration-picker time-picker-modal update-source-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup" ref={wrapperRef}>
          <div className="appointment-view">
            <div className="pending-water-test" onClick={() => handleTestStripValueChange('Don’t Test Salt Levels')}>
              {/* <h3>
                Chemicals Added as Calculated
              </h3> */}
              <h3>Don&apos;t Test Salt Levels</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="Don’t Test Salt Levels"
                    checked={
                      testConfirmValue === 'Don’t Test Salt Levels' ? true : ''
                        }
                    onChange={(e) => handleTestStripValueChange(e.target.name)}
                  />
                </div>
              </div>
            </div>
            <div className="pending-water-test" onClick={() => handleTestStripValueChange('Check Salt Readings')}>
              <h3>Check Salt Readings</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="Check Salt Readings"
                    checked={
                      testConfirmValue === 'Check Salt Readings' ? true : ''
                        }
                    onChange={(e) => handleTestStripValueChange(e.target.name)}
                  />
                </div>
              </div>
            </div>

            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={() => { setTestValueStatus(!testValueStatus); }}>
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={() => handleConfirmTestStrip()}>
                Select
              </Button>
            </div>

          </div>

        </div>
      </div>
    </div>

  );
};
TestStripConfirm.propTypes = {
  setTestValueStatus: PropTypes.func.isRequired,
  handleTestStripValueChange: PropTypes.func.isRequired,
  testConfirmValue: PropTypes.string.isRequired,
  testValueStatus: PropTypes.bool.isRequired,
  handleConfirmTestStrip: PropTypes.func.isRequired,
};
export default TestStripConfirm;
