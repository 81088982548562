import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
// import DateModal from '../appointment/dateModal';

const LocationChangePopup = ({
  setLocationPopUp, locationPopUp, handleLocationPopUpChange, handleLocationSelect, staffLocation, locationName, handleLocationPopUp,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal update-source-popup') {
            setLocationPopUp(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  // console.log('staffLocation', { staffLocation });
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="duration-picker time-picker-modal update-source-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup location-change-popup" ref={wrapperRef}>
          <div className="appointment-view">
            {
               staffLocation.map((el) => (
                 <div className="pending-water-test" onClick={() => handleLocationPopUpChange(el._id)}>
                   <h3>
                     {el.locationName}
                   </h3>
                   <div className="open-date client-notes">
                     <div className="basic">
                       <Form.Check
                         type="checkbox"
                         name={el._id}
                         checked={
                          locationName === el._id ? true : ''
                        }
                       />
                     </div>
                   </div>
                 </div>
               ))
}
            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={() => { setLocationPopUp(false); }}>
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={() => handleLocationSelect('select')}>
                Select
              </Button>
            </div>

          </div>

        </div>
      </div>
      {/* <DateModal blockedtime={blockedtime} setBlockedtime={setBlockedtime} /> */}
    </div>

  );
};
LocationChangePopup.propTypes = {
  locationPopUp: PropTypes.bool.isRequired,
  setLocationPopUp: PropTypes.func.isRequired,
  handleLocationPopUpChange: PropTypes.func.isRequired,
  handleLocationSelect: PropTypes.func.isRequired,
  staffLocation: PropTypes.arrayOf.isRequired,
  locationName: PropTypes.string.isRequired,
  handleLocationPopUp: PropTypes.func.isRequired,
};
export default LocationChangePopup;
