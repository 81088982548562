import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { Link, useHistory } from 'react-router-dom';
import moment from '../../functions/moment';
import arrow from '../../assets/home/arrowdown.png';
import upsort from '../../assets/home/up-sort.png';
import { userInstance } from '../../config/axios';
import downsort from '../../assets/home/down-sort.png';
import {
  addZero,
  showErrMsg,
  averageGstCalculation,
  queryString,
  gstChemicalsCalculations,
} from '../../functions/index';
import sort from '../../assets/home/sort.png';
import showmore from '../../assets/home/showmore.png';
import SearchModal from '../appointment/searchmodal';
import DateModal from '../appointment/dateModal';
import TimePicker from '../appointment/timepicker';
import ServiceModal from '../appointment/servicemodal';
import DurationPicker from '../appointment/durationPicker';
import HourPicker from '../appointment/hourPicker';
import search from '../../assets/admin/search-icon.png';
import edit from '../../assets/home/grey-edit.png';
import clientimg from '../../assets/home/client.png';
import greydelete from '../../assets/home/grey-delete.png';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import DeletePopUp from '../DeletePopUp/index';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import useLocalStorage from '../../functions/hooks/useLocalStorage';

const EditInvoice = ({

  invoiceData,
  setEditInvoice,
  viewtype,
  customerAllDue,
  searchType,
  frompage,
}) => {
  const [chemicalsToDisplay] = useLocalStorage('chemicalsToDisplayInvoice', false, false, true);
  const { from, clientid } = queryString();
  const { clientAllData, setClientAllData, userData: { industryId } } = useContext(AuthDataContext);
  const history = useHistory();
  const {
    invoiceNo,
    createdAt,
    appointment,
    invoiceTotal,
    balanceDue,
    amountApplied,
    customer,
    gst,
    invoiceType,
    chemicalsAdded,
    invoiceSubTotal,
    paymentId,
  } = invoiceData;
  let invoiceEditSubTotal = parseFloat(invoiceSubTotal);
  const [addedClients, setAddedClients] = useState([customer]);
  const [addedServices, setAddedServices] = useState(
    appointment ? appointment[0].dateData[0].service : [],
  );
  const [renderSort, setRenderSort] = useState(false);
  const [invoiceNotes, setInvoiceNotes] = useState('');
  const timeFromDateFormat = 'HH:mm';
  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };
  const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  let setDefaultTime = '9:30';
  const initialBookingData = {
    frequency: "One-off appointment | Doesn't repeat",
    repeatEnd: 'Ongoing',
    category: '',
    subcategory: '',
    startTime: setDefaultTime,
    duration: '5',
    staffId: '',
    staffName: '',
    clientId: '',
    cost: '',
    start: '',
    end: '',
    customCost: 'default',
    type: '',
    buyCostProduct: '',
    laborMargin: '',
    quantity: '1',
    timeChange: false,
    customMisc: '',
    previousDuration: '',
    previousQuantity: '',
    pricingType: 'fixedCost',
    previousCost: '',
    prevBuyCostProduct: '',
    renderCost: '',
    soldPer: '',
    unit: '',
    symbol: '',
    description: '',
    tax: '',
    s_uId: '',
    categoryId: '',
    supplier: '',
  };
  const otherBookingInitial = {
    category: '',
    subcategory: '',
    duration: '5',
    cost: '',
    start: '',
    end: '',
    customCost: 'default',
    type: '',
    buyCostProduct: '',
    laborMargin: '',
    quantity: '1',
    customMisc: '',
    previousDuration: '',
    previousQuantity: '',
    pricingType: 'fixedCost',
    soldPer: '',
    unit: '',
    symbol: '',
    tax: '',
    s_uId: '',
    categoryId: '',
    supplier: '',
  };

  const initialDatePopup = { interval_type: 'weekly', interval_multiplier: '4' };
  const query = window.location.href.substring(1);
  let startDateTime = query.split('?startDateTime=');
  const initialdateBookingData = {
    date: moment(startDateTime[1]).format(dateFormat),
    createdOn: new Date(),
    interval_multiplier: null,
    occurence: null,
    interval_type: null,
    endsAt: null,
    endType: null,
    msg: "One-off appointment | Doesn't repeat",
    frequencyMsg: '',
    occurenceMsg: '',
    specificDate: moment().format(dateFormat),
    requiredday: null,
    appointmenttype: 'oneteam_oneday',
    service: [],
  };
  const multiNotesState = [{ notes: null }];
  const [searchedClients, setClients] = useState(null);
  const [searchedClients2, setClients2] = useState(null);
  const [activeAdd, setActiveAdd] = useState(0);
  const [totalData, setTotalData] = useState(
    appointment ? appointment[0].totalData : {},
  );
  const [appointmentIndex, setappointmentIndex] = useState('');
  const [staffIndex, setStaffIndex] = useState();
  const [newLength, setnewlength] = useState();
  const [showService, setShowService] = useState(false);
  const [bookingData, setBookingData] = useState(initialBookingData);
  const [subcategoryTxt, setSearchTxt] = useState('');
  const [subcategories, setSubcategories] = useState(null);
  const [misc, setMisc] = useState(false);
  const [multiNotes, setMultiNotes] = useState(multiNotesState);
  const [showSearch, setShowSearch] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [categories, setCategories] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [editData, setEditData] = useState({
    edit: false,
    index: '',
    type: 'mark',
  });
  const [hourPicker, setHourPicker] = useState(false);
  const [hourData, setHourData] = useState('');
  const [dateBookingData, setdateBookingData] = useState(initialdateBookingData);
  const [extras, setExtras] = useState({ value: '', msg: '' });
  const [timepickerShow, setTimePickerShow] = useState(false);
  const [clientTxt, setClientTxt] = useState('');
  const [durationPicker, setDurationPicker] = useState(false);
  const [timeData, setTime] = useState('9:30');
  const [durationData, setDuration] = useState('');
  const [repeatAppt, setRepeatAppt] = useState(false);
  const [repeatFrequency, setRepeatFrequency] = useState('');
  const [datePopupData, setDatePopupData] = useState(initialDatePopup);
  const timepickerRef = useRef(null);
  const [appointmentType, setAppointmentType] = useState('oneteam_oneday');
  const [multiAppointment, setmultiAppointment] = useState(false);
  const [newAppointment, setNewAppointment] = useState([initialdateBookingData]);
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [popUpText, setPopUpText] = useState();
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [oldData, setOldData] = useState([]);
  const paraText = ' Are you sure to void this invoice?';
  const [errorValue, setErrorValue] = useState('');

  const handleSaveDate = () => {
    updatedateBookingData();
    handleFrequency();
  };

  // This is custom app array for the gst function.
  const customAppointmentArr = [{ service: addedServices }];

  const updatedateBookingData = (isEndType) => {
    let { interval_type, interval_multiplier } = datePopupData;
    const originalIntervalType = interval_type;
    let frequencyMsg = '';
    if (parseInt(interval_multiplier, 10) > 1) {
      switch (interval_type) {
        case 'monthly':
          interval_type = 'months';
          break;
        case 'weekly':
          interval_type = 'weeks';
          break;
        case 'daily':
          interval_type = 'days';
          break;

        default:
          break;
      }
    }
    frequencyMsg = parseInt(interval_multiplier, 10) > 1
      ? `Repeats every ${interval_multiplier} ${interval_type}`
      : interval_type;
    const updateObj = {
      ...dateBookingData,
      interval_multiplier,
      interval_type: originalIntervalType,
      frequencyMsg,
    };
    if (isEndType) {
      updateObj.endType = 'ongoing';
    }
    setdateBookingData(updateObj);
  };

  const handleFrequency = () => {
    setRepeatFrequency(!repeatFrequency);
    if (!repeatFrequency) {
      setTimeout(() => {
        console.log('dd', timepickerRef);
        timepickerRef.current.value = `${datePopupData.interval_multiplier}:00`;
      }, 350);
    }
  };
  const checkAddress = (index, id) => {
    setActiveAdd(index);
    let oldState = [...searchedClients];
    oldState.forEach((el) => {
      if (el._id === id) {
        el.siteAddresses.forEach((el2, i) => {
          if (index === i) {
            el2.active = true;
          } else {
            el2.active = false;
          }
        });
      } else {
        el.siteAddresses.forEach((el2, i) => {
          el2.active = false;
        });
      }
    });
    setClients(oldState);
  };
  const handleAddClient = (client) => {
    if (client.siteAddresses[activeAdd] || client.siteAddresses.length === 0) {
      let { active } = client.siteAddresses[activeAdd]
        ? client.siteAddresses[activeAdd]
        : {};
      if (active || client.siteAddresses.length === 0) {
        setAddedClients([client]);
        handleShowSearch();
        setClientTxt('');
      } else {
        NotificationManager.error(
          'Pleass select site address',
          'Message',
          errorMsgTime,
        );
      }
    } else {
      NotificationManager.error(
        'Pleass select site address',
        'Message',
        errorMsgTime,
      );
    }
  };
  const moveArr = (oldIndex, newIndex, sign) => {
    const servicesCopy = [...addedServices];
    const { duration, start, startTime } = addedServices[newIndex];
    let newNextStartTime;
    let currentNewStartTime;
    if (sign === 'plus') {
      const currentEntryStart = new Date(servicesCopy[oldIndex].start);
      const updatedNextEntryStart = moment(
        new Date(
          currentEntryStart.setMinutes(
            currentEntryStart.getMinutes() + parseFloat(duration),
          ),
        ),
      ).format(dateFormat);
      newNextStartTime = moment(updatedNextEntryStart).format(
        timeFromDateFormat,
      );
      currentNewStartTime = servicesCopy[oldIndex].startTime;
    } else if (sign === 'minus') {
      newNextStartTime = startTime;
      const updatedCurrentEntryStart = moment(
        new Date(
          new Date(start).setMinutes(
            new Date(start).getMinutes()
              + parseFloat(servicesCopy[oldIndex].duration),
          ),
        ),
      ).format(dateFormat);
      currentNewStartTime = moment(updatedCurrentEntryStart).format(
        timeFromDateFormat,
      );
    }
    const updatedOldIndex = {
      ...addedServices[oldIndex],
      startTime: newNextStartTime,
    };
    const updatedNewIndex = {
      ...addedServices[newIndex],
      startTime: currentNewStartTime,
    };
    // console.log('updatedOldIndex', updatedOldIndex, 'updatedNewIndex', updatedNewIndex);
    servicesCopy[oldIndex] = { ...updatedOldIndex };
    servicesCopy[newIndex] = { ...updatedNewIndex };

    if (newIndex >= servicesCopy.length) {
      let k = newIndex - servicesCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        servicesCopy.push(undefined);
      }
    }
    servicesCopy.splice(newIndex, 0, servicesCopy.splice(oldIndex, 1)[0]);
    // console.log('servicesCopy', servicesCopy);
    calculateDurations(servicesCopy);
    // setServices([...servicesCopy]);
  };

  const calculateDurations = (info) => {
    info.forEach((el) => {
      let start = moment(el.date).toDate();
      const time = el.startTime.split(':');
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);

      el.start = moment(start).format(dateFormat);
      el.end = moment(
        new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
      ).format(dateFormat);
    });
    // console.log('services', info);
    setAddedServices([...info]);
  };

  const handleShowService = (index) => {
    setappointmentIndex(index);
    setStaffIndex(0);
    setnewlength(addedServices.length);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setShowService(!showService);
    setEditData({ edit: false, index: '', type: 'mark' });
    const lastService = addedServices[addedServices.length - 1];
    if (lastService) {
      console.log('lastService staffId  staffName', lastService);
      let copyOldbookingData = { ...bookingData };
      const startTime = moment(lastService.end).format(timeFromDateFormat);
      copyOldbookingData.startTime = startTime;
      copyOldbookingData.staffId = lastService.staffId;
      copyOldbookingData.staffName = lastService.staffName;
      setBookingData(copyOldbookingData);
    } else {
      setBookingData({ ...bookingData, ...otherBookingInitial });
    }
    setSearchTxt('');
    setSubcategories(null);
    setMisc(false);
  };

  const getTimes = () => g();

  const g = () => {
    const x = {
      slotInterval: 5,
      openTime: '12:25 AM',
      closeTime: '23:30 PM',
    };
    const startTime = moment(x.openTime, 'h:mm A');
    const endTime = moment(x.closeTime, 'h:mm A');
    const allTimes = [];
    while (startTime <= endTime) {
      allTimes.push(startTime.format('h:mm A'));
      startTime.add(x.slotInterval, 'minutes');
    }
    return allTimes;
  };

  const handleDateModal = (handeltype, index) => {
    const {
      date,
      occurence,
      interval_multiplier,
      interval_type,
      endType,
      occurenceMsg,
      specificDate,
      requiredday,
      createdOn,
      endsAt,
    } = dateBookingData;
    if (index || index === 0) {
      console.log('check Date', dateBookingData);
      setappointmentIndex(index);
      let oldStateData = [...newAppointment];
      console.log('newAppointment.........', dateBookingData);
      let startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0);
      let endDate = new Date(endsAt || startDate);
      endDate.setHours(0, 0, 0, 0);
      console.log('startDate', startDate);
      if (
        index === 0
        && handeltype === 'add'
        && (appointmentType === 'oneteam_multiday'
          || appointmentType === 'multiteam_multiday')
      ) {
        // eslint-disable-next-line no-undef
        let nostep = requiredday || 2;
        for (let step = 0; step < nostep; step++) {
          startDate.setDate(startDate.getDate() + step);
          let dateBookingDataCopy = {
            ...dateBookingData,
            date: moment(startDate).format(dateFormat),
            createdOn: moment(createdOn).format(dateFormat),
            endsAt: moment(endDate).format(dateFormat),
          };
          oldStateData[step] = dateBookingDataCopy;
        }
        // console.log('data_arraydata_array', oldStateData);
      } else if (
        handeltype === 'add'
        && (appointmentType === 'oneteam_multiday'
          || appointmentType === 'multiteam_multiday')
      ) {
        let count = 0;
        for (let step = index; step < newAppointment.length; step++) {
          startDate.setDate(startDate.getDate() + count);
          count += 1;
          let dateBookingDataCopy = {
            ...dateBookingData,
            date: moment(startDate).format(dateFormat),
            createdOn: moment(createdOn).format(dateFormat),
            endsAt: moment(endDate).format(dateFormat),
          };
          oldStateData[step] = dateBookingDataCopy;
        }
      } else {
        let dateBookingDataCopy = {
          ...dateBookingData,
          date: moment(startDate).format(dateFormat),
          createdOn: moment(createdOn).format(dateFormat),
          endsAt: moment(endDate).format(dateFormat),
        };
        oldStateData[index] = dateBookingDataCopy;
      }
      oldStateData[index].service = newAppointment[index].service;
      setNewAppointment(oldStateData);
    }
    if (
      handeltype === 'add'
      && (appointmentType === 'oneteam_multiday'
        || appointmentType === 'multiteam_multiday')
    ) {
      setmultiAppointment(true);
      setdateBookingData({
        ...dateBookingData,
        requiredday: requiredday || 2,
        msg:
          appointmentType === 'multiteam_multiday'
            ? `Additional Team Members Multi Visit | ${
              requiredday || 2
            } days | Doesn't repeat`
            : `One Team Member Multi Visit | ${
              requiredday || 2
            } days | Doesn't repeat`,
      });
      let nostep = requiredday || 2;
      for (let step = 0; step < nostep; step++) {
        setMultiNotes([...multiNotes, { notes: null }]);
      }
    }
    let msgIntervalType = 'days';
    if (interval_multiplier) {
      let newDate = new Date(
        occurenceMsg === 'On a Specific Date' ? specificDate : date,
      );
      // console.log('new date', newDate);
      newDate.setHours(12, 0, 0, 0);
      let interval = interval_multiplier;
      if (interval_type === 'weekly') {
        interval *= 7;
        msgIntervalType = 'weeks';
      } else if (interval_type === 'monthly') {
        // newDate.setDate(newDate.getDate() + daysToAdd);
        newDate = new Date(
          moment(newDate, 'DD-MM-YYYY')
            .add(occurence * parseInt(interval, 10), 'months')
            .format('L'),
        );
        // console.log('new date after month', newDate);
        msgIntervalType = 'months';
      }
      if (endType === 'ongoing') {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() - 1);
      } else if (
        occurenceMsg !== 'On a Specific Date'
        && interval_type !== 'monthly'
      ) {
        const daysToAdd = occurence * interval;
        // console.log('daysToAdd', daysToAdd);
        newDate.setDate(newDate.getDate() + daysToAdd);
        // console.log('date', newDate, 'old date', date);
      }
      const msg = endType === 'ongoing'
        ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ongoing`
        : occurenceMsg !== 'On a Specific Date'
          ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends after ${occurence} times on ${moment(
            newDate,
          ).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`
          : `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends on ${moment(
            newDate,
          ).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`;
      setdateBookingData({ ...dateBookingData, msg, endsAt: newDate });
    }
    // console.log('fvmdjm', { ...dateBookingData });
    setShowDate(!showDate);
    // console.log('handeltypehandeltype', handeltype, appointmentType);
  };

  const setStaff = (staffName, staffId) => {
    const servicesListCopy = addedServices;
    servicesListCopy.forEach((el) => {
      el.staffId = staffId;
      el.staffName = staffName;
    });
    setAddedServices([...servicesListCopy]);
  };

  const getStaffList = async () => {
    try {
      const response = await userInstance().get('/staff/getStaff');
      const { staff } = response.data;
      setStaffList(staff);
      return {
        staffName: `${staff[0].firstname} ${staff[0].lastname}`,
        staffId: staff[0]._id,
      };
    } catch (error) {
      //   console.log(error);
      return null;
    }
  };

  const getCategories = async () => {
    try {
      const response = await userInstance().get('/service/getServices');
      const { services } = response.data;
      setCategories(services);
      const staffData = await getStaffList();
      const objToUpdate = {
        ...bookingData,
      };
      if (staffData) {
        objToUpdate.staffName = staffData.staffName;
        objToUpdate.staffId = staffData.staffId;
      }
      setBookingData(objToUpdate);
    } catch (error) {
      //   console.log(error);
    }
  };

  const handleBookingData = (e) => {
    const { name, value } = e.target;
    const obj = { ...bookingData, [name]: value };
    if (name === 'staffName') {
      const staffId = staffList[e.target.selectedIndex]._id;
      obj[name] = value;
      obj[staffId] = staffId;
      if (
        appointmentType === 'oneteam_oneday'
        || appointmentType === 'oneteam_multiday'
      ) {
        setStaff(value, staffId);
      }
    }
    if (name === 'cost' && !bookingData.customCost) {
      const newval = addZero(value);
      obj[name] = newval;
    } else if (name === 'startTime') {
      obj.timeChange = true;
    } else if (name === 'duration') {
      obj.durationChange = true;
    } else if (name === 'quantity') {
      obj.quantityChange = true;
    } else if (name === 'pricingType') {
      obj.duration = bookingData.previousDuration;
      obj.quantity = '5';
      if (bookingData.customCost !== 'default') {
        obj.cost = '';
      }
    } else if (name === 'soldPer' && value !== 'Add Custom Measure') {
      obj.unit = '';
      obj.symbol = '';
    }
    // console.log('objobj', obj);
    setBookingData(obj);
  };

  const handleChangeDate = (type, value) => {
    setDatePopupData({ ...datePopupData, [type]: value });
  };

  const handleRepeat = () => {
    setRepeatAppt(!repeatAppt);
    if (repeatAppt) {
      const initialCopy = { ...initialdateBookingData };
      delete initialCopy.date;
      setdateBookingData({ ...initialCopy, date: dateBookingData.date });
      setDatePopupData({ ...initialDatePopup });
    } else {
      updatedateBookingData(true);
    }
  };
  const handleTimePicker = () => {
    setTime(bookingData.startTime);
    setTimePickerShow(!timepickerShow);
  };

  const handleDurationPicker = () => {
    setDuration(bookingData.duration);
    setDurationPicker(!durationPicker);
  };
  const handleHourPicker = () => {
    setHourData(bookingData.quantity);
    setHourPicker(!hourPicker);
  };

  const handleChangeDateData = (e) => {
    console.log('ee', e.target.value);
    // const { name } = e.target;
    // const { value } = e.target;
    // const obj = { ...dateBookingData };
    // if (value === 'Repeats every 4 weeks') {
    //   handleFrequency();
    // }
    // if (
    //   name === 'interval_multiplier'
    //   && (value === 'daily'
    //     || value === 'weekly'
    //     || value.includes('weeks')
    //     || value.includes('days')
    //     || value.includes('One-off'))
    // ) {
    //   // if (value === 'On a Specific Date') {

    //   // }
    //   // console.log('here');
    //   const val = value.includes('weeks') || value.includes('days') ? parseInt(value.replace(/[^0-9\\.]/g, ''), 10) : 1;
    //   // console.log('val', val);
    //   obj.interval_multiplier = val;
    //   obj.interval_type = value.includes('weeks') || value === 'weekly' ? 'weekly' : 'daily';
    //   obj.frequencyMsg = value;
    //   if (!obj.occurence) {
    //     obj.occurence = null;
    //   }
    //   obj.endType = !obj.endType ? 'ongoing' : 'by_occurence';
    //   if (value === "One-off appointment | Doesn't repeat") {
    //     // console.log('11');
    //     obj.occurence = null;
    //     obj.frequencyMsg = null;
    //     obj.interval_type = null;
    //     obj.msg = value;
    //     obj.interval_multiplier = null;
    //     obj.endType = null;
    //     obj.frequencyMsg = '';
    //     obj.occurenceMsg = '';
    //     // obj = { ...initialdateBookingData, date: dateBookingData.bookingData, msg: value };
    //     // console.log('obj value', obj);
    //   } else {
    //     obj.occurenceMsg = !obj.occurenceMsg ? 'Ongoing' : obj.occurenceMsg;
    //   }
    // } else if (name === 'occurence') {
    //   // console.log('value', value);
    //   if (value === 'Ongoing') {
    //     obj.occurence = null;
    //     obj.endType = value.toLowerCase();
    //     obj.occurenceMsg = value;
    //   } else {
    //     obj.occurenceMsg = value;
    //     obj[name] = parseInt(value.replace(/[^0-9\\.]/g, ''), 10);
    //     obj.endType = 'by_occurence';
    //     // console.log('obj', obj);
    //   }
    // } else {
    //   obj[name] = value;
    // }
    // // console.log('obj', obj);
    // setdateBookingData(obj);
  };

  const handleChangeCustom = (value) => {
    const obj = { ...bookingData, customCost: value };
    if (value === 'default') {
      obj.cost = bookingData.previousCost;
      if (bookingData.type === 'product') {
        obj.buyCostProduct = bookingData.prevBuyCostProduct;
      }
    } else if (value === 'yes' || value === 'sellPrice') {
      obj.cost = '';
      obj.buyCostProduct = obj.prevBuyCostProduct;
    } else if (value === 'buyCost') {
      obj.buyCostProduct = '';
      obj.cost = obj.previousCost;
    } else if (value === 'buySell') {
      obj.cost = '';
      obj.buyCostProduct = '';
    }
    setBookingData(obj);
  };

  const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad(`0${str}`, max) : str;
  };

  const convertHours = (mins) => {
    const hour = Math.floor(mins / 60);
    var mins = mins % 60;
    const converted = `${pad(hour, 2)}:${pad(mins, 2)}`;
    return converted;
  };

  const calculate_time_slot = (start_time, end_time, interval = '30') => {
    var i;
    let formatted_time;
    const time_slots = new Array();
    for (var i = start_time; i <= end_time; i += interval) {
      formatted_time = convertHours(i);
      time_slots.push(formatted_time);
    }
    return time_slots;
  };

  const getHourWithQuantity = (duration, multiplier) => {
    // console.log('multiplier', multiplier);
    let hourPart = duration.includes('hr')
      ? parseFloat(duration.split('hr')[0].trim())
      : 0;
    let minutePart = duration.includes('hr') && duration.includes('min')
      ? parseFloat(duration.split('hr')[1].split('min')[0])
      : duration.includes('min')
        ? parseFloat(duration.split('min')[0])
        : 0;

    let hourWithQuantity = hourPart * multiplier;
    // console.log('hourWithQuantity', hourWithQuantity);
    return { hourWithQuantity, hourPart, minutePart };
  };

  const calculateServiceLabour = (bookingDataCopy) => {
    const convertedDuration = bookingDataCopy.pricingType === 'hourly'
      ? bookingDataCopy.quantity
      : bookingDataCopy.duration;

    let hour = convertToReadableDuration(
      addQuantityDuration(convertedDuration, parseFloat('1')),
    );
    let { hourWithQuantity, minutePart } = getHourWithQuantity(
      hour,
      bookingDataCopy.cost,
    );

    minutePart = (minutePart / 60) * parseFloat(bookingDataCopy.cost);
    let finalDuration = parseFloat(hourWithQuantity + minutePart);

    // eslint-disable-next-line no-mixed-operators
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return parseFloat(finalDuration).toFixed(2);
  };

  const addQuantityDuration = (duration, quantity) => {
    const updatedDuration = convertToReadableDuration(duration.toString());
    // console.log('updatedDuration', updatedDuration);
    let { hourWithQuantity, hourPart, minutePart } = getHourWithQuantity(
      updatedDuration,
      quantity,
    );

    let multipleMinute = parseFloat(
      `0.${hourWithQuantity.toString().split('.')[1]}`,
    );

    const updatedMinute = hourPart ? multipleMinute * (60 / hourPart) : 0;
    minutePart *= quantity;

    let finalDuration = parseFloat(hourWithQuantity.toString().split('.')[0]) * 60
      + updatedMinute
      + minutePart;
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return finalDuration.toString();
  };

  const calculateMarginLabour = (bookingDataCopy) => {
    const {
      cost, buyCostProduct, quantity, type, renderCost,
    } = bookingDataCopy;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    let totalLabour = 0;

    if (type === 'product') {
      totalBuy += parseFloat(buyCostProduct);
      totalSellPrice += parseFloat(renderCost);
      const totalLabourProduct = (
        totalSellPrice
        - totalBuy * parseFloat(quantity)
      ).toFixed(2);
      laborMargin = totalLabourProduct.toString();
    }
    if (type === 'service') {
      totalLabour = bookingDataCopy.pricingType === 'hourly'
        ? calculateServiceLabour(bookingDataCopy)
        : cost;
    }
    return { totalLabour, laborMargin };
  };

  const renderCostCalculation = (data) => {
    let quot = data / 5;
    let str = quot.toString();
    let splitData = str.split('.');
    console.log('splitData', splitData);
    let value;
    if (splitData.length > 1 && splitData[1] !== '' && splitData[1] !== '0') {
      value = parseFloat(splitData[0]) + 1;
    } else {
      value = quot;
    }
    let result = value * 5;

    // console.log('ddddddddddd', result);
    return result;
  };

  const validateService = () => {
    let isValid = true;
    let msg = '';
    let errValue = '';
    const {
      category, subcategory, staffName, type, customMisc, quantity,
    } = bookingData;
    let { cost, buyCostProduct } = bookingData;

    cost = cost.replace(/,/g, '').replace('$', '');
    buyCostProduct = buyCostProduct.replace(/,/g, '').replace('$', '');
    // console.log('cost', cost, 'buy cost product', buyCostProduct);

    if (!category) {
      isValid = false;
      msg = 'Please select category';
      errValue = 'category';
    } else if (!subcategory) {
      isValid = false;
      msg = 'Please select subcategory';
      errValue = 'subcategory';
    } else if (!staffName) {
      isValid = false;
      msg = 'Please select staff';
      errValue = 'staffName';
    } else if (
      type === 'product'
      && parseFloat(cost) < parseFloat(buyCostProduct)
    ) {
      isValid = false;
      msg = 'Sell price must be greater than buy cost';
      errValue = 'sell price';
    } else if (!cost) {
      isValid = false;
      msg = 'Enter Sell Price (ex tax)';
      errValue = 'sell price';
    } else if (!buyCostProduct && type === 'product') {
      isValid = false;
      msg = 'Enter Buy Price (ex tax)';
      errValue = 'buyCostProduct';
    } else if (
      bookingData.category.toLowerCase().includes('misc')
      && !customMisc
    ) {
      isValid = false;
      msg = 'Enter New Name';
      errValue = 'customMisc';
    } else if (!quantity) {
      isValid = false;
      msg = 'Enter quantity';
      errValue = 'quantity';
    }
    return { isValid, msg, errValue };
  };
  function groupBy(collection, property) {
    let i = 0;
    let val;
    let index;
    let values = [];
    let result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msg = hours && minutes
        ? `${hours}hr ${minutes} min`
        : hours && !minutes
          ? `${hours}hr`
          : `${minutes} min`;
      arr.push({ value: val, data: msg });
      val += 5;
    }
    return arr;
  };

  useEffect(() => {
    getCategories();
  }, [dateBookingData]);

  const calculateSummary = (finalServices) => {
    let totalCostWithoutProduct = 0;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    let totalCost = 0;
    let totalDuration = 0;
    finalServices.forEach((el) => {
      totalDuration += parseFloat(el.duration);
      totalCost += parseFloat(el.renderCost);
      laborMargin += parseFloat(el.laborMargin) + parseFloat(el.totalLabour);
    });
    let totalLabour = laborMargin;
    // console.log('appointment[0]?.chemicalsArray?---', appointment[0]?.chemicalsArray);
    const chemicalsLaborMargin = parseFloat(
      appointment[0]?.chemicalsArray[0]?.labourMargin,
    );
    if (appointment[0]?.chemicalsArray.length > 0) {
      totalLabour = chemicalsLaborMargin + laborMargin;
    }

    let totalLabourOnly = finalServices.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.totalLabour ? next.totalLabour : 0),
      0,
    );
    let totalMargin = finalServices.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.laborMargin ? next.laborMargin : 0),
      0,
    );
    setTotalData({
      ...totalData,
      totalCost: addZero(parseFloat(totalCost).toFixed(2)),
      totalDuration,
      totalLabour: addZero(parseFloat(totalLabour).toFixed(2)),
      multiTotal: totalCost,
      totalLabourOnly: addZero(parseFloat(totalLabourOnly).toFixed(2)),
      totalMargin: addZero(parseFloat(totalMargin).toFixed(2)),
    });
  };

  const handleEditService = (services, index) => {
    setStaffIndex(index);
    if (index === 0) {
      setnewlength(0);
    } else {
      setnewlength(addedServices.length);
    }
    const updateObj = { ...bookingData, ...services };
    setBookingData(updateObj);
    setShowService(!showService);
    setEditData({ edit: true, index, type: 'mark' });
    if (parseFloat(services.duration) > 960) {
      setExtras({
        ...extras,
        value: services.duration,
        data: convertToReadableDuration(services.duration),
      });
    }
    const subcategory = services.subcategory.replace(
      services.subcategory.split('$')[1],
      services.renderCost.split('.')[1] === '00'
        ? addZero(services.renderCost)
        : addZero(services.cost),
    );
    setSearchTxt(subcategory);
  };

  const handleDeleteService = () => {
    const currentServices = [...addedServices];
    currentServices.splice(editData.index, 1);
    if (editData.index === addedServices.length - 1) {
      setBookingData({
        ...bookingData,
        startTime: addedServices[editData.index].startTime,
      });
      setAddedServices([...currentServices]);
    } else {
      recalculateServices(addedServices[editData.index]);
    }
    calculateSummary([...currentServices]);
    invoiceEditSubTotal -= addedServices[editData.index].cost;
    invoiceData.invoiceSubTotal = invoiceEditSubTotal;
    setShowService(!showService);
  };

  const recalculateServices = (data) => {
    const remainingServices = addedServices.filter(
      (_el, i) => i > editData.index,
    );
    const previousServices = addedServices.filter(
      (_el, i) => i < editData.index,
    );
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = '';
      if (i === 0) {
        end = new Date(data.start);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(
        new Date(
          end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
        ),
      ).format(dateFormat);
    }
    const arr = previousServices;
    const newArray = arr.concat(remainingServices);
    setAddedServices([...newArray]);
  };

  const handleSearchCategory = async (searchTxt) => {
    console.log(searchTxt);
    try {
      setSearchTxt(searchTxt);
      if (searchTxt) {
        const response = await userInstance().get(
          `/service/searchCategory?value=${searchTxt.replace('$', '').trim()}&industryId=${industryId._id}`,
        );
        const { services } = response.data;
        setCategories(services);
        if (services.length) {
          const subCat = [];
          services.forEach((ser) => {
            const reg = new RegExp(`${searchTxt.replace('$', '').trim()}`, 'i');
            const matchedSubcategories = ser.subCategory.filter(
              (el) => reg.test(el.cost)
                || reg.test(el.name)
                || reg.test(el.retailCost)
                || reg.test(el.serviceDescription),
            );
            subCat.push(...matchedSubcategories);
          });
          if (subCat.length) {
            setSubcategories([...subCat]);
          } else {
            setSubcategories([]);
          }
        } else {
          setSubcategories([]);
        }
      }
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const handleAddService = async () => {
    const {
      subcategory,
      type,
      duration,
      previousDuration,
      previousQuantity,
      pricingType,
      customMisc,
      description,
    } = bookingData;
    let {
      cost, buyCostProduct, renderCost, quantity, startTime,
    } = bookingData;
    // console.log(
    //   'costttt',
    //   invoiceEditSubTotal,
    //   renderCost,
    //   parseFloat(renderCost.replace(/,/g, '').replace('$', '')),
    // );
    // invoiceEditSubTotal += parseFloat(cost);
    // invoiceEditSubTotal += parseFloat(renderCost.replace(/,/g, '').replace('$', ''));
    // invoiceData.invoiceSubTotal = invoiceEditSubTotal;
    // calculateGst(dat);
    // calculateInvoiceTotal(dat);
    let oldStateCopy = [...newAppointment];
    quantity = quantity.replace(/,/g, '');
    cost = parseFloat(cost.replace(/,/g, '').replace('$', '')).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, '').replace('$', ''),
    ).toFixed(2);
    renderCost = type === 'service' && pricingType === 'hourly'
      ? (parseFloat(cost) * parseFloat(quantity)) / 60
      : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === 'product') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === 'service' || pricingType === 'hourly') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }
    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      // let startTime = setDefaultTime; // changes by sanjana '9:30';
      let start = moment(dateBookingData.date).toDate();
      let time = bookingData.startTime.split(':');
      const bookingDataCopy = { ...bookingData };
      const lastAppServ = addedServices.length;
      const lastServiceArr = addedServices[lastAppServ - 1];
      const lastService = lastServiceArr;
      // const lastService = addedServices[0];
      if (lastService) {
        start = moment(lastService.end).toDate();
        time = moment(lastService.end).format(timeFromDateFormat).split(':');
      }
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);
      bookingDataCopy.cost = cost;
      bookingDataCopy.quantity = quantity;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.description = customMisc || description;
      invoiceEditSubTotal += parseFloat(renderCost.replace(/,/g, '').replace('$', ''));
      invoiceData.invoiceSubTotal = invoiceEditSubTotal;

      if (bookingDataCopy.buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      const { laborMargin, totalLabour } = calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;

      let durationInMin = duration;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 || pricingType === 'hourly'
          ? quantity
          : duration;
      }
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      bookingDataCopy.duration = durationInMin;
      bookingDataCopy.start = moment(start).format(dateFormat);
      bookingDataCopy.end = moment(
        new Date(
          start.setMinutes(
            start.getMinutes() + parseFloat(bookingDataCopy.duration),
          ),
        ),
      ).format(dateFormat);
      startTime = moment(bookingDataCopy.start).format(timeFromDateFormat);
      bookingDataCopy.startTime = startTime;
      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00'
          ? addZero(renderCost)
          : addZero(renderCost),
      );
      bookingDataCopy.timeChange = false;
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      if (!previousQuantity) {
        bookingDataCopy.previousQuantity = quantity;
      }

      if (!editData.edit) {
        finalServices = [...addedServices, bookingDataCopy];
      }
      console.log('finalServices====>', finalServices);
      // finalServices.sort((a, b) => ((a.startTime > b.startTime) ? 1 : -1));
      // let FillterServices = finalServices.sort((a, b) => new Date(...b.start.split('/').reverse()) - new Date(...a.start.split('/').reverse()));
      handleShowService();
      calculateSummary([...finalServices]);
      setAddedServices(finalServices);
    } else {
      NotificationManager.error(msg, 'Message', errorMsgTime);
      setErrorValue(errValue);
    }
    setTimeout(() => { setErrorValue(''); }, 1000);
  };
  const calculateNewDurations = (data) => {
    let multiArray = [];
    multiArray = data;
    for (let i = 0; i < multiArray.length; i++) {
      const currentEl = multiArray[i];
      let end = '';
      if (i === 0) {
        end = new Date(data[0].end);
      } else {
        end = new Date(multiArray[i - 1].end);
        const startTime = moment(end).format(timeFromDateFormat);
        currentEl.startTime = startTime;
        currentEl.start = moment(end).format(dateFormat);
        currentEl.end = moment(
          new Date(
            end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
          ),
        ).format(dateFormat);
      }
    }
    // console.log('multiArray ===>', multiArray);
  };
  const editService = () => {
    const {
      start,
      startTime,
      subcategory,
      type,
      previousDuration,
      previousQuantity,
      duration,
      pricingType,
    } = bookingData;
    let {
      cost, buyCostProduct, renderCost, quantity,
    } = bookingData;
    console.log('booking data for edit', bookingData);
    quantity = quantity.replace(/,/g, '');
    cost = parseFloat(cost.replace(/,/g, '').replace('$', '')).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, '').replace('$', ''),
    ).toFixed(2);
    renderCost = type === 'service' && pricingType === 'hourly'
      ? (parseFloat(cost) * parseFloat(quantity)) / 60
      : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === 'product') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === 'service' || pricingType === 'hourly') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }

    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      const bookingDataCopy = { ...bookingData };
      const startDate = moment(start).toDate();
      const time = startTime.split(':');
      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00'
          ? addZero(renderCost)
          : addZero(renderCost),
      );
      bookingDataCopy.cost = cost;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.quantity = quantity;
      if (buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      startDate.setHours(time[0]);
      startDate.setMinutes(time[1]);
      startDate.setSeconds(0);
      bookingDataCopy.start = moment(startDate).format(dateFormat);
      const { laborMargin, totalLabour } = calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;
      let durationInMin = 0;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 || pricingType === 'hourly'
          ? quantity
          : duration;
        bookingDataCopy.duration = durationInMin;
      } else if (
        previousQuantity !== quantity
        || previousDuration !== duration
      ) {
        bookingDataCopy.previousDuration = durationInMin;
        bookingDataCopy.previousQuantity = bookingDataCopy.quantity;
      }
      bookingDataCopy.end = moment(
        new Date(
          startDate.setMinutes(
            startDate.getMinutes() + parseFloat(bookingDataCopy.duration),
          ),
        ),
      ).format(dateFormat);

      const currentServices = [...addedServices];
      currentServices[editData.index] = bookingDataCopy;
      finalServices = currentServices;
      // let FillterServices = finalServices.sort(
      //   (a, b) => new Date(...b.start.split('/').reverse())
      //     - new Date(...a.start.split('/').reverse()),
      // );
      calculateNewDurations(finalServices);
      calculateSummary([...finalServices]);
      setAddedServices(finalServices);
      setShowService(!showService);
    } else {
      NotificationManager.error(msg, 'Message', errorMsgTime);
      setErrorValue(errValue);
    }
    setTimeout(() => { setErrorValue(''); }, 1000);
  };

  const addSubcategoryToService = (value) => {
    // console.log('kkkkkkkkkkkkkk', value);
    let kquantity = '1';
    const selectedCategory = categories.filter(
      (el) => el.subCategory.filter((sub) => sub.name === value).length,
    );
    const subcategoryData = selectedCategory[0].subCategory.filter(
      (el) => el.name === value,
    );
    const { type, category } = selectedCategory[0];
    const {
      duration, cost, soldPer, unit, symbol, serviceDescription, tax, s_uId,
      categoryId, supplier,
    } = subcategoryData[0];

    let customCostData = 'default';
    let newCost = addZero(
      subcategoryData[0][type === 'product' ? 'retailCost' : 'cost'],
    );
    let newBuyCost = type === 'product' ? cost : '';

    if (selectedCategory[0].category.toLowerCase().includes('misc')) {
      setMisc(true);
      customCostData = type === 'product' ? 'buySell' : 'yes';
      newCost = '';
      newBuyCost = '';
    }
    let soldPerValue = soldPer || '';
    if (type === 'product') {
      if (soldPer === 'Add Custom Measure') {
        soldPerValue = `${unit}(${symbol})`;
      }
    }
    if (value.toLowerCase().includes('misc')) {
      console.log(value);
      if (type === 'product') {
        kquantity = '';
        bookingData.quantity = kquantity;
      }
      // setBookingData({ ...bookingData, quantity: '' });
    }
    setBookingData({
      ...bookingData,
      category,
      subcategory: value,
      cost: newCost,
      duration,
      type,
      quantity: kquantity,
      buyCostProduct: newBuyCost,
      customCost: customCostData,
      timeChange: false,
      customMisc: '',
      previousDuration: '',
      previousQuantity: '',
      pricingType: 'fixedCost',
      previousCost: newCost,
      prevBuyCostProduct: newBuyCost,
      soldPer: soldPerValue,
      description: serviceDescription || value,
      tax,
      s_uId,
      categoryId,
      supplier,
    });
    setSearchTxt(value);
    setSubcategories(null);
  };
  const searchClient = async (searchTxt_) => {
    try {
      setClientTxt(searchTxt_);
      if (searchTxt_) {
        const response = await userInstance().get(
          `/customer/searchCustomer?value=${searchTxt_}`,
        );
        const { customers } = response.data;
        setClients(customers);
      } else {
        setClients(searchedClients2);
      }
    } catch (error) {
      //   console.log(error);
    }
  };
  const handleEditDetails = (data, data2) => {
    console.log('hello', data, data2);
    history.push(`/client?clientid=${data}&name=${data2}&typee=edit`);
  };

  const handleShowSearch = async () => {
    if (showSearch) {
      console.log('I was here 2');
      setClients(null);
    } else {
      const response = await userInstance().get('/customer/getCustomersAll2');
      if (response.status === 200) {
        const { customers } = response.data;
        setClients(customers);
        setClients2(customers);
      }
    }

    setShowSearch(!showSearch);
  };
  let gstAmt = 0;
  let invoiceTotalAmt = 0;
  const calculateGst = (info1) => {
    const resultGst = averageGstCalculation(customAppointmentArr);
    let chemicalGst = 0;
    if (appointment[0]
      && appointment[0].chemicalsArray
      && appointment[0].chemicalsArray.length > 0) {
      chemicalGst = gstChemicalsCalculations(appointment[0].chemicalsArray[0]?.testResult[0]?.service);
    }
    // console.log('chemicalGst', chemicalGst);
    const { perfectGstPer, totalCost } = resultGst;
    const gstAmount = (totalCost * (perfectGstPer / 100)) + (chemicalGst || 0);
    gstAmt = gstAmount;
    return gstAmount;
    // const gstPercent = 10;
    // const gstAmount = parseFloat(info1, 10) * (gstPercent / 100);
    // gstAmt = gstAmount;
    // return gstAmount;
    // const totalAmount = parseInt(appointmentData.totalData.totalCost, 10) + gstAmount;
  };

  const calculateInvoiceTotal = (info2) => {
    const totalAmount = parseFloat(info2, 10) + gstAmt;
    invoiceTotalAmt = totalAmount;
    return totalAmount;
  };

  const updateInvoice = async () => {
    try {
      if (addedServices.length > 0) {
        // console.log('ghhggty===>>>', appointment[0].dateData[0].service, addedServices);
        const copyServices = appointment[0].dateData[0].service.map((el) => ({ ...el }));
        appointment[0].dateData[0].service = addedServices;
        appointment[0].dateData[0].summary = totalData;
        const payload = {
          id: invoiceData._id,
          addedServices: appointment,
          invoiceTotalAmt: invoiceTotalAmt.toFixed(2),
          gstAmt: gstAmt.toFixed(2),
          invoiceSubTotal: parseFloat(invoiceSubTotal).toFixed(2),
          totalData,
          invoiceNotes,
          service: addedServices,
          oldServices: copyServices,
        };

        console.log('addedService', addedServices, copyServices, oldData);

        const response = await userInstance().post(
          '/invoice/editInvoice',
          payload,
        );
        const { code, msg } = response.data;
        if (code === 200) {
          NotificationManager.success(msg, 'Message', successMsgTime);
          if (clientid && from) {
            history.push(`/approvalappointments?viewtype=listpage&clientid=${clientid}&from=${from}`);
          } else {
            history.push('/approvalappointments?viewtype=listpage');
          }
          // setEditInvoice(false);
        }
      } else {
        NotificationManager.error(
          'Please Add Services',
          'Message',
          errorMsgTime,
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = () => {
    history.push(`/approvalappointments?clientid=${addedClients._id}&viewtype=listpage`);
  };
  const goBackToPage = () => {
    if (viewtype === 'detailPage') {
      handleCancel();
    } else {
      setEditInvoice(false);
    }
  };

  const voidInvoiceClick = (id) => {
    setPopUpText('Void  Invoice?');
    setDeletePopUp(true);
  };
  const handlePopUpChange = async (val) => {
    console.log('val', val);
    if (val === 'Yes' && invoiceData._id) {
      const response = await userInstance().get(
        `/invoice/deleteInvoice?id=${invoiceData._id}`,
      );
      if (response.data.msg === 'Success') {
        console.log('response.data.msg=>', response.data.msg);
        NotificationManager.success(
          'Invoice deleted successfully',
          'Message',
          5000,
        );
      }
      setEditInvoice(false);
    }
    setDeletePopUp(false);
  };
  // For the dynamic  Labour and margin text show according to condition
  const labourMarginText = appointment[0]
    && appointment[0].chemicalsArray
    && appointment[0]?.chemicalsArray.length > 0 || (parseFloat(totalData?.totalLabourOnly) > 0 && parseFloat(totalData?.totalMargin)) > 0 ? ' Labour & Margin: $' : parseFloat(totalData?.totalMargin) > 0 ? 'Margin: $' : 'Labour: $';

  const handleWaterUpdate = async () => {
    console.log('oooopopopopopo', appointment[0]?.appointment_calc_type, addedClients);

    localStorage.setItem('invId', invoiceData._id);
    const ApptId = appointment[0]?.appointment_calc_type !== 'Water Test' ? appointment[0]?.pid : appointment[0]?._id;
    const ClientId = appointment[0].client;
    const clWholeData = addedClients[0];

    console.log('apppidd', ApptId);
    try {
      if (ApptId) {
        console.log('okay 2==>');
        const response = await userInstance().get(
          `/watertest/getWaterHistoryByAppointmentId?id=${ApptId}`,
        );
        const { waterList, msg } = response.data;
        console.log('watertestPart==>', waterList);
        const copyClientAllData = { ...clientAllData };
        copyClientAllData.waterTestHistory = waterList?.history;
        copyClientAllData.clientAllDetails = clWholeData;
        console.log('copyCLientAllData.waterTesthisstiu===========>>>>', copyClientAllData);
        setClientAllData(copyClientAllData);
        localStorage.setItem('apptData', JSON.stringify(copyClientAllData));
        if (waterList?.history?.serviceAnalysis === 'Water Test') {
          history.push(`/calculator?appId=${ApptId}&clident=${ClientId}&editChemicalList=true&invoice=true&watTesId=${waterList?._id}`);
        } else if (waterList?.chemicals.length > 0) {
          console.log('greentestPart');
          history.push(`/calculator?appId=${ApptId}&clident=${ClientId}&visitMessage=true&invoice=true&watId=${waterList?._id}`);
        }
      }

      if (ApptId) {
        console.log('ApptId', ApptId);
        const response = await userInstance().get(
          `/watertest/getWaterHistoryByAppointmentId?id=${ApptId}`,
        );
        const { waterList, msg } = response.data;
        console.log('Water------<<<<<<<<<<<<<<<<', waterList);
        // const copyClientAllData = { ...clientAllData };
        // copyClientAllData.waterTestHistory = waterList?.history;
        // copyClientAllData.clientAllDetails = clWholeData;
        // console.log('copyCLientAllData.waterTesthisstiu===========>>>>', copyClientAllData);
        // setClientAllData(copyClientAllData);
        // localStorage.setItem('apptData', JSON.stringify(copyClientAllData));
        // history.push(`/calculator?appId=${ApptId}&clident=${ClientId}&editChemicalList=true&invoice=true&watTesId=${waterList?._id}`);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const calculateMutiCost = (item) => {
    const total = item.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.renderCost ? next.renderCost : 0),
      0,
    );
    // const totalCost = item.reduce(
    //     (pre, next) => parseFloat(pre || 0)
    //         + parseFloat(next.renderCost ? next.renderCost : 0),
    //     0,
    //   ))
    //   .reduce((pre, next) => pre + next);
    // const totalLabourAmount = item
    //   .map((el2) => el2.service.reduce(
    //     (pre, next) => parseFloat(pre || 0)
    //         + parseFloat(next.totalLabour ? next.totalLabour : 0),
    //     0,
    //   ))
    //   .reduce((pre, next) => pre + next);

    // const totalMargin = item
    //   .map((el2) => el2.service.reduce(
    //     (pre, next) => parseFloat(pre || 0)
    //         + parseFloat(next.laborMargin ? next.laborMargin : 0),
    //     0,
    //   ))
    //   .reduce((pre, next) => pre + next);
    // const totalLabour = totalLabourAmount + totalMargin;
    // const totalDuration = item
    //   .map((el2) => el2.service.reduce(
    //     (pre, next) => parseFloat(pre || 0)
    //         + parseFloat(next.duration ? next.duration : 0),
    //     0,
    //   ))
    //   .reduce((pre, next) => pre + next);

    // if (index !== null) {
    //   const subSummary = item[index].service;
    //   let obj = {
    //     totalCost: addZero(parseFloat(TotalPartVist(subSummary)).toFixed(2)),
    //     totalDuration: TotalPartduration(subSummary),
    //     totalLabour: addZero(
    //       parseFloat(TotalPartLabour(subSummary)).toFixed(2),
    //     ),
    //     multiTotal: addZero(parseFloat(TotalPartVist(subSummary)).toFixed(2)),
    //     totalMargin: parseFloat(totalMargin).toFixed(2),
    //     totalLabourOnly: parseFloat(totalLabourAmount).toFixed(2),
    //   };
    //   item[index].summary = obj;
    //   setNewAppointment(item);
    // }
    // setMutiSummary({
    //   ...mutiSummary,
    //   totalCost: addZero(parseFloat(totalCost).toFixed(2)),
    //   totalDuration,
    //   totalLabour: addZero(parseFloat(totalLabour).toFixed(2)),
    //   totalLabourMargin: addZero(parseFloat(totalMargin).toFixed(2)),
    //   multiTotal: totalCost,
    //   totalLabourAmount: addZero(parseFloat(totalLabourAmount).toFixed(2)),
    // });
  };
  useEffect(() => {
    if (appointment[0]
      && appointment[0].chemicalsArray
      && appointment[0].chemicalsArray.length > 0) {
      const chemService = appointment[0].chemicalsArray[0]?.testResult[0]?.service;
      const chemicalaTypeList = chemService.filter(
        (el2) => el2.type === 'product' && el2.status === 'quantity',
      );
      const serviceProTypeList = chemService.filter(
        (el2) => el2.type === 'service'
            || (el2.type === 'product' && el2.status !== 'quantity'),
      );
      let finalServices2 = [...addedServices, ...serviceProTypeList];
      const finalServices = finalServices2.reduce((acc, current) => {
        const x = acc.find((item) => item.subcategory === current.subcategory);
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, []);

      // console.log('finalServices ====>', finalServices);
      // console.log('serviceProTypeList==>', { chemService });
      calculateMutiCost(serviceProTypeList);
    }
  }, []);

  useEffect(() => {
    const copy = JSON.stringify(appointment);
    setOldData(JSON.parse(copy));
  }, []);

  const changeChemValue = (string) => `Add${string.split('of')[1]}`;

  return (
    <div className="appointment-container finalize-cost-page new-invoice">
      {console.log(searchType, 'searchTypesearchTypesearchType')}
      {/* <div className={`container ${searchType === 'search' ? 'invoice-details-align' : ''}`}> */}
      <div
        className="container"
        style={searchType === 'search' || searchType === 'previewedit' ? { padding: '0px' } : null}
      >
        <div className="row">
          {searchType === 'search' ? null : (
            <>
              <div className="back-option">
                <span />
                <i
                  className="fa fa-times"
                  onClick={goBackToPage}
                  aria-hidden="true"
                />
              </div>
            </>
          )}
          <div className="add-appointment">
            {searchType === 'search' ? (
              <div className="invoice-detail-header">
                <h4>Invoice Details</h4>
                <h6>
                  Invoice: #
                  {invoiceNo || '' }
                </h6>
              </div>
            ) : (
              <div className="invoice-detail-header">
                <h4>Edit Invoice</h4>
                <h6>
                  Invoice: #
                  {invoiceNo || '' }
                </h6>
              </div>
            )}

            <div className="invoice-detail-body">
              <div className="row">
                <div className="col-md-6">
                  {!addedClients.length || !addedClients ? (
                    <div className="search-container">
                      <div className="search-title">
                        <h3> Client </h3>
                        <span
                          className="plus-icon"
                          onClick={handleShowSearch}
                          role="presentation"
                        >
                          <i className="fas fa-plus" />
                        </span>
                      </div>
                      <div className="no-customer">
                        <img src={search} alt={search} />
                        <p>
                          Use the search to add a customers, or keep empty to save
                          as walk-in.
                        </p>
                      </div>
                      <div className="footer-btn view-btn">
                        <Button className="appointment-btn btn-save">
                          {' '}
                          Save/Update
                          {' '}
                        </Button>
                      </div>
                    </div>
                  ) : null}
                  <div className="edit-invoice-due">
                    <div className="search-container">
                      {addedClients
                      && addedClients.map((client) => (
                        <>
                          <div className="owing-amount">
                            <p>
                              $
                              {customerAllDue !== 'NaN' ? customerAllDue?.toFixed(2) : 0.00}
                              {' '}
                              Due
                            </p>
                          </div>
                          <div className="search-title">
                            <div className="client-profile ">
                              {client ? (
                                <div className="client-info">
                                  <h3>{`${client.firstname} ${client.lastname}`}</h3>
                                  <p>
                                    {`${client.streetAddress}${
                                      client.suburb || client.state ? '' : ''
                                    } ${client.suburb} ${client.state}`}
                                  </p>
                                  <p>{client.mobileNo}</p>
                                </div>
                              ) : (
                                ''
                              )}

                              {client ? (
                                <div className="client-option service-edit-icon">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                    >
                                      <img src={showmore} alt="show" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <div className="action client">
                                          <span>
                                            <a href={`tel:${client.mobileNo}`}>
                                              <i
                                                className="fa fa-phone"
                                                aria-hidden="true"
                                              />
                                              Call
                                              {client.mobileNo}
                                            </a>

                                          </span>
                                        </div>
                                      </Dropdown.Item>
                                      {/* <Dropdown.Item>
                                  <div
                                    className="action client"
                                    onClick={() => {
                                      handleEditDetails(client._id, client.firstname);
                                    }}
                                  >
                                    <img src={edit} alt="edit" />
                                    Edit Client Details
                                  </div>
                                </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          {client ? (
                            <div className="client-profile">
                              <div className="client-name-contact site-addresses">
                                {client.siteAddresses.length > 0 && (
                                  <div className="client-left-details">
                                    <h5 className="client-company-name">
                                      Site Address
                                    </h5>
                                    <p>
                                      {client.siteAddresses[activeAdd]
                                        .householderName
                                        ? client.siteAddresses[activeAdd]
                                          .householderName
                                        : ''}
                                    </p>
                                    <p>
                                      {client.siteAddresses[activeAdd].address
                                        ? client.siteAddresses[activeAdd]
                                          .address
                                        : ''}
                                      {' '}
                                    </p>
                                    <p>
                                      <a
                                        className="mobile"
                                        href={`tel:${
                                          client.siteAddresses[activeAdd]
                                            .mobileNo || ''
                                        }`}
                                      >
                                        {`${
                                          client.siteAddresses[activeAdd]
                                            .mobileNo || ''
                                        }`}
                                      </a>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                {appointment ? (
                  <div className="col-md-6">
                    <div className="appointment-view">
                      <div className="appointment-date" role="presentation">
                        {appointment?.length > 0 && (
                        <div className="">
                          <h3>
                            {`${moment(appointment[0].dateData[0].date).format(
                              'dddd',
                            )}, ${moment(
                              appointment[0].dateData[0].date,
                            ).format('D MMMM YYYY')}`}
                          </h3>
                        </div>
                        )}
                        {searchType === 'search' ? null : (
                          <>
                            <div className="sorting-sub-services appointment-sort">
                              <div className="content-dropdown">
                                <img src={arrow} alt="arrow" />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <span className="sep" />
                      <div className="search-title service-head">
                        <h3> Maintenance Required</h3>
                        <div className="sorting-services">
                          {searchType === 'search' ? null
                            : (
                              <>
                                {addedServices.length > 1 ? (
                                  <div className="sorting">
                                    <img
                                      src={sort}
                                      alt="sorting"
                                      onClick={() => setRenderSort(!renderSort)}
                                    />
                                  </div>
                                ) : null}
                                {' '}

                              </>
                            )}
                          {searchType === 'search' ? null : (
                            <>
                              <span
                                className="plus-icon"
                                onClick={() => handleShowService(0)}
                                role="presentation"
                              >
                                <i className="fas fa-plus" />
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      {addedServices
                      && addedServices.map((ser, i) => (
                        <div
                          className="search-title"
                          onClick={
                            searchType === 'search'
                              ? null
                              : () => handleEditService(ser, i)
                          }
                        >
                          <div className="client-profile">
                            <h3>
                              {`${
                                ser.category.toLowerCase().includes('misc')
                                  ? `${ser.customMisc} $${addZero(
                                    ser.renderCost,
                                  )}`
                                  : ser.subcategory
                              } ${
                                parseFloat(ser.quantity) > 1
                                  ? `($${`${addZero(ser.cost)}${
                                    ser.soldPer === 'Kilogram (kg)'
                                      ? ' per kg'
                                      : ''
                                  }`} x ${`${
                                    ser.type === 'service'
                                      && ser.pricingType === 'hourly'
                                      ? convertToReadableDuration(
                                        ser.quantity,
                                      )
                                      : `${
                                        ser.soldPer === 'Kilogram (kg)'
                                          ? `${ser.quantity}kg`
                                          : ser.quantity
                                      }`
                                  }${ser.type === 'service' ? ' ' : ''})`}`
                                  : ''
                              }`}
                            </h3>
                            <p>
                              <span>
                                {moment(ser.startTime, [
                                  timeFromDateFormat,
                                ]).format('h:mm a')}
                              </span>
                              <span> | </span>
                              <span>
                                {` ${convertToReadableDuration(
                                  ser.duration,
                                )}`}

                              </span>
                              <span> | </span>
                              <span>{` with ${ser.staffName}`}</span>
                              <span> | </span>
                            </p>
                            <p>
                              <span>
                                {`${
                                  ser.type === 'product'
                                    ? `Margin $${addZero(ser.laborMargin)}`
                                    : ser.type === 'service'
                                      ? `Labour $${`${addZero(ser.totalLabour)}`}`
                                      : null
                                }`}
                              </span>
                            </p>
                          </div>
                          <div className="sorting-sub-services appointment-sort">
                            <div className="service-edit-icon">
                              {renderSort ? (
                                <div
                                  className={`sorting up-down ${
                                    renderSort && 'active-sort'
                                  }`}
                                >
                                  {i !== 0 ? (
                                    <img
                                      src={upsort}
                                      alt="sorting"
                                      onClick={() => moveArr(i, i - 1, 'minus')}
                                    />
                                  ) : null}
                                  {i !== addedServices.length - 1 ? (
                                    <img
                                      src={downsort}
                                      alt="sorting"
                                      onClick={() => moveArr(i, i + 1, 'plus')}
                                    />
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                            {searchType === 'search' ? null : (
                              <>
                                <div
                                  className="content-dropdown"
                                  onClick={() => handleEditService(ser, i)}
                                >
                                  <Dropdown name="startingDate">
                                    <Dropdown.Toggle
                                      variant="link"
                                      id="dropdown-basic"
                                    >
                                      <img src={arrow} alt="arrow" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item>Option 1</Dropdown.Item>
                                      <Dropdown.Item>Option 2</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                      {/* <div className="appointment-form">
                        <Form>
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Invoice Notes</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Only Visible to Team Members"
                              name="notes"
                              value={invoiceNotes}
                              onChange={(e) => setInvoiceNotes(e.target.value)}
                            />
                          </Form.Group>
                        </Form>
                      </div> */}
                      <span className="sep" />
                      {appointment[0]
                      && appointment[0].chemicalsArray
                      && appointment[0].chemicalsArray.length > 0 && (
                        <div className="added-chemicals-info">
                          <h6 onClick={frompage === 'invoice' ? '' : handleWaterUpdate}>
                            Chemicals added
                          </h6>
                          {appointment[0].chemicalsArray[0]?.testResult[0]?.service.map(
                            (chem, index) => (
                              <>
                                {parseFloat(chem.cost) > 0
                                  && chem.type === 'product' && chem.status === 'quantity' ? (
                                    <div className="chemical-description">
                                      <div className="chemical-name">
                                        {chemicalsToDisplay && '-'}
                                        {' '}
                                        { chemicalsToDisplay
                                          ? chem?.subcategory : changeChemValue(chem?.subcategory)}
                                        {/* {`Add ${chem.quantity} ${chem.unit} of ${chem.chemical}`} */}
                                      </div>
                                      <div className="chemical-rate">
                                        $
                                        <span>
                                          {parseFloat(chem?.renderCost).toFixed(
                                            2,
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : null}
                              </>
                            ),
                          )}
                        </div>
                      )}
                      <div className="appointment-total">
                        <h6 className="duration-time">
                          Sub Total:
                          <span>
                            $
                            {invoiceEditSubTotal.toFixed(2)}
                          </span>
                        </h6>
                        <h6 className="duration-time">
                          GST:
                          <span>
                            $
                            {calculateGst(invoiceEditSubTotal).toFixed(2)}
                          </span>
                        </h6>
                        <h6 className="duration-time">
                          Invoice Total:
                          <span>
                            $
                            {calculateInvoiceTotal(invoiceEditSubTotal).toFixed(
                              2,
                            )}
                          </span>
                        </h6>
                        <p className="labour-margin">
                          {labourMarginText}
                          {totalData?.totalLabour}
                        </p>
                      </div>

                      <div className="footer-btn view-btn">
                        <>
                          <Button className="btn-delete" onClick={goBackToPage}>
                            Cancel
                          </Button>
                        </>
                        {searchType === 'search' ? (
                          <>
                            {invoiceData.status !== 'deleted'
                            && (
                            <>
                              <Button
                                className="appointment-btn btn-save"
                                onClick={voidInvoiceClick}
                              >
                                Void Invoice
                              </Button>
                            </>
                            )}
                          </>
                        ) : (
                          <>
                            <Button
                              className="appointment-btn btn-save"
                              onClick={() => updateInvoice()}
                            >
                              Update Invoice
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <SearchModal
                  showSearch={showSearch}
                  setShowSearch={setShowSearch}
                  handleShowSearch={handleShowSearch}
                  searchClient={searchClient}
                  searchedClients={searchedClients}
                  handleAddClient={handleAddClient}
                  clientTxt={clientTxt}
                  setClientTxt={setClientTxt}
                  checkAddress={checkAddress}
                />
                <DateModal
                  showDate={showDate}
                  setShowDate={setShowDate}
                  handleDateModal={handleDateModal}
                  handleBookingData={handleBookingData}
                  bookingData={bookingData}
                  setBookingData={setBookingData}
                  dateData={dateBookingData}
                  handleChangeDateData={handleChangeDateData}
                  setDateData={setdateBookingData}
                  repeatAppt={repeatAppt}
                  setRepeatAppt={setRepeatAppt}
                  handleFrequency={handleFrequency}
                  repeatFrequency={repeatFrequency}
                  setRepeatFrequency={setRepeatFrequency}
                  handleChangeDate={handleChangeDate}
                  datePopupData={datePopupData}
                  timepickerRef={timepickerRef}
                  handleSaveDate={handleSaveDate}
                  handleRepeat={handleRepeat}
                  appointmentType={appointmentType}
                  appointmentIndex={appointmentIndex}
                />

                <ServiceModal
                  showService={showService}
                  setShowService={setShowService}
                  handleShowService={handleShowService}
                  newLength={newLength}
                  categories={categories}
                  subcategories={subcategories}
                  handleBookingData={handleBookingData}
                  getTimes={getTimes}
                  bookingData={bookingData}
                  renderOptions={renderOptions}
                  staffList={staffList}
                  handleAddService={handleAddService}
                  editData={editData}
                  handleDeleteService={handleDeleteService}
                  handleSearchCategory={handleSearchCategory}
                  setSubcategories={setSubcategories}
                  addSubcategoryToService={addSubcategoryToService}
                  editService={editService}
                  setBookingData={setBookingData}
                  staffIndex={staffIndex}
                  misc={misc}
                  addedServices={addedServices}
                  servicesLength={addedServices.length}
                  extras={extras}
                  handleChangeCustom={handleChangeCustom}
                  timepickerShow={timepickerShow}
                  handleTimePicker={handleTimePicker}
                  subcategoryTxt={subcategoryTxt}
                  setSearchTxt={setSearchTxt}
                  durationPicker={durationPicker}
                  setDurationPicker={setDurationPicker}
                  convertToReadableDuration={convertToReadableDuration}
                  handleDurationPicker={handleDurationPicker}
                  setnewlength={setnewlength}
                  setHourPicker={setHourPicker}
                  hourPicker={hourPicker}
                  handleHourPicker={handleHourPicker}
                  appointmentType={appointmentType}
                  error={errorValue}
                />
                {timepickerShow ? (
                  <TimePicker
                    timepickerShow={timepickerShow}
                    handleTimePicker={handleTimePicker}
                    bookingData={bookingData}
                    setBookingData={setBookingData}
                    timeData={timeData}
                    setTime={setTime}
                  />
                ) : null}
                {durationPicker ? (
                  <DurationPicker
                    durationPicker={durationPicker}
                    setDurationPicker={setDurationPicker}
                    durationData={durationData}
                    handleDurationPicker={handleDurationPicker}
                    setDuration={setDuration}
                    setBookingData={setBookingData}
                    convertToReadableDuration={convertToReadableDuration}
                    bookingData={bookingData}
                    subcategory={null}
                    setSubCategory={null}
                  />
                ) : null}
                {hourPicker ? (
                  <HourPicker
                    setHourPicker={setHourPicker}
                    handleHourPicker={handleHourPicker}
                    hourPicker={hourPicker}
                    hourData={hourData}
                    handleDurationPicker={handleDurationPicker}
                    setHourData={setHourData}
                    setBookingData={setBookingData}
                    convertToReadableDuration={convertToReadableDuration}
                    bookingData={bookingData}
                    subcategory={null}
                    setSubCategory={null}
                  />
                ) : null}
                {deletePopUp && (
                <DeletePopUp
                  // additionalContactDetails={additionalContactDetails}
                  handlePopUpChange={handlePopUpChange}
                  setDeletePopUp={setDeletePopUp}
                  // deletePopUp={deletePopUp}
                  PopUpText={popUpText}
                  paraText={paraText}
                />
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
EditInvoice.propTypes = {
  invoiceData: PropTypes.func.isRequired,
  setEditInvoice: PropTypes.bool.isRequired,
  viewtype: PropTypes.string.isRequired,
  customerAllDue: PropTypes.number.isRequired,
  searchType: PropTypes.string.isRequired,
  frompage: PropTypes.string.isRequired,
};
export default EditInvoice;
