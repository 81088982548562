import React, { useState, useEffect, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { userInstance } from '../../config/axios';
import arrow from '../../assets/home/arrowdown.png';
import search from '../../assets/home/search.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const SearchInventory = ({ handleSearch, updateView, setSearchData }) => {
  const { userData: { industryId } } = useContext(AuthDataContext);
  const [checked, setchecked] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([]);
  const [productsListOnly, setProductsListOnly] = useState([]);

  const [recOrder, setOrder] = useState('Product Name (A-Z)');
  const formData = {
    locationId: '',
    memberName: '',
    productId: '',
    // supplier: '',
    sortProductOption: { name: 1 },
    showOutOfStock: checked,
  };
  const [filterObj, setFilterObj] = useState(formData);
  const handleOnchange = (e) => {
    let { name, value } = e || {};
    console.log('onChnage', name, value);
    if (name === 'sortProductOption') {
      setOrder(value);
      if (value === 'Date Created (new-old)') value = { createdAt: -1 };
      else if (value === 'Date Created (old-new)') value = { createdAt: 1 };
      else if (value === 'Date Updated (new-old)') value = { updatedAt: -1 };
      else if (value === 'Date Updated (old-new)') value = { updatedAt: 1 };
      else if (value === 'Product Group Name (A-Z)') value = { productCode: 1 };
      else if (value === 'Product Group Name (Z-A)') value = { productCode: -1 };
      else if (value === 'Product Name (A-Z)') value = { name: 1 };
      else if (value === 'Product Name (Z-A)') value = { name: -1 };
      else if (value === 'Supplier (A-Z)') value = { supplier: 1 };
      else if (value === 'Supplier (Z-A)') value = { supplier: -1 };
      else if (value === 'Buy Cost (low-high)') value = { cost: 1 };
      else if (value === 'Buy Cost (high-low)') value = { cost: -1 };
      else if (value === 'Sell Price (low-high)') value = { retailCost: 1 };
      else if (value === 'Sell Price (high-low)') value = { retailCost: -1 };
      else if (value === 'Stock Level (low-high)') value = { currentStockItems: 1 };
      else if (value === 'Stock Level (high-low)') value = { currentStockItems: -1 };
      let copyfilterObj = { ...filterObj, [name]: value };
      setFilterObj(copyfilterObj);
    } else {
      let copyfilterObj = { ...filterObj, [name]: value };
      setFilterObj(copyfilterObj);
    }
  };
  const getteammember = async () => {
    const response = await userInstance().get(
      '/appointment/getTeamMemberList',
    );
    const { teamList, code } = response.data;
    if (code === 200) {
      setTeamMemberData(teamList);
      // setfilterStatus(teamList);
    }
  };

  const getLocation = async () => {
    const response = await userInstance().get(
      '/appointment/getLocationList',
    );
    const { locationList, code } = response.data;
    locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
    if (code === 200) {
      setLocationData([...locationList]);
    }
  };

  useEffect(() => {
    getteammember();
    getLocation();
  }, []);

  useEffect(() => {
    const getProductsList = async () => {
      const res = await userInstance().get('/inventory/getInventoryListOnly');
      if (res.data.code === 200) {
        setProductsListOnly(res.data.results);
      }
    };
    getProductsList();
  }, []);

  // ---------------------------------------search listin----------------------
  const SearchInventoryData = async () => {
    const payLoad = {
      productId: filterObj.productId,
      // supplier: filterObj.supplier,
      sortProductOption: filterObj.sortProductOption,
      member: filterObj.memberName,
      showOutOfStock: checked,
      industryId: industryId?._id,
    };
    const response = await userInstance().get(`/inventory/getFilterInventory/?payload=${JSON.stringify(payLoad)}`);
    const { data, status } = response;
    if (status === 200) {
      setSearchData({ data: data?.data, payload: filterObj });
      updateView('InventorySearchResult');
    }
  };
  const sortOptions = [
    { value: 'Product Name (A-Z)' },
    { value: 'Product Name (Z-A)' },
    { value: 'Stock Level (low-high)' },
    { value: 'Stock Level (high-low)' },
    { value: 'Buy Cost (low-high)' },
    { value: 'Buy Cost (high-low)' },
    // { value: 'Date Created (new-old)' },
    // { value: 'Date Created (old-new)' },
    // { value: 'Date Updated (new-old)' },
    // { value: 'Date Updated (old-new)' },
    // { value: 'Product Group Name (A-Z)' },
    // { value: 'Product Group Name (Z-A)' },
    // { value: 'Supplier (A-Z)' },
    // { value: 'Supplier (Z-A)' },
    // { value: 'Sell Price (low-high)' },
    // { value: 'Sell Price (high-low)' },
  ];
  return (
    <>
      <div className="invoice-detail search-invoice search-inventory">
        <div className="pool-setup">
          <div className="pool-chemistry-box appointment-service-modal">
            <h5>Inventory Search </h5>
            <div className="search-invoces-box">
              <div className="InventorySearch-form">
                <div className="searchInner">
                  <h6>Search Criteria</h6>
                  <div
                    className="outstandings-invoices"
                    onClick={() => {
                      setchecked(!checked);
                    }}
                  >
                    <p>Show Out of Stock Only</p>
                    <div className="open-date client-notes">
                      <div className="basic">
                        <Form.Check
                          type="checkbox"
                          checked={checked ? true : ''}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Form.Group controlId="formBasicCompanyType">
                  <Form.Label>Location</Form.Label>
                  <div className="select-box">
                    <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="locationId" value={filterObj.locationId}>
                      <option value="">All Locations</option>
                      {locationData.map((el) => <option value={el._id}>{el?.locationName}</option>)}
                    </Form.Control>
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasicCompanyType">
                  <Form.Label>Team Member</Form.Label>
                  <div className="select-box">
                    <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="memberName" value={filterObj.memberName}>
                      <option value="">All Team Members</option>
                      {teamMemberData.map((el) => (
                        <>
                          {filterObj.locationId === '' ? <option value={el?.firstname}>{`${el?.firstname} ${el?.lastname}`}</option> : filterObj?.locationId === el?.location && <option value={el?.firstname}>{`${el?.firstname} ${el.lastname}`}</option>}
                        </>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group> */}
                <Form.Group controlId="formBasicHiddenEmployees">
                  <Form.Label>Search Display Order</Form.Label>
                  <div className="select-box">
                    <i className="fas fa-sort-down" />
                    <Form.Control
                      as="select"
                      name="sortProductOption"
                      value={recOrder}
                      onChange={(e) => handleOnchange(e.target)}
                    >
                      {' '}
                      {sortOptions.map((el, i) => (
                        <>
                          <option key={el.value} value={el.value}>{el.value}</option>
                        </>
                      ))}

                    </Form.Control>
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasicHiddenEmployees">
                  <Form.Label>
                    Product Name
                  </Form.Label>
                  <div className="select-box">
                    <i className="fas fa-sort-down" />
                    <Form.Control
                      as="select"
                      name="productId"
                      value={filterObj.productId}
                      onChange={(e) => handleOnchange(e.target)}
                    >
                      <option value="">Select Product</option>
                      {productsListOnly.map((ele) => (
                        <>
                          <option key={ele?._id} value={ele?._id}>{ele?.renderName}</option>
                        </>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
                {/* <Form.Group controlId="formBasicHiddenEmployees">
                  <Form.Label>Supplier</Form.Label>
                  <Form.Group className="search-box">
                    <Form.Control
                      type="text"
                      placeholder="Search by Supplier"
                      name="supplier"
                      value={filterObj.supplier}
                      onChange={(e) => handleOnchange(e.target)}
                    />
                    <img src={search} alt="search" />
                  </Form.Group>
                </Form.Group> */}

              </div>

              <p className="group-time">* Less fields filled-in will broaden the search.</p>
              <div className="row">
                <div className="col-md-12">
                  <div className="view-btn search-invoice-btn">
                    <Button
                      className="btn-save"
                      onClick={SearchInventoryData}
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

SearchInventory.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
  setSearchData: PropTypes.func.isRequired,
};
export default SearchInventory;
