import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from '../../functions/moment';

const InvoiceDetail = ({ detailedInvoice }) => {
  const {
    invoiceNo, createdAt, appointment,
    invoiceTotal, balanceDue,
    amountApplied,
    chemicalsAdded,
    gst,
    msg,
    invoiceSubTotal,
    invoiceType,
    paymentId,
    customer,
  } = detailedInvoice;
  const [appointmentData, setAppointmnetData] = useState(appointment);
  const chemicalArray = [{
    chemicalName: 'Add 1.5kg of Granular Chlorine',
    cost: 20,
  }, {
    chemicalName: 'Add 400 grams if Soda Ash',
    cost: 15,
  },
  {
    chemicalName: 'Add 7.7kg of Buffer',
    cost: 170,
  },
  {
    chemicalName: 'Add 13.9kg of Calcium Raiser',
    cost: 225,
  },
  {
    chemicalName: 'Add 1.6kg of Stabliser',
    cost: 45,
  },
  {
    chemicalName: 'Add 8 Bags of Salt(20kg)',
    cost: 240,
  },
  ];
  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msgg = '';
    msgg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msgg;
  };

  const chooseStaff = (info) => {
    let staffMember;
    const splitName = info.split(' ');
    const firstName = splitName[0];
    if (msg === 'No name to appear in Invoice detail') {
      staffMember = '';
    } else if (msg === 'First name only to appear in Invoice detail') {
      staffMember = firstName;
    } else if (msg === 'First & last name to appear in Invoice detail') {
      staffMember = info;
    } else if (msg === 'Initials only to appear in Invoice detail') {
      staffMember = '';
    }
    return staffMember;
  };

  useEffect(() => {
    setAppointmnetData(appointment);
  }, [appointment]);
  // useEffect(() => {
  //   checkInvoiceType();
  // }, []);

  // const checkInvoiceType = () => {
  //   if (invoiceType === 'combined') {
  //     const unique = [];
  //     let old = [...appointmentData];
  //     const valueArr = old.map((item) => item.subcategory);
  //     const isDuplicate = valueArr.some((item, idx) => valueArr.indexOf(item) !== idx);
  //     if (isDuplicate) {
  //       old.map((x) => (unique.filter((a) => a.subcategory === x.subcategory).length > 0 ? null : unique.push(x)));
  //       // console.log(unique);
  //       unique.cost = '200';
  //       old = unique;
  //       old.cost = 2 * 100;
  //       setAppointmnetData(old);
  //     }
  //   } else {
  //     setAppointmnetData(appointment);
  //   }
  // };

  return (
    <div className="invoice-detail">
      <div className="pool-setup">
        <div className="target-heading">
          <h6>Invoice Detail</h6>
        </div>
        <div className="invoices-detail-table pool-chemistry-box">
          <h6 className="invoice-no">
            Invoice No:
            {invoiceNo}
          </h6>
          <h6 className="invoice-no">
            Date:
            {moment(createdAt).format('D MMMM')}
            ,
            {moment(createdAt).format('YYYY')}
          </h6>
          <h6>Bill To: </h6>
          {customer && (
          <h6>
            {' '}
            {`${customer.firstname} ${customer.lastname}`}
            -
            {`${customer.streetAddress}${customer.suburb || customer.state ? ' ,' : ''} ${customer.suburb
            } ${customer.state}`}
          </h6>
          )}
          {/* <h6>Hawthorn Victoria 3122</h6> */}
          <div className="invoice-description">
            <div className="head-des">
              <div className="left-description">
                <h6>Description</h6>
              </div>
              <div className="right-description">
                <h6>Goods/Services Cost</h6>
              </div>
            </div>
            <div className="body-desc">
              {/* {console.log('appointmentdata--', appointmentData)} */}
              {appointmentData && appointmentData.map((app) => (
                <>
                  {app.dateData && app.dateData.map((el) => (
                    <>
                      {el.service && el.service.map((service) => (
                        <>
                          <div className="body-description labour-invoice-cost">

                            <div className="margin-cost-invoice">
                              <p>
                                {service.description}
                                {' '}
                                |
                                {' '}
                                {` ${convertToReadableDuration(service.duration)}`}
                                {' '}
                                |
                              </p>
                              <p>
                                {' '}
                                {/* {service.staffName} */}
                                {chooseStaff(service.staffName)}
                              </p>
                            </div>
                            <h6>
                              $
                              {service.cost}
                            </h6>
                          </div>
                        </>
                      ))}
                    </>
                  ))}
                  {app.chemicalsArray && (
                  <>
                    <div className="body-description labour-invoice-cost">
                      <p>
                        CHEMICALS ADDED
                      </p>
                    </div>
                    {/* {console.log('app.chemicalsArray', app.chemicalsArray) } */}
                    {app?.chemicalsArray.length > 0 && app?.chemicalsArray[0]?.testResult?.map((el) => el?.service.map((chem) => (
                      <div className="body-description">
                        <p>
                          -
                          {' '}
                          {chem.subcategory}
                        </p>
                        <h6>
                          $
                          {parseFloat(chem.renderCost).toFixed(2)}
                        </h6>
                      </div>
                    )))}
                  </>
                  )}
                </>
              ))}

            </div>
            <div className="invoice-total">
              <div className="body-description">
                <h6>
                  Sub Total:
                </h6>
                <h6>
                  $
                  {invoiceSubTotal}
                </h6>
              </div>
              <div className="body-description">
                <h6>
                  GST:
                </h6>
                <h6>
                  $
                  {gst}
                </h6>
              </div>
              <div className="body-description">
                <h6>
                  Invoice Total:
                </h6>
                <h6>
                  $
                  {invoiceTotal}
                </h6>
              </div>
            </div>
          </div>
          {/* <p className="labour-cost">
            Labour & Margin: $
            {app && app.totalData.totalLabour}
          </p> */}

          <div className="payment-detail">
            <h6 className="payment-heading">Payment Detail</h6>
            <div className="head-des">
              <div className="left-description">
                <h6>Invoice Total: </h6>
              </div>
              <div className="right-description">
                <h6>
                  $
                  {invoiceTotal}
                </h6>
              </div>
            </div>
            <div className="body-description">
              <p>
                Payment received
                {' '}
                <br />
                by
                {' '}
                {paymentId && paymentId.paymentMethod}
                {' '}
                {moment(paymentId && paymentId.createdAt).format('D MMMM')}
                ,
                {moment(paymentId && paymentId.createdAt).format('YYYY')}
              </p>
              <p>
                $
                {paymentId && paymentId.amount}
              </p>
            </div>
            <div className="body-description">
              <p>
                Balance Due:
              </p>
              <p>
                $
                {balanceDue}
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
InvoiceDetail.propTypes = {
  detailedInvoice: PropTypes.func.isRequired,
};
export default InvoiceDetail;
