import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';

const WaterSelectionDatePopup = ({
  setShowWaterSelectionPopup, handleWaterTestApp, handleCancel, handleSelect, selectBoxes,
}) => (
  <div
    className="duration-picker time-picker-modal update-source-popup existing-maintanance-popup"
  >
    <div>
      <div className="time-keeper-popup duration-popup event-popup">
        <div className="appointment-view">
          <div className="pending-water-test" onClick={() => handleWaterTestApp('yes')}>
            <h3>
              Add to Existing Scheduled Maintenance
            </h3>
            <div className="open-date client-notes">
              <div className="basic">
                <Form.Check
                  type="checkbox"
                  name="chemicalsAdded"
                  checked={selectBoxes === 'yes'}
                />
              </div>
            </div>
          </div>
          <div className="pending-water-test" onClick={() => handleWaterTestApp('no')}>
            <h3>Create New Scheduled Maintenance</h3>
            <div className="open-date client-notes">
              <div className="basic">
                <Form.Check
                  type="checkbox"
                  name="editQuantity"
                  checked={selectBoxes === 'no'}
                />
              </div>
            </div>
          </div>
          <div className="event-popup-btn footer-btn view-btn">
            <Button className="appointment-btn btn-edit" type="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="button" className="appointment-btn btn-save" onClick={handleSelect}>
              Select
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);
WaterSelectionDatePopup.propTypes = {
  setShowWaterSelectionPopup: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleWaterTestApp: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  selectBoxes: PropTypes.string.isRequired,
};
export default WaterSelectionDatePopup;
