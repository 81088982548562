/* eslint-disable import/no-unresolved */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const StartEndDate = (props) => {
  const {
    datePopup, setDatePopup, handleDatePopup, handleDateOnchange, dateRange, handleDateRange,
  } = props;
  return (
    <Modal
      size="md"
      show={datePopup}
      onHide={() => setDatePopup(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="startdate-modal calendar-modal search-modal time-popup custom-time-popup"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="search-popup shift-container startend-popup">
          <div className="time-calender">
            <h6>Start Date</h6>
            <Calendar onChange={(e) => handleDateOnchange(e, 'start')} value={dateRange ? new Date(dateRange.start) : new Date()} />
            <h6 className="end-date">End Date</h6>
            <Calendar
              onChange={(e) => handleDateOnchange(e, 'end')}
              value={dateRange ? new Date(dateRange.end) : new Date()}
              minDate={dateRange ? new Date(dateRange.start) : new Date()}
            />
            <div className="view-btn">
              <Button
                type="Submit"
                className="btn btn-edit"
                onClick={handleDatePopup}
              >
                {' '}
                Cancel
                {' '}
              </Button>
              <Button
                type="Submit"
                className="btn btn-save"
                onClick={() => handleDateRange(dateRange.start, dateRange.end)}
              >
                {' '}
                Save/Update
                {' '}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
StartEndDate.propTypes = {
  datePopup: PropTypes.func.isRequired,
  setDatePopup: PropTypes.func.isRequired,
  handleDatePopup: PropTypes.func.isRequired,
  handleDateOnchange: PropTypes.func.isRequired,
  dateRange: PropTypes.func.isRequired,
  handleDateRange: PropTypes.func.isRequired,
};
export default StartEndDate;
