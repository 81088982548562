/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Table,
  Button,
  Dropdown,
  Form,
  Accordion,
  Card,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from '../../functions/moment';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import StartDateModal from './startdatemodal';
import EndDateModal from './enddatemodal';
import DorpdownModal from './dropdownModal';
import left1 from '../../assets/home/left1.png';
import left2 from '../../assets/home/left2.png';
import left3 from '../../assets/home/left3.png';
import right1 from '../../assets/home/right1.png';
import right2 from '../../assets/home/right2.png';
import right3 from '../../assets/home/right3.png';
import CalendarPopup from './calendarPopup';

const StaffModal = (props) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const {
    showTable,
    HandleOpenCloseTable,
    staffData,
    showStartDate,
    setShowStartDate,
    showEndDate,
    setShowEndDate,
    handleRepeatDropdown,
    setRepeatDates,
    deleteStaff,
    handleDelete,
    handleCalendar,
    handleAfterRange,
    handleBeforeRange,
    firstDay,
    lastDay,
    weeklist,
    editWorking,
    handleEditWorking,
    updateMonth,
    updateYear,
    staffList,
    HandleOpenCloseDropdown,
    showDropdownModal,
    setShowDropdown,
    selectedDay,
    renderSpecificDate,
    setRender,
    selectedShift,
    handleShiftChange,
    updateRepeatStaff,
    repeatStaff,
    updateCurrentShift,
    updateAllShift,
    deleteCurrentShift,
    deleteUpcomingShift,
    handleTimePicker,
    timepicker,
    type,
  } = props;

  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedNestedAccordion, setSelectedNestedAccordion] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarData, setCalendarData] = useState({
    currentDate: '',
    currentWeekNo: '',
    startDate: '',
    endDate: '',
  });
  const handleCalendarChange = (e) => {
    const date = moment(e).format('YYYY-MM-DD');
    setCalendarData({ ...calendarData, currentDate: date });
    setCalendarData({ ...calendarData, currentWeekNo: date });
    setShowCalendar(false);
  };
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  const getTimes = () => {
    const locale = 'en'; // or whatever you want...
    const hours = [];

    moment.locale(locale); // optional - can remove if you are only dealing with one locale

    // eslint-disable-next-line no-plusplus
    for (let hour = 0; hour < 24; hour++) {
      hours.push(moment({ hour }).format('h:mm A'));
      hours.push(
        moment({
          hour,
          minute: 15,
        }).format('h:mm A'),
      );
    }
    return hours;
  };

  const getRepeatVal = (value) => {
    let msg;
    if (value === 'Specific Date') {
      msg = value;
    } else if (value === 'dont-repeat') {
      msg = "Don't Repeat";
    } else if (value === 'ongoing') {
      msg = 'Ongoing';
    }
    return msg;
  };

  const handleSelectStaff = (e) => {
    const id = e.target.value;
    if (id !== '0') {
      const filteredStaff = staffList.filter((el) => el._id === id);
      setSelectedStaff([...filteredStaff]);
    } else {
      setSelectedStaff([...staffList]);
    }
  };

  const checkForTimings = (week, el) => {
    // console.log('el shift', el.shift);
    let jsx = (
      // <td className="text-center">
      //   <Button
      //     className="check-btn"
      //     onClick={() => HandleOpenCloseDropdown({
      //       date: week.date, _id: el._id, shift: el.shift,
      //     })}
      //   >
      //     <i className="fas fa-plus-circle" />
      //   </Button>
      // </td>
      <h6
        onClick={() => HandleOpenCloseDropdown({
          date: week.date,
          _id: el._id,
          shift: el.shift,
        })}
      >
        9.00 AM to 5.00 PM
      </h6>
    );
    if (el.shift.length) {
      const dayData = el.shift.filter(
        (day) => +new Date(day.date) === +week.date,
      );
      const staff = dayData.length ? dayData[0] : null;
      // console.log('staff', staff, 'current day', week.date);
      if (!staff) {
        const day = days[week.date.getDay()];
        // console.log('day', day);
        // console.log('date from up', week.date);
        const chkShiftForDay = el.shift.filter(
          (dateData) => days[new Date(dateData.date).getDay()] === day
            && dateData.endRepeat === 'Ongoing'
            && new Date(week.date) >= new Date(dateData.date),
        );
        // console.log('chkShiftForDay', chkShiftForDay);
        if (chkShiftForDay.length) {
          const currentDate = new Date(week.date);
          for (let i = 0; i < chkShiftForDay.length; i++) {
            // const { endRepeat } = chkShiftForDay[i];
            // console.log('endrepeat', endRepeat);
            const currentIDate = new Date(chkShiftForDay[i].date);
            const nextDate = chkShiftForDay[i + 1]
              ? new Date(chkShiftForDay[i + 1].date)
              : null;
            // console.log('currentDate', currentDate, 'nextDate', nextDate);
            if (
              currentDate > currentIDate
              && (currentDate < nextDate || !nextDate)
            ) {
              const staffData = {
                repeats: 'Weekly',
                endRepeat: 'Ongoing',
                timing: {
                  startTime: chkShiftForDay[i].timing.startTime,
                  endTime: chkShiftForDay[i].timing.endTime,
                },
              };
              jsx = (
                <h6
                  className="check-btn"
                  onClick={() => HandleOpenCloseDropdown({
                    date: week.date,
                    _id: el._id,
                    shift: el.shift,
                    staff: staffData,
                  })}
                >
                  {`${
                    chkShiftForDay[i].timing.startTime
                      ? chkShiftForDay[i].timing.startTime
                      : '-'
                  } to ${
                    chkShiftForDay[i].timing.endTime
                      ? chkShiftForDay[i].timing.endTime
                      : '-'
                  }`}
                </h6>
              );
              break;
            }
          }
        } else {
          const getEndingDate = el.shift.filter(
            (dateData) => dateData.repeats === 'Weekly'
              && dateData.endRepeat !== 'Ongoing'
              && dateData.endRepeat,
          );
          // console.log('getEndingDate', getEndingDate);
          if (getEndingDate.length) {
            const currentDate = new Date(week.date);
            for (let i = 0; i < getEndingDate.length; i++) {
              const { endRepeat, weeklyOngoing } = getEndingDate[i];
              // console.log('endrepeat', endRepeat);
              const currentIDate = new Date(getEndingDate[i].date);
              // console.log('currentDate', currentDate, 'nextDate', nextDate);
              if (
                currentDate >= currentIDate
                && currentDate <= new Date(endRepeat)
              ) {
                const staffData = {
                  repeats: "Don't Repeat",
                  endRepeat: null,
                  timing: {
                    startTime: getEndingDate[i].timing.startTime,
                    endTime: getEndingDate[i].timing.endTime,
                  },
                };
                if (weeklyOngoing) {
                  if (days[currentIDate.getDay()] === day) {
                    jsx = (
                      <td className="text-center">
                        <Button
                          className="check-btn"
                          onClick={() => HandleOpenCloseDropdown({
                            date: week.date,
                            _id: el._id,
                            shift: el.shift,
                            staff: staffData,
                          })}
                        >
                          {`${
                            getEndingDate[i].timing.startTime
                              ? getEndingDate[i].timing.startTime
                              : '-'
                          } to ${
                            getEndingDate[i].timing.endTime
                              ? getEndingDate[i].timing.endTime
                              : '-'
                          }`}
                        </Button>
                      </td>
                    );
                  }
                } else {
                  jsx = (
                    <td className="text-center">
                      <Button
                        className="check-btn"
                        onClick={() => HandleOpenCloseDropdown({
                          date: week.date,
                          _id: el._id,
                          shift: el.shift,
                          staff: staffData,
                        })}
                      >
                        {`${
                          getEndingDate[i].timing.startTime
                            ? getEndingDate[i].timing.startTime
                            : '-'
                        } to ${
                          getEndingDate[i].timing.endTime
                            ? getEndingDate[i].timing.endTime
                            : '-'
                        }`}
                      </Button>
                    </td>
                  );
                }
              }
            }
          }
        }
        // console.log('chkShiftForDay', chkShiftForDay);
      } else {
        // console.log('date', week.date);
        jsx = (
          <td className="text-center">
            <Button
              className="check-btn"
              onClick={() => HandleOpenCloseDropdown({
                date: week.date,
                _id: el._id,
                shift: el.shift,
                staff,
              })}
            >
              {`${staff.timing.startTime ? staff.timing.startTime : '-'} to ${
                staff.timing.endTime ? staff.timing.endTime : '-'
              }`}
            </Button>
          </td>
        );
      }
    }
    return jsx;
  };

  const handleNestedAccordion = (e) => {
    if (selectedNestedAccordion && selectedNestedAccordion.includes(e)) {
      const data = selectedNestedAccordion.filter((el) => el !== e);
      return setSelectedNestedAccordion(data);
    }
    return setSelectedNestedAccordion([...selectedNestedAccordion, e]);
  };

  useEffect(() => {
    setSelectedStaff([...staffList]);
    handleCalendarChange();
  }, [staffList]);

  const handleShowCalendar = () => {
    setShowCalendar(!showCalendar);
  };
  return (
    <>
      <Modal
        size="xl"
        show={showTable}
        onHide={HandleOpenCloseTable}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="staff-modal"
      >
        <div className="staff-close">
          <i
            className="fa fa-times"
            onClick={HandleOpenCloseTable}
            aria-hidden="true"
          />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Working Hours
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="schedule-filters">
            <div className="select-wrapper">
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Select Staff</Form.Label>
                <Form.Control as="select" onChange={handleSelectStaff}>
                  <option value="0">All Staff</option>
                  {staffList.map((el) => (
                    <option
                      value={el._id}
                    >
                      {`${el.firstname} ${el.lastname}`}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="schedule-date-toolbar">
              <div className="date-range">
                <h3>Date Range</h3>
              </div>
              <div className="calendar-date-client">
                <div className="calendar-filter-prev">
                  <div className="calendar-filter-icons">
                    <span>
                      <img
                        src={left3}
                        alt="arrow"
                        onClick={() => updateYear('prev')}
                      />
                    </span>
                    <span>
                      <img
                        src={left2}
                        alt="arrow"
                        onClick={() => updateMonth('prev')}
                      />
                    </span>
                    <span>
                      <img
                        src={left1}
                        alt="arrow"
                        onClick={handleBeforeRange}
                      />
                    </span>
                  </div>
                </div>
                <div
                  className="date"
                  onClick={handleShowCalendar}
                  role="presentation"
                >
                  {/* {firstDay.substring(4, 16)} */}
                  {moment(firstDay).format('DD MMM YYYY')}
                  {' '}
                  to
                  {' '}
                  <br />
                  {moment(lastDay).format('DD MMM YYYY')}
                  {/* {lastDay.substring(4, 16)} */}
                </div>

                <div className="calendar-filter-next">
                  <div className="calendar-filter-icons">
                    <span>
                      <img
                        src={right1}
                        alt="arrow"
                        onClick={handleAfterRange}
                      />
                    </span>
                    <span>
                      <img
                        src={right2}
                        alt="arrow"
                        onClick={() => updateMonth('next')}
                      />
                    </span>
                    <span>
                      <img
                        src={right3}
                        alt="arrow"
                        onClick={() => updateYear('next')}
                      />
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="btn-group js-date-toolbar">
                <div
                  className="btn btn-default navigate"
                  data-action="previous"
                  title="Previous"
                >
                  <div className="arrow-icons">
                    <span onClick={() => updateYear('prev')}>
                      <i className="fas fa-chevron-left" />
                      <i className="fas fa-chevron-left" />
                      <i className="fas fa-chevron-left" />
                    </span>
                    <span onClick={() => updateMonth('prev')}>
                      <i className="fas fa-chevron-left" />
                      <i className="fas fa-chevron-left" />
                    </span>

                    <span onClick={handleBeforeRange}>
                      <i className="fas fa-chevron-left" />
                    </span>
                  </div>
                </div>

                <div
                  className="btn btn-default select-date"
                  title="Change Date"
                >
                  <span className="display-date" id="birthday">
                    {firstDay.substring(4, 16)}
                    {' '}
                    -
                    {lastDay.substring(4, 16)}
                  </span>
                  <Form.Control
                    type="date"
                    id="birthday"
                    name="birthday"
                    onChange={(e) => handleCalendar(e.target.value)}
                  />
                </div>
                <div
                  className="btn btn-default navigate"
                  data-action="next"
                  title="Next"
                >
                  <div className="arrow-icons">
                    <span onClick={handleAfterRange}>
                      <i className="fas fa-chevron-right" />
                    </span>
                    <span onClick={() => updateMonth('next')}>
                      <i className="fas fa-chevron-right" />
                      <i className="fas fa-chevron-right" />
                    </span>
                    <span onClick={() => updateYear('next')}>
                      <i className="fas fa-chevron-right" />
                      <i className="fas fa-chevron-right" />
                      <i className="fas fa-chevron-right" />
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className={`datetime-table ${!editWorking ? 'hideEdit' : ''}`}>
            <Table striped hover bordered responsive className="head-table">
              <thead>
                <tr>
                  <th>Staff Member</th>
                  {weeklist.map((el) => (
                    <th>{new Date(el.date).toString().substring(0, 10)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {selectedStaff.map((el) => (
                  <tr>
                    <td className="text-left">
                      {`${el.firstname} ${el.lastname}`}
                    </td>
                    {weeklist.map((week) => checkForTimings(week, el))}
                  </tr>
                ))}
              </tbody>
            </Table>

            <div className="mob-table">
              {selectedStaff.map((el, i) => (
                <Accordion defaultActiveKey="0" className="mob-accordion">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={i.toString()}
                        onClick={() => handleNestedAccordion(i)}
                      >
                        {/* <i className="fas fa-chevron-circle-down" /> */}
                        <i
                          className={`fas ${
                            selectedNestedAccordion.includes(i)
                              ? 'far fa-chevron-circle-down'
                              : 'far fa-chevron-circle-up'
                          }`}
                        />
                        <span>{`${el.firstname} ${el.lastname}`}</span>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={i.toString()}>
                      <Card.Body>
                        <ul className="staff-list">
                          {weeklist.map((week) => (
                            <li className="staff-list-item">
                              <div className="staff-box">
                                <h3>
                                  {new Date(week.date)
                                    .toString()
                                    .substring(0, 10)}
                                </h3>
                                <h5>{checkForTimings(week, el)}</h5>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              ))}
            </div>
            {/* <div className="view-btn">
              {!editWorking ? <Button type="submit" className="btn edit-btn" onClick={() => setEditWorking(true)}>Edit</Button> : null}
              {editWorking ? <Button type="submit" className="btn edit-btn" onClick={() => setEditWorking(false)}>Cancel</Button> : null}
              {editWorking ? <Button type="submit" className="btn btn-save" onClick={handleEditWorking}>Save</Button> : null}
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
      <DorpdownModal
        showDropdownModal={showDropdownModal}
        HandleOpenCloseDropdown={HandleOpenCloseDropdown}
        setShowDropdown={setShowDropdown}
        getTimes={getTimes}
        setRepeatDates={setRepeatDates}
        handleRepeatDropdown={handleRepeatDropdown}
        handleEditWorking={handleEditWorking}
        getRepeatVal={getRepeatVal}
        staffData={staffData}
        selectedDay={selectedDay}
        renderSpecificDate={renderSpecificDate}
        setRender={setRender}
        selectedShift={selectedShift}
        handleShiftChange={handleShiftChange}
        updateRepeatStaff={updateRepeatStaff}
        repeatStaff={repeatStaff}
        updateCurrentShift={updateCurrentShift}
        updateAllShift={updateAllShift}
        handleDelete={handleDelete}
        deleteStaff={deleteStaff}
        deleteCurrentShift={deleteCurrentShift}
        deleteUpcomingShift={deleteUpcomingShift}
        handleTimePicker={handleTimePicker}
        timepicker={timepicker}
        type={type}
      />
      <StartDateModal
        showStartDate={showStartDate}
        setShowStartDate={setShowStartDate}
        setRepeatDates={setRepeatDates}
      />
      <EndDateModal
        showEndDate={showEndDate}
        setShowEndDate={setShowEndDate}
        setRepeatDates={setRepeatDates}
        handleShiftChange={handleShiftChange}
      />
      <CalendarPopup
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
        handleCalendarChange={handleCalendarChange}
        handleCalendar={handleCalendar}
      />
    </>
  );
};
StaffModal.propTypes = {
  showTable: PropTypes.func.isRequired,
  HandleOpenCloseTable: PropTypes.func.isRequired,
  staffData: PropTypes.func.isRequired,
  showStartDate: PropTypes.func.isRequired,
  setShowStartDate: PropTypes.func.isRequired,
  showEndDate: PropTypes.func.isRequired,
  setShowEndDate: PropTypes.func.isRequired,
  handleRepeatDropdown: PropTypes.func.isRequired,
  setRepeatDates: PropTypes.func.isRequired,
  handleAfterRange: PropTypes.func.isRequired,
  handleBeforeRange: PropTypes.func.isRequired,
  handleCalendar: PropTypes.func.isRequired,
  firstDay: PropTypes.func.isRequired,
  lastDay: PropTypes.func.isRequired,
  weeklist: PropTypes.func.isRequired,
  editWorking: PropTypes.func.isRequired,
  handleEditWorking: PropTypes.func.isRequired,
  updateMonth: PropTypes.func.isRequired,
  updateYear: PropTypes.func.isRequired,
  staffList: PropTypes.func.isRequired,
  HandleOpenCloseDropdown: PropTypes.func.isRequired,
  showDropdownModal: PropTypes.func.isRequired,
  setShowDropdown: PropTypes.func.isRequired,
  selectedDay: PropTypes.func.isRequired,
  renderSpecificDate: PropTypes.func.isRequired,
  setRender: PropTypes.func.isRequired,
  selectedShift: PropTypes.func.isRequired,
  handleShiftChange: PropTypes.func.isRequired,
  repeatStaff: PropTypes.func.isRequired,
  updateRepeatStaff: PropTypes.func.isRequired,
  updateCurrentShift: PropTypes.func.isRequired,
  updateAllShift: PropTypes.func.isRequired,
  deleteStaff: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  deleteCurrentShift: PropTypes.func.isRequired,
  deleteUpcomingShift: PropTypes.func.isRequired,
  handleTimePicker: PropTypes.func.isRequired,
  timepicker: PropTypes.func.isRequired,
  type: PropTypes.func.isRequired,
};

export default StaffModal;
