import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  const { label, handleClickBtn, disabled } = props;
  return (
    <button
      type="button"
      style={{
        border: '1px solid #ccc',
        padding: '2px 12px',
        borderRadius: '8px',
        fontSize: '14px',
      }}
      onClick={handleClickBtn}
      disabled={disabled}
    >
      <span>{label}</span>
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  handleClickBtn: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Button;
