import { render } from '@testing-library/react';
import { labourCostCalculation, handleUnit, LoadExistValue } from './calculatorUtils';
import { handleTime, calculateEndingTimeArr, convertQuantUnitCalci } from '../../functions';
import { categoryObj } from './green_state';

export const greenChemicalAndLabour = (data, levelData) => {
  const copyLevelData = [...levelData];
  // console.log('data', data);
  const copyService = [];
  data.filter((el) => el.subcategory).forEach((el, index) => {
    // console.log('runningTime', el);
    const copyInitial = { ...categoryObj };
    copyInitial.buyCostProduct = el?.buyCostProduct;
    copyInitial.quantity = el.quantity;
    copyInitial.cost = el.cost;
    copyInitial.renderCost = el.renderCost;
    copyInitial.previousCost = el.previousCost;
    copyInitial.totalLabour = el.totalLabour;
    copyInitial.laborMargin = el.laborMargin;
    copyInitial.subcategory = el.subcategory;
    copyInitial.duration = el.duration;
    copyInitial.type = el.type;
    copyInitial.show = el.show;
    copyInitial.chemicalUnits = el.chemicalUnits || '';
    copyInitial.originalQuantity = el.originalQuantity || '';
    copyInitial.chemicalDisplayName = el.chemicalDisplayName || '';
    copyInitial.calciName = el.calciName || '';
    copyService.push(copyInitial);
  });
  console.log('copyService', copyService);
  copyLevelData[0].service = copyService;
};

export const chemicalNewDrainGreen = (drainQuantity, displayCost, drainTime, chemName) => ({
  drainPercent: drainQuantity, renderCost: displayCost, duration: drainTime, chemicalMsg: chemName,
});

export const chemicalDrainGreen = (updatedObj, drainStatus, stripExist) => {
  const copyInitial = { ...categoryObj };
  let {
    drainPercent, renderCost, duration, chemicalMsg,
  } = updatedObj;

  let displayCost = drainStatus ? '0' : renderCost / 1.1;
  if (renderCost === 'N/A') {
    displayCost = 'N/A';
    if (stripExist) {
      duration = '0';
    }
  } else {
    displayCost = (Math.ceil(LoadExistValue(displayCost) / 5) * 5).toFixed(2);
  }

  let msg = chemicalMsg || `Drain ${drainPercent} % of water`;
  copyInitial.buyCostProduct = displayCost;
  copyInitial.renderCost = displayCost;
  copyInitial.cost = '100.00';
  copyInitial.subcategory = displayCost ? msg : '';
  copyInitial.totalLabour = displayCost;
  copyInitial.show = 'visible';
  copyInitial.duration = duration;
  copyInitial.type = 'service';
  copyInitial.category = 'drain';

  return copyInitial;
};

const greenBuyCostFirstSecondDosage = (greenSplitName, greenQuantity, buyCost, greenSecondDosage, firstUnit, secondUnit, greenFirstUpdatedValue, greenSecondUpdatedValue, valueType) => {
  let msg = '';
  let secondMsg = '';
  let buyCostGreen = '';
  let buyCostGreenFirstDosage = '';
  let buyCostGreenSecondDosage = '';
  let renderGreenFirstUnit = '';
  let renderGreenSecondUnit = '';
  let renderChlorineUnique = '';

  const unitType = greenSplitName === 'Liquid' ? 'litres' : 'kg';
  let unit = unitType;
  let cheMsg = '';

  if (greenSplitName === 'Black-Spot') {
    cheMsg = `${greenSplitName} Remover`;
    renderChlorineUnique = 'BlackSpotAlgae';
  } else {
    cheMsg = `${greenSplitName} Chlorine`;
    if (greenSplitName === 'Liquid') {
      renderChlorineUnique = 'Liquid';
    } else {
      renderChlorineUnique = 'Granular';
    }
  }

  buyCostGreen = buyCost;
  if (greenSplitName !== 'Drain') {
    renderGreenFirstUnit = firstUnit === 'gm' ? 'grams' : firstUnit;
    renderGreenSecondUnit = secondUnit === 'gm' ? 'grams' : secondUnit;
    msg = `add ${greenFirstUpdatedValue} ${renderGreenFirstUnit} of ${cheMsg}`;
    secondMsg = `add ${greenSecondUpdatedValue} ${renderGreenSecondUnit} of ${cheMsg}`;

    if (!valueType) {
      if (firstUnit === 'gm' || firstUnit === 'kg') {
        const unitValue = firstUnit === 'gm' ? 'oz' : 'lb';
        msg = `Add ${greenFirstUpdatedValue} ${unitValue} of ${cheMsg}`;
        // if (greenFirstUpdatedValue.toString().includes('.') && greenFirstUpdatedValue > 1) {
        //   const splitValue = greenFirstUpdatedValue.split('.');
        //   msg = `Add ${splitValue[0]} lb ${splitValue[1]} oz  of ${cheMsg}`;
        //   renderGreenFirstUnit = 'lb';
        // }
        secondMsg = `Add ${greenSecondUpdatedValue} ${unitValue} of ${cheMsg}`;
        // if (greenSecondUpdatedValue.toString().includes('.') && greenSecondUpdatedValue > 1) {
        //   const splitValue = greenSecondUpdatedValue.split('.');
        //   secondMsg = `Add ${splitValue[0]} lb ${splitValue[1]} oz  of ${cheMsg}`;
        //   renderGreenSecondUnit = 'lb';
        // }
      } else {
        msg = `add ${greenFirstUpdatedValue} fl oz of ${cheMsg}`;
        secondMsg = `add ${greenSecondUpdatedValue} fl oz of ${cheMsg}`;
        renderGreenFirstUnit = 'fl oz';
        renderGreenSecondUnit = 'fl oz';
      }
    }

    buyCostGreenFirstDosage = buyCostGreen * parseFloat(greenQuantity);
    buyCostGreenSecondDosage = buyCostGreen * greenSecondDosage;
  }

  return {
    msg,
    secondMsg,
    buyCostGreen,
    buyCostGreenFirstDosage,
    buyCostGreenSecondDosage,
    renderGreenFirstDisplayName: cheMsg,
    renderGreenSecondDisplayName: cheMsg,
    renderGreenFirstUnit,
    renderGreenSecondUnit,
    renderChlorineUnique,
  };
};

const fetchingBlackData = (data) => {
  const obj = {};
  data.forEach((el) => {
    if (!el.chemicalName.includes('Initial')) {
      obj[`${el.chemicalName.split(' ')[0]}`] = el.dosageAndTime;
    }
  });
  obj.Labour = 0;
  return obj;
};

const poolTime = (value, poolCapacity) => {
  const answer = ((value * 1 / 40000) * poolCapacity).toFixed(1);
  return answer;
};

export const greenPoolAndDrainPoolCalculation = (data) => {
  const {
    greenSplitName, greenTreatment, clarifierGreenPoolQuantity, costClarifierGreen, quantityCostClarifierGreen, flockQuantity, costFlock, quantityCostFlock, serviceAnalysis, levelData, acidWashApiData,
    greenSelect, greenApiData, copyPoolInputs, calVariable, drainWaterHardness, drainWaterSalt, drainWaterChorine, drainWater, isDrainage, withDrainage, drainCopper, drainPercentWaterLevelLowered, valueType,
    costDrainPercentWaterLevelLowered, blackApiData, clarGreenUnits,
  } = data;
  const poolInputs = { ...copyPoolInputs };

  let copyLevelData = [...levelData];

  let {
    greenQuantity, greenLabourCost, greenCost, greenVaccumCost,
  } = data;
  const {
    baseLiquid, baseGranular, baseFlock, baseClarifierGreen, basePhAcid, sellGranular, sellLiquid, sellBlackSpotAlgae, baseBlackSpotAlgae,
  } = calVariable;

  // if (serviceAnalysis === 'Black-Spot Algae') {
  const contApi = fetchingBlackData(greenApiData);
  // console.log('blackApiData', contApi, 'containingBlackData', contApi);
  const sellCostChemical = greenSplitName === 'Liquid' ? sellLiquid : greenSplitName === 'Granular' ? sellGranular : sellBlackSpotAlgae;
  // console.log('baseLiquid', sellCostChemical);
  // console.log('value', valueImp);

  // const data = e ? greenApiData : greenParseData;

  // if (data.length) {
  //   secondChlorineDosage = data[8].dosageAndTime;
  //   labourCost = data[9].dosageAndTime;
  //   hoursRequired = data[10].dosageAndTime;
  //   vaccumCost = data[11].dosageAndTime;
  //   setGreenPoolCalculation({
  //     ...contApi,
  //     secondChlorineDosage,
  //     labourCost,
  //     hoursRequired,
  //     vaccumCost,
  //   });
  // }
  // }

  if (
    serviceAnalysis === 'Green Pool'
      || serviceAnalysis === 'Drain Pool' || serviceAnalysis === 'Black-Spot Algae'
  ) {
    let greenChemicalQuantity = '';
    let labourMarginGreen = 0;
    let { Hours: hoursRequired } = contApi;
    let greenData = '';
    let greenSecondDosage = '';
    let secondDosageCost = '';
    let greenFirstDosageUnit = '';
    let greenSecondDosageUnit = '';
    let greenFirstUpdatedValue = '';
    let greenSecondUpdatedValue = '';
    let acidObj = {};
    acidWashApiData.forEach((el) => {
      acidObj[el.calciName] = {
        dosageAndTime: el.dosageAndTime,
        timeReq: el.timeReq,
      };
    });
    let passedUnit = '';
    if (greenSplitName === 'Granular' || greenSplitName === 'Black-Spot') {
      passedUnit = 'kg';
    } else {
      passedUnit = 'litres';
    }
    if (greenSplitName === 'Granular' || greenSplitName === 'Liquid' || greenSplitName === 'Black-Spot') {
      greenData = greenApiData.filter((el) => el.greenName !== '' && el.greenName.split(' ').join('').toLowerCase() === greenSelect
         && el.chemicalName.includes(greenSplitName));

      const returnedDat = convertQuantUnitCalci((((greenData[0].dosageAndTime) / 40000) * poolInputs.poolCapacity), passedUnit, valueType);
      greenQuantity = parseFloat(returnedDat.actualValue);
      greenFirstDosageUnit = returnedDat.chemUnit;
      greenFirstUpdatedValue = returnedDat.chemValue; 
      const secondReturned = convertQuantUnitCalci((((((greenData[0].dosageAndTime) / 40000) * poolInputs.poolCapacity) * contApi.Second) / 100), passedUnit, valueType);
      greenSecondDosage = parseFloat(secondReturned.actualValue);
      greenSecondDosageUnit = secondReturned.chemUnit;
      greenSecondUpdatedValue = secondReturned.chemValue;
      secondDosageCost = Math.ceil((sellCostChemical * greenSecondDosage) / 5) * 5;
      greenCost = Math.ceil((sellCostChemical * greenQuantity) / 5) * 5;
      greenLabourCost = labourCostCalculation(contApi.Hours, contApi.Labour,
        greenSplitName);
      greenVaccumCost = labourCostCalculation(contApi.Vacuum, contApi.Labour, greenSplitName);
    }
    let { drainingHourlyRate, drainingHours } = calVariable;
    // console.log('draining', drainingHours, greenSplitName);
    let { poolCapacity } = poolInputs;
    let drainCost = 0;
    let acidQuantity = '';
    let chlorineQuantity = '';
    let acidMsg = '';
    let chlorineMsg = '';
    let drainLiquidCost = '';
    let hydrochloricAcidBuyCost = '';
    let pressureWashLabour = '';
    let removeDebrisLabour = '';
    let liquidChlorineCost = '';
    let chlorineLabourCost = '';
    let acidCost = '';
    let acidLabourCost = '';
    let acidQuantityUnit = '';
    let acidDisplayName = '';
    let renderAcidQuantity = '';
    let renderobj = {
      renderChlorineUnit: '',
      renderChlorineQuantity: '',
      renderChemicalDisplayName: '',
    };
    if (greenSplitName === 'Drain') {
      const { setupCost, hourlyRate, litresPerHour } = calVariable;
      const baseCost = parseFloat(setupCost);
      let litresHour = parseFloat(litresPerHour);
      let hourRate = parseFloat(hourlyRate);

      let hoursToDrain = (poolInputs.poolCapacity / litresHour) * 100;
      hoursToDrain /= 100;
      drainingHours = hoursToDrain.toFixed(1);
      const subTotal = baseCost + hourRate * hoursToDrain;
      const roundUpTotal = Math.ceil(subTotal / 5) * 5;
      drainCost = roundUpTotal;
      if (acidObj) {
        chlorineQuantity = ((acidObj.chlorineWashQuantity.dosageAndTime * 1) / 40000) * poolCapacity;
        acidQuantity = ((acidObj.acidWashQuantity.dosageAndTime * 1) / 40000) * poolCapacity;
        let { unit: acidUnit, actualQuantity: aciidQuantity } = handleUnit(
          acidQuantity,
        );

        renderAcidQuantity = aciidQuantity;
        acidQuantityUnit = acidUnit;
        acidQuantityUnit = valueType ? acidUnit : 'fl oz';
        acidDisplayName = 'Hydrochloric Acid';

        acidMsg = `add ${aciidQuantity} ${acidUnit} Hydrochloric Acid`;
        hydrochloricAcidBuyCost = (aciidQuantity * basePhAcid).toFixed(2);

        let { unit: chlorineUnit, actualQuantity: chlorQuantity } = handleUnit(chlorineQuantity);
        renderobj.renderChlorineUnit = valueType ? chlorineUnit : 'fl oz';
        renderobj.renderChlorineQuantity = chlorQuantity;
        renderobj.renderChemicalDisplayName = 'Liquid Chlorine';
        chlorineMsg = `add ${renderobj?.renderChlorineQuantity} ${renderobj?.renderChlorineUnit} ${renderobj?.renderChemicalDisplayName}`;
        drainLiquidCost = (renderobj?.renderChlorineQuantity * baseLiquid).toFixed(2);

        pressureWashLabour = labourCostCalculation(acidObj.pressureWashHourly.timeReq, acidObj.pressureWashHourly.dosageAndTime,
          greenSplitName, poolCapacity);
        removeDebrisLabour = labourCostCalculation(acidObj.removeDebris.timeReq, acidObj.removeDebris.dosageAndTime,
          greenSplitName, poolCapacity);
        liquidChlorineCost = ((35 / 15) * (acidObj.chlorineWashQuantity.dosageAndTime / 40000)
        * poolCapacity).toFixed(2);
        chlorineLabourCost = Math.ceil(labourCostCalculation(acidObj.acidWashHourly.timeReq, acidObj.acidWashHourly.dosageAndTime,
          greenSplitName, poolCapacity) / 5) * 5;
        acidCost = (95 / 20) * (acidObj.acidWashQuantity.dosageAndTime / 40000) * poolCapacity;
        acidLabourCost = Math.ceil(labourCostCalculation(acidObj.chlorineWashHourly.timeReq,
          acidObj.chlorineWashHourly.dosageAndTime, greenSplitName, poolCapacity) / 5) * 5;
      }
    }

    if (
      (drainWater || drainWaterHardness || drainWaterSalt || drainWaterChorine)
        && !withDrainage && isDrainage !== null && isDrainage === true) {
      let drainValue = drainWaterSalt || drainWaterHardness || drainWater || drainWaterChorine;
      let quantCalculation = (parseFloat(greenQuantity) * drainValue) / 100;
      greenQuantity = parseFloat(greenQuantity) - quantCalculation;
      const firstReturn = convertQuantUnitCalci(greenQuantity, passedUnit, valueType);
      greenFirstUpdatedValue = firstReturn.chemValue;
      greenFirstDosageUnit = firstReturn.chemUnit;

      let quantSecondCalculation = (parseFloat(greenSecondDosage) * drainValue) / 100;
      greenSecondDosage = parseFloat(greenSecondDosage) - quantSecondCalculation;
      greenCost = Math.ceil(((sellCostChemical)
              * greenQuantity) / 5) * 5;
      secondDosageCost = Math.ceil((sellCostChemical
              * greenSecondDosage) / 5) * 5;
      const secReturn = convertQuantUnitCalci(greenSecondDosage, passedUnit, valueType);
      greenSecondUpdatedValue = secReturn.chemValue;
      greenSecondDosageUnit = secReturn.chemUnit;
    }

    const buyCostChlor = greenSplitName === 'Liquid' ? baseLiquid : greenSplitName === 'Granular' ? baseGranular : baseBlackSpotAlgae;
    let {
      msg, secondMsg, buyCostGreen,
      buyCostGreenFirstDosage, buyCostGreenSecondDosage, renderGreenFirstDisplayName,
      renderGreenSecondDisplayName,
      renderGreenFirstUnit,
      renderGreenSecondUnit,
      renderChlorineUnique,
    } = greenBuyCostFirstSecondDosage(greenSplitName, greenQuantity,
      buyCostChlor, greenSecondDosage,
      greenFirstDosageUnit,
      greenSecondDosageUnit,
      greenFirstUpdatedValue,
      greenSecondUpdatedValue, valueType);

    labourMarginGreen = greenCost - (greenQuantity * buyCostGreen).toFixed(2);
    let labourMarginSecondGreen = secondDosageCost - (greenSecondDosage * buyCostGreen).toFixed(2);

    let drainValueForClarifierAndDrain = '';
    if (
      (drainWater || drainWaterHardness || drainWaterSalt || drainWaterChorine || drainCopper)
        && !withDrainage && isDrainage !== null && isDrainage === true) {
      drainValueForClarifierAndDrain = drainWaterSalt || drainWaterHardness || drainWater || drainWaterChorine || drainCopper;
    }

    let clarifierMargin = '';
    let clarMsg = '';
    let renderClarifierQuantity = '';
    let renderClarifierUnit = '';
    if (clarifierGreenPoolQuantity) {
      let greenChemicalClarifier = clarifierGreenPoolQuantity;
      if (drainValueForClarifierAndDrain) {
        greenChemicalClarifier = (greenChemicalClarifier * drainValueForClarifierAndDrain) / 100;
      }

      let newQuantity = clarifierGreenPoolQuantity;
      if (clarGreenUnits === 'ml' && valueType) {
        newQuantity = clarifierGreenPoolQuantity * 1000;
      }

      renderClarifierQuantity = newQuantity;
      renderClarifierUnit = valueType ? clarGreenUnits : 'fl oz';

      clarMsg = `add ${renderClarifierQuantity} ${renderClarifierUnit} of Concentrated Clarifier`;
      clarifierMargin = costClarifierGreen
          - (greenChemicalClarifier / 1000) * baseClarifierGreen;
    }

    let flkmsg = '';
    let flockMargin = '';
    let renderFlockQuantity = '';
    let renderFlockUnit = '';
    if (flockQuantity) {
      let greenChemicalFlock = flockQuantity;
      if (drainValueForClarifierAndDrain) {
        greenChemicalFlock = (greenChemicalFlock * drainValueForClarifierAndDrain) / 100;
      }
      let unit = 'kg';

      let newQuantity = '';
      if (greenChemicalFlock < 1) {
        newQuantity = greenChemicalFlock * 1000;
        unit = 'grams';
      } else {
        newQuantity = greenChemicalFlock;
      }
      renderFlockQuantity = newQuantity;
      renderFlockUnit = valueType ? unit : unit === 'grams' ? 'oz' : 'lb';

      flkmsg = `add ${renderFlockQuantity} ${renderFlockUnit} of flock`;
      flockMargin = costFlock - greenChemicalFlock * baseFlock;
    }

    const firstIndex = 0;
    const secondIndex = 1;
    const thirdIndex = 2;
    let drainPresChlorWash = false;
    let drainPresChlorAcid = false;
    if (greenTreatment === 'Drain + Pressure Wash & Chlorine Wash'
      || greenTreatment === 'Drain + Pressure Wash + Chlorine & Acid Wash') {
      drainPresChlorWash = true;
    }

    if (greenTreatment === 'Drain + Pressure Wash + Chlorine & Acid Wash'
      || greenTreatment === 'Drain + Pressure Wash & Acid Wash') {
      drainPresChlorAcid = true;
    }
    // It will be solve by umar
    // console.log('hoursRequired==', hoursRequired);
    // if (serviceAnalysis === 'Black-Spoyt') {
    // console.log('serviceanalysis', serviceAnalysis);
    const labourMessage = serviceAnalysis === 'Black-Spot Algae' ? 'Black-Spot Algae Labour' : 'Green Pool Labour';
    // } else {
    console.log('fakeLogger', renderClarifierQuantity);

    const createdObj = [
      {
        buyCostProduct: greenSplitName === 'Drain' ? '' : '0.00',
        renderCost: greenSplitName === 'Drain' ? drainCost.toFixed(2) : greenLabourCost.toFixed(2),
        totalLabour: greenSplitName === 'Drain' ? drainCost.toFixed(2) : greenLabourCost.toFixed(2),
        duration: greenSplitName === 'Drain' ? handleTime(greenSplitName, drainingHours, 'drain')
          : handleTime(greenSplitName, hoursRequired).toString(),
        laborMargin: '0',
        quantity: greenSplitName === 'Drain' ? handleTime(greenSplitName, drainingHours, 'drain')
          : handleTime(greenSplitName, hoursRequired).toString(),
        subcategory: greenSplitName === 'Drain' ? 'Drain Time Required' : labourMessage,
        cost: greenSplitName === 'Drain' ? drainingHourlyRate : parseFloat(contApi.Labour).toFixed(2),
        previousCost: greenSplitName === 'Drain' ? drainingHourlyRate
          : parseFloat(contApi.Labour).toFixed(2),
        type: 'service',
        show: 'visible',
      },
      {
        subcategory: greenSplitName === 'Drain' ? 'Remove Debris Labour Time Required' : '',
        duration: greenSplitName === 'Drain' ? handleTime(greenSplitName, poolTime(acidObj.removeDebris.timeReq, poolCapacity)) : '0',
        quantity: greenSplitName === 'Drain' ? handleTime(greenSplitName, poolTime(acidObj.removeDebris.timeReq, poolCapacity)) : '',
        totalLabour: greenSplitName === 'Drain' ? (Math.ceil(removeDebrisLabour / 5) * 5).toFixed(2) : '',
        cost: greenSplitName === 'Drain' ? parseFloat(acidObj.removeDebris.dosageAndTime).toFixed(2) : '',
        previousCost: greenSplitName === 'Drain' ? parseFloat(acidObj.removeDebris.dosageAndTime).toFixed(2) : '',
        renderCost: greenSplitName === 'Drain' ? (Math.ceil(removeDebrisLabour / 5) * 5).toFixed(2) : '',
        type: greenSplitName === 'Drain' ? 'service' : '',
        show: greenSplitName === 'Drain' ? 'visible' : '',
        laborMargin: '',
        buyCostProduct: '0.00',
      },
      {
        subcategory: greenSplitName === 'Drain' && greenTreatment !== 'Drain Only'
          ? 'Pressure Wash Labour Time Required' : msg,
        quantity: greenSplitName === 'Drain' ? greenTreatment === 'Drain Only' ? ''
          : handleTime(greenSplitName, poolTime(acidObj.pressureWashHourly.timeReq, poolCapacity)) : greenFirstUpdatedValue,
        buyCostProduct: greenSplitName === 'Drain' ? '0.00' : parseFloat(buyCostGreenFirstDosage).toFixed(2),
        cost: greenSplitName === 'Drain' ? greenTreatment === 'Drain Only' ? '0.00'
          : parseFloat(acidObj.pressureWashHourly.dosageAndTime).toFixed(2) : greenCost.toFixed(2),
        renderCost: greenSplitName === 'Drain' ? greenTreatment === 'Drain Only' ? '0.00'
          : (Math.ceil(pressureWashLabour / 5) * 5).toFixed(2) : greenCost.toFixed(2),
        duration: greenSplitName === 'Drain' ? greenTreatment === 'Drain Only' ? '0'
          : handleTime(greenSplitName, poolTime(acidObj.pressureWashHourly.timeReq, poolCapacity)) : '5',
        laborMargin: greenSplitName === 'Drain' ? '' : labourMarginGreen.toFixed(2),
        previousCost: greenSplitName === 'Drain' ? parseFloat(acidObj.pressureWashHourly.dosageAndTime).toFixed(2)
          : greenCost.toFixed(2),
        show: greenSplitName === 'Drain' ? 'visible' : 'visible',
        type: greenSplitName === 'Drain' ? 'service' : 'product',
        totalLabour: greenSplitName === 'Drain' ? greenTreatment === 'Drain Only' ? '0'
          : (Math.ceil(pressureWashLabour / 5) * 5).toFixed(2) : '',
        chemicalUnits: renderGreenFirstUnit,
        originalQuantity: greenFirstUpdatedValue,
        chemicalDisplayName: renderGreenFirstDisplayName,
        calciName: renderChlorineUnique,
      },
      {
        subcategory: drainPresChlorWash ? chlorineMsg : '',
        quantity: drainPresChlorWash ? renderobj?.renderChlorineQuantity : '',
        cost: drainPresChlorWash ? (Math.ceil(liquidChlorineCost / 5) * 5).toFixed(2) : '',
        previousCost: drainPresChlorWash ? (Math.ceil(liquidChlorineCost / 5) * 5).toFixed(2) : '',
        renderCost: drainPresChlorWash ? (Math.ceil(liquidChlorineCost / 5) * 5).toFixed(2) : '',
        laborMargin: drainPresChlorWash ? ((Math.ceil(liquidChlorineCost / 5) * 5) - drainLiquidCost).toFixed(2) : '',
        show: drainPresChlorWash ? 'visible' : '',
        duration: drainPresChlorWash ? '5' : '0',
        buyCostProduct: drainPresChlorWash ? drainLiquidCost : '0',
        totalLabour: '',
        type: 'product',
        chemicalUnits: drainPresChlorWash ? renderobj?.renderChlorineUnit : '',
        originalQuantity: drainPresChlorWash ? renderobj?.renderChlorineQuantity : '',
        chemicalDisplayName: drainPresChlorWash ? renderobj?.renderChemicalDisplayName : '',
      },
      {
        subcategory: drainPresChlorWash ? 'Chlorine Labour Time Required' : '',
        cost: drainPresChlorWash ? parseFloat(acidObj.acidWashHourly.dosageAndTime).toFixed(2) : '',
        previousCost: drainPresChlorWash ? parseFloat(acidObj.acidWashHourly.dosageAndTime).toFixed(2) : '',
        renderCost: drainPresChlorWash ? (Math.ceil(chlorineLabourCost / 5) * 5).toFixed(2) : '',
        totalLabour: drainPresChlorWash ? (Math.ceil(chlorineLabourCost / 5) * 5).toFixed(2) : '',
        type: drainPresChlorWash ? 'service' : '',
        show: drainPresChlorWash ? 'visible' : '',
        laborMargin: '',
        buyCostProduct: '0.00',
        quantity: drainPresChlorWash ? handleTime(greenSplitName, poolTime(acidObj.chlorineWashHourly.timeReq, poolCapacity)) : '',
        duration: drainPresChlorWash ? handleTime(greenSplitName, poolTime(acidObj.chlorineWashHourly.timeReq, poolCapacity)) : '0',
      },
      {
        subcategory: drainPresChlorAcid ? acidMsg : '',
        quantity: drainPresChlorAcid ? acidQuantity : '',
        cost: drainPresChlorAcid ? (Math.ceil(acidCost / 5) * 5).toFixed(2) : '',
        previousCost: drainPresChlorAcid ? (Math.ceil(acidCost / 5) * 5).toFixed(2) : '',
        renderCost: drainPresChlorAcid ? (Math.ceil(acidCost / 5) * 5).toFixed(2) : '',
        laborMargin: drainPresChlorAcid ? ((Math.ceil(acidCost / 5) * 5) - hydrochloricAcidBuyCost).toFixed(2) : '',
        show: drainPresChlorAcid ? 'visible' : '',
        buyCostProduct: drainPresChlorAcid ? hydrochloricAcidBuyCost : '0.00',
        duration: drainPresChlorAcid ? '5' : '0',
        totalLabour: '',
        type: 'product',
        chemicalUnits: drainPresChlorAcid ? acidQuantityUnit : '',
        originalQuantity: drainPresChlorAcid ? renderAcidQuantity : '',
        chemicalDisplayName: drainPresChlorAcid ? acidDisplayName : '',
      },
      {
        subcategory: drainPresChlorAcid ? 'Acid Labour Time Required' : '',
        cost: drainPresChlorAcid ? parseFloat(acidObj.chlorineWashHourly.dosageAndTime).toFixed(2) : '',
        previousCost: drainPresChlorAcid ? parseFloat(acidObj.chlorineWashHourly.dosageAndTime).toFixed(2) : '',
        renderCost: drainPresChlorAcid ? (Math.ceil(acidLabourCost / 5) * 5).toFixed(2) : '',
        totalLabour: drainPresChlorAcid ? (Math.ceil(acidLabourCost / 5) * 5).toFixed(2) : '',
        type: drainPresChlorAcid ? 'service' : '',
        show: drainPresChlorAcid ? 'visible' : '',
        buyCostProduct: '0.00',
        duration: drainPresChlorAcid ? handleTime(greenSplitName, poolTime(acidObj.acidWashHourly.timeReq, poolCapacity)) : '0',
        laborMargin: '',
        quantity: drainPresChlorAcid ? handleTime(greenSplitName, poolTime(acidObj.acidWashHourly.timeReq, poolCapacity)) : '',
      },
      {
        cost: greenSplitName !== 'Drain' && clarifierGreenPoolQuantity ? drainValueForClarifierAndDrain
          ? ((costClarifierGreen * drainValueForClarifierAndDrain) / 100).toFixed(2)
          : costClarifierGreen.toFixed(2) : '',
        previousCost: greenSplitName !== 'Drain' && clarifierGreenPoolQuantity ? drainValueForClarifierAndDrain
          ? ((costClarifierGreen * drainValueForClarifierAndDrain) / 100).toFixed(2)
          : costClarifierGreen.toFixed(2) : '',
        renderCost: greenSplitName !== 'Drain' && clarifierGreenPoolQuantity ? drainValueForClarifierAndDrain ? (
          Math.ceil((costClarifierGreen * drainValueForClarifierAndDrain) / 100 / 5) * 5).toFixed(2)
          : (Math.ceil(costClarifierGreen / 5) * 5).toFixed(2)
          : '',
        subcategory: greenSplitName !== 'Drain' && clarifierGreenPoolQuantity ? clarMsg : '',
        buyCostProduct: greenSplitName !== 'Drain' && clarifierGreenPoolQuantity ? drainValueForClarifierAndDrain
          ? ((quantityCostClarifierGreen * drainValueForClarifierAndDrain) / 100).toFixed(2)
          : quantityCostClarifierGreen.toFixed(2) : '0.00',
        quantity: greenSplitName !== 'Drain' && renderClarifierQuantity ? renderClarifierQuantity : '',
        laborMargin: greenSplitName !== 'Drain' && clarifierGreenPoolQuantity ? drainValueForClarifierAndDrain
          ? ((clarifierMargin * drainValueForClarifierAndDrain) / 100).toFixed(2)
          : clarifierMargin.toFixed(2) : '',
        show: greenSplitName !== 'Drain' && clarifierGreenPoolQuantity ? 'visible' : '',
        duration: greenSplitName !== 'Drain' && clarifierGreenPoolQuantity ? '5' : '0',
        totalLabour: '',
        type: 'product',
        chemicalUnits: greenSplitName !== 'Drain' && clarifierGreenPoolQuantity ? renderClarifierUnit : '',
        originalQuantity: greenSplitName !== 'Drain' && clarifierGreenPoolQuantity ? renderClarifierQuantity : '',
        chemicalDisplayName: greenSplitName !== 'Drain' && clarifierGreenPoolQuantity ? 'Concentrated Clarifier' : '',
      },
      {
        cost: greenSplitName !== 'Drain' && flockQuantity ? drainValueForClarifierAndDrain
          ? ((costFlock * drainValueForClarifierAndDrain) / 100).toFixed(2) : costFlock.toFixed(2) : '',
        previousCost: greenSplitName !== 'Drain' && flockQuantity ? drainValueForClarifierAndDrain
          ? ((costFlock * drainValueForClarifierAndDrain) / 100).toFixed(2) : costFlock.toFixed(2) : '',
        buyCostProduct: greenSplitName !== 'Drain' && flockQuantity ? drainValueForClarifierAndDrain
          ? ((quantityCostFlock * drainValueForClarifierAndDrain) / 100).toFixed(2)
          : quantityCostFlock.toFixed(2) : '0.00',
        renderCost: greenSplitName !== 'Drain' && flockQuantity ? drainValueForClarifierAndDrain
          ? (Math.ceil((costFlock * drainValueForClarifierAndDrain) / 100 / 5) * 5).toFixed(2)
          : (Math.ceil(costFlock / 5) * 5).toFixed(2) : '',
        subcategory: greenSplitName !== 'Drain' && flockQuantity ? flkmsg : '',
        quantity: greenSplitName !== 'Drain' && renderFlockQuantity
          ? renderFlockQuantity : '',
        laborMargin: greenSplitName !== 'Drain' && flockQuantity ? drainValueForClarifierAndDrain
          ? ((flockMargin * drainValueForClarifierAndDrain) / 100).toFixed(2) : flockMargin.toFixed(2) : '',
        show: greenSplitName !== 'Drain' && flockQuantity ? 'visible' : '',
        duration: greenSplitName !== 'Drain' && flockQuantity ? '5' : '0',
        totalLabour: '',
        type: 'product',
        chemicalUnits: greenSplitName !== 'Drain' && flockQuantity ? renderFlockUnit : '',
        originalQuantity: greenSplitName !== 'Drain' && flockQuantity ? renderFlockQuantity : '',
        chemicalDisplayName: greenSplitName !== 'Drain' && flockQuantity ? 'flock' : '',
      },
    ];
    greenChemicalAndLabour(createdObj, levelData, withDrainage);
    // }

    const serviceLevelData = (subcategory, quantity, originalQuantity, duration, cost, previousCost, buyCost, renderCost, totalLabour, laborMargin, show, type, chemicalUnits, chemicalDisplayName, calciName) => {
      const copyInitial = { ...categoryObj };
      copyInitial.renderCost = parseFloat(renderCost).toFixed(2);
      copyInitial.buyCostProduct = parseFloat(buyCost).toFixed(2);
      copyInitial.quantity = quantity;
      copyInitial.cost = parseFloat(cost).toFixed(2);
      copyInitial.previousCost = parseFloat(previousCost).toFixed(2);
      copyInitial.subcategory = subcategory;
      copyInitial.laborMargin = parseFloat(laborMargin).toFixed(2);
      copyInitial.totalLabour = parseFloat(totalLabour).toFixed(2);
      copyInitial.show = show;
      copyInitial.type = type;
      copyInitial.duration = duration;
      copyInitial.chemicalUnits = chemicalUnits;
      copyInitial.originalQuantity = originalQuantity;
      copyInitial.chemicalDisplayName = chemicalDisplayName;
      copyInitial.calciName = calciName;
      return copyInitial;
    };
    let arr = [];
    if (greenSplitName === 'Liquid' || greenSplitName === 'Granular' || greenSplitName === 'Black-Spot') {
      arr.push(serviceLevelData(
        labourMessage,
        handleTime(greenSplitName, hoursRequired).toString(),
        handleTime(greenSplitName, hoursRequired).toString(),
        handleTime(greenSplitName, hoursRequired).toString(),
        contApi?.Labour,
        contApi?.Labour,
        0,
        greenLabourCost,
        greenLabourCost,
        0,
        'visible',
        'service',
        '',
        '',
        '',
      ));
      arr.push(serviceLevelData(
        secondMsg,
        greenSecondUpdatedValue,
        greenSecondUpdatedValue,
        '5',
        secondDosageCost,
        secondDosageCost,
        buyCostGreenSecondDosage,
        secondDosageCost,
        0,
        labourMarginSecondGreen,
        'visible',
        'product',
        renderGreenSecondUnit,
        renderGreenSecondDisplayName,
        renderChlorineUnique,
      ));

      copyLevelData[secondIndex].service = arr;

      let arr2 = [];

      const renderCostForVaccum = Math.ceil(((greenVaccumCost / 40000) * poolCapacity) / 5) * 5;
      arr2.push(serviceLevelData(
        'Vacuum For Final Step',
        handleTime(greenSplitName, contApi.Vacuum).toString(),
        handleTime(greenSplitName, contApi.Vacuum).toString(),
        handleTime(greenSplitName, contApi.Vacuum).toString(),
        contApi?.Labour,
        contApi?.Labour,
        0,
        renderCostForVaccum,
        renderCostForVaccum,
        0,
        'visible',
        'service',
        '',
        '',
        '',
      ));

      copyLevelData[thirdIndex].service = arr2;
    }
  }
  return copyLevelData;
};
