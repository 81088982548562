/* eslint-disable no-restricted-syntax */
import React, {
  useState, useEffect, useContext,
} from 'react';
import {
  Modal, Dropdown, Button, Form,
} from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from '../../functions/moment';
import showmore from '../../assets/home/showmoreblue.png';
import AppointmentCheckList from '../payments/appointmentChecklist';
import ViewAppointmentCheckList from '../payments/viewAppointmentChecklist';
import PreviewInvoicePopup from '../invoices/previewInvoicePopup';
import Editappointment from './editAppointment';
import Cancel from './cancelAppointment';
import DeleteWaterTestPopup from './deleteWaterTest';
import DurationPicker from './durationPicker';
import ChemicalPopup from '../calculator/chemicalPopup';
import {
  getCalendarPath,
  queryString,
  makingAddress,
  addZero,
  calculatChemicalCost,
  calculatServiceProCost,
  updateServerDate,
  updateSingleServiceDateTime,
} from '../../functions/index';
import { userInstance } from '../../config/axios';
import DeleteInvoicePopup from '../invoices/deleteInvoicePopup';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import {
  errorBorderTime,
} from '../../functions/intervalTime';
import arrow from '../../assets/home/arrowdown.png';
import sort from '../../assets/home/sort.png';
import upsort from '../../assets/home/up-sort.png';
import downsort from '../../assets/home/down-sort.png';
import ServiceModal from './servicemodal';
import HourPicker from './hourPicker';
import TimePicker from './timepicker';
import {
  displayCacliPrices,
  displayDollar,
  notifyAlert,
} from '../calculator/calculatorUtils';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';
import { socket } from '../../socket';
import {
  convertDateTime, displayTime, getTimeZone, updateEndTime, 
} from '../../functions/timeDate';

const ViewAppointment = ({
  appointmentDetail,
  handleAppointmentDetails,
  appointmentData,
  addedServices,
  setAddedServices,
  comingClientId,
  setRandom,
  eventDate,
  SelectedStaff,
  activeStaff,
  customerDues,
  setCustomerDues,
  finaliseState,
  clientid,
  setForManagementEdit,
  forManagementEdit,
  setAppointmentDetail,
  setEventDate,
  chemicaltype,
}) => {
  // console.log('view=>', {
  //   appointmentDetail,
  //   handleAppointmentDetails,
  //   appointmentData,
  //   addedServices,
  //   setAddedServices,
  //   comingClientId,
  //   setRandom,
  //   eventDate,
  //   SelectedStaff,
  //   activeStaff,
  //   customerDues,
  //   setCustomerDues,
  //   finaliseState,
  //   clientid,
  //   setForManagementEdit,
  //   forManagementEdit,
  //   setAppointmentDetail,
  //   setEventDate,
  //   chemicaltype,
  // });
  const history = useHistory();
  const { userData, clientAllData, setClientAllData } = useContext(AuthDataContext);
  const { userPermissions, industryId, calendarSettings } = userData;
  const { timeZone } = calendarSettings || {};
  const timeDisplay = calendarSettings?.timeDisplay === 'HH:mm A'
    ? 'HH:mm'
    : calendarSettings?.timeDisplay;
  const idClient = localStorage.getItem('idClient');
  const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  let currentDates = new Date(eventDate);
  const [durationPicker, setDurationPicker] = useState(false);
  const [durationData, setDuration] = useState('');
  const [waterTestPopup, setWaterTestPopup] = useState(false);
  const [showService, setShowService] = useState(false);
  const [newLength, setnewlength] = useState();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState(null);
  const [editData, setEditData] = useState({
    edit: false,
    index: '',
    type: 'mark',
  });
  const [staffIndex, setStaffIndex] = useState();
  const [misc, setMisc] = useState(false);
  const [extras, setExtras] = useState({ value: '', msg: '' });
  const [timepickerShow, setTimePickerShow] = useState(false);
  const [subcategoryTxt, setSearchTxt] = useState('');
  const [hourPicker, setHourPicker] = useState(false);
  const [appointmentType, setAppointmentType] = useState('oneteam_oneday');
  const [hourData, setHourData] = useState('');
  const [timeData, setTime] = useState('9:30');
  const [appointmentIndex, setappointmentIndex] = useState('');
  let { staff_id } = queryString();
  const [saveUpdateStatus, setSaveUpdateStatus] = useState(false);
  const [futureNotify, setFutureNotify] = useState(false);
  const [loading, setLoading] = useState(true);

  const initialBookingData = {
    frequency: "One-off appointment | Doesn't repeat",
    repeatEnd: 'Ongoing',
    category: 'Pool Vacuum & Clean',
    subcategory: 'Pool Vacuum & Clean',
    startTime: '9:30',
    // startTime: setDefaultTime,
    duration: '5',
    staffId: staff_id,
    staffName: '',
    clientId: '',
    cost: '',
    start: '',
    end: '',
    customCost: 'default',
    type: '',
    buyCostProduct: '',
    laborMargin: '',
    quantity: '1',
    timeChange: false,
    customMisc: '',
    previousDuration: '',
    previousQuantity: '',
    pricingType: 'fixedCost',
    previousCost: '',
    prevBuyCostProduct: '',
    renderCost: '',
    soldPer: '',
    unit: '',
    symbol: '',
    description: '',
    tax: '',
    s_uId: '',
    categoryId: '',
    supplier: '',
  };
  const otherBookingInitial = {
    category: 'Pool Vacuum & Clean',
    subcategory: 'Pool Vacuum & Clean',
    duration: '5',
    cost: '',
    start: '',
    end: '',
    customCost: 'default',
    type: '',
    buyCostProduct: '',
    laborMargin: '',
    quantity: '1',
    customMisc: '',
    previousDuration: '',
    previousQuantity: '',
    pricingType: 'fixedCost',
    soldPer: '',
    unit: '',
    symbol: '',
    tax: '',
    s_uId: '',
    categoryId: '',
    supplier: '',
  };
  const [bookingData, setBookingData] = useState(initialBookingData);
  const handleDurationPicker = () => {
    setDuration(bookingData.duration);
    setDurationPicker(!durationPicker);
  };
  const [directionpopup, setDirectionPopup] = useState(false);
  const [markcomplete, setMarkcomplete] = useState(false);
  const [checkList, setCheckList] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [showInvoice, setShowInvoice] = useState(false);
  const [editAppointment, setEditAppointment] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [chemicalsAdded, setChemicalsAdded] = useState(false);
  const [viewChecklist, setViewChecklist] = useState(false);
  const [statusData, setStatusData] = useState(false);
  const [popup, setPopup] = useState(false);
  const [siteAddressCopy, setSiteAddressCopy] = useState({});
  const [ok, setOk] = useState(false);
  const [checklistArray, setChecklistArray] = useState([]);
  const [image, setImage] = useState([]);
  const [editJob, seteditJob] = useState(false);

  const [voidPermission, setVoidPermission] = useState(false);
  const [currentUserStaff, securrentUserStaff] = useState({});
  // const [summary, setSummary] = useState({
  //   totalCost: 0,
  //   totalDuration: '',
  //   totalLabourMargin: 0,
  // });
  const [summary, setSummary] = useState({
    totalCost: 0,
    totalDuration: '',
    totalLabourMargin: 0,
  });

  const [locStatus, setLocStatus] = useState(false);
  const [isEditCompleted, setisEditCompleted] = useState(false);
  let {
    client,
    dateData,
    totalData,
    _id,
    pid,
    Invoice,
    waterTestStatus,
    chemicalsArray,
    repeats,
    checklist,
    images,
    clientSiteAddressIndex,
    chemicalsArray: chemicalalist,
    status,
    paymentStatus,
    visitMessage,
    appointment_calc_type,
  } = appointmentData || {};
  let { service, teamnotes } = dateData ? dateData[0] : {};
  const [notesmain, setnotesMain] = useState();

  const chemicalArrayFields = chemicalsArray && chemicalsArray[0]?.testResult
    ? chemicalsArray[0]?.testResult
    : [];
  // console.log('chemicalArrayFields==>', chemicalArrayFields);

  const [chemDataFields, setChemDataFields] = useState([]);

  useEffect(() => {
    const drainChemicalArray = chemicalArrayFields?.length > 0
      ? chemicalArrayFields.filter((el) => {
        let val = true;
        if (el?.service?.length > 0) {
          el?.service.forEach((ele) => {
            if (ele?.category === 'drain') {
              val = false;
            }
          });
        }
        if (val) {
          return true;
        }
        return false;
      })
      : [];
    setChemDataFields(drainChemicalArray);
  }, [chemicalArrayFields.length]);

  let {
    address,
    firstname,
    lastname,
    siteAddresses,
    mobileNo,
    note,
    // streetAddress,
    // suburb,
    // state,
  } = client || {};
  // let { service, msg, teamnotes } = dateData ? dateData[0] : {};

  let {
    totalCost, totalLabour, totalDuration, totalMargin, totalLabourOnly,
  } = totalData || {};
  // console.log('totalData==>', totalData);
  const [totalDataSummary, setTotalData] = useState(totalData);
  // const [totalDataSummaryUpdate, setTotalDataUpdate] = useState(totalData);
  const [calculater_type, setCalculater_type] = useState('');
  // console.log('appointmentDetail', {
  //   totalCost, totalLabour, totalDuration, totalMargin, totalLabourOnly,
  // });

  const [selectTemplate, setSelectTemplate] = useState();
  const [errorValue, setErrorValue] = useState('');
  // const getAllTemplate = async () => {
  //   try {
  //     const response = await userInstance().get(
  //       '/template/getAllTemplate?type=Invoice',
  //     );
  //     const { temp } = response.data;
  //     if (response.data.msg === 'Success') {
  //       const defaultTemp = temp.filter((el, i) => el.default);
  //       if (defaultTemp.length > 0) {
  //         setSelectTemplate(defaultTemp[0]._id);
  //       } else {
  //         setSelectTemplate(temp[0]._id);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const [userPermissions, setUserPermissions] = useState([]);

  const [oldData, setOldData] = useState();

  useEffect(() => {
    if (userData.accessLevel) {
      const testingData = ['low', 'high', 'medium'];

      const filteredData = userData.permissions.find(
        (el) => el.action === 'Delete Invoice',
      );
      if (filteredData && filteredData.level.includes(userData.accessLevel)) {
        setVoidPermission(true);
      } else {
        setVoidPermission(false);
      }
    }
  }, []);

  useEffect(() => {
    if (service) {
      getChecklist();
    }
    const copy = { ...appointmentData };
    setOldData(copy);
  }, [service]);

  const getAddress = () => {
    if (currentUserStaff?.streetAddress) {
      let { streetAddress, suburb, state } = currentUserStaff;
      return makingAddress({ streetAddress, suburb, state });
    }
    return '';
  };

  const getChecklist = async () => {
    try {
      let arr = [];
      let filter = [];
      let checkArray = [];
      const response = await userInstance().get(
        `/service/getServices?industryId=${industryId._id}`,
      );
      const { services } = response.data;
      for (let i = 0; i < service.length; i++) {
        const find = services.find(
          (item) => item.category === service[i].category,
        );
        if (find) {
          filter.push(find);
        }
      }
      if (filter && filter.length > 0) {
        for (let i = 0; i < filter.length; i++) {
          arr = [...arr, ...filter[i].appointmentChecklist];
        }
        const mySet = new Set(arr);
        arr = [...mySet];
        for (let i = 0; i < arr.length; i++) {
          checkArray.push({ name: arr[i], checked: false, tempChecked: false });
        }
        if (!finaliseState) {
          setChecklistArray(checkArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };
  const HandleWaterTest = () => {
    // console.log('eventDate', eventDate);
    // let eventformattedDate = convertDateTime(eventDate);
    let lastElement = service[service.length - 1];
    const nextEntryStart = convertDateTime(lastElement.start);
    const nextStartTime = updateEndTime(nextEntryStart, lastElement.duration, true)
      .format('HH:mm');
    // console.log('service', lastElement, lastElement.startTime, lastElement.duration, nextStartTime);
    // history.push(`/calculator?appId=${_id}&eventDate=${eventformattedDate}&Id=${client._id}&nextStartTime=${nextStartTime}`);
    let poolHeight = '';
    let poolWidth = '';
    if (client?.poolWidth && client?.poolHeight) {
      poolWidth = client.poolWidth;
      poolHeight = client.poolHeight;
    }
    if (siteAddressCopy?.poolWidth && siteAddressCopy?.poolHeight) {
      poolWidth = siteAddressCopy.poolWidth;
      poolHeight = siteAddressCopy.poolHeight;
    }
    if (localStorage.getItem('s#@4f11') && localStorage.getItem('f#@5e11')) {
      socket.emit('addingWaterTest', {
        appId: _id,
        eventDate,
        nextStartTime,
        poolWidth,
        poolHeight,
        userId: userData?.userInformation?._id,
        preBackDate: localStorage.getItem('prebackDate'),
        clientAllData,
      });
      return;
    }

    history.push(
      `/calculator?appId=${_id}&eventDate=${eventDate}&nextStartTime=${nextStartTime}&poolWidth=${poolWidth}&poolHeight=${poolHeight}`,
    );
  };
  const waterHistory = () => {
    // let Id = client ? client._id : {};
    history.push(`/watertesthistory?data=${staff_id}&routeType=calendar`);
  };

  const waterHistoryById = () => {
    const appId = pid || _id;
    history.push(
      `/watertesthistory?data=${staff_id}&routeType=calendar&appId=${appId}`,
    );
  };
  const bookingHistory = (type) => {
    let Id = client ? client._id : {};
    let routeType = 'calendar';
    history.push(
      `/booking?client=${Id}&routeType=${routeType}&name=name&type=${type}`,
    );
  };
  const invoiceHistory = () => {
    let Id = client ? client._id : {};

    history.push(`/approvalappointments?clientid=${Id}&from=viewApp`);
  };
  const equipmentDetails = () => {
    let Id = client ? client._id : {};
    history.push(`/equipment-details?client=${Id}`);
  };

  const Rebook = () => {
    let oldUrl = getCalendarPath();
    const newUrl = `${oldUrl}&data=Rebook&id=${_id}`;
    setAppointmentDetail(!appointmentDetail);
    history.push(newUrl);
    // window.location.href = newUrl;
  };
  // Changes by
  const [coords, setCoords] = useState(null);

  // const handleReverseCoordinates = (coordsData) => {
  //   fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordsData.lat},${coordsData.lng}&sensor=false&key=AIzaSyA4nIdMdEOV6yq3hvMi605xcQRL7hBiVaM`).then((res) => res.json())
  //     .then((data) => setCoords({ ...coords, formattedAddress: data.results[0].formatted_address }))
  //     .catch((error) => console.log('error', error));
  // };
  const showPosition = (position) => {
    const coordsData = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    setCoords(coordsData);
  };
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  const handleDirection = async () => {
    try {
      getLocation();
      setDirectionPopup(!directionpopup);
      setLocStatus(true);
      // if (locStatus === true) {
      //   getClientLocation();
      // }
    } catch (e) {
      console.log('error data', e);
    }
  };
  // useEffect(() => {
  //   handleDirection();
  // }, [locStatus]);

  const handleCheckList = () => {
    const appointmentDate = moment(new Date(eventDate)).format('YYYY-MM-DD');
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const dateOne = new Date(appointmentDate);
    const dateTwo = new Date(todayDate);

    if (!waterTestStatus && !ok && !checkList) {
      setPopup(true);
      console.log('current past1');
    } else if (checkList) {
      setPopup(false);
      console.log('current past2');
      setCheckList(!checkList);
    } else {
      console.log('current past3');
      setCheckList(!checkList);
    }
  };

  const handlePopuncheck = () => {
    setPopup(false);
    setOk(true);
  };

  const hasEmptyElement = (array) => {
    for (var i = 0; i < array.length; i++) {
      let caption = array[i].caption.trim();
      if (caption === '') {
        return false;
      }
    }
    return true;
  };

  const hasEmptyChecked = (array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].checked === false && array[i].tempChecked === false) {
        return false;
      }
    }
    return true;
  };

  const handleMarkasComplete = () => {
    if (hasEmptyChecked(checklistArray)) {
      if (image.length > 0) {
        if (hasEmptyElement(image)) {
          let Id = client ? client._id : {};
          history.push({
            pathname: '/finlizevisitcost',
            state: {
              id: _id,
              addedServices,
              dateBookingData: dateData,
              summary: summary.totalCost === 0 ? totalData : summary,
              eventDate,
              repeats,
              customerDues,
              checklistArray,
              image,
              clientIdentity:
                finaliseState && finaliseState.clientIdentity
                  ? finaliseState.clientIdentity
                  : Id,
              chemicalalist,
              dateData,
              visitMessage,
              pid,
            },
          });

          // setMarkcomplete(!markcomplete);
          // setStatusData(true);
        } else {
          notifyAlert('Add caption to photo', 'error');
          setErrorValue('caption');
        }
      } else {
        notifyAlert('Include photo for reference', 'error');
      }
    } else {
      notifyAlert('All points in checklist to be marked', 'error');
    }
    setTimeout(() => {
      setErrorValue('');
    }, 1000);
  };

  const calculateAmount = () => {
    let allTotalCost = 0;
    let allTotalMargin = 0;
    let allDuration = 0;
    let allDurationTotal = 0;
    let chemicalTotalCost = 0;
    let chemicalTotaMargin = 0;
    let chemicalTotaDuration = 0;
    let allTotalMargin2 = 0;
    let allTotalCost2 = 0;
    let allDurationTotal2 = 0;
    if (chemicalalist && chemicalalist?.length) {
      // console.log('chemicalalist==>', chemicalalist);
      const chemicalaTypeList = chemicalalist[0].testResult[0].service.filter(
        (el2) => el2.type === 'product' && el2.status === 'quantity',
      );
      // setServices(serviceProTypeList);
      const { subtotalCost, subtotalDuration, subtotalLabour } = calculatChemicalCost(chemicalaTypeList);
      // console.log('test totala==>', {
      //   subtotalCost, subtotalDuration, subtotalLabour, chemicalaTypeList,
      // });
      chemicalTotalCost = subtotalCost;
      chemicalTotaDuration = subtotalDuration;
      chemicalTotaMargin = subtotalLabour;
      /* this comment code  calculate total chemical cost and sub */
      let replaceCommaTotalCost2 = totalCost?.replace(',', '');
      allTotalCost2 = parseFloat(replaceCommaTotalCost2) + parseFloat(subtotalCost);
      let replaceCommaTotalLabour2 = totalLabour?.replace(',', '');
      allTotalMargin2 = parseFloat(replaceCommaTotalLabour2) + parseFloat(subtotalLabour);
      let allDuration2 = totalDuration + subtotalDuration;
      allDurationTotal2 = convertToReadableDuration(allDuration2);
      let chemicalTotalCost2 = parseFloat(subtotalCost);
      let chemicalTotaMargin2 = parseFloat(subtotalLabour);
      let chemicalTotaDuration2 = subtotalDuration;
    }
    // console.log('test check heloooo========>', {
    //   allTotalCost, allTotalMargin, allDurationTotal, chemicalTotaMargin, chemicalTotalCost,
    // });
    return {
      allTotalCost,
      allTotalMargin,
      allDurationTotal,
      chemicalTotalCost,
      chemicalTotaMargin,
      chemicalTotaDuration,
      allTotalCost2,
      allDurationTotal2,
      allTotalMargin2,
    };
  };
  useEffect(() => {
    if (totalCost || totalCost !== 'undefined') {
      calculateAmount();
    }
  }, [totalCost]);

  const handleChecked = (e, index) => {
    const old = [...checklistArray];
    if (!old[index].checked && !old[index].tempChecked) {
      old[index].checked = true;
    } else if (old[index].checked && !old[index].tempChecked) {
      old[index].tempChecked = true;
      old[index].checked = false;
    } else {
      old[index].checked = false;
      old[index].tempChecked = false;
    }
    if (!finaliseState) {
      setChecklistArray(old);
    }
  };
  const handleInvoicePopup = async () => {
    setShowInvoice(true);
  };
  const handleClose = () => setShowInvoice(false);

  const goToPayments = (invoiceId, id) => {
    let Id = client ? client._id : {};
    history.push(
      `/invoicespayment?data=${invoiceId}&appId=${id}&clientid=${Id}&event=${eventDate}`,
    );
  };

  const handleEdit = () => {
    // console.log('why Im Hitting');
    setEditAppointment(true);
  };
  const handleCloseEdit = () => {
    setEditAppointment(false);
  };
  const handleReschedule = async () => {
    let oldUrl = getCalendarPath();
    const newUrl = `${oldUrl}&data=Reschedule&id=${_id}`;
    setAppointmentDetail(!appointmentDetail);
    history.push(newUrl);
    // window.location.href = newUrl;
  };

  const CancelAppt = () => {
    setCancel(true);
  };

  const closeCancel = () => {
    setCancel(false);
  };

  let filesName = '';
  const rotateImage = (imageBase64, rotation) => {
    let img = new Image();
    img.src = imageBase64;
    img.onload = () => {
      let canvas = document.createElement('canvas');
      const maxDim = Math.max(img.height, img.width);
      // console.log('img.height=>', img.height, img.width);
      canvas.width = img.width;
      canvas.height = img.height;

      let ctx = canvas.getContext('2d');
      ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
      // ctx.rotate(45 * (Math.PI / 180));
      ctx.drawImage(img, -maxDim / 2, -maxDim / 2);
      const canvasBaseUrl = canvas.toDataURL('image/jpeg', 0.2);
      const filesResult = dataURLtoFile(canvasBaseUrl, filesName);
      // console.log('sanjanananaaann ====>', filess);
      console.log('urllll=>', window.URL.createObjectURL(filesResult));
      // console.log('canvas.toDataURL ==>', ddddd);
      handleImageChange(filesResult);
    };
  };
  const getBase64 = (file) => new Promise((resolve) => {
    let baseURL = '';
    let reader = new FileReader(); // Make new FileReader
    reader.readAsDataURL(file); // Convert the file to base64 text
    reader.onload = () => {
      baseURL = reader.result; // Make a fileInfo Object
      rotateImage(baseURL);
      resolve(baseURL);
    };
  });
  const handleFileInputChange = (e) => {
    [...e.target.files].forEach((file) => {
      filesName = file.name;
      getBase64(file);
    });
  };
  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  const tempArr = [...image];
  const handleImageChange = (fileRes) => {
    // [...e.target.files].forEach((file) => {
    tempArr.push({
      data: fileRes,
      caption: '',
    });
    // });
    setImage(tempArr);
  };

  const removePhotos = (e, index) => {
    const old = [...image];
    if (e.target.name === 'removePhoto') {
      old.splice(index, 1);
      setImage(old);
    } else {
      old[index].caption = '';
      setImage(old);
    }
  };

  const AddCaptions = (e, i) => {
    const old = [...image];
    old[i].caption = e.target.value;
    setImage(old);
  };

  const handleViewCheckList = () => {
    setViewChecklist(!viewChecklist);
  };

  const returnSideAddress = () => {
    if (siteAddresses) {
      const dat = siteAddresses[clientSiteAddressIndex];
      setSiteAddressCopy(dat);
    }
  };
  const editJobNotes = () => {
    const notes = notesmain || teamnotes;
    setnotesMain(notes);
    seteditJob(true);
  };
  const saveNotes1 = async () => {
    const id1 = appointmentData._id;
    const response1 = await userInstance().post(
      '/appointment/changeTeamNotes',
      { id1, notesmain },
    );
    setnotesMain(notesmain);
    if (response1.status === 200) {
      seteditJob(false);
    }
  };

  useEffect(() => {
    returnSideAddress();
  }, [siteAddresses]);

  const [gg, setGg] = useState(false);
  function getValue() {
    if (client && client.firstname) {
      setGg(true);
    }
    if (gg === true) {
      setEditAppointment(true);
      localStorage.removeItem('idClient');
    }
  }
  function finaliseData() {
    if (finaliseState?.existClientId && clientid) {
      // console.log('comingUnderThisCondition', finaliseState, clientid);
      setCheckList(true);
      setChecklistArray(finaliseState.existCheckListArray);
      setImage(finaliseState.existImage);
      setSummary(finaliseState.existingSummary);
    }
  }

  async function gettingTemplate() {
    let dataInv = Invoice;
    if (selectTemplate && Invoice && !Invoice.invoiceUrl) {
      const InvoiceId = Invoice.appointment[0].Invoice;
      const response = await userInstance().get(
        `/template/previewTemplate?id=${selectTemplate}&invoiceid=${InvoiceId}&type=${Invoice.invoiceType}`,
      );
      const { code, tempId } = response.data;
      dataInv = { ...Invoice, invoiceUrl: tempId };
    }
    setInvoiceDetail(dataInv);
  }
  useEffect(() => {
    finaliseData();
    // getAllTemplate();
  }, []);
  useEffect(() => {
    if (idClient && !forManagementEdit) {
      getValue();
    }
  }, [gg, client]);

  useEffect(() => {
    gettingTemplate();
  }, [Invoice, selectTemplate]);
  const handleEditDetails = (data, data2) => {
    // console.log('data, data2', data, data2);
    const splitName = data2.split(' ');
    history.push(`/client?clientid=${data}&name=${data2}&typee=editclient`);
  };
  const [invoicepopup, setInvoicePopup] = useState(false);
  const VoidInvoice = async () => {
    setInvoicePopup(true);
  };
  const handleInvoicePopuncheck = async () => {
    if (invoiceDetail) {
      const response = await userInstance().get(
        `/invoice/deleteInvoice?id=${invoiceDetail._id}`,
      );
      if (response.data.msg === 'Success') {
        toastMessage('success', 'Invoice deleted successfully');
        setInvoicePopup(false);
        SelectedStaff(localStorage.getItem('staff_id'));
        handleAppointmentDetails(false);
      }
      handleClose();
    }
  };

  const removeWaterTest = async () => {
    const deleteData = {
      appId: _id,
      chemicalsArray: [],
      waterTestStatus: false,
    };
    const response = await userInstance().put(
      '/appointment/removeToWaterTest',
      deleteData,
    );
    const { code } = response.data;
    if (code === 200) {
      const copyClientAllData = { ...clientAllData };
      copyClientAllData.appointmentData.waterTestStatus = false;
      copyClientAllData.appointmentData.chemicalsArray = [];
      setClientAllData(copyClientAllData);
      localStorage.setItem('apptData', JSON.stringify(copyClientAllData));
      toastMessage('success', 'Chemicals removed successfully');
      setWaterTestPopup(false);
    }
  };
  const removeWaterConfirm = async () => {
    setWaterTestPopup(!waterTestPopup);
  };
  const timeFromDateFormat = 'HH:mm';
  const [renderSort, setRenderSort] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const getStaffList = async () => {
    try {
      const response = await userInstance().get('/staff/getStaff');
      const { staff } = response.data;
      setStaffList(staff);
      const setDefaultStaff = staff.filter(
        (el) => el._id === localStorage.getItem('staff_id'),
      );
      let currentUserStaff_ = staff.find((el) => el._id === appointmentData.staffId);
      let copyBookingData = { ...bookingData };
      copyBookingData.staffId = setDefaultStaff[0]._id;
      copyBookingData.staffName = `${setDefaultStaff[0].firstname} ${setDefaultStaff[0].lastname}`;
      setBookingData(copyBookingData);
      securrentUserStaff(currentUserStaff_);

      // console.log('staff==>', setDefaultStaff, localStorage.getItem('staff_id'));
      return {
        staffName: `${setDefaultStaff[0].firstname} ${setDefaultStaff[0].lastname}`,
        staffId: setDefaultStaff[0]._id,
      };
    } catch (error) {
      // showErrMsg(error);
      return null;
    }
  };
  const handleShowService = (index) => {
    setappointmentIndex(index);
    setStaffIndex(0);
    setnewlength(addedServices.length);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setShowService(!showService);
    setEditData({ edit: false, index: '', type: 'mark' });
    const lastService = addedServices[addedServices.length - 1];
    if (lastService) {
      const startTime = moment(lastService.end).format(timeFromDateFormat);
      setBookingData({ ...bookingData, startTime, ...otherBookingInitial });
    } else {
      setBookingData({ ...bookingData, ...otherBookingInitial });
    }
    setSearchTxt('');
    setSubcategories(null);
    setMisc(false);
  };
  const handleEditService = (services, index) => {
    setStaffIndex(index);
    if (index === 0) {
      setnewlength(0);
    } else {
      setnewlength(addedServices.length);
    }
    const updateObj = { ...bookingData, ...services };
    setBookingData(updateObj);
    setShowService(!showService);
    setEditData({ edit: true, index, type: 'mark' });
    if (parseFloat(services.duration) > 960) {
      setExtras({
        ...extras,
        value: services.duration,
        data: convertToReadableDuration(services.duration),
      });
    }
    const subcategory = services.subcategory.replace(
      services.subcategory.split('$')[1],
      services.renderCost.split('.')[1] === '00'
        ? addZero(services.renderCost)
        : addZero(services.cost),
    );
    setSearchTxt(subcategory);
  };
  const moveArr = (oldIndex, newIndex, sign) => {
    const servicesCopy = [...addedServices];
    const { duration, start, startTime } = addedServices[newIndex];
    let newNextStartTime;
    let currentNewStartTime;
    if (sign === 'plus') {
      const currentEntryStart = new Date(servicesCopy[oldIndex].start);
      const updatedNextEntryStart = moment(
        new Date(
          currentEntryStart.setMinutes(
            currentEntryStart.getMinutes() + parseFloat(duration),
          ),
        ),
      ).format(dateFormat);
      newNextStartTime = moment(updatedNextEntryStart).format(
        timeFromDateFormat,
      );
      currentNewStartTime = servicesCopy[oldIndex].startTime;
    } else if (sign === 'minus') {
      newNextStartTime = startTime;
      const updatedCurrentEntryStart = moment(
        new Date(
          new Date(start).setMinutes(
            new Date(start).getMinutes()
              + parseFloat(servicesCopy[oldIndex].duration),
          ),
        ),
      ).format(dateFormat);
      currentNewStartTime = moment(updatedCurrentEntryStart).format(
        timeFromDateFormat,
      );
    }
    const updatedOldIndex = {
      ...addedServices[oldIndex],
      startTime: newNextStartTime,
    };
    const updatedNewIndex = {
      ...addedServices[newIndex],
      startTime: currentNewStartTime,
    };
    servicesCopy[oldIndex] = { ...updatedOldIndex };
    servicesCopy[newIndex] = { ...updatedNewIndex };
    if (newIndex >= servicesCopy.length) {
      let k = newIndex - servicesCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        servicesCopy.push(undefined);
      }
    }
    servicesCopy.splice(newIndex, 0, servicesCopy.splice(oldIndex, 1)[0]);
    calculateDurations(servicesCopy);
  };
  const calculateDurations = (info) => {
    info.forEach((el) => {
      let start = moment(el.date).toDate();
      const time = el.startTime.split(':');
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);

      el.start = moment(start).format(dateFormat);
      el.end = moment(
        new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
      ).format(dateFormat);
    });
    setAddedServices([...info]);
  };
  const handleBookingData = (e) => {
    const { name, value } = e.target;
    const obj = { ...bookingData, [name]: value };
    if (name === 'staffName') {
      const staffId = staffList[e.target.selectedIndex]._id;
      obj[name] = value;
      obj[staffId] = staffId;
      if (
        appointmentType === 'oneteam_oneday'
        || appointmentType === 'oneteam_multiday'
      ) {
        setStaff(value, staffId);
      }
    }
    if (name === 'cost' && !bookingData.customCost) {
      const newval = addZero(value);
      obj[name] = newval;
    } else if (name === 'startTime') {
      obj.timeChange = true;
    } else if (name === 'duration') {
      obj.durationChange = true;
    } else if (name === 'quantity') {
      obj.quantityChange = true;
    } else if (name === 'pricingType') {
      obj.duration = bookingData.previousDuration;
      obj.quantity = '5';
      if (bookingData.customCost !== 'default') {
        obj.cost = '';
      }
    } else if (name === 'soldPer' && value !== 'Add Custom Measure') {
      obj.unit = '';
      obj.symbol = '';
    }
    // console.log('objobj', obj);
    setBookingData(obj);
  };
  const setStaff = (staffName, staffId) => {
    // console.log('staffId', staffId);
    const servicesListCopy = addedServices;
    servicesListCopy.forEach((el) => {
      el.staffId = staffId;
      el.staffName = staffName;
    });
    setAddedServices([...servicesListCopy]);
  };
  const getTimes = () => g();
  const g = () => {
    const x = {
      slotInterval: 5,
      openTime: '12:25 AM',
      closeTime: '23:30 PM',
    };

    // Format the time
    const startTime = moment(x.openTime, 'h:mm A');

    // Format the end time and the next day to it
    const endTime = moment(x.closeTime, 'h:mm A');

    // Times
    const allTimes = [];

    // Loop over the times - only pushes time with 30 minutes interval
    while (startTime <= endTime) {
      // Push times
      allTimes.push(startTime.format('h:mm A'));
      // Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    return allTimes;
  };
  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msgs = hours && minutes
        ? `${hours}hr ${minutes} min`
        : hours && !minutes
          ? `${hours}hr`
          : `${minutes} min`;
      arr.push({ value: val, data: msgs });
      val += 5;
    }
    return arr;
  };
  const handleAddService = async () => {
    const {
      subcategory,
      type,
      duration,
      previousDuration,
      previousQuantity,
      pricingType,
      customMisc,
      description,
    } = bookingData;
    let {
      cost, buyCostProduct, renderCost, quantity, startTime,
    } = bookingData;
    quantity = quantity.replace(/,/g, '');
    cost = parseFloat(cost.replace(/,/g, '').replace('$', '')).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, '').replace('$', ''),
    ).toFixed(2);
    renderCost = type === 'service' && pricingType === 'hourly'
      ? (parseFloat(cost) * parseFloat(quantity)) / 60
      : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === 'product') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === 'service' || pricingType === 'hourly') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }
    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      let start = moment(dateData[0].date).toDate();
      let time = bookingData.startTime.split(':');
      let bookingDataCopy = { ...bookingData };
      // const lastService = addedServices[0];
      const lastAppServ = addedServices.length;
      const lastServiceArr = service[lastAppServ - 1];
      const lastService = lastServiceArr;
      if (lastService) {
        start = moment(lastService.end).toDate();
        time = moment(lastService.end).format(timeFromDateFormat).split(':');
      }
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);
      bookingDataCopy.cost = cost;
      bookingDataCopy.quantity = quantity;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.description = customMisc || description;

      if (bookingDataCopy.buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      const { laborMargin, totalLabourData } = calculateMarginLabour(bookingDataCopy);

      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabourData;

      let durationInMin = duration;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 || pricingType === 'hourly'
          ? quantity
          : duration;
      }
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      bookingDataCopy.duration = durationInMin;
      bookingDataCopy.start = moment(start).format(dateFormat);
      bookingDataCopy.end = moment(
        new Date(
          start.setMinutes(
            start.getMinutes() + parseFloat(bookingDataCopy.duration),
          ),
        ),
      ).format(dateFormat);
      startTime = moment(bookingDataCopy.start).format(timeFromDateFormat);
      bookingDataCopy.startTime = startTime;
      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00'
          ? addZero(renderCost)
          : addZero(renderCost),
      );
      bookingDataCopy.timeChange = false;
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      if (!previousQuantity) {
        bookingDataCopy.previousQuantity = quantity;
      }

      if (!editData.edit) {
        finalServices = [...addedServices, bookingDataCopy];
      }
      // finalServices.sort((a, b) => ((a.startTime > b.startTime) ? 1 : -1));
      // let FillterServices = finalServices.sort((a, b) => new Date(...b.start.split('/').reverse()) - new Date(...a.start.split('/').reverse()));
      handleShowService();
      calculateSummary([...finalServices]);
      setAddedServices(finalServices);
      const copyClientAllData = { ...clientAllData };
      copyClientAllData.addedServices = finalServices;
      copyClientAllData.appointmentData.services = finalServices;
      copyClientAllData.appointmentData.dateData[0].service = finalServices;
      setClientAllData(copyClientAllData);
      setSaveUpdateStatus(true);
    } else {
      setErrorValue(errValue);
      toastMessage('error', msg);
    }
    setTimeout(() => {
      setErrorValue('');
    }, errorBorderTime);
  };

  const calculateSummary = (finalServices) => {
    const { chemicalTotalCost, chemicalTotaMargin, chemicalTotaDuration } = calculateAmount();

    // console.log('iutioturiotu', chemicalTotalCost, chemicalTotaMargin, chemicalTotaDuration);
    let totalCostWithoutProduct = 0;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    let totalCost2 = chemicalTotalCost || 0;
    let totalDuration2 = chemicalTotaDuration || 0;
    let totalLabourAmount2 = 0;
    let totalMargin2 = 0;
    let totalLabour2 = chemicalTotaMargin || 0;

    let totalCost3 = 0;
    let totalDuration3 = 0;
    let totalLabourAmount3 = 0;
    let totalMargin3 = 0;
    let totalLabour3 = 0;

    // console.log('finalServices===>>>', finalServices);

    finalServices.forEach((el) => {
      totalDuration2 += parseInt(el.duration, 10);
      totalCost2 += parseFloat(el.renderCost || 0);
      totalLabourAmount2 += parseFloat(el.totalLabour || 0);
      totalMargin2 += parseFloat(el.laborMargin !== '' ? el.laborMargin : 0);
      totalDuration3 += parseInt(el.duration, 10);
      totalCost3 += parseFloat(el.renderCost || 0);
      totalLabourAmount3 += parseFloat(el.totalLabour || 0);
      totalMargin3 += parseFloat(el.laborMargin !== '' ? el.laborMargin : 0);
    });
    totalLabour2 += totalLabourAmount2 + totalMargin2;
    totalLabour3 += totalLabourAmount3 + totalMargin3;
    // console.log('test Calulation Data ===>', {
    //   totalCost: addZero(parseFloat(totalCost2).toFixed(2)),
    //   totalDuration: totalDuration2,
    //   totalLabour: addZero(parseFloat(totalLabour2).toFixed(2)),
    //   multiTotal: totalCost2,
    //   totalMargin: addZero(parseFloat(totalMargin2).toFixed(2)),
    //   totalLabourOnly: addZero(parseFloat(totalLabourAmount2).toFixed(2)),
    // });
    const replaceComma = totalData.totalCost
      ? totalData.totalCost.replace(',', '')
      : 0;
    setTotalData({
      ...totalData,
      totalCost: addZero(parseFloat(totalCost2).toFixed(2)),
      totalDuration: totalDuration2,
      totalLabour: addZero(parseFloat(totalLabour2).toFixed(2)),
      multiTotal: totalCost2,
      totalMargin: addZero(parseFloat(totalMargin2).toFixed(2)),
      totalLabourOnly: addZero(parseFloat(totalLabourAmount2).toFixed(2)),
    });
    setSummary({
      ...summary,
      totalCost: addZero(parseFloat(totalCost3).toFixed(2)),
      totalDuration: totalDuration3,
      totalLabour: addZero(parseFloat(totalLabour3).toFixed(2)),
      multiTotal: totalCost2,
      totalMargin: addZero(parseFloat(totalMargin3).toFixed(2)),
      totalLabourOnly: addZero(parseFloat(totalLabourAmount3).toFixed(2)),
    });
    // const [summary, setSummary] = useState({
    //   totalCost: 0,
    //   totalDuration: '',
    //   totalLabourMargin: 0,
    // });
  };

  const handleDeleteService = () => {
    const currentServices = [...addedServices];
    currentServices.splice(editData.index, 1);
    if (editData.index === addedServices.length - 1) {
      setBookingData({
        ...bookingData,
        startTime: addedServices[editData.index].startTime,
      });
      setAddedServices([...currentServices]);
    } else {
      recalculateServices(addedServices[editData.index]);
    }
    calculateSummary([...currentServices]);
    setShowService(!showService);
    setSaveUpdateStatus(true);
  };
  const recalculateServices = (data) => {
    const remainingServices = addedServices.filter(
      (_el, i) => i > editData.index,
    );
    const previousServices = addedServices.filter(
      (_el, i) => i < editData.index,
    );
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = '';
      if (i === 0) {
        end = new Date(data.start);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(
        new Date(
          end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
        ),
      ).format(dateFormat);
    }
    const arr = previousServices;
    const newArray = arr.concat(remainingServices);
    setAddedServices([...newArray]);
  };
  const handleSearchCategory = async (searchTxt) => {
    console.log(searchTxt);
    try {
      setSearchTxt(searchTxt);
      if (searchTxt) {
        const response = await userInstance().get(
          `/service/searchCategory?value=${searchTxt
            .replace('$', '')
            .trim()}&industryId=${userData?.industryId._id}`,
        );
        const { services } = response.data;
        setCategories(services);
        if (services.length) {
          const subCat = [];
          services.forEach((ser) => {
            const reg = new RegExp(`${searchTxt.replace('$', '').trim()}`, 'i');
            const matchedSubcategories = ser.subCategory.filter(
              (el) => reg.test(el.cost)
                || reg.test(el.name)
                || reg.test(el.retailCost)
                || reg.test(el.serviceDescription),
            );
            subCat.push(...matchedSubcategories);
          });
          if (subCat.length) {
            setSubcategories([...subCat]);
          } else {
            setSubcategories([]);
          }
        } else {
          setSubcategories([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addSubcategoryToService = (value) => {
    if (value.toLowerCase().includes('misc')) {
      setMisc(true);
    }
    setBookingData({
      ...bookingData,
      category: value,
      subcategory: value,
    });
    setSearchTxt(value);
  };

  const calculateNewDurations = (data) => {
    let multiArray = [];
    multiArray = data;
    for (let i = 0; i < multiArray.length; i++) {
      const currentEl = multiArray[i];
      let end = '';
      if (i === 0) {
        end = new Date(data[0].end);
      } else {
        end = new Date(multiArray[i - 1].end);
        const startTime = moment(end).format(timeFromDateFormat);
        currentEl.startTime = startTime;
        currentEl.start = moment(end).format(dateFormat);
        currentEl.end = moment(
          new Date(
            end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
          ),
        ).format(dateFormat);
      }
    }
    // console.log('multiArray ===>', multiArray);
  };
  const editService = () => {
    const {
      start,
      startTime,
      subcategory,
      type,
      previousDuration,
      previousQuantity,
      duration,
      pricingType,
    } = bookingData;
    let {
      cost, buyCostProduct, renderCost, quantity,
    } = bookingData;
    console.log('booking data for edit', bookingData);
    quantity = quantity.replace(/,/g, '');
    cost = parseFloat(cost.replace(/,/g, '').replace('$', '')).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, '').replace('$', ''),
    ).toFixed(2);
    renderCost = type === 'service' && pricingType === 'hourly'
      ? (parseFloat(cost) * parseFloat(quantity)) / 60
      : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === 'product') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === 'service' || pricingType === 'hourly') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }

    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      const bookingDataCopy = { ...bookingData };
      const startDate = moment(start).toDate();
      const time = startTime.split(':');
      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00'
          ? addZero(renderCost)
          : addZero(renderCost),
      );
      bookingDataCopy.cost = cost;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.quantity = quantity;
      if (buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      startDate.setHours(time[0]);
      startDate.setMinutes(time[1]);
      startDate.setSeconds(0);
      bookingDataCopy.start = moment(startDate).format(dateFormat);
      const { laborMargin, totalLabourData } = calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabourData;
      let durationInMin = 0;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 || pricingType === 'hourly'
          ? quantity
          : duration;
        bookingDataCopy.duration = durationInMin;
      } else if (
        previousQuantity !== quantity
        || previousDuration !== duration
      ) {
        bookingDataCopy.previousDuration = durationInMin;
        bookingDataCopy.previousQuantity = bookingDataCopy.quantity;
      }
      bookingDataCopy.end = moment(
        new Date(
          startDate.setMinutes(
            startDate.getMinutes() + parseFloat(bookingDataCopy.duration),
          ),
        ),
      ).format(dateFormat);

      const currentServices = [...addedServices];
      currentServices[editData.index] = bookingDataCopy;
      finalServices = currentServices;
      // let FillterServices = finalServices.sort((a, b) => new Date(...b.start.split('/').reverse()) - new Date(...a.start.split('/').reverse()));
      calculateNewDurations(finalServices);
      // console.log('appointmnetData---', appointmnetData);
      // appointmnetData[0].service = FillterServices;
      calculateSummary([...finalServices]);
      setAddedServices(finalServices);
      setShowService(!showService);
      setSaveUpdateStatus(true);
    } else {
      setErrorValue(errValue);
      toastMessage('error', msg);
    }
    setTimeout(() => {
      setErrorValue('');
    }, errorBorderTime);
  };

  const validateService = () => {
    let isValid = true;
    let errValue = '';
    let msg = '';
    const {
      category, subcategory, staffName, type, customMisc, quantity,
    } = bookingData;
    let { cost, buyCostProduct } = bookingData;
    cost = cost.replace(/,/g, '').replace('$', '');
    buyCostProduct = buyCostProduct.replace(/,/g, '').replace('$', '');

    if (!category) {
      isValid = false;
      errValue = 'category';
      msg = 'Please select service or product';
    } else if (!subcategory) {
      isValid = false;
      errValue = 'subcategory';
      msg = 'Please select subcategory';
    } else if (
      bookingData.category.toLowerCase().includes('misc')
      && !customMisc
    ) {
      isValid = false;
      errValue = 'customMisc';
      msg = 'Enter New Name';
    } else if (!quantity) {
      isValid = false;
      errValue = 'quantity';
      msg = 'Enter quantity';
    }
    return { isValid, msg, errValue };
  };

  const calculateMarginLabour = (bookingDataCopy) => {
    const {
      cost, buyCostProduct, quantity, type, renderCost,
    } = bookingDataCopy;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    let totalLabourData = 0;

    if (type === 'product') {
      totalBuy += parseFloat(buyCostProduct);
      totalSellPrice += parseFloat(renderCost);
      // console.log('totalSellPrice', totalSellPrice, 'total buy', totalBuy);
      const totalLabourProduct = (
        totalSellPrice
        - totalBuy * parseFloat(quantity)
      ).toFixed(2);
      laborMargin = totalLabourProduct.toString();
    }
    if (type === 'service') {
      totalLabourData = bookingDataCopy.pricingType === 'hourly'
        ? calculateServiceLabour(bookingDataCopy)
        : cost;
    }
    return { totalLabourData, laborMargin };
  };
  const calculateServiceLabour = (bookingDataCopy) => {
    const convertedDuration = bookingDataCopy.pricingType === 'hourly'
      ? bookingDataCopy.quantity
      : bookingDataCopy.duration;

    let hour = convertToReadableDuration(
      addQuantityDuration(convertedDuration, parseFloat('1')),
    );
    let { hourWithQuantity, minutePart } = getHourWithQuantity(
      hour,
      bookingDataCopy.cost,
    );

    minutePart = (minutePart / 60) * parseFloat(bookingDataCopy.cost);
    let finalDuration = parseFloat(hourWithQuantity + minutePart);

    // eslint-disable-next-line no-mixed-operators
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return parseFloat(finalDuration).toFixed(2);
  };
  const addQuantityDuration = (duration, quantity) => {
    const updatedDuration = convertToReadableDuration(duration.toString());
    // console.log('updatedDuration', updatedDuration);
    let { hourWithQuantity, hourPart, minutePart } = getHourWithQuantity(
      updatedDuration,
      quantity,
    );

    let multipleMinute = parseFloat(
      `0.${hourWithQuantity.toString().split('.')[1]}`,
    );

    const updatedMinute = hourPart ? multipleMinute * (60 / hourPart) : 0;
    minutePart *= quantity;

    let finalDuration = parseFloat(hourWithQuantity.toString().split('.')[0]) * 60
      + updatedMinute
      + minutePart;
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return finalDuration.toString();
  };
  const getHourWithQuantity = (duration, multiplier) => {
    // console.log('multiplier', multiplier);
    let hourPart = duration.includes('hr')
      ? parseFloat(duration.split('hr')[0].trim())
      : 0;
    let minutePart = duration.includes('hr') && duration.includes('min')
      ? parseFloat(duration.split('hr')[1].split('min')[0])
      : duration.includes('min')
        ? parseFloat(duration.split('min')[0])
        : 0;

    let hourWithQuantity = hourPart * multiplier;
    // console.log('hourWithQuantity', hourWithQuantity);
    return { hourWithQuantity, hourPart, minutePart };
  };
  const handleChangeCustom = (value) => {
    const obj = { ...bookingData, customCost: value };
    if (value === 'default') {
      obj.cost = bookingData.previousCost;
      if (bookingData.type === 'product') {
        obj.buyCostProduct = bookingData.prevBuyCostProduct;
      }
    } else if (value === 'yes' || value === 'sellPrice') {
      obj.cost = '';
      obj.buyCostProduct = obj.prevBuyCostProduct;
    } else if (value === 'buyCost') {
      obj.buyCostProduct = '';
      obj.cost = obj.previousCost;
    } else if (value === 'buySell') {
      obj.cost = '';
      obj.buyCostProduct = '';
    }
    setBookingData(obj);
  };
  const handleTimePicker = () => {
    setTime(bookingData.startTime);
    setTimePickerShow(!timepickerShow);
  };
  const handleHourPicker = () => {
    setHourData(bookingData.quantity);
    setHourPicker(!hourPicker);
  };
  useEffect(() => {
    getStaffList();
    if (service) {
      calculateSummary(service);
    }
  }, [service]);

  const saveUpdateData = async () => {
    if (addedServices?.length === 0) {
      toastMessage('error', 'Please add maintenance required.');
      return;
    }
    const servicetotalTime = addedServices?.reduce(
      (pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0),
      0,
    );
    // console.log('chemicalalist==>', chemicalsArray);
    let chemtotalDuration = 0;
    if (chemicalsArray[0]?.testResult?.length > 0) {
      const arrList = chemicalsArray[0]?.testResult[0].service;
      const chemicalaTypeList = arrList?.filter(
        (el2) => el2.type === 'product' && el2.status === 'quantity',
      );
      chemtotalDuration = chemicalaTypeList?.reduce(
        (pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0),
        0,
      );
    }
    let totalDurationtimes = servicetotalTime + chemtotalDuration;
    // console.log('chemicalaTypeList==>', totalDurationtimes);

    const addduration = 15 - servicetotalTime;
    const editServiceIndex = addedServices?.length - 1;
    let updateServ = addedServices;
    let copySummary = { ...summary };
    if (totalDurationtimes < 15) {
      updateServ = updateSingleServiceDateTime(addedServices, editServiceIndex, addduration);
      copySummary.totalDuration = 15;
      // console.log('oldData==>', {
      //   updateServ, oldData, totalData, summary,
      // });
    }
    // console.log('Array List ==>', { chemicalalist, chemicalsArray });

    if (chemicalsArray[0]?.testResult?.length > 1) {
      let isMulti = !(clientAllData?.groupAppointments?.length > 1);
      const payload = {
        id: _id,
        totalData: summary.totalCost === 0 ? totalData : copySummary,
        service: updateServ,
        from: 'viewAppointment',
        chemicalalist,
        multi: isMulti,
        newAppointmentWater,
        calculater_type,
        oldObjectData: oldData,
        industryId: industryId._id,
      };

      const response = await userInstance().put(
        '/appointment/updatedService',
        payload,
      );
      const { code } = response.data;
      if (code === 200) {
        toastMessage('success', 'Pool Maintenance updated successfully');
        const copyClientAllData = { ...clientAllData };
        copyClientAllData.addedServices = addedServices;
        copyClientAllData.appointmentData.services = addedServices;
        copyClientAllData.appointmentData.dateData[0].service = addedServices;
        setClientAllData(copyClientAllData);
        setSaveUpdateStatus(false);
        SelectedStaff(localStorage.getItem('staff_id'));
        let oldUrl = getCalendarPath();
        history.push(oldUrl);
      }
    } else {
      const payload = {
        id: _id,
        totalData: summary.totalCost === 0 ? totalData : copySummary,
        service: updateServ,
        from: 'viewAppointment',
        chemicalalist,
        calculater_type,
        oldObjectData: oldData,
        isEditCompleted,
      };
      const response = await userInstance().put(
        '/appointment/updatedService',
        payload,
      );
      const { code } = response.data;
      if (code === 200) {
        SelectedStaff(localStorage.getItem('staff_id'));
        toastMessage('success', 'Pool Maintenance updated successfully');
        const copyClientAllData = { ...clientAllData };
        copyClientAllData.addedServices = addedServices;
        copyClientAllData.appointmentData.services = addedServices;
        copyClientAllData.appointmentData.dateData[0].service = addedServices;
        setClientAllData(copyClientAllData);
        setSaveUpdateStatus(false);
        let oldUrl = getCalendarPath();
        history.push(oldUrl);
      }
    }
    setisEditCompleted(false);
  };
  const initialDateData = {
    date: moment().format(dateFormat),
    createdOn: new Date(),
    interval_multiplier: null,
    occurence: null,
    interval_type: null,
    endsAt: null,
    endType: null,
    msg: "One-off appointment | Doesn't repeat",
    frequencyMsg: '',
    occurenceMsg: '',
    specificDate: moment().format(dateFormat),
    requiredday: 2,
    teammember: 2,
    appointmenttype: 'oneteam_oneday',
    service: [],
    notes: null,
    summary: null,
    repeatAppt: false,
    chemicalTestArray: [],
  };

  const [newAppointmentWater, setNewAppointmentWater] = useState([
    initialDateData,
  ]);

  const TotalPartVist = (el) => {
    let totalcostWater = el.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.renderCost ? next.renderCost : 0),
      0,
    );
    return totalcostWater;
  };
  const TotalPartduration = (el) => {
    let totalDurationWater = el.reduce(
      (pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0),
      0,
    );
    return totalDurationWater;
  };
  const TotalPartLabour = (el) => {
    let totalLabourOnlyWater = el.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.totalLabour ? next.totalLabour : 0),
      0,
    );
    let totalMarginWater = el.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.laborMargin ? next.laborMargin : 0),
      0,
    );
    let totalLabourWater = totalLabourOnly + totalMargin;
    return totalLabourWater;
  };

  useEffect(() => {
    if (chemicaltype === 'open') {
      const resulttChemical = JSON.parse(localStorage.getItem('chemicalalist'));
      if (chemicalalist && chemicalalist[0]?.testResult?.length >= 1) {
        let testCount = chemicalalist[0]?.testResult.length;
        const calcType = chemicalalist[0]?.testResult[0].serviceAnalysis;
        setCalculater_type(calcType);
        let apptypes = 'oneteam_oneday';
        let apptypemsg = "One-off appointment | Doesn't repeat";
        if (testCount > 1) {
          apptypes = 'oneteam_multiday';
          apptypemsg = `One Team Member Multi Visit | ${chemicalalist[0]?.testResult.length} days | Doesn't repeat`;
          setAppointmentType('oneteam_multiday');
        }
        let waterarr = [];
        let flag = new Date(appointmentData.appointmentdate);
        // console.log(' chemicalalist[0].testResult=====>', chemicalalist[0].testResult);
        chemicalalist[0]?.testResult.forEach((el, i) => {
          let copyinitialDateData = { ...initialDateData };
          const chemicalaTypeList = el.service.filter(
            (el2) => el2.type === 'product' && el2.status === 'quantity',
          );
          const serviceProTypeList = el.service.filter(
            (el2) => el2.type === 'service'
              || (el2.type === 'product' && el2.status !== 'quantity'),
          );
          copyinitialDateData.chemicalTestArray = chemicalaTypeList || [];
          copyinitialDateData.appointmenttype = apptypes;
          copyinitialDateData.msg = apptypemsg;
          // copyinitialDateData.client = client._id;
          copyinitialDateData.clientSiteAddressIndex = appointmentData.clientSiteAddressIndex;
          copyinitialDateData.staffId = appointmentData.staffId || '';
          copyinitialDateData.userid = appointmentData.userid || '';
          copyinitialDateData.teammember = appointmentData.teammember || '';
          copyinitialDateData.waterTestStatus = true;
          if (i === 0) {
            copyinitialDateData.date = moment(flag).format(dateFormat);
            if (serviceProTypeList && serviceProTypeList.length > 0) {
              let finalServices2 = [...service, ...serviceProTypeList];
              const finalServices = finalServices2.reduce((acc, current) => {
                const x = acc.find(
                  (item) => item.subcategory === current.subcategory,
                );
                if (!x) {
                  return acc.concat([current]);
                }
                return acc;
              }, []);
              copyinitialDateData.service = finalServices || [];
              calculateSummary(finalServices);
              setAddedServices(finalServices);
              const copyClientAllData = { ...clientAllData };
              copyClientAllData.addedServices = finalServices;
              copyClientAllData.appointmentData.services = finalServices;
              copyClientAllData.appointmentData.dateData[0].service = finalServices;
              setClientAllData(copyClientAllData);
            }
          } else if (
            calcType === 'Black-Spot Algae'
            || calcType === 'Drain Pool'
          ) {
            const { subSummary } = calculatServiceProCost(serviceProTypeList);
            const falgDate = moment(flag).add(2, 'days').format(dateFormat);
            copyinitialDateData.date = falgDate;
            copyinitialDateData.appointmentdate = falgDate;
            const changesNewService = updateServerDate(
              serviceProTypeList,
              falgDate,
            );
            copyinitialDateData.service = changesNewService || [];
            copyinitialDateData.summary = subSummary;
          } else if (calcType === 'Green Pool') {
            const { subSummary } = calculatServiceProCost(serviceProTypeList);
            const falgDate = moment(flag).add(4, 'days').format(dateFormat);
            copyinitialDateData.date = falgDate;
            const changesNewService = updateServerDate(
              serviceProTypeList,
              falgDate,
            );
            copyinitialDateData.service = changesNewService || [];
            copyinitialDateData.summary = subSummary;
            copyinitialDateData.appointmentdate = falgDate;
          } else {
            const { subSummary } = calculatServiceProCost(serviceProTypeList);
            const falgDate = moment(flag).add(1, 'days').format(dateFormat);
            copyinitialDateData.date = falgDate;
            const changesNewService = updateServerDate(
              serviceProTypeList,
              falgDate,
            );
            copyinitialDateData.service = changesNewService || [];
            copyinitialDateData.summary = subSummary;
            copyinitialDateData.appointmentdate = falgDate;
          }
          // console.log('fine service test=>', copyinitialDateData.service);
          flag = copyinitialDateData.date;
          waterarr.push(copyinitialDateData);
        });
        setNewAppointmentWater(waterarr);
      }
    }
  }, []);

  const handleWaterUpdate = async () => {
    console.log('id');
    let id = appointmentData?.appointment_calc_type ? pid : _id;
    let serviceType = appointmentData?.chemicalsArray[0]?.testResult[0];
    console.log('chemicalsArray', chemicalsArray);
    const { days, serviceAnalysis } = serviceType;
    try {
      if (
        days === 'Today'
        && serviceAnalysis === 'Sand Filter Media Replacement'
      ) {
        return;
      }
      if (id) {
        const response = await userInstance().get(
          `/watertest/getWaterHistoryByAppointmentId?id=${id}&pid=${pid}&appId=${_id}`,
        );
        const { waterList, groupAppointments, code } = response?.data;
        const copyClientAllData = { ...clientAllData };
        console.log({ copyClientAllData });
        copyClientAllData.waterTestHistory = waterList?.history;
        copyClientAllData.groupAppointments = groupAppointments;
        setClientAllData(copyClientAllData);
        localStorage.setItem('apptData', JSON.stringify(copyClientAllData));

        if (localStorage.getItem('s#@4f11') && localStorage.getItem('f#@5e11')) {
          let payload = {};

          if (waterList?.history?.serviceAnalysis === 'Water Test') {
            payload = {
              appId: _id,
              editChemicalList: true,
              watTesId: waterList?._id,
            };
          } else if (waterList?.chemicals?.length > 0) {
            payload = {
              appId: _id,
              visitMessage: true,
              watId: waterList?._id,
            };
          }

          socket.emit('editChemicalsFromViewAppt', {
            copyClientAllData,
            userId: userData?.userInformation?._id,
            paramsData: payload,
          });
          return;
        }

        if (waterList?.history?.serviceAnalysis === 'Water Test') {
          history.push(
            `/calculator?appId=${_id}&editChemicalList=true&watTesId=${waterList?._id}`,
          );
        } else if (waterList?.chemicals?.length > 0) {
          history.push(
            `/calculator?appId=${_id}&visitMessage=true&watId=${waterList?._id}`,
          );
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const isTest = waterTestStatus
    && chemDataFields?.length > 0
    && status !== 'Completed'
    && status !== 'Cancelled';
  const editCompletedAppointment = () => {
    setisEditCompleted(true);
    // console.log('okkk');
  };

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  return (
    <>
      <Modal
        size="md"
        show={appointmentDetail}
        onHide={!appointmentDetail}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`staff-modal appointment-details ${
          (waterTestStatus
            && chemDataFields?.length > 0
            && status !== 'Completed'
            && status !== 'Cancelled')
          || isEditCompleted
            ? 'view-appointment-details'
            : ''
        }  ${status === 'Completed' ? 'uncomplete-appointment' : ''}`}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="staff-close">
              <i
                className="fa fa-times"
                onClick={() => handleAppointmentDetails(comingClientId)}
                aria-hidden="true"
              />
            </div>
            <Modal.Header>
              <Modal.Title
                className={`${
                  status === 'Completed' ? 'completed-appointment' : ''
                }`}
              >
                {isEditCompleted
                  ? 'Edit Completed Scheduled Maintenance'
                  : status === 'Completed'
                    ? 'Pool Maintenance Completed'
                    : status === 'Cancelled'
                      ? 'Pool Maintenance Cancelled'
                      : 'Pool Maintenance Details'}
                {' '}
              </Modal.Title>
              <div className="service-edit-icon client-details-option">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={showmore} alt="show" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {status !== 'Completed' && status !== 'Cancelled' && (
                    <>
                      <Dropdown.Item>
                        <div className="action client" onClick={handleEdit}>
                          Edit Pool Maintenance
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          className="action client"
                          onClick={() => handleReschedule()}
                        >
                          Reschedule
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div className="action client" onClick={CancelAppt}>
                          Cancel Pool Maintenance
                        </div>
                      </Dropdown.Item>
                    </>
                    )}
                    <Dropdown.Item onClick={() => bookingHistory('pastbooking')}>
                      <div className="action">
                        <span>Past Maintenance</span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => bookingHistory('futurebooking')}>
                      <div className="action">
                        <span>Future Maintenance</span>
                      </div>
                    </Dropdown.Item>
                    {userPermissions.includes('Quotes') && (
                    <Dropdown.Item onClick={() => bookingHistory('quotes')}>
                      <div className="action">
                        <span>Quotes</span>
                      </div>
                    </Dropdown.Item>
                    )}

                    {industryId?.name === 'Pool Servicing' && (
                    <Dropdown.Item onClick={waterHistory}>
                      <div className="action client">Water Test History</div>
                    </Dropdown.Item>
                    )}
                    {status !== 'Cancelled'
                  && userPermissions.includes('Invoices & Payments') && (
                    <Dropdown.Item onClick={invoiceHistory}>
                      <div className="action">Invoices & Payments</div>
                    </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="view-add-appointment-body">
                {isEditCompleted ? (
                  ''
                ) : (
                  <>
                    {!isTest && (
                    <>
                      <div className="staff-form view-add-appointment">
                        {waterTestStatus
                  && chemDataFields.length > 0
                  && status !== 'Completed'
                  && status !== 'Cancelled' ? (
                            ''
                          ) : (
                            <>
                              <div className="client-details main-clients-details ok11">
                                <div className="client-left-details">
                                  <h6>
                                    {currentUserStaff?.firstname}
                                    {' '}
                                    {currentUserStaff?.lastname}
                                    {' '}
                                  </h6>
                                  <p>{getAddress()}</p>
                                  <p>
                                    <a className="mobile" href={`tel:${currentUserStaff?.mobileNo || ''}`}>
                                      {`${currentUserStaff?.mobileNo || ''}`}
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div className="client-details site-address">
                                <div className="client-left-details">
                                  <p />
                                  <p> </p>
                                  <p />
                                </div>
                              </div>
                              {/* afer appointment commpleted and before water test  layout */}
                              {addedServices
                      && addedServices.map((el, i) => (
                        <div className="pool-visit-cost">
                          {i === 0 ? (
                            <h5>
                              {el.start
                                ? moment(convertDateTime(eventDate)).format('dddd, D MMMM yyyy')
                                : ''}
                            </h5>
                          ) : (
                            ''
                          )}
                          <h6>
                            {' '}
                            {`${
                              el.category.toLowerCase().includes('misc')
                                ? `${el.customMisc}`
                                : el.subcategory
                            }`}
                          </h6>

                          <p>
                            <span>{displayTime(timeZone, el.start, timeDisplay)}</span>
                            {/* <span>
                              {el.startTime
                                ? moment(el.startTime, ['HH:mm']).format(
                                  timeDisplay,
                                )
                                : ''}
                            </span> */}
                            <span> | </span>
                            <span>
                              {` ${convertToReadableDuration(
                                el.duration,
                              )}`}

                            </span>
                            <span> | </span>
                            <span>
                              {'with '}
                              {el.staffName ? el.staffName : ''}
                            </span>
                          </p>
                        </div>
                      ))}
                              {waterTestStatus
                      && chemDataFields.length > 0
                      && chemDataFields[0].service.map((el, i) => (
                        <>
                          {(parseFloat(el?.cost) > 0
                          || el?.cost === 'N/A')
                        && el?.type === 'product' ? (
                          <div className="pool-visit-cost OK1">
                            <h6>{el.subcategory ? el.subcategory : ''}</h6>
                            <p>
                              <span>
                                {el.startTime
                                  ? moment(el.startTime, ['HH:mm']).format(
                                    timeDisplay,
                                  )
                                  : ''}
                              </span>
                              <span> | </span>
                              <span>
                                {` ${convertToReadableDuration(
                                  el.duration,
                                )}`}

                              </span>
                              <span> | </span>
                              <span>
                                {'with '}
                                {el.staffName ? el.staffName : ''}
                              </span>
                            </p>
                          </div>
                            ) : null}
                        </>
                      ))}
                            </>
                          )}
                        {status !== 'Completed' && status !== 'Cancelled' && (
                        <div className="view-btn service-submit single-btn">
                          {!waterTestStatus && chemDataFields.length === 0 ? (
                            <>
                              {industryId?.name === 'Pool Servicing' && (
                              <Button
                                className="add-btn water-test-btn"
                                onClick={HandleWaterTest}
                              >
                                Water Test
                              </Button>
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        )}
                      </div>
                    </>
                    )}
                  </>
                )}
                <>
                  {(waterTestStatus
              && chemDataFields.length > 0
              && status !== 'Completed'
              && status !== 'Cancelled')
            || isEditCompleted ? (
              <div className="staff-form view-add-appointment">
                {/* after water test appointment layout */}
                <div className="client-details main-clients-details ok22">
                  <div className="client-left-details">
                    <h6>
                      {currentUserStaff?.firstname}
                      {' '}
                      {currentUserStaff?.lastname}
                      {' '}
                    </h6>
                    <p>{getAddress()}</p>
                    <p>
                      <a className="mobile" href={`tel:${currentUserStaff?.mobileNo || ''}`}>
                        {`${currentUserStaff?.mobileNo || ''}`}
                      </a>
                    </p>
                  </div>
                </div>
                {eventDate && (
                  <div className="appointment-container finalize-cost-page">
                    <div className="appointment-view">
                      <div className="appointment-date" role="presentation">
                        <div className="">
                          <h3>
                            {`${moment(eventDate).format('dddd')}, ${moment(
                              eventDate,
                            ).format('D MMMM YYYY')}`}
                          </h3>
                        </div>
                        <div className="sorting-sub-services appointment-sort">
                          <div className="content-dropdown">
                            {/* <img src={arrow} alt="arrow" /> */}
                          </div>
                        </div>
                      </div>
                      <span className="sep" />
                      <div className="search-title service-head">
                        <h3> Maintenance Required</h3>
                        <div className="sorting-services">
                          {service && service.length > 1 ? (
                            <div className="sorting">
                              <img
                                src={sort}
                                alt="sorting"
                                onClick={() => setRenderSort(!renderSort)}
                              />
                            </div>
                          ) : null}
                          <span
                            className="plus-icon"
                            onClick={() => handleShowService(0)}
                            role="presentation"
                          >
                            <i className="fas fa-plus" />
                          </span>
                        </div>
                      </div>
                      {/* {console.log('addedServices sanjana ==>', addedServices)} */}
                      {addedServices
                        && addedServices.map((ser, i) => (
                          <div className="search-title">
                            <div
                              className="client-profile desktop-screen"
                              onClick={() => handleEditService(ser, i)}
                            >
                              <h3>
                                {`${
                                  ser.category.toLowerCase().includes('misc')
                                    ? `${ser.customMisc}`
                                    : ser.subcategory
                                } `}
                              </h3>
                              {/* <h3>
                                {`${ser.category.toLowerCase().includes('misc')
                                  ? `${ser.customMisc} $${addZero(
                                    ser.renderCost,
                                  )}`
                                  : ser.subcategory
                                } ${parseFloat(ser.quantity) > 1
                                  ? `($${`${addZero(ser.cost)}${ser.soldPer === 'Kilogram (kg)'
                                    ? ' per kg'
                                    : ''
                                  }`} x ${`${ser.type === 'service'
                                      && ser.pricingType === 'hourly'
                                    ? convertToReadableDuration(
                                      ser.quantity,
                                    )
                                    : `${ser.soldPer === 'Kilogram (kg)'
                                      ? `${ser.quantity}kg`
                                      : ser.quantity
                                    }`
                                  }${ser.type === 'service' ? ' ' : ''})`}`
                                  : ''
                                }`}
                              </h3> */}
                              <p>
                                <span>
                                  {moment(ser.startTime, [
                                    timeFromDateFormat,
                                  ]).format(timeDisplay)}
                                </span>
                                <span> | </span>
                                <span>
                                  {` ${convertToReadableDuration(
                                    ser.duration,
                                  )}`}
                                </span>
                                <span> | </span>
                                <span>{` with ${ser.staffName}`}</span>
                                <span> | </span>
                              </p>
                              {/* <p>
                                <span>
                                  {`${ser.type === 'product'
                                    ? `Margin $${addZero(ser.laborMargin)}`
                                    : ser.type === 'service'
                                      ? `Labour $${`${addZero(
                                        ser.totalLabour,
                                      )}`}`
                                      : null
                                  }`}
                                </span>
                              </p> */}
                            </div>
                            <div className="sorting-sub-services appointment-sort">
                              <div className="service-edit-icon">
                                {renderSort ? (
                                  <div
                                    className={`sorting up-down ${
                                      renderSort && 'active-sort'
                                    }`}
                                  >
                                    {i !== 0 ? (
                                      <img
                                        src={upsort}
                                        alt="sorting"
                                        onClick={() => moveArr(i, i - 1, 'minus')}
                                      />
                                    ) : null}
                                    {i !== addedServices.length - 1 ? (
                                      <img
                                        src={downsort}
                                        alt="sorting"
                                        onClick={() => moveArr(i, i + 1, 'plus')}
                                      />
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                              <div
                                className="content-dropdown"
                                onClick={() => handleEditService(ser, i)}
                              >
                                <Dropdown name="startingDate">
                                  <Dropdown.Toggle
                                    variant="link"
                                    id="dropdown-basic"
                                  >
                                    <img src={arrow} alt="arrow" />
                                  </Dropdown.Toggle>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                {waterTestStatus && chemDataFields?.length > 0 && (
                  <>
                    {/* <div className="client-details main-clients-details">
                      <div className="client-left-details">
                        <h6>
                          {currentUserStaff?.firstname}
                          {' '}
                          {currentUserStaff?.lastname}
                          {' '}
                        </h6>
                        <p>{getAddress()}</p>
                        <p>
                          <a className="mobile" href={`tel:${currentUserStaff?.mobileNo || ''}`}>
                            {`${currentUserStaff?.mobileNo || ''}`}
                          </a>
                        </p>
                      </div>
                    </div> */}
                    <span className="sep" />
                    <div className="finalize-cost-page">
                      <div className="added-chemicals-info">
                        <div className="chemical-cross">
                          <h6 onClick={handleWaterUpdate}>CHEMICALS REQUIRED</h6>
                          <i
                            className="fa fa-times"
                            aria-hidden="true"
                            onClick={removeWaterConfirm}
                          />
                        </div>

                        {waterTestStatus
                          && newAppointmentWater?.length > 0
                          && chemicaltype === 'open'
                          && newAppointmentWater[0]?.chemicalTestArray.map(
                            (chem, i) => (
                              <>
                                {(parseFloat(chem?.cost) > 0
                                  || chem.cost === 'N/A')
                                && chem.type === 'product' ? (
                                  <div className="chemical-description OK3">
                                    <div className="chemical-name">
                                      -
                                      {' '}
                                      {chem.subcategory || ''}
                                      {' '}
                                      {displayDollar(chem.renderCost)}
                                      <span>
                                        {displayCacliPrices(
                                          chem.renderCost,
                                          '',
                                        )}
                                        {' '}
                                      </span>
                                    </div>
                                    <div className="chemical-rate">
                                      {displayDollar(chem.renderCost)}
                                      <span>
                                        {displayCacliPrices(
                                          chem.renderCost,
                                          '',
                                        )}
                                        {' '}
                                      </span>
                                    </div>
                                  </div>
                                  ) : null}
                              </>
                            ),
                          )}
                        {waterTestStatus
                          && chemicaltype !== 'open'
                          && chemDataFields?.length > 0
                          && chemDataFields[0]?.service.map((chem, i) => (
                            <>
                              {(parseFloat(chem?.cost) > 0
                                || chem?.cost === 'N/A')
                              && chem.type === 'product' ? (
                                <div className="chemical-description OK4">
                                  <div className="chemical-name">
                                    -
                                    {' '}
                                    {chem?.subcategory || ''}
                                    {' '}
                                    {displayDollar(chem?.renderCost)}
                                    <span>
                                      {displayCacliPrices(chem?.renderCost, '')}
                                    </span>
                                  </div>
                                  <div className="chemical-rate">
                                    {displayDollar(chem?.renderCost)}
                                    <span>
                                      {' '}
                                      {displayCacliPrices(chem?.renderCost, '')}
                                    </span>
                                  </div>
                                </div>
                                ) : null}
                            </>
                          ))}
                      </div>
                    </div>
                  </>
                )}
                <span className="sep" />
                <div className="finalize-cost-page">
                  <div className="visit-message">
                    <h6>{visitMessage || ''}</h6>
                  </div>
                  <div className="footer-btn view-btn view-appointment-water">
                    {saveUpdateStatus
                    || (!saveUpdateStatus
                      && chemicalsArray[0]?.testResult.length > 1
                      && chemicaltype === 'open')
                    || isEditCompleted ? (
                      <>
                        <Button
                          className="appointment-btn btn-save"
                          onClick={saveUpdateData}
                        >
                          Save/Update
                        </Button>
                      </>
                      ) : (
                        <Button
                          className="testing-btn appointment-btn btn-save"
                          onClick={handleCheckList}
                        >
                          Mark as Completed
                          {' '}
                        </Button>
                      )}
                  </div>
                </div>
              </div>
                    ) : (
                      ''
                    )}
                </>
                {(!waterTestStatus || status === 'Completed') && !isEditCompleted && (
                <div className="staff-form view-add-appointment">
                  <div className="appointment-form">
                    <Form>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Notes</Form.Label>
                        {!editJob && (
                        <div className="job-notes-content">
                          <p>{note || ''}</p>
                          <p>
                            {notesmain || teamnotes}
                            {/* {notesmain !== undefined
                            ? notesmain
                            : teamnotes || ''} */}
                          </p>
                        </div>
                        )}
                        {editJob && (
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Only Visible to Team Members"
                          value={notesmain}
                          onChange={(e) => setnotesMain(e.target.value)}
                          name="notes"
                        />
                        )}
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="appointment-total">
                    {chemicalalist && chemicalalist?.length > 0 ? (
                      <>
                        {status === 'Completed' && paymentStatus === 'unpaid' ? (
                          <>
                            {/* <div
                          className={`appointment-total ${Invoice.paymentId.status !== 'paid'
                            ? 'unpaid-appointment-total'
                            : ''
                          }`}
                        >
                          <h5>
                            Unpaid Visit Total: $
                            {Invoice.balanceDue || '0'}
                          </h5>
                          <h6 className="duration-time">
                            {`Duration: ${convertToReadableDuration(
                              totalDuration,
                            )}`}
                          </h6>
                          <h6>
                            {`Labour & Margin: $${totalLabour
                              ? currencyComma(
                                calculateAmount().allTotalMargin2.toFixed(2),
                              )
                              : 0
                            }`}
                          </h6>
                        </div> */}
                            <div className="appointment-total visit-message">
                              <h6>{visitMessage || ''}</h6>
                            </div>
                          </>
                        ) : (
                          <>

                            <h6 className="duration-time">
                              Duration:
                              {' '}
                              {/* {totalDuration
                            ? calculateAmount().allDurationTotal2
                            : '0 min'} */}
                              {totalDataSummary?.totalDuration ? convertToReadableDuration(totalDataSummary?.totalDuration) : '0 min'}

                            </h6>

                          </>
                        )}

                        {status === 'Completed' && paymentStatus !== 'unpaid' ? (
                          <>
                            {/* <div className="mt-2">
                          <h6>
                            Payment Method:
                            {' '}
                            {Invoice?.paymentId?.paymentMethod}
                          </h6>
                          <h6>
                            $
                            {Invoice?.paymentId?.amount}
                            {' '}
                            received by:
                            {' '}
                            {Invoice?.paymentId?.paymentReceivedBy}
                          </h6>
                          <h6>
                            {`${moment(Invoice?.paymentId?.createdAt).format(
                              'dddd, Do MMMM YYYY',
                            )} at ${moment(Invoice?.paymentId?.createdAt).format(
                              timeDisplay,
                            )}`}

                          </h6>
                          <h6>
                            Invoice Balance Due:
                            {' '}
                            {Invoice?.balanceDue > 0
                              ? `$${currencyComma(Invoice?.balanceDue)}`
                              : 'Paid in full'}
                          </h6>
                        </div> */}
                            <div className="appointment-total visit-message">
                              <h6>{visitMessage || ''}</h6>
                            </div>
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {status === 'Completed' && paymentStatus === 'unpaid' ? (
                          <>
                            {/* <div
                          className={`appointment-total ${Invoice.paymentId.status !== 'paid'
                            ? 'unpaid-appointment-total'
                            : ''
                          }`}
                        >
                          <h5>
                            Unpaid Visit Total: $
                            {Invoice.balanceDue || '0'}
                          </h5>
                          <h6 className="duration-time">
                            {`Duration: ${convertToReadableDuration(
                              totalDuration,
                            )}`}
                          </h6>
                          <h6>
                            {parseFloat(totalLabourOnly) > 0
                              && parseFloat(totalMargin) > 0
                              ? `Labour & Margin: $${totalLabour}`
                              : parseFloat(totalMargin) > 0
                                ? `Margin: $${totalMargin}`
                                : parseFloat(totalLabourOnly) > 0
                                  ? `Labour: $${totalLabourOnly}`
                                  : 'Labour & Margin: $0'}
                          </h6>
                        </div> */}
                          </>
                        ) : (
                          <>
                            <h6 className="duration-time">
                              Duration:
                              {' '}
                              {totalDataSummary?.totalDuration ? convertToReadableDuration(totalDataSummary?.totalDuration) : '0 min'}
                            </h6>
                            {/* <h6>
                          {parseFloat(totalLabourOnly) > 0
                            && parseFloat(totalMargin) > 0
                            ? `Labour & Margin: $${currencyComma(totalLabour)}`
                            : parseFloat(totalMargin) > 0
                              ? `Margin: $${currencyComma(totalMargin)}`
                              : parseFloat(totalLabourOnly) > 0
                                ? `Labour: $${currencyComma(totalLabourOnly)}`
                                : 'Labour & Margin: $0'}

                        </h6> */}
                            {/* <div className="appointment-total visit-message">
                          <h6>{visitMessage || ''}</h6>
                        </div> */}
                          </>
                        )}
                        {/* {status === 'Completed' && paymentStatus !== 'unpaid' ? (
                      <>
                        <div className="mt-2">
                          <h6>
                            Payment Method:
                            {' '}
                            {Invoice?.paymentId.paymentMethod}
                          </h6>
                          <h6>
                            $
                            {Invoice?.paymentId.amount}
                            {' '}
                            received by:
                            {' '}
                            {Invoice?.paymentId.paymentReceivedBy}
                          </h6>
                          <h6>
                            {`${moment(Invoice.paymentId.createdAt).format(
                              'dddd, Do MMMM YYYY',
                            )} at ${moment(Invoice.paymentId.createdAt).format(
                              timeDisplay,
                            )}`}

                          </h6>
                          <h6>
                            Invoice Balance Due:
                            {' '}
                            {Invoice.balanceDue > 0
                              ? `$${currencyComma(Invoice.balanceDue)}`
                              : 'Paid in full'}
                          </h6>
                        </div>
                      </>
                    ) : null} */}
                        <div className="appointment-total visit-message">
                          <h6>{visitMessage || ''}</h6>
                        </div>
                      </>
                    )}
                  </div>
                  {status === 'Completed' && !isEditCompleted ? (
                    <div
                      className={`footer-btn view-btn ${
                        editJob ? '' : 'single-btn'
                      }`}
                    >
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          View Other Detail
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => Rebook()}>
                            Rebook
                          </Dropdown.Item>

                          {/* <Dropdown.Item onClick={() => handleInvoicePopup()}>
                        View Invoice
                      </Dropdown.Item> */}
                          <Dropdown.Item onClick={waterHistoryById}>
                            View Water Test
                          </Dropdown.Item>
                          <Dropdown.Item onClick={editJobNotes}>
                            Edit Job Notes
                          </Dropdown.Item>
                          <Dropdown.Item onClick={handleViewCheckList}>
                            View maintenance checklist
                          </Dropdown.Item>
                          <Dropdown.Item onClick={editCompletedAppointment}>
                            Edit Completed Scheduled Maintenance
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {editJob && (
                      <Button
                        onClick={saveNotes1}
                        className="appointment-btn btn-save"
                      >
                        Save/Update
                      </Button>
                      )}
                      {/* {!editJob
                    && (Invoice.paymentId.status !== 'paid' ? (
                      <Button
                        className="appointment-btn btn-save"
                        onClick={() => goToPayments(Invoice._id, _id)}
                      >
                        Apply Payment
                      </Button>
                    ) : (
                      <Button
                        className="appointment-btn btn-save"
                        onClick={() => Rebook()}
                      >
                        Rebook
                      </Button>
                    ))} */}
                    </div>
                  ) : status === 'Cancelled' ? (
                    <div className="view-btn rebook-btn">
                      <Button
                        className="appointment-btn btn-save"
                        onClick={() => Rebook()}
                      >
                        Rebook
                      </Button>
                    </div>
                  ) : (
                    <div className="footer-btn view-btn">
                      {saveUpdateStatus ? (
                        <>
                          <Button
                            className="appointment-btn btn-save"
                            onClick={saveUpdateData}
                          >
                            {}
                            Save/Update
                            {' '}
                            {}
                          </Button>
                        </>
                      ) : (
                        <Button
                          className="appointment-btn btn-save"
                          onClick={handleCheckList}
                        >
                          Mark as Completed
                          {' '}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
                )}

              </div>

            </Modal.Body>
          </>
        )}
      </Modal>
      {/* <DirectionPopup
        handleDirection={handleDirection}
        directionPopup={directionpopup}
        siteAddresses={createdAddress}
        coords={coords}
      /> */}
      <AppointmentCheckList
        handleCheckList={handleCheckList}
        checkList={checkList}
        handleMarkasComplete={handleMarkasComplete}
        chemicalsAdded={chemicalsAdded}
        image={image}
        handleFileInputChange={handleFileInputChange}
        removePhotos={removePhotos}
        AddCaptions={AddCaptions}
        checklistArray={checklistArray}
        handleChecked={handleChecked}
        error={errorValue}
      />
      {viewChecklist && (
      <ViewAppointmentCheckList
        handleCheckList={handleViewCheckList}
        checkList={viewChecklist}
        image={images}
        checklistArray={checklist}
      />
      )}
      {showInvoice && (
      <PreviewInvoicePopup
        handleInvoicePopup={handleInvoicePopup}
        showInvoice={showInvoice}
        invoiceData={invoiceDetail}
        chemicalsArray={chemicalsArray}
        handleClose={handleClose}
        VoidInvoice={VoidInvoice}
        permission={voidPermission}
        userPermissions={userPermissions}
      />
      )}
      {editAppointment && (
      <Editappointment
        editAppointment={editAppointment}
        handleEdit={handleEdit}
        handleCloseEdit={handleCloseEdit}
        idd={_id}
        pid={pid}
          // getAppointments={getAppointments}
        setRandom={setRandom}
        durationPicker={durationPicker}
        setDurationPicker={setDurationPicker}
        durationData={durationData}
        setDuration={setDuration}
        handleDurationPicker={handleDurationPicker}
        setBookingData={setBookingData}
        bookingData={bookingData}
        initialBookingData={initialBookingData}
        currentDates={currentDates}
        eventDate={eventDate}
        handleAppointmentDetails={handleAppointmentDetails}
        SelectedStaff={SelectedStaff}
        setEventDate={setEventDate}
      />
      )}
      {cancel && (
      <Cancel
        closeCancel={closeCancel}
        setCancel={setCancel}
          // getAppointments={getAppointments}
        setRandom={setRandom}
        appointmentData={appointmentData}
        eventDate={eventDate}
        activeStaff={activeStaff}
        SelectedStaff={SelectedStaff}
        handleAppointmentDetails={handleAppointmentDetails}
        groupAppointments={clientAllData.groupAppointments || []}
      />
      )}
      <ServiceModal
        showService={showService}
        setShowService={setShowService}
        handleShowService={handleShowService}
        newLength={newLength}
        categories={categories}
        subcategories={subcategories}
        handleBookingData={handleBookingData}
        getTimes={getTimes}
        bookingData={bookingData}
        renderOptions={renderOptions}
        staffList={staffList}
        handleAddService={handleAddService}
        editData={editData}
        handleDeleteService={handleDeleteService}
        handleSearchCategory={handleSearchCategory}
        setSubcategories={setSubcategories}
        addSubcategoryToService={addSubcategoryToService}
        editService={editService}
        setBookingData={setBookingData}
        staffIndex={staffIndex}
        misc={misc}
        addedServices={addedServices}
        servicesLength={addedServices.length}
        extras={extras}
        handleChangeCustom={handleChangeCustom}
        timepickerShow={timepickerShow}
        handleTimePicker={handleTimePicker}
        subcategoryTxt={subcategoryTxt}
        setSearchTxt={setSearchTxt}
        durationPicker={durationPicker}
        setDurationPicker={setDurationPicker}
        convertToReadableDuration={convertToReadableDuration}
        handleDurationPicker={handleDurationPicker}
        setnewlength={setnewlength}
        setHourPicker={setHourPicker}
        hourPicker={hourPicker}
        handleHourPicker={handleHourPicker}
        appointmentType={appointmentType}
        error={errorValue}
      />
      {durationPicker && (
      <DurationPicker
        durationPicker={durationPicker}
        setDurationPicker={setDurationPicker}
        durationData={durationData}
        handleDurationPicker={handleDurationPicker}
        setDuration={setDuration}
        setBookingData={setBookingData}
        convertToReadableDuration={convertToReadableDuration}
        bookingData={bookingData}
        subcategory={null}
        setSubCategory={null}
      />
      )}
      {hourPicker ? (
        <HourPicker
          setHourPicker={setHourPicker}
          handleHourPicker={handleHourPicker}
          hourPicker={hourPicker}
          hourData={hourData}
          handleDurationPicker={handleDurationPicker}
          setHourData={setHourData}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      ) : null}
      {timepickerShow ? (
        <TimePicker
          timepickerShow={timepickerShow}
          handleTimePicker={handleTimePicker}
          bookingData={bookingData}
          setBookingData={setBookingData}
          timeData={timeData}
          setTime={setTime}
        />
      ) : null}

      {invoicepopup ? (
        <DeleteInvoicePopup
          handleInvoicePopuncheck={handleInvoicePopuncheck}
          setInvoicePopup={setInvoicePopup}
        />
      ) : null}

      {waterTestPopup ? (
        <DeleteWaterTestPopup
          setWaterTestPopup={setWaterTestPopup}
          handleWaterTestPopuncheck={removeWaterTest}
        />
      ) : null}
      {popup ? (
        <ChemicalPopup
          popup={popup}
          setPopUp={setPopup}
          handlePopuncheck={handlePopuncheck}
        />
      ) : null}
    </>
  );
};
ViewAppointment.propTypes = {
  appointmentDetail: PropTypes.func.isRequired,
  handleAppointmentDetails: PropTypes.func.isRequired,
  appointmentData: PropTypes.func.isRequired,
  addedServices: PropTypes.func.isRequired,
  setRandom: PropTypes.func.isRequired,
  eventDate: PropTypes.func.isRequired,
  SelectedStaff: PropTypes.func.isRequired,
  activeStaff: PropTypes.func.isRequired,
  customerDues: PropTypes.func.isRequired,
  setCustomerDues: PropTypes.func.isRequired,
  finaliseState: PropTypes.func.isRequired,
  clientid: PropTypes.func.isRequired,
  comingClientId: PropTypes.string.isRequired,
  forManagementEdit: PropTypes.bool.isRequired,
  setForManagementEdit: PropTypes.bool.isRequired,
  setAppointmentDetail: PropTypes.func.isRequired,
  setEventDate: PropTypes.func.isRequired,
  setAddedServices: PropTypes.func.isRequired,
  chemicaltype: PropTypes.string.isRequired,
};

export default ViewAppointment;
