import React, { useState, useContext, useEffect } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import showmore from '../../assets/home/showmore.png';
import sort from '../../assets/home/sort.png';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import QuoteDetail from './qoutedetail';
import QuoteListing from './QuoteListing';
import SearchModal from './searchmodal';
import { showErrMsg, queryString, getCalendarPath } from '../../functions/index';
import SearchQuote from './searchQuote-listing';
import { userInstance } from '../../config/axios';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import target from '../../assets/home/icon/target.png';
import edit from '../../assets/home/grey-edit.png';
import clientimg from '../../assets/home/client.png';
import greydelete from '../../assets/home/grey-delete.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import sortClientList from '../../functions/listSort';

const Quotes = ({ setBookingTime }) => {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const history = useHistory();
  const { client: clientIdentity, clienttype } = queryString();
  const [quoteType, setQuoteType] = useState('New Quote');
  const [client, setclient] = useState();
  const [clientTxt, setClientTxt] = useState('');
  const [searchedClients, setClients] = useState([]);
  const [searchedClients2, setClients2] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [addClient, setAddClient] = useState([]);
  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(0);
  const [showQuote, setShowQuote] = useState(null);
  const [saveQuoteType, setsaveQuoteType] = useState('');
  const [view, setView] = useState('invoiceList');
  const { clientAllData, setClientAllData } = useContext(AuthDataContext);
  const [isHideSearch, setHideSearch] = useState(false);
  const [activeAdd, setActiveAdd] = useState(0);

  const handleClient = async () => {
    setclient(!client);
    if (client) {
      setClients(null);
      setClientTxt('');
    } else {
      const response = await userInstance().get('/customer/getCustomersAll2');
      if (response.status === 200) {
        const { customers } = response.data;
        const newList = sortClientList(customers);
        setClients(newList);
        setClients2(newList);
      }
    }
  };
  const searchClient = (searchTxt_) => {
    setClientTxt(searchTxt_);
  };
  useEffect(() => {
    let searchTimeOut = setTimeout(async () => {
      if (!clientTxt) {
        setClients(searchedClients2);
        return;
      }
      try {
        console.log('API CALEED HERE IN TIMEOUT', clientTxt);
        const response = await userInstance().get(`/customer/searchCustomer?value=${clientTxt}`);
        let list = response.data.customers;
        if (clientTxt) {
          if (isHideSearch) {
            console.log('check3');
            list.forEach((client2) => {
              if (client2.siteAddressess) {
                client2.siteAddressess.forEach((addr) => {
                  addr.hide = false;
                });
              }
            });
          } else {
            console.log('wantConditions');
            list = list.filter((el) => (el.siteAddresses ? el.siteAddresses.filter((adr) => adr.hide).length === 0 : el));
          }
        }
        const newList = sortClientList(list);
        setClients(newList);
      } catch (error) {
        console.log(error);
      }
    }, 500);
    // console.log('intravl2');
    return (() => clearInterval(searchTimeOut));
  }, [clientTxt]);

  const handleAddClient = (info) => {
    const copy = { ...info };
    setSelectedClient(info._id);
    if (info.siteAddresses[activeAdd] || info.siteAddresses.length === 0) {
      let { activeSiteAdd } = info.siteAddresses[activeAdd]
        ? info.siteAddresses[activeAdd]
        : {};
      if (activeSiteAdd || info.siteAddresses.length === 0) {
        setAddClient([info]);
        clientAllData.clientAllDetails = info;
        handleClient();
        setClientTxt('');
        localStorage.setItem('apptData', JSON.stringify(clientAllData));
        // console.log('client-', client);
      } else {
        NotificationManager.error(
          'Pleass select site address',
          'Message',
          errorMsgTime,
        );
      }
    } else {
      NotificationManager.error(
        'Pleass select site address',
        'Message',
        errorMsgTime,
      );
    }
  };

  const handleQuotesStatus = (e) => {
    setQuoteType(e.target.value);
    setSkip(0);
    setPageCounts(0);
  };
  const deleteClient = () => {
    setSelectedClient('');
    setAddClient([]);
    setClientTxt('');
  };
  const handleBackPage = () => {
    if (clientIdentity) {
      // setBookingTime('Past Bookings');
      history.push(`${getCalendarPath()}&clientid=${clientIdentity}`);
    }
  };
  const handleEditDetails = (data, data2) => {
    const url = `/client?clientid=${data}&name=${data2}&typee=edit&back=quotes`;
    history.push(url);
  };

  useEffect(() => {
    if (clienttype === 'edit') {
      const clinfo = clientAllData.clientAllDetails;
      setSelectedClient(clinfo._id);
      setAddClient([clinfo]);
    }
    if (clienttype === 'close') {
      handleClient();
    }
  }, [clienttype]);
  const checkAddress = (index, id) => {
    setActiveAdd(index);
    let oldState = [...searchedClients];
    oldState.forEach((el) => {
      if (el._id === id) {
        el.siteAddresses?.forEach((el2, i) => {
          if (index === i) {
            el2.activeSiteAdd = true;
          } else {
            el2.activeSiteAdd = false;
          }
        });
      } else {
        el.siteAddresses.forEach((el2, i) => {
          el2.activeSiteAdd = false;
        });
      }
    });
    setClients(oldState);
    // handleAddAppointmentPopup();
  };

  const updateView = (key) => {
    setQuoteType(key);
    // setActiveLink(key);
  };

  return (
    <AdminWrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {clientIdentity && (
            <div className="back-option">
              <span />
              <i className="fa fa-times" aria-hidden="true" onClick={handleBackPage} />

            </div>
            )}

            <div className="appointment-container finalize-cost-page new-invoice quotes-page">
              <div className="main-titles-mob">
                <h3>Quotes</h3>
                <div className="content-dropdown">
                  <div className="client-option service-edit-icon">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={showmore} alt="show" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('New Quote')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span> New Quote</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('Saved Quotes')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Saved Quotes</span>
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('Pending Quotes')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Pending Quotes</span>
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('pending')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Search Pending Quotes</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('saved')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Search Saved Quotes</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('all')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Search All Quotes</span>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="main-titles-desktop">
                <div className="dropdown-option-section">
                  <div className={`option-list ${quoteType === 'New Quote' ? 'active-menu' : ''}`} onClick={() => updateView('New Quote')}>
                    <img src={target} alt="edit" />
                    New Quotes
                  </div>
                  <div className={`option-list ${quoteType === 'Saved Quotes' ? 'active-menu' : ''}`} onClick={() => updateView('Saved Quotes')}>
                    <img src={target} alt="edit" />
                    Saved Quotes
                  </div>
                  <div className={`option-list ${quoteType === 'Pending Quotes' ? 'active-menu' : ''}`} onClick={() => updateView('Pending Quotes')}>
                    <img src={target} alt="edit" />
                    Pending Quotes
                  </div>
                  <div className={`option-list ${quoteType === 'pending' ? 'active-menu' : ''}`} onClick={() => updateView('pending')}>
                    <img src={target} alt="edit" />
                    Search Pending Quotes
                  </div>
                  <div className={`option-list ${quoteType === 'saved' ? 'active-menu' : ''}`} onClick={() => updateView('saved')}>
                    <img src={target} alt="edit" />
                    Search Saved Quotes
                  </div>
                  <div className={`option-list ${quoteType === 'all' ? 'active-menu' : ''}`} onClick={() => updateView('all')}>
                    <img src={target} alt="edit" />
                    Search All Quotes
                  </div>
                </div>
              </div>
              <div className="add-appointment">
                <div className="row newinvoice-row">
                  {/* <div className="col-lg-12 col-md-4">
                    <div className="search-container">
                      <div className="search-title">
                        <h3> Quotes </h3>
                        <span className="plus-icon" role="presentation">
                          <i className="fas fa-plus" />
                        </span>
                      </div>
                      <div className="search-title">
                        <div className="client-profile">
                          <div className="">
                            <h3>James Marker</h3>
                            <p>
                              12 Evansdale Road,  Hawthorn Victoria
                            </p>
                            <p>+61 467 675 755</p>
                          </div>
                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <img src={showmore} alt="show" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <div className="action">
                                  <img src={sort} alt="edit" />
                                  Delete
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-12 col-md-8">
                    <div className={`appointment-view appointment-service-modal ${quoteType === 'New Quote' ? 'newQuote-page' : ''} ${quoteType === 'Pending Quotes' ? 'pendingQuote-page' : ''}${quoteType === 'Saved Quotes' ? 'savedQuote-page' : ''} ${quoteType === 'pending' ? 'searchPendingQuote-page' : ''}${quoteType === 'saved' ? 'savedSavedQuote-page' : ''} ${quoteType === 'all' ? 'searchAllQuote-page' : ''}`}>
                      <div className="search-container">
                        {addClient.length === 0 ? (
                          <div className="search-title">
                            <h3> Client </h3>
                            <span className="plus-icon" role="presentation">
                              <i className="fas fa-plus" onClick={handleClient} />
                            </span>
                          </div>
                        ) : (
                          <div className="quotes-client-edit">
                            {addClient && addClient.map((cl) => (
                              <>
                                <div className="search-title" key={cl?._id}>
                                  <div className="client-profile">

                                    <div className="">
                                      <h3>{`${cl?.firstname} ${cl?.lastname || ''}`}</h3>
                                      <p>
                                        {`${cl?.streetAddress}${cl?.suburb ? cl?.suburb : '' || cl?.state ? '' : ''} ${cl?.suburb || ''
                                        } ${cl?.state || ''}`}
                                      </p>
                                      <p>{cl?.mobileNo}</p>

                                    </div>
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                      >
                                        <img src={showmore} alt="show" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>

                                        <Dropdown.Item>
                                          <div
                                            className="action client"
                                            onClick={() => {
                                              handleEditDetails(
                                                cl?._id,
                                                cl?.firstname,
                                              );
                                            }}
                                          >
                                            <img src={edit} alt="edit" />
                                            Edit Client Details
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <div
                                            className="action client"
                                            onClick={handleClient}
                                          >
                                            <img src={clientimg} alt="edit" />
                                            Change Client
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <div
                                            className="action"
                                            onClick={() => deleteClient([])}
                                          >
                                            <img src={greydelete} alt="edit" />
                                            Delete
                                          </div>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="client-profile">
                                  <div className="client-name-contact site-addresses">
                                    {activeAdd !== '' && activeAdd >= 0 && cl?.siteAddresses?.length > 0 && (
                                    <div className="client-left-details">
                                      <h5 className="client-company-name">
                                        Site Address
                                      </h5>
                                      <p>
                                        {cl.siteAddresses[activeAdd]
                                          .householderName
                                          ? cl.siteAddresses[activeAdd]
                                            .householderName
                                          : ''}
                                      </p>
                                      <p>
                                        {cl.siteAddresses[activeAdd].address
                                          ? cl.siteAddresses[activeAdd]
                                            .address
                                          : ''}
                                        {' '}
                                      </p>
                                      <p>
                                        <a
                                          className="mobile"
                                          href={`tel:${
                                            cl.siteAddresses[activeAdd]
                                              .mobileNo || ''
                                          }`}
                                        >
                                          {cl?.siteAddresses[
                                            activeAdd
                                          ].mobileNo.includes('+61')
                                            ? cl?.siteAddresses[activeAdd]
                                              .mobileNo
                                            : `+61 ${
                                              cl?.siteAddresses[
                                                activeAdd
                                              ].mobileNo.replace(
                                                phoneRegex,
                                                '$1 $2 $3',
                                              ) || ''
                                            }`}
                                        </a>
                                      </p>
                                    </div>
                                    )}
                                  </div>
                                </div>

                              </>
                            ))}
                          </div>
                        )}
                      </div>
                      {/* <Form.Group controlId="formBasicHiddenEmployees">
                        <Form.Label>New, Pending or Saved Quotes</Form.Label>
                        <div className="select-box">
                          <i className="fas fa-sort-down" />
                          <Form.Control
                            as="select"
                            name="pricingType"
                            value={quoteType}
                            selected={quoteType}
                            onChange={handleQuotesStatus}
                          >
                            <option value="New Quote">New Quote</option>
                            <option value="Saved Quotes">Saved Quotes</option>
                            <option value="Pending Quotes">Pending Quotes</option>
                            <option value="pending">Search Pending Quotes</option>
                            <option value="saved">Search Saved Quotes</option>
                            <option value="all">Search All Quotes</option>
                          </Form.Control>
                        </div>
                      </Form.Group> */}
                      {quoteType === 'New Quote' ? (
                        <QuoteDetail
                          addClient={addClient}
                          setQuoteType={setQuoteType}
                          showQuote={showQuote}
                          setShowQuote={setShowQuote}
                          saveQuoteType={saveQuoteType}
                          setAddClient={setAddClient}
                          setSelectedClient={setSelectedClient}
                          activeAdd={activeAdd}
                          setActiveAdd={setActiveAdd}
                          setsaveQuoteType={setsaveQuoteType}
                        />
                      ) : (
                        null
                      )}
                      {(quoteType === 'Pending Quotes') || (quoteType === 'Saved Quotes') ? (
                        <QuoteListing
                          quoteType={quoteType}
                          selectedClient={selectedClient}
                          skip={skip}
                          setSkip={setSkip}
                          pageCounts={pageCounts}
                          setPageCounts={setPageCounts}
                          setQuoteType={setQuoteType}
                          showQuote={showQuote}
                          setShowQuote={setShowQuote}
                          setsaveQuoteType={setsaveQuoteType}
                          addClient={addClient}
                        />
                      ) : (
                        null
                      )}
                      {(quoteType === 'pending' || quoteType === 'saved' || quoteType === 'all') ? (
                        <SearchQuote selectedClient={selectedClient} quoteType={quoteType} />
                      ) : (
                        null
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SearchModal
        showSearch={client}
        handleShowSearch={handleClient}
        searchClient={searchClient}
        searchedClients={searchedClients}
        clientTxt={clientTxt}
        handleAddClient={handleAddClient}
        setHideSearch={setHideSearch}
        isHideSearch={isHideSearch}
        checkAddress={checkAddress}
        activeAdd={activeAdd}
      />
    </AdminWrapper>

  );
};
Quotes.propTypes = {
  setBookingTime: PropTypes.func.isRequired,
};
export default Quotes;
