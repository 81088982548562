/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import {
  Modal, Form, Col, Row, Button, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { NotificationManager } from 'react-notifications';
import 'react-datepicker/dist/react-datepicker.css';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import { userInstance } from '../../config/axios';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import toastMessage from '../../functions/toastMessage';

const AddProductForm = ({
  show,
  HandleOpenClose,
  startDate,
  setStartDate,
  editData,
  getProducts,
}) => {
  const { userData: { industryId } } = useContext(AuthDataContext);

  const initialValues = {
    renderName: '',
    currentStockItems: '',
    cost: '',
    rowId: 1,
  };

  const [addmultiple, setAddmultiple] = useState(editData ? [editData] : [initialValues]);
  const [loading, setLaoding] = useState(false);

  const handleaddItems = () => {
    const copyObj = [...addmultiple];
    copyObj.push({ ...initialValues, rowId: copyObj.at(-1).rowId + 1 });
    setAddmultiple(copyObj);
  };

  const handleRemove = (rowId) => {
    let filterData = addmultiple.filter((el, i) => el.rowId !== rowId);
    setAddmultiple(filterData);
  };

  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    const copyAddmultiple = JSON.parse(JSON.stringify(addmultiple));
    copyAddmultiple[index][name] = value;
    setAddmultiple(copyAddmultiple);
  };

  // eslint-disable-next-line consistent-return
  const handleSaveUpdateOrder = async (e) => {
    e.preventDefault();
    try {
      const copyAddMultiple = JSON.parse(JSON.stringify(addmultiple));
      if (!loading) {
        const checkKeysData = (data) => Object.keys(data).some((x) => data[x] === '' || data[x] === null || data[x] === undefined);
        for (let index = 0; index < copyAddMultiple.length; index++) {
          const checkEmptyValues = checkKeysData(copyAddMultiple[index]);
          if (checkEmptyValues) {
            return toastMessage('error',
              `Please enter all fields at Row ${index + 1}`);
          }
        }
        if (!startDate) {
          return toastMessage('error',
            'Please select date.');
        }
        let payload = {
          productDate: startDate,
        };
        if (!editData) {
          payload.productDetails = copyAddMultiple;
          payload.industryId = industryId;
        } else {
          payload.productDetails = copyAddMultiple.at(0);
          payload._id = editData._id;
        }
        let response = null;
        setLaoding(true);
        if (!editData) {
          response = await userInstance().post('/inventory/product-add', payload);
        } else {
          response = await userInstance().patch('/inventory/product-add', payload);
        }
        setLaoding(false);
        if (response) {
          const { code, msg } = response?.data || {};
          if (code === 201) {
            getProducts();
            toastMessage('success',
              msg);
            HandleOpenClose(true);
          }
        }
      }
    } catch (error) {
      console.log('error--', error);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={() => HandleOpenClose(true)}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="service-modal add-order-popup"
      >
        <div className="staff-close">
          <i
            className="fa fa-times"
            onClick={() => HandleOpenClose(true)}
            aria-hidden="true"
          />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {editData ? 'Edit' : 'Add'}
            {' '}
            Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="modal-form">
                <Form onSubmit={handleSaveUpdateOrder}>
                  <Form.Group
                    as={Row}
                    controlId="formInput1"
                  >
                    <Form.Label column sm="3">
                      Date
                    </Form.Label>
                    <Col sm="9">
                      <div className="custom-date">
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="d MMM, yyyy" />
                      </div>
                    </Col>
                  </Form.Group>
                  {addmultiple && addmultiple.map((el, i) => (
                    <div className="add-multiple-order" key={`${el._id}`}>
                      <Form.Group
                        as={Row}
                        controlId="formInput1"
                      >
                        <Form.Label column sm="3">
                          Product Name
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            placeholder="Enter Product Name"
                            name="renderName"
                            value={el?.renderName}
                            onChange={(e) => (handleOnChange(e, i))}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="formInput1"
                      >
                        <Form.Label column sm="3">
                          Quantity
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="number"
                            placeholder="Enter Quantity"
                            name="currentStockItems"
                            value={el?.currentStockItems}
                            onChange={(e) => (handleOnChange(e, i))}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="formInput1"
                      >
                        <Form.Label column sm="3">
                          Price
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="number"
                            placeholder="Enter Price"
                            name="cost"
                            value={el?.cost}
                            onChange={(e) => (handleOnChange(e, i))}
                          />
                        </Col>
                      </Form.Group>
                      {!editData && (
                      <>
                        {i === addmultiple.length - 1 ? (
                          <i className="fa fa-plus" onClick={handleaddItems} />
                        ) : (
                          <i className="fa fa-minus" aria-hidden="true" onClick={() => handleRemove(el.rowId)} />
                        )}

                      </>
                      ) }
                    </div>
                  ))}
                  <div className="view-btn">
                    <Button
                      type="button"
                      className="btn btn-edit"
                      onClick={() => HandleOpenClose(true)}
                    >
                      {' '}
                      Cancel
                      {' '}
                    </Button>
                    <Button
                      type="Submit"
                      className="btn btn-save"
                    >
                      {!loading ? 'Save/Update' : <Spinner animation="border" />}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

AddProductForm.propTypes = {
  show: PropTypes.bool.isRequired,
  HandleOpenClose: PropTypes.func.isRequired,
  editData: PropTypes.objectOf.isRequired,
  startDate: PropTypes.objectOf.isRequired,
  setStartDate: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
};

export default AddProductForm;
