/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-fallthrough */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import {
  Table, Button, Form, Dropdown, Accordion, Card,
} from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import Adminwrapper from '../admin/adminwrapper/wrapper';
import greyplus from '../../assets/home/plus.png';
import showmore from '../../assets/home/showmoreblue.png';
import ca from '../../assets/home/icon/ca.png';
import d from '../../assets/home/icon/d.png';
import chlorinatorimg from '../../assets/home/icon/chlorinator.png';
import percentage from '../../assets/home/icon/percentage.png';
import target from '../../assets/home/icon/target.png';
import { userInstance } from '../../config/axios';
import defaultPredictiveOrderChemicals from './predictiveDefaultState';
import { successMsgTime } from '../../functions/intervalTime';
import Loader from '../loader/loader';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import toastMessage from '../../functions/toastMessage';
import { coverBlankField } from '../../functions';
import './subscriptionOrdering.css';
import TaxApplicable from './taxApplicable';
import ChemicalCosts from './chemicalCosts';

function SubscriptionOrdering() {
  const {
    userData,
  } = useContext(AuthDataContext);
  // const [view, setView] = useState('chemicalTargetLevels');
  const [activeLink, setActiveLink] = useState('chemicalTargetLevels');
  const [state, setState] = useState([]);
  const [revertChemicals, setRevertChemicals] = useState([]);
  const [run, setRun] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkBlankFields, setCheckBlankFields] = useState([]);

  // new Change
  const [view, setView] = useState('orderChemicals');
  const [errorState, setErrorState] = useState('');
  const [taxStatus, setTaxStatus] = useState(false);
  const initialTaxObj = {
    chemicalsTax: '',
    labourTax: '',
  };
  const history = useHistory();

  const isUsImperial = userData?.calendarSettings?.unit !== 'metric';
  const removeInCaseOfImperial = ['Magna Minerals (10 kg)', 'Aquatherepe Minerals (10 kg)', 'Aquatherepe Transform (10 kg)', 'Fine Glass Media (15 kg)', 'Coarse Glass Media (15 kg)'];

  const [errorValue, setErrorValue] = useState('');

  const getAllPredictiveChemicals = async () => {
    try {
      const response = await userInstance().get(`/inventory/getPreditiveOrderChemicals?view=${view}`);
      const { poolData } = response?.data;
      console.log({ poolData });
      setLoading(false);
      if (poolData[view]) {
        if (view === 'orderChemicals') {
          setState([...poolData[view]]);
          if (poolData[`revert${view}`]) {
            setRevertChemicals([...poolData[`revert${view}`]]);
          }
        } else if (view === 'taxApplicable') {
          setState({ ...poolData[view] });
          if (poolData[`revert${view}`]) {
            setRevertChemicals({ ...poolData[`revert${view}`] });
          }
        }
      } else if (!poolData[view]) {
        if (view === 'taxApplicable') {
          setState({ ...initialTaxObj });
        }
      }
      // if (response?.data?.allPredictiveChemicals?.length > 0) {
      //   setState(response?.data?.allPredictiveChemicals);
      //   setRevertChemicals(response?.data?.allRevertPredictiveChemicals);
      // }
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  console.log({ state, revertChemicals });

  useEffect(() => {
    if (view) {
      setLoading(true);
      getAllPredictiveChemicals();
    }
  }, [view]);

  useEffect(() => {
    if (run) {
      setRun(false);
      updatePredictiveChemicals();
    }
  }, [run]);

  const updatePredictiveChemicals = async () => {
    const response = await userInstance().put('/inventory/updatePreditiveOrderChemicals', { key: view, data: state });
    if (response?.data) {
      const { msg, code } = response.data;
      if (code === 200) {
        toastMessage('success', msg);
        getAllPredictiveChemicals();
      }
    }
  };
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChange = (e, parentIndex, childIndex) => {
    let { value, name } = e.target;
    console.log({ value, name, state });
    if (value && !value.match(/^(\d{0,7})(\.[0-9]{0,2})?$/)) {
      return;
    }
    const copyChemicals = [...state];
    copyChemicals[parentIndex].unitAndPrice[childIndex][name] = value;

    setState(copyChemicals);
  };

  const chemicalCostsBlank = () => {
    let arr = [];
    // console.log('=>>>>>>>>state', state);
    state?.forEach((elem) => {
      elem?.unitAndPrice?.forEach((el) => {
        if (!el?.buycost || !el?.sellprice) {
          if (!(arr.includes(elem.calciName))) {
            arr = [...arr, elem.calciName];
          }
        }
      });
    });
    return arr;
  };
  const taxApplicableBlank = () => {
    let arr = [];
    Object.keys(state).forEach((el) => {
      if (!state[el]?.trim()) {
        arr = [...arr, el];
      }
    });
    return arr;
  };

  const handleSaveUpdate = () => {
    let arr = [];
    switch (view) {
      case 'orderChemicals':
        arr = chemicalCostsBlank();
        break;
      case 'taxApplicable':
        arr = taxApplicableBlank();
        break;
      default:
        break;
    }
    setCheckBlankFields(arr);
    if (arr?.length > 0) {
      let errorVal = errorValue === '0' ? '1' : '0';
      setErrorValue(errorVal);
      toastMessage('error', 'Fields cannot be blank');
      return;
    }
    setRun(true);
  };

  const handleRestoreOriginalSettings = () => {
    setState([...defaultPredictiveOrderChemicals]);
    setRun(true);
  };

  const handlePreviousSavedDedfault = () => {
    setState([...revertChemicals]);
    setRun(true);
  };

  // function for VolumeChange

  const handleVolumeChange = (e, index) => {
    const copyState = [...state];
    const { value, name } = e.target;
    const fIndex = copyState[index]?.unitAndPrice?.findIndex((el) => el.volume === value);
    console.log('value', { value, name, fIndex });
    if (fIndex >= 0) {
      copyState[index].selectedIndex = fIndex;
    }
    console.log({ copyState });
    setState([...copyState]);
  };

  const handleView = (viewName) => {
    if (viewName === 'subscriptionManagement') {
      history.push('/subscriptions?page=predictiveOrdering');
      return;
    }
    setView(viewName);
  };

  useEffect(() => {
    if (errorValue) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errorValue]);

  const handleRequest = () => {

  };

  // new Change

  const handleChangeTaxValues = (e) => {
    const { name, value } = e.target;
    console.log({ name, value });
    if (value && !value.match(/^(\d{0,3})(\.[0-9]{0,2})?$/)) {
      return;
    }
    setState({ ...state, [name]: value });
  };

  console.log('==<<<', { checkBlankFields });

  const renderToView = () => {
    let componentToRender = null;
    switch (view) {
      case 'orderChemicals':
        componentToRender = (
          <ChemicalCosts
            state={state}
            handleChangeTaxValues={handleChangeTaxValues}
            error={errorState}
            taxStatus={taxStatus}
            isUsImperial={isUsImperial}
            removeInCaseOfImperial={removeInCaseOfImperial}
            handleChange={handleChange}
            handleRestoreOriginalSettings={handleRestoreOriginalSettings}
            handlePreviousSavedDedfault={handlePreviousSavedDedfault}
            handleRequest={handleSaveUpdate}
            checkBlankFields={checkBlankFields}
          />
        );
        break;
      case 'taxApplicable':
        componentToRender = (
          <TaxApplicable
            state={state}
            handleChangeTaxValues={handleChangeTaxValues}
            handleRequest={handleSaveUpdate}
            error={errorState}
            taxStatus={taxStatus}
            checkBlankFields={checkBlankFields}
          />
        );
        break;
      default:
        break;
    }
    return componentToRender;
  };

  return (
    <Adminwrapper>
      {loading ? <Loader />
        : (
          <div className=" pool-set-up chemical-predective-ordering">
            <div className="container">
              <div className="row webview-pool-section">
                <div className="col-md-12">
                  <div className="top-heading">
                    <h1>Chemical Costs for Predictive Ordering</h1>
                  </div>
                  {/* <div className="dropdown-option-section">
                    <div className={`option-list ${activeLink === 'chemicalTargetLevels' ? 'active-menu' : ''}`} onClick={() => updateView('chemicalTargetLevels')}>
                      <img src={target} alt="edit" />
                      Chemistry Target Levels
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="pool-bg">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="main-titles-mob">
                      <h3>Chemical Costs for Predictive Ordering</h3>
                    </div> */}
                    <div className="main-titles-mob">
                      <h3>Chemical Costs for Predictive Ordering</h3>
                      <div className="content-dropdown" />

                      <div className="client-option service-edit-icon">
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <img src={showmore} alt="show" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div className="action" onClick={() => handleView('subscriptionManagement')}>
                              <span>
                                <img src={target} alt="edit" />
                              </span>
                              <span> Subscription Management</span>
                            </div>
                            <div className="action" onClick={() => handleView('taxApplicable')}>
                              <span>
                                <img src={target} alt="edit" />
                              </span>
                              <span> Tax Applicable</span>
                            </div>
                            <div className="action" onClick={() => handleView('orderChemicals')}>
                              <span>
                                <img src={percentage} alt="edit" />
                              </span>
                              <span> Chemical Costs</span>
                            </div>
                            {/* <div className="action">
                              <span><img src={searchgray} alt="edit" /></span>
                              <span>Show Hidden data in Search</span>
                            </div> */}
                            {/* <div className="action">
                              <span><img src={download} alt="edit" /></span>

                            </div> */}
                            {/* <div className="action">
                <span>
                  <img src={upload} alt="edit" />
                </span>
                <span> Upload Client List </span>
              </div> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                {renderToView()}
              </div>
            </div>
          </div>
        )}
    </Adminwrapper>
  );
}
export default SubscriptionOrdering;
