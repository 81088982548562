import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from 'moment';
import { SyncLoader } from 'react-spinners';
import markdownit from 'markdown-it';
import DOMPurify from 'dompurify';
import { isMobile } from 'react-device-detect';
import TextareaAutosize from 'react-textarea-autosize';
import { FaArrowCircleUp } from 'react-icons/fa';
import { fetchEventSource } from '@microsoft/fetch-event-source';

import './index.css';

const md = markdownit({
  html: true,
  linkify: true,
  typographer: true,
});

const ChatBot = (props) => {
  const { handleClose, countryName } = props;
  const [messages, setMessages] = useState([
    {
      type: 'bot',
      text: isMobile ? 'Hello!\n\nAsk me any questions about our\n\nPoolWater-Pro software, or get\n\nhelp to solve your pool issues.' : 'Hello!\n\nAsk me any questions about our PoolWater-Pro software, or get help to solve your pool issues.',
      timestamp: moment().format('hh:mm'),
    },
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [allContent, setAllContent] = useState('');
  const messagesEndRef = useRef(null);
  const scrollbarsRef = useRef(null);
  const sseRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToBottom();
    }
  };

  const sendQuestion = () => {
    if (input.trim() === '' || isLoading) return;

    setIsChat(true);
    setIsLoading(true);

    const newMessage = {
      type: 'user',
      text: input,
      timestamp: moment().format('hh:mm'),
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);

    scrollToBottom();
  };

  useEffect(() => {
    if (!isChat) return;
    const systemPrompt = `Here is User's information:\n${countryName}\n`;
  
    // let chatHistory = getChatHistory();
  
    setInput('');
  
    const ctrl = new AbortController();
    fetchEventSource('https://chatbot.poolwater-pro.com/api/langchain/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      body: JSON.stringify({
        prefix: systemPrompt,
        messages,
        is_app: 'yes',
      }),
      signal: ctrl.signal,
      onmessage(msg) {
        if (msg.data) {
          setAllContent((prevContent) => {
            const updatedContent = prevContent + msg.data.replace(/\\n/g, '\n').replace(/\\t/g, '\t');
            setIsLoading(false);
            setMessages((prevMessages) => {
              const lastMessage = prevMessages[prevMessages.length - 1];
              if (lastMessage.type === 'user') {
                return [
                  ...prevMessages,
                  {
                    type: 'bot',
                    text: updatedContent,
                    timestamp: moment().format('hh:mm'),
                  },
                ];
              } 
              if (lastMessage.type === 'bot') {
                const updatedMessages = [...prevMessages];
                updatedMessages[updatedMessages.length - 1].text = updatedContent;
                return updatedMessages;
              } 
              return prevMessages;
            });
            return updatedContent;
          });
        }
      },
      onclose() {
        setIsChat(false);
        setIsLoading(false);
        setAllContent('');
      },
      onerror(err) {
        setIsChat(false);
        setIsLoading(false);
        setAllContent('');
      },
    });
  }, [isChat]);

  const handleKeyDown = (e) => {
    if (!isMobile) {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();
        setInput(`${input}\n`);
      } else if (e.key === 'Enter') {
        e.preventDefault();
        sendQuestion();
      }
    }
  };

  return (
    <section className="avenue-messenger">
      <div className="menu">
        <button className="button" type="button" onClick={handleClose}>
          ✕
        </button>
      </div>
      <div className="agent-face">
        <div className="half">
          <img
            className="agent chatbot-circle"
            src="https://poolwater-pro.com/wp-content/uploads/2024/05/bot-e1716661303394.jpeg"
            alt="Jesse Tino"
          />
        </div>
      </div>
      <div
        className="chat"
        style={{
          height: isMobile ? 'calc( 100% - 33px )' : 'calc( 100% - 15px )',
        }}
      >
        <div className="chat-title">
          <span>HelpBot</span>
        </div>
        <div className="messages">
          <Scrollbars
            ref={scrollbarsRef}
            className="messages-content"
            onScroll={(e) => {
              e.stopPropagation();
            }}
          >
            {messages.map((msg, index) => (
              <div
                key={msg.timestamp + index.toString()}
                className={`message ${
                  msg.type === 'user' ? 'message-personal' : 'new'
                }`}
              >
                {msg.type === 'bot' && (
                  <figure className="avatar">
                    <img
                      src="https://poolwater-pro.com/wp-content/uploads/2024/05/bot-e1716661303394.jpeg"
                      alt="Bot Avatar"
                    />
                  </figure>
                )}
                <div
                  className="message-box-content"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(md.render(msg.text)) }}
                />
                {/* {msg.type === 'bot' && (
                  <>
                    <div className="timestamp">{msg.timestamp}</div>
                    <div className="checkmark-sent-delivered">✓</div>
                    <div className="checkmark-read">✓</div>
                  </>
                )} */}
              </div>
            ))}
            {isChat && isLoading && (
              <div className="message new">
                <figure className="avatar">
                  <img
                    src="https://poolwater-pro.com/wp-content/uploads/2024/05/bot-e1716661303394.jpeg"
                    alt="Bot Avatar"
                  />
                </figure>
                <SyncLoader
                  size={6}
                  color="grey"
                  loading={isLoading}
                  speedMultiplier={0.7}
                />
              </div>
            )}
            <div ref={messagesEndRef} />
          </Scrollbars>
        </div>
        <div className="message-box">
          <TextareaAutosize
            className="message-input"
            placeholder="Type your question here…"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            maxRows={10}
          />
          <button
            type="submit"
            aria-label="Send Message"
            className="message-submit"
            onClick={sendQuestion}
            disabled={isLoading}
          >
            <FaArrowCircleUp size={28} color="black" />
          </button>
        </div>
      </div>
    </section>
  );
};

ChatBot.propTypes = {
  handleClose: PropTypes.func.isRequired,
  countryName: PropTypes.string.isRequired,
};

export default ChatBot;
