import { NotificationManager } from 'react-notifications';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';

let showState = false;
let isInterval;
let IntervalTime = 0;
let isTimerInterval;

export const LoadExistValue = (valueData) => {
  // eslint-disable-next-line no-restricted-globals
  if (valueData && !isNaN(valueData)) {
    return parseFloat(parseFloat(valueData).toFixed(2));
  }
  return 0;
};

export const validateData = (data) => {
  let isValid = true;
  const emptyField = {};
  Object.keys(data).forEach((el) => {
    if (!data[el]) {
      isValid = false;
      emptyField[el] = true;
    }
  });
  return { isValid, emptyField };
};

export const limitWidthHeight = (inputObj) => {
  let { value } = inputObj;
  if (value === '00' || value === '0.0') {
    return 0;
  } if (value.charAt(0) === '.') {
    value = `0${value}`;
  }
  const limitAmount = 100.0;
  const splitVal = value.toString().split('.');
  const isValid = splitVal.length > 1 ? splitVal[1].length <= 2 : true;
  if (value <= limitAmount && isValid) {
    return inputObj;
  }

  return 0;
};

export const limitInputValues = (inputObj, limit) => {
  let { value } = inputObj;
  if (value === '00') {
    return 0;
  }
  if (value.charAt(0) === '.') {
    value = `0${value}`;
  }
  const limitAmount = limit;
  const splitVal = value.toString().split('.');
  const isValid = splitVal.length > 1 ? splitVal[1].length <= 2 : true;
  if (value <= limitAmount && isValid) {
    return inputObj;
  }
  return 0;
};

export const limitPoolCost = (inputObj) => {
  const { value } = inputObj;
  const limitAmount = 1000000.0;
  const splitVal = value.toString().split('.');
  const isValid = splitVal.length > 1 ? splitVal[1].length <= 2 : true;
  if (value <= limitAmount && isValid) {
    return inputObj;
  }
  return 0;
};

export const labourCostCalculation = (hours, labourCostt, greenSplitName, poolCapacity) => {
  let labourPrice = 0;
  if (greenSplitName === 'Granular' || greenSplitName === 'Liquid' || greenSplitName === 'Black-Spot') {
    labourPrice = hours * labourCostt;
  }
  if (greenSplitName === 'Drain') {
    labourPrice = ((hours * labourCostt) / 40000) * poolCapacity;
  }
  return Math.ceil(labourPrice / 5) * 5;
};

export const handleUnit = (data) => {
  let unit = 'litres';
  let actualQuantity = data;
  if (data < 1) {
    unit = 'ml';
    actualQuantity = data * 1000;
  }
  if (actualQuantity?.toString()?.includes('.')) {
    actualQuantity = parseFloat(actualQuantity).toFixed(2);
  }
  return { unit, actualQuantity };
};

export const totalSellCost = (data) => {
  let totalLab = data.filter((el) => el).reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.renderCost ? (next.renderCost === 'N/A' ? 0 : next.renderCost) : 0), 0);
  return totalLab.toFixed(2);
};

export const consumableMargin = (data) => {
  let totalBuy = data.filter((el) => el).reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.buyCostProduct ? (next.buyCostProduct === 'N/A' ? 0 : next.buyCostProduct) : 0), 0);

  let totalSell = data.filter((el) => el).reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.renderCost ? (next.renderCost === 'N/A' ? 0 : next.renderCost) : 0), 0);

  return (totalSell - totalBuy).toFixed(2);
};

export const consumableDatabaseMargin = (data, checkingDrainStatus, levelData) => {
  console.log(data, checkingDrainStatus, levelData);
  let parentIndex = checkingDrainStatus ? 1 : 0;
  let totalBuy = data.filter((el) => el).reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.buyCostProduct ? (next.buyCostProduct === 'N/A' ? 0 : next.buyCostProduct) : 0), 0);

  let totalSell = levelData[parentIndex].service.filter((el) => el).reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.renderCost ? (next.renderCost === 'N/A' ? 0 : next.renderCost) : 0), 0);

  return (totalSell - totalBuy).toFixed(2);
};

export const getDrainWaterLevelPercent = (waterAmount, width, height, capacity) => {
  let waterlev = 0;
  waterlev = waterAmount * height * width;
  waterlev = Math.floor(parseFloat(waterlev) / 1000) * 1000;
  let result = waterlev && Math.round((waterlev / capacity) * 100);
  return result;
};

export const calciumAdjustmentCal = (calcium, salt) => {
  // const calcium = 800;
  // const salt = 6900;
  let e18 = salt / 4500 * 100;
  let f18 = 70 * e18 / 100;
  let g18 = 100 - f18;
  let h18 = calcium * g18 / 100;
  let adjusetedCalcium30 = Math.ceil(h18 / 5) * 5;
  let e21 = salt / 4500;
  let f21 = 30 / e21;
  let h21 = calcium * f21 / 100;
  let adjusetedCalcium15 = Math.ceil(h21 / 5) * 5;

  const result = salt > 4500 ? adjusetedCalcium15 : adjusetedCalcium30;

  if (salt > 4500) {
    console.log('ooppp', adjusetedCalcium15);
  } else { console.log('kkk', adjusetedCalcium30); }
  return result;
};

export const forGettingAllDrains = (data) => {
  const {
    calVariable, poolFinish, chlorinationType, poolInputs, manualValue, selectedModel, drainMagnaPoint, drainPoint, magnaPoolAdjust, calciDisplayChemicals, showFullHardness, actValSalt,
  } = data;
  let drainPercent = 0;
  let drainWaterCopper = 0;
  let drainWaterChorine = 0;
  let drainWaterSalt = 0;
  let drainWaterHardness = 0;
  let drainWater = 0;
  let drainTaker = 0;
  let maxTestingDrain = 0;

  let passDiffStab = false;
  let passDiffHard = false;
  let passDiffSalt = false;
  let passDiffChlorine = false;
  let passDiffCopper = false;

  let diffStabliser = false;
  let diffCalcium = false;
  let diffChlorine = false;
  let diffSalt = false;
  let diffCopper = false;

  const {
    stabiliser, drainPointcopper, salt, drainPointstabiliserCya,
    drainPointcalciumHardness, drainPointcombinedChlorine,
    drainPointstabiliserOrp,
    copper,
  } = calVariable;

  const {
    salt: disSalt, copper: disCopper, calciumHardness: disCalcium, totalChlorine: disTotalChlorine,
  } = calciDisplayChemicals;

  let { calciumHardness, hardCalcium } = calVariable;
  const stabValue = stabiliser === 0 && poolFinish.includes('Indoor') ? 40 : stabiliser;
  let copyPoolInputs = { ...poolInputs };

  copyPoolInputs.salt = actValSalt;

  if (chlorinationType === 'Calcium Hardness Titration Test' || showFullHardness?.fullhardness || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') {
    calciumHardness = hardCalcium;
    copyPoolInputs.calciumHardness = copyPoolInputs.hardCalcium;
  }

  if (copyPoolInputs.stabiliser > Number(stabValue)) {
    const calculation = (stabValue / copyPoolInputs.stabiliser) * 100;
    drainWater = 100 - calculation;
    drainWater = parseInt(drainWater / 5, 10) * 5;
  }

  const newCombinedChrlone = parseFloat(copyPoolInputs.combinedChlorine)
  - parseFloat(copyPoolInputs.chlorine);
  const actualChlorine = newCombinedChrlone + parseFloat(copyPoolInputs.chlorine);

  if ((parseFloat(newCombinedChrlone) >= drainPointcombinedChlorine) && disTotalChlorine) {
    let calculation = (5 / parseFloat(newCombinedChrlone)) * 100;
    let difference = 100 - calculation;
    drainPercent = Math.floor(difference / 5) * 5;
    drainWaterChorine = drainPercent;
    if (drainPercent) {
      diffChlorine = true;
      passDiffChlorine = true;
    }
  }

  let passingSalt = copyPoolInputs.salt;

  if (manualValue.includes('Aquatherepe Transform Maintenance')) {
    passingSalt = 4500;
  }

  const checkCalcium = (disCalcium || chlorinationType === 'Calcium Hardness Titration Test' || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test');

  const notImplementFormula = !!((chlorinationType === 'Calcium Hardness Titration Test' || showFullHardness?.fullhardness || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test'));

  const input = (!notImplementFormula && (chlorinationType === 'Mineral Pool'
  || manualValue.includes('Aquatherepe Transform Maintenance')))
    ? calciumAdjustmentCal(copyPoolInputs.calciumHardness, passingSalt)
    : copyPoolInputs.calciumHardness;

  let inputTaker = copyPoolInputs.calciumHardness;
  let calciumValue = calciumHardness;

  if ((input > Number(calciumValue)) && drainPointcalciumHardness && checkCalcium) {
    const calculation = (calciumValue / input) * 100;
    drainWaterHardness = parseInt((100 - calculation) / 5, 10) * 5;
    // if (chlorinationType === 'Mineral Pool' && !disSalt) drainWaterHardness = 0;
    drainTaker = drainWaterHardness;
  }

  if (copyPoolInputs.copper > 0.199 && disCopper) {
    const calculationDrain = (parseFloat(0.199) / copyPoolInputs.copper) * 100;
    drainWaterCopper = parseInt((100 - calculationDrain) / 5, 10) * 5;
  }

  if (
    chlorinationType === 'Convert Salt Pool to Mineral Pool'
    || manualValue === 'Convert Pool to Mineral Pool'
    || manualValue.includes('Aquatherepe Transform Maintenance')
  ) {
    if ((copyPoolInputs.salt > Number(salt)) && disSalt) {
      const calculationAquaSalt = (salt / copyPoolInputs.salt) * 100;
      drainWaterSalt = parseInt((100 - calculationAquaSalt) / 5, 10) * 5;
    }
  } else if (
    chlorinationType === 'Salt Pool'
    || selectedModel === 'Magna Minerals'
    || selectedModel === 'Magna Minerals - With ORP Probe'
  ) {
    if ((copyPoolInputs.salt > Number(salt)) && disSalt) {
      const calculation = (salt / copyPoolInputs.salt) * 100;
      drainWaterSalt = parseInt((100 - calculation) / 5, 10) * 5;
    }
  }

  if (drainWaterCopper) {
    const level = drainPointcopper;
    if (copyPoolInputs.copper >= level) {
      diffCopper = true;
      passDiffCopper = true;
    } else {
      drainWaterCopper = 0;
    }
  }

  if (drainWater) {
    const level = (Number(stabValue) * drainPointstabiliserCya) / 100 + Number(stabValue);
    if (copyPoolInputs.stabiliser > level) {
      diffStabliser = true;
      passDiffStab = true;
    } else {
      drainWater = 0;
    }
  }

  if (drainWaterHardness) {
    const level = Math.ceil(((calciumValue * drainPointcalciumHardness) / 100
      + calciumValue) / 5) * 5;
    if (input > level) {
      diffCalcium = true;
      passDiffHard = true;
    } else {
      drainWaterHardness = 0;
    }
  }

  if (drainWaterSalt) {
    let level;
    if (chlorinationType === 'Salt Pool') {
      level = (salt * drainPoint) / 100 + salt;
    } else if (chlorinationType === 'Mineral Pool') {
      level = (salt * drainMagnaPoint) / 100 + salt;
    } else {
      level = (salt * 37) / 100 + salt;
    }

    if (copyPoolInputs.salt > level) {
      diffSalt = true;
      passDiffSalt = true;
    } else {
      drainWaterSalt = 0;
    }
  }
  maxTestingDrain = Math.max(
    parseInt(drainWater, 10),
    parseInt(drainWaterHardness, 10),
    parseInt(drainWaterSalt, 10),
    parseInt(drainWaterChorine, 10),
    parseInt(drainWaterCopper, 10),
  );

  // console.log('drainWaterSalt,drainWater,drainWaterChorine,drainWaterHardness,drainWaterCopper', drainWaterSalt,
  //   drainWater,
  //   drainWaterChorine,
  //   drainWaterHardness,
  //   drainWaterCopper);

  return {
    drainWaterSalt,
    drainWater,
    drainWaterChorine,
    drainWaterHardness,
    drainWaterCopper,
    inputTaker,
    newCombinedChrlone,
    maxTestingDrain,
    passDiffChlorine,
    passDiffCopper,
    passDiffHard,
    passDiffSalt,
    passDiffStab,
    diffStabliser,
    diffCalcium,
    diffChlorine,
    diffSalt,
    diffCopper,
    drainTaker,
    drainPercent,
    actualChlorine,
    input,
  };
};

export const higherThanTargetLevels = (inp, cal, chlorInp, type, getValue) => {
  console.log(inp, cal, chlorInp, type, getValue);
  let isValid = true;
  let higherChemical = '';
  let chemicalValue = '';
  Object.keys(inp).forEach((el) => {
    if (el !== 'ph' && inp[el]) {
      let actaulReadingHalf = (parseFloat(cal[el]) * 50) / 100;
      if (el === 'copper') {
        actaulReadingHalf = 0.2;
      }
      if (el === 'combinedChlorine') {
        const vale = chlorInp || cal.chlorine;
        actaulReadingHalf = (parseFloat(vale) * 50) / 100;
      }

      if (el === 'salt' && type === 'Mineral Pool') {
        if (getValue.includes('Magna Minerals')) {
          actaulReadingHalf = 700;
        } else if (getValue.includes('Aquatherepe Minerals')) {
          actaulReadingHalf = 500;
        }
      }

      if (parseFloat(inp[el]) > actaulReadingHalf) {
        isValid = false;
        higherChemical = el;
        chemicalValue = actaulReadingHalf;
      }
    }
  });
  return { isValid, higherChemical, chemicalValue };
};

export const drainNewCalculation = (oldInp, percentDrain, newInp, ideal) => {
  // let calciumaAfterDraining = (parseFloat(oldInp) * percentDrain) / 100;
  let finalCalciumReading = ((parseFloat(newInp) * percentDrain) / 100) + parseFloat(oldInp);
  let calculationForNewDrainPercent = 100 - ((ideal / finalCalciumReading) * 100);
  let result = Math.ceil(calculationForNewDrainPercent / 5) * 5;
  return result;
};

export const getChemcialAfterReducing = (chemicalReading, drainPercentForReducing) => {
  if (!chemicalReading) {
    return '';
  }
  const result = parseFloat(chemicalReading) - ((parseFloat(chemicalReading) * parseFloat(drainPercentForReducing)) / 100);
  if (result.toString().includes('.')) {
    return parseFloat(result.toFixed(2));
  }
  return result;
};

export const getChemcialAfterReducingSrc = (chemicalReading, drainPercentForReducing) => {
  if (!chemicalReading) {
    return '';
  }
  const result = (parseFloat(chemicalReading) * parseFloat(drainPercentForReducing)) / 100;
  if (result.toString().includes('.')) {
    return parseFloat(result.toFixed(2));
  }
  return result;
};
export const finalDrainInp = (chemicalReading, drainPercentForReducing, newSrcVal) => {
  if (!chemicalReading) {
    return '';
  }
  const result = parseFloat(newSrcVal || 0) + (parseFloat(chemicalReading) - ((parseFloat(chemicalReading) * parseFloat(drainPercentForReducing)) / 100));
  if (result.toString().includes('.')) {
    return parseFloat(result.toFixed(2));
  }

  return result;
};
export const getSourceReducedReadings = (sourceInpts, drainPercentForReducing) => {
  let newSrcObj = { ...sourceInpts };
  Object.keys(newSrcObj).forEach((el) => {
    newSrcObj[el] = getChemcialAfterReducingSrc(newSrcObj[el], drainPercentForReducing);
  });
  return newSrcObj;
};

export const calculatedSumForInputs = (poolInp, srcInp, calciumDownStatus) => {
  // console.log('hhhhhhh', poolInp, srcInp, calciumDownStatus);
  Object.keys(srcInp).forEach((el) => {
    if (el === 'calciumHardness' && calciumDownStatus) {
      poolInp[el] = sumForInputs(poolInp[el], srcInp[el], calciumDownStatus);
    } else {
      poolInp[el] = sumForInputs(poolInp[el], srcInp[el]);
    }
  });

  return poolInp;
};

export const sumForInputs = (poolInp, srcInp, calciumDownStatus) => {
  // if (calciumDownStatus) {
  //   console.log('poolInp, srcInp, calciumDownStatus', poolInp, srcInp, calciumDownStatus);
  // }
  if (parseFloat(poolInp) > 0) {
    poolInp = parseFloat(poolInp);
  }
  if (parseFloat(srcInp) > 0) {
    srcInp = parseFloat(srcInp);
  }

  const result = calciumDownStatus ? poolInp - srcInp : poolInp + srcInp;
  if (result && result.toString().includes('.')) {
    return parseFloat(result).toFixed(2);
  }
  return result;
};

export const totalBuyCost = (data) => {
  let totalLab = data?.filter((el) => el).reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.buyCostProduct ? (next.buyCostProduct === 'N/A' ? 0 : next.buyCostProduct) : 0), 0);
  return totalLab?.toFixed(2);
};

export const litresUnits = (chemical, valueType) => {
  const inMl = Math.ceil(Math.trunc(chemical * 1000) / 100) * 100;
  const chemValue = chemical < 1
    ? inMl === 1000
      ? chemical.toFixed(1)
      : inMl
    : chemical.toFixed(1);
  let unit = chemical < 1
    ? inMl === 1000
      ? 'litres'
      : 'ml'
    : 'litres';
  if (parseFloat(chemValue) === parseFloat(1)) {
    unit = 'litre';
  }

  if (!valueType) {
    unit = 'fl oz';
    if (chemical >= 1) {
      if (chemical >= 128) {
        unit = 'gallons';
      }
    }
  }

  return { chemValue, unit };
};

export const roundUpNext = (val, roundUpVal) => Math.ceil(parseFloat(val) / roundUpVal) * roundUpVal;

export const displayCacliPrices = (valueData, type, upTax) => {
  // eslint-disable-next-line no-restricted-globals
  if (valueData && !isNaN(valueData)) {
    let value = parseFloat(parseFloat(valueData).toFixed(2));
    if (upTax) {
      value /= upTax;
    }
    return (type + value.toFixed(2));
  }
  if (valueData === 'N/A') {
    valueData = 'N/A';
  } else {
    valueData = (type + (0).toFixed(2));
  }

  return valueData;
};

export const displayDollar = (valueData) => {
  // eslint-disable-next-line no-restricted-globals
  if (valueData && !isNaN(valueData)) {
    return '$';
  }
  return '';
};

export const displayNotTestedMsg = (stripExist, msg, value, type) => {
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(value)) {
    return msg;
  }
  if (!stripExist) {
    return msg;
  }
  return (`${type} Not Tested`);
};

const setDefaultState = () => {
  showState = false;
  clearInterval(isTimerInterval);
  IntervalTime = 0;
  clearTimeout(isInterval);
};

const notifyCallBack = () => {
  setDefaultState();
};

export const notifyAlert = (message, type) => {
  const errorTime = type === 'error' ? errorMsgTime : successMsgTime;
  if (isInterval) {
    clearTimeout(isInterval);
  }
  if (IntervalTime >= 5000) {
    setDefaultState();
  }
  if (!showState) {
    NotificationManager[type](message, 'Message', errorTime, notifyCallBack);
    showState = true;

    isTimerInterval = setInterval(() => {
      IntervalTime += 1000;
      if (IntervalTime >= 5000) {
        setDefaultState();
      }
    }, 1000);
  }
  isInterval = setTimeout(() => {
    showState = false;
    isInterval = null;
  }, errorTime);
};

const updatingCalVariable = (type, quantType, costType, element, goingToUpdate, upTaxValue) => {
  console.log({
    type, quantType, costType, element, goingToUpdate, upTaxValue,
  });
  goingToUpdate[costType] = element?.renderCost === 'N/A' ? element.renderCost : parseFloat(element.renderCost) * upTaxValue;
  goingToUpdate[quantType] = element?.buyCostProduct === 'N/A' ? element?.buyCostProduct : parseFloat(element?.buyCostProduct);
  goingToUpdate[type] = element?.subcategory;
};

export const getUpdateCalVariable = (goingToUpdate, serviceArray, upTaxValue) => {
  if (serviceArray?.length > 0) {
    let totalNewCost = 5.5;
    serviceArray.forEach((el) => {
      totalNewCost += (el?.renderCost ? el?.renderCost === 'N/A' ? 0 : parseFloat(el?.renderCost) * upTaxValue : 0);
      switch (el?.category) {
        case 'chlorine':
          updatingCalVariable('chlorineMsg',
            'quantityCostForChlorine',
            'costChroline',
            el, goingToUpdate, upTaxValue);
          break;
        case 'combined':
          updatingCalVariable('combinedChlorineMsg',
            'quantityCostCombined',
            'costCombinedChlorine',
            el, goingToUpdate, upTaxValue);
          break;

        case 'copper':
          updatingCalVariable('copperMsg',
            'quantityCostCopper',
            'costCopper',
            el, goingToUpdate, upTaxValue);
          break;

        case 'phosphatesRemover':
          updatingCalVariable('phosphateMsg',
            'quantityCostPhosphates',
            'costPhophates',
            el, goingToUpdate, upTaxValue);
          break;

        case 'buffer':
          updatingCalVariable('alkMsg',
            'quantityCostAlk',
            'costAlkalinity',
            el, goingToUpdate, upTaxValue);
          break;

        case 'stabiliser':
          updatingCalVariable('stabiliserMsg',
            'quantityCostStabiliser',
            'costStabiliser',
            el, goingToUpdate, upTaxValue);
          break;

        case 'calciumRaiser':
          updatingCalVariable('calciumHardnessMsg',
            'quantityCostCalcium',
            'costCalcium',
            el, goingToUpdate, upTaxValue);
          break;

        case 'salt':
          updatingCalVariable('saltMsg',
            'quantityCostSalt',
            'costSalt',
            el, goingToUpdate, upTaxValue);
          break;

        case 'hydrochloricAcidPh':
          updatingCalVariable('phMsg',
            'quantityCostPh',
            'costPh',
            el, goingToUpdate, upTaxValue);
          break;

        case 'clarifier':
          updatingCalVariable('clarifierMsg',
            'quantityCostClarifierMain',
            'costClarifierMain',
            el, goingToUpdate, upTaxValue);
          break;

        case 'flock':
          updatingCalVariable('flockMsg',
            'quantityCostFlock',
            'costFlock',
            el, goingToUpdate, upTaxValue);
          break;

        case 'algaecide':
          updatingCalVariable('algaecideMsg',
            'quantityCostAlgaecide',
            'costAlgaecide',
            el, goingToUpdate, upTaxValue);
          break;

        case 'saltMsgConvert':
          updatingCalVariable('saltMsgConvert',
            'quantityCostSaltConvert',
            'costSaltConvert',
            el, goingToUpdate, upTaxValue);
          break;

        default:
          break;
      }
    });

    goingToUpdate.totalCost = totalNewCost;
  }

  return goingToUpdate;
};
