import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

const AvatarForm = (props) => {
  const {
    isUpLoading,
    label,
    avatar,
    size,
    handleUploadFile,
  } = props;

  const fileInputRef = useRef();

  const handleFileUploadBtnClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="d-flex justify-content-center align-self-center mt-4">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUploadFile}
        hidden
      />
      <button
        type="button"
        className="btn-avatar-upload"
        onClick={handleFileUploadBtnClick}
      >
        {isUpLoading ? (
          <div
            className="bot-avatar-image"
            style={{
              width: size,
              height: size,
              background: '#f8f9fa',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ClipLoader size={size} color="#ccc" />
          </div>
        ) : (
          <>
            {avatar === '' ? (
              <div
                className="bot-avatar-image"
                style={{ width: size, height: size, background: '#ccc' }}
              />
            ) : (
              <img
                className="bot-avatar-image"
                width={size}
                height={size}
                src={avatar}
                alt="robot"
              />
            )}
          </>
        )}
      </button>
      <div className="d-flex justify-content-center">
        <span style={{ fontSize: '12px' }}>{label}</span>
      </div>
    </div>
  );
};

AvatarForm.propTypes = {
  isUpLoading: PropTypes.bool.isRequired,
  avatar: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  handleUploadFile: PropTypes.func.isRequired,
};

export default AvatarForm;
