/* eslint-disable spaced-comment */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-quotes */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Button } from 'react-bootstrap';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import SubscriptionForm from './subscriptionForm';
import SubscriptionTable from './subscriptionTable';
import { userInstance } from '../../config/axios';
import {
  showErrMsg,
  validateDataWithKey,
  queryString,
  getCalendarPath,
  removeCodeFromNumber,
} from '../../functions/index';
import { emailRegex } from '../../config/keys';
import AddBilingContact from '../clients/billingContact';
import './subscription.css';
import DeletePopUp from '../DeletePopUp/index';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { errorBorderTime } from '../../functions/intervalTime';
import UpgradePlanPopUp from './updgradePlanPopUp';
import toastMessage from '../../functions/toastMessage';
import { countries, states } from '../country-state/country-state';
import Loader from '../loader/loader';
import AddSubsSiteAddress from './subsSiteAddress';
import StripePaymentPopup from '../SubscribePayment/stripePaymentPopup';

function Subscription() {
  const { userData } = useContext(AuthDataContext);
  const history = useHistory();
  const [subscriptiontype, setSubscriptionType] = useState('Basic Plan');
  const initialClientData = {
    customerType: 'Householder',
    duration: '',
    companyName: '',
    firstname: '',
    lastname: '',
    mobileNo: '',
    landlineNo: '',
    email: '',
    streetAddress: '',
    suburb: '',
    postcode: '',
    state: '',
    country: 'Australia',
    role: 'subscriber',
    note: '',
    subscriptionStatus: '',
    numberUsers: 0,
    numberOfPools: 0,
    totalCost: 0,
    hide: false,
    associatedContacts: [],
    siteAddresses: [],
    agreeStatus: false,
    paymentStatus: 'Up-to-Date-Last Payment $530.00 on 22 Mar,2019',
    showBookings: true,
    applyNewPayment: 'No',
    status: 'Active',
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
    gender: 'male',
    invoiceSendType: 'Send Invoices by SMS',
    billingCycle: '1 Year',
    costDropDown: 'no cost',
  };
  let {
 clientid, name, startDateTime, employee_id, typee, back 
} =
    queryString();
  const initialContactData = {
    name: '',
    lastName: '',
    mobileNo: '',
    email: '',
    landlineNo: '',
    hide: false,
    tempShow: false,
  };
  const initialAddressData = {
    householderName: '',
    address: '',
    mobileNo: '',
    landlineNo: '',
    email: '',
    nestedContacts: [],
    hide: false,
    tempShow: false,
    poolWidth: '',
    poolHeight: '',
  };
  const limit = 10;
  const [showTable, setShowTable] = useState(false);
  const [clientData, setClientData] = useState(initialClientData);
  const [clientList, setClientList] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedClient, setClientId] = useState('');
  const [showHide, setShowHide] = useState(false);
  const [selectedCountry, setCountry] = useState('+61');
  const [searchData, setSearchData] = useState('');
  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(1);
  const [showContactModal, setContactModal] = useState(false);
  const [showSiteAddress, setSiteAddress] = useState(false);
  const [contactData, setContactData] = useState(initialContactData);
  const [selectedIndex, setIndex] = useState('');
  const [runEdit, setRun] = useState(null);
  const [isUpdate, setUpdate] = useState(false);
  const [contactIndex, setContactIndex] = useState('');
  const [menuType, setMenuType] = useState('');
  const [addressData, setAddressData] = useState(initialAddressData);
  const [addressIndex, setAddrIndex] = useState('');
  const [hideClicked, setClicked] = useState(null);
  const [msg, setMsg] = useState('');
  const [clientTxt, setClientTxt] = useState('');
  const [isHideSearch, setHideSearch] = useState(false);
  const [hideClickedAssociated, setHideClkAssociated] = useState(null);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [delValue, setDelvalue] = useState();
  const [crossStatus, setCrossStatus] = useState(false);
  const [newstate, setnewState] = useState(true);
  const [clientIdentity, setClientIdentity] = useState('');
  const [clientNewData, setClientNewData] = useState([]);

  const [copyClientData, setcopyClientData] = useState({
    subscriptionStatus: '',
    duration: 0,
    numberUsers: 0,
    numberOfPools: 0,
    totalCost: 0,
  });
  const [planId, setPlanId] = useState('');
  const [poolData, setPoolData] = useState({});
  const [upgradePopUp, setUpgradePopUp] = useState(false);
  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  // payload state before edit Subscriber API
  const [payloadBeforeEdit, setpayloadBeforeEdit] = useState({});

  const [paymentResult, setPaymentResult] = useState('');
  const [error, setError] = useState('');
  const [subsTypeName, setSubsTypeName] = useState('Basic Plan');
  const [selectedPage, setSelectedPage] = useState(1);
  const [stateArray, setStateArray] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [mobileFormat, setMobileFormat] = useState('### ### ###');
  const [loading, setLoading] = useState(false);

  const [smallLoader, setSmallLoader] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  // console.log(clientData, 'clientData>>>>>>>');

  // const stripePromise = loadStripe('pk_test_51KjgGOSHhBMizAxJbkmFP2RG4SjmsXfhOWsOHrAtCqaBhFXAvDMPkr0X94xVRAN1J8wURdXWKPu6Ub1D2ylrjfy70002aH6XJk');

  const poolFinishArray = [
    'Concrete Pool - Outdoor',
    'Fibreglass Pool - Outdoor',
    'Vinyl Liner Pool - Outdoor',
    'Concrete Pool - Indoor',
    'Fibreglass Pool - Indoor',
    'Vinyl Liner Pool - Indoor',
  ];
  const chlorinationArray = [
    'Salt Pool',
    'Mineral Pool with Chlorinator',
    'Convert Salt Pool to Mineral Pool',
    'Manually Chlorinated',
  ];
  const poolTypeArray = [
    'Non-Mineral Pool',
    'Convert Pool to Mineral Pool',
    'Aquatherepe Transform Maintenance',
  ];
  const convertPoolChlorineArray = [
    'Aquatherepe Transform',
    'Aquatherepe Transform - With ORP Probe',
  ];

  const PopUpText = `Any Additional Contacts and
  their details will be deleted
  with this change.`;

  const paraText = 'Do you want to continue?';

  const [saltChlorinator, setSaltChorinator] = useState([]);
  const [mineralChlorinator, setMineralChlorinator] = useState([]);

  const forSettingChlorinationModel = (
    saltModel,
    mineralModel,
    typeCh,
    changeStatus
  ) => {
    const {
      selectedModel,
      updatedSelectedModel,
      selectedModelObj,
      chlorinatorModel,
    } = clientData;
    if (typeCh === 'Salt Pool') {
      let chlorineData = chlorinatorModel;
      if ((!changeStatus && chlorinatorModel.length === 0) || changeStatus) {
        chlorineData = saltModel;
      }
      setClientData({
        ...clientData,
        selectedModel: !changeStatus ? selectedModel : saltModel[0].name,
        updatedSelectedModel: !changeStatus
          ? updatedSelectedModel
          : saltModel[0].name,
        selectedModelObj: !changeStatus ? selectedModelObj : saltModel[0],
        chlorinationType: typeCh,
        chlorinatorModel: chlorineData,
      });
    } else if (typeCh === 'Mineral Pool with Chlorinator') {
      let chlorineData = chlorinatorModel;
      if ((!changeStatus && chlorinatorModel.length === 0) || changeStatus) {
        chlorineData = mineralModel;
      }

      setClientData({
        ...clientData,
        selectedModel: !changeStatus
          ? selectedModel
          : mineralModel[0].brandName,
        updatedSelectedModel: !changeStatus
          ? updatedSelectedModel
          : mineralModel[0].brandName,
        selectedModelObj: !changeStatus ? selectedModelObj : mineralModel[0],
        chlorinationType: typeCh,
        chlorinatorModel: chlorineData,
      });
    } else if (typeCh === 'Convert Salt Pool to Mineral Pool') {
      let chlorineData = chlorinatorModel;
      if ((!changeStatus && chlorinatorModel.length === 0) || changeStatus) {
        chlorineData = convertPoolChlorineArray;
      }

      setClientData({
        ...clientData,
        selectedModel: !changeStatus
          ? selectedModel
          : convertPoolChlorineArray[0],
        updatedSelectedModel: !changeStatus
          ? updatedSelectedModel
          : convertPoolChlorineArray[0],
        selectedModelObj: null,
        chlorinationType: typeCh,
        chlorinatorModel: chlorineData,
      });
    } else if (typeCh === 'Manually Chlorinated') {
      let chlorineData = chlorinatorModel;
      if ((!changeStatus && chlorinatorModel.length === 0) || changeStatus) {
        chlorineData = poolTypeArray;
      }

      setClientData({
        ...clientData,
        selectedModel: !changeStatus ? selectedModel : poolTypeArray[0],
        updatedSelectedModel: !changeStatus
          ? updatedSelectedModel
          : poolTypeArray[0],
        selectedModelObj: null,
        chlorinationType: typeCh,
        chlorinatorModel: chlorineData,
      });
    }
  };

  const handleSiteAddressCountry = (countryCode) => {
    setCountry(countryCode);
    const textToReplace = addressData.mobileNo.includes('+61') ? '+61' : '+1';
    const obj = {};
    if (addressData.mobileNo) {
      obj.mobileNo = `${addressData.mobileNo.replace(textToReplace, '')}`;
    }
    setSiteAddress({ ...addressData, ...obj });
  };

  useEffect(async () => {
    const newresponse = await userInstance().get(
      '/poolmineral/getAllPoolMinerals'
    );
    const mineralList = newresponse?.data?.mineralList;
    if (mineralList) {
      setPoolData(mineralList);
      const saltChorinatorModel = mineralList?.chlorinatorModels;
      const mineralChlorinatorModel = mineralList?.calciumAdjustments;
      setSaltChorinator(saltChorinatorModel);
      setMineralChlorinator(mineralChlorinatorModel);
      forSettingChlorinationModel(
        saltChorinatorModel,
        mineralChlorinatorModel,
        clientData?.chlorinationType
      );
    }
  }, []);

  const handlePopUpChange = (val) => {
    if (val === 'Yes') {
      setClientData({ ...clientData, customerType: delValue });
    }
    setDeletePopUp(false);
  };
  const editCustomerType = (value) => {
    setDeletePopUp(true);
    setDelvalue(value);
  };

  const handleContactModal = (index, _id, addrIndex) => {
    setContactData(initialContactData);
    setContactModal(!showContactModal);
    if (index !== undefined) setIndex(index);
    if (_id) setClientId(_id);
    if (addrIndex !== undefined) {
      setMenuType('site');
      setAddrIndex(addrIndex);
    } else {
      setMenuType('associated');
    }
  };
  const handleSiteAddressModal = (index, _id) => {
    setAddressData(initialAddressData);
    setSiteAddress(!showSiteAddress);
    setMenuType('site');
    if (index !== undefined) setIndex(index);
    if (_id) setClientId(_id);
  };

  const handleContactData = (e) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };
  const handleAddressData = (e) => {
    setAddressData({ ...addressData, [e.target.name]: e.target.value });
  };

  const handleAddBillingContact = (e) => {
    e.preventDefault();
    let message = '';
    const {
 name, lastName, mobileNo, email 
} = contactData;
    const validationObj = {
      'first name': name,
      'last name': lastName,
      'mobile no': mobileNo,
      email,
    };
    const { isValid, emptyField } = validateDataWithKey(validationObj);
    if (isValid) {
      if (emailRegex.test(String(email).toLowerCase())) {
        const clientListCopy = clientList;
        const client = clientListCopy[selectedIndex];
        // eslint-disable-next-line operator-linebreak
        const propertyName =
          menuType === 'site' ? 'siteAddresses' : 'associatedContacts';
        const contacts = client[propertyName];
        if (menuType === 'associated') {
          if (isUpdate) {
            let selectedContact = contacts.length
              ? contacts[contactIndex]
              : null;
            if (selectedContact) {
              selectedContact = contactData;
              contacts[contactIndex] = selectedContact;
            }
            message = 'Contact updated';
          } else {
            message = 'Contact added';
            contacts.push(contactData);
          }
        } else if (menuType === 'site') {
          const existingContacts = contacts[addressIndex].nestedContacts;
          if (isUpdate) {
            existingContacts[contactIndex] = contactData;
            message = 'Contact updated';
          } else {
            message = 'Contact added';
            existingContacts.push(contactData);
          }
          let selectedAddress = contacts[addressIndex];
          selectedAddress.nestedContacts = existingContacts;
          contacts[addressIndex] = selectedAddress;
        }
        client[propertyName] = contacts;
        setClientList([...clientListCopy]);
        setRun(true);
        setContactModal(!showContactModal);
        setUpdate(false);
      } else {
        setError('email');
        toastMessage('error', 'Invalid email');
      }
    } else {
      setError(emptyField?.split(' ').join('').toLowerCase());
      toastMessage('error', `Please fill ${emptyField}`);
    }
    setTimeout(() => {
      setError('');
    }, errorBorderTime);
    setMsg(message);
  };

  const handleEditContact = (data, _id, index, contactI, addrIndex) => {
    let copySelectedCountry = selectedCountry;
    if (data?.mobileNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(data.mobileNo);
      data.mobileNo = updatedNo;
      copySelectedCountry = countryCode;
    }

    if (data?.landlineNo) {
      const { updatedNo } = removeCodeFromNumber(data.landlineNo);
      data.landlineNo = updatedNo;
    }

    setContactData(data);
    setClientId(_id);
    setIndex(index);
    setUpdate(true);
    setCountry(copySelectedCountry);
    if (contactI !== undefined) setContactIndex(contactI);
    if (addrIndex !== undefined) {
      setMenuType('site');
      setAddrIndex(addrIndex);
    } else {
      setMenuType('associated');
    }
    setContactModal(!showContactModal);
  };

  const handleDeleteContact = (_id, index, contactI, type, addrIndex) => {
    setClientId(_id);
    setIndex(index);
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    // eslint-disable-next-line operator-linebreak
    const propertyName =
      type === 'site' ? 'siteAddresses' : 'associatedContacts';
    const contacts = client[propertyName] ? client[propertyName] : [];
    if (type === 'contact') {
      contacts.splice(contactI, 1);
    } else if (type === 'site') {
      const existingContacts = contacts[addrIndex].nestedContacts;
      existingContacts.splice(contactI, 1);
    }
    client[propertyName] = contacts;
    setClientList([...clientListCopy]);
    setMsg('Contact deleted');
    setRun(true);
  };
  const handleSubscription = (e) => {
    let { value } = e.target;
    setSubsTypeName(value);
    setUpgradePopUp(true);
  };

  const handleAddAddress = (e) => {
    e.preventDefault();
    let message = '';
    // eslint-disable-next-line operator-linebreak
    const {
 householderName, address, mobileNo, poolHeight, poolWidth 
} =
      addressData;
    const validationObj = {
      'householder name': householderName,
      address,
      'mobile no': mobileNo,
    };
    const { isValid, emptyField } = validateDataWithKey(validationObj);
    if (isValid) {
      const clientListCopy = clientList;
      const client = clientListCopy[selectedIndex];
      const addresses = client.siteAddresses ? client.siteAddresses : [];
      if (isUpdate) {
        let selectedContact = addresses?.length
          ? addresses[addressIndex]
          : null;
        if (selectedContact) {
          selectedContact = addressData;
          addresses[addressIndex] = selectedContact;
        }
        message = 'Address updated';
      } else {
        message = 'Address added';
        addresses.push(addressData);
      }
      client.siteAddresses = addresses;
      setClientList([...clientListCopy]);
      setRun(true);
      setSiteAddress(!showSiteAddress);
      setUpdate(false);
    } else {
      setError(emptyField?.split(' ').join('').toLowerCase());
      toastMessage('error', `Please fill ${emptyField}`);
    }
    setTimeout(() => {
      setError('');
    }, errorBorderTime);
    setMsg(message);
  };

  const handleEditAddress = (data, _id, index, addrIndex) => {
    let copySelectedCountry = selectedCountry;
    if (data?.mobileNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(data.mobileNo);
      data.mobileNo = updatedNo;
      copySelectedCountry = countryCode;
    }

    if (data?.landlineNo) {
      const { updatedNo } = removeCodeFromNumber(data.landlineNo);
      data.landlineNo = updatedNo;
    }

    setCountry(copySelectedCountry);

    setAddressData(data);
    setClientId(_id);
    setIndex(index);
    setUpdate(true);
    setAddrIndex(addrIndex);
    setSiteAddress(!showSiteAddress);
    setMenuType('site');
  };

  const handleDeleteAddress = (_id, index, addrIndex) => {
    setClientId(_id);
    setIndex(index);
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    const addresses = client.siteAddresses ? client.siteAddresses : [];
    addresses.splice(addrIndex, 1);
    client.siteAddressess = addresses;
    setClientList([...clientListCopy]);
    setMsg('Address deleted');
    setRun(true);
  };

  // const handleVisibilityAddr = (index, _id, addrIndex, type) => {
  //   setIndex(index);
  //   setClientId(_id);
  //   const clientListCopy = clientList;
  //   const client = clientListCopy[index];
  //   const addresses = client[type];
  //   const currentAddress = addresses[addrIndex];
  //   currentAddress.hide = !currentAddress.hide;

  //   currentAddress.tempShow = type === 'associatedContacts' ? hideClickedAssociated : hideClicked;
  //   client[type] = addresses;
  //   setClientList([...clientListCopy]);
  //   setMsg('Visibility updated');
  //   setRun(true);
  // };
  const handleVisibilityAddr = (index, _id, addrIndex, type) => {
    setIndex(index);
    setClientId(_id);
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    const addresses = client[type];
    const currentAddress = addresses[addrIndex];
    currentAddress.hide = !currentAddress.hide;
    // eslint-disable-next-line operator-linebreak
    currentAddress.tempShow =
      type === 'associatedContacts' ? hideClickedAssociated : hideClicked;
    client[type] = addresses;
    setClientList([...clientListCopy]);
    setMsg('Visibility updated');
    setRun(true);
  };
  const showAll = (index, type) => {
    const value = !(type === 'siteContacts' || type === 'siteAddresses'
      ? hideClicked
      : hideClickedAssociated);
    if (type === 'associatedContacts') {
      setHideClkAssociated(value);
    } else {
      setClicked(value);
    }
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    client[type === 'siteAddresses' ? type : 'associatedContacts'].forEach(
      (el) => {
        el.tempShow = value;
      }
    );
    setClientList([...clientListCopy]);
  };

  const HandleOpenCloseTable = (staff) => {
    setShowTable(!showTable);
    if (staff) {
      setClientId(staff._id);
      const newShift = convertDate(staff.shift);
      setClientData({ ...clientData, ...staff, shift: newShift });
    }
  };

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
    setSelectedPage(selected + 1);
  };

  const getClientList = async () => {
    try {
      const response = await userInstance().get(
        `/subscribers/getAllSubscriber?limit=${limit}&skip=${skip}`
      );
      setLoading(false);
      const list = response.data.returnedArray;
      list.sort((a, b) => a.firstname.localeCompare(b.firstname));
      setPageCounts(response.data.count);
      setClientList(list);
    } catch (error) {
      setLoading(false);
    }
  };

  const searchClient = async (value) => {
    try {
      setClientTxt(value);
      const response = await userInstance().get(
        `/subscribers/searchSubscriber?value=${value}`
      );
      let list = response.data.customers;
      if (value) {
        if (isHideSearch) {
          list.forEach((client) => {
            if (client.sideAddresses) {
              client.sideAddresses.forEach((addr) => {
                addr.hide = false;
              });
            }
          });
        } else {
          // eslint-disable-next-line no-confusing-arrow
          list = list.filter(
            // eslint-disable-next-line no-confusing-arrow
            (el) =>
              el.siteAddresses
                ? el.siteAddresses.filter((adr) => adr.hide).length === 0
                : el
            // eslint-disable-next-line function-paren-newline
          );
        }
      }
      list.sort((a, b) => a.firstname.localeCompare(b.firstname));

      setClientList(list);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCountry = (countryCode) => {
    setCountry(countryCode);
    const textToReplace = clientData.mobileNo.includes('+61') ? '+61' : '+1';
    const obj = {};
    if (clientData.mobileNo) {
      obj.mobileNo = `${clientData.mobileNo.replace(textToReplace, '')}`;
    }
    if (clientData.landlineNo) {
      obj.landlineNo = `${clientData.landlineNo.replace(textToReplace, '')}`;
    }
    setClientData({ ...clientData, ...obj });
  };

  const convertDate = (shift) => {
    const newShift = [];
    shift.forEach((el) => {
      if (el) {
        newShift.push({
          ...el,
          date: new Date(new Date(el.date).toDateString()),
        });
      }
    });
    return newShift;
  };
  const handleDateState = (start, end) => {
    setClientData({ ...clientData, rangeStartDate: start, rangeEndDate: end });
  };

  const handleCountryStates = (compareValue) => {
    const fIndex = countries.findIndex((el) => el.name === compareValue);
    if (fIndex >= 0) {
      const copyStates = JSON.parse(JSON.stringify(states));
      const filteredArray = copyStates.filter(
        (el) => el.countryCode === countries[fIndex].isoCode
      );
      const list = filteredArray.sort((a, b) => a.name.localeCompare(b.name));
      setCountry(`+${countries[fIndex].phonecode}`);
      setStateArray(filteredArray);
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;

    console.log('gggg', { name, value });

    if (name === 'gender') value = e.target.id;
    if (name === 'hide') value = JSON.parse(e.target.value);
    if (name === 'numberUsers') value = Number(value);
    if (name === 'agreeStatus') value = e.target.checked;

    const copy = { ...clientData };

    if (name === 'country') {
      if (value === 'Australia') {
        setMobileFormat('### ### ###');
      }
      if (value === 'United States') {
        setMobileFormat('### ### ####');
      }
      handleCountryStates(value);
      copy.state = '';
    }

    const validateConditionalFields = () => {
      if (name === 'postcode' && value.length >= 10) {
        return false;
      }
      copy[name] = value;
      // console.log(value, name, 'value');
      if (name === 'billingCycle') {
        // console.log(copy, 'copyvalue');
        copy.costDropDown = value === 'Annual' ? 'per annum' : 'per month';
      }
      if (name === 'costDropDown') {
        copy.billingCycle = value === 'per annum' ? 'Annual' : 'Monthly';
      }
      return true;
    };

    if (name === 'customerType' && selectedClient) {
      const client = clientList.filter((el) => el._id === selectedClient);
      if (
        // eslint-disable-next-line operator-linebreak
        (client[0].customerType === 'Householder' && value !== 'Householder') ||
        (client[0].customerType !== 'Householder' && value === 'Householder')
      ) {
        editCustomerType(value);
        return;
      }
    }
    if (!validateConditionalFields()) return;
    setClientData(copy);
  };

  const handleClosePaymentForm = () => {
    setPaymentResult('');
    setOpenPaymentForm(false);
  };

  // this is the add Subscriber function after doing the payment is paid else in trial case it will be directl called
  const creatingSubscriberAfterpayment = async (resultData) => {
    let payload;
    // console.log('step 1');

    if (resultData) {
      payload = { ...clientData, resultData };
    } else {
      payload = clientData;
    }
    // console.log('step 2');

    if (selectedCountry === '+61') {
      if (payload.mobileNo.charAt(0) === '0') {
        payload.mobileNo = payload.mobileNo.replace('0', '');
      }
      if (payload.landlineNo.charAt(0) === '0') {
        payload.landlineNo = payload.landlineNo.replace('0', '');
      }
    }
    if (payload?.landlineNo) {
      payload.landlineNo = `${selectedCountry} ${payload.landlineNo}`;
    }
    if (payload?.mobileNo) {
      payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
    }

    payload.email = payload.email.toLowerCase();
    payload.industryId = userData?.industryId._id;

    // payload before going into the server
    // console.log('New user data=>', payload);
    const responseUser = await userInstance().post(
      '/user/registerSubscriber',
      payload
    );
    // console.log('responseUser=====>>>', responseUser);

    setShowForm(false);
    getClientList();
    toastMessage('success', 'Subscriber Added Successfully');
    setPaymentResult('');
    const { msg, code, userid } = responseUser.data;
    setOpenPaymentForm(false);
    delete poolData._id;
    delete poolData.userPool;
    delete poolData.taxApplicable;

    poolData?.calciumAdjustments?.forEach((elem) => {
      elem.okNegative = '0.1';
      elem.okPositive = '49';
      elem.drainPoint = '50';
    });
    // console.log(
    //   'poolData?.calciumAdjustments======>>',
    //   poolData?.calciumAdjustments
    // );

    const addNewSubscriberPayload = {
      userid,
      poolData,
      role: 'subscriber',
    };
    // console.log(poolData, 'pppppppp');

    await userInstance().post(
      '/subscribers/addNewSubscriberData',
      addNewSubscriberPayload
    );
  };
  // this function will be called after payment for the extra cost in the edit subscriber function
  const editSubscriberAfterPayment = async (resultData, oldPayload) => {
    let payload;
    if (resultData) {
      payload = { ...oldPayload, resultData, selectedClient };
    } else {
      payload = { ...oldPayload, selectedClient };
    }
    let response;
    try {
      response = await userInstance().put(
        '/subscribers/updateSubscriber',
        payload
      );
      setpayloadBeforeEdit({});
      setPaymentResult('');
      setOpenPaymentForm(false);
      setShowForm(false);
      if (clientTxt) {
        searchClient(clientTxt);
      } else {
        getClientList();
      }
      setError('');
      toastMessage('success', response.data.msg);
    } catch (err) {
      toastMessage('error', response.data.msg);
    }
  };
  // handle Token function will be called when doing the payment
  const handleToken = async (token) => {
    setPaymentLoader(true);
    try {
      if (token) {
        setDisableButton(true);
        // amount of the payment will the client data total cost
        const amountData = clientData.totalCost;
        const payload = {
          sourceId: token.token,
          amountMoney: {
            currency: 'AUD',
            amount: Math.ceil(amountData),
          },
        };
        // console.log('payload ==>', payload);
        // api for doing the payment
        const response = await userInstance().post(
          '/subscribers/subscriberPayment',
          // eslint-disable-next-line comma-dangle
          payload
        );
        // console.log('response =>', response);
        // if payment is successful then go to the createSubscriberAfterPayment
        if (response.data.code === 200) {
          setPaymentResult('Payment Successful');
          // if selected client is present then update the subscriber
          if (selectedClient) {
            editSubscriberAfterPayment(
              response.data.resultData,
              payloadBeforeEdit
            );
          } else {
            // add the subscriber into the database
            creatingSubscriberAfterpayment(response.data.resultData);
          }

          setDisableButton(false);
          setPaymentLoader(false);
        } else {
          setPaymentResult('Payment Successful');
          setDisableButton(false);
          setPaymentLoader(false);
        }
      }
    } catch (error) {
      setDisableButton(false);
      setPaymentLoader(false);
    }
  };
  // handle Add Customer will add a customer
  const handleAddCustomer = async (e) => {
    e.preventDefault();
    const {
      customerType,
      companyName,
      firstname,
      lastname,
      mobileNo,
      email,
      streetAddress,
      suburb,
      state,
      country,
      postcode,
      agreeStatus,
      duration,
      numberUsers,
      numberOfPools,
      subscriptionStatus,
      invoiceSendType,
    } = clientData;
    const payload = { ...clientData };

    try {
      const validationObj = {
        'customer type': customerType,
        'subscription duration': duration,
        'number of pools': numberOfPools,
        'number of user': numberUsers,
        country,
        'first name': firstname,
        'last name': lastname,
        'mobile no': mobileNo,
        email,
        'street address': streetAddress,
        suburb,
        'postal/zip code': postcode,
        state,
      };
      if (customerType !== 'Householder') {
        validationObj[`${customerType} name`] = companyName;
      }
      if (subscriptionStatus === 'trial') {
        delete validationObj['number of pools'];
        delete validationObj['number of user'];
      }
      validationObj['invoice sending type'] = invoiceSendType;
      let { isValid, emptyField } = validateDataWithKey(
        validationObj,
        'checkMinus'
      );

      let emailValid = true;
      if (email) {
        if (emailRegex.test(String(clientData.email).toLowerCase())) {
          emailValid = true;
        } else {
          emailValid = false;
        }
      }

      if (isValid) {
        if (!agreeStatus) {
          toastMessage(
            'error',
            'You need to agree to be bound by our Terms & Conditions of Use to continue.'
          );
          setError('agreeStatus');
          setTimeout(() => {
            setError('');
          }, 1000);
          return;
        }

        let isMobileValid = true;
        let isLandlineValid = true;
        let mobileMsg = '';
        let landlineMsg = '';

        if (selectedCountry === '+61') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }

          if (
            // eslint-disable-next-line operator-linebreak
            clientData.landlineNo &&
            clientData.landlineNo.trim().length !== 11
          ) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          if (payload.mobileNo.charAt(0) !== '4') {
            isMobileValid = false;
            mobileMsg = 'Please start mobile number with 4';
            setError('mobileno');
          }
          if (payload.mobileNo.trim().length !== 11) {
            setError('mobileno');
            isMobileValid = false;
            // mobileMsg = 'Invalid Mobile Number';
            mobileMsg = 'Enter 9 Digit Mobile Number';
          }
        }

        if (selectedCountry === '+1') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }

          if (
            // eslint-disable-next-line operator-linebreak
            clientData.landlineNo &&
            clientData.landlineNo.trim().length !== 11
          ) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          if (payload.mobileNo.charAt(0) !== '4') {
            isMobileValid = false;
            mobileMsg = 'Please start mobile number with 4';
            setError('mobileno');
          }
          if (payload.mobileNo.trim().length !== 12) {
            setError('mobileno');
            isMobileValid = false;
            // mobileMsg = 'Invalid Mobile Number';
            mobileMsg = 'Enter 10 Digit Mobile Number';
          }
        }

        // payload.totalCost = 1;
        // payload.extraCost = 1;
        // clientData.totalCost = 1;
        // clientData.extraCost = 1;

        if (isLandlineValid) {
          if (payload?.landlineNo) {
            payload.landlineNo = `${selectedCountry} ${payload.landlineNo}`;
          }
          if (emailValid) {
            if (isMobileValid) {
              if (payload?.mobileNo) {
                payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
              }

              const emailExist = await userInstance().post(
                '/user/checkUserMail',
                { email }
              );
              console.log(
                'clientData.subscriptionStatus=====>>>',
                clientData.subscriptionStatus
              );
              console.log('clientData======>> Sheck====>>', clientData);

              if (emailExist.data.code === 200) {
                // if the chose subscription status is trial then directly add the customer
                //if (clientData.subscriptionStatus === 'trial') {
                console.log('iudsjdsud');

                // creatingSubscriberAfterpayment();
                //  } else {
                // open the Form for doing the payment
                setOpenPaymentForm(true);
                setSmallLoader(true);
                setTimeout(() => {
                  setSmallLoader(false);
                }, 1000);
                setError('');
                // }
              } else {
                setError('email');
                toastMessage('error', 'Email already register');
              }
            } else if (!isMobileValid) {
              setError('mobileno');
              toastMessage('error', mobileMsg);
            }
          } else {
            setError('email');
            toastMessage('error', 'Email is Invalid!');
          }
        } else {
          toastMessage('error', landlineMsg);
        }
      } else {
        setError(emptyField?.split(' ').join('').toLowerCase());
        toastMessage('error', `Please fill ${emptyField}`);
      }
      setTimeout(() => {
        setError('');
      }, 1000);
    } catch (error) {
      const { msg } = error?.response?.data;
      toastMessage('error', msg);
    }
  };
  // this function will open the Subscriber Form
  const handleFormModal = () => {
    handleCountryStates(clientData?.country);
    // set client Data to the intitial State
    setClientData({ ...initialClientData });
    // make the copy client data to an empy object
    setcopyClientData({});
    // remove the client id for the New Subscriber Addition
    setClientId('');
    // open the Add Subscriber Form
    setShowForm(!showForm);
  };
  // this function will open the Subscriber Form for the edit section
  const handleEditClient = (client) => {
    handleCountryStates(client?.country);

    setClientId(client._id);
    setShowForm(true);

    // let copySelectedCountry = selectedCountry;
    if (client?.mobileNo) {
      const { updatedNo } = removeCodeFromNumber(client.mobileNo);
      client.mobileNo = updatedNo;
      // copySelectedCountry = countryCode;
    }
    if (client?.landlineNo) {
      const { updatedNo } = removeCodeFromNumber(client.landlineNo);
      client.landlineNo = updatedNo;
    }

    // setCountry(copySelectedCountry);

    const {
      subscriptionStatus,
      duration,
      numberUsers,
      numberOfPools,
      totalCost,
    } = client;

    setcopyClientData({
      subscriptionStatus,
      duration,
      numberUsers,
      numberOfPools,
      totalCost,
    });

    setClientData({ ...clientData, ...client });
  };
  // submit function for editing the Subscriber Before Payment
  const editClient = async (e) => {
    e.preventDefault();

    const {
      customerType,
      companyName,
      firstname,
      lastname,
      mobileNo,
      landlineNo,
      email,
      billingAddressType,
      billingAddress,
      streetAddress,
      state,
      suburb,
      country,
      invoiceSendType,
      postcode,
      agreeStatus,
    } = clientData;

    let name = [firstname, lastname].join(' ');
    const payload = { ...clientData, name };
    try {
      const validationObj = {
        country,
        'first name': firstname,
        'last name': lastname,
        'mobile no': mobileNo,
        email,
        'street address': streetAddress,
        suburb,
        'postal/zip code': postcode,
        state,
      };
      if (billingAddressType === 'same') {
        validationObj['street address'] = streetAddress;
        payload.billingAddress = streetAddress;
      } else if (billingAddressType === 'different') {
        validationObj['billing address'] = billingAddress;
      }
      let { isValid, emptyField } = validateDataWithKey(validationObj);
      let emailValid = true;
      if (email) {
        if (emailRegex.test(String(clientData.email).toLowerCase())) {
          emailValid = true;
        } else {
          emailValid = false;
        }
      }

      if (isValid) {
        if (!agreeStatus) {
          toastMessage(
            'error',
            'You need to agree to be bound by our Terms & Conditions of Use to continue.'
          );
          setError('agreeStatus');
          setTimeout(() => {
            setError('');
          }, 1000);
          return;
        }
        let isMobileValid = true;
        let isLandlineValid = true;
        let mobileMsg = '';
        let landlineMsg = '';
        if (selectedCountry === '+61') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }

          if (
            // eslint-disable-next-line operator-linebreak
            clientData.landlineNo &&
            clientData.landlineNo.trim().length !== 11
          ) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          if (payload.mobileNo.charAt(0) !== '4') {
            isMobileValid = false;
            mobileMsg = 'Please start mobile number with 4';
            setError('mobileno');
          }
          if (payload.mobileNo.trim().length !== 11) {
            setError('mobileno');
            isMobileValid = false;
            mobileMsg = 'Invalid Mobile Number';
          }
        }
        if (selectedCountry === '+1') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }

          if (
            // eslint-disable-next-line operator-linebreak
            clientData.landlineNo &&
            clientData.landlineNo.trim().length !== 11
          ) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          if (payload.mobileNo.charAt(0) !== '4') {
            isMobileValid = false;
            mobileMsg = 'Please start mobile number with 4';
            setError('mobileno');
          }
          if (payload.mobileNo.trim().length !== 12) {
            setError('mobileno');
            isMobileValid = false;

            mobileMsg = 'Enter 10 Digit Mobile Number';
          }
        }
        if (isLandlineValid) {
          if (payload?.landlineNo) {
            payload.landlineNo = `${selectedCountry} ${payload.landlineNo}`;
          }
          if (emailValid) {
            if (isMobileValid) {
              if (payload?.mobileNo) {
                payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
              }

              payload.planId = planId;
              payload.email = payload.email.toLowerCase();
              // update the subscriber only if the extra cost is equal to zero that means no Subscription Payment add on
              if (!payload?.extraCost) {
                editSubscriberAfterPayment(null, payload);
              } else {
                // first do the Payment and then update the subscriber
                // open the Form for doing the payment
                setpayloadBeforeEdit(payload);
                setOpenPaymentForm(true);
                setSmallLoader(true);
                setTimeout(() => {
                  setSmallLoader(false);
                }, 1000);
                setError('');
              }
            } else if (!isMobileValid) {
              setError('mobileno');
              toastMessage('error', mobileMsg);
            }
          } else {
            setError('email');
            toastMessage('error', 'Email is not Valid');
          }
        } else {
          toastMessage('error', landlineMsg);
        }
      } else {
        setError(emptyField?.split(' ').join('').toLowerCase());
        toastMessage('error', `Please fill ${emptyField}`);
      }
      setTimeout(() => {
        setError('');
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const editSubfields = async () => {
    try {
      const clientListCopy = clientList;
      const client = clientListCopy[selectedIndex];
      client.siteAddresses.forEach((el) => {
        delete el.tempShow;
      });
      client.associatedContacts.forEach((el) => {
        delete el.tempShow;
      });
      const { associatedContacts, siteAddresses } = client;
      await userInstance().put('/subscribers/updateSubfields', {
        associatedContacts,
        selectedClient,
        siteAddresses,
      });
      toastMessage('success', msg);
      client.siteAddresses.forEach((el) => {
        el.tempShow = hideClicked;
      });
      client.associatedContacts.forEach((el) => {
        el.tempShow = hideClickedAssociated;
      });
      setClientList([...clientListCopy]);
      setRun(false);
    } catch (error) {
      setRun(false);
    }
  };

  const removeClient = async (id) => {
    try {
      const response = await userInstance().delete(
        `/subscribers/deleteSubscriber?id=${id}`
      );
      getClientList();
      setnewState(!newstate);
      setClientIdentity(false);
      toastMessage('success', 'Client deleted successfully');
    } catch (error) {
      console.log(error);
    }
  };

  const setBooking = () => {
    setClientData({ ...clientData, showBookings: !clientData.showBookings });
  };
  const backToAppointment = () => {
    if (typee && crossStatus && back) {
      history.push(
        `/addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&clientid=${clientid}`
      );
    } else if (typee) {
      let oldUrl = getCalendarPath();
      const newUrl = `${oldUrl}&clientid=${clientid}`;
      if (typee === 'edit') localStorage.setItem('idClient', clientid);
      history.push(newUrl);
    }
  };

  const handleResendLogin = async (clId) => {
    try {
      const response = await userInstance().put('/staff/resendPassword', {
        staffid: clId,
      });
      toastMessage('success', response.data.msg);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (runEdit === null) {
      setLoading(true);
      getClientList();
    }
    if (runEdit === true) {
      editSubfields();
    }
  }, [skip, runEdit]);

  const handleUpgradePopUp = () => {
    let value = subsTypeName;
    let name = 'subscriptionType';
    let numberUsers = Number(clientData.numberUsers);
    let annnumCost = Number(clientData.annnumCost);
    let monthlyCost = Number(clientData.monthlyCost);

    let usersDisplay;

    const valueNumber = numberUsers > 1 ? numberUsers - 1 : 0;
    if (value === 'Basic Plan') {
      annnumCost = Number((valueNumber * 10 + 15) * 12);
      monthlyCost = Number(valueNumber * 10 + 15);
    } else if (value === 'Standard Plan') {
      annnumCost = Number((valueNumber * 10 + 18) * 12);
      monthlyCost = Number(valueNumber * 10 + 18);
    } else if (value === 'Premium Plan') {
      annnumCost = Number((valueNumber * 10 + 20) * 12);
      monthlyCost = Number(valueNumber * 10 + 20);
    }

    if (name === 'customerType' && selectedClient) {
      const client = clientList.filter((el) => el._id === selectedClient);
      if (
        // eslint-disable-next-line operator-linebreak
        (client[0].customerType === 'Householder' && value !== 'Householder') ||
        (client[0].customerType !== 'Householder' && value === 'Householder')
      ) {
        editCustomerType(value);
      } else {
        setClientData({ ...clientData, [name]: value });
      }
    } else {
      setClientData({
        ...clientData,
        [name]: value,
        numberUsers,
        usersDisplay,
        monthlyCost,
        annnumCost,
      });
    }
    setUpgradePopUp(false);
  };

  useEffect(() => {
    if (error) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [error]);

  const pageCount = Math.ceil(pageCounts / limit);

  return (
    <>
      <AdminWrapper>
        {loading ? (
          <Loader />
        ) : (
          <div className=' client-container subcription-page'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <SubscriptionTable
                    setClientNewData={setClientNewData}
                    clientNewData={clientNewData}
                    clientList={clientList}
                    HandleOpenCloseTable={HandleOpenCloseTable}
                    handleFormModal={handleFormModal}
                    handleEditClient={handleEditClient}
                    removeClient={removeClient}
                    setShowHide={setShowHide}
                    showHide={showHide}
                    searchClient={searchClient}
                    setSearchData={setSearchData}
                    searchData={searchData}
                    handleContactModal={handleContactModal}
                    handleEditContact={handleEditContact}
                    handleDeleteContact={handleDeleteContact}
                    handleEditAddress={handleEditAddress}
                    handleDeleteAddress={handleDeleteAddress}
                    handleVisibilityAddr={handleVisibilityAddr}
                    name={name}
                    backToAppointment={backToAppointment}
                    crossStatus={crossStatus}
                    newstate={newstate}
                    setnewState={setnewState}
                    setClientIdentity={setClientIdentity}
                    handleResendLogin={handleResendLogin}
                    skip={skip}
                    isHideSearch={isHideSearch}
                    setHideSearch={setHideSearch}
                    setSkip={setSkip}
                    handleSiteAddressModal={handleSiteAddressModal}
                    showAll={showAll}
                    hideClicked={hideClicked}
                    hideClickedAssociated={hideClickedAssociated}
                  />
                  <SubscriptionForm
                    HandleOpenCloseTable={HandleOpenCloseTable}
                    handleChange={handleChange}
                    handleDateState={handleDateState}
                    clientData={clientData}
                    copyClientData={copyClientData}
                    setClientData={setClientData}
                    handleAddCustomer={handleAddCustomer}
                    showForm={showForm}
                    handleFormModal={handleFormModal}
                    selectedClient={selectedClient}
                    editClient={editClient}
                    handleCountry={handleCountry}
                    selectedCountry={selectedCountry}
                    setBooking={setBooking}
                    setUpgradePopUp={setUpgradePopUp}
                    poolFinishArray={poolFinishArray}
                    poolTypeArray={poolTypeArray}
                    convertPoolChlorineArray={convertPoolChlorineArray}
                    chlorinationArray={chlorinationArray}
                    subscriptiontype={subscriptiontype}
                    setSubscriptiontype={setSubscriptionType}
                    error={error}
                    countries={countries}
                    stateArray={stateArray}
                    mobileFormat={mobileFormat}
                    handleSubscription={handleSubscription}
                  />
                  <div className='view-btn'>
                    <Button
                      type='button'
                      className='btn add-btn desktop'
                      onClick={handleFormModal}
                    >
                      Add Subscriber
                    </Button>
                  </div>

                  {deletePopUp && (
                    <DeletePopUp
                      handlePopUpChange={handlePopUpChange}
                      setDeletePopUp={setDeletePopUp}
                      PopUpText={PopUpText}
                      paraText={paraText}
                    />
                  )}

                  {clientList && !clientIdentity ? (
                    <div className='user-pagination'>
                      <ReactPaginate
                        previousLabel='← Previous'
                        nextLabel='Next →'
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName='pagination-chk'
                        previousLinkClassName='pagination__link'
                        nextLinkClassName='pagination__link'
                        disabledClassName='pagination__link--disabled'
                        activeClassName='pagination__link--active'
                        forcePage={selectedPage - 1}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <AddSubsSiteAddress
              handleSiteAddressModal={handleSiteAddressModal}
              showSiteAddress={showSiteAddress}
              addressData={addressData}
              handleAddressData={handleAddressData}
              handleAddAddress={handleAddAddress}
              selectedCountry={selectedCountry}
              handleSiteAddressCountry={handleSiteAddressCountry}
              error={error}
            />

            <AddBilingContact
              showContactModal={showContactModal}
              handleContactModal={handleContactModal}
              handleContactData={handleContactData}
              handleAddBillingContact={handleAddBillingContact}
              contactData={contactData}
              error={error}
            />
            {upgradePopUp ? (
              <UpgradePlanPopUp
                upgradePopUp={upgradePopUp}
                setUpgradePopUp={setUpgradePopUp}
                handleUpgradePopUp={handleUpgradePopUp}
              />
            ) : null}

            {openPaymentForm ? (
              <StripePaymentPopup
                openPaymentForm={openPaymentForm}
                clientData={clientData}
                handleToken={handleToken}
                handleAction={handleClosePaymentForm}
                paymentResult={paymentResult}
                disableButton={disableButton}
                smallLoader={smallLoader}
                returnPopUp='subscriptionPage'
                handleFormModal={handleFormModal}
                paymentLoader={paymentLoader}
                poolData={poolData}
                UpdateSubscriptionPopup={false}
                updateSubscriptionTotalCostValue={0}
              />
            ) : // eslint-disable-next-line operator-linebreak
            // <PaymentDetails
            //   clientData={clientData}
            //   openPaymentForm={openPaymentForm}
            //   handleToken={handleToken}
            //   handleAction={handleClosePaymentForm}
            //   paymentResult={paymentResult}
            //   disableButton={disableButton}
            //   smallLoader={smallLoader}
            //   returnPopUp="subscriptionPage"
            //   handleFormModal={handleFormModal}
            //   paymentLoader={paymentLoader}
            // />
            null}
          </div>
        )}
      </AdminWrapper>
    </>
  );
}

export default Subscription;
