/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SoldData from '../prduct&services/productSold';
import ProductView from './productview';
import ServiceView from './serviceview';
import search from '../../assets/home/search.png';
import crosss from '../../assets/home/black-icon.png';
import DurationPicker from './durationPicker';
import { userInstance } from '../../config/axios';

const ServiceModal = (props) => {
  const {
    setDuration,
    durationData,
    setDurationPicker,
    durationPicker,
    showService,
    handleShowService,
    subcategories,
    handleBookingData,
    getTimes,
    bookingData,
    renderOptions,
    staffList,
    handleAddService,
    editData,
    handleDeleteService,
    handleSearchCategory,
    setSubcategories,
    addSubcategoryToService,
    editService,
    setBookingData,
    servicesLength,
    extras,
    handleChangeCustom,
    timepickerShow,
    handleTimePicker,
    subcategoryTxt,
    setSearchTxt,
    convertToReadableDuration,
    handleDurationPicker,
    setnewlength,
    newLength,
    staffIndex,
    handleHourPicker,
    appointmentType,
    setShowService,
    levelData,
    endTimeNew,
    timeZone,
    timeDisplay,
    error,
  } = props;
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setSubcategories(null);
          setDurationPicker(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useEffect(() => {
    setnewlength(servicesLength);
  }, [servicesLength]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Modal
      size="md"
      show={showService}
      onHide={handleShowService}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="appointment-service-modal add-maintanance-popup"
    >
      <div className="staff-close">
        <i
          className="fa fa-times"
          onClick={() => setShowService(!showService)}
          aria-hidden="true"
        />
      </div>
      <Modal.Header> Add Maintenance Required </Modal.Header>
      <Modal.Body>
        <div className="service-popup">
          <div className="service-form">
            <Form>
              <div className="row">
                <div className="col">
                  <Form.Group controlId="formBasicHiddenEmployees">
                    <Form.Label>Name of Person Doing Maintenance</Form.Label>
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        onChange={handleBookingData}
                        value={bookingData.staffName}
                        name="staffName"
                        disabled={
                          editData.type && editData.type === 'mark'
                            ? false
                            : newLength > 0
                        }
                        className={`${
                          error === 'staffName' ? 'error-text' : ''
                        }`}
                      >
                        {staffList.map((el) => (
                          <option
                            value={`${el.firstname} ${el.lastname}`}
                            key={el._id}
                          >
                            {`${el.firstname} ${el.lastname}`}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group controlId="formBasicHiddenEmployees">
                    <Form.Label>Maintenance Required</Form.Label>
                    <div className="select-box">
                      <i className="fas fa-sort-down" />
                      <Form.Control
                        as="select"
                        onChange={(e) => addSubcategoryToService(e.target.value)}
                        value={bookingData.subcategory}
                        name="service"
                      >
                        <option
                          value="Pool Vacuum & Clean"
                          key="Pool Vacuum & Clean"
                        >
                          Pool Vacuum & Clean
                        </option>
                        <option
                          value="Misc Services/Labour"
                          key="Misc Services/Labour"
                        >
                          Misc Services/Labour
                        </option>
                        <option
                          value=" Misc Products/Materials/Parts"
                          key=" Misc Products/Materials/Parts"
                        >
                          Misc Products/Materials/Parts
                        </option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                {bookingData.category.toLowerCase().includes('misc') ? (
                  <div className="col">
                    <Form.Group controlId="formBasicHiddenEmployees">
                      <Form.Label>
                        {bookingData.category.toLowerCase().includes('services')
                          ? 'Rename Misc Services/Labour'
                          : 'Rename Misc Products/Materials/Parts'}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter New Name"
                        name="customMisc"
                        value={bookingData.customMisc}
                        onChange={handleBookingData}
                        className={
                          bookingData.category.toLowerCase().includes('misc')
                          && bookingData.customMisc !== ''
                            ? ''
                            : 'addBorder'
                        }
                      />
                    </Form.Group>
                  </div>
                ) : null}
              </div>

              <ServiceView
                handleBookingData={handleBookingData}
                getTimes={getTimes}
                bookingData={bookingData}
                renderOptions={renderOptions}
                editData={editData}
                setBookingData={setBookingData}
                servicesLength={servicesLength}
                extras={extras}
                handleChangeCustom={handleChangeCustom}
                timepickerShow={timepickerShow}
                handleTimePicker={handleTimePicker}
                durationPicker={durationPicker}
                setDurationPicker={setDurationPicker}
                convertToReadableDuration={convertToReadableDuration}
                handleDurationPicker={handleDurationPicker}
                subcategoryTxt={subcategoryTxt}
                handleHourPicker={handleHourPicker}
                endTimeNew={endTimeNew}
                timeDisplay={timeDisplay}
                timeZone={timeZone}
              />

              <div className="row">
                <div className="col-md-12 text-right">
                  <div
                    className={`view-btn service-submit text-right ${
                      editData.edit === true ? '' : 'single-btn'
                    }`}
                  >
                    {editData.edit === true ? (
                      <Button
                        className="btn-delete"
                        onClick={handleDeleteService}
                      >
                        Delete
                      </Button>
                    ) : null}

                    <Button
                      className="add-btn"
                      onClick={editData.edit ? editService : handleAddService}
                    >
                      Save/Update
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
      {durationPicker && (
        <DurationPicker
          durationPicker={durationPicker}
          setDurationPicker={setDurationPicker}
          durationData={durationData}
          handleDurationPicker={handleDurationPicker}
          setDuration={setDuration}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      )}
    </Modal>
  );
};

ServiceModal.propTypes = {
  showService: PropTypes.func.isRequired,
  handleShowService: PropTypes.func.isRequired,
  subcategories: PropTypes.func.isRequired,
  handleBookingData: PropTypes.func.isRequired,
  getTimes: PropTypes.func.isRequired,
  bookingData: PropTypes.func.isRequired,
  renderOptions: PropTypes.func.isRequired,
  staffList: PropTypes.func.isRequired,
  handleAddService: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  handleDeleteService: PropTypes.func.isRequired,
  handleSearchCategory: PropTypes.func.isRequired,
  setBookingData: PropTypes.func.isRequired,
  setSubcategories: PropTypes.func.isRequired,
  addSubcategoryToService: PropTypes.func.isRequired,
  editService: PropTypes.func.isRequired,
  servicesLength: PropTypes.func.isRequired,
  extras: PropTypes.shape({}).isRequired,
  handleChangeCustom: PropTypes.func.isRequired,
  timepickerShow: PropTypes.bool.isRequired,
  handleTimePicker: PropTypes.func.isRequired,
  subcategoryTxt: PropTypes.string.isRequired,
  setSearchTxt: PropTypes.func.isRequired,
  setDurationPicker: PropTypes.func.isRequired,
  durationPicker: PropTypes.bool.isRequired,
  convertToReadableDuration: PropTypes.func.isRequired,
  handleDurationPicker: PropTypes.func.isRequired,
  handleHourPicker: PropTypes.func.isRequired,
  appointmentType: PropTypes.string.isRequired,
  // durationData: PropTypes.func.isRequired,
  // setDuration: PropTypes.func.isRequired,
  setShowService: PropTypes.func.isRequired,
  levelData: PropTypes.shape([]).isRequired,
  endTimeNew: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
};

export default ServiceModal;
