import React, { useEffect, useState } from 'react';

const useLocalStorage = (key, deffaltValue, isObjOrArr = false, isBool = false, isNumber = false) => {
  const [state, setState] = useState();

  useEffect(() => {
    if (isObjOrArr) {
      setState(localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : deffaltValue);
    } else if (isBool) {
      setState(localStorage.getItem(key) ? localStorage.getItem(key) === 'true' : deffaltValue);
    } else if (isNumber) {
      // eslint-disable-next-line no-restricted-globals
      setState(localStorage.getItem(key) && !isNaN(parseFloat(localStorage.getItem(key))) ? parseFloat(localStorage.getItem(key)) : deffaltValue);
    } else {
      setState(localStorage.getItem(key) || deffaltValue);
    }
  }, [key]);

  useEffect(() => {
    localStorage.setItem(key, state);
  }, [state]);

  return [
    state, setState,
  ];
};

export default useLocalStorage;
