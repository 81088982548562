/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable quotes */
/* eslint-disable no-undef */
import React, { useRef } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from '../../functions/moment';
// import SquareCheckout from './squareCheckout';
import './payment.css';
import SubsPayment from '.';

const StripePaymentPopup = ({
  openPaymentForm,
  clientData,
  handleAction,
  smallLoader,
  returnPopUp,
  handleFormModal,
  // eslint-disable-next-line react/prop-types
  poolData,
  updateSubscriptionTotalCostValue,
  UpdateSubscriptionPopup,
  totalCostForFIlteringProduct,
  specificDate,
  slectedItem,
  handleToggleSubscription,
  tokenState
}) => {
  console.log("Value Check===========>>", { clinentAmount: clientData.totalCost, updateSubscriptionTotalCostValue, clientData });
  
  const timepickerRef = useRef(null);
  const getDesiredEndDate = () => {
    const { duration } = clientData;
    let futureDate;
    if (duration === 12) {
      futureDate = moment(moment()).add(1, 'year').format('D MMM YYYY');
    } else if (duration === 24) {
      futureDate = moment(moment()).add(2, 'year').format('D MMM YYYY');
    } else if (duration === 36) {
      futureDate = moment(moment()).add(3, 'year').format('D MMM YYYY');
    } else {
      futureDate = moment(moment()).add(10, 'days').format('D MMM YYYY');
    }
    return futureDate;
  };

  return (
    <div className='staff-modal BillSummary'>
      <Modal show={openPaymentForm} className='billSummary'>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <div
          className='back-popup crossBtn'
          onClick={() => handleAction(returnPopUp)}
        >
          <i className='las la-times' />
        </div>
        <Modal.Body>
          <div>
            <div className='billing-details-section'>
              <h3 className='section-title'>Billing Summary</h3>
              <div className='billl-content'>
                <div className='upper-section'>
                  <p className='bill-label'>
                    Subscription Duration:
                    <span>{clientData.duration} months</span>
                    <span>
                      {' '}
                      {`(${moment(moment()).format('D MMM YYYY')}`} -{' '}
                      {`${getDesiredEndDate()})`}
                      {/* {moment(userInformation?.endsubscriptionDate).format('D MMM YYYY')} */}{' '}
                    </span>
                  </p>
                  {/* <p className="bill-value">(9 May 2023 - 8 May 2025)</p> */}
                </div>
                <p className='bill-label'>
                  No. of Pools:
                  <span>{clientData.numberOfPools} Pools</span>
                </p>
                <p className='bill-label'>
                  No. of Users:
                  <span>{clientData.numberUsers} User</span>
                </p>
              </div>
              {clientData.extraCost && (
                <div className='total-cost'>
                  Cost to Pay:
                  <span>
                    {clientData.country === 'Australia' ? 'AU' : 'US'}$
                    {Number(clientData?.extraCost || 0)?.toFixed(2)}
                  </span>
                </div>
              )}
              <div className='total-cost'>
                Total Cost:
                {UpdateSubscriptionPopup ? (
                  <span>
                    {clientData.country === 'Australia' ? 'AUD' : 'USD'}$
                    {updateSubscriptionTotalCostValue}
                  </span>
                ) : clientData?.subscriptionStatus === "canceled" ? (
                  <span>
                    {clientData.country === 'Australia' ? 'AUD' : 'USD'}$
                    {updateSubscriptionTotalCostValue}
                  </span>
                ) : (
                  <span>
                    {clientData.country === 'Australia' ? 'AUD' : 'USD'}$
                    {Number(clientData?.totalCost || 0)?.toFixed(2)}
                  </span>
                )}
              </div>
              {/* <input type='text' placeholder="Enter your username" /> */}
              <h3 className='section-title'>Card Details</h3>
              <div
                className={
                  smallLoader ? 'show-loader-payment' : 'hide-loader-payment'
                }
              >
                <div className='video-loader'>
                  <Spinner animation='border' />
                </div>

                <div className='payment-form1'>
                  <SubsPayment
                    returnPopUp={returnPopUp}
                    handleFormModal={handleFormModal}
                    handleAction={handleAction}
                    clientData={clientData}
                    amountDisplay={
                      UpdateSubscriptionPopup
                        ? totalCostForFIlteringProduct
                        : clientData.subscriptionStatus === "canceled" ? updateSubscriptionTotalCostValue : clientData.extraCost
                        ? clientData.extraCost
                        : clientData.totalCost
                    }
                    poolData={poolData}
                    UpdateSubscriptionPopup={UpdateSubscriptionPopup}
                    specificDate={specificDate}
                    slectedItem={slectedItem}
                    handleToggleSubscription={handleToggleSubscription}
                    tokenState={tokenState}
                  />
                  {/* <SquareCheckout handleToken={handleToken} amountDisplay={clientData.extraCost ? clientData.extraCost : clientData.totalCost} paymentLoader={paymentLoader} /> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
      <Button variant="secondary">
        Close
      </Button>
      <Button variant="primary">
        Save Changes
      </Button>
    </Modal.Footer> */}
      </Modal>
    </div>
  );
};
StripePaymentPopup.propTypes = {
  clientData: PropTypes.objectOf.isRequired,
  returnPopUp: PropTypes.string.isRequired,
  handleAction: PropTypes.string.isRequired,
  smallLoader: PropTypes.bool.isRequired,
  handleFormModal: PropTypes.string.isRequired,
  openPaymentForm: PropTypes.bool.isRequired,
};
export default StripePaymentPopup;
