/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import { months } from 'moment';
import moment from './moment';
import { removeComma, calculatChemicalCost } from './index';
import {
  addTimeZone, getTimeZone, udpateTime, updateEndTime, 
} from './timeDate';

export const newappointmentEventCreate = (
  appointments,
  blocktimes,
  secondcolors,
  colors,
  filterStart,
  filterEnd,
  timeZone,
) => {
  const color = localStorage.getItem('color');
  const secondcolor = localStorage.getItem('secondcolor');
  const eventAllData = [];
  if (blocktimes && blocktimes.length > 0) {
    for (let blocks of blocktimes) {
      const dbTimeZone = blocks?.timeZone || timeZone;
      const filterDateList = blocks.repeates.filter(
        (d) => new Date(d) >= new Date(filterStart)
          && new Date(d) <= new Date(filterEnd),
      );
      for (let blockDate of filterDateList) {
        const date = blockDate.split('T');
        const startTimeDate = blocks?.startdate.split('T');
        const endTimeDate = blocks?.enddate.split('T');
        const blockstartDate = `${date[0]}T${startTimeDate[1]}`;
        const blockendDate = `${date[0]}T${endTimeDate[1]}`;

        if (blocks.type === 'personalappointment') {
          let myEvent = {
            title: blocks.blockname,
            start: blockstartDate,
            end: blockendDate,
            backgroundColor: '#03c',
            textColor: '#fff',
            type: 'personaltype',
            reapated: blocks.interval_type ? 'reapated' : '',
            staffId: blocks.staffId,
            id: blocks._id,
          };
          eventAllData.push(myEvent);
        } else {
          let myEvent = {
            title: blocks.blockname,
            start: blockstartDate,
            end: blockendDate,
            backgroundColor: '#7f7f7f',
            textColor: '#00000',
            type: 'blocktype',
            reapated: blocks.interval_type ? 'reapated' : '',
            staffId: blocks.staffId,
            id: blocks._id,
          };
          eventAllData.push(myEvent);
        }
      }
    }
  }
  if (appointments && appointments.length > 0) {
    for (let el of appointments) {
      const chemicalsArrayCopy = el.chemicalsArray;
      const { service, endType, summary } = el.dateData ? el.dateData[0] : {};
      if (service && service?.length > 0) {
        const removeCommaTotalCost = parseFloat(
          removeComma(summary?.totalCost),
        );
        const chemicalaTypeList = el.chemicalsArray[0]?.testResult[0]?.service.filter(
          (el2) => el2.type === 'product' && el2.status === 'quantity',
        );
        const { subtotalCost, subtotalDuration, subtotalLabour } = calculatChemicalCost(chemicalaTypeList);
        const totalduration = service.reduce(
          (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.duration ? next.duration : 0),
          0,
        );
        const totalTime = subtotalDuration + totalduration;

        // const allCostToRender = chemicalsArrayCopy && chemicalsArrayCopy.length > 0
        //   ? subtotalCost + removeCommaTotalCost
        //   : removeCommaTotalCost;

        const filtereduserList = el.repeats.filter(
          (name) => new Date(name.date) >= new Date(filterStart)
            && new Date(name.date) <= new Date(filterEnd)
            && name.status !== 'Cancelled'
            && name.status !== 'hidden',
        );
        // const time = service[0]?.startTime.split(':');
        // let hours = time[0];
        // const minutes = time[1];
       
        // let hours = parseInt(time[0], 10);
        // const minutes = parseInt(time[1], 10);

        for (let rept of filtereduserList) {
          // let start = moment(getTimeZone(timeZone, rept.date)).toDate();
          // let start2 = moment(getTimeZone(timeZone, rept.date)).format('YYYY-MM-DD');
          // let start = new Date(getTimeZone(timeZone, start2));
          // console.log('rept.date=>', { rept: rept.date, start, start2 });
          
          // let start = moment(getTimeZone(timeZone, rept.date)).toDate();
          // start = moment(start.setMinutes(start.getMinutes() + start.getTimezoneOffset())).toDate();
  
          // start.setHours(hours);
          // start.setMinutes(minutes);
          // start.setSeconds(0);
          // const startAppDate = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
          // const endAppDate = moment(
          //   new Date(
          //     start.setMinutes(start.getMinutes() + parseFloat(totalTime)),
          //   ),
          // ).format('YYYY-MM-DDTHH:mm:ssZ');

          const date = rept.date.split('T');
          const servEndIndex = service.length - 1;
          const startAppDate = service[0].start;
          const endAppDate = service[servEndIndex].end;
          const startTimeDate = startAppDate.split('T');
          const endTimeDate = endAppDate.split('T');
          const startdatetime = `${date[0]}T${startTimeDate[1]}`;
          const enddatetime = `${date[0]}T${endTimeDate[1]}`;

          // console.log('------>', { startdatetime, enddatetime });
          // console.log('endAppDate=>', endAppDate, startAppDate);

          let backgroundColor = rept.status === 'Completed' ? secondcolor : color;
          let textColor = rept.status && rept.status === 'Completed' ? '#887f6e' : '#000000';
          let titleArr = service[0]?.subcategory?.toLowerCase().includes('misc')
            ? service[0].customMisc.split('$')
            : service[0].subcategory.split('$');
          let eventTitle = titleArr[0].split(' ');

          const myEvent = {
            title: `${eventTitle ? eventTitle[0] : ''}`,
            subtitle: `${eventTitle[1] ? eventTitle[1] : ''}`,
            time: totalTime,
            start: startdatetime,
            end: enddatetime,
            id: rept.appointmentid,
            backgroundColor,
            textColor,
            // staffName: `${el?.client?.firstname} ${el?.client?.lastname}`,
            staffName: '',
            reapated: endType ? 'reapated' : '',
            status: rept.status,
            cost: '',
            servicename: titleArr[0],
            paymentStatus: el.paymentStatus || '',
            invoiceStatus: el.invoiceStatus || '',
            appPid: el.pid || '',
          };
          eventAllData.push(myEvent);
        }
      }
    }
  }
  return eventAllData;
};

export const calculateCapacity = (
  poolDepth,
  poolWidth,
  poolHeight,
  diameter,
  deepEnd,
  shallowEnd,
  isUsImperial,
) => {
  // console.log('isUsImperial', isUsImperial);
  let poolCapacity = 0;
  // console.log('cominginthissusiou', {
  //   poolDepth, poolWidth, poolHeight, diameter, deepEnd, shallowEnd,
  // });
  let poolMsg = '';
  if (poolDepth === 'Average Depth - Rectangular Pool') {
    const calValue = !isUsImperial ? 1250 : 5.5 * 7.48;
    poolCapacity = poolWidth * poolHeight * calValue;
    // console.log('asdfasd', poolCapacity);
  } else if (poolDepth === 'Average Depth - Circular pool') {
    const calValue = !isUsImperial ? 1250 * 0.78 : 5.5 * 7.48;
    poolCapacity = diameter * diameter * calValue;
  } else if (poolDepth === 'Specific Depths - Rectangular Pool') {
    const calValue = !isUsImperial ? 1000 : 5.5 * 7.48;
    const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
    // console.log({ shallowCalculation, calValue });
    poolCapacity = poolWidth * poolHeight * shallowCalculation * calValue;
    // console.log({ poolCapacity });
  } else if (poolDepth === 'Specific Depths - Circular Pool') {
    const calValue = !isUsImperial ? 780 : 5.5 * 7.48;
    const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
    poolCapacity = diameter * diameter * shallowCalculation * calValue;
  }

  poolCapacity = !isUsImperial
    ? Math.floor(parseInt(poolCapacity, 10) / 1000) * 1000
    : Math.round(parseInt(poolCapacity, 10) / 100) * 100;
  return poolCapacity;
};
