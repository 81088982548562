import React, { useState, useEffect, useRef } from 'react';
import {
  Form, Button, Modal, Nav, Tabs, Tab, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { TimePicker } from 'smart-webcomponents-react/timepicker';
import { useParams, useHistory } from 'react-router-dom';
import moment from '../../functions/moment';
import 'smart-webcomponents-react/source/styles/smart.default.css';
import toastMessage from '../../functions/toastMessage';
import { userInstance } from '../../config/axios';
import Loader from '../loader/loader';

const VideoViewPopUp = () => {
  const videoNameObj = {
    overview: 'Overview',
    calendar: 'Calendar',
    calculator: 'Calculator',
    // 'using-calculator': 'Using Calculator',
    'how-to-do-a-water-test': 'How to do a Water Test',
    'green-pool-treatment': 'Green Pool Treatment',
    'black-spot-algae-treatment': 'Black-Spot Algae Treatment',
    'drain-or-part-drain-pool': 'Drain or Part-drain Pool',
    'pool-water-volume-via-salinity': 'Pool Water Volume via Salinity',
    'water-loss-calculator': 'Water Loss Calculator',
    'inverter-heater---heating-time-required':
      'Inverter Heater - Heating Time Required',
    'pool-chemistry-inter-relationship': 'Pool Chemistry Inter-relationship',
    'permissions-&-access-level': 'Permissions & Access Level',
    users: 'Users',
    // 'pool-set-up': 'Pool Set-up',
    'my-pool-size-&-chlorination': 'My Pool Size & Chlorination',
    'chemistry-target-levels': 'Chemistry Target Levels',
    'calculator-chemicals-to-display': 'Calculator Chemicals to Display',
    'chemical-costs': 'Chemical Costs',
    'chlorinator-model': 'Chlorinator Model',
    'drainage-times': 'Drainage Times',
    'mineral-pool-settings': 'Mineral Pool Settings',
    'chemicals-used-to-balance-water': 'Chemicals Used to Balance Water',
    'green-pool-calculations': 'Green Pool Calculations',
    'black-spot-algae-calculations': 'Black-Spot Algae Calculations',
    'drain,-pressure-wash,-chlorine-&-acid-wash-calculations':
      'Drain, Pressure Wash, Chlorine & Acid Wash Calculations',
    'sand-filter-media-replacement': 'Sand Filter Media Replacement',
    'calendar-settings-&-unit-of-measurement':
      'Calendar Settings & Unit of Measurement',
    'water-test-history': 'Water Test History',
    inventory: 'Inventory',
    // reminders: 'Reminders',
    // reports: 'Reports',
    // 'my-account': 'My Account',
    // 'my-profile': 'My Profile',
    // 'calculator-pro-billing': 'Calculator-Pro Billing',
    // 'help-centre': 'Help Centre',
    // 'chat-bot': 'Chat Bot',
    // 'getting-started': 'Getting Started',
    // 'video-tutorials': 'Video Tutorials',
  };

  const videoLinks = [
    { title: 'Overview', url: 'https://youtu.be/xcUc9kHxk8Y' },
    { title: 'Calendar', url: 'https://youtu.be/nFcubUsCRSs' },
    { title: 'Calculator', url: 'https://youtu.be/x7tqYPbM-3c' },
    { title: 'How to do a Water Test', url: 'https://youtu.be/SOR3-yTyoPc' },
    { title: 'Green Pool Treatment', url: 'https://youtu.be/eq6WEXjEcMY' },
    { title: 'Black-Spot Algae Treatment', url: 'https://youtu.be/JRmaWNCOPQ8' },
    { title: 'Drain or Part-drain Pool', url: 'https://youtu.be/0guTIb_7EhY' },
    { title: 'Sand Filter Media Replacement', url: 'https://youtu.be/0oKkyUYx9i4' },
    { title: 'Pool Water Volume via Salinity', url: 'https://youtu.be/EP_W4Ns_aaw' },
    { title: 'Water Loss Calculator', url: 'https://youtu.be/KrOnK1tDg5E' },
    { title: 'Inverter Heater - Heating Time Required', url: 'https://youtu.be/c6_GIgNZN6k' },
    { title: 'Pool Chemistry Inter-relationship', url: '' },
    { title: 'Permissions & Access Level', url: 'https://youtu.be/LDaDJIrfqE8' },
    { title: 'Users', url: 'https://youtu.be/ePeY_ntyHXw' },
    { title: 'My Pool Size & Chlorination', url: 'https://youtu.be/KzLudgkrRD8' },
    { title: 'Chemistry Target Levels', url: 'https://youtu.be/d5JADoQ9Pl4' },
    { title: 'Calculator Chemicals to Display', url: 'https://youtu.be/ch0x4XK0l5M' },
    { title: 'Chemical Costs', url: 'https://youtu.be/TbOBJpHJujc' },
    { title: 'Chlorinator Model', url: 'https://youtu.be/PH4ezFHJcrI' },
    { title: 'Drainage Times', url: 'https://youtu.be/DjqKIyR6U_I' },
    { title: 'Mineral Pool Settings', url: 'https://youtu.be/q7n8Ehsy4Bw' },
    { title: 'Chemicals Used to Balance Water', url: 'https://youtu.be/O5Rt4B0qPpc' },
    { title: 'Green Pool Calculations', url: 'https://youtu.be/pkBvYjkbBlc' },
    { title: 'Black-Spot Algae Calculations', url: 'https://youtu.be/k5xAVz1Seu8' },
    { title: 'Drain, Pressure Wash, Chlorine & Acid Wash Calculations', url: 'https://youtu.be/iM5yKOA8fw0' },
    { title: 'Calendar Settings & Unit of Measurement', url: 'https://youtu.be/5mNNKe70Mfs' },
    { title: 'Water Test History', url: 'https://youtu.be/Zb-rkCQxjbU' },
    { title: 'Inventory', url: 'https://youtu.be/58z446N_kxQ' },
  ];

  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [videoSource, setVideoSource] = useState('');

  const handleViewVideo = async (categoryName) => {
    console.log('kasjdhsdf==>', categoryName);
    setLoading(true);
    try {
      // const response = await userInstance().get(
      //   '/user/getVideosByCategory?', {
      //     params: {
      //       category: categoryName,
      //     },
      //   },
      // );
      // console.log('response', response);
      let vedioCategory = videoLinks.find((item) => item.title === categoryName);
      console.log('vedioCategory');

      setLoading(false);
      if (vedioCategory) {
        console.log('vedioCategory.url', vedioCategory, 'vedioCategory.url==>', vedioCategory.url);
        setVideoSource(vedioCategory.url);
      } else {
        setVideoSource('');
      }

      // if (response?.data?.videoDetails?.videoUrl) {
      //   setVideoSource(response?.data?.videoDetails?.videoUrl);
      // } else {
      //   setVideoSource('');
      // }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleViewVideo(videoNameObj[id]);
    }
  }, [id]);

  const handleBack = () => {
    localStorage.setItem('popup', 'true');
    history.goBack();
  };

  return (
    <>
      <div className="duration-picker time-picker-modal video_uploadImg">
        <div>
          <div className="time-keeper-popup duration-popup">
            <div className="back" onClick={handleBack}>
              <i className="las la-times" />
            </div>
            <div className="video_heading">
              <h6>Video Tutorial</h6>
              <h4>{videoNameObj[id] || ''}</h4>
            </div>
            {loading ? (
              <div className="video-loader">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="video_upload viewUploadVedio">
                {videoSource !== '' ? (
                  <ReactPlayer width="100%" url={videoSource} controls />
                ) : (
                  <p>No Video Found</p>
                )}
              </div>
            )}
            <div className="template-btn view-btn single-btn">
              <button
                type="submit"
                className="btn btn-edit"
                onClick={handleBack}
              >
                {' '}
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoViewPopUp;
