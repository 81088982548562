/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'smart-webcomponents-react/timepicker';
import 'smart-webcomponents-react/source/styles/smart.default.css';
import {
  Form, Button, Modal, Nav, Tabs, Tab, Spinner, ProgressBar,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import toastMessage from '../../functions/toastMessage';
import { notTimeOutUserInstance, userInstance } from '../../config/axios';
import Loader from '../loader/loader';
import { getProgressbar, socket } from '../../socket';

const VideoUploadPopUp = ({
  videoData, setViewVideo, category, setVideoSource, videoSource, isLoading, setVideoTutorial,
}) => {
  const [progressbarPercentage, setProgressBarPercentage] = useState(-1);
  const [loading, setLoading] = useState('');
  const [file, setFile] = useState('');
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal video_uploadImg') {
            handleClose();
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRefVideo = useRef(null);
  useOutsideAlerter(wrapperRefVideo);

  // useEffect(() => {
  //   const response = getProgressbar((data) => {
  //     console.log('=>>>>>>', data);
  //     if (data) {
  //       setProgressBarPercentage(data?.percent);
  //     }
  //   });
  //   console.log('response', response);
  // }, []);

  const removePicture = async () => {
    // console.log('videoSource', videoSource);
    try {
      setLoading('remove');
      if (videoSource.includes('calendar-pro.s3.us-east-1.amazonaws.com/')) {
        let Key = videoSource.split('calendar-pro.s3.us-east-1.amazonaws.com/')[1];
        // console.log('key', Key);
        const response = await userInstance().put('/user/deleteVideo', {}, {
          params: {
            Key,
            category: videoData?.name,
          },
        });
        // console.log({ response });
      }
      setLoading('');
      setVideoSource('');
      setFile('');
      setProgressBarPercentage(-1);
    } catch (error) {
      // console.log('error in remove video', error);
      setLoading('');
    }
  };

  const handleChange = (e) => {
    var url = URL.createObjectURL(e.target.files[0]);
    setVideoSource(url);
    setFile(e.target.files[0]);
  };

  const handleUpdate = async () => {
    try {
      if (!file || videoSource.includes('calendar-pro.s3.us-east-1.amazonaws.com/')) return;
      const formData = new FormData();
      formData.append('file', file);
      setLoading('update');
      setProgressBarPercentage(0);
      const response = await notTimeOutUserInstance().post('/user/uploadVideo?', formData, {
        params: {
          category: videoData?.name,
        },
    
        onUploadProgress: (e) => {
          const percentage = Math.round((e.loaded * 100) / e.total);
          // console.log('progress', percentage);
        },
      });
      if (response?.status === 200) {
        setViewVideo(false);
        setVideoSource('');
        setProgressBarPercentage(-1);
        toastMessage('success', response?.data?.msg);
        setVideoTutorial(true);
      }

      // console.log({ response });
    } catch (error) {
      setLoading('');
      if (error?.response?.data) {
        const { msg } = error.response.data || {};
        toastMessage('error', msg);
      }
    }
  };

  const handleClose = () => {
    setViewVideo(false);
    setVideoSource('');
    setVideoTutorial(true);
    setProgressBarPercentage(-1);
  };

  useEffect(() => {
    socket.on('uploadProgressEvent', (progress) => {
      setProgressBarPercentage(progress);
    });
  }, []);

  // console.log('==>>>', isLoading);

  return (
    <>
      <div className="duration-picker time-picker-modal video_uploadImg">
        <div>
          <div
            className="time-keeper-popup duration-popup"
            ref={wrapperRefVideo}
          >
            <div className="back" onClick={() => handleClose()}>
              <i className="las la-times" />
            </div>
            <h4>
              {videoData?.name}
            </h4>
            <div className="field-location-section">
              <div className="preview-image mt-4">
                <h6>Preview Video</h6>
                {isLoading ? (
                  <div className="video-loader">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <div
                    className="video_upload"
                  >
                    {videoSource ? (
                      <video
                        width="100%"
                      // height="400px"
                        controls
                        src={videoSource}
                      />
                    ) : (
                      <>
                        <h6>Upload Video</h6>
                        
                      </>
                   
                    )}
                    
                  </div>
                )}
                {/* {console.log('progressBar', progressbarPercentage)} */}
                { progressbarPercentage >= 0 ? (
                  <div className="progress-section">
                    <ProgressBar
                      animated
                      now={progressbarPercentage}
                      // label={`${progressbarPercentage}%`}
                    />
                    <p className="text-end fs_10">
                      {`${progressbarPercentage}%`}
                    </p>
                  </div>
                ) : null}
                { isLoading ? ' ' : (
                  <div className="mt-4 d-none">
                    <input
                      type="file"
                      id="upload"
                      onChange={(e) => handleChange(e)}
                      accept=".mov,.mp4"
                    />
                  </div>
                )}
              </div>
              <div className="template-btn view-btn staff-form-btn staff-tmp-btn d-flex gap-1">

                {(!videoSource || videoSource?.includes('calendar-pro.s3.us-east-1.amazonaws.com/')) ? (
                  <Button className="delete-btn video_btn btn btn-edit" onClick={handleClose}>Cancel</Button>
                ) : (
                  <Button className="delete-btn video_btn btn  btn-delete" onClick={() => removePicture()}>{loading === 'remove' ? <Spinner animation="border" /> : 'Delete'}</Button>
                )}
                {videoSource
                  ? videoSource?.includes('calendar-pro.s3.us-east-1.amazonaws.com/')
                    ? <Button className="delete-btn video_btn btn  btn-delete" onClick={() => removePicture()}>{loading === 'remove' ? <Spinner animation="border" /> : 'Delete'}</Button> : (
                      <Button className="save-btn video_btn" onClick={handleUpdate}>
                        {loading === 'update' ? <Spinner animation="border" /> : ' Save/ Update'}

                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="save-template upload-logo video_btn  btn btn-save"
                      >
                        <Form.Group controlId="exampleForm.ControlTextarea1" className=" m-0 d-flex justify-content-center">
                         
                          <Form.Label htmlFor="upload" className="d-flex justify-content-center text-white">
                            Upload 
                          </Form.Label>
                        </Form.Group>
                      </Button>
                  )}

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
VideoUploadPopUp.propTypes = {
  videoData: PropTypes.objectOf.isRequired,
  setViewVideo: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setVideoSource: PropTypes.func.isRequired,
  videoSource: PropTypes.string.isRequired,
  setVideoTutorial: PropTypes.string.isRequired,
};
export default VideoUploadPopUp;
