import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import DateModal from '../appointment/dateModal';

const Event = ({
  setEventPopup, eventPopup, blockedtime, setBlockedtime,
  eventDateTime,
  activeStaff,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            setEventPopup(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const history = useHistory();

  const [addappt, setAddAppt] = useState('addappointment');
  const handleChange = (e) => {
    setAddAppt(e.target.value);
    localStorage.setItem('checkapptype', e.target.value);
  };
  const handleChange1 = (value) => {
    setAddAppt(value);
    localStorage.setItem('checkapptype', value);
  };
  const handleSelect = () => {
    let { staffId } = activeStaff || {};
    if (addappt === 'addappointment') {
      history.push(`/addappointment?startDateTime=${eventDateTime}&employee_id=${staffId}`);
    } else if (addappt === 'blockedtime' || addappt === 'personalappointment') {
      // console.log('blockedtime', blockedtime);
      // handleBlcokedtime();
      history.push(`/addappointment?startDateTime=${eventDateTime}&employee_id=${staffId}&blocktime=open`);
    } else if (addappt === 'jksdfsd') {
      // do
    }
  };
  const handleBlcokedtime = () => {
    setBlockedtime(!blockedtime);
  };
  const handleCancel = () => {
    setEventPopup(false);
  };
  return (
    <div
      className="duration-picker time-picker-modal"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup" ref={wrapperRef}>
          <div className="appointment-view">
            <div className="pending-water-test" onClick={() => handleChange1('addappointment')}>
              <h3>Schedule Pool Maintenance</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check type="checkbox" value="addappointment" onChange={handleChange} checked={addappt === 'addappointment'} />
                </div>
              </div>
            </div>
            <div className="pending-water-test" onClick={() => handleChange1('blockedtime')}>
              <h3>Set Blocked Time</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check type="checkbox" value="blockedtime" onChange={handleChange} checked={addappt === 'blockedtime'} />
                </div>
              </div>
            </div>
            <div className="pending-water-test" onClick={() => handleChange1('personalappointment')}>
              <h3>Create Personal Appointment</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check type="checkbox" value="personalappointment" onChange={handleChange} checked={addappt === 'personalappointment'} />
                </div>
              </div>
            </div>
            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={() => handleCancel()}>
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={() => handleSelect()}>
                Select
              </Button>
            </div>

          </div>

        </div>
      </div>
      {/* <DateModal blockedtime={blockedtime} setBlockedtime={setBlockedtime} /> */}
    </div>

  );
};
Event.propTypes = {
  eventPopup: PropTypes.func.isRequired,
  setEventPopup: PropTypes.func.isRequired,
  setBlockedtime: PropTypes.func.isRequired,
  blockedtime: PropTypes.func.isRequired,
  eventDateTime: PropTypes.func.isRequired,
  activeStaff: PropTypes.func.isRequired,
};
export default Event;
