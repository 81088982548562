import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import StartEndDate from './startEndDate';
import crosss from '../../assets/home/black-icon.png';
import search from '../../assets/home/search.png';

const SalesSummary = () => {
  const [dateRange, setDateRange] = useState();
  const [clients, setClients] = useState();
  const [datePopup, setDatePopup] = useState();
  const handleDateRange = (e) => {
    setDateRange(e.target.value);
  };
  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };
  const handleClients = (e) => {
    setClients(e.target.value);
  };
  return (

    <div className="appointment-summary">
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Service or Product Sales</Form.Label>
        <div className="select-box">
          <Form.Control as="select">
            <option value="Architect's Company">All Services & Products</option>
            <option value="Architect's Company">All Services</option>
            <option value="Architect's Company">All Products</option>
            <option value="Architect's Company">Specific Service</option>
            <option value="Architect's Company">Specific  Products</option>
          </Form.Control>
        </div>
      </Form.Group>
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Client</Form.Label>
        <div className="select-box">
          <Form.Control as="select" value={clients} onChange={handleClients}>
            <option value="All Clients">All Clients</option>
            <option value="Specific clients">Specific clients</option>
          </Form.Control>
        </div>
      </Form.Group>
      {clients === 'Specific clients' ? (
        <div className=" search-box search-text">
          <Form.Group className="search-box">
            <Form.Control type="text" placeholder="Search by name, mobile, phone or address" />
            <img src={search} alt="search" />
            <div className="remove-text">
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
        </div>
      ) : ''}
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Location</Form.Label>
        <div className="select-box">
          <Form.Control as="select">
            <option value="Architect's Company">All Locations</option>
          </Form.Control>
        </div>
      </Form.Group>
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Team Member</Form.Label>
        <div className="select-box">
          <Form.Control as="select">
            <option value="Architect's Company">All Team Members</option>
          </Form.Control>
        </div>
      </Form.Group>
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Date Range</Form.Label>
        <div className="select-box">
          <Form.Control as="select" value={dateRange} onChange={(e) => handleDateRange(e)}>
            <option value="Today">Today</option>
            <option value="Yesterday">Yesterday</option>
            <option value="Tomorrow">Tomorrow</option>
            <option value="This Week">This Week</option>
            <option value="This Month">This Month</option>
            <option value="This Year">This Year</option>
            <option value="Last Week">Last Week</option>
            <option value="Last Year">Last Year</option>
            <option value="Next Week">Next Week</option>
            <option value="Next Year">Next Year</option>
            <option value="Week to Date">Week to Date</option>
            <option value="Month to Date">Month to Date</option>
            <option value="Year to Date">Year to Date</option>
            <option value="Date Range">Date Range</option>
          </Form.Control>
        </div>
      </Form.Group>
      {dateRange === 'Date Range' ? (
        <div className="start-date-section">
          <Form.Group className="search-box">
            <div className="custom-datepicker" onClick={handleDatePopup}>Mon, 16 Aug 2021</div>
          </Form.Group>
          <span>to</span>
          <Form.Group className="search-box">
            <div className="custom-datepicker">Mon, 16 Aug 2021</div>
          </Form.Group>
        </div>
      ) : ''}

      <div className="appointment-summary-section">
        <p>Sales Summary</p>
        <p>Mon, 16 Aug 2021 - Mon, 18 Aug 2021</p>
        <hr />
        <div className="details-table">
          <div className="details-body">
            <h6>Service or Product sales</h6>
            <p>Viron P320 Variable Speed Pump</p>
          </div>
          <div className="details-body">
            <h6>Clients</h6>
            <p>All Clients</p>
          </div>
          <div className="details-body">
            <h6>Branch Location</h6>
            <p>12, Evansdale Road, Howthorn VIC 3122</p>
          </div>
          <div className="details-body">
            <h6>Team Member</h6>
            <p>Mano Gaurav</p>
          </div>
          <div className="details-body">
            <h6>Sales</h6>
            <p>215</p>
          </div>
          <div className="details-body">
            <h6>Average Labour Sell Price</h6>
            <p>$545.00</p>
          </div>
          <div className="details-body">
            <h6>Average Product Buy cost</h6>
            <p>$545.00</p>
          </div>
          <hr />
          <div className="details-body period-total">
            <h6>Period Sales Total</h6>
            <h6>$125.00</h6>
          </div>
          <div className="details-body period-total-other">
            <h6>Period Margin Total</h6>
            <h6>$125.00</h6>
          </div>
        </div>
      </div>
      <StartEndDate datePopup={datePopup} setDatePopup={setDatePopup} />
    </div>
  );
};
export default SalesSummary;
