/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import moment from '../../functions/moment';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';
import StartEndDate from './startEndDate';
import { handlePress } from '../../functions';
import toastMessage from '../../functions/toastMessage';
import {
  billing_users, billing_pools, billing_data,
  billing_rules,
  durationCheck,
  poolCheck,
  userCheck,
  checkRemainTime,
} from './utils';

function SubscriptionForm(props) {
  const {
    handleChange,
    handleDateState,
    clientData,
    copyClientData,
    setClientData,
    handleAddCustomer,
    showForm,
    handleFormModal,
    editClient,
    selectedClient,
    selectedCountry,
    error,
    countries,
    stateArray,
    mobileFormat,
    handleSubscription,
    setBooking,
  } = props;

  const validateMobileNo = (inputObj) => {
    const { value } = inputObj;
    if (value) {
      if (value.trim().charAt(0) === '6' && value.trim().charAt(1) === '1' || (value.trim().charAt(0) === '0') || (value.trim().charAt(0) === '+')) {
        toastMessage('error', `Please do not enter ${value.trim().charAt(0) === '0' || value.trim().charAt(0) === '+' ? value.charAt(0).trim() : '61'}  at start of mobile no`);
        return '';
      }
      return inputObj;
    }
    return inputObj;
  };

  let options = [];

  for (let i = 2; i < 101; i += 1) {
    options.push(i);
  }
  const [datePopup, setDatePopup] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleDateOnchange = (value, type) => {
    setDateRange({ ...dateRange, [type]: new Date(value) });
  };

  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };

  const handleDateRange = (start, end) => {
    handleDateState(start, end);
    setDatePopup(false);
  };
  // submit Form function for add and edit
  const handleFormFunction = (e) => {
    setDateRange({ start: new Date(), end: new Date() });
    if (selectedClient) {
      editClient(e);
    } else {
      // ading a customer for the first time
      handleAddCustomer(e);
    }
  };
  // this function will handle all Add Subscription Cases
  function billing_add_summary(durationTime, poolNum, userNum, countryName) {
    const userCost = 5;
    const poolCost = 5;
    let extraUserCost = 0;
    let extraPoolCost = 0;
    let finalCost = 0;
   
    const billingDetails = billing_rules[countryName || clientData.country].find((el) => el.duration === Number(durationTime));
    const {
      includedUsers,
      includedPools,
      cost,
    } = billingDetails;

    console.log('billingDetails==>', billingDetails);

    const extraUsers = Number(userNum) - includedUsers;
    const extraPools = Number(poolNum) - includedPools;

    if (extraUsers > 0) {
      extraUserCost = extraUsers * userCost * Number(durationTime);
    }

    if (extraPools > 0) {
      extraPoolCost = extraPools * poolCost * Number(durationTime);
    }

    finalCost = cost + extraPoolCost + extraUserCost;

    if (Number(durationTime) === 0) {
      setClientData((prev) => ({
        ...prev, duration: 0, totalCost: finalCost, subscriptionStatus: 'trial', numberOfPools: 0, numberUsers: 0,
      }));
    } else {
      let numberOfPools = Number(poolNum);
      let numberUsers = Number(userNum);
      if (Number(poolNum) === 0) {
        numberOfPools = 1;
      }

      if (Number(userNum) === 0) {
        numberUsers = 2;
      }

      setClientData((prev) => ({
        ...prev, duration: Number(durationTime), totalCost: finalCost, subscriptionStatus: 'paid', numberOfPools, numberUsers,
      }));
    }
  }

  // this function will handle all Edit Subscription Cases
  function billing_edit_summary(durationTime, poolNum, userNum) {
    // Guard Pattern
    // check if duartion has been downgraded
    const checkDuration = durationCheck(Number(durationTime), copyClientData.duration, clientData.endsubscriptionDate);
    if (!checkDuration) {
      return;
    }
    // check if Number of Pools has been downgraded
    const checkPools = poolCheck(Number(poolNum), copyClientData.numberOfPools, clientData.endsubscriptionDate);
    if (!checkPools) {
      return;
    }
    // check if Number of Users has been downgraded
    const checkUsers = userCheck(Number(userNum), copyClientData.numberUsers, clientData.endsubscriptionDate);
    if (!checkUsers) {
      return;
    }

    const userCost = 5;
    const poolCost = 5;
    let extraUserCost = 0;
    let extraPoolCost = 0;
    let finalCost = 0;
    const billingDetails = billing_rules[clientData.country].find((el) => el.duration === Number(durationTime));
    const {
      includedUsers,
      includedPools,
      cost,
    } = billingDetails;

    const extraUsers = Number(userNum) - includedUsers;
    const extraPools = Number(poolNum) - includedPools;
    // check the remaining time left for the subscription to end and add in accordance to number of months left
    const timeRemain = checkRemainTime(clientData.endsubscriptionDate);

    if (extraUsers > 0) {
      extraUserCost = extraUsers * userCost * timeRemain;
    }

    if (extraPools > 0) {
      extraPoolCost = extraPools * poolCost * timeRemain;
    }

    finalCost = cost + extraPoolCost + extraUserCost;
    // check if the subscription if of trial basis using this if condition
    if (Number(durationTime) === 0) {
      setClientData((prev) => ({
        ...prev, duration: 0, totalCost: finalCost, subscriptionStatus: 'trial', numberOfPools: 0, numberUsers: 0,
      }));
    } else {
      // this else condition is for paid subscribers
      let numberOfPools = Number(poolNum);
      let numberUsers = Number(userNum);
      if (Number(poolNum) === 0) {
        numberOfPools = 1;
      }

      if (Number(userNum) === 0) {
        numberUsers = 2;
      }
      // calculate this extra cost and if the extra cost will be greater than Zero then open the payment form
      const extraCost = parseFloat(finalCost) - parseFloat(copyClientData.totalCost);
      setClientData((prev) => ({
        ...prev, duration: Number(durationTime), totalCost: finalCost, subscriptionStatus: 'paid', numberOfPools, numberUsers, extraCost,
      }));
    }
  }

  const handleChangeSubscription = (type, e) => {
    // this if condition will take all necesary cases in editing case while all the else-if will work for the Add cases
    // selected client is a state which holds the mongoID of the subscriber in Edit case
    if (selectedClient) {
      // for edit all cases starts
      if (type === 'duration') {
        billing_edit_summary(e.target.value, clientData.numberOfPools, clientData.numberUsers);
      } else if (type === 'pool') {
        billing_edit_summary(clientData.duration, e.target.value, clientData.numberUsers);
      } else if (type === 'user') {
        billing_edit_summary(clientData.duration, clientData.numberOfPools, e.target.value);
      }
      // for edit all cases ends
    } else if (type === 'duration') {
      billing_add_summary(e.target.value, clientData.numberOfPools, clientData.numberUsers);
    } else if (type === 'pool') {
      billing_add_summary(clientData.duration, e.target.value, clientData.numberUsers);
    } else if (type === 'user') {
      billing_add_summary(clientData.duration, clientData.numberOfPools, e.target.value);
    }
  };

  const handleCountryChange = (e) => {
    handleChange(e);
    if (selectedClient) {
      billing_edit_summary(clientData.duration, clientData.numberOfPools, clientData.numberUsers, e.target.value);
    } else {
      billing_add_summary(clientData.duration, clientData.numberOfPools, clientData.numberUsers, e.target.value);
    }
  };
  // console.log('these are the clientData', clientData);
  return (
    <Modal
      size="lg"
      show={showForm}
      onHide={!showForm}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal subsciption-popup"
    >
      <div className="staff-close">
        <i
          className="fa fa-times"
          onClick={handleFormModal}
          aria-hidden="true"
        />
      </div>
      <div className="adduser-heading">
        <h2 className="signup-popup-heading">Sign-up Now for PoolWater-Pro</h2>
        <p className="modal-description">
          Choose from four easy access options.
          {' '}
          <br />
          All choices have identical features, the
          {' '}
          <br />
          only difference is their duration period.
        </p>
        {billing_data[clientData.country].map((item) => (
          <>
             
            <p className="desc-head">{item.Option}</p>
            <p>
              {/* 10 Day Free Trial
              <span style={{ color: 'red' }}>&nbsp;*</span> */}
              {item.description}
            </p>
          </>

        )) }
        {/* <p className="desc-head">Option 1</p>
        <p>
          10 Day Free Trial
          <span style={{ color: 'red' }}>&nbsp;*</span>
        </p> */}
       
        <p className="extra-comment">* Subscription automatically updates to Option 2 if not cancelled prior to the end of trial period.</p>
      </div>

      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Subscribers Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          <Form className="desktop-view-form">

            <div className="row">
              <div className="col-md-12">
                {/* <Form.Group controlId="formBasicCompanyType">
                  <Form.Label>
                    {`${clientData.customerType !== 'Householder' ? 'Billing ' : ''}`}
                    Contact
                  </Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientData.customerType}
                      name="customerType"
                      onChange={handleChange}
                    >
                      <option value="Architect's Company">Architect</option>
                      <option value="Builder's Company">Builder</option>
                      <option value="Company">Company</option>
                      <option value="Consultant's Company">Consultant</option>
                      <option value="Householder">Householder</option>
                      <option value="Insurance Company">Insurance Company</option>
                      <option value="Landlord">Landlord</option>
                      <option value="Real Estate Agent">Real Estate Agent</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                  </div>
                </Form.Group> */}
                {/* {clientData.customerType !== 'Householder' ? (
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group controlId="formBasicInput-1">
                        <Form.Label>
                          {clientData.customerType === 'Other'
                            ? 'Specific Name for Customer Type'
                            : `${clientData.customerType} Name`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="companyName"
                          onChange={handleChange}
                          value={clientData.companyName}
                          className={`${
                            error === `${clientData?.customerType?.toLowerCase()?.split(' ').join('')}name`
                              ? 'error-text'
                              : ''
                          }`}
                        />
                      </Form.Group>
                    </div>
                  </div>
                ) : null} */}
                <Form.Group controlId="formBasicInput-9" className="italic-text">
                  <Form.Label>Subscription Duration</Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      name="duration"
                      onChange={(e) => handleChangeSubscription('duration', e)}
                      value={clientData.duration}
                      className={`${
                        error === 'subscriptionduration'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      <option value="">Select</option>
                      {billing_rules[clientData.country].map((el) => <option value={el.duration}>{el.description}</option>)}

                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            {clientData?.subscriptionStatus === 'trial' ? null : (
              <>
                <div className="row">
                  <div className="col-md-12 detailed-data">
                    <Form.Group controlId="formBasicLocation">
                      <Form.Label>
                        No. of Pools
                      </Form.Label>
                      <div className="select-box">
                        <Form.Control
                          as="select"
                          value={clientData.numberOfPools}
                          name="numberOfPools"
                          onChange={(e) => handleChangeSubscription('pool', e)}
                          className={`${
                            error === 'numberofpools'
                              ? 'error-text'
                              : ''
                          }`}
                        >
                          <option value="">Select</option>
                          {billing_pools[clientData.country].map((el) => (
                            <option value={el.num}>
                              {el.description}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 detailed-data">
                    <Form.Group controlId="formBasicLocation">
                      <Form.Label>
                        No. of Users
                      </Form.Label>
                      <div className="select-box">
                        <Form.Control
                          as="select"
                          value={clientData.numberUsers}
                          name="numberUsers"
                          onChange={(e) => handleChangeSubscription('user', e)}
                          className={`${
                            error === 'numberofuser'
                              ? 'error-text'
                              : ''
                          }`}
                        >
                          <option value="">Select</option>
                          {billing_users[clientData.country].map((el) => (
                            <option value={el.num}>
                              {el.description}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </>
            ) }

            <div className="row">
              <h6>{`Total Cost : ${clientData.country === 'Australia' ? 'AU' : 'US'}$${clientData?.totalCost?.toFixed(2)}`}</h6>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-9" className="italic-text">
                  <Form.Label>
                    {clientData.customerType !== 'Householder' ? 'Billing ' : ''}
                    Country

                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      name="country"
                      onChange={handleCountryChange}
                      value={clientData.country}
                      className={`${
                        error === 'country'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      {countries?.length > 0 && countries.map((el) => <option value={el.name}>{el.name}</option>)}

                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  controlId="formBasicInput-1"
                  className="italic-text"
                >
                  <Form.Label>
                    {clientData.customerType !== 'Householder'
                      ? 'Main '
                      : ''}
                    Contact's First Name

                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="firstname"
                    value={clientData.firstname}
                    onKeyPress={handlePress}
                    placeholder="Enter First Name"
                    className={`${error === 'firstname' ? 'error-text' : ''}`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  controlId="formBasicInput-2"
                  className="italic-text"
                >
                  <Form.Label>
                    {clientData.customerType !== 'Householder'
                      ? 'Main '
                      : ''}
                    Contact's Last Name

                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="lastname"
                    value={clientData.lastname}
                    onKeyPress={handlePress}
                    placeholder="Enter Last Name"
                    className={`${error === 'lastname' ? 'error-text' : ''}`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  controlId="formBasicInput-3"
                  className="number italic-text"
                >
                  <Form.Label>
                    {clientData.customerType !== 'Householder'
                      ? 'Main '
                      : ''}
                    Contact's Mobile No.

                  </Form.Label>
                  <div className="number-field">
                    <div className="number-box">
                      <Dropdown id="mobile">
                        <Dropdown.Toggle variant="success" id="dropdown-basic1">
                          <div className="flag-icon">
                            <img
                              src={selectedCountry === '+61' ? flag : us}
                              alt="flag"
                            />
                          </div>
                          {selectedCountry === '+61' ? (
                            <span>
                              Australia
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === '+1' ? (
                            <span>
                              United States
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : (
                            ''
                          )}

                        </Dropdown.Toggle>

                      </Dropdown>
                    </div>
                    <NumberFormat
                      className={`form-control mobile-num ${
                        error === 'mobileno' ? 'error-text' : ''
                      }`}
                      format={mobileFormat}
                      onChange={handleChange}
                      name="mobileNo"
                      value={clientData.mobileNo}
                      placeholder="Enter Mobile Number"
                      autocomplete="off"
                      isAllowed={validateMobileNo}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-34" className="number">
                  <Form.Label>
                    {clientData.customerType !== 'Householder'
                      ? 'Main '
                      : ''}
                    Contact's Landline No.
                  </Form.Label>
                  <div className="number-field">
                    <div className="number-box">
                      <Dropdown id="landline">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="flag-icon">
                            <img
                              src={selectedCountry === '+61' ? flag : us}
                              alt="flag"
                            />
                          </div>
                          {selectedCountry === '+61' ? (
                            <span>
                              Australia
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === '+1' ? (
                            <span>
                              United States
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : (
                            ''
                          )}

                        </Dropdown.Toggle>

                      </Dropdown>
                    </div>
                    <NumberFormat
                      className={`form-control mobile-num ${
                        error === 'landlineno' ? 'error-text' : ''
                      }`}
                      format="# #### ####"
                      onChange={handleChange}
                      name="landlineNo"
                      value={clientData.landlineNo}
                      autocomplete="off"
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  controlId="formBasicInput-5"
                  className="italic-text"
                >
                  <Form.Label>
                    {clientData.customerType !== 'Householder'
                      ? 'Main '
                      : ''}
                    Contact's Email Address

                  </Form.Label>
                  <Form.Control
                    type="email"
                    onChange={handleChange}
                    name="email"
                    value={clientData.email}
                    placeholder="Enter Email Address"
                    className={`${error === 'email' ? 'error-text' : ''}`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  controlId="formBasicInput-6"
                  className="italic-text"
                >
                  <Form.Label>
                    {clientData.customerType !== 'Householder'
                      ? 'Billing '
                      : ''}
                    Street Address

                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="streetAddress"
                    value={clientData.streetAddress}
                    placeholder="Enter Street Address"
                    className={`${
                      error === 'streetaddress' ? 'error-text' : ''
                    }`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  controlId="formBasicInput-7"
                  className="italic-text"
                >
                  <Form.Label>
                    {clientData.customerType !== 'Householder'
                      ? 'Billing '
                      : ''}
                    City/Town/Suburb

                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="suburb"
                    placeholder="Enter City/Town/Suburb"
                    value={clientData.suburb}
                    className={`${error === 'suburb' ? 'error-text' : ''}`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-8" className="italic-text">

                  <Form.Label>
                    {clientData.customerType !== 'Householder'
                      ? 'Billing '
                      : ''}
                    Postal / Zip Code
                  </Form.Label>
                  <Form.Control
                    type="number"
                    onChange={handleChange}
                    name="postcode"
                    value={clientData.postcode}
                    placeholder="Enter Postal/Zip Code"
                    className={`${error === 'postal/zipcode' ? 'error-text' : ''}`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  controlId="formBasicInput-9"
                  className="italic-text"
                >
                  <Form.Label>
                    {clientData.customerType !== 'Householder'
                      ? 'Billing '
                      : ''}
                    State
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="state"
                      value={clientData.state}
                      className={`${error === 'state' ? 'error-text' : ''}`}
                    >
                      <option value="">Select</option>
                      {stateArray?.length > 0 && stateArray.map((el) => (
                        <option value={el.name}>
                          {el.isoCode}
                          {' '}
                          -
                          {' '}
                          {el.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Automaic Invoice Sending</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientData.invoiceSendType}
                      name="invoiceSendType"
                      onChange={handleChange}
                    >
                      {/* <option value="Select">Select</option> */}
                      <option value="Send Invoices by SMS">Send Invoices by SMS</option>
                      <option value="Send Invoices by Email"> Send Invoices by Email</option>
                      <option value="Send Invoices by SMS & Email"> Send Invoices by SMS & Email</option>
                      <option value="Don't Automatically send">Don't Automatically send</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Subscription Type</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientData.subscriptionType}
                      name="subscriptionType"
                      onChange={handleSubscription}
                    >
                      <option value="Basic Plan">Basic Plan</option>
                      <option value="Standard Plan"> Standard Plan</option>
                      <option value="Premium Plan">Premium Plan</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Billing Cycle</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientData.billingCycle}
                      name="billingCycle"
                      onChange={handleChange}
                    >
                      <option value="1 Year">1 Year</option>
                      <option value="2 Years">2 Years</option>
                      <option value="3 Years">3 Years</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col-md-12 subsciption-period">
                <Form.Label>Subscription Period</Form.Label>
                <div className="appointment-summary">
                  <div className="start-date-section">
                    <Form.Group className="search-box">
                      <div className="custom-datepicker" onClick={handleDatePopup}>
                        {`${moment(clientData.rangeStartDate).format('ddd DD MMM yyyy')}`}
                      </div>
                    </Form.Group>
                    <span>to</span>
                    <Form.Group className="search-box">
                      <div className="custom-datepicker">
                        {' '}
                        {`${moment(clientData.rangeEndDate).format('ddd DD MMM yyyy')}`}
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <Form.Group controlId="formBasicLocation">
              <Form.Label>Cost</Form.Label>
              <div className="select-box">

                <Form.Control
                  as="select"
                  value={clientData.costDropDown}
                  name="costDropDown"
                  onChange={handleChange}
                >
                  <option value="per annum">
                    $
                    {clientData.annnumCost}
                    {' '}
                    per annum
                  </option>
                  <option value="per month">
                    $
                    {clientData.monthlyCost}
                    {' '}
                    per month
                  </option>
                </Form.Control>
              </div>
            </Form.Group> */}
            {/* <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-9">
                  <Form.Label>
                    Payment Status
                  </Form.Label>
                  <Form.Control type="text" disabled name="paymentStatus" value={clientData.paymentStatus} />
                </Form.Group>
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Apply New Payment</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientData.applyNewPayment}
                      name="applyNewPayment"
                      onChange={handleChange}
                    >
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Payment Status</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientData.status}
                      name="status"
                      onChange={handleChange}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div> */}

            <div className="col-md-12">
              <Form.Group className="form-radio" onChange={handleChange} name="gender">
                <Form.Check
                  type="radio"
                  label="Male"
                  name="gender"
                  id="male"
                  checked={clientData.gender === 'male'}
                />
                <Form.Check
                  type="radio"
                  label="Female"
                  name="gender"
                  id="female"
                  checked={clientData.gender === 'female'}
                />
              </Form.Group>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <div className="client-notes">
                    <Form.Label>Client Notes</Form.Label>
                    <div className="basic">
                      Show on all bookings
                      <Form.Check type="checkbox" onChange={setBooking} checked={clientData.showBookings} />
                    </div>
                  </div>
                  <Form.Control as="textarea" rows={3} onChange={handleChange} name="note" value={clientData.note} />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <Form.Group className="form-radio" onChange={handleChange}>
                <Form.Check
                  type="checkbox"
                  name="agreeStatus"
                  checked={clientData?.agreeStatus}
                  onChange={handleChange}
                  className={`${error === 'agreeStatus' ? 'error-text' : ''}`}
                />
                <Form.Label>
                  By continuing you agree to be bound by our Terms & Conditions of Use
                  {' '}
                  <span>view here.</span>
                </Form.Label>
              </Form.Group>
            </div>

            <div className="row item-test">
              <div className="col-md-12">
                <div className="view-btn">
                  <Button
                    type="button"
                    className="btn btn-edit"
                    onClick={(e) => {
                      e.preventDefault();
                      setDateRange({ start: new Date(), end: new Date() });
                      handleFormModal();
                    }}
                  >
                    {' '}
                    Cancel
                    {' '}
                  </Button>
                  <Button
                    type="Submit"
                    className="btn btn-save"
                    onClick={handleFormFunction}
                  >
                    {' '}
                    Next
                    {' '}
                  </Button>
                </div>
              </div>
            </div>

          </Form>
          <StartEndDate
            datePopup={datePopup}
            setDatePopup={setDatePopup}
            handleDatePopup={handleDatePopup}
            handleDateOnchange={handleDateOnchange}
            dateRange={dateRange}
            handleDateRange={handleDateRange}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

SubscriptionForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  clientData: PropTypes.func.isRequired,
  copyClientData: PropTypes.func.isRequired,
  setClientData: PropTypes.func.isRequired,
  handleAddCustomer: PropTypes.func.isRequired,
  showForm: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  selectedClient: PropTypes.func.isRequired,
  editClient: PropTypes.func.isRequired,
  handleDateState: PropTypes.func.isRequired,
  selectedCountry: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf.isRequired,
  stateArray: PropTypes.arrayOf.isRequired,
  mobileFormat: PropTypes.string.isRequired,
  handleSubscription: PropTypes.func.isRequired,
  setBooking: PropTypes.func.isRequired,
};

export default SubscriptionForm;
