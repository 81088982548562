import openSocket from 'socket.io-client';
import { server } from '../config/keys';

// Constants for local storage keys
const USER_TOKEN_KEY = 's#@4f';
const STAFF_TOKEN_KEY = 'f#@5e';
const LOGIN_STAFF_KEY = 'loginStaff';

// Initialize socket connection
const socket = openSocket(server, {
  transports: ['websocket'],
  rejectUnauthorized: false,
  query: {
    usertoken: localStorage.getItem(USER_TOKEN_KEY),
    stafftoken: localStorage.getItem(STAFF_TOKEN_KEY),
  },
});

// Function to handle join event
const handleJoin = () => {
  const userid = localStorage.getItem(USER_TOKEN_KEY);
  const isLogin = localStorage.getItem(STAFF_TOKEN_KEY);
  const isLoginId = localStorage.getItem(LOGIN_STAFF_KEY);

  if (isLogin && userid) {
    socket.emit('join', isLoginId);
  } else {
    console.error('Missing login information');
  }
};

// Event listeners
socket.on('connect', handleJoin);
socket.on('reconnect', () => {
  console.log('Reconnected');
  handleJoin();
});

// Functions to handle specific socket events
const GetNotification = (cb) => {
  socket.on('GetNotification', (data) => {
    cb(data);
  });
};

const getProgressbar = (cb) => {
  socket.on('progressBar', (data) => {
    cb(data);
  });
};

export { socket, GetNotification, getProgressbar };
