const timeZone = [
  { utc: 'Etc/GMT+12', text: '(UTC -12:00) International Dateline West' },
  { utc: 'Etc/GMT+11', text: '(UTC -11:00) Co-ordinated Universal Time-11' },
  { utc: 'Etc/GMT+11', text: '(UTC -11:00) Samoa (US)' },
  { utc: 'Etc/GMT+10', text: '(UTC -10:00) Hawaii (US)' },
  { utc: 'America/Anchorage', text: '(UTC -09:00) Alaska (US)' },
  { utc: 'America/Santa_Isabel', text: '(UTC -08:00) Baja California' },
  { utc: 'America/Santa_Isabel', text: '(UTC -08:00) Pacific Time (Canada)' },
  { utc: 'America/Santa_Isabel', text: '(UTC -08:00) Pacific Time (US)' },
  { utc: 'America/Los_Angeles', text: '(UTC -07:00) Arizona (US)' },
  { utc: 'America/Los_Angeles', text: '(UTC -07:00) Chihuahua' },
  { utc: 'America/Los_Angeles', text: '(UTC -07:00) La Paz (Mexico)' },
  { utc: 'America/Los_Angeles', text: '(UTC -07:00) Mazatlan' },
  { utc: 'America/Los_Angeles', text: '(UTC -07:00) Mountain Time (Canada)' },
  { utc: 'America/Los_Angeles', text: '(UTC -07:00) Mountain Time (US)' },
  { utc: 'America/Chicago', text: '(UTC -06:00) Central Time (Canada)' },
  { utc: 'America/Chicago', text: '(UTC -06:00) Central America (US)' },
  { utc: 'America/Chicago', text: '(UTC -06:00) Central Time (US)' },
  { utc: 'America/Chicago', text: '(UTC -06:00) Guadalajara' },
  { utc: 'America/Mexico_City', text: '(UTC -05:00) Mexico City' },
  { utc: 'America/Chicago', text: '(UTC -06:00) Monterrey' },
  { utc: 'America/Chicago', text: '(UTC -06:00) Saskatchewan' },
  { utc: 'America/Bogota', text: '(UTC -05:00) Bogota' },
  { utc: 'America/Bogota', text: '(UTC -05:00) Lima' },
  { utc: 'America/Bogota', text: '(UTC -05:00) Quito' },
  { utc: 'America/Bogota', text: '(UTC -05:00) Eastern Time (Canada)' },
  { utc: 'America/Bogota', text: '(UTC -05:00) Eastern Time (US)' },
  { utc: 'America/Bogota', text: '(UTC -05:00) Indiana (East)' },
  { utc: 'America/Caracas', text: '(UTC -04:30) Caracas' },
  { utc: 'America/Asuncion', text: '(UTC -04:00) Asuncion' },
  { utc: 'America/Asuncion', text: '(UTC -04:00) Atlantic Time (Canada)' },
  { utc: 'America/Asuncion', text: '(UTC -04:00) Cuiaba' },
  { utc: 'America/Asuncion', text: '(UTC -04:00) Georgetown' },
  { utc: 'America/Asuncion', text: '(UTC -04:00) La Paz (Bolivia)' },
  { utc: 'America/Asuncion', text: '(UTC -04:00) Manaus' },
  { utc: 'America/Asuncion', text: '(UTC -04:00) San Juan' },
  { utc: 'America/Asuncion', text: '(UTC -04:00) Santiago' },
  { utc: 'America/St_Johns', text: '(UTC -03:30) Newfoundland' },
  { utc: 'America/Buenos_Aires', text: '(UTC -03:00) Brasil' },
  { utc: 'America/Buenos_Aires', text: '(UTC -03:00) Buenos Aires' },
  { utc: 'America/Buenos_Aires', text: '(UTC -03:00) Cayenne' },
  { utc: 'America/Buenos_Aires', text: '(UTC -03:00) Fortaleza' },
  { utc: 'America/Buenos_Aires', text: '(UTC -03:00) Greenland' },
  { utc: 'America/Buenos_Aires', text: '(UTC -03:00) Montevideo' },
  { utc: 'Etc/GMT+2', text: '(UTC -02:00) Co-ordinated Universal Time-02' },
  { utc: 'Etc/GMT+2', text: '(UTC -02:00) Mid-Atlantic' },
  { utc: 'Etc/GMT+1', text: '(UTC -01:00) Azores' },
  { utc: 'Etc/GMT+1', text: '(UTC -01:00) Cape Verde Islands' },
  { utc: 'Europe/Dublin', text: '(UTC -00:00) Casablanca' },
  { utc: 'Europe/Dublin', text: '(UTC -00:00) Co-ordinated Universal Time' },
  { utc: 'Europe/Dublin', text: '(UTC -00:00) Dublin' },
  { utc: 'Europe/Dublin', text: '(UTC -00:00) Edinburgh' },
  { utc: 'Europe/Dublin', text: '(UTC -00:00) Lisbon' },
  { utc: 'Europe/Dublin', text: '(UTC -00:00) London' },
  { utc: 'Europe/Dublin', text: '(UTC -00:00) Monrovia' },
  { utc: 'Europe/Dublin', text: '(UTC -00:00) Reykjavik' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Amsterdam' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Belgrade' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Berlin' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Bern' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Bratislava' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Brussels' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Budapest' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Copenhagen' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Ljubljana' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Madrid' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Paris' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Prague' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Rome' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Sarajevo' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Skopje' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Stockholm' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Vienna' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Warsaw' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) West Central Africa' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Windhoek' },
  { utc: 'Europe/Berlin', text: '(UTC +01:00) Zagreb' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Amman' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Athens' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Bucharest' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Beirut' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Cairo' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Damascus' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Harare' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Helsinki' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Istanbul' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Jerusalem' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Kiev' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Minsk' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Pretoria' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Rig' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Sofia' },
  { utc: 'Etc/GMT-2', text: '(UTC +02:00) Tallinn' },
  { utc: 'Etc/GMT-3', text: '(UTC +03:00) Baghdad' },
  { utc: 'Etc/GMT-3', text: '(UTC +03:00) Kuwait' },
  { utc: 'Etc/GMT-3', text: '(UTC +03:00) Moscow' },
  { utc: 'Etc/GMT-3', text: '(UTC +03:00) St Petersburg' },
  { utc: 'Etc/GMT-3', text: '(UTC +03:00) Volgograd' },
  { utc: 'Etc/GMT-3', text: '(UTC +03:00) Nairobi' },
  { utc: 'Etc/GMT-3', text: '(UTC +03:00) Riyadh' },
  { utc: 'Asia/Tehran', text: '(UTC +03:30) Tehran' },
  { utc: 'Etc/GMT-4', text: '(UTC +04:00) Abu Dhabi' },
  { utc: 'Etc/GMT-4', text: '(UTC +04:00) Baku' },
  { utc: 'Etc/GMT-4', text: '(UTC +04:00) Muscat' },
  { utc: 'Etc/GMT-3', text: '(UTC +03:00) Port Louis' },
  { utc: 'Etc/GMT-4', text: '(UTC +04:00) Tbilisi' },
  { utc: 'Etc/GMT-4', text: '(UTC +04:00) Yerevan' },
  { utc: 'Asia/Kabul', text: '(UTC +04:30) Kabul' },
  { utc: 'Etc/GMT-5', text: '(UTC +05:00) Ekaterinburg' },
  { utc: 'Etc/GMT-5', text: '(UTC +05:00) Islamabad' },
  { utc: 'Etc/GMT-5', text: 'UTC +05:00) Karachi' },
  { utc: 'Etc/GMT-5', text: '(UTC +05:00) Tashkent' },
  { utc: 'Asia/Kolkata', text: '(UTC +05:30) Chennai' },
  { utc: 'Asia/Kolkata', text: '(UTC +05:30) Kolkata' },
  { utc: 'Asia/Kolkata', text: '(UTC +05:30) Mumbai' },
  { utc: 'Asia/Kolkata', text: '(UTC +05:30) New Delhi' },
  { utc: 'Asia/Kolkata', text: '(UTC +05:30) Sri Jayawardenepura' },
  { utc: 'Asia/Kathmandu', text: '(UTC +05:45) Kathmandu' },
  { utc: 'Etc/GMT-6', text: '(UTC +06:00) Astana' },
  { utc: 'Etc/GMT-6', text: '(UTC +06:00) Dhaka' },
  { utc: 'Etc/GMT-6', text: '(UTC +06:00) Novosibirsk' },
  { utc: 'Asia/Rangoon', text: '(UTC +06:30) Yangon (Rangoon)' },
  { utc: 'Etc/GMT-7', text: '(UTC +07:00) Bangkok' },
  { utc: 'Etc/GMT-7', text: '(UTC +07:00) Hanoi' },
  { utc: 'Etc/GMT-7', text: '(UTC +07:00) Krasnoyarsk' },
  { utc: 'Etc/GMT-7', text: '(UTC +07:00) Jakarta' },
  { utc: 'Etc/GMT-8', text: '(UTC +08:00) Beijing' },
  { utc: 'Etc/GMT-8', text: '(UTC +08:00) Chongqing' },
  { utc: 'Etc/GMT-8', text: '(UTC +08:00) Hong Kong' },
  { utc: 'Etc/GMT-8', text: '(UTC +08:00) Urumqi' },
  { utc: 'Etc/GMT-8', text: '(UTC +08:00) Irkutsk' },
  { utc: 'Etc/GMT-8', text: '(UTC +08:00) Kuala Lumpur' },
  { utc: 'Etc/GMT-8', text: '(UTC +08:00) Singapore' },
  { utc: 'Etc/GMT-8', text: '(UTC +08:00) Perth' },
  { utc: 'Etc/GMT-8', text: '(UTC +08:00) Taipei' },
  { utc: 'Etc/GMT-8', text: '(UTC +08:00) Ulaanbaatar' },
  { utc: 'Etc/GMT-9', text: '(UTC +09:00) Osaka' },
  { utc: 'Etc/GMT-9', text: '(UTC +09:00) Sapporo' },
  { utc: 'Etc/GMT-9', text: '(UTC +09:00) Tokyo' },
  { utc: 'Etc/GMT-9', text: '(UTC +09:00) Pyongyang' },
  { utc: 'Etc/GMT-9', text: '(UTC +09:00) Seoul' },
  { utc: 'Etc/GMT-9', text: '(UTC +09:00) Yakutsk' },
  { utc: 'Australia/Adelaide', text: '(UTC +09:30) Adelaide' },
  { utc: 'Australia/Adelaide', text: '(UTC +09:30) Broken Hill' },
  { utc: 'Australia/Adelaide', text: '(UTC +09:30) Darwin' },
  { utc: 'Etc/GMT-10', text: '(UTC +10:00) Brisbane' },
  { utc: 'Etc/GMT-10', text: '(UTC +10:00) Canberra' },
  { utc: 'Etc/GMT-10', text: '(UTC +10:00) Hobart' },
  { utc: 'Etc/GMT-10', text: '(UTC +10:00) Melbourne' },
  { utc: 'Etc/GMT-10', text: '(UTC +10:00) Sydney' },
  { utc: 'Etc/GMT-10', text: '(UTC +10:00) Guam' },
  { utc: 'Etc/GMT-10', text: '(UTC +10:00) Port Moresby' },
  { utc: 'Etc/GMT-10', text: '(UTC +10:00) Vladivostok' },
  { utc: 'Etc/GMT-11', text: '(UTC +11:00) Magadan' },
  { utc: 'Etc/GMT-11', text: '(UTC +11:00) New Caledonia' },
  { utc: 'Etc/GMT-11', text: '(UTC +11:00) Solomon Islands' },
  { utc: 'Etc/GMT-12', text: '(UTC +12:00) Auckland, Wellington' },
  { utc: 'Etc/GMT-12', text: '(UTC +12:00) Co-ordinated Universal Time+12' },
  { utc: 'Etc/GMT-12', text: '(UTC +12:00) Fiji' },
  { utc: 'Etc/GMT-13', text: '(UTC +13:00) Nuku alofa' },
];

export default timeZone;
