import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Spinner } from 'react-bootstrap';
import DateModal from '../appointment/dateModal';

const ChemicalInventoryItems = ({
  showProductive,
  setShowPredictive,
  handlePredictiveOrderChange,
  predictiveOption,
  handleSelectPredictiveOrder,
  predictiveData,
  predictiveOrderingArray,
  setChemicalAddSelected,
  predectiveLoading,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          console.log('hello=====', event.srcElement.className);
          if (
            event.srcElement.className === 'duration-picker time-picker-modal'
          ) {
            setShowPredictive(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const { notAvail, requiredChemicalsNames } = predictiveData;

  const predictiveMessage = () => {
    let message = "You don't have enough supplies of";
    let chemMessage = notAvail.join(', ');
    if (notAvail.length === 1) {
      message = 'You have enough chemicals except';
      chemMessage = `for ${notAvail[0]}`;
    }
    if (chemMessage.includes(' (20 kg)')) {
      chemMessage = chemMessage.replace(' (20 kg)', '');
    }
    // eslint-disable-next-line consistent-return
    return (
      <>
        <p>{message}</p>
        <p>{chemMessage}</p>
      </>
    );
  };

  return (
    <div className="duration-picker time-picker-modal update-source-popup chemical-inventory-popup">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <div className="appointment-view">
            {notAvail?.length > 0 ? (
              <div className="calc-inventroy-section inventroy-alert-msg">
                <h6>Inventory</h6>
                {predictiveMessage()}
              </div>
            ) : (
              <div className="calc-inventroy-section">
                <h6>Inventory</h6>
                <p>
                  You have enough chemicals in your supply for today&apos;s
                  requirement.
                </p>
                <p>* Approx 3 weeks stock remaining.</p>
              </div>
            )}
            <h5>Order Chemicals</h5>
            {predictiveOrderingArray?.length > 0
              && predictiveOrderingArray.map((el) => (
                <div
                  className="pending-water-test"
                  onClick={() => handlePredictiveOrderChange(el.value)}
                >
                  <h3>{el.name}</h3>
                  <div className="open-date client-notes">
                    <div className="basic">
                      <Form.Check
                        type="checkbox"
                        name={el.value}
                        checked={predictiveOption === el.value ? true : ''}
                        onChange={(e) => handlePredictiveOrderChange(e.target.name)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            {/* <div className="pending-water-test" onClick={() => handlePredictiveOrderChange('orderingThreeMonths')}>
              <h3>Predictive Ordering - 3 Months</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="orderingThreeMonths"
                    checked={
                      predictiveOption === 'orderingThreeMonths' ? true : ''
                        }
                    onChange={(e) => handlePredictiveOrderChange(e.target.name)}
                  />
                </div>
              </div>
            </div>
            <div className="pending-water-test" onClick={() => handlePredictiveOrderChange('orderingOneMonths')}>
              <h3>
                Predictive Ordering - 1 Month
              </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="orderingOneMonths"
                    checked={
                      predictiveOption === 'orderingOneMonths' ? true : ''
                        }
                    onChange={(e) => handlePredictiveOrderChange(e.target.name)}
                  />
                </div>
              </div>
            </div>
            <div className="pending-water-test" onClick={() => handlePredictiveOrderChange('todaysOrder')}>
              <h3>
                Only Order for Today&apos;s Needs
              </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="todaysOrder"
                    checked={
                      predictiveOption === 'todaysOrder' ? true : ''
                        }
                    onChange={(e) => handlePredictiveOrderChange(e.target.name)}
                  />
                </div>
              </div>
            </div>
            <div className="pending-water-test" onClick={() => handlePredictiveOrderChange('dontOrder')}>
              <h3>
                Don&apos;t Order Any Chemicals
              </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="dontOrder"
                    checked={
                      predictiveOption === 'dontOrder' ? true : ''
                        }
                    onChange={(e) => handlePredictiveOrderChange(e.target.name)}
                  />
                </div>
              </div>
            </div> */}
            <div className="italic-text">
              <p>
                Buying pool chemicals from our website enables us to provide you with ongoing free access & support for PoolWater-Pro.
              </p>
            </div>
            <div className="event-popup-btn footer-btn view-btn">
              <Button
                className="appointment-btn btn-edit"
                type="button"
                onClick={() => setShowPredictive(false)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn btn-save"
                onClick={!predectiveLoading ? () => handleSelectPredictiveOrder() : () => {}}
              >
                {predectiveLoading ? <Spinner animation="border" /> : 'Select'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ChemicalInventoryItems.propTypes = {
  setShowPredictive: PropTypes.func.isRequired,
  handlePredictiveOrderChange: PropTypes.func.isRequired,
  predictiveOption: PropTypes.string.isRequired,
  showProductive: PropTypes.bool.isRequired,
  handleSelectPredictiveOrder: PropTypes.func.isRequired,
  predictiveData: PropTypes.objectOf.isRequired,
  predictiveOrderingArray: PropTypes.arrayOf.isRequired,
  setChemicalAddSelected: PropTypes.func.isRequired,
  predectiveLoading: PropTypes.bool.isRequired,
};
export default ChemicalInventoryItems;
