import React from 'react';
import { toast } from 'react-toastify';
import { errorMsgTime, successMsgTime } from './intervalTime';

const toastMessage = (type, message, time = 0) => {
  const messages = toast[type](
    <>
      <h4>Message</h4>
      <div id="toastIdentity">
        {message}
      </div>
    </>,
    {
      position: 'top-right',
      autoClose: type === 'error' ? errorMsgTime : time || successMsgTime,
      hideProgressBar: false,
      closeOnClick: true,
      icon: type === 'error' ? <i className="fa fa-times-circle" aria-hidden="true" /> : <i className="fa fa-check-circle" aria-hidden="true" />,
      toastId: 'ErrorMessage',
    },
  );
  return messages;
};
export default toastMessage;
