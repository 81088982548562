function compare(a, b) {
  const n = a?.companyName?.toLowerCase() || a?.firstname?.toLowerCase();
  const m = b?.companyName?.toLowerCase() || b?.firstname?.toLowerCase();
  if (n < m) {
    return -1;
  }
  if (n > m) {
    return 1;
  }
  return 0;
}

const sortClientList = (arr) => {
  arr?.sort((a, b) => a.lastname.localeCompare(b.lastname));
  // const listArray = arr?.sort(compare);
  // arr?.forEach((el) => {
  //   const sortSiteAddArray = el?.siteAddresses?.sort((a, b) => a?.householderName.localeCompare(b?.householderName));
  //   el.siteAddresses = sortSiteAddArray;
  // });
  return arr;
};

export default sortClientList;
