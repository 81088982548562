import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import { NotificationManager } from 'react-notifications';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import moment from '../../functions/moment';
import { userInstance } from '../../config/axios';
import logo from '../../assets/home/logo-pool-2.png';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import showmore from '../../assets/home/showmoreblue.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const NewpreviewInvoicePopup = ({
  showInvoice, handleInvoicePopup, invoiceData, type, handleClose, previewUrl, VoidInvoice, permission, userPermissions, addappt, setAddAppt, changeTemplate, defaultTemp, templateArray,
}) => {
  console.log('invoiceData =>', invoiceData);
  const {
    invoiceType, invoiceUrl,
  } = invoiceData || {};
  const [message, setMsg] = useState('No name to appear in Invoice detail');
  const [typeInvoice, setTypeInvoice] = useState(invoiceType);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const handleChange = (value) => {
    setAddAppt(value);
  };

  console.log('inoviceUrl', invoiceUrl, 'preview', previewUrl, invoiceData);
  return (
    <>
      <Modal
        size="md"
        show={showInvoice}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="search-modal new-search-popup"
      >
        <div className="staff-close">
          <i className="fa fa-times" onClick={handleClose} aria-hidden="true" />
        </div>
        <Modal.Body>
          <div className="appointment-container preview-invoice preview-popup pool-setup preview-popup-new">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {addappt && (addappt === 'specificTemp' || addappt === 'defaultTemp')
                && (
                  <>
                    <div className="main-titles-mob">
                      <h3>
                        Invoice Templates
                      </h3>
                      <div className="content-dropdown">
                        <div className="client-option service-edit-icon">
                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <img src={showmore} alt="show" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {addappt === 'specificTemp'
                                ? (
                                  <Dropdown.Item onClick={() => handleChange('defaultTemp')}>
                                    <div className="action">
                                      <span>Default Invoice Template</span>
                                    </div>
                                  </Dropdown.Item>
                                )
                                : (
                                  <Dropdown.Item onClick={() => handleChange('specificTemp')}>
                                    <div className="action">
                                      <span>Select Specific Template</span>
                                    </div>
                                  </Dropdown.Item>
                                )}

                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    {addappt === 'specificTemp'
                    && (
                    <div className="target-heading client-profile">

                      <Form.Group controlId="formBasicCompanyType">
                        <Form.Label>
                          Template Name
                        </Form.Label>
                        <div className="select-box">
                          <Form.Control
                            as="select"
                            name="customerType"
                            value={defaultTemp}
                            onChange={(e) => changeTemplate(e.target.value)}
                          >
                            {templateArray && templateArray.map((temp) => (
                              <option value={temp._id}>{temp.templateName}</option>
                            ))}
                          </Form.Control>
                        </div>
                      </Form.Group>

                    </div>
                    )}
                  </>
                )}
                  {invoiceUrl || previewUrl ? (
                    <div className="add-appointment">
                      <TransformWrapper>
                        <TransformComponent>
                          <Document
                            file={invoiceUrl || previewUrl}
                            onLoadError={(error) => console.log(`Error while loading document! ${error.message}`)}
                          >
                            <Page
                              pageNumber={pageNumber}
                            />
                          </Document>
                        </TransformComponent>
                      </TransformWrapper>
                    </div>

                  ) : (
                    <p> PDF Loading... </p>
                  )}
                </div>
                {invoiceData && invoiceType && invoiceUrl
            && (
            <div className={`footer-btn view-btn ${userPermissions.includes('Delete Invoice') ? '' : 'voidPermission'}`}>

              <Button className="btn-delete" onClick={handleClose}>
                Cancel
              </Button>
              {userPermissions.includes('Delete Invoice') && (
              <Button className="appointment-btn btn-save" onClick={VoidInvoice}>
                Void Invoice
              </Button>
              )}
            </div>
            )}
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

    </>
  );
};
NewpreviewInvoicePopup.propTypes = {
  showInvoice: PropTypes.func.isRequired,
  handleInvoicePopup: PropTypes.bool.isRequired,
  invoiceData: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  previewUrl: PropTypes.string.isRequired,
  VoidInvoice: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  userPermissions: PropTypes.arrayOf.isRequired,
  addappt: PropTypes.string.isRequired,
  setAddAppt: PropTypes.func.isRequired,
  changeTemplate: PropTypes.func.isRequired,
  defaultTemp: PropTypes.string.isRequired,
  templateArray: PropTypes.arrayOf.isRequired,
};
export default NewpreviewInvoicePopup;
