import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const InverterHeater = (props) => {
  const {
    inverterSize,
    handleInverterSize,
    poolInputs,
    handleInverterInputs,
    inverterResult,
    valueType,
    validationData,
  } = props;
  return (
    <>
      <Form.Group
        controlId="exampleForm.SelectCustom"
        className={` ${inverterResult ? 'delete-icon' : ''}`}
      >
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Inverter Heater Size</Form.Label>
          </Col>
          <Col>
            <div className={`select-box ${inverterResult ? 'remove-dropdown' : ''}`}>
              <Form.Control
                as="select"
                custom
                value={inverterSize}
                onChange={handleInverterSize}
                disabled={inverterResult}
              >
                <option value="12">12 Kw</option>
                <option value="17">17 Kw</option>
                <option value="24">24 Kw</option>
              </Form.Control>
            </div>
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Average Air Temp °C</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              name="ambientAirTemp"
              className={`spec-depth ${validationData.ambientAirTemp && !poolInputs.ambientAirTemp ? 'redBorder' : ''}`}
              maxLength="2"
              value={poolInputs.ambientAirTemp}
              disabled={inverterResult}
              onChange={handleInverterInputs}
            />
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Current Pool Temp ºC</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              name="currentPoolTemp"
              className={`spec-depth ${validationData.currentPoolTemp && !poolInputs.currentPoolTemp ? 'redBorder' : ''}`}
              maxLength="2"
              value={poolInputs.currentPoolTemp}
              disabled={inverterResult}
              onChange={handleInverterInputs}
            />
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Required Pool Temp ºC</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              name="requiredPoolTemp"
              className={`spec-depth ${validationData.requiredPoolTemp && !poolInputs.requiredPoolTemp ? 'redBorder' : ''}`}
              maxLength="2"
              value={poolInputs.requiredPoolTemp}
              disabled={inverterResult}
              onChange={handleInverterInputs}
            />
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group
        controlId="exampleForm.SelectCustom"
        className="form-group tempp-input"
      >
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Increase in Pool Temp ºC</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              name="increaseInPoolTemp"
              className="form-control spec-depth"
              maxLength="2"
              value={poolInputs.increaseInPoolTemp}
              disabled
              onChange={handleInverterInputs}
            />
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group
        controlId="exampleForm.SelectCustom"
        className="form-group tempp-input"
      >
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Temp Rise °C (per hour)</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              name="tempRise"
              className="form-control spec-depth"
              maxLength="4"
              value={
                poolInputs.tempRise > 0
                  ? poolInputs.tempRise.toFixed(2)
                  : poolInputs.tempRise
              }
              disabled
              onChange={handleInverterInputs}
            />
          </Col>
        </Form.Row>
      </Form.Group>
      {inverterResult ? (
        <Form.Group controlId="exampleForm.SelectCustom" className="time-to-heat">
          <Form.Row>
            <Col column="xs" md={7} xs={5}>
              <Form.Label>Time to Heat (hours)</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="text"
                name="timeToHeat"
                className="form-control spec-depth"
                maxLength="4"
                value={
                  poolInputs.timeToHeat > 0
                    ? poolInputs.timeToHeat.toFixed(2)
                    : poolInputs.timeToHeat
                }
                disabled
                onChange={handleInverterInputs}
              />
            </Col>
          </Form.Row>
        </Form.Group>
      ) : null}
      {inverterResult && (
      <div className="tempp-desc testing-method">
        <p className="alert-text">
          <span>*</span>
          {' '}
          Ambient temperature impacts inverter heater performance and has been adjusted in calculations. Changes in humidity will affect the accuracy of results. An inverter heater can only increase the pool temperature by between 12-14 degrees higher than the last 5 days average ambient temperature .
        </p>
      </div>
      )}
    </>
  );
};

InverterHeater.propTypes = {
  inverterSize: PropTypes.string.isRequired,
  handleInverterSize: PropTypes.func.isRequired,
  poolInputs: PropTypes.func.isRequired,
  handleInverterInputs: PropTypes.func.isRequired,
  inverterResult: PropTypes.bool.isRequired,
  valueType: PropTypes.bool.isRequired,
  validationData: PropTypes.isRequired,
};

export default InverterHeater;
