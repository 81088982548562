import React, { useState, useEffect, useContext } from 'react';
import { Dropdown, Form, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import moment from '../../functions/moment';
import showmore from '../../assets/home/showmoreblue.png';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import { userInstance } from '../../config/axios';
import {
  getCalendarPath, queryString, removeComma, getCalendarPath2,
} from '../../functions/index';
import target from '../../assets/home/icon/target.png';
import StartDate from './StartDate';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
// import { AuthDataContext } from '../../contexts/AuthDataContext';

const InvoicesPayment = () => {
  // const { clientAllData } = useContext(AuthDataContext);
  const queryParams = new URLSearchParams(window.location.search);
  const Id = queryParams.get('data');
  const history = useHistory();
  const [invoiceData, setInvioiceData] = useState({});
  const [client, setClient] = useState({});
  const [paymentMethod, setPaymentMethod] = useState('EFT');
  const [datePopup, setDatePopup] = useState();
  const [dateCalendar, setdateCalendar] = useState(new Date());
  const { clientid, clientData } = queryString();
  const { from, event } = queryString();
  let AlloutOfBlance = 0;
  const [outstanding, setOutstanding] = useState(false);
  const initialStatePaymentSummery = {
    paymentReceived: '',
    totalApplied: '',
    plusAdjustment: '',
    outofBalance: '',
  };
  const initialStateInvoice = {
    id: '',
    balanceDue: '',
    invoiceTotal: '',
    appliedAmount: '',
    minorAdjustment: '',
    invoiceDate: new Date(),
    invoiceNo: '',
    paymentId: '',
    amount: '',
    status: '',
    appointment: [],
  };
  const [paymentSummary, setPaymentSummary] = useState(initialStatePaymentSummery);
  const [invoicePayload, setInvoicePayload] = useState([initialStateInvoice]);
  const [copyInvoiceData, setCopyInvoiceData] = useState([]);
  const [errorValue, setErrorValue] = useState('');

  const getInvoiceById = async () => {
    try {
      const response = await userInstance().get(`/invoice/getPaymentInvoiveById?id=${Id}`);
      const { invoicedetail, code } = response.data;
      console.log('invoicedetailinvoicedetail=>', invoicedetail);
      if (code === 200) {
        let copyInvoicePayload = { ...initialStateInvoice };
        const { paymentId } = invoicedetail || {};
        if (paymentId.paymentMethod) {
          setPaymentMethod(paymentId.paymentMethod);
        }
        setInvioiceData(invoicedetail);
        setClient(invoicedetail.customer);
        copyInvoicePayload.id = invoicedetail._id || '';
        copyInvoicePayload.invoiceTotal = invoicedetail.invoiceTotal || '';
        copyInvoicePayload.balanceDue = invoicedetail.balanceDue || '';
        copyInvoicePayload.invoiceDate = invoicedetail.createdAt || new Date();
        copyInvoicePayload.invoiceNo = invoicedetail.invoiceNo || '';
        copyInvoicePayload.paymentMethod = invoicedetail.paymentId.paymentMethod || '';
        copyInvoicePayload.paymentId = invoicedetail.paymentId._id || '';
        copyInvoicePayload.amount = invoicedetail.paymentId.amount || '';
        copyInvoicePayload.status = invoicedetail.paymentId.status || '';
        copyInvoicePayload.appointment = invoicedetail.appointment || [];
        setInvoicePayload([copyInvoicePayload]);
        setCopyInvoiceData([copyInvoicePayload]);
        const res = await userInstance().get(`/invoice/getCustomerInvoices?client=${invoicedetail?.customer?._id}&invoiceId=${Id}`);
        const { allInvoiceDetail } = res.data;
        let allArray = [];
        if (allInvoiceDetail && allInvoiceDetail.length > 0) {
          allInvoiceDetail.forEach((el) => {
            // console.log('elel=>', el);
            let obj = {
              id: el.invoice._id || '',
              balanceDue: el.invoice.balanceDue || '',
              invoiceTotal: el.invoice.invoiceTotal || '',
              appliedAmount: '',
              minorAdjustment: '',
              invoiceDate: el.invoice.createdAt || new Date(),
              invoiceNo: el.invoice.invoiceNo || '',
              paymentId: el._id || '',
              amount: el.amount || '',
              status: el.status || '',
              appointment: el.invoice.appointment || [],
            };
            allArray.push(obj);
          });
          allArray.unshift(copyInvoicePayload);
          setInvoicePayload(allArray);
          setCopyInvoiceData(allArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getInvoiceById();
  }, []);

  const savePaymentCompleted = async () => {
    if (paymentSummary?.paymentReceived) {
      if (parseFloat(AlloutOfBlance) >= 0) {
        try {
          const appId = queryParams.get('appId');
          const payload = {
            id: Id,
            appId,
            paymentMethod,
            invoicePayload,
            outstanding,
          };
          const response = await userInstance().post('/payment/saveunpaidPayment', payload);
          // console.log('reponse----->>>', response);
          const { msg } = response.data;
          if (msg === 'Success') {
            history.push(getCalendarPath2(event));
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        // NotificationManager.error('Payment received cannot be greater than invoice total.Please do the minor adjustment', 'Message', 1000);
        NotificationManager.error('Payment applied cannot be greater than payment received amount.Please do the minor adjustment', 'Message', errorMsgTime);
      }
    } else {
      setErrorValue('paymentReceived');
      NotificationManager.error('Pleasee enter payment received amount', 'Message', errorMsgTime);
      setTimeout(() => {
        setErrorValue('');
      }, 1000);
    }
  };

  const handleDatePopup = () => {
    setdateCalendar(new Date());
    setDatePopup(!datePopup);
  };
  const handleSave = () => {
    setDatePopup(!datePopup);
  };
  const handleDateOnchange = (value) => {
    setdateCalendar(new Date(value));
    setDatePopup(!datePopup);
  };
  const handlePaymentReceived = (values) => {
    let copyinvoicePayload = [...invoicePayload];
    let copyPaymentSummary = { ...paymentSummary };
    copyPaymentSummary.paymentReceived = values;
    const receivedAmmount = removeComma(values);
    setPaymentSummary(copyPaymentSummary);
    const balanceDue = removeComma(invoicePayload[0].balanceDue);
    if (parseFloat(receivedAmmount) >= parseFloat(balanceDue)) {
      copyinvoicePayload[0].appliedAmount = invoicePayload[0].balanceDue;
    } else {
      copyinvoicePayload[0].appliedAmount = values;
    }
    setInvoicePayload(copyinvoicePayload);
    setCopyInvoiceData(copyinvoicePayload);
  };
  const goToAwaitSend = () => {
    history.push('/approvalappointments');
  };
  const goToInvoice = (type) => {
    const url = `/invoice?type=${type}`;
    if (clientData) {
      history.push(`${url}&client=${clientData}`);
    } else {
      history.push(url);
    }
  };
  const outStandingClick = () => {
    setOutstanding(!outstanding);
  };
  const getTotalApplied = (allData) => {
    const totalAppliedAmount = allData.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.appliedAmount ? removeComma(next.appliedAmount) : 0), 0);
    return totalAppliedAmount.toFixed(2);
  };
  const getTotalOutofBalance = (allDatas) => {
    let totalaRecevedAmount = removeComma(paymentSummary.paymentReceived);
    let totalAppliedAmount = allDatas.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.appliedAmount ? removeComma(next.appliedAmount) : 0), 0);
    const totalOutBalnce = parseFloat(totalaRecevedAmount) - parseFloat(totalAppliedAmount);
    AlloutOfBlance = totalOutBalnce;
    return totalOutBalnce.toFixed(2);
  };
  const handleMultipleInvoices = (e, index, id) => {
    // console.log('oktest1 ===================>', outstanding, AlloutOfBlance, invoicePayload[index].minorAdjustment);
    let copyinvoicePayload = [...invoicePayload];
    const { name, value } = e.target;
    let balanceDue = removeComma(invoicePayload[index].balanceDue);
    const minusAdjustment = removeComma(invoicePayload[index].minorAdjustment);

    // if minusAdjument have value the change the balance due-
    if (parseFloat(minusAdjustment) > 0) {
      const minusAdjustBalanceDue = parseFloat(balanceDue) - parseFloat(minusAdjustment);
      balanceDue = minusAdjustBalanceDue.toFixed(2);
    }
    const currentValue = removeComma(value);
    if (parseFloat(currentValue) >= parseFloat(balanceDue)) {
      if (parseFloat(minusAdjustment) > 0) {
        copyinvoicePayload[index].appliedAmount = balanceDue.toString();
      } else {
        copyinvoicePayload[index].appliedAmount = invoicePayload[index].balanceDue;
      }
      if (parseFloat(currentValue) > parseFloat(balanceDue)) {
        NotificationManager.error('Payment applied can\'t be higher than the invoice amount', 'Message', errorMsgTime);
      }
    } else {
      copyinvoicePayload[index].appliedAmount = value;
    }
    setInvoicePayload(copyinvoicePayload);
    setCopyInvoiceData(copyinvoicePayload);
  };
  const handleMultipleMinusAmount = (e, index, id) => {
    // console.log('oktest99');
    // console.log('copyInvoiceData==>', copyInvoiceData);
    let copyinvoicePayload = invoicePayload.map((el) => ({ ...el }));
    const { name, value } = e.target;
    let currentAmount = copyInvoiceData[index].appliedAmount;
    const thisInvoicebalanceDue = removeComma(invoicePayload[index].balanceDue);
    const balanceDue = removeComma(currentAmount);
    const currentValue = removeComma(value);
    let minusAmount = 0;
    if (parseFloat(currentValue) >= parseFloat(thisInvoicebalanceDue)) {
      copyinvoicePayload[index].minorAdjustment = invoicePayload[index].balanceDue;
      minusAmount = removeComma(invoicePayload[index].balanceDue);
    } else {
      minusAmount = removeComma(value);
      copyinvoicePayload[index].minorAdjustment = value;
    }
    const calculateTotalAmount = parseFloat(balanceDue) + parseFloat(minusAmount);
    // console.log('calculateTotalAmountcalculateTotalAmount===>', calculateTotalAmount);
    if (parseFloat(currentAmount) >= parseFloat(thisInvoicebalanceDue)) {
      if (parseFloat(balanceDue) > 0 && value !== '') {
        let appliedAmounts = parseFloat(balanceDue) - parseFloat(minusAmount);
        // console.log('appliedAmounts=>', appliedAmounts, balanceDue);
        copyinvoicePayload[index].appliedAmount = appliedAmounts !== 'NaN' ? appliedAmounts.toFixed(2) : '';
      } else {
        copyinvoicePayload[index].appliedAmount = balanceDue.toString();
      }
    } else if (parseFloat(calculateTotalAmount) > parseFloat(thisInvoicebalanceDue)) {
      const saveInvoiceAmount = parseFloat(calculateTotalAmount) - parseFloat(thisInvoicebalanceDue);
      let appliedAmounts = parseFloat(balanceDue) - parseFloat(saveInvoiceAmount);
      copyinvoicePayload[index].appliedAmount = appliedAmounts.toFixed(2);
    } else {
      copyinvoicePayload[index].appliedAmount = balanceDue.toString();
    }
    setInvoicePayload(copyinvoicePayload);
  };
  const handleMultipleInvoicesOnFocus = (value, index, id, due) => {
    let balanceDue = removeComma(invoicePayload[index].balanceDue);
    let copyInvoicePayload = [...invoicePayload];
    const copyoutOfBalnceValue = AlloutOfBlance;
    const minusAdjustment = removeComma(invoicePayload[index].minorAdjustment);
    // if minusAdjument have value the change the balance due-

    if (parseFloat(minusAdjustment) > 0) {
      const minusAdjustBalanceDue = parseFloat(balanceDue) - parseFloat(minusAdjustment);
      balanceDue = minusAdjustBalanceDue.toFixed(2);
    }
    if (!value && parseFloat(paymentSummary.paymentReceived) > 0) {
      if (copyoutOfBalnceValue < parseFloat(balanceDue)) {
        if (copyoutOfBalnceValue === 0) {
          copyInvoicePayload[index].appliedAmount = '';
        } else {
          copyInvoicePayload[index].appliedAmount = copyoutOfBalnceValue.toFixed(2);
        }
      } else {
        copyInvoicePayload[index].appliedAmount = balanceDue.toString();
      }
      setInvoicePayload(copyInvoicePayload);
    }
  };

  useEffect(() => {
    console.log('element');
    if (errorValue) {
      let element = document.querySelector('.error-text');
      if (element) {
        console.log('element');
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errorValue]);

  return (
    <AdminWrapper>
      <div className=" pool-set-up invoices-list invoice-payment-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="back-option">
                <span />
                <Link to={from !== 'invoices' ? `${getCalendarPath()}&clientid=${clientid}` : '/invoice?type=paymentList'}>
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                  />
                </Link>
              </div>
              <div className="main-titles-mob">
                <h3>Invoices & Payments</h3>
                <div className="content-dropdown">
                  <div className="client-option service-edit-icon">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={showmore} alt="show" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div className="action" onClick={() => goToInvoice('newInvoice')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span> New Invoice</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={goToAwaitSend}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span> Awaiting Send Approval</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => goToInvoice('invoiceList')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span> Saved Invoices</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => goToInvoice('paymentList')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span> Receive Payments</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => goToInvoice('searchinvoices')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span> Search Invoices</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => goToInvoice('searchpayments')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Search Payments</span>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>

                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="invoice-detail search-invoice receive-invoice-detail">
                <div className="pool-setup">
                  <div className="target-heading">
                    <h6>Receive Payments</h6>
                    <i className="fa fa-plus" />
                  </div>
                  <div className="pool-chemistry-box appointment-service-modal">
                    <div className="target-heading client-profile">
                      {client
                        ? (
                          <div className="">
                            <h3>{`${client.firstname || ''} ${client.lastname || ''}`}</h3>
                            <p>
                              {`${client.streetAddress || ''}${client.suburb || client.state ? '' : ''} ${client.suburb || ''
                              } ${client.state || ''}`}
                            </p>
                            <p>{client.mobileNo || ''}</p>
                          </div>
                        )
                        : '' }
                    </div>
                  </div>
                  <div className="pool-chemistry-box appointment-service-modal">
                    <div
                      className="outstandings-invoices"
                      onClick={() => {
                        setOutstanding(!outstanding);
                      }}
                    >
                      <h6>Display All Outstandings Invoices </h6>
                      <div className="open-date client-notes">
                        <div className="basic">
                          <Form.Check
                            type="checkbox"
                            // onChange={() => {
                            //   outStandingClick();
                            // }}
                            checked={outstanding ? true : ''}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="search-invoces-box payment-method">
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <Form.Group className="search-box">
                            <Form.Label>Payment Received</Form.Label>
                            <NumberFormat
                              thousandSeparator
                              type="text"
                              placeholder=""
                              name="quantity"
                              value={paymentSummary.paymentReceived || ''}
                              onChange={(e) => handlePaymentReceived(e.target.value)}
                              // className="form-control"
                              className={`form-control ${
                                errorValue === 'paymentReceived'
                                  ? 'error-text'
                                  : ''
                              }`}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12 col-12">
                          <Form.Group className="search-box search-invoice-box">
                            <Form.Label>Date Received</Form.Label>
                            <Form.Group className="search-box">
                              <div className="custom-datepicker" onClick={handleDatePopup}>
                                {`${moment(dateCalendar).format('ddd, DD MMM yyyy')}`}
                              </div>
                            </Form.Group>
                          </Form.Group>
                        </div>
                        <div className="col-md-12 col-12">
                          <Form.Group className="search-box">
                            <Form.Label>Payment Method</Form.Label>
                            <div className="select-box">
                              <i className="fas fa-sort-down" />
                              <Form.Control
                                as="select"
                                name=""
                                value={paymentMethod}
                                onChange={(e) => setPaymentMethod(e.target.value)}
                              >
                                <option
                                  name="customCost"
                                  value="EFT"
                                >
                                  EFT
                                </option>
                                <option
                                  name="customCost"
                                  value="Visa/Mastercard"
                                >
                                  Visa/Mastercard
                                </option>
                                <option
                                  name="customCost"
                                  value="Cash"
                                >
                                  Cash
                                </option>
                                <option
                                  name="customCost"
                                  value="Cheque"
                                >
                                  Cheque
                                </option>
                              </Form.Control>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    {invoicePayload && invoicePayload.length > 0 && (outstanding ? invoicePayload : [invoicePayload[0]]).map((el, index) => (
                      <div className="search-invoces-box">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="multiple-invoice">
                              <div className="payment-details-box">
                                <div className="invoice-detail-section">
                                  <div className="payment-details">
                                    <h6>Invioce #</h6>
                                    <h6>{el?.invoiceNo || 'N/A'}</h6>
                                  </div>
                                  <div className="payment-details">
                                    <h6> Invioce Date</h6>
                                    {' '}
                                    <h6>{moment(el?.invoiceDate || new Date()).format('DD MMMM YYYY')}</h6>
                                  </div>
                                  <div className="payment-details">
                                    <h6> Invioce Amount</h6>
                                    <h6>
                                      $
                                      {el?.invoiceTotal || ''}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-12">
                            <Form.Group className="search-box minor">
                              <Form.Label>Minor Adjustment (-)</Form.Label>
                              <NumberFormat
                                thousandSeparator
                                type="text"
                                placeholder=""
                                name="minorAdjustment"
                                value={el.minorAdjustment || ''}
                                className="form-control"
                                onChange={(e) => handleMultipleMinusAmount(e, index, el.id)}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-12 col-12">
                            <Form.Group className="search-box minor">
                              <Form.Label>Total This Invoice</Form.Label>
                              <NumberFormat
                                thousandSeparator
                                type="text"
                                placeholder=""
                                name="balanceDue"
                                value={el.balanceDue || ''}
                                className="form-control"
                                disabled
                              />
                            </Form.Group>
                          </div>
                          {index === 0
                            ? (
                              <div className="col-md-12 col-12">
                                <Form.Group className="search-box payment-applied">
                                  <Form.Label>Payment Applied</Form.Label>
                                  <NumberFormat
                                    thousandSeparator
                                    type="text"
                                    placeholder=""
                                    name="appliedAmount"
                                    value={el.appliedAmount || ''}
                                    className="form-control"
                                    onChange={(e) => handleMultipleInvoices(e, index, el.id)}
                                  />
                                </Form.Group>
                              </div>
                            )
                            : (
                              <div className="col-md-12 col-12">
                                <Form.Group className="search-box payment-applied">
                                  <Form.Label>Payment Applied</Form.Label>
                                  <NumberFormat
                                    thousandSeparator
                                    type="text"
                                    placeholder=""
                                    name="appliedAmount"
                                    value={el.appliedAmount || ''}
                                    className="form-control"
                                    onChange={(e) => handleMultipleInvoices(e, index, el.id)}
                                    onFocus={(e) => handleMultipleInvoicesOnFocus(el.appliedAmount, index, el.id, el.balanceDue)}
                                  />
                                </Form.Group>
                              </div>
                            )}
                        </div>
                      </div>
                    ))}

                    <div className="search-invoces-box total-applied">
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <Form.Group className="search-box">
                            <Form.Label>Total Applied</Form.Label>
                            <NumberFormat
                              thousandSeparator
                              type="text"
                              placeholder=""
                              name="totalApplied"
                              value={getTotalApplied(invoicePayload)}
                              className="form-control"
                              disabled
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12 col-12">
                          <Form.Group className="search-box">
                            <Form.Label>Payment Received</Form.Label>
                            <NumberFormat
                              thousandSeparator
                              type="text"
                              placeholder=""
                              name="paymentReceived"
                              value={paymentSummary.paymentReceived || ''}
                              className="form-control"
                              onChange={(e) => handlePaymentReceived(e.target.value)}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12 col-12">
                          <Form.Group className="search-box">
                            <Form.Label>Minor Adjustment (+)</Form.Label>
                            <NumberFormat
                              thousandSeparator
                              type="text"
                              placeholder=""
                              name="plusAdjustment"
                              value={getTotalOutofBalance(invoicePayload)}
                              className="form-control"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12 col-12">
                          <Form.Group className="search-box">
                            <Form.Label>Out of Balance</Form.Label>
                            <NumberFormat
                              thousandSeparator
                              type="text"
                              placeholder=""
                              name="outofBalance"
                              value={getTotalOutofBalance(invoicePayload)}
                              className="form-control"
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="view-btn search-invoice-btn">
                          <Button
                            className="btn-save"
                            onClick={() => savePaymentCompleted()}
                          >
                            Save Payment
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StartDate datePopup={datePopup} setDatePopup={setDatePopup} handleDatePopup={handleDatePopup} dateCalendar={dateCalendar} handleDateOnchange={handleDateOnchange} handleSave={handleSave} />
    </AdminWrapper>
  );
};
export default InvoicesPayment;
