import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { userInstance } from '../../config/axios';
import { queryString } from '../../functions';
import './smstemplate.css';
import AdminWrapper from '../admin/adminwrapper/wrapper';

const moment = require('moment');

const SmsTemplate = () => {
  let { id } = queryString();
  const [smstemplateData, setSmsTemplate] = useState(null);

  const getInvoiceData = async () => {
    const response = await userInstance().get(`/automaticInvoice/smsInvoicesData?uid=${id}`);
    const { smsTempData, code } = response.data;
    if (code === 200) {
      setSmsTemplate(smsTempData);
    }
  };
  useEffect(() => {
    getInvoiceData();
  }, [id]);

  return (
    <AdminWrapper>
      <div className="email-sending-temp search-invoice pool-set-up sms-invocie-template new-sms-template">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="client-profile">
                <div className="">
                  <h3 className="sms-heading">
                    Invoice Summary
                  </h3>
                </div>
              </div>
              <div className="smssending-days-section">
                <div className="top-heading" />
                <div className="days-description">
                  <p className="margin-values">
                    {smstemplateData?.smstemplate?.salutation || 'Hi'}
                    {' '}
                    {smstemplateData?.customerName || ''}
                    ,
                  </p>
                  <p>
                    {smstemplateData?.smstemplate?.textOpening || 'Your pool visit was completed for today.Please click the invoice link to view detail.'}
                    {' '}
                  </p>

                  <div className="todays-invoice">
                    <h6>TODAY&apos;S VISIT</h6>
                    {smstemplateData && smstemplateData.todayInvoice && smstemplateData.todayInvoice.length > 0 && smstemplateData.todayInvoice.map((ele) => (
                      <p>
                        <span>$</span>
                        {' '}
                        {parseFloat(ele?.balanceDue || 0).toFixed(2) || ''}
                        {' '}
                        |
                        {' '}
                        <a href={ele?.invoiceUrl} target="_blank" rel="noreferrer">
                          <span className="inovocie-link">
                            INV #
                            {ele?.invoiceNo || ''}
                          </span>
                        </a>

                        {' '}
                        {moment(new Date(ele?.createdAt)).format('ddd, D MMMM YYYY')}
                        {' '}
                      </p>
                    ))}
                  </div>
                  <div className="todays-invoice past-due">
                    <h6>PAST DUE</h6>
                    {smstemplateData && smstemplateData.pastInvoice && smstemplateData.pastInvoice.length > 0 && smstemplateData.pastInvoice.map((ele) => (

                      <p>
                        <span>$</span>
                        {' '}
                        {parseFloat(ele?.balanceDue || 0).toFixed(2) || ''}
                        {' '}
                        |
                        {' '}
                        <a href={ele?.invoiceUrl} target="_blank" rel="noreferrer">
                          <span className="inovocie-link">
                            INV #
                            {ele?.invoiceNo || ''}
                          </span>
                        </a>
                        {' '}
                        {moment(new Date(ele?.createdAt)).format('ddd, D MMMM YYYY')}
                        {' '}
                      </p>
                    ))}
                    <p />
                    <p>- - - - - - - - - - - - - -</p>
                    <h6>
                      $
                      {parseFloat(smstemplateData?.totalDue || 0).toFixed(2)}
                      {' '}
                      TOTAL DUE
                    </h6>
                  </div>
                  <div className="bottom-inovoice-text">
                    <p>{smstemplateData?.smstemplate?.textClosing || 'Thank you very much for your work'}</p>
                    <p>{smstemplateData?.smstemplate?.complimentary || 'Kind Regards,'}</p>
                    <p>
                      {smstemplateData?.smstemplate?.senderName || ''}
                      {' '}
                    </p>
                  </div>
                  <div className="bottom-inovoice-text">
                    <Button
                      className="paynowdue appointment-btn btn-save"
                    >
                      Pay Now
                    </Button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default SmsTemplate;
