import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Form, Col, Row, Modal, Spinner,
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import Calendar from 'react-calendar';
import moment from '../../functions/moment';
import { userInstance } from '../../config/axios';
import {
  errorMsgTime,
  successMsgTime,
  errorBorderTime,
} from '../../functions/intervalTime';
import 'react-calendar/dist/Calendar.css';
import adjustInv from './index';
import {
  alphaOnly,
  chemicalsUnitDropdown,
  getPerUnitFromUnitValue,
  getUnit,
  getUnitNew,
  numberFromString,
  onKeyDownNumber,
} from '../../functions';
import toastMessage from '../../functions/toastMessage';

const InventoryAdjustment = ({ updateView }) => {
  const initialobj = {
    stockChange: 'increaseStock',
    fromLocationId: '',
    fromLocationName: '',
    fromLocationStock: '',
    fromMemberId: '',
    fromMemberStock: '',
    toLocationId: '',
    toLocationName: '',
    toPreviousLocationStock: '',
    toMemberId: '',
    toMemberName: '',
    toPreviousMemberStock: '',
    stockOnHand: '',
    quantityTransferred: '',
    toCurrentLocationStock: '',
    toCurrentMemberStock: '',
    buyCost: '',
  };

  const initialSimpleObj = {
    quantityAdded: '',
    newQuanity: '',
    adjustmentReason: 'newStockPurchase',
    toLocationId: '',
    toMemberId: '',
    adjustmentDate: '',
    addedVolume: '',
  };

  const [showLoaction, setShowLocations] = useState(false);
  const [filterObj, setFilterObj] = useState(initialobj);
  const [locationData, setLocationData] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([]);
  const [searchedProduct, setSearchedProduct] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [staffBylocation, setStaffByLocation] = useState([]);
  const [locationSelect, setLocation] = useState('');
  const [singleProduct, setSingleProduct] = useState(null);
  const [selectedLocationValue, setSelectedLocationValue] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [simpleObject, setSimpleObject] = useState(initialSimpleObj);
  const [errorValue, setErrorValue] = useState('');
  const [datePopup, setDatePopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format('D MMM YYYY'),
  );
  const [err, setErr] = useState(false);
  const [itemVolume, setItemVolume] = useState([]);
  const stockCost = singleProduct && simpleObject.quantityAdded > 0
    ? simpleObject.quantityAdded * parseFloat(singleProduct.cost)
    : '';
  console.log(singleProduct, 'singleProduct');
  useEffect(() => {
    const handleProductSearch = async () => {
      try {
        const response = await userInstance().get(
          '/inventory/getInventoryListOnly',
        );
        if (response.data.results.length > 0 && response.data.code === 200) {
          setSubcategories(response.data.results || []);
        } else {
          setSubcategories([]);
        }
      } catch (error) {
        console.log('error=>', error);
      }
    };
    handleProductSearch();
  }, []);

  const handleSave = () => {
    setDatePopup(!datePopup);
  };

  const selectedproductData = (e) => {
    const productId = e.target.value;

    if (productId) {
      let newOrderQuantity = [];
      let prodUnit = '';
      const findProduct = subcategories.find((el) => el._id === productId);
      const staff = {};
      const initialValue = Object.values(staff)[0] || [];
      const toLocationStockInitial = initialValue?.reduce(
        (acc, obj) => parseFloat(acc) + parseFloat(obj?.stockValue || 0),
        0,
      );
      setSingleProduct(findProduct);
      setSelectedProduct(staff);
      setLocation(Object.keys(staff)[0]);
      setStaffByLocation(initialValue);
      setFilterObj((prev) => ({
        ...prev,
        fromLocationStock: toLocationStockInitial,
        toPreviousLocationStock: toLocationStockInitial,
        stockOnHand: findProduct?.currentStockItems,
      }));
      const findSelctedChemicalDropdowns = chemicalsUnitDropdown.find(
        (el) => el.renderName === findProduct?.renderName,
      );
      if (findSelctedChemicalDropdowns) {
        let currentVolume = numberFromString(
          findSelctedChemicalDropdowns.dropDownOptions[0],
        );
        let diff = Math.abs(findProduct.predictedOrderQuantity - currentVolume);
        prodUnit = `per${getPerUnitFromUnitValue(
          findSelctedChemicalDropdowns.dropDownOptions[0],
        )}`;
        findSelctedChemicalDropdowns.dropDownOptions.forEach((el) => {
          let unitNumber = numberFromString(el);
          if (el.includes('ml') || el.includes('grams')) {
            unitNumber /= 1000;
          }
          let newdiff = Math.abs(
            findProduct.predictedOrderQuantity - unitNumber,
          );
          if (newdiff < diff) {
            diff = newdiff;
            currentVolume = unitNumber;
          }
          let obj = {
            value: unitNumber,
            unit: getPerUnitFromUnitValue(el),
            renderValue: numberFromString(el),
          };
          newOrderQuantity.push(obj);
        });
      } else {
        prodUnit = `per ${findProduct.unit}`;
        newOrderQuantity = [
          { value: 1, unit: ` ${findProduct.unit}`, renderValue: 1 },
          { value: 5, unit: ` ${findProduct.unit}`, renderValue: 5 },
          { value: 10, unit: ` ${findProduct.unit}`, renderValue: 10 },
          { value: 15, unit: ` ${findProduct.unit}`, renderValue: 15 },
          { value: 20, unit: ` ${findProduct.unit}`, renderValue: 20 },
          { value: 25, unit: ` ${findProduct.unit}`, renderValue: 25 },
        ];
      }
      setItemVolume([...newOrderQuantity]);
      const copySimpleObj = {
        ...simpleObject,
        addedVolume: newOrderQuantity[0].value,
      };
      copySimpleObj.newQuanity = copySimpleObj.addedVolume + findProduct?.currentStockItems;
      filterObj.buyCostUnit = `${newOrderQuantity[0].renderValue}${newOrderQuantity[0].unit}`;
      copySimpleObj.quantityAdded = parseFloat(newOrderQuantity[0].value);
      setSimpleObject(copySimpleObj);
    } else {
      setSimpleObject(initialSimpleObj);
      setItemVolume([]);
      setSingleProduct(null);
      setFilterObj(initialobj);
      setSelectedProduct(null);
    }
  };

  const handleOnchange = (e) => {
    let { name, value } = e || {};
    if (value.length >= 8) {
      toastMessage(
        'error',
        'You can only add 7 digit cost ',
      );
      setErr(true);
    } else {
      setErr(false);
    }
    // value = value.includes('.') ? value.replace('.', '') : value;
    let copyfilterObj = { ...filterObj };
    if (name === 'stockChange') {
      copyfilterObj.stockChange = value;
      setSimpleObject({
        quantityAdded: '',
        newQuanity: '',
        adjustmentReason:
          value === 'increaseStock'
            ? 'newStockPurchase'
            : value === 'decreaseStock'
              ? 'internalUse'
              : '',
      });
    }
    if (name === 'quantityTransferred') {
      const valueInNumber = value ? parseFloat(value) : '';
      if (valueInNumber > filterObj.stockOnHand) {
        toastMessage(
          'error',
          'Quantity cannot be greater than Quantity on Hand ',
        );
      } else {
        copyfilterObj.quantityTransferred = valueInNumber;
        copyfilterObj.toCurrentMemberStock = filterObj.toPreviousMemberStock
          + parseFloat(valueInNumber === '' ? 0 : valueInNumber);
        if (filterObj.toLocationId === filterObj.fromLocationId) {
          copyfilterObj.toCurrentLocationStock = filterObj.toPreviousLocationStock;
        } else {
          copyfilterObj.toCurrentLocationStock = filterObj.toPreviousLocationStock
            + parseFloat(valueInNumber === '' ? 0 : valueInNumber);
        }
      }
    }
    if (name === 'buyCost') {
      copyfilterObj.buyCost = value;
    }
    setFilterObj(copyfilterObj);
  };

  // const handleOnchange = (e) => {
  //   let { name, value, selectedOptions } = e || {};
  //   if (value.length > 7) {
  //     value = value.substring(0, 7);
  //     toastMessage(
  //       'error',
  //       'You can only add 7 digit cost ',
  //     );
  //     setErr(true);
  //   } else {
  //     setErr(false);
  //   }
   
  //   let copyfilterObj = { ...filterObj };
  //   if (name === 'stockChange') {
  //     copyfilterObj.stockChange = value;
  //     setSimpleObject({
  //       quantityAdded: '',
  //       newQuanity: '',
  //       adjustmentReason:
  //         value === 'increaseStock'
  //           ? 'newStockPurchase'
  //           : value === 'decreaseStock'
  //             ? 'internalUse'
  //             : '',
  //     });
  //   }
  //   if (name === 'quantityTransferred') {
  //     const valueInNumber = value ? parseFloat(value) : '';
  //     if (valueInNumber > filterObj.stockOnHand) {
  //       toastMessage(
  //         'error',
  //         'Quantity cannot be greater than Quantity on Hand ',
  //       );
  //     } else {
  //       copyfilterObj.quantityTransferred = valueInNumber;
  //       copyfilterObj.toCurrentMemberStock = filterObj.toPreviousMemberStock
  //         + parseFloat(valueInNumber === '' ? 0 : valueInNumber);
  //       if (filterObj.toLocationId === filterObj.fromLocationId) {
  //         copyfilterObj.toCurrentLocationStock = filterObj.toPreviousLocationStock;
  //       } else {
  //         copyfilterObj.toCurrentLocationStock = filterObj.toPreviousLocationStock
  //           + parseFloat(valueInNumber === '' ? 0 : valueInNumber);
  //       }
  //     }
  //   }
  //   if (name === 'buyCost') {
  //     copyfilterObj.buyCost = value;
  //   }
  //   setFilterObj(copyfilterObj);
  // };

  const isValidDate = (d) => d instanceof Date && !Number.isNaN(d);
  const handleDatePopup = (e) => {
    if (isValidDate(e)) {
      const date = moment(e).format('MMM D YYYY');
      setSelectedDate(date);
    }
    setDatePopup(!datePopup);
  };

  const handleCancel = () => {
    setShowLocations(false);
    setSearchedProduct('');
    setSelectedProduct([]);
    setLocation('');
    setStaffByLocation(null);
    setSearchedProduct([]);
    setSubcategories(null);
    setFilterObj({});
    // setShowLocations(false);
  };

  const incrementDecrementHandle = (e) => {
    const { value, name, selectedOptions } = e || {};
    const valueInNumber = value === '' ? 0 : parseFloat(value);

    let totalQuantity = 0;
    if (filterObj.stockChange === 'increaseStock') {
      totalQuantity = valueInNumber + filterObj.stockOnHand;
    } else if (filterObj.stockChange === 'decreaseStock') {
      if (filterObj.stockOnHand < valueInNumber) {
        setErr(true);
        totalQuantity = 0;
      } else {
        setErr(false);
        totalQuantity = filterObj.stockOnHand - valueInNumber;
      }
      // err
    }
    const changeSimpleObj = {
      ...simpleObject,
      quantityAdded: valueInNumber,
      newQuanity: value === '' ? 0 : totalQuantity,
    };
    if (name === 'quantityAddedDropdown') {
      changeSimpleObj.addedVolume = value;
      const copyfilterObj = { ...filterObj };
      copyfilterObj.buyCostUnit = `${selectedOptions[0].text}`;
      setFilterObj(copyfilterObj);
    }
    setSimpleObject(changeSimpleObj);
  };

  const MAX_LIMIT = 10000000;
  const limitQuantityAdded = (inputObj) => {
    const { value } = inputObj;
    if (
      value > filterObj.stockOnHand
      && filterObj.stockChange === 'decreaseStock'
    ) {
      return toastMessage(
        'error',
        `${
          filterObj.stockChange === 'increaseStock' ? 'Added' : 'Removed'
        } Quantity cannot be greater than Quantity on Hand`,
      );
    }
    if (value < MAX_LIMIT) {
      return inputObj;
    }
    return '';
  };

  const limitQuantityStaffDec = (inputObj, index) => {
    const { value } = inputObj;
    if (
      parseFloat(value || 0) > selectedStaff[index].previousStock
      && filterObj.stockChange === 'decreaseStock'
    ) {
      return toastMessage(
        'error',
        'Quantity cannot be greater than previous quantity',
      );
      // NotificationManager.error(
      //   'Quantity cannot be greater than previous quantity', 'Message', errorMsgTime,
      // );
    }
    return inputObj;
  };
  const handleAdjustDropdown = (e) => {
    const { value } = e.target;
    setSimpleObject({ ...simpleObject, adjustmentReason: value });
  };
  const increaseDecreaseStockAPI = async (totalStockValue) => {
    let payload = {
      transferType: filterObj.stockChange,
      productId: singleProduct?._id,
      productName: singleProduct?.renderName,
    };
    if (simpleObject.quantityAdded > 0) {
      let adjustData = singleProduct?.addjustmentRecord;
      const adjustCount = adjustData !== undefined ? adjustData.length : 0;
      payload = {
        ...payload,
        ...simpleObject,
        adjustCount: adjustCount + 1,
        stockCost,
      };
    } else {
      setErrorValue('quantityAdded');
      setTimeout(() => {
        setErrorValue('');
      }, errorBorderTime);
      toastMessage(
        'error',
        `Quantitiy ${
          filterObj.stockChange === 'increaseStock' ? 'added' : 'removed'
        } must be greater than 0`,
      );
      return null;
    }
    return payload;
  };
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line consistent-return
  const handleSubmit = async () => {
    if (selectedProduct) {
      console.log(selectedProduct, 'selectedProduct');
      let payload = null;
      const totalStockValue = selectedStaff?.reduce(
        (acc, obj) => parseFloat(acc) + parseFloat(obj?.stockValue || 0),
        0,
      );
      if (filterObj.stockChange === 'transferFrom') {
        // const trasnsferFromSubmit = await trasnsferFromAPI();
        // if (trasnsferFromSubmit) payload = trasnsferFromSubmit || {};
      } else {
        const decreaseStockSubmit = await increaseDecreaseStockAPI(
          totalStockValue,
        );
        if (decreaseStockSubmit) payload = decreaseStockSubmit || {};
      }
      if (payload) {
        if (filterObj.stockChange === 'increaseStock' && !filterObj.buyCost) {
          setErrorValue('buyCost');
          setTimeout(() => {
            setErrorValue('');
          }, errorBorderTime);
          return toastMessage('error', 'Enter buy cost.');
        }
        payload.adjustmentDate = new Date(selectedDate);
        payload.buyCostUnit = alphaOnly(filterObj?.buyCostUnit || '');
        payload.buyCost = filterObj.buyCost;
        setLoading(true);
        const res = await adjustInv(payload);
        setLoading(false);
        if (res.code === 200) {
          toastMessage('success', 'Inventory Adjust Successfully.');
        } else {
          toastMessage('error', 'Unable to update');
        }
      }
    } else {
      setErrorValue('selectProd');
      setTimeout(() => {
        setErrorValue('');
      }, errorBorderTime);
      toastMessage('error', 'Please select the product.');
    }
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setSubcategories(null);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if (errorValue) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errorValue]);

  return (
    <>
      <div className="inventory-adjustment pool-set-up ">
        <div className="target-heading">
          <h6>Inventory Adjustment</h6>
        </div>
        <div className="appointment-summary">
          <div className="inventory-adjustment-boxes">
            {/* <div className="search-box search-text"> */}
            <Form.Group className="search-box">
              <Form.Label>Date</Form.Label>
              {/* <Form.Control type="date" placeholder="" value={invoiceStart} onChange={(e) => setInvoiceStart(e.target.value)} /> */}
              {/* <div className="custom-datepicker" onClick={handleDatePopup}>26 Dec, 2022</div> */}
              <div className="custom-datepicker" onClick={handleDatePopup}>
                {`${moment(selectedDate).format('DD MMM yyyy')}`}
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicCompanyType">
              <Form.Label>Product Name</Form.Label>
              <div className="select-box">
                <Form.Control
                  as="select"
                  name="selectProd"
                  onChange={(e) => selectedproductData(e)}
                  className={errorValue === 'selectProd' ? 'error-text' : ''}
                >
                  <option value="">Select product</option>
                  {subcategories.map((el) => (
                    <option key={el?._id} value={el._id}>
                      {el?.renderName ?? el?.name}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicCompanyType">
              <Form.Label>Stock Movement</Form.Label>
              <div className="select-box">
                <Form.Control
                  as="select"
                  name="stockChange"
                  value={filterObj.stockChange}
                  onChange={(e) => handleOnchange(e.target)}
                >
                  <option value="increaseStock">Increase Stock Level</option>
                  <option value="decreaseStock">Decrease Stock Level</option>
                </Form.Control>
              </div>
            </Form.Group>
            {/* </div> */}
            {showLoaction && selectedProduct && (
              <>
                <Form.Group controlId="formBasicCompanyType">
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      onChange={(e) => handleOnchange(e.target)}
                      name="fromLocationId"
                      value={locationSelect}
                    >
                      {Object.keys(selectedProduct).map((location) => (
                        <option value={location}>
                          {location}
                          {' '}
                          Team Member
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasicCompanyType">
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      onChange={(e) => handleOnchange(e.target)}
                      name="fromMemberId"
                      value={filterObj.fromMemberId}
                    >
                      {staffBylocation
                        && staffBylocation.length > 0
                        && staffBylocation.map((el) => (
                          <>
                            <option value={el?._id._id}>
                              {`${el?._id.firstname} ${el._id.lastname}`}
                              {' '}
                            </option>
                          </>
                        ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </>
            )}

            {filterObj.stockChange !== 'transferFrom' && (
              <>
                <Form.Group controlId="formBasicCompanyType">
                  <Form.Label>Adjustment Reason</Form.Label>
                  {filterObj.stockChange === 'increaseStock' ? (
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        value={simpleObject.adjustmentReason}
                        onChange={(e) => handleAdjustDropdown(e)}
                      >
                        <option value="newStockPurchase">
                          New Stock Purchase
                        </option>
                        <option value="inventoryStocktake">
                          Inventory Stocktake
                        </option>
                        <option value="returned">Returned</option>
                        <option value="transfered">Transferred</option>
                        <option value="other">Other</option>
                      </Form.Control>
                    </div>
                  ) : (
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        value={simpleObject.adjustmentReason}
                        onChange={(e) => handleAdjustDropdown(e)}
                      >
                        <option value="internalUse">Internal Use</option>
                        <option value="faultyStock">Faulty Stock</option>
                        <option value="inventoryStocktake">
                          Inventory Stocktake
                        </option>
                        <option value="lost">Lost</option>
                        <option value="theft">Theft</option>
                        <option value="other">Other</option>
                      </Form.Control>
                    </div>
                  )}
                </Form.Group>
              </>
            )}
            <Form.Group controlId="formBasicCompanyType" className="half1 half-49 disable-input">
              <Form.Label>Quantity on Hand</Form.Label>
              <Form.Control
                type="text"
                value={`${filterObj.stockOnHand} ${getUnit(
                  singleProduct?.unit,
                  filterObj.stockOnHand,
                )}`}
              />
            </Form.Group>
            {simpleObject.adjustmentReason === 'newStockPurchase' ? (
              <Form.Group controlId="formBasicLocation" className="half1 half-49">
                <Form.Label>Quantity Added</Form.Label>
                <div className="select-box">
                  <Form.Control
                    as="select"
                    name="quantityAddedDropdown"
                    onChange={(e) => incrementDecrementHandle(e.target)}
                    value={simpleObject?.addedVolume}
                  >
                    <option value="">Select</option>
                    {itemVolume?.length > 0
                      && itemVolume.map((el) => (
                        <option value={el.value}>
                          {el.renderValue}
                          {' '}
                          {el.unit}
                        </option>
                      ))}
                  </Form.Control>
                </div>
              </Form.Group>
            ) : (
              <Form.Group
                controlId="formBasicInput-2"
                className="italic-text half1 half-49"
              >
                <Form.Label>
                  Quantity Removed
                  {' '}
                  {`${simpleObject?.quantityAdded}`
                    && ` (${getUnit(
                      singleProduct?.unit,
                      simpleObject?.quantityAdded,
                    )})`}
                </Form.Label>
                <NumberFormat
                  className={`form-control mobile-num ${
                    errorValue === 'quantityAdded' ? 'error-text' : ''
                  }`}
                  name="quantityAdded"
                  placeholder="Enter Quantity Removed"
                  value={simpleObject.quantityAdded}
                  onChange={(e) => incrementDecrementHandle(e.target)}
                  decimalScale={2}
                  isAllowed={limitQuantityAdded}
                />
              </Form.Group>
            )}
            <Form.Group
              controlId="formBasicInput-2"
              className={
                simpleObject.adjustmentReason === 'newStockPurchase'
                  ? 'half1 half-49 disable-input'
                  : 'disable-input'
              }
            >
              <Form.Label>New Total Quantity</Form.Label>
              <Form.Control
                type="text"
                value={
                  `${simpleObject?.newQuanity}`
                  && `${simpleObject?.newQuanity} ${getUnit(
                    singleProduct?.unit,
                    simpleObject?.newQuanity,
                  )}`
                }
              />
            </Form.Group>
            {simpleObject.adjustmentReason === 'newStockPurchase' ? (
              <>
                <Form.Group
                  controlId="formBasicInput-2"
                  className="italic-text half1 half-49"
                >
                  <Form.Label>Buy Cost</Form.Label>
                  <Form.Control
                    type="number"
                    name="buyCost"
                    value={filterObj.buyCost}
                    placeholder={`Enter Cost ${
                      filterObj.buyCostUnit
                        ? `for ${filterObj.buyCostUnit}`
                        : ''
                    }`}
                    onChange={(e) => handleOnchange(e.target)}
                    onKeyDown={onKeyDownNumber}
                    className={errorValue === 'buyCost' ? 'error-text' : ''}
                    
                  />
                </Form.Group>
                <Form.Group
                  controlId="formBasicInput-2"
                  className="italic-text half1 half-49 disable-input"
                >
                  <Form.Label>Past Average Cost</Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      singleProduct
                        ? `$${parseFloat(singleProduct?.pastAvgCost
                          || 0).toFixed(
                          2,
                        )} per ${getUnitNew(
                          singleProduct?.unit,
                          simpleObject?.newQuanity,
                        )}`
                        : ''
                    }
                  />
                  
                </Form.Group>
                <Form.Group
                  controlId="formBasicInput-2"
                  className="italic-text half1 half-49 disable-input"
                >
                  <Form.Label>Cost Today</Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      singleProduct
                        ? `$${parseFloat(singleProduct?.cost || 0).toFixed(
                          2,
                        )} per ${getUnitNew(
                          singleProduct?.unit,
                          simpleObject?.newQuanity,
                        )}`
                        : ''
                    }
                  />
                </Form.Group>
              </>
            ) : null}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn appointment-summary-btn">
                <Button
                  type="Submit"
                  className="btn btn-edit"
                  onClick={() => updateView('InventoryAdjustment', 'goBack')}
                >
                  {' '}
                  Cancel
                  {' '}
                </Button>
                {
                  !err ? (
                    <Button
                      type="Submit"
                      className="btn btn-save"
                      onClick={!loading ? handleSubmit : () => {}}
                    >
                      {' '}
                      {loading ? (
                        <Spinner animation="border" />
                      ) : (
                        'Save/Update'
                      )}
                      {' '}
                    </Button>
                  ) : (
                    <Button
                      type="Submit"
                      disabled
                      className="btn btn-save"
                    >
                      Save/Update

                    </Button>
                  )
                }
              </div>
            </div>
          </div>
        </div>

        <DatePopupModal
          datePopup={datePopup}
          setDatePopup={setDatePopup}
          handleDatePopup={handleDatePopup}
          selectedDate={selectedDate}
        />
      </div>
    </>
  );
};
InventoryAdjustment.propTypes = {
  updateView: PropTypes.func.isRequired,
};
export default InventoryAdjustment;

const DatePopupModal = (props) => {
  const {
    datePopup, setDatePopup, handleDatePopup, selectedDate,
  } = props;
  return (
    <Modal
      size="md"
      show={datePopup}
      onHide={() => setDatePopup(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="startdate-modal calendar-modal search-modal time-popup custom-time-popup"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="search-popup shift-container">
          <div className="staff-close">
            <i
              className="fa fa-times"
              onClick={() => setDatePopup(false)}
              aria-hidden="true"
            />
          </div>
          <div className="time-calender">
            <Calendar
              onChange={handleDatePopup}
              value={new Date(selectedDate)}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
DatePopupModal.propTypes = {
  datePopup: PropTypes.func.isRequired,
  setDatePopup: PropTypes.func.isRequired,
  handleDatePopup: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
};
