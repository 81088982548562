/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { limitWidthHeight } from './calculatorUtils';

const CalculatorMiddle = (props) => {
  const {
    poolInputs,
    handleInputs,
    result,
    serviceAnalysis,
    handlePoolDepth,
    poolDepth,
    waterPoolStatus,
    calVariable,
    validationData,
    customDepths,
    status,
    diameterInput,
    waterLossStatus,
    valueType,
    inverterResult,
  } = props;
  return (
    <>
      <Form.Group
        className={`pool-depth ${diameterInput ? 'diameter-input' : ''} ${customDepths ? 'customdepth-inputs' : ''}  ${(waterLossStatus || inverterResult) ? 'delete-icon' : ''}`}
        id="poolDepth"
        // controlId="exampleForm.SelectCustom"
      >
        {!result ? (
          <Form.Row>
            <Col column="xs" md={7} xs={5}>
              {!result ? <Form.Label>Pool Depth</Form.Label> : null}
            </Col>
            <Col>

              <>
                <div className={`select-box ${(waterLossStatus || inverterResult) ? 'remove-dropdown' : ''}`}>
                  <Form.Control
                    className={`${serviceAnalysis === 'Pool Water Volume Verification via Salinity' && waterPoolStatus ? 'waterPoolStatus check-waterVolume' : ''} ${waterPoolStatus ? 'waterPoolStatus' : ''}`}
                    as="select"
                    custom
                    onChange={handlePoolDepth}
                    value={poolDepth}
                    disabled={waterLossStatus || inverterResult}
                  >
                    <option value="Average Depth - Rectangular Pool">
                      Average Depth - Rectangular Pool
                    </option>
                    <option value="Average Depth - Circular pool">
                      Average Depth - Circular pool
                    </option>
                    <option value="Specific Depths - Rectangular Pool">
                      Specific Depths - Rectangular Pool
                    </option>
                    <option value="Specific Depths - Circular Pool">
                      Specific Depths - Circular Pool
                    </option>
                  </Form.Control>
                </div>
              </>

            </Col>
          </Form.Row>
        ) : null}

        <Form.Group
          className={`manage-pool ${result
        && 'manage-pool-setup'}`}
        >
          <Form.Row>
            <Col column="xs" md={7} xs={5}>
              {status ? (
                <Form.Label>
                  {`Pool ${
                    diameterInput
                      ? `Diameter(${valueType ? 'metres' : 'feet'})`
                      : result
                        ? `size, depth & ${valueType ? 'litres' : 'gallons'}`
                        : `Size (${valueType ? 'metres' : 'feet'})`
                  }`}
                </Form.Label>
              ) : null}
            </Col>

            <Col>
              <div className="pool-site-input">
                {result && status ? (
                  <div className="change-layout">
                    <div className="poolsize">
                      {diameterInput
                        ? poolInputs.diameter
                        : `${poolInputs.poolWidth} x ${poolInputs.poolHeight}`}
                    </div>
                    <>
                      <span>|</span>
                      <div className="depth-change">{customDepths ? `${poolInputs.shallowEnd} - ${poolInputs.deepEnd}` : 1.25}</div>
                    </>
                    <span>|</span>
                    <div className="change-litre">
                      {`${
                        !calVariable.poolMsg
                          ? `${poolInputs.poolCapacity
                            .toString()
                            .replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            )} ${valueType ? 'litres' : 'gallons'}`
                          : calVariable.poolMsg
                      }`}
                    </div>
                  </div>
                ) : null}
                {diameterInput && !result ? (
                  <Form.Control
                    type="text"
                    disabled={result}
                    value={
                  poolInputs.diameter === 0
                    ? ''
                    : poolInputs.diameter
                }
                    onChange={handleInputs}
                    name="diameter"
                    className={`${(waterLossStatus || inverterResult) ? 'waterVolume check-waterVolume' : ''} ${
                      validationData.diameter
                  && !poolInputs.diameter
                        ? 'redBorder'
                        : ''
                    }`}
                    placeholder={
                  validationData.diameter ? 'Enter Data' : ''
                }
                  />
                ) : !result ? (
                  <>
                    <NumberFormat
                      type="text"
                      name="poolWidth"
                      value={
                    poolInputs.poolWidth === 0
                      ? ''
                      : poolInputs.poolWidth
                  }
                      onChange={handleInputs}
                      disabled={result}
                      isAllowed={limitWidthHeight}
                      className={`form-control ${(waterLossStatus || inverterResult) ? 'waterVolume check-waterVolume' : ''} ${
                        validationData.poolWidth
                    && !poolInputs.poolWidth
                          ? 'redBorder'
                          : ''
                      }`}
                      placeholder={
                    validationData.poolWidth
                      ? 'Enter Data'
                      : ''
                  }
                    />
                    <span>X</span>
                    <NumberFormat
                      type="text"
                      name="poolHeight"
                      value={
                    poolInputs.poolHeight === 0
                      ? ''
                      : poolInputs.poolHeight
                  }
                      onChange={handleInputs}
                      isAllowed={limitWidthHeight}
                      disabled={result}
                      className={`form-control ${(waterLossStatus || inverterResult) ? 'waterVolume check-waterVolume' : ''} ${
                        validationData.poolHeight
                    && !poolInputs.poolHeight
                          ? 'redBorder'
                          : ''
                      }`}
                      placeholder={
                    validationData.poolHeight
                      ? 'Enter Data'
                      : ''
                  }
                    />
                  </>
                ) : null}
              </div>
            </Col>
          </Form.Row>
        </Form.Group>

        {customDepths && !result ? (
          <>
            <>
              <Form.Row>
                <Col column="xs" md={7} xs={5}>
                  <Form.Label>Shallow End Depth</Form.Label>
                </Col>
                <Col>
                  <Form.Group
                  // controlId="formBasicInput-6"
                    className="custom-input"
                  >
                    <Form.Control
                      type="text"
                      className={`spec-depth ${(waterLossStatus || inverterResult) ? 'waterVolume check-waterVolume' : ''} ${
                        validationData.shallowEnd
                  && !poolInputs.shallowEnd
                          ? 'redBorder'
                          : ''
                      }`}
                      name="shallowEnd"
                      value={
                  poolInputs.shallowEnd === 0
                    ? ''
                    : poolInputs.shallowEnd
                }
                      onChange={handleInputs}
                      disabled={result}
                      placeholder={
                  validationData.shallowEnd
                    ? 'Enter Data'
                    : ''
                }
                    />
                    <span className="mm">metres</span>
                  </Form.Group>
                </Col>
              </Form.Row>
            </>
            <>
              <Form.Row>
                <Col column="xs" md={7} xs={5}>
                  <Form.Label>Deep End Depth</Form.Label>
                </Col>
                <Col>
                  <Form.Group
                    // controlId="formBasicInput-6"
                    className="custom-input"
                  >
                    <Form.Control
                      type="text"
                      className={`spec-depth ${(waterLossStatus || inverterResult) ? 'waterVolume check-waterVolume' : ''} ${
                        validationData.deepEnd
                  && !poolInputs.deepEnd
                          ? 'redBorder'
                          : ''
                      }`}
                      name="deepEnd"
                      value={
                  poolInputs.deepEnd === 0
                    ? ''
                    : poolInputs.deepEnd
                }
                      onChange={handleInputs}
                      disabled={result}
                      placeholder={
                  validationData.deepEnd ? 'Enter Data' : ''
                }
                    />
                    <span className="mm">metres</span>
                  </Form.Group>
                </Col>
              </Form.Row>
            </>
          </>
        ) : null}
      </Form.Group>

      {!result ? (
        <Form.Group>
          <Form.Row>
            <Col>
              <Form.Label
                className={`liters ${
                  calVariable.poolMsg ? 'limit' : ''
                }`}
              >
                {`${
                  !calVariable.poolMsg
                    ? `${poolInputs.poolCapacity
                      .toString()
                      .replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ',',
                      )} ${valueType ? 'litres' : 'gallons'}`
                    : calVariable.poolMsg
                }`}
              </Form.Label>
            </Col>
          </Form.Row>
        </Form.Group>
      ) : null}
    </>

  );
};

CalculatorMiddle.propTypes = {
  poolInputs: PropTypes.objectOf(PropTypes.any).isRequired,
  handleInputs: PropTypes.func.isRequired,
  result: PropTypes.bool.isRequired,
  serviceAnalysis: PropTypes.string.isRequired,
  handlePoolDepth: PropTypes.func.isRequired,
  poolDepth: PropTypes.string.isRequired,
  waterPoolStatus: PropTypes.bool.isRequired,
  calVariable: PropTypes.objectOf(PropTypes.any).isRequired,
  validationData: PropTypes.objectOf(PropTypes.any).isRequired,
  customDepths: PropTypes.bool.isRequired,
  status: PropTypes.bool.isRequired,
  diameterInput: PropTypes.bool.isRequired,
  waterLossStatus: PropTypes.bool.isRequired,
  valueType: PropTypes.bool.isRequired,
  inverterResult: PropTypes.bool.isRequired,
};

export default CalculatorMiddle;
