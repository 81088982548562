import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import { onKeyDownNumberWithDecimcal, removeSfromString } from '../../functions';

function AddProductOrderDetails({
  show, handleClose, poolSetUpAllChemicals, foreCastPeriod, itemVolume, handleSelectNames, productDetails, handleItemVolume, handleOrderQuantityChange, handleAddPredictiveChemical,
}) {
  const { type, renderName } = productDetails || {};
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => handleClose(true)}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal addClient-modal add-predictive-product"
    >
      <div className="staff-close">
        <i className="fa fa-times" onClick={() => handleClose(true)} aria-hidden="true" />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {type === 'edit' ? 'Edit' : 'Add'}
          {' '}
          Additional Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          {/* {productDetails.map((productDetails, index) => ( */}
          <Form>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicCompanyType" className="italic-text">
                  <Form.Label>
                    Product Name
                  </Form.Label>
                  <div className="select-box">
                    {/* <i className="fas fa-sort-down" /> */}
                    <Form.Control
                      as="select"
                      name="customerType"
                      onChange={(e) => handleSelectNames(e)}
                      value={productDetails?._id}
                      disabled={type === 'edit'}
                    >
                      <option value="">{type === 'edit' ? renderName : 'Select'}</option>
                      {poolSetUpAllChemicals?.length > 0 && poolSetUpAllChemicals.map((el) => <option key={el._id} value={el._id}>{el.renderName}</option>)}
                    </Form.Control>
                  </div>
                </Form.Group>

              </div>

              <div className="col-md-6 col-6 half">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Order Quantity</Form.Label>

                  <Form.Control
                    type="text"
                    name="orderQuantity"
                    value={productDetails?.orderQuantity}
                    onChange={(e) => handleOrderQuantityChange(e)}
                    onKeyDown={onKeyDownNumberWithDecimcal}
                    maxLength={8}
                  />

                </Form.Group>
              </div>

              <div className="col-md-6 col-6">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Item Volume</Form.Label>
                  <div className="select-box">
                    {/* <i className="fas fa-sort-down" /> */}
                    <Form.Control
                      as="select"
                      name="invoiceSendType"
                      onChange={(e) => handleItemVolume(e)}
                      value={productDetails?.itemVolume}
                    >
                      <option value="">Select</option>
                      {itemVolume?.length > 0 && itemVolume.map((el) => (
                        <option value={el.value}>
                          {el.renderValue}
                          {' '}
                          {el.unit}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6 col-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>
                    {`Unit Price ${productDetails?.displaySelectUnit && !productDetails?.displaySelectUnit.includes('undefined') ? `(${removeSfromString(productDetails?.displaySelectUnit)})` : ''}`}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="postcode"
                    value={productDetails?.selectedItemCost}
                    disabled
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 col-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Total Cost</Form.Label>
                  <Form.Control
                    type="text"
                    name="postcode"
                    value={productDetails?.overAllCost}
                    disabled
                  />
                </Form.Group>
              </div>

              <div className="col-md-12">
                <div className="view-btn">
                  <Button
                    type="button"
                    className="btn btn-edit"
                    onClick={() => handleClose(true)}
                  >
                    {' '}
                    Cancel
                    {' '}
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-save"
                    onClick={() => handleAddPredictiveChemical()}
                  >
                    {' '}
                    Save/Update
                    {' '}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
          {/* ))} */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
AddProductOrderDetails.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  poolSetUpAllChemicals: PropTypes.arrayOf.isRequired,
  foreCastPeriod: PropTypes.arrayOf.isRequired,
  itemVolume: PropTypes.arrayOf.isRequired,
  handleSelectNames: PropTypes.func.isRequired,
  productDetails: PropTypes.objectOf.isRequired,
  handleItemVolume: PropTypes.func.isRequired,
  handleOrderQuantityChange: PropTypes.func.isRequired,
  handleAddPredictiveChemical: PropTypes.func.isRequired,
};

export default AddProductOrderDetails;
