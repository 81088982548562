/* eslint-disable import/no-unresolved */
import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const DatePopupModal = (props) => {
  const {
    datePopup, setDatePopup, handleDatePopup, quoteDate,
  } = props;
  return (
    <Modal
      size="md"
      show={datePopup}
      onHide={() => setDatePopup(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="startdate-modal calendar-modal search-modal time-popup custom-time-popup"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="search-popup shift-container">
          <div className="staff-close">
            <i
              className="fa fa-times"
              onClick={() => setDatePopup(false)}
              aria-hidden="true"
            />
          </div>
          <div className="time-calender">
            <Calendar onChange={handleDatePopup} value={new Date(quoteDate)} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
DatePopupModal.propTypes = {
  datePopup: PropTypes.func.isRequired,
  setDatePopup: PropTypes.func.isRequired,
  handleDatePopup: PropTypes.func.isRequired,
  quoteDate: PropTypes.string.isRequired,
};
export default DatePopupModal;
