import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import PropTypes from 'prop-types';
import moment from '../../functions/moment';
import { userInstance } from '../../config/axios';
import arrow from '../../assets/home/arrowdown.png';

import { showErrMsg } from '../../functions/index';

import DatePopup from './datePopup';
import PreviewQuoteSecond from './previewQuotesSecond';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';

const SearchQuote = ({ selectedClient, quoteType }) => {
  const [quoteFrom, setQuoteFrom] = useState(null);
  const [quoteTo, setQuoteTo] = useState(null);
  const [quoteStart, setQuoteStart] = useState('dd-mm-yyyy');
  const [quoteEnd, setQuoteEnd] = useState('dd-mm-yyyy');
  const [quoteTotalFrom, setQuoteTotalFrom] = useState(null);
  const [quoteTotalTo, setQuoteTotalTo] = useState(null);
  const [description, setDescription] = useState(null);
  const [list, setList] = useState([]);
  const [datePopup, setDatePopup] = useState();
  const [type, setType] = useState();
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('D MMM YYYY'));
  const [showQuote, setShowQuote] = useState(null);
  const [preview, setPreview] = useState();
  const [success, setSuccess] = useState(false);

  const SearchQuotes = async () => {
    try {
      if (selectedClient) {
        const payload = {
          quoteFrom,
          quoteTo,
          quoteStart: moment(quoteStart).toString(),
          quoteEnd: moment(quoteEnd).toString(),
          quoteTotalFrom,
          quoteTotalTo,
          selectedClient,
          msg: quoteType,
          description,
        };
        const response = await userInstance().post('/quotes/searchQuotes', payload);
        const { quoteData } = response.data;
        setList(quoteData);
      } else {
        NotificationManager.error('Please select client', 'Message', errorMsgTime);
      }
    } catch (error) {
      console.log(error);
      //     console.log(error);
    }
  };

  const handlePreview = (data) => {
    setShowQuote(data);
    setPreview(!preview);
  };

  const isValidDate = (d) => d instanceof Date && !Number.isNaN(d);
  const handleDatePopup = (e) => {
    if (isValidDate(e)) {
      const date = moment(e).format('D MMM YYYY');
      if (type === 'quoteStart') {
        setQuoteStart(date);
      } else {
        setQuoteEnd(date);
      }
    } else {
      setType(e);
    }
    setDatePopup(!datePopup);
  };

  return (
    <div className="search-quote-listing invoices-list ">
      <div className="pool-chemistry-box appointment-service-modal">
        {/* <Form.Group controlId="formBasicHiddenEmployees">
        <Form.Label>Search Unpaid, Paid or All Quotes</Form.Label>
        <div className="select-box">
          <i className="fas fa-sort-down" />
          <Form.Control
            as="select"
            name="pricingType"
          >
            <option value="pending">Search Pending Quotes</option>
            <option value="saved">Search Saved Quotes</option>
            <option value="all">Search All Quotes</option>
          </Form.Control>
        </div>
      </Form.Group> */}
        <div className="search-invoces-box">
          <h6>Search Criteria</h6>
          <div className="row">
            <div className="col-md-6 col-6">
              <Form.Group className="search-box">
                <Form.Label>Quote No. (From)</Form.Label>
                <Form.Control type="text" placeholder="" value={quoteFrom} onChange={(e) => setQuoteFrom(e.target.value)} />
              </Form.Group>
            </div>
            <div className="col-md-6 col-6">
              <Form.Group className="search-box">
                <Form.Label>Quote No. (to)</Form.Label>
                <Form.Control type="text" placeholder="" value={quoteTo} onChange={(e) => setQuoteTo(e.target.value)} />
              </Form.Group>
            </div>
            <div className="col-md-6 col-6">
              <Form.Group className="search-box">
                <Form.Label>Quote Date (From)</Form.Label>
                {/* {/ <Form.Control type="date" placeholder="" value={invoiceStart} onChange={(e) => setInvoiceStart(e.target.value)} /> /} */}
                <div className="custom-datepicker" onClick={() => handleDatePopup('quoteStart')}>{quoteStart}</div>
              </Form.Group>
            </div>
            <div className="col-md-6 col-6">
              <Form.Group className="search-box">
                <Form.Label>Quote Date (to)</Form.Label>
                {/* {/ <Form.Control type="date" placeholder="" value={invoiceEnd} onChange={(e) => setInvoiceEnd(e.target.value)} /> /} */}
                <div className="custom-datepicker" onClick={() => handleDatePopup('quoteEnd')}>{quoteEnd}</div>
              </Form.Group>
            </div>
            <div className="col-md-12 col-12">
              <Form.Group className="search-box">
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" placeholder="" value={description} onChange={(e) => setDescription(e.target.value)} />
              </Form.Group>
            </div>
            <div className="col-md-6 col-6">
              <Form.Group className="search-box">
                <Form.Label>Quote Total (From)</Form.Label>
                <Form.Control type="number" placeholder="" value={quoteTotalFrom} onChange={(e) => setQuoteTotalFrom(e.target.value)} />
              </Form.Group>
            </div>
            <div className="col-md-6 col-6">
              <Form.Group className="search-box">
                <Form.Label>Quote Total (to)</Form.Label>
                <Form.Control type="number" placeholder="" value={quoteTotalTo} onChange={(e) => setQuoteTotalTo(e.target.value)} />
              </Form.Group>
            </div>
          </div>
          <p className="group-time">* Less fields filled-in will broaden the search.</p>
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn search-invoice-btn">
                <Button
                  className="btn-save"
                  onClick={() => SearchQuotes()}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
        {list && list.length > 0 ? (
          <>
            {list && list.map((el) => (
              <div className="table-section">
                <div className="invoices-table-left">
                  <div className="invoices-head">
                    <h6 className={`quotes-status ${el.status}`}>{moment(el.quoteDate).format('D')}</h6>
                    <h6>Quote No.</h6>
                    <h6>Description</h6>
                    <h6>Amount</h6>
                  </div>
                  <div className="invoice-body">
                    <p className={`quotes-status ${el.status}`}>
                      <h6>{moment(el.quoteDate).format('MMM')}</h6>
                      {' '}
                      {moment(el.quoteDate).format('YYYY')}
                    </p>
                    <p>{el.quoteNo}</p>
                    {el.services && el.services.map((service) => (
                      <p>
                        {service.description}
                      </p>
                    ))}
                    <p>
                      $
                      {el.quotesTotal}
                    </p>
                  </div>
                </div>
                <div className="invoices-table-right" onClick={() => handlePreview(el)}>
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
            ))}
          </>
        ) : (
          <p className="table-section">No Quotes Found</p>
        )}
        <DatePopup datePopup={datePopup} setDatePopup={setDatePopup} handleDatePopup={handleDatePopup} quoteDate={selectedDate} />
      </div>
      {showQuote ? (
        <PreviewQuoteSecond
          showquote={preview}
          handleQuote={handlePreview}
          addClient={showQuote.client}
          addedServices={showQuote.services}
          quoteDate={showQuote.quoteDate}
          summary={showQuote.totalData}
          quotesTotal={showQuote.quotesTotal}
          gst={showQuote.gst}
          quoteNo={showQuote.quoteNo}
          previewURL={showQuote?.previewUrl}
          setSuccess={setSuccess}
          type="search"

        />
      ) : null}
    </div>
  );
};
SearchQuote.propTypes = {
  selectedClient: PropTypes.string.isRequired,
  quoteType: PropTypes.string.isRequired,
};
export default SearchQuote;
