import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const WaterLossCalculator = (props) => {
  const {
    waterLossStatus, waterPoolStatus, waterLossCalculator, handleWaterLossCalculator, poolInputs, handleInputs, result, valueType,
  } = props;
  return (
    <>
      <Form.Group controlId="exampleForm.SelectCustom" className={` ${waterLossStatus ? 'delete-icon' : ''}`}>
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Water Loss Time Period</Form.Label>
          </Col>
          <Col>
            <div className={`select-box ${waterLossStatus ? 'remove-dropdown' : ''}`}>
              <Form.Control
                className={`${waterPoolStatus ? 'waterPoolStatus' : ''}`}
                as="select"
                custom
                value={waterLossCalculator}
                onChange={handleWaterLossCalculator}
                disabled={waterLossStatus}
              >
                <option value="Water Loss Per Day">
                  Water Loss Per Day
                </option>
                <option value="Water Loss Per Week">
                  Water Loss Per Week
                </option>

              </Form.Control>
            </div>
          </Col>
        </Form.Row>
      </Form.Group>

      <>
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Amount of Water Lost</Form.Label>
          </Col>
          <Col>
            <Form.Group controlId="formBasicInput-6" className="custom-input per-day">
              <span className="cholinator-text">-</span>
              {/* <Form.Control type="number" name="okNegative" /> */}
              <Form.Control
                type="text"
                name="amountOfWaterLost"
                className={`form-control spec-depth ${waterLossStatus ? 'waterVolume check-waterVolume' : ''}`}
                maxLength="4"
                value={poolInputs.amountOfWaterLost}
                disabled={result}
                onChange={handleInputs}
              />
              <span className="mm">{`${waterLossCalculator === 'Water Loss Per Day' ? `${valueType ? 'mm' : 'inches'} per day` : `${valueType ? 'mm' : 'inches'} per week`}`}</span>
            </Form.Group>
          </Col>
        </Form.Row>
      </>
    </>
  );
};

WaterLossCalculator.propTypes = {
  waterLossStatus: PropTypes.bool.isRequired,
  waterPoolStatus: PropTypes.bool.isRequired,
  waterLossCalculator: PropTypes.string.isRequired,
  handleWaterLossCalculator: PropTypes.func.isRequired,
  poolInputs: PropTypes.func.isRequired,
  handleInputs: PropTypes.func.isRequired,
  result: PropTypes.bool.isRequired,
  valueType: PropTypes.bool.isRequired,
};

export default WaterLossCalculator;
