import React from 'react';
import { Button } from 'react-bootstrap';
import './home.css';
import Helmet from 'react-helmet';
import Slider from './slider';
import glogo from '../../assets/home/Guarantee-icon-Blue.png';
import img1 from '../../assets/home/pH.jpg';
import img2 from '../../assets/home/baking-soda-water-1.jpg';
import Layout from '../layout/index';
import logowhite from '../../assets/home/pool-logo-white.png';
import AdminWrapper from '../admin/adminwrapper/wrapper';

const Home = () => (
  <>
    <Helmet>
      <html
        className="calendar-main-page home-main-page"
        lang="en"
      />
    </Helmet>
    <AdminWrapper>
      <div>
        <div className="banner-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                <div className="banner-content">
                  <div className="banner-logo">
                    <img src={logowhite} alt="Logo" />
                  </div>
                  <h1>Now you can accurately test your pool water at home....</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="content-container home-block-container">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="block-content">
                  <h3>Know the exact amount of chemicals to add to your pool!</h3>
                  <p>
                    At Pool-Tester we&lsquove developed software that enables you to be the water
                    expert, without the need to visit your local pool shop or having to pay for a
                    mobile service person to visit your home.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="block-btn">
                  <a href="#/">Monthly or annual access options</a>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="block-btn">
                  <a href="#/">View Online Demo</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-container cb-container p-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="cb-box">
                  <div className="cb-btn">
                    <a href="#/"> solve your water balancing problems! </a>
                  </div>
                  <div className="cb-img">
                    <img src={img1} alt="img-text" />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="cb-box">
                  <div className="cb-img">
                    <img src={img2} alt="img-text" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-container service-container">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="service-heading">
                  <h3>We Service!</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="service-box">
                  <a href="#/"> Know how each camical affetc your pool </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="service-box">
                  <a href="#/"> Automatically keep records of all past water tests </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="service-box">
                  <a href="#/"> Fix a green pool  yourself</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-container testimonial-container">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="testimonial-slider">
                  <Slider />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-container sati-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="sati-logo">
                  <img src={glogo} alt="Garentee logo" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-container cta-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="cta-box">
                  <h4>Order Your Monthly or Anuual Subscription Now</h4>
                  <div className="cta-btn ml-auto">
                    <Button className="btn btn-primary"> Order now </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AdminWrapper>
  </>
);

export default Home;
