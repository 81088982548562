import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaRegEdit, FaBuysellads } from 'react-icons/fa';
import { FaTrashCan } from 'react-icons/fa6';
import AddButton from '../components/addButtons';
import AddAdvertisementModal from './addAdvertisementModal';
import RemoveModal from './removeModal';
import toastMessage from '../../../functions/toastMessage';
import { userInstance } from '../../../config/axios';

const DataCard = (props) => {
  const {
    title,
    handleEditBtn,
    handleRemoveBtn,
  } = props;
  const isMouseOver = false;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '4px 8px',
      }}
    >
      <div className="d-flex gap-2 align-items-center">
        <FaBuysellads size={18} />
        <span style={{ fontSize: '14px' }}>
          {title}
        </span>
      </div>
      <div className="d-flex align-items-center">
        <button
          type="button"
          onClick={handleEditBtn}
          style={{ background: 'transparent', border: 'none' }}
        >
          {!isMouseOver && <FaRegEdit size={16} />}
        </button>
        <button
          type="button"
          onClick={handleRemoveBtn}
          style={{ background: 'transparent', border: 'none' }}
        >
          {!isMouseOver && <FaTrashCan size={16} />}
        </button>
      </div>
    </div>
  );
};

DataCard.propTypes = {
  title: PropTypes.string.isRequired,
  handleEditBtn: PropTypes.func.isRequired,
  handleRemoveBtn: PropTypes.func.isRequired,
};

const BlankDataCard = () => {
  const emptyContent = 'No Data';
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '4px 8px',
      }}
    >
      <span style={{ fontSize: '14px' }}>
        {emptyContent}
      </span>
    </div>
  );
};

const Advertisement = (props) => {
  const {
    advertisements,
    setAdvertisements,
    getAdvertiesments,
  } = props;
  const [show, setShow] = useState(false);
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleRemoveModalClose = () => setRemoveModalShow(false);
  const handleRemoveModalShow = (data) => {
    setSelectedItem(data);
    setRemoveModalShow(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteItem = async (id) => {
    try {
      setIsRemoving(true);
      let response = await userInstance().delete(`/chatbotSettings/deleteAdvertisement?id=${id}`);
      const { msg } = response.data;
      if (response.status === 200) {
        let _advertisements = advertisements.slice();
        let findIndex = _advertisements.findIndex((item) => item._id === id);
        if (findIndex !== -1) {
          _advertisements.splice(findIndex, 1);
          setAdvertisements(_advertisements);
          handleRemoveModalClose();
          toastMessage('success', msg);
        } else {
          toastMessage('error', 'There is no uploaded file with this id.');
        }
      } else {
        toastMessage('error', msg);
      }
    } catch (error) {
      console.log('error', error);
    }
    setIsRemoving(false);
  };

  const handleEditAdvertiesment = (data) => {
    setSelectedItem(data);
    setIsEditMode(true);
    handleShow();
  };

  const handleAddAdvertiesment = () => {
    setSelectedItem(null);
    setIsEditMode(false);
    handleShow();
  };

  return (
    <>
      <div className="chatbot-settings-form-sub-container p-4">
        <div>
          <span className="chatbot-settings-form-sub-title">Advertisement</span>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div>
            <span>Contents List</span>
          </div>
          <AddButton handleClickBtn={handleAddAdvertiesment} />
        </div>
        <div
          className="mt-2"
          style={{
            height: '450px',
            overflowX: 'hidden',
            overflowY: advertisements.length > 0 ? 'scroll' : 'hidden',
            paddingRight: advertisements.length > 0 ? '8px' : 0,
          }}
        >
          <div className="d-flex flex-column gap-2">
            {
              advertisements.length > 0 ? advertisements.map((item) => (
                <DataCard
                  key={item._id}
                  title={item.title}
                  handleEditBtn={() => handleEditAdvertiesment(item)}
                  handleRemoveBtn={() => handleRemoveModalShow(item)}
                />
              )) : (
                <BlankDataCard />
              )
            }
          </div>
        </div>
      </div>
      <AddAdvertisementModal show={show} handleClose={handleClose} advertisements={advertisements} setAdvertisements={setAdvertisements} getAdvertiesments={getAdvertiesments} selectedItem={selectedItem} isEditMode={isEditMode} />
      <RemoveModal show={removeModalShow} handleClose={handleRemoveModalClose} title="Are you sure to remove this advertisement?" icon={<FaBuysellads size={18} />} selectedItem={selectedItem} handleDeleteItem={handleDeleteItem} isRemoving={isRemoving} />
    </>
  );
};

Advertisement.propTypes = {
  advertisements: PropTypes.arrayOf.isRequired,
  setAdvertisements: PropTypes.func.isRequired,
  getAdvertiesments: PropTypes.func.isRequired,
};

export default Advertisement;
