/* eslint-disable import/no-unresolved */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const StartDate = (props) => {
  const {
    datePopup, setDatePopup, handleDatePopup, dateCalendar, handleDateOnchange, handleSave,
  } = props;
  return (
    <Modal
      size="md"
      show={datePopup}
      onHide={() => setDatePopup(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="startdate-modal calendar-modal search-modal time-popup custom-time-popup"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="search-popup shift-container startend-popup">
          <div className="staff-close"><i className="fa fa-times" aria-hidden="true" onClick={() => setDatePopup(false)} /></div>
          <div className="time-calender">
            {/* <h6>Date hh</h6> */}
            <Calendar value={dateCalendar ? new Date(dateCalendar) : new Date()} onChange={(e) => handleDateOnchange(e)} />
            {/* <h6 className="end-date">End Date</h6>  */}
            {/* <Calendar onChange={(e) => handleDateOnchange(e, 'end')} value={dateRange ? new Date(dateRange.end) : new Date()} /> */}
            {/* <div className="view-btn">
              <Button
                type="Submit"
                className="btn btn-edit"
                onClick={handleDatePopup}
              >
                {' '}
                Cancel
                {' '}
              </Button>
              <Button
                type="Submit"
                className="btn btn-save"
                onClick={handleSave}
              >
                {' '}
                Save/Update
                {' '}
              </Button>
            </div> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
StartDate.propTypes = {
  datePopup: PropTypes.func.isRequired,
  setDatePopup: PropTypes.func.isRequired,
  handleDatePopup: PropTypes.func.isRequired,
  dateCalendar: PropTypes.func.isRequired,
  handleDateOnchange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
//   dateRange: PropTypes.func.isRequired,
//   handleDateRange: PropTypes.func.isRequired,
};
export default StartDate;
