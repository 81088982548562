import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import arrow from '../../assets/home/arrowdown.png';
import ReportsAppointments from './appoinments';

const ReportsListing = ({ updateView, setLoderStatus }) => (
  <>
    <Card>
      <div className="reports-heading" onClick={() => updateView('Scheduled Maintenance')}>
        Scheduled Maintenance
        <div className="arrow-img">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </Card>
    {/* <Card>
      <div className="reports-heading" onClick={() => updateView('Customer Payments')}>
        Customer Payments
        <div className="arrow-img">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </Card> */}
    {/* <Card>
      <div className="reports-heading" onClick={() => updateView('Debtors & Creditors')}>
        Debtors & Creditors
        <div className="arrow-img">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </Card> */}
    {/* <Card>
      <div className="reports-heading" onClick={() => updateView('Inventory')}>
        Inventory
        <div className="arrow-img">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </Card> */}
    {/* <Card>
      <div className="reports-heading" onClick={() => updateView('Sales')}>
        Sales
        <div className="arrow-img">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </Card> */}
    <Card>
      <div className="reports-heading" onClick={() => updateView('Purchases')}>
        Purchases
        <div className="arrow-img">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </Card>
    {/* <Card>
      <div className="reports-heading" onClick={() => updateView('Transactions')}>
        Transactions  Audit
        <div className="arrow-img">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </Card> */}
  </>
);
ReportsListing.propTypes = {
  updateView: PropTypes.func.isRequired,
  setLoderStatus: PropTypes.func.isRequired,
};
export default ReportsListing;
