/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-quotes */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown, Form, Button } from 'react-bootstrap';
import moment from '../../functions/moment';
// import chatbot from '../../assets/home/robot.jpg';
// import Loader from '../loader/loader';
import showmore from '../../assets/home/showmoreblue.png';
import target from '../../assets/home/icon/target.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import './index.css';

const BillingPopup = ({ handleAction }) => {
  const { userData } = useContext(AuthDataContext);

  const { userInformation } = userData || {};

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleAction('');
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  // console.log('userInformation ==>', {
  //   userInformation,
  // });
  console.log(userInformation, 'subscriberdata');
  return (
    <div className='duration-picker time-picker-modal chat-bot-page billing-popup'>
      <div>
        <div
          className='time-keeper-popup duration-popup newContent_popup'
          ref={wrapperRef}
        >
          <div className='inner-content '>
            <div
              className='back-popup cross-popup'
              onClick={() => handleAction()}
            >
              <i className='las la-times' />
            </div>
            <div className='maincontent'>
              <div className='main-titles-mob'>
                <h3>PoolWater-Pro Billing</h3>
                <div className='content-dropdown'>
                  <div className='client-option service-edit-icon'>
                    <Dropdown>
                      <Dropdown.Toggle variant='success' id='dropdown-basic'>
                        <img src={showmore} alt='show' />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div
                            className='action'
                            onClick={() =>
                              handleAction('Calculator-Pro Billing')
                            }
                          >
                            <span>
                              <img src={target} alt='edit' />
                            </span>
                            <span>Current Subscription</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className='action'
                            onClick={() => handleAction('Edit My Details')}
                          >
                            <span>
                              <img src={target} alt='edit' />
                            </span>
                            <span>Edit My Details</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className='action'
                            onClick={() =>
                              handleAction('Change Billing Details')
                            }
                          >
                            <span>
                              <img src={target} alt='edit' />
                            </span>
                            <span>Change Billing Details</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className='action'
                            onClick={() => handleAction('Update Subscription')}
                          >
                            <span>
                              <img src={target} alt='edit' />
                            </span>
                            <span>Update Subscription</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className='action'
                          // onClick={() => setAction('Update Subscription')}
                          >
                            <span>
                              <img src={target} alt='edit' />
                            </span>
                            <span>Cancel Subscription</span>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className='target-heading'>
                <h6>Current Subscription</h6>
              </div>
              <div className='billing-content'>
                <h4>My Details</h4>
                <ul className='my-details-section'>
                  <li>
                    <h3>Country</h3>
                    <h5>{userInformation?.country}</h5>
                  </li>
                  <li>
                    <h3>First Name</h3>
                    <h5>{userInformation?.firstname}</h5>
                  </li>

                  <li>
                    <h3>Last Name</h3>
                    <h5>{userInformation?.lastname}</h5>
                  </li>

                  <li>
                    <h3>Mobile No</h3>
                    <h5>{userInformation?.mobileNo}</h5>
                  </li>

                  <li>
                    <h3>Landline No</h3>
                    <h5>{userInformation?.landlineNo || '- - -'}</h5>
                  </li>

                  <li>
                    <h3>Email Address</h3>
                    <h5>{userInformation?.email}</h5>
                  </li>

                  <li>
                    <h3>Street Address</h3>
                    <h5>{userInformation?.streetAddress}</h5>
                  </li>

                  <li>
                    <h3>City/Town/Suburb</h3>
                    <h5>{userInformation?.suburb}</h5>
                  </li>

                  <li>
                    <h3>Postal / Zip Code</h3>
                    <h5>{userInformation?.postcode}</h5>
                  </li>

                  <li>
                    <h3>State</h3>
                    <h5>{userInformation?.state}</h5>
                  </li>

                  <li>
                    <h3>Gender</h3>
                    <h5>{userInformation?.gender}</h5>
                  </li>
                </ul>
                <h4>Subscription Selections</h4>
                <ul className='my-details-section'>
                  <li>
                    <h3>Subscription Duration:</h3>
                    <h5>
                      {userInformation?.duration
                        ? `${userInformation?.duration} Months`
                        : '10 Day Trial'}
                    </h5>
                  </li>
                  <li>
                    <h3>Start Date:</h3>
                    <h5>
                      {moment(userInformation?.startsubscriptionDate).format(
                        'D MMM YYYY'
                      )}
                    </h5>
                  </li>
                  <li>
                    <h3>End Date:</h3>
                    <h5>
                      {moment(userInformation?.endsubscriptionDate).format(
                        'D MMM YYYY'
                      )}
                    </h5>
                  </li>
                  <li>
                    <h3>No. of Pools:</h3>
                    <h5>{userInformation?.numberOfPools || 0}</h5>
                  </li>
                  <li>
                    <h3>No. of Users:</h3>
                    <h5>{userInformation?.numberUsers || 0}</h5>
                  </li>
                </ul>

                <div className='sub-total'>
                  <h3>
                    {userInformation?.duration
                      ? `${userInformation?.duration} Month Total:`
                      : 'Month Total:'}{' '}
                  </h3>
                  <h5>${userInformation?.totalCost || 0}</h5>
                </div>
                <div className='bottom-content'>
                  <p>
                    Your PoolWater-Pro subscription is set to auto-renew on{' '}
                    {moment(userInformation?.endsubscriptionDate).format(
                      'MMM D, YYYY'
                    )}{' '}
                    and every{' '}
                    {userInformation?.duration
                      ? `${userInformation?.duration}`
                      : ''}{' '}
                    months{' '}
                    <span>(based on your current subscription selection).</span>
                  </p>
                  <p>
                    Please note there is a one month notice period required for
                    cancellation. Please contact{' '}
                    <a href='mailto: service@poolwater-pro.com?subject=CONTACT from PoolWater-Pro'>
                      service@poolwater-pro.com
                    </a>{' '}
                    if you need further assistance.
                  </p>
                </div>
              </div>
              <div className='view-btn billing-btn'>
                <Button
                  type='button'
                  onClick={() => handleAction()}
                  className='btn btn btn-primary btnWhite'
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
BillingPopup.propTypes = {
  handleAction: PropTypes.func.isRequired,
};
export default BillingPopup;
