import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import './temp.css';

const TemperaturePopup = ({
  handleTempPopup, temPopup, setTempPopup, tempValue, handleTempOption, handleTemperature, temperature, handleAfterTemperature, exactTemp, valueType, tempBlank, displayAddress,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal update-source-popup') {
            setTempPopup(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  // console.log('staffLocation', { staffLocation });
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="duration-picker time-picker-modal update-source-popup temp-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup location-change-popup" ref={wrapperRef}>
          <div className="appointment-view">
            <div className="temp-heading">
              <h4>Temperature of Pool Water</h4>
              <p className="italic-text">
                {temperature}
                &deg;
                {' '}
                <span style={{ marginRight: '3px' }}> C</span>
                {' '}
                {' '}
                {' '}
                last 5 days average for
                {' '}
                {displayAddress}
              </p>
            </div>
            <div className="pending-water-test">
              <h3>
                Use 5 Day Average Temperature
              </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="fiveDays"
                    onChange={handleTempOption}
                    checked={tempValue === 'fiveDays'}
                  />
                </div>
              </div>
            </div>
            <div className="pending-water-test">
              <h3>
                Enter Exact Pool Temperature
              </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="exactDay"
                    onChange={handleTempOption}
                    checked={tempValue === 'exactDay'}
                  />
                </div>
              </div>
            </div>
            {tempValue === 'exactDay' ? (
              <div className="temp-input">
                <Form.Group controlId="formBasicInput-9" className="italic-text">
                  <Form.Control
                    type="number"
                    value={exactTemp}
                    onChange={handleTemperature}
                    className={tempBlank === 'exactDay' ? 'error-text' : ''}
                  />
                  <span>
                    &deg;
                    C
                  </span>
                </Form.Group>
              </div>
            ) : null}
            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={handleTempPopup}>
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={handleAfterTemperature}>
                Select
              </Button>
            </div>

          </div>

        </div>
      </div>
      {/* <DateModal blockedtime={blockedtime} setBlockedtime={setBlockedtime} /> */}
    </div>

  );
};
TemperaturePopup.propTypes = {
  temPopup: PropTypes.bool.isRequired,
  setTempPopup: PropTypes.func.isRequired,
  handleTempPopup: PropTypes.func.isRequired,
  handleTempOption: PropTypes.func.isRequired,
  tempValue: PropTypes.string.isRequired,
  handleTemperature: PropTypes.func.isRequired,
  temperature: PropTypes.string.isRequired,
  handleAfterTemperature: PropTypes.func.isRequired,
  exactTemp: PropTypes.string.isRequired,
  valueType: PropTypes.bool.isRequired,
  tempBlank: PropTypes.string.isRequired,
  displayAddress: PropTypes.string.isRequired,
};
export default TemperaturePopup;
