/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useEffect, useRef, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';
import showmore from '../../assets/home/showmoreblue.png';
import target from '../../assets/home/icon/target.png';
import './index.css';

import { ocrPath } from '../../config/keys';

const EditMyDetails = ({
  handleAction, handleChange, selectedCountry, handleCountry, countries, stateArray, user, handleEditSubscriber, error,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleAction('');
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="duration-picker time-picker-modal chat-bot-page billing-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup newContent_popup" ref={wrapperRef}>
          <div className="inner-content">
            <div className="back-popup" onClick={() => handleAction()}>
              <i className="las la-times" />
            </div>
            <div className="maincontent">

              <div className="main-titles-mob">
                <h3>PoolWater-Pro  Billing</h3>
                <div className="content-dropdown">
                  <div className="client-option service-edit-icon">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={showmore} alt="show" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div
                            className="action"
                            onClick={() => handleAction('Calculator-Pro Billing')}
                          >
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Current Subscription</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className="action"
                            onClick={() => handleAction('Edit My Details')}
                          >
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Edit My Details</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className="action"
                            onClick={() => handleAction('Change Billing Details')}
                          >
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Change Billing Details</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className="action"
                            onClick={() => handleAction('Update Subscription')}
                          >
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Update Subscription</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className="action"
                            // onClick={() => setAction('Update Subscription')}
                          >
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Cancel Subscription</span>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="target-heading">
                <h6>Edit My Details</h6>
              </div>
              <div className="edit-my-details-content billing-content">
                <div className="staff-form">
                  <h6>My Details</h6>
                  <Form onSubmit={(e) => { e.preventDefault(); }}>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicLocation">
                          <Form.Label>
                            Country
                            {console.log('countries', countries)}
                            {' '}
                            {/* <span className="required-field">*</span> */}
                          </Form.Label>
                          <div className="select-box">
                            <Form.Control
                              as="select"
                              name="country"
                              onChange={handleChange}
                              value={user?.country}
                              className={`${
                                error === 'country'
                                  ? 'error-text'
                                  : ''
                              }`}
                            >
                              {countries?.length > 0 && countries.map((el) => <option value={el.name}>{el.name}</option>)}
                            </Form.Control>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicInput-1" className="italic-text">
                          <Form.Label>
                            First name
                            {/* <span className="required-field">*</span> */}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            value={user?.firstname}
                            onChange={handleChange}
                            placeholder="Enter First Name"
                            className={`${
                              error === 'firstname'
                                ? 'error-text'
                                : ''
                            }`}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicInput-2" className="italic-text">
                          <Form.Label>
                            Last name
                            {/* <span className="required-field">*</span> */}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="lastname"
                            value={user?.lastname}
                            onChange={handleChange}
                            placeholder="Enter Last Name"
                            className={`${
                              error === 'lastname'
                                ? 'error-text'
                                : ''
                            }`}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicInput-3" className="number italic-text">
                          <Form.Label>
                            Mobile No.
                            {/* <span className="required-field">*</span> */}
                          </Form.Label>
                          <div className="number-field">
                            {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                            <div className="number-box">
                              <Dropdown id="mobile">
                                <Dropdown.Toggle variant="success" id="dropdown-basic1">
                                  <div className="flag-icon">
                                    <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                                  </div>
                                  {selectedCountry === '+61' ? (
                                    <span>
                                      Australia
                                      {' '}
                                      {selectedCountry}
                                    </span>
                                  ) : selectedCountry === '+1' ? (
                                    <span>
                                      United States
                                      {' '}
                                      {selectedCountry}
                                    </span>
                                  ) : ''}
                                  {/* <span><i className="fas fa-caret-down" /></span> */}
                                  {/* <div className="country-code">{selectedCountry}</div> */}
                                </Dropdown.Toggle>

                                {/* <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                                    <div className="flag-icon">
                                      <img src={flag} alt="flag" />
                                    </div>
                                    <div className="country-code">
                                      <span>Australia</span>
                                      +61
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                                    <div className="flag-icon">
                                      <img src={us} alt="flag" />
                                    </div>
                                    <div className="country-code">
                                      {' '}
                                      <span>United States</span>
                                      +1
                                    </div>
                                  </Dropdown.Item>
                                </Dropdown.Menu> */}
                              </Dropdown>
                            </div>
                            <NumberFormat
                              className={`form-control mobile-num ${error === 'mobileno' ? 'error-text' : ''}`}
                              format={selectedCountry === '+61' ? '### ### ###' : '### ### ####'}
                              name="mobileNo"
                              value={user?.mobileNo}
                              placeholder="Enter Mobile Number"
                              autocomplete="off"
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicInput-34" className="number">
                          <Form.Label>Landline No.</Form.Label>
                          <div className="number-field">
                            {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                            <div className="number-box">

                              <Dropdown id="landline">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  <div className="flag-icon">
                                    <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                                  </div>
                                  {selectedCountry === '+61' ? (
                                    <span>
                                      Australia
                                      {' '}
                                      {selectedCountry}
                                    </span>
                                  ) : selectedCountry === '+1' ? (
                                    <span>
                                      United States
                                      {' '}
                                      {selectedCountry}
                                    </span>
                                  ) : ''}
                                  {/* <span><i className="fas fa-caret-down" /></span> */}
                                  {/* <div className="country-code">{}</div> */}
                                </Dropdown.Toggle>

                                {/* <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => handleCountry('+61')}>
                                    <div className="flag-icon">
                                      <img src={flag} alt="flag" />
                                    </div>
                                    <div className="country-code">
                                      <span>Australia</span>
                                      +61
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleCountry('+1')}>
                                    <div className="flag-icon">
                                      <img src={us} alt="flag" />
                                    </div>
                                    <div className="country-code">
                                      {' '}
                                      <span>United States</span>
                                      +1
                                    </div>
                                  </Dropdown.Item>
                                </Dropdown.Menu> */}
                              </Dropdown>
                            </div>
                            <NumberFormat
                              className={`form-control mobile-num ${error === 'landlineno' ? 'error-text' : ''}`}
                              format="# #### ####"
                              name="landlineNo"
                              onChange={handleChange}
                              value={user?.landlineNo}
                              autocomplete="off"
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicInput-5" className="italic-text">
                          <Form.Label>
                            Email Address
                            {/* <span className="required-field">*</span> */}
                          </Form.Label>
                          <Form.Control
                            type="email"
                            onChange={handleChange}
                            name="email"
                            value={user?.email}
                            placeholder="Enter Email Address"
                            className={`${
                              error === 'email'
                                ? 'error-text'
                                : ''
                            }`}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="italic-text form-group" controlId="formBasicInput-6">
                          <Form.Label>Street Address </Form.Label>
                          <Form.Control
                            type="text"
                            name="streetAddress"
                            onChange={handleChange}
                            value={user?.streetAddress}
                            placeholder="Enter Street Address"
                            className={`${
                              error === 'streetaddress'
                                ? 'error-text'
                                : ''
                            }`}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="italic-text form-group" controlId="formBasicInput-7">
                          <Form.Label>City/Town/Suburb</Form.Label>
                          <Form.Control
                            type="text"
                            name="suburb"
                            onChange={handleChange}
                            value={user?.suburb}
                            placeholder="City/Town/Suburb"
                            className={`${
                              error === 'suburb'
                                ? 'error-text'
                                : ''
                            }`}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="italic-text form-group" controlId="formBasicInput-8">
                          <Form.Label>Postal/Zip Code</Form.Label>
                          <Form.Control
                            type="text"
                            name="postcode"
                            onChange={handleChange}
                            value={user?.postcode}
                            placeholder="Postal/Zip Code"
                            className={`${
                              error === 'postcode'
                                ? 'error-text'
                                : ''
                            }`}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicLocation">
                          <Form.Label>
                            State
                            {' '}
                          </Form.Label>
                          <div className="select-box">
                            <Form.Control
                              as="select"
                              onChange={handleChange}
                              name="state"
                              value={user?.state}
                              className={`${
                                error === 'state'
                                  ? 'error-text'
                                  : ''
                              }`}
                            >
                              <option value="">Select</option>
                              {stateArray?.length > 0 && stateArray.map((el) => (
                                <option value={el.name}>
                                  {el.isoCode}
                                  {' '}
                                  -
                                  {' '}
                                  {el.name}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="view-btn">
                          <Button type="button" className="btn btn-edit" onClick={handleAction}> Cancel </Button>
                          <Button type="Submit" className="btn btn-save" onClick={handleEditSubscriber}> Save/Update </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
EditMyDetails.propTypes = {
  handleAction: PropTypes.func.isRequired,
  selectedCountry: PropTypes.string.isRequired,
  handleCountry: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf.isRequired,
  handleChange: PropTypes.func.isRequired,
  stateArray: PropTypes.arrayOf.isRequired,
  user: PropTypes.arrayOf.isRequired,
  handleEditSubscriber: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};
export default EditMyDetails;
