// this is important
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';
import {
  Table, Button, Accordion, Card, Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import AddUpdateProductGroup from './InventoryFormModal';
import ProductFormInventory from './productFormInventory';
import { userInstance } from '../../config/axios';
import {
  showErrMsg, validateDataWithKey, addZero, capitalizeFirstLetter, getUnit, currencyComma,
} from '../../functions/index';
import DurationPicker from '../appointment/durationPicker';
import showmore from '../../assets/home/showmore.png';
import whiteedit from '../../assets/home/white-edit.png';
import greyedit from '../../assets/home/grey-edit.png';
import greydelete from '../../assets/home/grey-delete.png';
import greyplus from '../../assets/home/plus.png';
import DeletePopUp from '../DeletePopUp/index';
import { errorMsgTime, successMsgTime, errorBorderTime } from '../../functions/intervalTime';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import optionsDataDropdown from './dropDownOptions';
import toastMessage from '../../functions/toastMessage';

function InventoryProductList({ servicesList, getServices, setServices }) {
  const { userData: { industryId, userId }, userData } = useContext(AuthDataContext);
  const initialSubcategory = {
    name: '',
    serviceDescription: '',
    tax: '',
    duration: '5',
    cost: '',
    retailCost: '',
    productCode: '',
    soldPer: 'Item',
    unit: '',
    symbol: '',
    trackedInventory: 'Yes',
    barCode: '',
    supplier: '',
    supplyUnit: '1',
    currentStockItems: '',
    s_uId: '',
    categoryId: '',
    supplierLeadTime: '',
  };
  const initialEditData = { index: null, categoryId: '', isEdit: false };
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState('');
  const [appointmentChecklist, setAppointmentChecklist] = useState([]);
  const [checklistName, setChecklistName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showSubCat, setShowCat] = useState(false);
  const [subcategory, setSubCategory] = useState(initialSubcategory);
  const [editData, setEditData] = useState(initialEditData);
  const [selectedAccordion, setSelectedAccordion] = useState([]);
  const [selectedNestedAccordion, setSelectedNestedAccordion] = useState([]);
  // eslint-disable-next-line no-unused-vars
  // const [isServiceAccordOpen, setIsServiceAccordOpen] = useState(false);
  const [type, setType] = useState('');
  // const [renderSort, setRenderSort] = useState(false);
  // const [renderSortProduct, setRenderSortProduct] = useState(false);
  const [durationPicker, setDurationPicker] = useState(false);
  const [durationData, setDuration] = useState('');
  const [buysymbol, setBuySymbol] = useState(false);
  const [sellsymbol, setSellSymbol] = useState(false);
  const [openService, setOpenService] = useState();
  const [openProduct, setOpenProduct] = useState();
  const [checklist, setChecklist] = useState();
  const [inputDisabled, setInputDisabled] = useState(true);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [groupId, setGroupId] = useState();
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [catId, setCatId] = useState('');
  const [selectedLocationValue, setSelectedLocationValue] = useState([]);
  const [delData, setDelData] = useState({});
  const [delStatus, setDelStatus] = useState('');
  const [hideLocStaff, sethideLocStaff] = useState(true);
  const [teamMemberData, setTeamMemberData] = useState([]);
  const [errorValue, setErrorValue] = useState('');

  const paraText = 'Are you sure to do this?';

  // Get all inventory list
  // add product group
  const handleManageCategory = async (e) => {
    e.preventDefault();
    try {
      if (selectedCategory) {
        handleUpdateCategory();
      } else if (category.trim()) {
        if (appointmentChecklist.length > 0) {
          const response = await userInstance().post('/inventory/addInventory', {
            category,
            type,
            appointmentChecklist,
            industryId: industryId?._id,
          });
          const { msg, status } = response.data;
          console.log('response', response.data);
          HandleOpenClose();
          getServices();
          setAppointmentChecklist([]);
          toastMessage(status, msg);
        } else {
          toastMessage('error', 'Please add Pool Maintenance checklist.');
        }
      } else {
        toastMessage('error', 'Please enter category name');
        setErrorValue('category');
      }
      setTimeout(() => {
        setErrorValue('');
      }, errorBorderTime);
    } catch (error) {
      // showErrMsg(error);
    }
  };

  // edit product group
  const editProductGroup = async () => {
    try {
      if (category.trim()) {
        if (appointmentChecklist.length > 0) {
          const res = await userInstance().put('/inventory/updateProductGroup', { selectedCategory: groupId, category, appointmentChecklist });
          setShow(!show);
          getServices();
        } else {
          toastMessage('error', 'Please add Pool Maintenance checklist.');
          setErrorValue('category');
        }
        setTimeout(() => {
          setErrorValue('');
        }, errorBorderTime);
      } else {
        toastMessage('error', 'Please enter category name');
        setErrorValue('category');
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  // add sub category
  const handleManageSubCat = async (e) => {
    try {
      e.preventDefault();
      if (selectedCategory) {
        // console.log('selectedCategory', selectedCategory);
        const subcategoryCopy = { ...subcategory };
        const {
          name, serviceDescription, duration, cost, retailCost, tax, soldPer,
        } = subcategoryCopy;
        const validationObj = {
          name: name?.trim(),
          // description: serviceDescription,
          duration,
          // cost,
          // tax
        };
        if (!category.toLowerCase().includes('misc')) {
          validationObj['buy cost'] = cost;
        }
        // console.log('cost', cost);
        if (type === 'product') {
          // if (!category.toLowerCase().includes('misc')) {
          //   validationObj['retail cost'] = retailCost;
          // }
          // subcategoryCopy.retailCost = parseFloat(subcategoryCopy.retailCost.replace(/,/g, '')).toFixed(2);
        }
        subcategoryCopy.cost = parseFloat(subcategoryCopy.cost.replace(/,/g, '') || 0).toFixed(2);

        let { isValid, emptyField } = validateDataWithKey(validationObj);
        if (isValid) {
          // if (type === 'product' && parseFloat(subcategoryCopy.cost) > parseFloat(subcategoryCopy.retailCost)) {
          //   isValid = false;
          //   NotificationManager.error('Sell price must be greater than buy cost', 'Message', errorMsgTime);
          // }
          subcategoryCopy.tax = 0.00;

          if (isValid) {
            // if (type === 'product' && subcategoryCopy.tax.length === 0) {
            //   isValid = false;
            //   NotificationManager.error('Please Enter tax details ', 'Message', errorMsgTime);
            // }
          }
          let randomStr = (
            Math.floor(Math.random() * 9 + 1) + Date.now() + (Math.random() + 1).toString(36).substring(7)
          ).toString();
          subcategoryCopy.s_uId = randomStr;
          subcategoryCopy.categoryId = catId;
          if (isValid) {
            // console.log('subcategoryCopy', subcategoryCopy);
            const reqObj = {
              ...subcategoryCopy, currentStockItems: subcategoryCopy.supplyUnit, unit: soldPer,
            };
            console.log('reqObj---', reqObj);
            // if (!category.toLowerCase().includes('misc')) {
            //   reqObj.name = `${subcategoryCopy.name} $${addZero(
            //     subcategoryCopy[type === 'product' ? 'retailCost' : 'cost'],
            //   )}`;
            // }
            // const totalStock = selectedStaff.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.stockValue), 0);

            const mainUserData = [{
              _id: userId,
              stockValue: subcategoryCopy.supplyUnit,
            }];
            const totalStock = subcategoryCopy.supplyUnit;
            const data = {
              subcategory: reqObj,
              stockData: mainUserData,
              selectedCategory,
              type,
              totalStock,
            };
            // console.log('reqObj-----', reqObj);
            const response = await userInstance().post('/inventory/addSubInventory', {
              subcategory: reqObj,
              stockData: mainUserData,
              selectedCategory,
              type,
              totalStock,
              industryId,
            });
            const { msg } = response.data;
            setShowCat(!showSubCat);
            getServices();
            toastMessage('success', msg);
          }
        } else {
          toastMessage('error', `Please enter ${emptyField} for product`);
          setErrorValue(emptyField);
        }
      } else {
        toastMessage('error', 'Please enter product name');
        setErrorValue('name');
      }
      setTimeout(() => {
        setErrorValue('');
      }, errorBorderTime);
    } catch (error) {
      showErrMsg(error);
    }
  };

  // edit sub category
  const handleEditSubcategory = async (index, categoryId, entryType, name) => {
    try {
      setType(entryType);
      const currentSubCat = servicesList.filter((el) => el._id === categoryId);
      if (currentSubCat.length) {
        const currentSubData = currentSubCat[0].subCategory[index];
        setSubCategory({ ...subcategory, ...currentSubData });
        // const { staffStockData } = currentSubData;
        // const prevLocations = locationData.filter(({ _id: id1 }) => staffStockData.some(({ location: id2 }) => id2 === id1));
        // setSelectedLocationValue(prevLocations);
        // setSelectedStaff(staffStockData);
      }
      setEditData({
        ...editData,
        index,
        categoryId,
        entryType,
        isEdit: true,
      });
      setShowCat(true);
      setCategory(name);
    } catch (error) {
      showErrMsg(error);
    }
  };

  // delete sub category
  const handleDeleteSubcategory = async (index, categoryId, categoryName, eltype, subcategoryId) => {
    try {
      let categoryIndex = null;
      const currentSubCat = servicesList.filter((el, i) => {
        if (el._id === categoryId) {
          categoryIndex = i;
          return true;
        }
        return false;
      });
      if (currentSubCat.length) {
        const serviceArr = servicesList;
        serviceArr[categoryIndex] = currentSubCat[0];
        setServices([...serviceArr]);
        const response = await userInstance().put('/inventory/updateSubInventory', {
          _id: categoryId,
          subCategory: currentSubCat[0].subCategory,
          subcategoryId,
          reqType: 'delete',
        });
        getServices();
        toastMessage('success', 'Product deleted successfully');
      }
    } catch (error) {
      // showErrMsg(error);
    }
  };
  const handleChecklist = () => {
    setEditData([]);
    setChecklistName('');
    setChecklist(!checklist);
  };

  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };

  const handleDurationPicker = () => {
    setDuration(subcategory.duration);
    setDurationPicker(!durationPicker);
  };

  const moveArrCheckList = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    setAppointmentChecklist([...arr]);
  };

  const handlePopUpChange = (val) => {
    const {
      index, categoryId, categoryName, eltype, id, subcategoryId,
    } = delData;
    if (val === 'Yes') {
      switch (delStatus) {
        case 'Delete Product':
          handleDeleteCategory(id);
          break;
        default:
          handleDeleteSubcategory(index, categoryId, categoryName, eltype, subcategoryId);
          break;
      }
    }
    setDeletePopUp(false);
  };
  const submitRemoveSubCate = (index, categoryId, categoryName, eltype, subcategoryId) => {
    // console.log('data', index, categoryId, categoryName, eltype, subcategoryId);
    setPopUpText('Delete Product?');
    setDelStatus('Delete subproduct');
    setDeletePopUp(true);
    setDelData({
      index, categoryId, categoryName, eltype, subcategoryId,
    });
  };

  const submitRemoveCategory = (id) => {
    setDelStatus('Delete Product');
    setPopUpText('Delete Product?');
    setDeletePopUp(true);
    setDelData({
      id,
    });
  };

  const HandleOpenClose = (entryType) => {
    setType(entryType);
    setShow(!show);
    setSelectedCategory('');
    setCategory('');
    setAppointmentChecklist([]);
    setDeletePopUp(false);
  };
  const openModelForEdit = (entryType, cate, id, checkListData) => {
    setType(entryType);
    setShow(!show);
    setSelectedCategory('');
    setCategory('');
    setAppointmentChecklist(checkListData);
    setDeletePopUp(false);
    setCategory(cate);
    setGroupId(id);
  };

  const [dynamicUnit, setDynamicUnit] = useState(optionsDataDropdown[0].dropdownOptions);
  const handleChange = (e) => {
    let isValid = true;
    if (e.target.name === 'tax' || e.target.name === 'cost' || e.target.name === 'currentStockItems') {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(e.target.value.replace(/,/g, ''))) {
        isValid = false;
      }
    }
    const subCatCopy = { ...subcategory, [e.target.name]: e.target.value };
    if (e.target.name === 'soldPer') {
      subCatCopy.unit = e.target.value || '';
    }
    if (isValid) {
      setSubCategory(subCatCopy);
    }
    if (e.target.name === 'cost' && e.target.value) setBuySymbol(true);
    else if (e.target.name === 'cost' && e.target.value === null) setBuySymbol(false);
    if (e.target.name === 'retailCost' && e.target.value) setSellSymbol(true);
    else if (e.target.name === 'retailCost' && e.target.value === null) setSellSymbol(false);
    if (e.target.name === 'trackedInventory' && e.target.value === 'No') sethideLocStaff(false);
    else if (e.target.name === 'trackedInventory' && e.target.value === 'Yes') sethideLocStaff(true);
    if (e.target.name === 'soldPer') {
      const findDropdownOptions = optionsDataDropdown.find((el) => el.name === e.target.value);
      setDynamicUnit(findDropdownOptions.dropdownOptions);
    }
  };

  const withValueLimitSellPrice = (inputObj) => {
    const { value } = inputObj;
    const toCheck = 9999999.99;
    const splitD = value.toString().split('.');
    const isValid = splitD.length > 1 ? splitD[1].length <= 3 : true;
    if (value <= toCheck && isValid) return inputObj;
    toastMessage('error', `Enter an amount equal $${toCheck} or less`);
    return '';
  };
  const handleOpenSubCat = (id, entryType, i, catIdMain) => {
    setType(entryType);
    setSelectedCategory(id);
    setShowCat(!showSubCat);
    setSubCategory(initialSubcategory);
    setEditData(initialEditData);
    setCategory(id);
    setCatId(catIdMain);
    if (entryType === 'service') {
      setOpenService(i);
    } else {
      setOpenProduct(i);
    }
  };

  const handleUpdateSubCategory = async (e) => {
    try {
      e.preventDefault();
      // console.log('this is editData.categoryId', editData.categoryId);
      const currentSubCat = servicesList.filter((el) => el._id === editData.categoryId);
      if (currentSubCat.length) {
        currentSubCat[0].subCategory[editData.index] = subcategory;
        const subcategoryCopy = {
          ...currentSubCat[0].subCategory[editData.index],
        };
        const {
          serviceDescription, duration, cost, retailCost, tax, s_uId,
        } = subcategoryCopy;
        console.log('subcategoryCopy.name--', subcategoryCopy.name);
        const validationObj = {
          name: subcategoryCopy?.name?.trim(),
          // description: serviceDescription,
          duration,
          // cost,
          // tax,
        };
        if (!category.toLowerCase().includes('misc')) {
          validationObj['buy cost'] = cost;
        }
        // if (type === 'product') {
        //   if (!category.toLowerCase().includes('misc')) {
        //     validationObj['sell price'] = retailCost;
        //   }
        // }
        let { isValid, emptyField } = validateDataWithKey(validationObj);
        if (isValid) {
          const { name } = currentSubCat[0].subCategory[editData.index];
          const updatedSubCat = {
            ...currentSubCat[0].subCategory[editData.index],
          };

          if (!category.toLowerCase().includes('misc')) {
            updatedSubCat.name = `${name.includes('$') ? name.split('$')[0].trim() : name} $${parseFloat(addZero(
              currentSubCat[0].subCategory[editData.index]?.cost.replace(/,/g, ''),
            )).toFixed(2)}`;
          }
          updatedSubCat.cost = parseFloat(updatedSubCat.cost.replace(/,/g, '')).toFixed(2);
          // if (type === 'product') {
          //   updatedSubCat.retailCost = parseFloat(updatedSubCat.retailCost.replace(/,/g, '')).toFixed(2);
          // }
          currentSubCat[0].subCategory[editData.index] = updatedSubCat;
          // console.log('updatedSubCat', updatedSubCat);

          // if (type === 'product' && parseFloat(updatedSubCat.cost) > parseFloat(updatedSubCat.retailCost)) {
          //   isValid = false;
          //   NotificationManager.error('Sell price must be greater than buy cost', 'Message', errorMsgTime);
          // }

          // updatedSubCat.tax = parseFloat(updatedSubCat.tax.replace(/,/g, '')).toFixed(2);
          updatedSubCat.tax = 0;
          // if (isValid) {
          //   if (type === 'product' && updatedSubCat.tax.length === 0) {
          //     isValid = false;
          //     NotificationManager.error('Please Enter tax details ', 'Message', errorMsgTime);
          //   }
          // }

          if (isValid) {
            // const totalStock = selectedStaff.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.stockValue), 0);
            const totalStock = updatedSubCat.supplyUnit || '0';
            const mainUserData = [{
              _id: userId,
              stockValue: updatedSubCat.supplyUnit,
            }];
            const response = await userInstance().put('/inventory/updateSubInventory', {
              _id: editData.categoryId,
              subCategory: currentSubCat[0].subCategory,
              reqType: 'edit',
              subcategoryId: s_uId,
              totalStock,
              stockData: mainUserData,
            });
            const { msg } = response.data;
            setShowCat(!showSubCat);
            getServices();
            // setEditData();

            if (msg) {
              if (editData.entryType === 'product') {
                toastMessage('success', 'Product Updated Successfully');
              }
            }
          }
        } else {
          toastMessage('error', `Please enter ${emptyField} for product`);
          setErrorValue(emptyField);
        }
      }
      setTimeout(() => {
        setErrorValue('');
      }, errorBorderTime);
    } catch (error) {
      console.log(error);
      showErrMsg(error);
    }
  };

  const handleEditCategory = (id, categoryName, entryType, i, data) => {
    // console.log('handle Edit', categoryName, entryType, i, data);
    setShowCat(!showSubCat);
    setType(entryType);
    setEditData({
      ...editData,
      index: i,
      categoryId: id,
      // isEdit: true,
    });
    setSelectedCategory(id);
    setAppointmentChecklist(data || []);
    setCategory(categoryName);
    if (entryType === 'service') {
      setOpenService(i);
    } else {
      setOpenProduct(i);
    }
  };

  const handleUpdateCategory = async () => {
    try {
      // console.log('category', category);
      if (category.trim()) {
        if (appointmentChecklist.length > 0) {
          const response = await userInstance().put('/inventory/updateInventory', {
            selectedCategory,
            category,
            appointmentChecklist,
          });
          // console.log('sdsdsd', response);
          const { msg } = response.data;
          if (msg) {
            if (type === 'service') {
              toastMessage('success', 'Service Group Name updated');
            } else {
              toastMessage('success', 'Product Group Name updated');
            }
          }
          HandleOpenClose();
          getServices();
        } else {
          toastMessage('error', 'Please add appointment checklist');
        }
      } else {
        toastMessage('error', 'Please add category name');
      }
    } catch (error) {
      console.log(error);
      showErrMsg(error);
    }
  };
  const handleDeleteCategory = async (id) => {
    try {
      const checkSubCat = servicesList.filter((el) => el._id === id);
      if (checkSubCat.length) {
        if (!checkSubCat[0].subCategory.length) {
          const response = await userInstance().delete(`/inventory/deleteInventory?id=${id}`);
          // console.log('sdsdsd', response);
          const { msg } = response.data;
          getServices();
          toastMessage('success', msg);
        } else {
          // console.log('cannot');
          toastMessage('error',
            'All services/products linked to this category will need to be deleted before it can be deleted');
        }
      }
    } catch (error) {
      console.log(error);
      showErrMsg(error);
    }
  };

  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      // console.log(val);
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msg = hours && minutes ? `${hours} hr ${minutes} min` : hours && !minutes ? `${hours} hr` : `${minutes} min`;
      arr.push({ value: val, data: msg });
      if (val >= 300) {
        val += 30;
      } else {
        val += 5;
      }
    }
    return arr;
  };

  const convertToHour = (val) => {
    const hours = Math.floor(val / 60);
    const minutes = val % 60;
    const msg = hours && minutes ? `${hours} hr ${minutes} min` : hours && !minutes ? `${hours} hr` : `${minutes} min`;
    return msg;
  };
  const handleUpAccordion = (e) => {
    if (selectedAccordion && selectedAccordion.includes(e)) {
      const data = selectedAccordion.filter((el) => el !== e);
      return setSelectedAccordion(data);
    }
    return setSelectedAccordion([...selectedAccordion, e]);
  };

  const handleNestedAccordion = (e) => {
    if (selectedNestedAccordion && selectedNestedAccordion.includes(e)) {
      const data = selectedNestedAccordion.filter((el) => el !== e);
      return setSelectedNestedAccordion(data);
    }
    return setSelectedNestedAccordion([...selectedNestedAccordion, e]);
  };

  const handleManageChecklist = () => {
    if (checklistName.trim()) {
      const old = [...appointmentChecklist];
      old.push(checklistName);
      setAppointmentChecklist(old);
      handleChecklist();
    } else {
      toastMessage('error', 'Please enter checklist name.');
      setErrorValue('checklistName');
    }
    setTimeout(() => {
      setErrorValue('');
    }, errorBorderTime);
  };
  const removeList = (index) => {
    const old = [...appointmentChecklist];
    old.splice(index, 1);
    setAppointmentChecklist(old);
  };

  const editList = (e, index) => {
    const old = [...appointmentChecklist];
    old[index] = e.target.value;
    setAppointmentChecklist(old);
  };
  const getLocation = async () => {
    const response = await userInstance().get(
      '/appointment/getLocationList',
    );
    const { locationList, code } = response.data;
    locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
    if (code === 200) {
      setLocationData([...locationList]);
    }
  };
  const getTeam = async () => {
    const response = await userInstance().get(
      '/appointment/getTeamMemberList',
    );
    const { teamList, code } = response.data;
    teamList.sort((a, b) => a.firstname.localeCompare(b.firstname));
    if (code === 200) {
      setTeamMemberData(teamList);
    }
  };
  const handleStock = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const stt = selectedLocation.slice();
    const fIndex = stt.indexOf(value);
    if (fIndex < 0) {
      stt.push(value);
    }
    setSelectedLocation(stt);
  };
  useEffect(() => {
    getLocation();
    getTeam();
  }, []);

  useEffect(() => {
    if (errorValue) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errorValue]);

  return (
    <>
      <div className="product-service-container inventory-list inventory-product-list">
        {/* <div className="row">
          <div className="col-md-12">
            <div className="main-titles">
              <h3>Services & Products</h3>
            </div>
          </div>
        </div> */}
        <div className="service-container">
          <div className="service-title">
            <h4>Services</h4>
            <span>
              <i className="fa fa-plus" />
            </span>
          </div>
        </div>
        <div className="table-data">
          <div className="service-mbl">
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <div className="service-container">
                    <div className="service-title title-service">
                      <h4>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                          Inventory Product List
                        </Accordion.Toggle>
                      </h4>
                      <div className="services-header" onClick={() => HandleOpenClose('Add')}>
                        <span>
                          <i className="fa fa-plus" />
                        </span>
                      </div>
                    </div>
                  </div>
                </Card.Header>
                {console.log(servicesList)}
                <Accordion.Collapse eventKey="0">
                  <div className="mob-table">
                    {servicesList.map((el, i) => (
                      <Accordion className="mob-accordion outer-accordion">
                        <Card.Header>
                          <div className="heading-accordion">
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey={!el?.subCategory?.length ? null : i.toString()}
                              onClick={() => handleUpAccordion(el._id)}
                            >
                              <h5>
                                <i
                                  className={`fas ${selectedAccordion.includes(el._id)
                                    ? ' far fa-chevron-circle-down'
                                    : 'fa-chevron-circle-up'
                                  }`}
                                />
                                {el.category}
                              </h5>
                            </Accordion.Toggle>
                            <div className="">
                              {selectedAccordion.includes(el._id) && el.subCategory.length > 0
                            && (
                            <>
                              <div className="service-edit-icon">
                                Stock
                                {' '}
                                <br />
                                {' '}
                                Level
                              </div>
                            </>
                            )}
                              <div className={`client-option service-edit-icon ${el?.category === 'Chemicals' ? 'hide-element' : ''}`}>
                                {' '}
                                <Dropdown>
                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <img src={showmore} alt="show" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <div className="action" onClick={() => handleOpenSubCat(el.category, el.type, i, el._id)}>
                                      <span><img src={greyplus} alt="edit" /></span>
                                      <span>Add</span>
                                    </div>

                                    <div
                                      className="action"
                                    // onClick={() => handleEditCategory(el.id, el.category, el.type, i, el.appointmentChecklist)}
                                      onClick={() => openModelForEdit('update', el.category, el._id, el.appointmentChecklist)}
                                    >
                                      <span><img src={greyedit} alt="edit" /></span>
                                      <span> Edit</span>
                                    </div>
                                    {el.category.match(/Misc/g) ? null : (
                                      <div className="action" onClick={() => submitRemoveCategory(el._id)}>
                                        <span>
                                          {' '}
                                          <img src={greydelete} alt="edit" />
                                        </span>
                                        <span> Delete</span>
                                      </div>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>

                          {/* <div className="table-icons">
                          <Button onClick={() => handleEditCategory(el._id, el.category)}><i className="fas fa-pencil-alt" /></Button>
                          <Button onClick={() => submitRemoveCategory(el._id)}><i className="far fa-trash-alt" /></Button>
                          <i className="fa fa-plus" onClick={() => handleOpenSubCat(el.category)} />
                        </div> */}
                        </Card.Header>
                        <Accordion.Collapse eventKey={i.toString()} className={`${openProduct === i ? 'show' : ''}`}>
                          <Card.Body>
                            <div className="inner-accordion">
                              {el.subCategory.map((ele, index) => (
                                <>
                                  <Accordion defaultActiveKey="1">
                                    <Card>
                                      <Accordion>
                                        <Card.Header className="sub-category">
                                          <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey="1"
                                            onClick={() => handleNestedAccordion(index)}
                                          >
                                            <div className="product-service-list">
                                              <i
                                                className={`fas ${
                                                  selectedNestedAccordion.includes(index)
                                                    ? 'far fa-chevron-circle-down'
                                                    : 'far fa-chevron-circle-up'
                                                }`}
                                              />
                                              <span>
                                                {ele?.renderName}
                                              </span>
                                            </div>
                                          </Accordion.Toggle>
                                          <span className="time-category">
                                            <div className="timehour">
                                              {Number(ele?.currentStockItems || 0)?.toFixed(2)}
                                              {' '}
                                              {getUnit(ele?.unit, ele?.currentStockItems)}
                                            </div>
                                          </span>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                          <ul className="grid-list product-inventory-grid">
                                            {/* <li className="grid-list-item">
                                              <div className="grid-box">
                                                <h3>
                                                  Product code
                                                </h3>
                                                <div className="grid-content">
                                                  {ele.productCode}
                                                </div>
                                              </div>
                                            </li>
                                            <li className="grid-list-item">
                                              <div className="grid-box">
                                                <h3 className="yellow-text">Barcode</h3>
                                                <div className="grid-content">
                                                  {ele.barCode}
                                                </div>
                                              </div>
                                            </li>
                                            <li className="grid-list-item">
                                              <div className="grid-box">
                                                <h3>Description</h3>
                                                <div className="grid-content">
                                                  {ele.serviceDescription}
                                                </div>
                                              </div>
                                            </li>
                                            <li className="grid-list-item">
                                              <div className="grid-box">
                                                <h3 className="yellow-text">
                                                  Supplier
                                                </h3>
                                                <div className="grid-content">
                                                  {ele.supplier}
                                                </div>
                                              </div>
                                            </li>
                                            <li className="grid-list-item">
                                              <div className="grid-box">
                                                <h3>Product sold per</h3>
                                                <div className="grid-content">{ele?.soldPer}</div>
                                              </div>
                                            </li> */}
                                            <li className="grid-list-item">
                                              <div className="grid-box">
                                                <h3>Tracked Inventory Product</h3>
                                                <div className="grid-content">{ele?.trackedInventory}</div>
                                              </div>
                                            </li>
                                            {/* <li className="grid-list-item">
                                              <div className="grid-box">
                                                <h3 className="yellow-text">
                                                  Supply Unit
                                                  {' '}
                                                </h3>
                                                <div className="grid-content">{ele?.supplyUnit}</div>
                                              </div>
                                            </li>
                                            <li className="grid-list-item">
                                              <div className="grid-box">
                                                <h3 className="yellow-text">
                                                  Duration
                                                </h3>
                                                <div className="grid-content">{convertToHour(ele?.duration || 0)}</div>
                                              </div>
                                            </li>
                                            <li className="grid-list-item">
                                              <div className="grid-box">
                                                <h3>Tax</h3>
                                                <div className="grid-content">
                                                  {ele?.tax}
                                                  %
                                                </div>
                                              </div>
                                            </li> */}
                                            <li className="grid-list-item">
                                              <div className="grid-box">
                                                <h3>
                                                  Average Buy Cost
                                                  {' '}
                                                  <i>
                                                    ( per
                                                    {' '}
                                                    {getUnit(ele?.unit)}
                                                    )
                                                  </i>
                                                </h3>
                                                <div className="grid-content">
                                                  $
                                                  {currencyComma(parseFloat(ele?.cost).toFixed(2))}
                                                </div>
                                              </div>
                                            </li>
                                            {/* <li className="grid-list-item">
                                              <div className="grid-box">
                                                <h3>
                                                  {' '}
                                                  <span className="yellow-text">
                                                    Average
                                                  </span>
                                                  {' '}
                                                  Sell Price (ex Tax)
                                                </h3>
                                                <div className="grid-content">
                                                  $
                                                  {ele.retailCost}
                                                </div>
                                              </div>
                                            </li> */}
                                            {/* <li className="grid-list-item current-stock">
                                              <div className="grid-box">
                                                <h3>Current stock level</h3>
                                                <div className="grid-content">
                                                  {ele?.currentStockItems}
                                                </div>
                                              </div>
                                            </li> */}
                                            <li className="grid-list-item">
                                              <div className="predicative-stock">
                                                <h4>Analytics</h4>
                                                <h6>Historical Product Usage</h6>
                                                <div className="stock-listing">
                                                  <p>Quantity Used Last 6 Months</p>
                                                  <p>
                                                    {ele?.sixMonthsUsage || 0}
                                                    {' '}
                                                    {getUnit(ele?.unit, ele?.sixMonthsUsage)}
                                                  </p>
                                                </div>
                                                <div className="stock-listing">
                                                  <p>Quantity Used Last 3 Months</p>
                                                  <p>
                                                    {ele?.threeMonthsUsage || 0}
                                                    {' '}
                                                    {getUnit(ele?.unit, ele?.threeMonthsUsage)}
                                                  </p>
                                                </div>
                                                <div className="stock-listing">
                                                  <p>Quantity Used Last 1 Month</p>
                                                  <p>
                                                    {ele?.oneMonthUsage || 0}
                                                    {' '}
                                                    {getUnit(ele?.unit, ele?.oneMonthUsage)}
                                                  </p>
                                                </div>
                                                {/* <div className="ordring-section">
                                                  <h6>Re-Ordering Forecast</h6>
                                                  <div className="stock-listing">
                                                    <p>Stock Required Next 6 Months</p>
                                                    <p>
                                                      {' '}
                                                      {ele?.foreCastSixMonths || 0}
                                                      {' '}
                                                      {getUnit(ele?.unit, ele?.foreCastSixMonths)}
                                                    </p>
                                                  </div>
                                                  <div className="stock-listing">
                                                    <p>Stock Required Next 3 Months</p>
                                                    <p>
                                                      {' '}
                                                      {ele?.foreCastThreeMonths || 0}
                                                      {' '}
                                                      {getUnit(ele?.unit, ele?.foreCastThreeMonths)}
                                                    </p>
                                                  </div>
                                                  <div className="stock-listing">
                                                    <p>Stock Required Next 1 Month</p>
                                                    <p>
                                                      {' '}
                                                      {ele?.foreCastPerMonth || 0}
                                                      {' '}
                                                      {getUnit(ele?.unit, ele?.foreCastPerMonth)}
                                                    </p>
                                                  </div>
                                                </div> */}
                                              </div>
                                            </li>
                                            {el?.category !== 'Chemicals'
                                            && (
                                            <li className="grid-list-item actions">
                                              <div className="grid-box">
                                                <h3>Actions</h3>
                                                <div className="action-btn">
                                                  <Button
                                                    className="edit-btn"
                                                    onClick={() => handleEditSubcategory(index, el._id, 'product', el.category, el.type, ele._id)}
                                                  >
                                                    <img src={whiteedit} alt="edit" />
                                                  </Button>
                                                </div>
                                                <div className="action-btn">
                                                  <Button className="delete-btn" onClick={() => submitRemoveSubCate(index, el._id, 'product', el.type, ele.s_uId)}>
                                                    <i className="far fa-trash-alt" />
                                                  </Button>
                                                </div>
                                              </div>
                                            </li>
                                            )}
                                          </ul>
                                        </Accordion.Collapse>
                                      </Accordion>
                                    </Card>
                                  </Accordion>

                                </>
                              ))}

                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                    ))}

                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>

      <AddUpdateProductGroup
        show={show}
        HandleOpenClose={HandleOpenClose}
        handleManageCategory={handleManageCategory}
        category={category}
        setCategory={setCategory}
        servicesList={servicesList}
        type={type}
        appointmentChecklist={appointmentChecklist}
        handleManageChecklist={handleManageChecklist}
        checklist={checklist}
        handleChecklist={handleChecklist}
        checklistName={checklistName}
        setChecklistName={setChecklistName}
        removeList={removeList}
        inputDisabled={inputDisabled}
        setInputDisabled={setInputDisabled}
        editList={editList}
        moveArrCheckList={moveArrCheckList}
        editProductGroup={editProductGroup}
        groupId={groupId}
        error={errorValue}
      />
      <ProductFormInventory
        showSubCat={showSubCat}
        setShowCat={setShowCat}
        subcategory={subcategory}
        handleChange={handleChange}
        handleManageSubCat={handleManageSubCat}
        handleUpdateSubCategory={handleUpdateSubCategory}
        editData={editData}
        renderOptions={renderOptions}
        type={type}
        withValueLimitSellPrice={withValueLimitSellPrice}
        handleDurationPicker={handleDurationPicker}
        convertToReadableDuration={convertToReadableDuration}
        buysymbol={buysymbol}
        sellsymbol={sellsymbol}
        category={category}
        handleEditSubcategory={handleEditSubcategory}
        catId={catId}
        locationData={locationData}
        teamMemberData={teamMemberData}
        selectedLocation={selectedLocation}
        handleStock={handleStock}
        hideLocStaff={hideLocStaff}
        selectedStaff={selectedStaff}
        setSelectedStaff={setSelectedStaff}
        setTeamMemberData={setTeamMemberData}
        setLocationData={setLocationData}
        selectedLocationValue={selectedLocationValue}
        setSelectedLocationValue={setSelectedLocationValue}
        error={errorValue}
        dynamicUnit={dynamicUnit}
      />
      {durationPicker && (
        <DurationPicker
          durationPicker={durationPicker}
          setDurationPicker={setDurationPicker}
          durationData={durationData}
          handleDurationPicker={handleDurationPicker}
          setDuration={setDuration}
          setBookingData={null}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={null}
          subcategory={subcategory}
          setSubCategory={setSubCategory}
        />

      )}
      {deletePopUp
        && (
          <DeletePopUp
            handlePopUpChange={handlePopUpChange}
            paraText={paraText}
            PopUpText={popUpText}
          />
        )}

    </>
  );
}

InventoryProductList.propTypes = {
  getServices: PropTypes.func.isRequired,
  setServices: PropTypes.func.isRequired,
  servicesList: PropTypes.arrayOf.isRequired,
};
export default InventoryProductList;
