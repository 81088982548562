import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const ThankyouCalicumPopup = ({
  setShowThankyou,
  handleThankyou,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            setShowThankyou(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="duration-picker time-picker-modal thankyou-calcium-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup" ref={wrapperRef}>
          <div className="appointment-view">
            <h5>Thank you for ordering your chemicals online with us. Order details have been sent to your email address.</h5>
            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button">
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={handleThankyou}>
                Okay
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
ThankyouCalicumPopup.propTypes = {
  setShowThankyou: PropTypes.func.isRequired,
  handleThankyou: PropTypes.func.isRequired,
};
export default ThankyouCalicumPopup;
