import React from 'react';
import Slider from 'react-slick';

const SimpleSlider = () => (

  <Slider infinite speed={500} slidesToShow={1} slidesToScroll={1}>

    <div className="testimonial-content">
      <p>
        A fantastic software experience! As soon as we began using your software we
        felt comfortable that we were finally in control of our pool.
        We don&apos;t have to
        visit the pool shop to get our water checked,
        which saves us time and we don&apos;t waste money buying unnecessary- chemicalsanymore.
        We will most definitely be using this software regularly throughout the year.
        Thank you!
      </p>
      <span>-Ben Doyal</span>
    </div>

    <div className="testimonial-content">
      <p>
        A fantastic software experience! As soon as we began using your software we
        felt comfortable that we were finally in control of our pool.
        We don&apos;t have to
        visit the pool shop to get our water checked,
        which saves us time and we don&apos;t waste money buying unnecessary- chemicalsanymore.
        We will most definitely be using this software regularly throughout the year.
        Thank you!
      </p>
      <span>-Ben Doyal</span>
    </div>

    <div className="testimonial-content">
      <p>
        A fantastic software experience! As soon as we began using your software we
        felt comfortable that we were finally in control of our pool.
        We don&apos;t have to
        visit the pool shop to get our water checked,
        which saves us time and we don&apos;t waste money buying unnecessary- chemicalsanymore.
        We will most definitely be using this software regularly throughout the year.
        Thank you!
      </p>
      <span>-Ben Doyal</span>
    </div>

    <div className="testimonial-content">
      <p>
        A fantastic software experience! As soon as we began using your software we
        felt comfortable that we were finally in control of our pool.
        We don&apos;t have to
        visit the pool shop to get our water checked,
        which saves us time and we don&apos;t waste money buying unnecessary- chemicalsanymore.
        We will most definitely be using this software regularly throughout the year.
        Thank you!
      </p>
      <span>-Ben Doyal</span>
    </div>

  </Slider>
);

export default SimpleSlider;
