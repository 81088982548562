/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const StartDateModal = (props) => {
  const { showStartDate, setShowStartDate, setRepeatDates } = props;
  return (
    <Modal
      size="md"
      show={showStartDate}
      onHide={() => setShowStartDate(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="startdate-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Calendar onChange={(e) => setRepeatDates({ name: 'startingDate', data: e })} />
      </Modal.Body>
    </Modal>
  );
};
StartDateModal.propTypes = {
  showStartDate: PropTypes.func.isRequired,
  setShowStartDate: PropTypes.func.isRequired,
  setRepeatDates: PropTypes.func.isRequired,
};
export default StartDateModal;
