import React from 'react';
import { PropTypes, bool } from 'prop-types';
import {
  SquarePaymentsForm,
  CreditCardInput,
} from 'react-square-web-payments-sdk';

const SquareCheckout = ({
  handleToken, disableButton, amountDisplay, paymentLoader,
}) => {
  const cssForButtton = {
    backgroundColor: '#1aa01d',
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: '10px',
    paddingTop: 0,
    paddingBottom: 0,
    height: '38px',
  };

  if (disableButton) cssForButtton.pointerEvents = 'none';

  return (
    <div className="staff-form">
      <SquarePaymentsForm
        applicationId="sandbox-sq0idb-oYeu027nYUL7QSs5ZNQDxA"
        locationId="LXD1PHSA0X03H"
        cardTokenizeResponseReceived={(token) => handleToken(token)}
        createPaymentRequest={() => ({
          countryCode: 'US',
          currencyCode: 'USD',
          total: {
            amount: '500',
            label: 'Total',
          },
        })}
      >
        <CreditCardInput
          text={`${`Pay $${amountDisplay ? amountDisplay?.toFixed(2) : '0.00'}`} `}
          includeInputLabels
          style={{
            input: {
              fontSize: '14px',
            },
          }}
          overrideStyles={cssForButtton}
        />
        {/* Uncomment this when need of gPay, applePay, ACH */}
        {/* <p />
          <AchPay accountHolderName="Mohd Faizan Ansari" />
          <p />
          {window.location.protocol === 'https:' && (
          <>
            <GooglePay />
            <p />
            <ApplePay />
          </>
          )} */}
      </SquarePaymentsForm>
    </div>
  );
};
SquareCheckout.defaultProps = {
  paymentLoader: false,
};
SquareCheckout.propTypes = {
  handleToken: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
  amountDisplay: PropTypes.number.isRequired,
  paymentLoader: bool,
};
export default SquareCheckout;
