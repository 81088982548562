import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

const LoadingButton = (props) => {
  const {
    isLoading,
    icon,
    label,
    handleClickBtn,
  } = props;
  return (
    <button
      type="button"
      onClick={handleClickBtn}
      style={{
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '2px 8px',
      }}
      disabled={isLoading}
    >
      <div className="d-flex align-item-center" style={{ alignItems: 'center' }}>
        {icon}
        <div className="d-flex align-self-center align-item-center">
          <div>
            <span style={{ fontSize: '14px' }}>{label}</span>
          </div>
          {isLoading && <div className="d-flex align-self-center"><ClipLoader size={16} color="#ccc" /></div>}
        </div>
      </div>
    </button>
  );
};

LoadingButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  handleClickBtn: PropTypes.func.isRequired,
};

export default LoadingButton;
