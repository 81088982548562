import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import showmore from '../../assets/home/showmoreblue.png';
import show from '../../assets/home/showmore.png';
import {
  PredictiveOrderTotala, currencyComma, getUnit, validateDataWithKey, chemicalsUnitDropdown, numberFromString, removeSfromString, getPerUnitFromUnitValue,
} from '../../functions/index';
import AddProductOrderDetails from './addProductOrderDetails';

import { notifyAlert } from '../calculator/calculatorUtils';
import { errorMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';
import { userInstance } from '../../config/axios';
import MyPaymentForm from '../subscription/SubScriptionPayment';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import OrderDetailsPopup from './orderDetailsPopup';

const OrderInventory = ({ displayPredictiveChemicals, taxData }) => {
  const { userData } = useContext(AuthDataContext);
  const [chemcicalsData, setChemicalsData] = useState([]);
  const [toggleOrderDetails, settoggleOrderDetails] = useState(false);
  const [selectedCountry, setCountry] = useState('+61');
  const [dropDownValue, setDropDownValue] = useState('foreCastSixMonths');
  const [orderSummary, setOrderSummary] = useState(null);
  const [addProduct, setAddProduct] = useState(false);
  const initialAddProduct = {
    itemVolume: '',
    orderQuantity: '',
    predictedOrderQuantity: '',
    displaySelectUnit: '',
    renderName: '',
    selectedProductObj: null,
    overAllCost: '',
    selectedItemCost: '',
    currentStockItems: 0,
    prodUnit: '',
    type: '',
  };

  const [productDetails, setProductDetails] = useState(initialAddProduct);
  const [allChemicalsProducts, setallChemicalsProducts] = useState([]);
  const [deletedChemProds, setDeletedChemProds] = useState([]);
  const [itemVolume, setItemVolume] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  const [userFormInfo, setUserFormInfo] = useState(null);
  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  const [paymentResult, setPaymentResult] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const handleCountry = (countryCode) => {
    setCountry(countryCode);
  };

  const handleOrderDetails = () => {
    settoggleOrderDetails(!toggleOrderDetails);
  };

  const handleDropdown = (e) => {
    setDropDownValue(e.target.value);
  };

  console.log({ userDataRole: userData?.role, taxDataChemical: taxData?.chemicalsTax });

  const handleEditPredictiveProducts = (_id) => {
    const copyChemProdData = JSON.parse(JSON.stringify(chemcicalsData));
    if (copyChemProdData?.length > 0) {
      const fIndex = copyChemProdData.findIndex((el) => el._id === _id);
      if (fIndex >= 0) {
        let newOrderQuantity = [];
        let prodUnit = '';
        const foundProd = copyChemProdData[fIndex];
        const findSelctedChemicalDropdowns = chemicalsUnitDropdown.find((el) => el.renderName === foundProd?.renderName);
        let currentVolume = numberFromString(findSelctedChemicalDropdowns.dropDownOptions[0]);
        if (findSelctedChemicalDropdowns) {
          let diff = Math.abs(foundProd.predictedOrderQuantity - currentVolume);
          prodUnit = `per${getPerUnitFromUnitValue(findSelctedChemicalDropdowns.dropDownOptions[0])}`;
          findSelctedChemicalDropdowns.dropDownOptions.forEach((el) => {
            let unitNumber = numberFromString(el);
            if (el.includes('ml') || el.includes('grams')) {
              unitNumber /= 1000;
            }
            let newdiff = Math.abs(foundProd.predictedOrderQuantity - unitNumber);
            if (newdiff < diff) {
              diff = newdiff;
              currentVolume = unitNumber;
            }
            let obj = { value: unitNumber, unit: getPerUnitFromUnitValue(el), renderValue: numberFromString(el) };
            newOrderQuantity.push(obj);
          });
        } else {
          prodUnit = 'litres';
          newOrderQuantity = [
            { value: 5, unit: prodUnit }, { value: 10, unit: prodUnit }, { value: 15, unit: prodUnit },
          ];
        }
        const editObj = {
          itemVolume: currentVolume,
          orderQuantity: parseFloat(foundProd.predictedOrderQuantity * 1 || 0),
          predictedOrderQuantity: foundProd.predictedOrderQuantity * 1,
          displaySelectUnit: `${currentVolume} per ${foundProd.prodUnit}`,
          renderName: foundProd.renderName,
          selectedProductObj: foundProd,
          calciName: foundProd.calciName,
          overAllCost: foundProd.overAllCost,
          selectedItemCost: foundProd.buycost,
          currentStockItems: foundProd.currentStockItems,
          prodUnit: `per ${foundProd.prodUnit}`,
          unit: `per ${foundProd.prodUnit}`,
          type: 'edit',
          _id,
        };
        console.log('editObj---', editObj);
        setItemVolume([...newOrderQuantity]);
        setProductDetails(editObj);
        setAddProduct(!addProduct);
      }
    }
  };

  const handleAddProduct = (closeReq) => {
    if (!!deletedChemProds.length > 0 || closeReq) {
      setAddProduct(!addProduct);
      setProductDetails(initialAddProduct);
      setItemVolume([]);
    } else {
      toastMessage('error', 'All products already added.');
      // NotificationManager.error('All products already added.', 'Message', errorMsgTime);
    }
  };

  const handleDeleteChemProd = (index) => {
    // the mutation of actuall listing is by splice
    const deletedChemProdSingle = chemcicalsData.splice(index, 1);
    // set the deleted chemProd in new array
    setDeletedChemProds([...deletedChemProds, ...deletedChemProdSingle]);
    const summary = PredictiveOrderTotala(chemcicalsData, userData?.role, taxData?.chemicalsTax);
    setOrderSummary({
      gst: summary?.gst,
      subtotal: summary?.subtotal,
      total: summary?.total,
    });
    setChemicalsData(chemcicalsData);
  };

  const handleUpdatePreditiveItem = (_id, prodInfo) => {
    const copyChemProdData = JSON.parse(JSON.stringify(chemcicalsData));
    const findEditChemProdIndex = copyChemProdData.findIndex((el) => el._id === _id);
    if (findEditChemProdIndex > -1) {
      copyChemProdData[findEditChemProdIndex] = prodInfo;
      setChemicalsData(copyChemProdData);
    }
    const summary = PredictiveOrderTotala(copyChemProdData, userData?.role, taxData?.chemicalsTax);
    setOrderSummary({
      gst: summary?.gst,
      subtotal: summary?.subtotal,
      total: summary?.total,
    });
    setChemicalsData(copyChemProdData);
  };

  const handlAddPredictiveItem = (_id, prodDetails) => {
    let prodInfo = { ...prodDetails };
    // console.log('prodInfo', prodInfo);
    const objToUpdate = {
      renderName: prodInfo.renderName,
      calciName: prodInfo.selectedProductObj.calciName,
      currentStockItems: prodInfo.currentStockItems,
      predictedOrderQuantity: prodInfo.predictedOrderQuantity * 1,
      orderQuantity: prodInfo.predictedOrderQuantity * 1,
      prodUnit: prodInfo.prodUnit,
      unit: prodInfo.prodUnit,
      overAllCost: prodInfo.overAllCost,
      buycost: prodInfo.buycost,
      _id: prodInfo._id,
    };
    chemcicalsData.push(objToUpdate);
    setChemicalsData(chemcicalsData);
    const filterFromDelete = deletedChemProds.filter((el) => el._id !== _id);
    setDeletedChemProds(filterFromDelete);
    const summary = PredictiveOrderTotala(chemcicalsData, userData?.role, taxData?.chemicalsTax);
    setOrderSummary({
      gst: summary?.gst,
      subtotal: summary?.subtotal,
      total: summary?.total,
    });
  };

  const handleSelectNames = (e) => {
    const selectedItem = deletedChemProds.find((el) => el._id === e.target.value);
    let newOrderQuantity = [];
    let prodUnit = '';
    let selectItemCost = '';
    if (selectedItem?.renderName) {
      selectItemCost = selectedItem?.buycost;
      const findSelctedChemicalDropdowns = chemicalsUnitDropdown.find((el) => el.renderName === selectedItem?.renderName);
      if (findSelctedChemicalDropdowns) {
        prodUnit = `per${getPerUnitFromUnitValue(findSelctedChemicalDropdowns.dropDownOptions[0])}`;
        findSelctedChemicalDropdowns.dropDownOptions.forEach((el) => {
          let unitNumber = numberFromString(el);
          if (el.includes('ml') || el.includes('grams')) {
            unitNumber /= 1000;
          }
          let obj = { value: unitNumber, unit: getPerUnitFromUnitValue(el), renderValue: numberFromString(el) };
          newOrderQuantity.push(obj);
        });
      } else {
        prodUnit = 'per litres';
        newOrderQuantity = [
          { value: 5, unit: prodUnit }, { value: 10, unit: prodUnit }, { value: 15, unit: prodUnit },
        ];
      }
    }
    setItemVolume([...newOrderQuantity]);
    const productCopy = { ...productDetails };
    productCopy.renderName = selectedItem?.renderName;
    productCopy.orderQuantity = '';
    productCopy.predictedOrderQuantity = '';
    productCopy.selectedProductObj = selectedItem || null;
    productCopy.displaySelectUnit = newOrderQuantity?.length > 0 ? `${newOrderQuantity[0].renderValue} ${removeSfromString(prodUnit)}` : removeSfromString(prodUnit);
    productCopy.overAllCost = '';
    productCopy.selectedItemCost = selectItemCost;
    productCopy.prodUnit = prodUnit;
    productCopy.itemVolume = newOrderQuantity?.length > 0 ? newOrderQuantity[0].value : '';
    productCopy._id = e.target.value;
    productCopy.currentStockItems = selectedItem.currentStockItems;
    setProductDetails(productCopy);
  };

  const doVolumeQuantityCalculation = (prodInfo) => {
    if (Number(prodInfo?.itemVolume) >= 0 && Number(prodInfo.orderQuantity) >= 0) {
      if (Number(prodInfo?.itemVolume) === 0.5) {
        prodInfo.displaySelectUnit = '500 per gram';
      } else {
        prodInfo.displaySelectUnit = `${prodInfo.itemVolume || 0} ${prodInfo.prodUnit}`;
      }
      prodInfo.predictedOrderQuantity = (prodInfo.orderQuantity * prodInfo.itemVolume).toFixed(2);
      prodInfo.selectedItemCost = prodInfo.selectedItemCost || prodInfo.selectedProductObj?.buycost;
      prodInfo.buycost = prodInfo.selectedProductObj?.selectedItemCost || prodInfo.selectedProductObj?.buycost;
      prodInfo.overAllCost = ((Number(prodInfo.orderQuantity) * Number(prodInfo.selectedProductObj?.buycost)) || 0).toFixed(2);
    }
    return prodInfo;
  };

  const handleItemVolume = (e) => {
    let copy = { ...productDetails };
    copy.prodUnit = `per${getPerUnitFromUnitValue(e.target.selectedOptions[0].text)}`;
    copy.itemVolume = e.target.value;
    copy = doVolumeQuantityCalculation(copy);
    setProductDetails(copy);
  };

  const handleOrderQuantityChange = (e) => {
    let copy = { ...productDetails };
    if (e.target.value && !e.target.value.match(/^[0-9]*[.]?[0-9]{0,2}$/)) {
      return;
    }
    copy.orderQuantity = e.target.value;
    if (e.target.value === '') {
      copy.overAllCost = '';
      copy.displaySelectUnit = copy?.prodUnit;
    }
    copy = doVolumeQuantityCalculation(copy);
    setProductDetails(copy);
  };

  // eslint-disable-next-line consistent-return
  const handleAddPredictiveChemical = () => {
    console.log(productDetails);
    const {
      orderQuantity,
      renderName,
      _id,
      itemVolume: itemVol,
    } = productDetails;

    let { isValid, emptyField } = validateDataWithKey({ 'product name': renderName, 'order quantity': orderQuantity, 'item volume': itemVol });
    if (isValid) {
      if (productDetails?.type === 'edit') {
        handleUpdatePreditiveItem(_id, productDetails);
      } else {
        handlAddPredictiveItem(_id, productDetails);
      }
      setAddProduct(!addProduct);
      setProductDetails({ ...initialAddProduct });
    } else {
      return notifyAlert(`Please Select ${emptyField}`, 'error');
    }
  };

  useEffect(() => {
    if (dropDownValue !== 'Add') {
      const chemicalsCopy = JSON.parse(JSON.stringify(displayPredictiveChemicals));
      console.log('displayPredictiveChemicalsdsfds', { displayPredictiveChemicals, dropDownValue });
      const newChem = chemicalsCopy.map((el) => ({
        calciName: el.calciName,
        renderName: el.renderName,
        orderQuantity: el[dropDownValue] * 1 || 0,
        currentStockItems: el.currentStockItems || 0,
        predictedOrderQuantity: el[dropDownValue] * 1 || 0,
        prodUnit: getUnit(el?.unit),
        unit: getUnit(el?.unit),
        overAllCost: parseFloat(el.cost) * el[dropDownValue] || 0,
        buycost: parseFloat(el.cost),
        _id: el?._id,
      }));
      console.log('newChem', newChem);
      const summary = PredictiveOrderTotala(newChem, userData?.role, taxData?.chemicalsTax);
      setOrderSummary({
        gst: summary?.gst,
        subtotal: summary?.subtotal,
        total: summary?.total,
      });
      setChemicalsData(newChem);
    } else if (!!deletedChemProds.length > 0) {
      setAddProduct(!addProduct);
    } else {
      toastMessage('error', 'All products already added.');
    }
  }, [dropDownValue]);

  // Payment Form Function
  const handleClosePaymentForm = () => {
    setOpenPaymentForm(false);
    setShowPayment(false);
    // setCalciumsupply(false)
  };

  const handleToken = async (token) => {
    console.log('token=======>>>', { userFormInfo });

    try {
      if (token) {
        setDisableButton(true);
        // const amountData = clientData?.billingCycle === 'Monthly' ? clientData?.monthlyCost : clientData?.annnumCost;
        // const myBigInt = BigInt(orderSummary?.total); // `10n` also works
        // const myNumber = Number(myBigInt);
        const finalAmount = parseFloat(orderSummary?.total).toFixed(2);
        console.log('finalAmount', { finalAmount, chemcicalsData });
        const payload = {
          sourceId: token.token,
          amountMoney: {
            currency: 'USD',
            amount: orderSummary?.total,
          },
          predictiveData: chemcicalsData || {},
          userFormInfo,
          type: 'Order Inventory',
        };
        console.log('chemcicalsData==>', chemcicalsData);
        // if (productDetails) {
        //   return;
        // }
        const response = await userInstance().post(
          '/subscribers/orderpayment',
          payload,
        );

        if (response.data.code === 200) {
          setPaymentResult('Payment Successful');
          setTimeout(() => {
            // creatingSubscriberAfterpayment();
            // handleThankyou();
            setDisableButton(false);
            setPaymentResult('');
          }, 1500);
        } else {
          setDisableButton(false);
          setPaymentResult('Payment Unsuccessful');
        }
        // console.log('response', response);
        // alert(JSON.stringify(response.data.resultData));
      }
    } catch (error) {
      setDisableButton(false);
    }
  };

  return (

    <div className="pool-setup pool-set-up predictive-ordering-page inventory-ordering-page">
      <div className="target-heading">
        <h6>Order Product</h6>
        <div className="content-dropdown">
          <div className="client-option service-edit-icon">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={showmore} alt="show" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <div className="action" onClick={() => handleAddProduct(false)}>
                    <span>Add Additional Product</span>
                  </div>
                </Dropdown.Item>
                {/* <Dropdown.Item>
                  <div className="action">
                    <span>Edit Order Quantity</span>
                  </div>
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {/* uncomment this section when predictive-ordering section used */}
      {/* <div className="target-heading select-dropdown-box">
        <Form.Group
          controlId="formInput1"
        >
          <Form.Label column sm="3">
            Select Predictive Ordering Period
          </Form.Label>
          <div className="select-box">
            <Form.Control
              as="select"
              name="predectiveDuration"
              onChange={handleDropdown}
              value={dropDownValue}
            >
              <option value="foreCastSixMonths">Predictive Ordering - 6 Months</option>
              <option value="foreCastThreeMonths"> Predictive Ordering - 3 Months</option>
              <option value="foreCastPerMonth">Predictive Ordering - 1 Month</option>
              <option value="Add">
                Add individual products from the 3-dot menu above
              </option>
            </Form.Control>
          </div>
        </Form.Group>
      </div> */}
      <div className="predective-table">
        <div className="predective-header">
          <h6>
            <span>Product Name</span>
            {' '}
          </h6>
          <h6>Stock on Hand</h6>
          <h6>Order Quantity</h6>
          <h6>Cost Summary</h6>
        </div>
        {chemcicalsData?.length > 0 && chemcicalsData.map((el, index) => (
          <div className="main-predective-content" key={el?._id}>
            <div className="left-predective-content">
              <div className="predective-body-content">
                <p>{el?.renderName}</p>
                <p>
                  {' '}
                  {Number(el?.currentStockItems || 0)?.toFixed(2)}
                  {' '}
                  {getUnit(el?.prodUnit, el?.currentStockItems)}
                </p>
                <p>
                  {el?.predictedOrderQuantity}
                  {' '}
                  {getUnit(el?.prodUnit, el?.predictedOrderQuantity)}
                </p>
                <p>
                  $
                  {parseFloat(el?.overAllCost || 0).toFixed(2)}
                </p>
              </div>
            </div>
            <div className="right-predective-content">
              <div className="content-dropdown">
                <div className="client-option service-edit-icon">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img src={show} alt="show" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <div className="action" onClick={() => handleEditPredictiveProducts(el?._id)}>
                          <span>Edit</span>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div className="action" onClick={() => handleDeleteChemProd(index)}>
                          <span>Delete</span>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="predective-body-content predective-sub-total">
          <div className="predective-sub desktop-view">
            <h6>Sub Total: </h6>
            <h6>
              $
              {currencyComma(orderSummary?.subtotal)}
            </h6>
          </div>
          <div className="predective-sub">
            <h6>GST: </h6>
            <h6>
              $
              {currencyComma(orderSummary?.gst)}
            </h6>
          </div>
          <div className="predective-sub">
            <h6>Total: </h6>
            <h6>
              $
              {currencyComma(orderSummary?.total)}
            </h6>
          </div>
        </div>
        <div className="view-btn">
          <Button
            type="Submit"
            className="btn btn-edit"
          >
            {' '}
            Cancel
            {' '}
          </Button>
          <Button
            type="Submit"
            className="btn btn-save"
            onClick={handleOrderDetails}
          >
            {' '}
            Confirm & Paying
            {' '}
          </Button>
        </div>
      </div>
      {toggleOrderDetails && (
      <OrderDetailsPopup
        show={toggleOrderDetails}
        HandleOpenClose={handleOrderDetails}
        orderSummary={orderSummary}
        displayPredictiveChemicals={chemcicalsData}
        setShowPayment={setShowPayment}
        setUserFormInfo={setUserFormInfo}
      />
      )}

      {addProduct ? (
        <AddProductOrderDetails
          show={addProduct}
          handleClose={handleAddProduct}
          allChemicalsProducts={allChemicalsProducts}
          productDetails={productDetails}
          itemVolume={itemVolume}
          handleSelectNames={handleSelectNames}
          handleItemVolume={handleItemVolume}
          handleOrderQuantityChange={handleOrderQuantityChange}
          handleCloseProductForm={handleAddProduct}
          handleAddPredictiveChemical={handleAddPredictiveChemical}
          poolSetUpAllChemicals={deletedChemProds}
        />
      ) : null}

      {showPayment ? (
        <MyPaymentForm
          openPaymentForm={showPayment}
          handleToken={handleToken}
          handleClosePaymentForm={handleClosePaymentForm}
          paymentResult={paymentResult}
          disableButton={disableButton}
        />
      ) : null}

    </div>
  );
};

OrderInventory.propTypes = {
  displayPredictiveChemicals: PropTypes.arrayOf.isRequired,
  taxData: PropTypes.objectOf.isRequired,

};
export default OrderInventory;
