/* eslint-disable max-len */
import React from 'react';
import {
  Modal, Form, Col, Row, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import edit from '../../assets/home/grey-edit.png';
import sorting from '../../assets/home/sort.png';
import cancel from '../../assets/home/black-icon.png';

const AppointmentChecklist = ({
  show,
  HandleOpenClose,
  handleManageChecklist,
  checklistName,
  setChecklistName,
  error,
}) => (
  <Modal
    size="lg"
    show={show}
    onHide={HandleOpenClose}
    aria-labelledby="contained-modal-title-vcenter"
    top
    centered
    className="service-modal pool-maintenance-checklist-modal"
  >
    <div className="staff-close">
      <i
        className="fa fa-times"
        onClick={HandleOpenClose}
        aria-hidden="true"
      />
    </div>
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Add Pool Maintenance Checklist
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-md-12">
          <div className="modal-form">
            <Form>
              <Form.Group
                as={Row}
                controlId="formInput1"
                className="italic-text"
              >
                <Form.Label column sm="3">
                  Pool Maintenance Checklist
                  <span className="required-field">*</span>
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    name="category"
                    placeholder="Enter name of Pool Maintenance Checklist"
                    value={checklistName}
                    onChange={(e) => setChecklistName(e.target.value)}
                    className={`${error === 'checklistName'
                      ? 'error-text'
                      : ''
                    }`}
                  />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="col-md-12">
          <div className="view-btn">
            <Button
              type="submit"
              className="btn btn-save"
              onClick={handleManageChecklist}
            >
              Save/Update
            </Button>
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);

AppointmentChecklist.propTypes = {
  show: PropTypes.bool.isRequired,
  HandleOpenClose: PropTypes.func.isRequired,
  handleManageChecklist: PropTypes.func.isRequired,
  checklistName: PropTypes.string.isRequired,
  setChecklistName: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
};

export default AppointmentChecklist;
