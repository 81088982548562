import React, { useState, useEffect } from 'react';
import {
  Button, Accordion, Card, Dropdown,
} from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import moment from '../../functions/moment';

import showmore from '../../assets/home/showmore.png';
import whiteedit from '../../assets/home/white-edit.png';
import greyedit from '../../assets/home/grey-edit.png';
import greydelete from '../../assets/home/grey-delete.png';
import greyplus from '../../assets/home/plus.png';
import AddOrderProduct from './addOrderProduct';
import AddProductPopup from './addProductPopup';
import { userInstance } from '../../config/axios';
import { successMsgTime } from '../../functions/intervalTime';
import DeletePopUp from '../DeletePopUp';
import NoData from '../NoData/noData';
import toastMessage from '../../functions/toastMessage';

const RemoveProduct = () => {
  const [products, setProducts] = useState([]);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const paraText = 'Are you sure to do this?';
  const [popUpText, setPopUpText] = useState('');
  const [delData, setDelData] = useState({});
  const [removeProduct, setRemoveProduct] = useState(false);
  const handleRemoveProduct = () => {
    setRemoveProduct(!removeProduct);
  };

  const getProducts = async () => {
    const res = await userInstance().get('/inventory/product-add?type=Remove');
    if (res.data.code === 200) {
      setProducts(res.data.results);
    }
  };
  useEffect(() => {
    getProducts();
  }, []);

  const handleRevert = async (_id) => {
    try {
      const res = await userInstance().delete(`/inventory/product-add/${_id}?deleteType=Add`);
      const { code, msg } = res?.data || {};
      if (code === 201) {
        getProducts();
        toastMessage('success',
          msg);
      }
    } catch (error) {
      console.log('error in revert order->>>', error);
    }
  };

  const handleDeleteOrder = (_id) => {
    setPopUpText('Delete Product?');
    setDeletePopUp(true);
    setDelData({
      _id,
    });
  };

  const handlePopUpChange = (val) => {
    const {
      _id,
    } = delData;
    if (val === 'Yes') {
      handleDeleteOrderApi(_id);
    } else {
      setDeletePopUp(false);
    }
  };

  const handleDeleteOrderApi = async (_id) => {
    try {
      const res = await userInstance().delete(`/inventory/product-add/${_id}?deleteType=PermanentDelete`);
      const { code, msg } = res?.data || {};
      if (code === 201) {
        getProducts();
        toastMessage('success',
          msg);
        setDeletePopUp(false);
      }
    } catch (error) {
      console.log('error in delete order->>>', error);
    }
  };

  return (
    <div className="product-service-container inventory-list add-product-page">
      <div className="service-container">
        <div className="service-title">
          <h4>Services</h4>
          <span>
            <i className="fa fa-plus" />
          </span>
        </div>
      </div>
      <div className="table-data">
        <div className="service-mbl">
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <div className="service-container">
                  <div className="service-title title-service">
                    <h4>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Remove Product
                      </Accordion.Toggle>
                    </h4>
                    {/* <div className="services-header">
                      <span>
                        <i className="fa fa-plus" onClick={handleRemoveProduct} />
                      </span>
                    </div> */}
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <div className="mob-table">
                  {products.length > 0 ? (
                    <>
                      {products.map((el) => (
                        <Accordion className="mob-accordion outer-accordion">
                          <Card.Header className="sub-category">
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="1"
                            >
                              <div className="product-service-list">
                                <i
                                  className="fas far fa-chevron-circle-up"
                                />
                                <span>{el?.renderName || ''}</span>
                              </div>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <ul className="grid-list product-inventory-grid">
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3>
                                    Name
                                    {/* <span> (as will display on invoices) </span> */}
                                  </h3>
                                  <div className="grid-content">
                                    {el?.name || ''}
                                  </div>
                                </div>
                              </li>
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3 className="">Date</h3>
                                  <div className="grid-content">
                                    {moment(el?.productDate).format('D MMM, yyyy')}
                                  </div>
                                </div>
                              </li>
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3 className="">
                                    Quantity
                                  </h3>
                                  <div className="grid-content">
                                    {el?.currentStockItems || 0}
                                  </div>
                                </div>
                              </li>
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3>Price</h3>
                                  <div className="grid-content">
                                    $
                                    {parseFloat(el?.cost).toFixed(2)}
                                  </div>
                                </div>
                              </li>
                              <li className="grid-list-item actions">
                                <div className="grid-box">
                                  <h3>Actions</h3>
                                  <div className="action-btn">
                                    <Button
                                      className="edit-btn"
                                      style={{ width: '91px' }}
                                    >
                                      Revert to Add Products
                                      {/* <img src={whiteedit} alt="edit" onClick={() => handleRevert(el._id)} /> */}
                                    </Button>
                                  </div>
                                  <div className="action-btn" onClick={() => handleDeleteOrder(el._id)}>
                                    <Button className="delete-btn">
                                      <i className="far fa-trash-alt" />
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </Accordion.Collapse>
                        </Accordion>
                      ))}
                    </>
                  ) : <NoData />}
                </div>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
        {deletePopUp && (
        <DeletePopUp
          handlePopUpChange={handlePopUpChange}
          paraText={paraText}
          PopUpText={popUpText}
        />
        )}
      </div>
      <AddProductPopup show={removeProduct} HandleOpenClose={handleRemoveProduct} title="Remove Product" />
    </div>
  );
};
export default RemoveProduct;
