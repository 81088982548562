import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { IoIosSave } from 'react-icons/io';
import TextField from '../components/textField';
import Button from '../components/button';
import LoadingButton from '../components/loadingButton';
import TextArea from '../components/textarea';
import toastMessage from '../../../functions/toastMessage';
import { userInstance } from '../../../config/axios';

const AddAdvertisementModal = (props) => {
  const {
    show,
    handleClose,
    advertisements,
    setAdvertisements,
    getAdvertiesments,
    selectedItem,
    isEditMode,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const handleChangeTitle = (value) => {
    setTitle(value);
  };

  const handleChangeContent = (value) => {
    setContent(value);
  };

  const handleSaveAdvertisement = async () => {
    if (title === '') {
      toastMessage('error', 'Please input the title.');
      return;
    }

    if (content === '') {
      toastMessage('error', 'Please input the content.');
      return;
    }

    try {
      setIsSaving(true);

      // let resonse = await axios.post(`${CHATBOT_SETTINGS_API}/upload-file`, data);
      if (!isEditMode) {
        let response = await userInstance().post('/chatbotSettings/addAdvertisement', { title, content });
        const { msg } = response.data;
        if (response.status === 200) {
          const { addedAdvertisement } = response.data;
          setAdvertisements((prev) => [...prev, addedAdvertisement]);
          toastMessage('success', msg);
        } else {
          toastMessage('error', msg);
        }
        initialize();
        handleClose();
      } else {
        let response = await userInstance().put(`/chatbotSettings/updateAdvertisement?id=${selectedItem._id}`, { title, content });
        const { msg, updatedAdvertisement } = response.data;
        if (response.status === 200) {
          let _advertisements = advertisements.slice();
          let findIndex = _advertisements.findIndex((item) => item._id === updatedAdvertisement._id);
          if (findIndex !== -1) {
            _advertisements[findIndex].title = updatedAdvertisement.title;
            _advertisements[findIndex].content = updatedAdvertisement.content;
            setAdvertisements(_advertisements);
          }
          toastMessage('success', msg);
        } else {
          toastMessage('error', msg);
        }
        initialize();
        handleClose();
      }
    } catch (error) {
      console.log('error', error);
    }
    setIsSaving(false);
  };
  const initialize = () => {
    setTitle('');
    setContent('');
  };

  useEffect(() => {
    if (selectedItem !== null) {
      if (isEditMode) {
        setTitle(selectedItem.title);
        setContent(selectedItem.content);
      } else {
        initialize();
      }
    } else {
      initialize();
    }
  }, [selectedItem, isEditMode, show]);

  return (
    <>
      <Modal
        show={show}
        centered
        dialogClassName="chatbot-settings-modal-container modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {isEditMode ? 'Edit Advertisement' : 'Add Advertisement'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-3">
            <TextField
              type="text"
              label="Title"
              placeholder=""
              value={title}
              handleChangeValue={handleChangeTitle}
            />
            <TextArea
              label="Content"
              placeholder=""
              value={content}
              row={10}
              handleChangeValue={handleChangeContent}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button label="Cancel" handleClickBtn={handleClose} disabled={isSaving} />
            <LoadingButton
              isLoading={isSaving}
              icon={
                <IoIosSave size={18} style={{ marginRight: '16px' }} />
              }
              label="Save"
              handleClickBtn={handleSaveAdvertisement}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AddAdvertisementModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  advertisements: PropTypes.arrayOf.isRequired,
  setAdvertisements: PropTypes.func.isRequired,
  getAdvertiesments: PropTypes.func.isRequired,
  selectedItem: PropTypes.objectOf.isRequired,
  isEditMode: PropTypes.bool.isRequired,
};

export default AddAdvertisementModal;
