/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Dropdown, Spinner, 
} from 'react-bootstrap';
import { calculateCapacity } from '../../functions/newEvent';

function UserPoolPopup(props) {
  const {
    handleUserPoolPopUp,
    userPoolPopUp,
    poolFinishArray,
    chlorinationArray,
    poolDepthArray,
    userPoolData,
    handleUserPoolChange,
    handleUserPoolUpdate,
    isUsImperial,
    validationData,
    isDisabled,
  } = props;
  // const calculateCapacity = (poolDepth, poolWidth, poolHeight, diameter, deepEnd, shallowEnd) => {
  //   let poolCapacity = 0;
  //   console.log('cominginthissusiou', {
  //     poolDepth, poolWidth, poolHeight, diameter, deepEnd, shallowEnd,
  //   });
  //   let poolMsg = '';
  //   if (poolDepth === 'Average Depth - Rectangular Pool') {
  //     const calValue = !isUsImperial ? 1250 : 5.5 * 7.48;
  //     poolCapacity = poolWidth * poolHeight * calValue;
  //     // console.log('asdfasd', poolCapacity);
  //   } else if (poolDepth === 'Average Depth - Circular pool') {
  //     const calValue = !isUsImperial ? 1250 * 0.78 : 5.5 * 7.48;
  //     poolCapacity = diameter * diameter * calValue;
  //   } else if (poolDepth === 'Specific Depths - Rectangular Pool') {
  //     const calValue = !isUsImperial ? 1000 : 5.5 * 7.48;
  //     const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
  //     // console.log({ shallowCalculation, calValue });
  //     poolCapacity = poolWidth * poolHeight * shallowCalculation * calValue;
  //     // console.log({ poolCapacity });
  //   } else if (poolDepth === 'Specific Depths - Circular Pool') {
  //     const calValue = !isUsImperial ? 780 : 5.5 * 7.48;
  //     const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
  //     poolCapacity = diameter * diameter * shallowCalculation * calValue;
  //   }

  //   poolCapacity = !isUsImperial
  //     ? Math.floor(parseInt(poolCapacity, 10) / 1000) * 1000
  //     : Math.round(parseInt(poolCapacity, 10) / 100) * 100;
  //   return poolCapacity;
  // };
  return (
    <Modal
      size="lg"
      show={userPoolPopUp}
      onHide={handleUserPoolPopUp}
      aria-labelledby="contained-modal-title-vcenter"
      top
      centered
      className="staff-modal"
    >
      <div className="staff-close">
        <i
          className="fa fa-times"
          onClick={handleUserPoolPopUp}
          aria-hidden="true"
        />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add User Pool
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form user-pool">
          <Form>
            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="form-label">Pool Finish</Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      value={userPoolData?.poolFinish}
                      name="poolFinish"
                      onChange={handleUserPoolChange}
                    >
                      {poolFinishArray?.map((el) => (
                        <option key={el}>{el}</option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label pool-depth-label">
                    Pool Depth
                  </Form.Label>
                  <div className="select-box">
                    {/* <i className="fas fa-sort-down" /> */}
                    <Form.Control
                      as="select"
                      value={userPoolData?.poolDepth}
                      name="poolDepth"
                      onChange={handleUserPoolChange}
                    >
                      {poolDepthArray?.map((el) => (
                        <option key={el}>{el}</option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
              {(userPoolData?.poolDepth
                === 'Average Depth - Rectangular Pool'
                || userPoolData?.poolDepth
                  === 'Specific Depths - Rectangular Pool') && (
                  <div className="col-md-6">
                    <Form.Group>
                      <Form.Label className="form-label">
                        {`Pool Size (${!isUsImperial ? 'metres' : 'feet'})`}
                      </Form.Label>
                      <div className="pool-size-wrapper">
                        {/* {console.log('validationData', validationData)} */}
                        <Form.Control
                          type="text"
                          name="poolWidth"
                          value={userPoolData?.poolWidth}
                          onChange={handleUserPoolChange}
                          className={
                          validationData?.poolWidth && !userPoolData?.poolWidth
                            ? 'error-text'
                            : ''
                        }
                        />
                        <span>X</span>
                        <Form.Control
                          type="text"
                          name="poolHeight"
                          value={userPoolData?.poolHeight}
                          onChange={handleUserPoolChange}
                          className={
                          validationData?.poolHeight
                          && !userPoolData?.poolHeight
                            ? 'error-text'
                            : ''
                        }
                        />
                      </div>
                    </Form.Group>
                  </div>
              )}
              {(userPoolData?.poolDepth === 'Average Depth - Circular pool'
                || userPoolData?.poolDepth
                  === 'Specific Depths - Circular Pool') && (
                  <div className="col-md-6">
                    <Form.Group
                      controlId="formBasicInput-1"
                      className="italic-text"
                    >
                      <Form.Label>
                        {`Pool Diameter (${!isUsImperial ? 'metres' : 'feet'})`}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="diameter"
                        value={userPoolData?.diameter}
                        onChange={handleUserPoolChange}
                        className={
                        validationData?.diameter && !userPoolData?.diameter
                          ? 'error-text'
                          : ''
                      }
                      />
                    </Form.Group>
                  </div>
              )}
              {(userPoolData?.poolDepth
                === 'Specific Depths - Rectangular Pool'
                || userPoolData?.poolDepth
                  === 'Specific Depths - Circular Pool') && (
                  <>
                    <div className="col-md-6">
                      <Form.Group
                        controlId="formBasicInput-1"
                        className="italic-text"
                      >
                        <Form.Label>Shallow End Depth</Form.Label>
                        <Form.Control
                          type="text"
                          name="shallowEnd"
                          value={userPoolData?.shallowEnd}
                          onChange={handleUserPoolChange}
                          className={
                          validationData?.shallowEnd
                          && !userPoolData?.shallowEnd
                            ? 'error-text'
                            : ''
                        }
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        controlId="formBasicInput-1"
                        className="italic-text"
                      >
                        <Form.Label>Deep End Depth</Form.Label>
                        <Form.Control
                          type="text"
                          name="deepEnd"
                          value={userPoolData?.deepEnd}
                          onChange={handleUserPoolChange}
                          className={
                          validationData?.deepEnd && !userPoolData?.deepEnd
                            ? 'error-text'
                            : ''
                        }
                        />
                      </Form.Group>
                    </div>
                  </>
              )}
              <div className="col-md-6">
                <Form.Group
                  controlId="formBasicInput-1"
                  className="italic-text"
                >
                  <Form.Label>Pool Size</Form.Label>
                  <Form.Control
                    type="text"
                    name="poolCapacity"
                    value={`${
                      !userPoolData.poolMsg
                        ? `${calculateCapacity(
                          userPoolData?.poolDepth,
                          Number(userPoolData?.poolWidth),
                          Number(userPoolData?.poolHeight),
                          Number(userPoolData?.diameter),
                          Number(userPoolData?.deepEnd),
                          Number(userPoolData?.shallowEnd),
                          isUsImperial,
                        )} ${!isUsImperial ? 'litres' : 'gallons'}`
                        : userPoolData.poolMsg
                    }`}
                    disabled
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="form-label">Chlorination</Form.Label>
                  <div className="select-box">
                    {/* <i className="fas fa-sort-down" /> */}
                    <Form.Control
                      as="select"
                      value={userPoolData?.chlorinationType}
                      name="chlorinationType"
                      onChange={handleUserPoolChange}
                    >
                      {chlorinationArray?.map((el) => (
                        <option key={el?.name} value={el?.value}>
                          {el?.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="form-label">
                    {userPoolData?.chlorinationType === 'Manually Chlorinated'
                      ? 'Pool Type'
                      : 'Chlorinator Model'}
                  </Form.Label>
                  <div className="select-box">
                    {/* <i className="fas fa-sort-down" /> */}
                    <Form.Control
                      as="select"
                      value={userPoolData?.selectedModel}
                      name="selectedModel"
                      onChange={handleUserPoolChange}
                    >
                      {userPoolData?.chlorineModel?.length > 0
                        && userPoolData.chlorineModel.map((el, index) => {
                          const keyValue = (el.name || el.brandName || el) + index;
                          return (
                            <option
                              value={el.name || el.brandName || el}
                              key={keyValue}
                            >
                              {el.name || el.brandName || el}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="view-btn-desktop-view view-btn">
                  <Button
                    type="button"
                    className="btn btn-edit"
                    onClick={handleUserPoolPopUp}
                  >
                    {' '}
                    Cancel
                    {' '}
                  </Button>

                  <Button
                    type="button"
                    className="btn btn-save"
                    onClick={
                      !isDisabled ? () => handleUserPoolUpdate() : () => {}
                    }
                  >
                    {' '}
                    {isDisabled ? (
                      <Spinner animation="border" />
                    ) : (
                      'Save/Update'
                    )}
                    {' '}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
UserPoolPopup.propTypes = {
  userPoolPopUp: PropTypes.func.isRequired,
  handleUserPoolPopUp: PropTypes.func.isRequired,
  poolFinishArray: PropTypes.arrayOf.isRequired,
  chlorinationArray: PropTypes.arrayOf.isRequired,
  poolDepthArray: PropTypes.arrayOf.isRequired,
  userPoolData: PropTypes.objectOf.isRequired,
  handleUserPoolChange: PropTypes.func.isRequired,
  handleUserPoolUpdate: PropTypes.func.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
  validationData: PropTypes.objectOf.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default UserPoolPopup;
