import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';

const AddjobConfimationPopup = ({ setOpenConfirmBox, handleWaterTestJob, chemicalArray }) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === 'duration-picker time-picker-modal'
          ) {
            setOpenConfirmBox(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [addChemical, setAddChemical] = useState('yes');
  const handleWaterTestApp = (data) => {
    setAddChemical(data);
  };
  const handleCancel = () => {
    setOpenConfirmBox(false);
  };
  return (
    <div className="duration-picker time-picker-modal">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <div className="appointment-view">
            <div className="pending-water-test" onClick={() => handleWaterTestApp('yes')}>
              <h3>Save to Water Tests</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    value="yes"
                    onChange={(e) => handleWaterTestApp(e.target.value)}
                    checked={addChemical === 'yes'}
                  />
                </div>
              </div>
            </div>
            <div className="pending-water-test" onClick={() => handleWaterTestApp('no')}>
              <h3>Don’t Save to Water Tests</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    value="no"
                    onChange={(e) => handleWaterTestApp(e.target.value)}
                    checked={addChemical === 'no'}
                  />
                </div>
              </div>
            </div>
            <div className="event-popup-btn footer-btn view-btn">
              <Button
                className="appointment-btn btn-edit"
                type="button"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn btn-save"
                onClick={() => handleWaterTestJob(addChemical)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
AddjobConfimationPopup.propTypes = {
  setOpenConfirmBox: PropTypes.func.isRequired,
  handleWaterTestJob: PropTypes.func.isRequired,
  chemicalArray: PropTypes.arrayOf.isRequired,
};
export default AddjobConfimationPopup;
