import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const BlackSpotPool = (props) => {
  const {
    handleGreenChange, greenTreatment, handleBlackTreatment, greenSelect,
  } = props;
  return (
    <>
      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>
              Black-Spot Treatment
            </Form.Label>
          </Col>
          <Col>
            <div className="select-box">
              <Form.Control
                as="select"
                custom
                value={greenTreatment}
                onChange={handleBlackTreatment}
              >
                <option value="Black-Spot Remover">Black-Spot Remover</option>
                <option value="Black-Spot Remover + Clarifier">Black-Spot Remover + Clarifier</option>
                <option value="Black-Spot Remover + Flock">Black-Spot Remover + Flock</option>

                <option value="Liquid Chlorine">
                  Liquid Chlorine
                </option>
                <option value="Liquid Chlorine + Clarifier">
                  Liquid Chlorine + Clarifier
                </option>
                <option value="Liquid Chlorine + Flock">
                  {' '}
                  Liquid Chlorine + Flock
                </option>
                <option value="Granular Chlorine">
                  Granular Chlorine
                </option>
                <option value="Granular Chlorine + Clarifier">
                  Granular Chlorine + Clarifier
                </option>
                <option value="Granular Chlorine + Flock">
                  Granular Chlorine + Flock
                </option>
              </Form.Control>
            </div>
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group
        controlId="exampleForm.SelectCustom"
        className="green-pool-color"
      >
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>
              Black-Spot Intensity
            </Form.Label>
          </Col>
          <Col>
            <div className="pool-color choose-color blackSpotFont">
              <div className="select-color" onClick={() => handleGreenChange('black-spotalgae1(minimum)')}>
                <h6 className="black-one blackCalci3">Black-Spot 1 (minimum)</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="black-spotalgae1(minimum)"
                      checked={
                          greenSelect === 'black-spotalgae1(minimum)' ? true : ''
                        }
                      // onChange={handleGreenChange}
                    />
                  </div>
                </div>
              </div>
              <div className="select-color" onClick={() => handleGreenChange('black-spotalgae2(light)')}>
                <h6 className="black-two blackCalci3">Black-Spot 2 (light)</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="black-spotalgae2(light)"
                      checked={
                          greenSelect === 'black-spotalgae2(light)' ? true : ''
                        }
                      // onChange={handleGreenChange}
                    />
                  </div>
                </div>
              </div>
              <div className="select-color" onClick={() => handleGreenChange('black-spotalgae3(medium)')}>
                <h6 className="black-three blackCalci3">Black-Spot 3 (medium)</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="black-spotalgae3(medium)"
                      checked={
                          greenSelect === 'black-spotalgae3(medium)' ? true : ''
                        }
                      // onChange={handleGreenChange}
                    />
                  </div>
                </div>
              </div>
              <div className="select-color" onClick={() => handleGreenChange('black-spotalgae4(heavy)')}>
                <h6 className="black-four blackCalci4">Black-Spot 4 (heavy)</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="black-spotalgae4(heavy)"
                      checked={
                          greenSelect === 'black-spotalgae4(heavy)' ? true : ''
                        }
                      // onChange={handleGreenChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Form.Row>
      </Form.Group>

    </>
  );
};
BlackSpotPool.propTypes = {
  handleGreenChange: PropTypes.func.isRequired,
  greenTreatment: PropTypes.string.isRequired,
  handleBlackTreatment: PropTypes.func.isRequired,
  greenSelect: PropTypes.string.isRequired,

};

export default BlackSpotPool;
