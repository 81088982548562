/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-quotes */
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from '../../../functions/moment';
import SquareCheckout from './squareCheckout';
import toastMessage from '../../../functions/toastMessage';
import Loader from '../../loader/loader';

const PaymentDetails = ({
  user,
  smallLoader,
  paymentLoader,
  handleToken,
  returnPopUp,
  handleAction,
  setDurationPicker,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === 'duration-picker time-picker-modal'
          ) {
            setDurationPicker(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // console.log('this is the user', user);

  return (
    <div className='duration-picker time-picker-modal payment-details-popup'>
      <div>
        <div className='time-keeper-popup duration-popup' ref={wrapperRef}>
          <div className='back-popup' onClick={() => handleAction(returnPopUp)}>
            <i className='las la-times' />
          </div>
          <div className='billing-details-section'>
            <h3 className='section-title'>Billing Summary </h3>
            <div className='billl-content'>
              <div className='upper-section'>
                <p className='bill-label'>
                  Subscription Duration:
                  <span>{user.duration} months</span>
                  <span>
                    {' '}
                    {`(${moment(user.startsubscriptionDate).format(
                      'D MMM YYYY'
                    )}`}{' '}
                    -{' '}
                    {`${moment(user.endsubscriptionDate).format(
                      'D MMM YYYY'
                    )})`}{' '}
                  </span>
                </p>
              </div>
              <p className='bill-label'>
                No. of Pools:
                <span>
                  {user.numberOfPools}{' '}
                  {user.numberOfPools === 1 || user.numberOfPools === '1'
                    ? 'Pool'
                    : 'Pools'}
                </span>
              </p>
              <p className='bill-label'>
                No. of Users:
                <span>{user.numberUsers} Users</span>
              </p>
            </div>
            {user.extraCost && (
              <div className='total-cost'>
                Cost to Pay:
                <span>AUD ${user?.extraCost?.toFixed(2)}</span>
              </div>
            )}
            <div className='total-cost'>
              Total Cost:
              <span>AUD ${user?.totalCost?.toFixed(2)}</span>
            </div>

            {/* <h3 className="section-title">Card Details</h3>
            <div className="view-btn">
              <Button className="appointment-btn btn-save">
                Pay Now
              </Button>
            </div> */}

            <h3 className='section-title'>Card Details</h3>
            <div
              className={
                smallLoader ? 'show-loader-payment' : 'hide-loader-payment'
              }
            >
              <div className='video-loader'>
                <Spinner animation='border' />
              </div>

              <div className='payment-form'>
                <SquareCheckout
                  handleToken={handleToken}
                  amountDisplay={
                    user.extraCost ? user.extraCost : user.totalCost
                  }
                  paymentLoader={paymentLoader}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
PaymentDetails.propTypes = {
  user: PropTypes.objectOf.isRequired,
  smallLoader: PropTypes.bool.isRequired,
  paymentLoader: PropTypes.func.isRequired,
  handleToken: PropTypes.func.isRequired,
  returnPopUp: PropTypes.string.isRequired,
  handleAction: PropTypes.string.isRequired,
  setDurationPicker: PropTypes.func.isRequired,
};
export default PaymentDetails;
