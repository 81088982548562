import React, { useState, useEffect, useContext } from 'react';
import { Dropdown, Form, Button } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import moment from '../../functions/moment';
import Adminwrapper from '../admin/adminwrapper/wrapper';
import arrow from '../../assets/home/arrowdown.png';
import { userInstance } from '../../config/axios';
import showmore from '../../assets/home/showmoreblue.png';
import target from '../../assets/home/icon/target.png';
import PreviewInvoice from './previewInvoice';
import { queryString } from '../../functions';
import EditInvoice from '../invoices/editInvoice';
import Loader from '../loader/loader';
import PreviewInvoiceConfirm from './previewInvoiceConfirm';
import toastMessage from '../../functions/toastMessage';

const Awaitingapprovals = () => {
  const history = useHistory();

  const {
    clientid, viewtype, from, cal, routeType, namee,
  } = queryString();

  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [mergeInvoices, setMergeInvoices] = useState(false);
  const [preview, setPreview] = useState(false);
  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(1);
  const [invoiceData, setInvoiceData] = useState({});
  const [mergeAppointment, setMergeAppointment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detailedInvoice, setDetailedInvoice] = useState({});
  const [showMainPage, setShowMainPage] = useState(true);
  const [invoiceDetail, setInvoiceDetail] = useState(false);
  const [customerAllDue, setCustomerAllDue] = useState(0);
  const [pidAppointmentDetails, setPidAppointmentDetails] = useState([]);
  const limit = 4;
  const invId = localStorage.getItem('invId');
  const preSkip = localStorage.getItem('invoiceSkip');
  const [loader, setLoader] = useState(true);
  const [confirmPreview, setConfirmPreview] = useState(false);
  const [addappt, setAddAppt] = useState('defaultTemp');

  useEffect(() => {
    getPendingAppointments();

    if (from === 'viewApp') {
      localStorage.removeItem('invoiceSkip');
    }
    // if (cal) {
    //   setInvoiceDetail(true);
    // }
  }, [limit, skip, viewtype]);

  const getPendingAppointments = async () => {
    try {
      // console.log('skip', skip, preSkip);
      const newSkip = invId && cal === 'cal' ? preSkip : skip;
      // console.log('clientId', clientid, newSkip, limit, invId && cal);
      const response = await userInstance().get(`/appointment/getPendingAppointments?limit=${limit}&skip=${newSkip}}&client=${clientid}`);
      // console.log('response===>>>>', response);
      const { appointments, count, pidAppointmentsDataAll } = response.data;

      // console.log('appointments, count, pidAppointmentsDataAll', appointments, count, pidAppointmentsDataAll);
      // const list = appointments;
      setLoader(false);
      let pIdArraydata = [];
      const newLists = pidAppointmentsDataAll.filter(
        (el3) => el3 !== null,
      );
      newLists.forEach((el) => {
        pIdArraydata = [...pIdArraydata, ...el];
      });
      const pIdArraydataFiltered = pIdArraydata.filter((value, index, self) => index === self.findIndex((t) => (
        t.pid === value.pid
      )));

      const list = appointments.filter(
        (apps) => apps.client,
      );
      list.sort((a, b) => a.client.firstname.localeCompare(b.client.firstname));
      setPendingAppointments(list);
      setPageCounts(count);
      setPidAppointmentDetails(pIdArraydataFiltered);
    } catch (error) {
      console.log(error);
    }
  };
  const pageCount = Math.ceil(pageCounts / limit);
  const handlePageClick = ({ selected }) => {
    console.log('selected========>', selected);
    setSkip(selected * limit);
    localStorage.setItem('invoiceSkip', selected * limit);
  };

  const handleAppointmentChecked = (e, k) => {
    const old = [...pendingAppointments];
    old[k].checked = e.target.checked;
    setPendingAppointments(old);
  };

  const handleAppointmentChecked1 = (el, i) => {
    let status = el.checked;
    const old = [...pendingAppointments];
    old[i].checked = !status;
    setPendingAppointments(old);
  };

  const previewInvoice2 = async () => {
    const old = [...pendingAppointments];
    const filterCheckedAppointments = old.filter((el) => el.checked);
    const balancresponse = await userInstance().get(
      `/invoice/getCustomerDues?id=${filterCheckedAppointments[0].client._id}`,
    );
    setLoader(false);
    const { totalBalanceDue } = balancresponse.data;
    // console.log('balancresponse---', balancresponse);
    // console.log('filterCheckedAppointments[0].client._id---', filterCheckedAppointments[0].client._id);
    setCustomerAllDue(totalBalanceDue);
    if (filterCheckedAppointments.length === 0) {
      toastMessage('error', 'Select an Appointment to Preview How Invoice Will Display');
    } else if (filterCheckedAppointments.length > 1 && !mergeInvoices) {
      toastMessage('error', 'Select Only One Appointment to Preview How Invoice Will Display');
    } else {
      if (mergeInvoices) {
        const mergeData = await MergeAppointments();
        setMergeAppointment(mergeData);
      } else {
        setInvoiceData(filterCheckedAppointments[0].Invoice);
      }
      handlePreviewInvoice();
    }
  };

  const MergeAppointments = async () => {
    const old = [...pendingAppointments];
    let mergeAppointmentArray = [];
    const filterCheckedAppointments = old.filter((el) => el.checked);
    if (filterCheckedAppointments.length === 0) {
      toastMessage('error', 'Select an Appointment to Merge the Invoice');
    } else {
      var valueArr = filterCheckedAppointments.map((item) => item.client._id);
      var isDuplicate = valueArr.some((item, idx) => valueArr.indexOf(item) !== idx);
      // console.log(isDuplicate);
      if (!isDuplicate) {
        toastMessage('error', 'Only Appointments for the Same Customer Can be Merged');
      } else {
        for (let i = 0; i < filterCheckedAppointments.length; i++) {
          mergeAppointmentArray.push(filterCheckedAppointments[i]._id);
        }
        // console.log(mergeAppointmentArray);
      }
    }
    return mergeAppointmentArray;
  };

  const MergeSendAppointments = async () => {
    setLoading(true);
    const old = [...pendingAppointments];
    let mergeAppointmentArray = [];
    const filterCheckedAppointments = old.filter((el) => el.checked);
    if (filterCheckedAppointments.length === 0) {
      toastMessage('error', 'Select an Appointment to Merge the Invoice');
      setLoading(false);
    } else {
      var valueArr = filterCheckedAppointments.map((item) => item.client._id);
      const toFindDuplicates = valueArr.filter((item, index) => valueArr.indexOf(item) === index);
      // console.log('valueArr==>', toFindDuplicates.length);
      // var isDuplicate = valueArr.some((item, idx) => valueArr.indexOf(item) !== idx);
      // console.log('isDuplicate=>', valueArr, isDuplicate);
      if (toFindDuplicates.length > 1) {
        toastMessage('error', 'Only Appointments for the Same Customer Can be Merged');
        setLoading(false);
      } else {
        for (let i = 0; i < filterCheckedAppointments.length; i++) {
          mergeAppointmentArray.push(filterCheckedAppointments[i]._id);
        }
        // console.log(mergeAppointmentArray);
        const payload = {
          mergeAppointmentArray,
        };
        const response = await userInstance().post('/invoice/mergeInvoice', payload);
        // console.log(response);
        const { msg } = response.data;
        toastMessage('success', msg);
        setLoading(false);
        getPendingAppointments();
      }
    }
  };

  const SendInvoices = async () => {
    setLoading(true);
    const old = [...pendingAppointments];
    const filterCheckedAppointments = old.filter((el) => el.checked);
    if (filterCheckedAppointments.length === 0) {
      toastMessage('error', 'Select an Appointment to Send the Invoice');
      setLoading(false);
    } else {
      let mergeAppointmentArray = [];
      for (let i = 0; i < filterCheckedAppointments.length; i++) {
        mergeAppointmentArray.push(filterCheckedAppointments[i].Invoice._id);
      }
      const payload = {
        mergeAppointmentArray,
      };
      try {
        const response2 = await userInstance().post('xero/changeInvoiceStatus', payload);
      } catch (err) {
        console.log('this is the err', err);
      }
      const response = await userInstance().post('/invoice/sendInvoices', payload);
      const { msg } = response.data;
      toastMessage('success', msg);
      // window.location.href = '/invoice';
      if (clientid) {
        history.push(`/invoice?client=${clientid}`);
      } else {
        history.push('/invoice');
      }
      setLoading(false);
      getPendingAppointments();
    }
  };

  const handlePreviewInvoice = () => {
    setPreview(!preview);
  };

  const backToInvoices = () => {
    // console.log('hdfgfdgfhdgfhjdgfjhdgfjhfgjh');
    if (routeType) {
      const url = clientid ? `/invoice?client=${clientid}&from=viewApp&namee=${namee}&typeRoute=${routeType}` : '/invoice';
      history.push(url);
    } else {
      const url = clientid ? `/invoice?client=${clientid}&from=viewApp` : '/invoice';
      history.push(url);
    }
  };
  const handleInvoice = async (id) => {
    setLoader(true);
    if (routeType) {
      history.push(`/client?clientid=${clientid}&name=${namee}&typeRoute=${routeType}`);
    } else if (clientid && from && cal !== 'cal') {
      history.push(`/approvalappointments?clientid=${clientid}&viewtype=detailPage&from=${from}`);
    } else if (cal !== 'cal') {
      history.push('/approvalappointments?viewtype=detailPage');
    }

    const old = [...pendingAppointments];
    // console.log('oldold=========> 2test', { old, id });
    const filterCheckedAppointments = old.filter((el) => el?.Invoice?._id === id);
    // console.log('filterCheckedAppointments', filterCheckedAppointments);
    // console.log('InvoiceData', invoiceData);

    // console.log('clientAllData', clientAllData);
    // const copyClientData = { ...clientAllData };
    // const fnData = filterCheckedAppointments[0];
    // copyClientData.appointmentData = fnData;

    // localStorage.setItem('apptData', JSON.stringify(copyClientData));

    const balancresponse = await userInstance().get(
      `/invoice/getCustomerDues?id=${filterCheckedAppointments[0]?.client?._id}`,
    );
    const { totalBalanceDue } = balancresponse.data;
    // console.log('balancresponse---', balancresponse);
    // console.log('filterCheckedAppointments[0].client._id---', filterCheckedAppointments[0].client._id);
    setCustomerAllDue(totalBalanceDue);
    setInvoiceData(filterCheckedAppointments[0].Invoice);
    getInvoiceById(id);
    setInvoiceDetail(true);
    setLoader(false);
    if (invId) {
      localStorage.removeItem('invId');
    }
  };
  const getInvoiceById = async (data) => {
    try {
      const response = await userInstance().get(`/invoice/getInvoiceById?id=${data}`);
      const { invoicedetail } = response.data;
      setDetailedInvoice(invoicedetail);
    } catch (error) {
      console.log(error);
    }
  };
  const closeInvoiceDetails = () => {
    setShowMainPage(true);
  };

  const openDirectInvoice = () => {
    handleInvoice(invId);
    // setLoader(false);
  };

  useEffect(() => {
    // console.log('invId---', invId, cal, pendingAppointments);
    if (invId && cal === 'cal' && pendingAppointments.length > 0) {
      // console.log('hellloo go to edit invoice page');
      // setLoader(true);
    //  console.log('invdssd===>>>>>', invId);
      openDirectInvoice(invId);
    }
  }, [pendingAppointments]);
  const handleSelect = async () => {
    setLoader(true);
    await previewInvoice2();
    setConfirmPreview(false);
  };
  const handlePreviewConfirm = () => {
    const old = [...pendingAppointments];
    const filterCheckedAppointments = old.filter((el) => el.checked);
    if (filterCheckedAppointments.length === 0) {
      toastMessage('error', 'Select an Appointment to Preview How Invoice Will Display');
      return;
    } if (filterCheckedAppointments.length > 1 && !mergeInvoices) {
      toastMessage('error', 'Select Only One Appointment to Preview How Invoice Will Display');
      return;
    }
    setConfirmPreview(true);
  };

  return (
    <Adminwrapper>
      {loader ? (
        <Loader />
      ) : (
        <>
          { !invoiceDetail || viewtype === 'listpage' ? (
            <>
              <div className=" pool-set-up invoices-list awaiting-approvals">
                <div className="container">
                  <div className="row">
                    {!preview ? (
                      <div className="col-md-12">
                        <div className="back-option">
                          <span />
                          <i className="fa fa-times" onClick={() => backToInvoices()} aria-hidden="true" />

                        </div>
                        <div className="main-titles-mob">
                          {!mergeInvoices ? (
                            <h3>Awaiting Send Approval</h3>
                          ) : (
                            <h3>
                              Merge Multiple Completed
                              Appointments into a Single
                              Invoice
                            </h3>
                          )}
                          <div className="content-dropdown">
                            <div className="client-option service-edit-icon">
                              <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  <img src={showmore} alt="show" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => setMergeInvoices(false)}>
                                    <div className="action">
                                      <span>
                                        <img src={target} alt="edit" />
                                      </span>
                                      <span>Awating Send Approvals</span>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => setMergeInvoices(true)}>
                                    <div className="action">
                                      <span>
                                        <img src={target} alt="edit" />
                                      </span>
                                      <span>
                                        {' '}
                                        Merge Multiple Completed
                                        Appointments into a Single
                                        Invoice
                                      </span>
                                    </div>
                                  </Dropdown.Item>

                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div className="pool-setup">
                          {/* <div className="target-heading">
                <h6>Saved Invoices</h6>
              </div> */}

                          <div className="invoices-table pool-chemistry-box">
                            <div className="table-section mobile-comp">
                              <div className="invoices-table-left">
                                <div className="invoices-head">
                                  <h6>Customer name, Visit Date & Team Member</h6>
                                  <h6>Amount Due</h6>
                                  {!mergeInvoices ? (
                                    <h6>OK to send</h6>
                                  ) : (
                                    <h6>Merge Invoices</h6>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="table-section desktop-comp">
                              <div className="invoices-table-left">
                                <div className="invoices-head">
                                  <h6>Customer name, Visit Date & Team Member</h6>
                                  <h6>Amount Due</h6>
                                  <h6>Status</h6>
                                </div>
                              </div>
                            </div>
                            {pendingAppointments && pendingAppointments.length > 0 ? (
                              <>
                                {pendingAppointments && pendingAppointments.map((el, i) => (
                                  <div className="awating-new-section">
                                    <div className="table-section mobile-comp">
                                      <div className="invoices-table-left" onClick={() => handleAppointmentChecked1(el, i)}>
                                        <div className="invoice-body">
                                          <div className="customer-name">
                                            {/* {el.client.map((cl) => ( */}
                                            <p>
                                              {' '}
                                              {el.client.firstname}
                                              {' '}
                                              {el.client.lastname}
                                            </p>
                                            {/* ))} */}
                                            <p>
                                              Appointment Date:
                                              {moment(el.appointmentdate).format('DD MMM YYYY')}
                                            </p>
                                            {el.dateData.map((ser) => (
                                              <>
                                                <p>
                                                  team Member:
                                                  {' '}
                                                  {ser.service[0].staffName}
                                                  <span>
                                                    Detailed
                                                  </span>
                                                </p>
                                              </>
                                            ))}

                                          </div>
                                          {el.Invoice && el.Invoice.paymentId.status === 'paid' ? (
                                            <p className="check-paid">
                                              Paid in full $
                                              {el.Invoice && el.Invoice.invoiceTotal}
                                            </p>
                                          ) : (
                                            <p className="">
                                              $
                                              {el.Invoice && el.Invoice.balanceDue}
                                            </p>
                                          )}
                                          <p>
                                            <div className="open-date client-notes">
                                              <div className="basic">
                                                <Form.Check type="checkbox" value={el._id} onChange={(e) => handleAppointmentChecked(e, i)} checked={el.checked || false} />
                                              </div>
                                            </div>
                                          </p>
                                        </div>

                                      </div>
                                      <div className="invoices-table-right" onClick={() => handleInvoice(el.Invoice._id)}>
                                        <img src={arrow} alt="arrow" />
                                      </div>
                                    </div>

                                    <div className="table-section desktop-comp">
                                      <div className="invoices-table-left" onClick={() => handleAppointmentChecked1(el, i)}>
                                        <div className="invoice-body">
                                          <div className="customer-name">
                                            {/* {el.client.map((cl) => ( */}
                                            <p>
                                              {' '}
                                              {el.client.firstname}
                                              {' '}
                                              {el.client.lastname}
                                            </p>
                                            {/* ))} */}
                                            <p>
                                              Appointment Date:
                                              {moment(el.appointmentdate).format('DD MMM YYYY')}
                                            </p>
                                            {el.dateData.map((ser) => (
                                              <>
                                                <p>
                                                  team Member:
                                                  {' '}
                                                  {ser.service[0].staffName}
                                                  {' '}
                                                  <span> Detailed </span>
                                                </p>
                                              </>
                                            ))}

                                          </div>
                                          {el.Invoice && el.Invoice.paymentId.status === 'paid' ? (
                                            <p className="check-paid amt-due">
                                              Paid in full $
                                              {el.Invoice && el.Invoice.invoiceTotal}
                                            </p>
                                          ) : (
                                            <p className="amt-due">
                                              $
                                              {el.Invoice && el.Invoice.balanceDue}
                                            </p>
                                          )}
                                          <p className="status-text">
                                            Approved
                                            <span>22 aug 2022</span>
                                          </p>
                                          {/* <p>
                                            <div className="open-date client-notes">
                                              <div className="basic">
                                                <Form.Check type="checkbox" value={el._id} onChange={(e) => handleAppointmentChecked(e, i)} checked={el.checked || false} />
                                              </div>
                                            </div>
                                          </p> */}
                                        </div>

                                      </div>
                                      <div className="invoices-table-right" onClick={() => handleInvoice(el.Invoice._id)}>
                                        <img src={arrow} alt="arrow" />
                                      </div>
                                    </div>

                                    <p className="visit-color-green">
                                      {el.visitMessage ? el.visitMessage.replace('Visit', '') : '' }
                                      {' '}
                                      {el?.visitIndex && el?.visitIndex > 0 ? el?.status : ''}
                                      { pidAppointmentDetails && pidAppointmentDetails.length > 0 && pidAppointmentDetails.filter((ele) => ele.pid === el.pid)
                                        .map((elem) => (
                                          <span className="visit-color-red">
                                            Awaiting
                                            {
                                          elem.visitMessage ? elem.visitMessage.replace('Visit', '') : ''
                                          }
                                          </span>
                                        ))}
                                    </p>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <p className="no-invoice">No Appointments Found</p>
                            )}

                            {pendingAppointments.length > 0 ? (
                              <>
                                <div className="user-pagination">
                                  <ReactPaginate
                                    previousLabel="← Previous"
                                    nextLabel="Next →"
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName="pagination-chk"
                                    previousLinkClassName="pagination__link"
                                    nextLinkClassName="pagination__link"
                                    disabledClassName="pagination__link--disabled"
                                    activeClassName="pagination__link--active"
                                    breakLabel="..."
                                    marginPagesDisplayed={-2}
                                    pageRangeDisplayed={0}
                                  />
                                </div>
                                <div className="footer-btn view-btn">
                                  {!mergeInvoices && (
                                  <Button
                                    className="btn-delete"
                                    // onClick={() => previewInvoice2()}
                                    onClick={() => handlePreviewConfirm()}
                                  >
                                    Preview Invoice
                                  </Button>
                                  )}
                                  {!mergeInvoices ? (
                                    <Button disabled={loading} className="appointment-btn btn-save" onClick={() => SendInvoices()}>
                                      Send Invoices
                                    </Button>
                                  ) : (
                                    <div className="footer-btn view-btn2">
                                      <Button disabled={loading} className="appointment-btn btn-save" onClick={() => MergeSendAppointments()}>
                                        Merge Invoices
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              null
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <PreviewInvoice invoiceData={invoiceData} mergeInvoices={mergeInvoices} mergeAppointment={mergeAppointment} setPreview={setPreview} customerAllDue={customerAllDue} addappt={addappt} setAddAppt={setAddAppt} />
                    )}
                  </div>
                </div>
              </div>
            </>
          )
            : (
              <EditInvoice invoiceData={invoiceData} setEditInvoice={setInvoiceDetail} customerAllDue={customerAllDue} viewtype="listpage" searchType="awatingSearch" />
            )}

          {confirmPreview && (
          <PreviewInvoiceConfirm confirmPreview={confirmPreview} setConfirmPreview={setConfirmPreview} handleSelect={handleSelect} addappt={addappt} setAddAppt={setAddAppt} />
          )}
        </>
      )}

    </Adminwrapper>

  );
};
export default Awaitingapprovals;
