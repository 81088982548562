const defaultPredictiveOrderChemicals = [
  {
    unit: 'per litre',
    buycost: '1.07',
    sellprice: '3.21',
    name: 'liquidChlorine',
    renderName: 'Liquid Chlorine',
    calciName: 'Liquid',
    unitAndPrice: [
      {
        volume: '1', renderVolume: '1 litre', buycost: '1.07', sellprice: '3.21',
      },
      {
        volume: '5', renderVolume: '5 litres', buycost: '5.07', sellprice: '8.21',
      },
      {
        volume: '15', renderVolume: '15 litres', buycost: '10.07', sellprice: '30.21',
      },
    ],
  },
  {
    unit: 'per kg',
    buycost: '4.08',
    sellprice: '12.24',
    name: 'granularChlorine',
    renderName: 'Granular Chlorine',
    calciName: 'Granular',
    unitAndPrice: [
      {
        volume: '1', renderVolume: '1 kg', buycost: '4.08', sellprice: '12.24',
      },
      {
        volume: '2', renderVolume: '2 kg', buycost: '12.24', sellprice: '24.48',
      },
      {
        volume: '4', renderVolume: '4 kg', buycost: '20.00', sellprice: '30.24',
      },
      {
        volume: '10', renderVolume: '10 kg', buycost: '34.00', sellprice: '50.21',
      },
      {
        volume: '25', renderVolume: '25 kg', buycost: '75.00', sellprice: '100',
      },
      {
        volume: '50', renderVolume: '50 kg', buycost: '120.00', sellprice: '200.00',
      },
    ],
  },
  {
    unit: 'per kg',
    buycost: '26',
    sellprice: '78',
    name: 'sodiumThiosulphate',
    renderName: 'Sodium Thiosulphate',
    calciName: 'SodiumThi',
    unitAndPrice: [
      {
        volume: '0.5', renderVolume: '500 grams', buycost: '13', sellprice: '34',
      },
      {
        volume: '10', renderVolume: '10 kg', buycost: '130', sellprice: '150',
      },
      {
        volume: '25', renderVolume: '25 kg', buycost: '200', sellprice: '230',
      },
    ],
  },
  {
    unit: 'per kg',
    buycost: '10.052',
    sellprice: '45',
    name: 'potassiumMonopersulphate',
    renderName: 'Potassium Monopersulphate',
    calciName: 'Potassium',
    unitAndPrice: [
      {
        volume: '0.5', renderVolume: '500 grams', buycost: '5.09', sellprice: '45',
      },
      {
        volume: '25', renderVolume: '25 kg', buycost: '25.00', sellprice: '130.00',
      },
    ],
  },
  {
    unit: 'per kg',
    buycost: '1.59',
    sellprice: '29.415',
    name: 'sodaAsh',
    renderName: 'Soda Ash',
    calciName: 'PhSodaAsh',
    unitAndPrice: [
      {
        volume: '2', renderVolume: '2 kg', buycost: '1.59', sellprice: '29.415',
      },
      {
        volume: '25', renderVolume: '25 kg', buycost: '3.00', sellprice: '60.00',
      },
    ],
  },
  {
    unit: 'per litre',
    buycost: '2.33',
    sellprice: '12.583',
    name: 'hydrochloricAcid',
    renderName: 'Hydrochloric Acid',
    calciName: 'PhAcid',
    unitAndPrice: [
      {
        volume: '1', renderVolume: '1 litre', buycost: '2.33', sellprice: '12.583',
      },
      {
        volume: '5', renderVolume: '5 litres', buycost: '5.07', sellprice: '30.00',
      },
      {
        volume: '15', renderVolume: '15 litres', buycost: '12.00', sellprice: '40.00',
      },
    ],
  },
  {
    unit: 'per kg',
    buycost: '7.00',
    sellprice: '37.85',
    name: 'dryAcid',
    renderName: 'Dry Acid',
    calciName: 'DryAcid',
    unitAndPrice: [
      {
        volume: '3', renderVolume: '3 kg', buycost: '7.00', sellprice: '37.85',
      },
    ],
  },
  {
    unit: 'per kg',
    buycost: '1.48',
    sellprice: '21.70',
    name: 'buffer',
    renderName: 'Buffer',
    calciName: 'AlkBuffer',
    unitAndPrice: [
      {
        volume: '2', renderVolume: '2 kg', buycost: '2.00', sellprice: '21.70',
      },
      {
        volume: '4', renderVolume: '4 kg', buycost: '3.00', sellprice: '40.70',
      },
      {
        volume: '25', renderVolume: '25 kg', buycost: '10.00', sellprice: '60.70',
      },

    ],
  },
  {
    unit: 'per kg',
    buycost: '3.79',
    sellprice: '25.772',
    name: 'stabiliser',
    renderName: 'Stabiliser',
    calciName: 'Stabiliser',
    unitAndPrice: [
      {
        volume: '1', renderVolume: '1 kg', buycost: '3.79', sellprice: '25.772',
      },
      {
        volume: '2', renderVolume: '2 kg', buycost: '4.05', sellprice: '30.07',
      },
      {
        volume: '25', renderVolume: '25 kg', buycost: '16.00', sellprice: '55.00',
      },

    ],
  },
  {
    unit: 'per litre',
    buycost: '13.98',
    sellprice: '83.88',
    name: 'liquidStabiliser',
    renderName: 'Liquid Stabiliser',
    calciName: 'liquidStab',
    unitAndPrice: [
      {
        volume: '1', renderVolume: '1 litre', buycost: '13.98', sellprice: '83.88',
      },
      {
        volume: '5', renderVolume: '5 litres', buycost: '20.00', sellprice: '130.00',
      },
      {
        volume: '15', renderVolume: '15 litres', buycost: '35.00', sellprice: '160.00',
      },
    ],

  },
  {
    unit: 'per kg',
    buycost: '1.48',
    sellprice: '16.10',
    name: 'calciumRaiser',
    renderName: 'Calcium Raiser',
    calciName: 'CalciumRaiser',
    unitAndPrice: [
      {
        volume: '500', renderVolume: '500 grams', buycost: '1.48', sellprice: '16.10',
      },
      {
        volume: '2', renderVolume: '2 kg', buycost: '2.00', sellprice: '30.00',
      },
      {
        volume: '4', renderVolume: '4 kg', buycost: '3.00', sellprice: '45.00',
      },
      {
        volume: '25', renderVolume: '25 kg', buycost: '10.00', sellprice: '70.00',
      },

    ],
  },
  {
    unit: 'per litre',
    buycost: '14.74',
    sellprice: '81.07',
    name: 'phosphatesRemover',
    renderName: 'Phosphate Remover',
    calciName: 'Phosphates',
    unitAndPrice: [
      {
        volume: '2.5', renderVolume: '2.5 litre', buycost: '14.74', sellprice: '81.07',
      },
      {
        volume: '20', renderVolume: '20 litres', buycost: '25.00', sellprice: '130.00',
      },

    ],
  },
  {
    unit: 'per bag',
    buycost: '7.95',
    sellprice: '30',
    name: 'salt',
    renderName: 'Salt',
    calciName: 'Salt',
    unitAndPrice: [
      {
        volume: '20', renderVolume: '20 kg', buycost: '7.95', sellprice: '30',
      },
    ],
  },
  {
    unit: 'per bag',
    buycost: '32.45',
    sellprice: '65',
    name: 'magnaMinerals',
    renderName: 'Magna Minerals',
    calciName: 'Magna',
    unitAndPrice: [
      {
        volume: '10', renderVolume: '10 kg', buycost: '32.45', sellprice: '65',
      },
    ],
  },
  {
    unit: 'per bag',
    buycost: '24.53',
    sellprice: '65',
    name: 'aquatherepeMinerals',
    renderName: 'Aquatherepe Minerals',
    calciName: 'Aqua',
    unitAndPrice: [
      {
        volume: '10', renderVolume: '10 kg', buycost: '24.53', sellprice: '65',
      },
    ],
  },
  {
    unit: 'per bag',
    buycost: '50.69',
    sellprice: '130',
    name: 'aquatherepeTransform',
    renderName: 'Aquatherepe Transform',
    calciName: 'Convert',
    unitAndPrice: [
      {
        volume: '10', renderVolume: '10 kg', buycost: '50.69', sellprice: '130',
      },
    ],
  },
  {
    unit: 'per litre',
    buycost: '4.60',
    sellprice: '45.40',
    name: 'algacide',
    renderName: 'Zodiac Pool Long Life Algaecide',
    calciName: 'Algaecide',
    unitAndPrice: [
      {
        volume: '1', renderVolume: '1 litre', buycost: '4.60', sellprice: '45.40',
      },
      {
        volume: '2.5', renderVolume: '2.5 litre', buycost: '9.20', sellprice: '90.80',
      },
      {
        volume: '5', renderVolume: '5 litre', buycost: '13.60', sellprice: '135.00',
      },
      {
        volume: '25', renderVolume: '25 litre', buycost: '1.07', sellprice: '200.00',
      },
    ],
  },
  {
    unit: 'per litre',
    buycost: '12',
    sellprice: '48',
    name: 'clarifier',
    renderName: 'Clarifier',
    calciName: 'Clarifier',
    unitAndPrice: [
      {
        volume: '1', renderVolume: '1 litre', buycost: '12', sellprice: '48',
      },
    ],
  },
  {
    unit: 'per kg',
    buycost: '10',
    sellprice: '30',
    name: 'flock',
    renderName: 'Flock',
    calciName: 'Flock',
    unitAndPrice: [
      {
        volume: '2', renderVolume: '2 kg', buycost: '10', sellprice: '30',
      },
    ],
  },
  {
    unit: 'per bag',
    buycost: '11.00',
    sellprice: '33.00',
    name: 'fineGlassMedia',
    renderName: 'Fine Glass Media',
    calciName: 'fineGlassMedia',
    unitAndPrice: [
      {
        volume: '15', renderVolume: '15 kg', buycost: '11.00', sellprice: '33.00',
      },
    ],
  },
  {
    unit: 'per bag',
    buycost: '11.00',
    sellprice: '33.00',
    name: 'coarseGlassMedia',
    renderName: 'Coarse Glass Media',
    calciName: 'coarseGlassMedia',
    unitAndPrice: [
      {
        volume: '15', renderVolume: '15 kg', buycost: '11.00', sellprice: '33.00',
      },
    ],

  },
  {
    unit: 'per bag',
    buycost: '14.96',
    sellprice: '41.00',
    name: 'sandMedia',
    renderName: 'Sand Media',
    calciName: 'sandMedia',
    unitAndPrice: [
      {
        volume: '20', renderVolume: '20 kg', buycost: '14.96', sellprice: '41.00',
      },
    ],
  },
  {
    unit: 'per litre',
    buycost: '17',
    sellprice: '85',
    name: 'zodiacMetalReducer',
    renderName: 'Zodiac Metal Reducer',
    calciName: 'Copper',
    unitAndPrice: [
      {
        volume: '1', renderVolume: '1 litre', buycost: '17', sellprice: '85',
      },
    ],
  },

  {
    unit: 'per litre',
    buycost: '19.68',
    sellprice: '59.04',
    name: 'zodiacCalcium',
    renderName: 'Zodiac Calcium Down',
    calciName: 'ZodiacCalcium',
    unitAndPrice: [
      {
        volume: '1', renderVolume: '1 litre', buycost: '19.68', sellprice: '59.04',
      },
    ],
  },
  {
    unit: 'per kg',
    buycost: '28.00',
    sellprice: '56.00',
    name: 'blackSpotAlgae',
    renderName: 'Black-Spot Algae Remover',
    calciName: 'BlackSpotAlgae',
    unitAndPrice: [
      {
        volume: '1', renderVolume: '1 kg', buycost: '28.00', sellprice: '56.00',
      },
    ],
  },

];

export default defaultPredictiveOrderChemicals;
