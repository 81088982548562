// Settings Tabs
export const GENERAL_TAB = 'GENERAL';
export const DATA_MANAGEMENT_TAB = 'DATA_MANAGEMENT';

// Settings Options For Data Management
export const PUBLIC_SCOPE = 'public';
export const MARKETING_SCOPE = 'marketing';
export const WEB_APP_SCOPE = 'webapp';

export const ModelList = [
  {
    id: 3847561928475639,
    label: 'GPT-4o 2024-08-06',
    value: 'gpt-4o-2024-08-06',
  },
  {
    id: 9273648102938475,
    label: 'GPT-4o Mini',
    value: 'gpt-4o-mini',
  },
  {
    id: 6357281940573826,
    label: 'GPT-4o Mini 2024-07-18',
    value: 'gpt-4o-mini-2024-07-18',
  },
  {
    id: 1395827461039485,
    label: 'GPT-4o 2024-05-13',
    value: 'gpt-4o-2024-05-13',
  },
  {
    id: 4857392104895731,
    label: 'GPT-4o',
    value: 'gpt-4o',
  },
  {
    id: 2948571620394758,
    label: 'GPT-4 Turbo 2024-04-09',
    value: 'gpt-4-turbo-2024-04-09',
  },
  {
    id: 9384756128394756,
    label: 'GPT-4 Turbo',
    value: 'gpt-4-turbo',
  },
  {
    id: 7483920156473829,
    label: 'GPT-3.5 Turbo 0125',
    value: 'gpt-3.5-turbo-0125',
  },
  {
    id: 5674839201583746,
    label: 'GPT-4 Turbo Preview',
    value: 'gpt-4-turbo-preview',
  },
  {
    id: 1039572846103948,
    label: 'GPT-4 0125 Preview',
    value: 'gpt-4-0125-preview',
  },
  {
    id: 8573920148573629,
    label: 'GPT-3.5 Turbo 1106',
    value: 'gpt-3.5-turbo-1106',
  },
  {
    id: 2048573910485723,
    label: 'GPT-4 1106 Preview',
    value: 'gpt-4-1106-preview',
  },
  {
    id: 1928374650193847,
    label: 'GPT-3.5 Turbo Instruct 0914',
    value: 'gpt-3.5-turbo-instruct-0914',
  },
  {
    id: 7483920156473821,
    label: 'GPT-3.5 Turbo Instruct',
    value: 'gpt-3.5-turbo-instruct',
  },
  {
    id: 5847392018573942,
    label: 'GPT-4 32K 0314',
    value: 'gpt-4-32k-0314',
  },
  {
    id: 2039485761039485,
    label: 'GPT-4',
    value: 'gpt-4',
  },
  {
    id: 9374856102938475,
    label: 'GPT-4 0314',
    value: 'gpt-4-0314',
  },
  {
    id: 8573920148573647,
    label: 'GPT-4 0613',
    value: 'gpt-4-0613',
  },
  {
    id: 1029384756102938,
    label: 'GPT-3.5 Turbo 16K',
    value: 'gpt-3.5-turbo-16k',
  },
  {
    id: 3847561928475631,
    label: 'GPT-3.5 Turbo',
    value: 'gpt-3.5-turbo',
  },
];

export const DefaultGeneralSettings = {
  name: 'HelpBot',
  avatar: '',
  openAIKey: '',
  aiSettings: [{
    aiModel: 'gpt-4o',
    greeting: 'Hi there! How are you today?',
    systemPrompt: 'You are a marketing bot.',
    scope: 'marketing',
  },
  {
    aiModel: 'gpt-4o',
    greeting: 'Hi there! How are you today?',
    systemPrompt: 'You are a chatbot for web app.',
    scope: 'webapp',
  }]
};
