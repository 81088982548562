import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { userInstance } from '../../config/axios';
import search from '../../assets/home/search.png';
import { errorMsgTime } from '../../functions/intervalTime';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import toastMessage from '../../functions/toastMessage';

const PredictiveInventorySearch = ({ updateView, setSearchDataPredective, setLoading }) => {
  const { userData: { industryId } } = useContext(AuthDataContext);
  const [teamMemberData, setTeamMemberData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [recOrder, setOrder] = useState('Product Name (A-Z)');
  const initialfilterObj = {
    locationId: '',
    memberId: '',
    product: '',
    supplier: '',
    sortProductOption: '',
    predectiveWeeks: 'twoWeeks',
  };
  const [filterObj, setFilterObj] = useState(initialfilterObj);
  const handleOnchange = (e) => {
    let { name, value } = e || {};
    console.log('onChnage', name, value);
    if (name === 'sortProductOption') {
      setOrder(value);
      if (value === 'Date Created (new-old)') value = { createdAt: 1 };
      else if (value === 'Date Created (old-new)') value = { createdAt: -1 };
      else if (value === 'Date Updated (new-old)') value = { updatedAt: 1 };
      else if (value === 'Date Updated (old-new)') value = { updatedAt: -1 };
      else if (value === 'Date Updated (old-new)') value = { updatedAt: -1 };
      else if (value === 'Product Group Name (A-Z)') value = { category: 1 };
      else if (value === 'Product Group Name (Z-A)') value = { category: -1 };
      else if (value === 'Product Name (A-Z)') value = { name: 1 };
      else if (value === 'Product Name (Z-A)') value = { name: -1 };
      else if (value === 'Supplier (A-Z)') value = { supplier: 1 };
      else if (value === 'Supplier (Z-A)') value = { supplier: -1 };
      else if (value === 'Buy Cost (low-high)') value = { cost: 1 };
      else if (value === 'Buy Cost (high-low)') value = { cost: -1 };
      else if (value === 'Sell Price (low-high)') value = { retailCost: -1 };
      else if (value === 'Sell Price (high-low)') value = { retailCost: -1 };
      let copyfilterObj = { ...filterObj, [name]: value };
      setFilterObj(copyfilterObj);
    } else {
      let copyfilterObj = { ...filterObj, [name]: value };
      setFilterObj(copyfilterObj);
    }
  };

  const getteammember = async () => {
    const response = await userInstance().get(
      '/appointment/getTeamMemberList',
    );
    const { teamList, code } = response.data;
    if (code === 200) {
      teamList.sort((a, b) => a.firstname.localeCompare(b.firstname));
      setTeamMemberData(teamList);
    }
  };
  const getLocation = async () => {
    const response = await userInstance().get(
      '/appointment/getLocationList',
    );
    const { locationList, code } = response.data;
    if (code === 200) {
      locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
      setLocationData([...locationList]);
    }
  };
  const sortOptions = [
    { value: 'Date Created (new-old)' },
    { value: 'Date Created (old-new)' },
    { value: 'Date Updated (new-old)' },
    { value: 'Date Updated (old-new)' },
    { value: 'Product Group Name (A-Z)' },
    { value: 'Product Group Name (Z-A)' },
    { value: 'Product Name (A-Z)' },
    { value: 'Product Name (Z-A)' },
    { value: 'Stock Level (low-high)' },
    { value: 'Stock Level (high-low)' },
    { value: 'Supplier (A-Z)' },
    { value: 'Supplier (Z-A)' },
    { value: 'Buy Cost (low-high)' },
    { value: 'Buy Cost (high-low)' },
    { value: 'Sell Price (low-high)' },
    { value: 'Sell Price (high-low)' },
  ];

  useEffect(() => {
    getteammember();
    getLocation();
  }, []);
  const predictiveInventorySearchData = async () => {
    try {
      setLoading(true);
      const filterTeamMemberId = filterObj.memberId !== '' ? [filterObj.memberId] : filterObj.locationId === '' ? teamMemberData.map((el) => el._id) : teamMemberData.filter((el) => el.location === filterObj.locationId).map((el) => el._id);
      const payLoad = { filterObj, filterTeamMemberId, industryId: industryId._id };
      const response = await userInstance().get(`/inventory/getPredectiveSummary/?payload=${JSON.stringify(payLoad)}`);
      const { code, predectiveData } = response.data;
      if (code === 200) {
        setSearchDataPredective({ data: predectiveData || [] });
        updateView('Predictive Ordering Detail');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toastMessage('error',
        'Failed to load data. Please try again!');
    }
  };

  return (
    <>
      <div className="invoice-detail search-invoice search-inventory">
        <div className="pool-setup">
          <div className="pool-chemistry-box appointment-service-modal">
            <h5>Predictive Ordering Summary</h5>
            <div className="search-invoces-box">
              <h6>Display Criteria</h6>
              <div className="InventorySearch-form">
                <Form.Group controlId="formBasicCompanyType">
                  <Form.Label>Forward Purchase Period</Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      onChange={(e) => handleOnchange(e.target)}
                      name="predectiveWeeks"
                      value={filterObj.predectiveWeeks}
                    >
                      <option value="twoWeeks">2 Weeks</option>
                      <option value="fourWeeks">4 Weeks</option>
                      <option value="eightWeeks">8 Weeks</option>
                      <option value="twelveWeeks">12 Weeks</option>
                    </Form.Control>
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasicCompanyType">
                  <Form.Label>Location</Form.Label>
                  <div className="select-box">
                    <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="locationId" value={filterObj.locationId}>
                      <option value="">All Locations</option>
                      {locationData.map((el) => <option key={el._id} value={el._id}>{el?.locationName}</option>)}
                    </Form.Control>
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasicCompanyType">
                  <Form.Label>Team Member</Form.Label>
                  <div className="select-box">
                    <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="memberId" value={filterObj.memberId}>
                      <option value="">All Team Members</option>
                      {teamMemberData.map((el) => (
                        <>
                          {filterObj.locationId === '' ? <option key={el._id} value={el?._id}>{`${el?.firstname} ${el?.lastname}`}</option> : filterObj?.locationId === el?.location && <option key={el._id} value={el?._id}>{`${el?.firstname} ${el.lastname}`}</option>}
                        </>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>

                <Form.Group controlId="formBasicHiddenEmployees">
                  <Form.Label>Search Dispaly Order</Form.Label>
                  <div className="select-box">
                    <i className="fas fa-sort-down" />
                    <Form.Control
                      as="select"
                      name="sortProductOption"
                      value={recOrder}
                      onChange={(e) => handleOnchange(e.target)}
                    >
                      {' '}
                      {sortOptions.map((el, i) => (
                        <>
                          <option key={el.value} value={el.value}>{el.value}</option>
                        </>
                      ))}

                    </Form.Control>
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasicHiddenEmployees">
                  <Form.Label>Specific Product</Form.Label>
                  <div>
                    <Form.Group className="search-box">
                      <Form.Control
                        type="text"
                        placeholder="Search by produt, description or cost"
                        name="product"
                        value={filterObj.product}
                        onChange={(e) => handleOnchange(e.target)}
                      />
                      <img src={search} alt="search" />
                    </Form.Group>
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasicHiddenEmployees">
                  <Form.Label>Supplier</Form.Label>
                  <div>
                    <Form.Group className="search-box">
                      <Form.Control
                        type="text"
                        placeholder="Search by Supplier"
                        name="supplier"
                        value={filterObj.supplier}
                        onChange={(e) => handleOnchange(e.target)}
                      />
                      <img src={search} alt="search" />
                    </Form.Group>
                  </div>
                </Form.Group>
              </div>
              <p className="group-time">* Less fields filled-in will broaden the search.</p>
              <div className="row">
                <div className="col-md-12">
                  <div className="view-btn search-invoice-btn">
                    <Button
                      className="btn-save"
                      onClick={() => predictiveInventorySearchData()}
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
PredictiveInventorySearch.propTypes = {
  updateView: PropTypes.func.isRequired,
  setSearchDataPredective: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,

};

export default PredictiveInventorySearch;
