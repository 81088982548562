import React, { useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { NotificationManager } from 'react-notifications';
import PropTypes from 'prop-types';
import moment from '../../functions/moment';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import { displayTime } from '../../functions/timeDate';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const ServiceView = (props) => {
  const {
    handleBookingData,
    bookingData,
    editData,
    servicesLength,
    handleChangeCustom,
    handleTimePicker,
    convertToReadableDuration,
    handleDurationPicker,
    handleHourPicker,
    subcategoryTxt,
    endTimeNew,
    timeDisplay,
  } = props;
  const { userData: { calendarSettings } } = useContext(AuthDataContext);
  const { timeZone } = calendarSettings || {};
  const quantityLabel = bookingData.pricingType === 'fixedCost';
  const MAX_VAL = 24;

  const withValueLimitHours = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return inputObj;
    NotificationManager.error('Enter an amount 24.0 or less', 'Message', errorMsgTime);
    return '';
  };

  const withValueLimitSellPrice = (inputObj) => {
    const { value } = inputObj;
    const toCheck = 9999999999.999;
    console.log('toCheck', toCheck);
    if (value <= toCheck) return inputObj;
    NotificationManager.error(
      'Enter an amount 9,999,999,999.999 or less',
      'Message',
      errorMsgTime,
    );
    return '';
  };
  console.log('bookingData=====>', { bookingData });
  return (
    <div className="row">
      <div className="col-md-6 col-6 half">
        <Form.Group controlId="formBasicHiddenEmployees">
          <Form.Label>Start Time</Form.Label>
          <Button
            onClick={handleTimePicker}
          >
           
            {moment(bookingData.startTime, ['HH:mm']).format('h:mm a')}
          </Button>
        </Form.Group>
      </div>
      {quantityLabel ? (
        <div className="col-md-6 col-6 halfright">
          <Form.Group controlId="formBasicHiddenEmployees">
            <Form.Label>Duration</Form.Label>
            <Button
              onClick={handleDurationPicker}
              disabled={!bookingData.subcategory}
            >
              {convertToReadableDuration(bookingData.duration)}
            </Button>
          </Form.Group>
        </div>
      ) : (
        <div className="col-md-6 col-6 halfright">
          <Form.Group controlId="formBasicHiddenEmployees">
            <Form.Label>No. of Hours</Form.Label>

            <Button
              onClick={handleHourPicker}
              disabled={!bookingData.subcategory}
            >
              {convertToReadableDuration(bookingData.quantity)}
            </Button>
          </Form.Group>
        </div>
      )}
    </div>
  );
};

ServiceView.propTypes = {
  handleBookingData: PropTypes.func.isRequired,
  bookingData: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  servicesLength: PropTypes.func.isRequired,
  handleChangeCustom: PropTypes.func.isRequired,
  handleTimePicker: PropTypes.func.isRequired,
  convertToReadableDuration: PropTypes.func.isRequired,
  handleDurationPicker: PropTypes.func.isRequired,
  subcategoryTxt: PropTypes.func.isRequired,
  handleHourPicker: PropTypes.func.isRequired,
  endTimeNew: PropTypes.string.isRequired,
  timeDisplay: PropTypes.string.isRequired,
};

export default ServiceView;
