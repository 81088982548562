import React from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ChemicalCosts = ({
  state, isUsImperial, removeInCaseOfImperial, handleChange, handleRestoreOriginalSettings, handlePreviousSavedDedfault, handleRequest, checkBlankFields,
}) => {
  const checkValueExist = (value, name) => {
    if (!value && checkBlankFields?.includes(name)) {
      return 'error-text';
    }
    return '';
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="pool-setup mineral-pool chemical-setup">
          <div className="target-heading">
            <h6>Chemical Costs</h6>
          </div>
          <div className="pool-chemistry-box chemistry-target">
            <div className="pool-analysis">
              <div className="left-pool-analysis"><h6>Chemical Costs</h6></div>
              <div className="right-pool-analysis">
                {/* <h6>Unit</h6> */}
                <h6>
                  Buy Cost
                  {' '}
                  <span>(ex gst)</span>
                </h6>
                <h6>
                  Sell Price
                  {' '}
                  <span>(ex gst)</span>
                </h6>
              </div>
            </div>

            {state?.length > 0 && state.map((el, pindex) => (
              <>
                {isUsImperial && removeInCaseOfImperial.includes(el?.renderName) ? null : (
                  el.unitAndPrice?.length > 0 && el.unitAndPrice.map((elem, chIndex) => (
                    <div className="pool-analysis" key={el?._id}>
                      <div className="left-pool-analysis">
                        <h5>{`${el.renderName} (${elem?.renderVolume})`}</h5>
                        {/* <h5>
                                  {(el?.renderName === 'Salt (20 kg)' ? isUsImperial ? 'Salt (40 lb)' : el.renderName
                                    : el?.renderName === 'Sand Media (20 kg)' ? isUsImperial ? 'Sand Media (50 lb)' : el.renderName : el.renderName)}
                                </h5> */}
                      </div>
                      <div className="right-pool-analysis target-level-input">

                        <Form>
                          {/* {console.log(el?.unitAndPrice, el.selectedIndex)} */}
                          <Form.Group controlId="formBasicInput">
                            <Form.Control
                              type="text"
                              name="buycost"
                              onChange={(e) => handleChange(e, pindex, chIndex)}
                              value={elem?.buycost}
                              className={checkValueExist(elem.buycost, el.calciName)}
                            />
                          </Form.Group>
                        </Form>
                        <Form>
                          <Form.Group controlId="formBasicInput">
                            <Form.Control
                              type="text"
                              name="sellprice"
                              onChange={(e) => handleChange(e, pindex, chIndex)}
                              value={elem?.sellprice}
                              className={checkValueExist(elem.sellprice, el.calciName)}
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                  )))}
                {['Stabiliser', 'PhAcid'].includes(el.calciName) || window?.innerWidth >= 991 ? null : <span className="divider" />}
              </>
            ))}

            <div className="view-btn">
              <div className="client-option service-edit-icon">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-edi">
                    View Other Settings
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <div className="action" onClick={handleRestoreOriginalSettings}>
                        Restore Original Settings
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className="action" onClick={handlePreviousSavedDedfault}>
                        Revert to Previous Saved Default
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Button type="submit" className="btn btn-save" onClick={handleRequest}>
                Save as Default
              </Button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

ChemicalCosts.propTypes = {
  handlePreviousSavedDedfault: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRestoreOriginalSettings: PropTypes.func.isRequired,
  state: PropTypes.arrayOf.isRequired,
  removeInCaseOfImperial: PropTypes.arrayOf.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
  checkBlankFields: PropTypes.arrayOf.isRequired,
};

export default ChemicalCosts;
