/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Modal, Form, Col, Row, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
// import { propTypes } from 'react-bootstrap/esm/Image';
import { propTypes } from 'react-bootstrap/esm/Image';
import AppointmentChecklist from '../prduct&services/addChecklist';
import edit from '../../assets/home/grey-edit.png';
import sorting from '../../assets/home/sort.png';
import cancel from '../../assets/home/black-icon.png';
import sortup from '../../assets/home/up-sort.png';
import sortdown from '../../assets/home/down-sort.png';

const InventoryFormModal = ({
  show,
  HandleOpenClose,
  handleManageCategory,
  category,
  setCategory,
  type,
  appointmentChecklist,
  handleManageChecklist,
  checklist,
  handleChecklist,
  checklistName,
  setChecklistName,
  removeList,
  inputDisabled,
  setInputDisabled,
  editList,
  moveArrCheckList,
  editProductGroup,
  error,
}) => {
  const [sortingItem, setSortingItem] = useState();
  const handleSorting = () => {
    setSortingItem(!sortingItem);
  };
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={HandleOpenClose}
        aria-labelledby="contained-modal-title-vcenter"
        top
        centered
        className="service-modal update-product-group-modal"
      >
        <div className="staff-close">
          <i
            className="fa fa-times"
            onClick={HandleOpenClose}
            aria-hidden="true"
          />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {` ${type === 'Add' ? 'Add' : 'Update'} Product Group`}
            {' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="modal-form">
                <Form>
                  <Form.Group
                    as={Row}
                    controlId="formInput1"
                    className="italic-text"
                  >
                    <Form.Label column sm="3">
                      Name of Product Group
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder="Enter Name of Product Group"
                        name="category"
                        onChange={(e) => setCategory(e.target.value)}
                        value={category}
                        className={`${
                          error === 'category'
                            ? 'error-text'
                            : ''
                        }`}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </div>
              <div className="bottom-section">
                <div className="checklist-heading">
                  <h6>Pool Maintenance Checklist</h6>
                  <div className="checklist-icon">
                    {appointmentChecklist && appointmentChecklist.length > 1 && (
                    <>
                      <img src={edit} alt="edit" onClick={() => setInputDisabled(!inputDisabled)} />
                      <img src={sorting} alt="edit" onClick={handleSorting} />
                    </>
                    )}
                    <i className="fa fa-plus" onClick={handleChecklist} />
                  </div>
                </div>
                <div className="modal-form">
                  {appointmentChecklist && appointmentChecklist.length > 0 ? (
                    <>
                      {appointmentChecklist && appointmentChecklist.map((el, i) => (
                        <div className="appointment-checklist-section">
                          <div className={`checklist-content ${sortingItem ? 'checklist-content-sorting' : ''}`}>
                            <Form.Group
                              as={Row}
                              controlId="formInput1"
                              className="italic-text"
                            >
                              <Col sm="9">
                                <div className={`checklist-text ${inputDisabled ? 'disable-input' : ''} `}>
                                  <span className="number-text">
                                    {i + 1}
                                    .
                                  </span>
                                  <Form.Control
                                    type="text"
                                    name="category"
                                    onChange={(e) => editList(e, i)}
                                    value={el}
                                  />
                                  <span>
                                    {' '}
                                    <img src={cancel} alt="cancel" onClick={() => removeList(i)} />
                                  </span>
                                  {sortingItem && (
                                    <span className="sorting-img">
                                      {i !== 0 ? (
                                        <img
                                          src={sortup}
                                          alt="sorting"
                                          onClick={() => moveArrCheckList(appointmentChecklist, i, i - 1)}
                                        />
                                      ) : null}
                                      {i !== appointmentChecklist.length - 1 ? (
                                        <img
                                          src={sortdown}
                                          alt="sorting"
                                          onClick={() => moveArrCheckList(appointmentChecklist, i, i + 1)}
                                        />
                                      ) : null}
                                    </span>
                                  )}
                                </div>
                              </Col>
                            </Form.Group>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <p className="custom-text-checklist">
                      Add Checklist Items to form part of QA when scheduled maintenance is Marked as Completed.
                    </p>
                  )}
                  <div className="view-btn">
                    <Button
                      type="submit"
                      className="btn btn-save"
                      onClick={type === 'Add' ? handleManageCategory : editProductGroup}
                    >
                      Save/Update
                    </Button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AppointmentChecklist
        show={checklist}
        HandleOpenClose={handleChecklist}
        appointmentChecklist={appointmentChecklist}
        handleManageChecklist={handleManageChecklist}
        checklistName={checklistName}
        setChecklistName={setChecklistName}
        inputDisabled={inputDisabled}
        error={error}
      />
    </>
  );
};

InventoryFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  HandleOpenClose: PropTypes.func.isRequired,
  handleManageCategory: PropTypes.func.isRequired,
  category: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  type: PropTypes.func.isRequired,
  appointmentChecklist: PropTypes.arrayOf.isRequired,
  handleManageChecklist: PropTypes.func.isRequired,
  checklist: PropTypes.bool.isRequired,
  handleChecklist: PropTypes.func.isRequired,
  checklistName: PropTypes.string.isRequired,
  setChecklistName: PropTypes.func.isRequired,
  removeList: PropTypes.func.isRequired,
  inputDisabled: PropTypes.bool.isRequired,
  setInputDisabled: PropTypes.func.isRequired,
  editList: PropTypes.func.isRequired,
  moveArrCheckList: PropTypes.func.isRequired,
  editProductGroup: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default InventoryFormModal;
