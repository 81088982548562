/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable jsx-quotes */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import moment, { duration } from 'moment';
import React, { useEffect, useState } from 'react';
import {
 Accordion, Button, Card, Form 
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  billing_pools,
  billing_rules,
  billing_users,
} from '../subscription/utils';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import StripePaymentPopup from '../SubscribePayment/stripePaymentPopup';

const NewCreateSubscription = ({
  subscriptionDetails,
  error,
  handleChange,
  userInformation,
  handleClose,
  newUpgradeTotalAmount,
}) => {
  console.log('userInformation=======aaa====>>>>', subscriptionDetails);
  // newUpgradeTotalAmount = 0.6;

  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  const [smallLoader, setSmallLoader] = useState(false);
  const [specificDate, setSpecificDate] = useState('');
  const [slectedItem, setSelectedItem] = useState('immediate');
  const [isCalender, setIsCalender] = useState(false);
  const [totalCostForFIlteringProduct, setTotalCostForFIlteringProduct] =
    useState(0);
  const handleSelectChange = (e) => {
    setSelectedItem(e.target.value);
    if (e.target.value === 'specific') {
      setIsCalender(true);
    } else {
      setIsCalender(false);
      setSpecificDate('');
    }
  };
  const handleClick = () => {
    setOpenPaymentForm(true);
  };

  const handleClosePaymentForm = () => {
    setOpenPaymentForm(false);
  };

  const handleFormModal = () => {};

  // Calculate Function for Total Cost for upgrade the Plan
  const findProductPriceId = () => {
    const costOfPlanDuration =
      Number(subscriptionDetails?.duration) === 24
        ? 462
        : Number(subscriptionDetails?.duration) === 12
        ? 264
        : Number(subscriptionDetails?.duration) === 36 && 594;
    const costOfPoolMonth =
      billing_pools[subscriptionDetails.country][
        subscriptionDetails?.numberOfPools - 1
      ].price * Number(subscriptionDetails?.duration);
    const costOfUserMonth =
      billing_users[subscriptionDetails.country][
        subscriptionDetails?.numberUsers - 2
      ].price * Number(subscriptionDetails?.duration);
    console.log({ costOfPlanDuration, costOfPoolMonth, costOfUserMonth });

    const productPrice = costOfPlanDuration + costOfPoolMonth + costOfUserMonth;
    setTotalCostForFIlteringProduct(productPrice);
  };

  useEffect(() => {
    if (newUpgradeTotalAmount > 0) {
      findProductPriceId();
    }
  }, [newUpgradeTotalAmount, subscriptionDetails]);

  const handleDateSet = (e) => {
    const inputDate = new Date(e);
    setSpecificDate(inputDate);
    setIsCalender(false);
  };
  console.log('specificDate--a-a--a-a-a->>', specificDate);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    // Combine to get a consistent local date in 'YYYY-MM-DDT00:00:00' format
    const localDateString = `${year}-${month}-${day}`;

    // Convert to UTC without changing the day
    const localDate = new Date(localDateString);
    return localDate.toISOString().split('T')[0];
  };
console.log("totalCostForFIlteringProduct=====>>", totalCostForFIlteringProduct);
const newClientData = {
  ...subscriptionDetails, totalCost: totalCostForFIlteringProduct, duration: subscriptionDetails?.duration === 0 ? 12 : subscriptionDetails?.duration
};

  return (
    <div className='target-heading new-client-section flex-column'>
      <Accordion className='w-100'>
        <Card.Body>
          <ul className='subscription_billing staff-list top-client-details1 '>
            <div className='staff-form w-100'>
              <div className='row'>
                <div className='col-md-12 detailed-data'>
                  <Form.Group controlId='formBasicLocation'>
                    <Form.Label>Subscription Duration</Form.Label>
                    <div className='select-box'>
                      <Form.Control
                        as='select'
                        name='duration'
                        onChange={(e) => handleChange(e)}
                        value={subscriptionDetails.duration}
                        className={`${
                          error === 'subscriptionduration' ? 'error-text' : ''
                        }`}
                      >
                        {billing_rules[subscriptionDetails.country].map(
                          (el, i) =>
                            i !== el.duration && (
                              <option value={el.duration}>
                                {el.description}
                              </option>
                            )
                        )}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 detailed-data'>
                  <Form.Group controlId='formBasicLocation'>
                    <Form.Label>No. of Pools</Form.Label>
                    <div className='select-box'>
                      <Form.Control
                        as='select'
                        value={subscriptionDetails.numberOfPools}
                        name='numberOfPools'
                        onChange={(e) => handleChange(e)}
                        className={`${
                          error === 'numberofpools' ? 'error-text' : ''
                        }`}
                      >
                        {billing_pools[subscriptionDetails.country].map(
                          (el) => (
                            <option value={el.num}>{el.description}</option>
                          )
                        )}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 detailed-data'>
                  <Form.Group controlId='formBasicLocation'>
                    <Form.Label>No. of Users</Form.Label>
                    <div className='select-box'>
                      <Form.Control
                        as='select'
                        value={subscriptionDetails.numberUsers}
                        name='numberUsers'
                        onChange={(e) => handleChange(e)}
                        className={`${
                          error === 'numberofuser' ? 'error-text' : ''
                        }`}
                      >
                        {billing_users[subscriptionDetails.country].map(
                          (el) => (
                            <option value={el.num}>{el.description}</option>
                          )
                        )}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
              </div>
              {/* <div className='row'>
                <div className='col-md-12 detailed-data'>
                  <Form.Group controlId='formBasicLocation'>
                    <Form.Label>Update Subscription Effective From</Form.Label>
                    <div className='select-box'>
                      <Form.Control
                        as='select'
                        name='dropdown'
                        value={slectedItem}
                        onChange={handleSelectChange}
                      >
                        <option value='immediate'>Update Immediately</option>
                        <option value='specific'>
                          Update From Specific Date
                        </option>
                        <option value='endOfperiod'>
                          Update at End of Current Subscription
                        </option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
              </div> */}
              {specificDate && <div>{formatDate(specificDate)}</div>}
              <div className='row'>
                <div className='col-md-12 detailed-data'>
                  <Form.Group controlId='formBasicLocation' className='m-0'>
                    <Form.Label>
                      Total Cost:
                      {`${
                        userInformation?.country === 'Australia' ? 'AUD' : 'USD'
                      } $${totalCostForFIlteringProduct}`}{' '}
                    </Form.Label>
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='view-btn staff-form-btn'>
                    {/* <Button
                      type='button'
                      className='btn btn-edit'
                      onClick={handleClose}
                    >
                      {' '}
                      Cancel{' '}
                    </Button> */}
                    <Button
                      type='Submit'
                      className='btn btn-save w-100' 
                      onClick={handleClick}
                    >
                      {' '}
                      Save/Update{' '}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </Card.Body>
      </Accordion>
      {isCalender && (
        <div className='search-popup shift-container'>
          <div className='time-calender'>
            <Calendar onChange={handleDateSet} value={specificDate} />
          </div>
        </div>
      )}
      {openPaymentForm ? (
        <StripePaymentPopup
          openPaymentForm={openPaymentForm}
          clientData={newClientData}
          handleAction={handleClosePaymentForm}
          smallLoader={smallLoader}
          returnPopUp='subscriptionPage'
          handleFormModal={handleFormModal}
          UpdateSubscriptionPopup={false}
          updateSubscriptionTotalCostValue={totalCostForFIlteringProduct}
          totalCostForFIlteringProduct={totalCostForFIlteringProduct}
          slectedItem={slectedItem}
          specificDate={specificDate}
          newPlanSubscriptionPage={true}
        />
      ) : null}
    </div>
  );
};
export default NewCreateSubscription;

NewCreateSubscription.propTypes = {
  userInformation: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  subscriptionDetails: PropTypes.objectOf({}).isRequired,
};
