/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Modal, Form, Col, Row, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddProductPopup = ({
  show,
  HandleOpenClose,
}) => {
  const initialValues = {
    id: 1,
    productName: '',
    quantity: '',
    subPrice: '',
  };
  let arr = [];
  const [addmultiple, setAddmultiple] = useState([initialValues]);
  const [startDate, setStartDate] = useState(new Date());
  const handleaddItems = () => {
    const copyObj = [...addmultiple];
    copyObj.push({ ...initialValues, id: copyObj.at(-1).id + 1 });
    setAddmultiple(copyObj);
  };

  const handleRemove = (id) => {
    let filterData = addmultiple.filter((el) => el.id !== id);

    setAddmultiple(filterData);
  };
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={HandleOpenClose}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="service-modal add-order-popup"
      >
        <div className="staff-close">
          <i
            className="fa fa-times"
            onClick={HandleOpenClose}
            aria-hidden="true"
          />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="modal-form">
                <Form>
                  <Form.Group
                    as={Row}
                    controlId="formInput1"
                  >
                    <Form.Label column sm="3">
                      Date
                    </Form.Label>
                    <Col sm="9">
                      <div className="custom-date">
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="d MMM, yyyy" />
                      </div>
                    </Col>
                  </Form.Group>
                  {addmultiple && addmultiple.map((el, i) => (
                    <div className="add-multiple-order" key={`${el.id}`}>
                      <Form.Group
                        as={Row}
                        controlId="formInput1"
                      >
                        <Form.Label column sm="3">
                          Product Name
                        </Form.Label>
                        <Col sm="9">
                          <div className="select-box">
                            <i className="fas fa-sort-down" />
                            <Form.Control
                              as="select"
                              name="invoiceSendType"
                            >
                              {/* <option value="Select">Select</option> */}
                              <option value="Send Invoices by SMS">Send Invoices by SMS</option>
                              <option value="Send Invoices by Email"> Send Invoices by Email</option>
                              <option value="Send Invoices by SMS & Email"> Send Invoices by SMS & Email</option>
                              <option value="Don't Automatically send">Don&apos;t Automatically send</option>
                            </Form.Control>
                          </div>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="formInput1"
                      >
                        <Form.Label column sm="3">
                          Qunatity
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            placeholder="Enter Name of Product Group"
                            name="category"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="formInput1"
                      >
                        <Form.Label column sm="3">
                          Sub Price
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            placeholder="Enter Name of Product Group"
                            name="category"
                          />
                        </Col>
                      </Form.Group>
                      {i === addmultiple.length - 1 ? (
                        <i className="fa fa-plus" onClick={handleaddItems} />
                      ) : (
                        <i className="fa fa-minus" aria-hidden="true" onClick={() => handleRemove(el.id)} />
                      )}

                    </div>
                  ))}
                  <div className="view-btn">
                    <Button
                      type="Submit"
                      className="btn btn-edit"
                    >
                      {' '}
                      Cancel
                      {' '}
                    </Button>
                    <Button
                      type="Submit"
                      className="btn btn-save"
                    >
                      {' '}
                      Save/Update
                      {' '}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

AddProductPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  HandleOpenClose: PropTypes.func.isRequired,
};

export default AddProductPopup;
