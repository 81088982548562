import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';
import { userInstance } from '../../config/axios';
import {
  queryString, removeCodeFromNumber, showErrMsg, validateDataWithKey,
} from '../../functions/index';
import { emailRegex } from '../../config/keys';
import { notifyAlert } from '../calculator/calculatorUtils';
import { countries, states } from '../country-state/country-state';
import Loader from '../loader/loader';
import './profile.css';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const MyProfile = () => {
  const [profileData, setProfileData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState('+61');
  const [error, setError] = useState('');
  const [stateArray, setStateArray] = useState([]);
  const [loader, setLoader] = useState(true);
  const { userData, setUserData } = useContext(AuthDataContext);

  const handleCountry = (countryCode) => {
    setSelectedCountry(countryCode);

    const textToReplace = profileData.mobileNo.includes('+61') ? '+61' : '+1';
    const obj = {};
    if (profileData.mobileNo) {
      obj.mobileNo = `${profileData.mobileNo.replace(
        textToReplace,
        '',
      )}`;
    }
    if (profileData.landlineNo) {
      obj.landlineNo = `${profileData.landlineNo.replace(
        textToReplace,
        '',
      )}`;
    }
    setProfileData({ ...profileData, ...obj });
  };

  const handleCountryStates = (compareValue) => {
    console.log({ compareValue });
    let compareField = compareValue === 'United States' ? compareValue : 'Australia';
    const fIndex = countries.findIndex((el) => el.name === (!compareValue ? 'Australia' : compareField));
    if (fIndex >= 0) {
      const copyStates = JSON.parse(JSON.stringify(states));
      const filteredArray = copyStates.filter((el) => el.countryCode === countries[fIndex].isoCode);
      const list = filteredArray.sort((a, b) => a.name.localeCompare(b.name));
      console.log({ list, countryCode: countries[fIndex].phonecode });
      setSelectedCountry(`+${countries[fIndex].phonecode}`);
      setStateArray(filteredArray);
    }
  };
  const handleChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    console.log({ ...profileData, [name]: value });
    if (name === 'gender') {
      value = e.target.id;
    }
    const copy = { ...profileData };
    if (name === 'country') {
      handleCountryStates(value);
      copy.state = '';
      copy.mobileNo = '';
      // console.log('countries', countries);
    }
    if (name === 'postcode' && value.length >= 10) {
      return;
    }
    copy[name] = value;
    console.log('value', value);
    setProfileData({ ...copy });
    console.log({ copy });
    // setSubmitStatus(false);
    console.log('profileData with country', profileData);
  };

  const getUserInfo = async () => {
    const response = await userInstance().get('/user/getUserInfo');
    console.log('response==>', response);
    const { userDetails, msg } = response.data;
    if (msg === 'success') {
      setLoader(false);
    }
    let copyUserData = userDetails;
    const getCountryCountryCode = userDetails?.mobileNo?.includes('+61') ? '+61' : '+1';
    if (userDetails?.mobileNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(userDetails.mobileNo);
      userDetails.mobileNo = updatedNo;
      // copySelectedCountry = countryCode;
    }
    if (userDetails?.landlineNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(userDetails.landlineNo);
      userDetails.landlineNo = updatedNo;
      // copySelectedCountry = countryCode;
    }
    console.log('hereherehere', getCountryCountryCode);
    // setSelectedCountry(getCountryCountryCode);
    setProfileData(userDetails);
    handleCountryStates(userDetails?.country);
    console.log('after remove cout==d>', userDetails);
  };

  const editStaff = async (e) => {
    e.preventDefault();
    const {
      firstname, lastname, email, accessLevel, location, mobileNo, gender, state, postcode, streetAddress, suburb,
    } = profileData;
    console.log('profileData', profileData);
    try {
      const { isValid, emptyField } = validateDataWithKey({
        'first name': firstname.trim(),
        'last name': lastname.trim(),
        mobileNo,
        email,
        // gender,
        state,
        postcode,
        suburb,
        streetAddress,
      });

      if (isValid) {
        setError('');
        if (emailRegex.test(String(profileData.email).toLowerCase())) {
          let isMobileValid = true;
          let isLandlineValid = true;
          // let mobileMsg = '';
          let errorMsg = '';
          // let landlineMsg = '';
          const payload = { ...profileData, email: email.toLowerCase() };

          // console.log('selectedCountry', selectedCountry);
          console.log('payload', payload.mobileNo.length);
          if (selectedCountry === '+61') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }

            if (payload.mobileNo.charAt(0) !== '4') {
              isMobileValid = false;
              errorMsg = 'Please start mobile number with 4';
              setError('mobileno');
            }
            if (payload.mobileNo.trim().length !== 11) {
              setError('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 9 Digit Mobile Number';
            }
          }

          if (selectedCountry === '+1') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }

            if (payload.mobileNo.trim().length !== 12) {
              setError('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 10 Digit Mobile Number';
            }
          }

          if (isMobileValid) {
            payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
            const response = await userInstance().put('/user/updateUserProfile', {
              ...payload,
            });

            // // console.log('sdsdsd', response);
            const { msg } = response.data;

            notifyAlert(msg, 'success');
            const copy = { ...userData };
            copy.userInformation = { ...copy.userInformation, ...payload };
            console.log({ copy });
            localStorage.setItem('apptData', JSON.stringify(copy));
            setUserData(copy);
          } else {
            notifyAlert(errorMsg, 'error');
          }
        } else {
          setError('email');
          notifyAlert('Invalid email', 'Error');
        }
      } else {
        setError(emptyField?.split(' ').join('').toLowerCase());
        notifyAlert(`Please fill ${emptyField}`, 'error');
      }
      setTimeout(() => {
        setError('');
      }, 1000);
    } catch (err) {
      showErrMsg(err);
    }
  };

  useEffect(() => {
    console.log('profileData', profileData);
    getUserInfo();
  }, []);

  return (
    <AdminWrapper>
      {loader ? <Loader /> : (
        <div className="contact-page profile-page">
          <div className="container">
            <div className="row">

              <div className="col-md-12">
                <div className="main-titles-mob">
                  <h3>Profile</h3>
                </div>
              </div>
            </div>

            <div className="contact-container client-main-contact">
              <div className="profile-new-form staff-form">
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group controlId="formBasicInput-9" className="italic-text">
                        <Form.Label>
                          Country
                          {/* {/ <span className="required-field">*</span> /} */}
                        </Form.Label>
                        <div className="select-box">
                          <Form.Control
                            as="select"
                            name="country"
                            onChange={handleChange}
                            value={profileData.country}
                            className={`${
                              error === 'country'
                                ? 'error-text'
                                : ''
                            }`}
                          >
                            {countries?.length > 0 && countries.map((el) => <option value={el.name}>{el.name}</option>)}

                          </Form.Control>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicInput-1" className="italic-text">
                        <Form.Label>
                          First name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="firstname"
                          placeholder="Enter First Name"
                          value={profileData?.firstname}
                          onChange={handleChange}
                          className={`${
                            error === 'firstname'
                              ? 'error-text'
                              : ''
                          }`}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicInput-2" className="italic-text">
                        <Form.Label>
                          Last name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="lastname"
                          placeholder="Enter Last Name"
                          value={profileData?.lastname}
                          onChange={handleChange}
                          className={`${
                            error === 'lastname'
                              ? 'error-text'
                              : ''
                          }`}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicInput-3" className="number italic-text">
                        <Form.Label>
                          Mobile No.
                        </Form.Label>
                        <div className="number-field">
                          <div className="number-box">

                            <Dropdown id="mobile">
                              <Dropdown.Toggle variant="success" id="dropdown-basic1">
                                <div className="flag-icon">
                                  <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                                </div>
                                {selectedCountry === '+61' ? (
                                  <span>
                                    Australia
                                    {' '}
                                    {selectedCountry}
                                  </span>
                                ) : selectedCountry === '+1' ? (
                                  <span>
                                    United States
                                    {' '}
                                    {selectedCountry}
                                  </span>
                                ) : ''}
                                {/* <span><i className="fas fa-caret-down" /></span> */}
                                {/* <div className="country-code">{selectedCountry}</div> */}
                              </Dropdown.Toggle>

                              {/* <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                                  <div className="flag-icon">
                                    <img src={flag} alt="flag" />
                                  </div>
                                  <div className="country-code">
                                    <span>Australia</span>
                                    +61
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                                  <div className="flag-icon">
                                    <img src={us} alt="flag" />
                                  </div>
                                  <div className="country-code">
                                    {' '}
                                    <span>United States</span>
                                    +1
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu> */}
                            </Dropdown>
                          </div>
                          <NumberFormat
                            className={`form-control mobile-num ${error === 'mobileno' ? 'error-text' : ''}`}
                            name="mobileNo"
                            onChange={handleChange}
                            value={profileData?.mobileNo}
                            format={`${selectedCountry === '+61' ? '### ### ###' : '### ### ####'}`}
                            placeholder="Enter Mobile Number"
                            autocomplete="off"
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicInput-34" className="number">
                        <Form.Label>Landline No.</Form.Label>
                        <div className="number-field">
                          {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                          <div className="number-box">

                            <Dropdown id="landline">
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <div className="flag-icon">
                                  <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                                </div>
                                {selectedCountry === '+61' ? (
                                  <span>
                                    Australia
                                    {' '}
                                    {selectedCountry}
                                  </span>
                                ) : selectedCountry === '+1' ? (
                                  <span>
                                    United States
                                    {' '}
                                    {selectedCountry}
                                  </span>
                                ) : ''}
                                {/* <span><i className="fas fa-caret-down" /></span> */}
                                {/* <div className="country-code">{}</div> */}
                              </Dropdown.Toggle>

                              {/* <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleCountry('+61')}>
                                  <div className="flag-icon">
                                    <img src={flag} alt="flag" />
                                  </div>
                                  <div className="country-code">
                                    <span>Australia</span>
                                    +61
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleCountry('+1')}>
                                  <div className="flag-icon">
                                    <img src={us} alt="flag" />
                                  </div>
                                  <div className="country-code">
                                    {' '}
                                    <span>United States</span>
                                    +1
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu> */}
                            </Dropdown>
                          </div>
                          <NumberFormat
                            className={`form-control mobile-num ${error === 'landlineno' ? 'error-text' : ''}`}
                            format="# #### ####"
                            onChange={handleChange}
                            name="landlineNo"
                            value={profileData.landlineNo}
                            autocomplete="off"
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicInput-5" className="italic-text">
                        <Form.Label>
                          Email Address
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter Email Address"
                          value={profileData?.email}
                          onChange={handleChange}
                          className={`${
                            error === 'email'
                              ? 'error-text'
                              : ''
                          }`}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicInput-6">
                        <Form.Label>Street Address </Form.Label>
                        <Form.Control
                          type="text"
                          name="streetAddress"
                          value={profileData?.streetAddress}
                          onChange={handleChange}
                          className={`${
                            error === 'streetAddress'
                              ? 'error-text'
                              : ''
                          }`}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicInput-7">
                        <Form.Label>City/Town/Suburb</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          name="suburb"
                          className={`${
                            error === 'suburb'
                              ? 'error-text'
                              : ''
                          }`}
                          value={profileData.suburb}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="formBasicInput-8">
                        <Form.Label>Postal/Zip Code</Form.Label>
                        <Form.Control
                          type="Number"
                          name="postcode"
                          value={profileData?.postcode}
                          onChange={handleChange}
                          className={`${
                            error === 'postcode'
                              ? 'error-text'
                              : ''
                          }`}
                        />
                      </Form.Group>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group
                        controlId="formBasicInput-9"
                        className="italic-text"
                      >
                        <Form.Label>
                          State
                          {/* <span className="required-field">*</span> */}
                        </Form.Label>
                        <div className="select-box">
                          <Form.Control
                            as="select"
                            onChange={handleChange}
                            name="state"
                            value={profileData.state}
                            className={`${error === 'state' ? 'error-text' : ''}`}
                          >
                            {console.log('stateArray', stateArray)}
                            <option value="">Select</option>
                            {stateArray?.length > 0 && stateArray.map((el) => (
                              <option value={el.name}>
                                {el.isoCode}
                                {' '}
                                -
                                {' '}
                                {el.name}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="form-radio" onChange={handleChange} name="gender">
                        <Form.Check
                          type="radio"
                          label="Male"
                          name="gender"
                          id="male"
                          checked={profileData.gender === 'male'}
                        />
                        <Form.Check
                          type="radio"
                          label="Female"
                          name="gender"
                          id="female"
                          checked={profileData.gender === 'female'}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="view-btn staff-form-btn">
                        <Button type="button" className="btn btn-edit"> Cancel </Button>
                        <Button type="Submit" className="btn btn-save" onClick={editStaff}> Save/Update </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>

            </div>
          </div>
        </div>
      ) }
    </AdminWrapper>
  );
};
export default MyProfile;
