import React, { useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ChemicalPopup = ({ popup, setPopUp, handlePopuncheck }) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal chemical-popup') {
            setPopUp(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="duration-picker time-picker-modal chemical-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup">
          <h6>
            Water Test has not been added to pool maintenance.
          </h6>
          <div className="time-keeper-btn">
            <Button onClick={() => handlePopuncheck()}>OK</Button>
          </div>
        </div>
      </div>
    </div>

  );
};
ChemicalPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  setPopUp: PropTypes.isRequired,
  handlePopuncheck: PropTypes.isRequired,
};
export default ChemicalPopup;
