/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable key-spacing */
/* eslint-disable no-unused-expressions */
import React, {
  useState, useEffect, useRef, useContext, useLocation,
} from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import moment from '../../functions/moment';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import './addappointment.css';
import SearchModal from './searchmodal';
import ServiceModal from './servicemodal';
import arrow from '../../assets/home/arrowdown.png';
import arrowwhite from '../../assets/home/whitearrow.png';
import TimePicker from './timepicker';
import DateModal from './dateModal';
import { userInstance, notTimeOutUserInstance } from '../../config/axios';
import ChemicalsAddedPopUp from '../calculator/chemicalsAdded';
import {
  showErrMsg,
  addZero,
  queryString,
  timeIntervalDay,
  eventDateRange,
  getCalendarPath,
  getCalendarPath2,
  blockTimeRange,
  calculatChemicalProductCost,
  currencyComma,
  removeComma,
  updateServiceDateTime,
  getFirstServiceTime,
} from '../../functions/index';
import sort from '../../assets/home/sort.png';
import upsort from '../../assets/home/up-sort.png';
import downsort from '../../assets/home/down-sort.png';
import DurationPicker from './durationPicker';
import HourPicker from './hourPicker';
import WaterSelectionDatePopup from './waterSelectionDatePopup';
import ClientAppointments from './clientAppointments';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import sortClientList from '../../functions/listSort';
import DeleteWaterTestPopup from './deleteWaterTest';
import toastMessage from '../../functions/toastMessage';
import showmore from '../../assets/home/showmore.png';
import clientimg from '../../assets/home/client.png';
import greydelete from '../../assets/home/grey-delete.png';
import {
  getTodayTime, getTimeZone, convertDateTime,
  udpateTime,
  updateEndTime,
  updateStartTime,
  getTimeZone2,
  displayTime, 
} from '../../functions/timeDate';

function AddAppointment() {
  const history = useHistory();
  const {
    clientAllData, setClientAllData, userData: { industryId, calendarSettings, userId },
  } = useContext(AuthDataContext);
  const { timeZone, week } = calendarSettings || {};
  const timeDisplay = calendarSettings?.timeDisplay === 'HH:mm A' ? 'HH:mm' : 'h:mm a';
  const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  const timeFromDateFormat = 'HH:mm';
  let {
    startDateTime,
    employee_id,
    appId,
    blocktime,
    blocktype,
    block_id,
    quoteId,
    clientid,
    waterTest,
    waterId,
    typo,
    cust,
    invoice,
    clident,
    editChemicalList,
  } = queryString();

  const editChem = appId && editChemicalList ? editChemicalList : '';
  let defulttime = moment(getTimeZone(timeZone, startDateTime)).format(timeFromDateFormat).split(':');
  let removeChemical = false;
  let setDefaultTime = defulttime
    ? `${`${defulttime[0]}:${defulttime[1]}`}`
    : '9:30';
  const initialBookingData = {
    frequency: "One-off appointment | Doesn't repeat",
    repeatEnd: 'Ongoing',
    category: 'Pool Vacuum & Clean',
    subcategory: 'Pool Vacuum & Clean',
    startTime: setDefaultTime,
    duration: blocktime === 'open' ? '60' : '5',
    staffId: employee_id,
    staffName: '',
    clientId: '',
    cost: '',
    start: startDateTime,
    end: '',
    customCost: 'default',
    type: '',
    buyCostProduct: '',
    laborMargin: '',
    quantity: '1',
    timeChange: false,
    customMisc: '',
    previousDuration: '',
    previousQuantity: '',
    pricingType: 'fixedCost',
    previousCost: '',
    prevBuyCostProduct: '',
    renderCost: '',
    soldPer: '',
    unit: '',
    symbol: '',
    description: '',
    tax: '',
    s_uId: '',
    categoryId: '',
    xerocode: '',
    supplier: '',
  };
  const otherBookingInitial = {
    category: 'Pool Vacuum & Clean',
    subcategory: 'Pool Vacuum & Clean',
    duration: blocktime === 'open' ? '60' : '5',
    cost: '',
    start: startDateTime,
    end: '',
    customCost: 'default',
    type: '',
    buyCostProduct: '',
    laborMargin: '',
    quantity: '1',
    customMisc: '',
    previousDuration: '',
    previousQuantity: '',
    pricingType: 'fixedCost',
    soldPer: '',
    unit: '',
    symbol: '',
    tax: '',
    supplier: '',
  };
  const initialDatePopup = { interval_type: 'weekly', interval_multiplier: '4' };
  const initialDateData = {
    date: startDateTime,
    createdOn: new Date(getTodayTime(timeZone)),
    interval_multiplier: null,
    occurence: null,
    interval_type: null,
    endsAt: null,
    endType: null,
    msg: "One-off appointment | Doesn't repeat",
    frequencyMsg: '',
    occurenceMsg: '',
    specificDate: moment(new Date(getTodayTime(timeZone))).format(dateFormat),
    requiredday: 2,
    teammember: 2,
    appointmenttype: 'oneteam_oneday',
    service: [],
    notes: null,
    summary: null,
    repeatAppt: false,
    chemicalTestArray: [],
    days: '',
  };
  // changes by sanjana
  const multiNotesState = [{ notes: null }];
  const [multiNotes, setMultiNotes] = useState(multiNotesState);
  const [showSearch, setShowSearch] = useState(false);
  const [showService, setShowService] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [searchedClients, setClients] = useState(null);
  const [searchedClients2, setClients2] = useState(null);
  const [addedClients, setAddedClients] = useState();
  const [bookingData, setBookingData] = useState(initialBookingData);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState(null);
  const [staffList, setStaffList] = useState([]);
  const [addedServices, setServices] = useState([]);
  const [editData, setEditData] = useState({ edit: false, index: '' });
  const [notes, setNotes] = useState('');
  const [hourPicker, setHourPicker] = useState(false);
  const [hourData, setHourData] = useState('');
  const [summary, setSummary] = useState({
    totalCost: 0,
    totalDuration: '',
    totalLabourMargin: 0,
  });
  const [mutiSummary, setMutiSummary] = useState({
    totalCost: 0,
    totalDuration: '',
    totalLabourMargin: 0,
    totalLabourAmount: 0,
    totalLabour: 0,
  });

  const [dateData, setDateData] = useState(initialDateData);
  const [renderSort, setRenderSort] = useState(false);
  const [misc, setMisc] = useState(false);
  const [extras, setExtras] = useState({ value: '', msg: '' });
  const [timepickerShow, setTimePickerShow] = useState(false);
  const [subcategoryTxt, setSearchTxt] = useState('');
  const [clientTxt, setClientTxt] = useState('');
  const [durationPicker, setDurationPicker] = useState(false);
  const [timeData, setTime] = useState('9:30');
  const [durationData, setDuration] = useState('');
  const [repeatAppt, setRepeatAppt] = useState(false);
  const [repeatFrequency, setRepeatFrequency] = useState('');
  const [datePopupData, setDatePopupData] = useState(initialDatePopup);
  const timepickerRef = useRef(null);

  const [newLength, setnewlength] = useState();
  const [staffIndex, setStaffIndex] = useState();
  const [viewAppointmentPopup, setViewappointment] = useState();
  const [appointmentType, setAppointmentType] = useState('oneteam_oneday');
  const [multiServices, setMultiServices] = useState([]);
  const [multiAppointment, setmultiAppointment] = useState(false);
  const [activeAdd, setActiveAdd] = useState(0);
  const [newAppointment, setNewAppointment] = useState([initialDateData]);
  const [appointmentIndex, setappointmentIndex] = useState(0);
  const [blcoktimeName, setBlcoktimeName] = useState('');
  const [blcokId, setBlcokId] = useState('');
  const [blocktypeCheck, setBlocktypeCheck] = useState(true);
  const [staffTypeIndex, setStaffTypeIndex] = useState(0);
  const [checksubmit, setChecksubmit] = useState(false);
  const [requiredDays, setRequiredDays] = useState(2);
  const [team, setTeam] = useState(2);
  const [isHideSearch, setHideSearch] = useState(false);
  const [waterTestPopup, setWaterTestPopup] = useState(false);
  const [removeIndex, setRemoveIndex] = useState('');
  const [activeStaff, setActiveStaff] = useState(null);

  const [mutiStaff, setMutiStaff] = useState([]);
  const handleSaveDate = () => {
    updateDateData();
    handleFrequency();
  };

  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(1);
  const [showWaterSelectionPopup, setShowWaterSelectionPopup] = useState(false);
  const [showClientAppointment, setShowClientAppoinment] = useState(false);
  const [selectBoxes, setSelectBoxes] = useState('yes');
  const [appIdMerge, setAppIdMerge] = useState('');
  const [endTimeNew, setEndTimeNew] = useState('');
  const [dateByApp, setDateByApp] = useState('');
  const [calculater_type, setCalculater_type] = useState('');
  const [chemicalOptionsSelected, setChemicalOptionsSelected] = useState('chemicalsAdded');
  const [sourceReading, setSoourceReading] = useState(false);
  const [chemicalAddSelected, setChemicalAddSelected] = useState(false);
  const [multiEditQuantityStatus, setMultiEditQuantityStatus] = useState(false);
  const [editQuantityStatus, setEditQuantityStatus] = useState(false);
  const [status, setStatus] = useState(true);
  const [editSellPriceStatus, setEditSellPriceStatus] = useState(false);
  const [dbTimeZone, setDbTimeZone] = useState('');

  const [waterServiceSummary, setWaterServiceSummary] = useState({
    newTotalCost: 0,
    newTotalDuration: 0,
    newTotalLabourMargin: 0,
  });
  let limit = 10;
  const [errorValue, setErrorValue] = useState('');

  useEffect(() => {
    if (typo) {
      handleShowSearch();
    }
  }, []);

  const handleCancel = () => {
    setShowWaterSelectionPopup(false);
    setShowClientAppoinment(false);
  };
  const handleWaterTestApp = (data) => {
    setSelectBoxes(data);
  };

  const handleAddAppointmentPopup = async (cId) => {
    if (cId && waterTest === 'done') {
      setShowWaterSelectionPopup(true);
    }
  };

  useEffect(() => {
    handleAddAppointmentPopup(userId);
  }, []);
  const pageCount = Math.ceil(pageCounts / limit);
  const [appointmentClient, setAppointmentClient] = useState([]);
  const handleSelect = async () => {
    if (selectBoxes === 'no') {
      setShowWaterSelectionPopup(false);
      setEndTimeNew('');
      setAppIdMerge('');
      setDateByApp('');
    } else if (selectBoxes === 'yes') {
      let response = [];
      response = await userInstance().get(
        `/appointment/appointmentByClient?clientId=${userId}&skip=${skip}&limit=${limit}`,
      );
      const { clientAppointments, count } = response.data;
      if (clientAppointments.length > 0) {
        setShowClientAppoinment(true);
        setShowWaterSelectionPopup(false);
        setAppointmentClient(clientAppointments);
        setPageCounts(count);
      } else {
        toastMessage('error', 'No Existing Appointment.');
      }
    }
  };

  useEffect(() => {
    if (showClientAppointment) handleSelect();
  }, [skip]);

  const setStaff = (staffName, staffId, info) => {
    if (info === 'oneteam_oneday') {
      const old = [...newAppointment];
      const servicesListCopy = old[0].service;
      servicesListCopy.forEach((el) => {
        el.staffId = staffId;
        el.staffName = staffName;
      });
      old[0].service = servicesListCopy;
      setNewAppointment([...old]);
    } else if (info === 'oneteam_multiday') {
      const old = [...newAppointment];
      old.forEach((item) => {
        item.service.forEach((el) => {
          el.staffId = staffId;
          el.staffName = staffName;
        });
        if (waterTest === 'done') {
          item.chemicalTestArray.forEach((el) => {
            el.staffId = staffId;
            el.staffName = staffName;
          });
        }
      });
      setNewAppointment([...old]);
    } else if (info === 'multiteam_oneday' || info === 'multiteam_multiday') {
      if (editData.edit) {
        const old = [...newAppointment];
        const indexService = old[appointmentIndex].service;
        indexService.forEach((el) => {
          el.staffId = staffId;
          el.staffName = staffName;
        });
        old[appointmentIndex].service = indexService;
        setNewAppointment([...old]);
        const OldState = [...mutiStaff];
        const findIndex = OldState.findIndex(
          (el) => el.index === appointmentIndex,
        );
        if (findIndex > -1) {
          OldState[findIndex].staffId = staffId;
          OldState[findIndex].staffName = staffName;
          setMutiStaff(OldState);
        }
      }
    }
  };

  const updateDateData = (isEndType) => {
    let { interval_type, interval_multiplier } = datePopupData;
    const { occurenceMsg } = dateData || {};
    const originalIntervalType = interval_type;
    let frequencyMsg = '';
    if (parseInt(interval_multiplier, 10) > 1) {
      switch (interval_type) {
        case 'monthly':
          interval_type = 'months';
          break;
        case 'weekly':
          interval_type = 'weeks';
          break;
        case 'daily':
          interval_type = 'days';
          break;

        default:
          break;
      }
    }
    frequencyMsg = parseInt(interval_multiplier, 10) > 1
      ? `Repeats every ${interval_multiplier} ${interval_type}`
      : interval_type;
    const updateObj = {
      ...dateData,
      interval_multiplier,
      interval_type: originalIntervalType,
      frequencyMsg,
    };
    if (occurenceMsg === 'On a Specific Date') {
      const interval = timeInterval(interval_multiplier, originalIntervalType);
      updateObj.specificDate = interval;
    }
    if (isEndType) {
      updateObj.endType = 'ongoing';
      updateObj.occurenceMsg = 'Ongoing';
    }
    setDateData(updateObj);
  };

  const handleChangeDate = (type, value) => {
    setDatePopupData({ ...datePopupData, [type]: value });
  };

  const handleFrequency = () => {
    setRepeatFrequency(!repeatFrequency);
    if (!repeatFrequency) {
      setTimeout(() => {
        timepickerRef.current.value = `${datePopupData.interval_multiplier}:00`;
      }, 350);
    }
  };

  const handleRepeat = () => {
    setRepeatAppt(!repeatAppt);
    const old = [...newAppointment];
    if (old[appointmentIndex].repeatAppt) {
      old[appointmentIndex].repeatAppt = false;
      old[appointmentIndex].occurence = null;
      old[appointmentIndex].frequencyMsg = null;
      old[appointmentIndex].interval_type = null;
      old[appointmentIndex].msg = "One-off appointment | Doesn't repeat";
      old[appointmentIndex].endType = null;
      old[appointmentIndex].occurenceMsg = null;
      old[appointmentIndex].interval_multiplier = null;
      // const initialCopy = { ...old[appointmentIndex] };
      // delete initialCopy.date;
      // setDateData({ ...initialCopy, date: dateData.date });
      // setDatePopupData({ ...initialDatePopup });
      // old[appointmentIndex] = { ...initialCopy, date: dateData.date };
    } else {
      old[appointmentIndex].repeatAppt = true;
      updateDateData(true);
    }
    setNewAppointment(old);
    if (repeatAppt || blocktime === 'open') {
      const initialCopy = { ...initialDateData };
      delete initialCopy.date;
      setDateData({ ...initialCopy, date: dateData.date });
      setDatePopupData({ ...initialDatePopup });
    } else {
      updateDateData(true);
    }
  };
  const handleTimePicker = () => {
    setTime(bookingData.startTime);
    setTimePickerShow(!timepickerShow);
  };

  const handleDurationPicker = () => {
    setDuration(bookingData.duration);
    setDurationPicker(!durationPicker);
  };
  const handleHourPicker = () => {
    setHourData(bookingData.quantity);
    setHourPicker(!hourPicker);
  };
  
  const moveArrr = (oldIndex, newIndex, sign, appIndex, upperIndex) => {
    const old = [...newAppointment];
    let servicesCopy = old[appIndex].service;
    const { duration, start, startTime } = servicesCopy[newIndex];

    let newNextStartTime;
    let currentNewStartTime;

    if (sign === 'plus') {
      const currentEntryStart = convertDateTime(servicesCopy[oldIndex].start); // new Date(servicesCopy[oldIndex].start);
      // const updatedNextEntryStart = moment(
      //   new Date(
      //     currentEntryStart.setMinutes(
      //       currentEntryStart.getMinutes() + parseFloat(duration),
      //     ),
      //   ),
      // ).format(dateFormat);
      const updatedNextEntryStart = updateStartTime(currentEntryStart, duration);
      newNextStartTime = updatedNextEntryStart.format(
        timeFromDateFormat,
      );
      // newNextStartTime = moment(updatedNextEntryStart).format(
      //   timeFromDateFormat,
      // );

      currentNewStartTime = servicesCopy[oldIndex].startTime;
    } else if (sign === 'minus') {
      newNextStartTime = startTime;
      const startObj = convertDateTime(start);
      const updatedCurrentEntryStart = updateStartTime(startObj, servicesCopy[oldIndex].duration);

      // const updatedCurrentEntryStart = moment(
      //   new Date(
      //     new Date(start).setMinutes(
      //       new Date(start).getMinutes()
      //       + parseFloat(servicesCopy[oldIndex].duration),
      //     ),
      //   ),
      // ).format(dateFormat);

      currentNewStartTime = updatedCurrentEntryStart.format(
        timeFromDateFormat,
      );
    }

    const updatedOldIndex = {
      ...servicesCopy[oldIndex],
      startTime: newNextStartTime,
    };
    const updatedNewIndex = {
      ...servicesCopy[newIndex],
      startTime: currentNewStartTime,
    };
    servicesCopy[oldIndex] = { ...updatedOldIndex };
    servicesCopy[newIndex] = { ...updatedNewIndex };

    if (newIndex >= servicesCopy.length) {
      let k = newIndex - servicesCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        servicesCopy.push(undefined);
      }
    }
    servicesCopy.splice(newIndex, 0, servicesCopy.splice(oldIndex, 1)[0]);
    
    calculateMutiDurations(servicesCopy, appIndex, upperIndex);
  };

  // console.log('newAppointment', newAppointment[0]?.service);
  const calculateMutiDurations = (services, appIndex) => {
    const old = [...newAppointment];
    services.forEach((el) => {
      // let start = moment(el.date).toDate();
      // const time = el.startTime.split(':');
      // start.setHours(time[0]);
      // start.setMinutes(time[1]);
      // start.setSeconds(0);

      const start = udpateTime(old[0].date, el.startTime, true);

      el.start = start.format(dateFormat);
      el.end = updateEndTime(start, el.duration);
    });
    old[appIndex].service = services;
    setNewAppointment(old);
    // setServices([...services]);
  };

  const timeInterval = (interval, type) => {
    if (type === 'weekly') {
      // let intervalDay = dateData.specificDate interval * 7;
      let intervalDay = moment(dateData.date)
        .add(interval * 7, 'days')
        .format(dateFormat);
      return intervalDay;
    }
    if (type === 'daily') {
      let intervalDay = moment(dateData.date)
        .add(interval, 'days')
        .format(dateFormat);
      return intervalDay;
    }
    if (type === 'monthly') {
      let intervalDay = moment(dateData.date)
        .add(interval * 30, 'days')
        .format(dateFormat);
      // let intervalDay = interval * 30;
      return intervalDay;
    }
  };
  const handleChangeDateData = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const obj = { ...dateData };
    if (value === 'On a Specific Date') {
      const interval = timeInterval(obj.interval_multiplier, obj.interval_type);
      obj.specificDate = interval;
    }
    if (value === 'Repeats every 4 weeks') {
      handleFrequency();
    }
    if (
      name === 'interval_multiplier'
      && (value === 'daily'
        || value === 'weekly'
        || value.includes('weeks')
        || value.includes('days')
        || value.includes('One-off'))
    ) {
      const val = value.includes('weeks') || value.includes('days')
        ? parseInt(value.replace(/[^0-9\\.]/g, ''), 10)
        : 1;
      obj.interval_multiplier = val;
      obj.interval_type = value.includes('weeks') || value === 'weekly' ? 'weekly' : 'daily';
      obj.frequencyMsg = value;
      if (!obj.occurence) {
        obj.occurence = null;
      }
      obj.endType = !obj.endType ? 'ongoing' : 'by_occurence';
      if (value === "One-off appointment | Doesn't repeat") {
        obj.occurence = null;
        obj.frequencyMsg = null;
        obj.interval_type = null;
        obj.msg = value;
        obj.interval_multiplier = null;
        obj.endType = null;
        obj.frequencyMsg = '';
        obj.occurenceMsg = '';
        // obj = { ...initialDateData, date: dateData.bookingData, msg: value };
      } else {
        obj.occurenceMsg = !obj.occurenceMsg ? 'Ongoing' : obj.occurenceMsg;
      }
    } else if (name === 'occurence') {
      if (value === 'Ongoing') {
        obj.occurence = null;
        obj.endType = value.toLowerCase();
        obj.occurenceMsg = value;
      } else {
        obj.occurenceMsg = value;
        obj[name] = parseInt(value.replace(/[^0-9\\.]/g, ''), 10);
        obj.endType = 'by_occurence';
      }
    } else {
      obj[name] = value;
    }
    setDateData(obj);
  };

  const handleChangeCustom = (value) => {
    const obj = { ...bookingData, customCost: value };
    if (value === 'default') {
      obj.cost = bookingData.previousCost;
      if (bookingData.type === 'product') {
        obj.buyCostProduct = bookingData.prevBuyCostProduct;
      }
    } else if (value === 'yes' || value === 'sellPrice') {
      obj.cost = '';
      obj.buyCostProduct = obj.prevBuyCostProduct;
    } else if (value === 'buyCost') {
      obj.buyCostProduct = '';
      obj.cost = obj.previousCost;
    } else if (value === 'buySell') {
      obj.cost = '';
      obj.buyCostProduct = '';
    }
    setBookingData(obj);
  };

  const getAllClients = async (type) => {
    const response = await userInstance().get('/staff/getStaffList');
    if (response.status === 200) {
      const { staff } = response.data;
      if (type !== 'open') {
        const defaultseletedStaff = staff?.find((el) => el._id === employee_id);
        setAddedClients(defaultseletedStaff);
      }
      // console.log('staff==>', defaultseletedStaff);
      // employee_id
      // let updatedStaff = staff;
      // if (addedClients[0]?._id) {
      //   updatedStaff = staff.map((el) => {
      //     if (el._id === addedClients[0]._id) {
      //       if (el.siteAddresses[activeAdd]) {
      //         el.siteAddresses[activeAdd].activeSiteAdd = true;
      //       }
      //     }
      //     return el;
      //   });
      // }
      // const newList = sortClientList(updatedStaff);
      // const newList2 = sortClientList(staff);
      setClients(staff);
      setClients2(staff);
    }
  };

  const handleShowSearch = () => {
    setShowSearch(!showSearch);
    // console.log('showSearch ==>', showSearch);
    if (showSearch) {
      setClients(null);
    } else {
      getAllClients('open');
    }
  };
  const searchClient = async (searchTxt_, type) => {
    setClientTxt(searchTxt_);
    // if (searchTxt_) {
    //   const response = await userInstance().get(
    //     `/staff/searchStaff?value=${searchTxt_}`,
    //   );
    //   const { staff } = response?.data || {};
    //   console.log('staff==>', staff);
    //   // console.log('response==>', response);
    //   // let list = response?.data?.staff;
    //   // const newList = sortClientList(list);

    //   // console.log('customers=>', list);
    //   // if (isHideSearch) {
    //   //   console.log('coming under This Section');
    //   //   list.forEach((client) => {
    //   //     if (client.siteAddresses) {
    //   //       client.siteAddresses.forEach((addr) => {
    //   //         addr.hide = false;
    //   //       });
    //   //     }
    //   //   });
    //   // } else {
    //   //   list = list.filter((el) => (el.siteAddresses
    //   //     ? el.siteAddresses.filter((adr) => adr.hide).length === 0
    //   //     : el));
    //   // }
    //   // searchedClients2(staff);
    //   setClients(staff);
    // }
  };
  useEffect(() => {
    getAllClients();
  }, []);

  useEffect(() => {
    let searchTimeOut = setTimeout(async () => {
      if (!clientTxt) {
        // console.log('clientTxt==>', clientTxt);
        setClients(searchedClients2);
        return;
      }
      try {
        // console.log('API CALEED HERE IN TIMEOUT', clientTxt);

        const response = await userInstance().get(`/staff/searchStaff?value=${clientTxt}`);
        const { staff } = response?.data || {};
        // if (clientTxt) {
        //   if (isHideSearch) {
        //     console.log('check3');
        //     list.forEach((client) => {
        //       if (client.siteAddressess) {
        //         client.siteAddressess.forEach((addr) => {
        //           addr.hide = false;
        //         });
        //       }
        //     });
        //   } else {
        //     console.log('wantConditions');
        //     list = list.filter((el) => (el.siteAddresses ? el.siteAddresses.filter((adr) => adr.hide).length === 0 : el));
        //   }
        // }
        // const newList = sortClientList(staff);
        setClients(staff);
      } catch (error) {
        console.log(error);
      }
    }, 500);
    // console.log('intravl2');
    return (() => clearInterval(searchTimeOut));
  }, [clientTxt]);

  const getAppointmentById = async () => {
    try {
      const response = await userInstance().get(
        `/appointment/getAppointmentById?id=${appId}`,
      );
      const { appointmentdetail, msg } = response.data;
      if (msg === 'Success') {
        let {
          multiTotal,
          totalCost,
          totalDuration,
          totalLabour,
          totalLabourOnly,
          totalMargin,
        } = appointmentdetail.dateData
          ? appointmentdetail.dateData[0].summary
          : {};
        setMutiSummary({
          totalCost: totalCost || '',
          totalDuration: totalDuration || '',
          totalLabourMargin: totalMargin || '',
          totalLabourAmount: totalLabourOnly || '',
          totalLabour: totalLabour || '',
        });
        setAppointmentType(appointmentdetail.appointmenttype);
        const old = [...appointmentdetail.dateData];
        old[0].date = startDateTime;
        const oldservice = old[0].service;
        updateServiceDateTime(oldservice, startDateTime, 'addapp');
        setNewAppointment(old);
        // setAddedClients([appointmentdetail.client]);
        // setActiveAdd(appointmentdetail.clientSiteAddressIndex);
        setServices(appointmentdetail.services);
        setSummary(appointmentdetail.totalData);
        calculateNew(appointmentdetail.services);
      }
    } catch (error) {
      //   console.log(error);
    }
  };

  const calculateNew = (data) => {
    // console.log('data new calculate=>', data);
    for (let i = 0; i < data.length; i++) {
      const currentEl = data[i];
      let end = '';
      if (i === 0) {
        end = new Date(startDateTime);
      } else {
        end = new Date(data[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(
        new Date(
          end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
        ),
      ).format(dateFormat);
      setServices(...currentEl);
    }
  };

  const handleShowService = (index) => {
    setappointmentIndex(index);
    setStaffIndex(0);
    const old = [...newAppointment];
    const Index = index || 0;
    if (
      appointmentType === 'multiteam_oneday'
      || appointmentType === 'multiteam_multiday'
    ) {
      const currentServices = old[Index].service.length;
      setnewlength(currentServices);
    } else if (appointmentType === 'oneteam_multiday') {
      setnewlength(0);
    } else {
      setnewlength(addedServices.length);
    }
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setShowService(!showService);
    setEditData({ edit: false, index: '' });
    const lastService = addedServices[addedServices.length - 1];
    // console.log('currentServices', old[Index].service);
    if (lastService && old[Index].service.length !== 0) {
      console.log('1');
      const startTime = convertDateTime(lastService.end).format(timeFromDateFormat);
      setBookingData({ ...bookingData, startTime, ...otherBookingInitial });
    } else {
      console.log('222');
      const startTime = appIdMerge !== '' ? endTimeNew : bookingData.startTime;
      setBookingData({ ...bookingData, startTime, ...otherBookingInitial });
    }
    setSearchTxt('');
    setSubcategories(null);
    setMisc(false);
    if (appointmentType === 'multiteam_oneday') {
      checkStaff(old, Index, index);
    } else if (appointmentType === 'multiteam_multiday') {
      const appDate = old[Index].date;
      checkMultiStaff(old, Index, index, appDate);
    } else if (appointmentType === 'oneteam_multiday') {
      const currentServices = old[0].service[0];
      if (currentServices) {
        const obj = {
          ...bookingData,
          staffId: currentServices.staffId,
          staffName: currentServices.staffName,
        };
        setBookingData(obj);
      }
    }
  };

  const checkMultiStaff = async (old, Index, index, appDate) => {
    let leftStaffs = [];
    if (mutiStaff.length > 0) {
      const filterDates = mutiStaff.filter(
        (el) => new Date(el.date).toLocaleDateString()
          === new Date(appDate).toLocaleDateString(),
      );
      const findIndex = filterDates.filter((el) => el.index < index);
      if (findIndex.length > 0) {
        const currentServices = old[Index].service.length;
        if (currentServices === 0) {
          findIndex.forEach((el) => {
            leftStaffs.push(el.staffId);
          });
          const filterProList = filterArray(staffList, leftStaffs);
          const obj = {
            ...bookingData,
            staffId: filterProList[0]._id,
            staffName: `${filterProList[0].firstname} ${filterProList[0].lastname}`,
            startTime: setDefaultTime,
          };
          setBookingData(obj);
          setStaffList(filterProList);
        } else {
          const findIndexStaff = mutiStaff.filter((el) => el.index === index);
          if (findIndexStaff.length > 0) {
            const obj = {
              ...bookingData,
              staffId: findIndexStaff[0].staffId,
              staffName: findIndexStaff[0].staffName,
              startTime: setDefaultTime,
            };
            setBookingData(obj);
          }
        }
      } else {
        await getStaffList();
        const findIndexStaff = mutiStaff.filter((el) => el.index === index);
        if (findIndexStaff.length > 0) {
          const setDefaultStaff = await staffList.filter(
            (el) => el._id === findIndexStaff[0].staffId,
          );
          if (setDefaultStaff.length > 0) {
            const obj = {
              ...bookingData,
              staffId: setDefaultStaff[0]._id,
              staffName: `${setDefaultStaff[0].firstname} ${setDefaultStaff[0].lastname}`,
              startTime: setDefaultTime,
            };
            setBookingData(obj);
          }
        } else {
          await getStaffList();
          const obj = {
            ...bookingData,
            startTime: setDefaultTime,
          };
          setBookingData(obj);
        }
      }
    }
  };

  const checkStaff = async (old, Index, index) => {
    let leftStaffs = [];
    if (mutiStaff.length > 0) {
      const findIndex = mutiStaff.filter((el) => el.index < index);
      if (findIndex.length > 0) {
        const currentServices = old[Index].service.length;
        if (currentServices === 0) {
          findIndex.forEach((el) => {
            leftStaffs.push(el.staffId);
          });
          const filterProList = filterArray(staffList, leftStaffs);
          const obj = {
            ...bookingData,
            staffId: filterProList[0]._id,
            staffName: `${filterProList[0].firstname} ${filterProList[0].lastname}`,
            startTime: setDefaultTime,
          };
          setBookingData(obj);
          setStaffList(filterProList);
        } else {
          const findIndexStaff = mutiStaff.filter((el) => el.index === index);
          if (findIndexStaff.length > 0) {
            const obj = {
              ...bookingData,
              staffId: findIndexStaff[0].staffId,
              staffName: findIndexStaff[0].staffName,
              startTime: setDefaultTime,
            };
            setBookingData(obj);
          }
        }
      } else {
        await getStaffList();
        const findIndexStaff = mutiStaff.filter((el) => el.index === index);
        const setDefaultStaff = await staffList.filter(
          (el) => el._id === findIndexStaff[0].staffId,
        );
        if (setDefaultStaff.length > 0) {
          const obj = {
            ...bookingData,
            staffId: setDefaultStaff[0]._id,
            staffName: `${setDefaultStaff[0].firstname} ${setDefaultStaff[0].lastname}`,
            startTime: setDefaultTime,
          };
          setBookingData(obj);
        }
      }
    }
  };

  const filterArray = (arr1, arr2) => {
    let filteredArr = [];
    arr1.forEach((el) => {
      const userExists = arr2.some((user) => user === el._id);
      if (!userExists) {
        filteredArr.push(el);
      }
    });
    return filteredArr;
  };

  const handleDateModal = (handeltype, index) => {
    // console.log('handleDateModal');
    const resulttChemical = JSON.parse(localStorage.getItem('chemicalalist'));
    const chemicalalist = resulttChemical ? resulttChemical[0] : {};
    const {
      date,
      occurence,
      interval_multiplier,
      interval_type,
      endType,
      occurenceMsg,
      specificDate,
      requiredday,
      createdOn,
      endsAt,
      appointmenttype,
      teammember,
    } = dateData;
    let oldStateData = [...newAppointment];
    const obj = oldStateData[index];
    setappointmentIndex(index);
    let startDate = new Date(date);
    // startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(endsAt || startDate);
    // endDate.setHours(0, 0, 0, 0);
    if (handeltype === 'add' && appointmentType === 'oneteam_oneday') {
      setOneTeamOneDay(dateData, startDate, oldStateData, endDate, index);
    } else if (handeltype === 'add' && appointmentType === 'oneteam_multiday') {
      setOneTeamMultiday(oldStateData, obj, startDate, dateData, index, endDate);
    } else if (handeltype === 'add' && appointmentType === 'multiteam_oneday') {
      setMultiTeamOneday(
        index,
        teammember,
        oldStateData,
        obj,
        startDate,
        createdOn,
        dateData,
      );
    } else if (
      handeltype === 'add'
      && appointmentType === 'multiteam_multiday'
    ) {
      setMultiTeamMultiday(
        index,
        teammember,
        requiredday,
        oldStateData,
        obj,
        startDate,
        createdOn,
        dateData,
      );
    } else if (
      handeltype === 'close'
      && appointmentType === 'oneteam_multiday'
    ) {
      // console.log('gettismk ');
      oldStateData.splice(index, 1);
      if (oldStateData.length === 1) {
        setRequiredDays(2);
      } else {
        setRequiredDays(oldStateData.length);
      }
      // console.log('oldStateData===============>', oldStateData);
      calculateWatesrCost(chemicalalist, 'deletedays', oldStateData);
      setNewAppointment(oldStateData);
      setappointmentIndex(0);
      calculateMutiCost(oldStateData, null);
    } else if (
      handeltype === 'close'
      && appointmentType === 'multiteam_oneday'
    ) {
      oldStateData.splice(index, 1);
      if (oldStateData.length === 1) {
        setTeam(2);
      } else {
        setTeam(oldStateData.length);
      }
      calculateWatesrCost(chemicalalist, 'deletedays', oldStateData);
      setNewAppointment(oldStateData);
      setappointmentIndex(0);
      calculateMutiCost(oldStateData, null);
      const oldMulti = [...mutiStaff];
      const findIndex = oldMulti.findIndex((el) => el.index === index);
      oldMulti.splice(findIndex, 1);
      setMutiStaff(oldMulti);
    } else if (
      handeltype === 'close'
      && appointmentType === 'multiteam_multiday'
    ) {
      // console.log('length', oldStateData.length, team);
      if (oldStateData.length > team) {
        const indexDate = oldStateData[index].date;
        for (var i = oldStateData.length - 1; i >= 0; i--) {
          if (
            new Date(indexDate).toLocaleString()
            === new Date(oldStateData[i].date).toLocaleString()
          ) {
            oldStateData.splice(i, 1);
          }
        }
      } else {
        oldStateData.length = 1;
      }
      calculateWatesrCost(chemicalalist, 'deletedays', oldStateData);
      checkDaysAndTeam(oldStateData);
      setNewAppointment(oldStateData);
      setappointmentIndex(0);
      calculateMutiCost(oldStateData, null);
      const oldMulti = [...mutiStaff];
      const findIndex = oldMulti.findIndex((el) => el.index === index);
      oldMulti.splice(findIndex, 1);
      setMutiStaff(oldMulti);
    } else {
      const dateDataCopy = oldStateData[index];
      oldStateData[index] = dateDataCopy;
      setDateData(dateDataCopy);
      setNewAppointment(oldStateData);
    }
    setShowDate(!showDate);
  };

  const checkDaysAndTeam = (data) => {
    const arr = [];
    let count = 1;
    let j = 0;
    for (let i = 0; i < data.length; i++) {
      const findArr = arr.find((el) => el.date === data[i].date);
      if (!findArr) {
        arr.push({ date: data[i].date, count });
        j++;
      } else {
        arr[j - 1].count = arr[j - 1].count + 1;
      }
    }
    // console.log('arr in checkDaysAndTeam', arr);
    if (data.length <= 4) {
      setRequiredDays(2);
      setTeam(2);
    } else {
      setRequiredDays(arr.length);
      setTeam(arr[0].count);
    }
  };

  const CrossDate = () => {
    setShowDate(!showDate);
  };

  const SetRDays = (info) => {
    setRequiredDays(info);
  };

  const SetRteams = (info) => {
    setTeam(info);
  };

  const setOneTeamOneDay = (
    dateDataa,
    startDate,
    oldStateData,
    endDate,
    index,
  ) => {
    const {
      date,
      occurence,
      interval_multiplier,
      interval_type,
      endType,
      occurenceMsg,
      specificDate,
      requiredday,
      createdOn,
      endsAt,
      appointmenttype,
      teammember,
    } = dateDataa;
    oldStateData[0].date = moment(startDate).format(dateFormat);
    oldStateData[0].endsAt = moment(endDate).format(dateFormat);
    setNewAppointment(oldStateData);
    let msgIntervalType = 'days';
    if (interval_multiplier > 1) {
      msgIntervalType = 'days';
    } else {
      msgIntervalType = 'day';
    }

    if (interval_multiplier) {
      let newDate = new Date(
        occurenceMsg === 'On a Specific Date' ? specificDate : date,
      );
      newDate.setHours(12, 0, 0, 0);
      let interval = interval_multiplier;
      if (interval_type === 'weekly') {
        if (interval > 1) {
          msgIntervalType = 'weeks';
        } else {
          msgIntervalType = 'week';
        }
        interval *= 7;
      } else if (interval_type === 'monthly') {
        if (interval > 1) {
          msgIntervalType = 'months';
        } else {
          msgIntervalType = 'month';
        }
        newDate = new Date(
          moment(newDate, 'DD-MM-YYYY')
            .add((occurence - 1) * parseInt(interval, 10), 'months')
            .format('L'),
        );

        // msgIntervalType = 'months';
      }
      if (endType === 'ongoing') {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() - 1);
      } else if (
        occurenceMsg !== 'On a Specific Date'
        && interval_type !== 'monthly'
      ) {
        const daysToAdd = (occurence - 1) * interval;
        // console.log('daysToAdd', daysToAdd, occurence, interval);
        newDate.setDate(newDate.getDate() + daysToAdd);
        // console.log('date....', newDate, 'old date', date);
      }
      // console.log('newDat.....e', newDate);
      const msg = endType === 'ongoing'
        ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ongoing`
        : occurenceMsg !== 'On a Specific Date'
          ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends after ${occurence} times on ${moment(
            newDate,
          ).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`
          : `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends on ${moment(
            newDate,
          ).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`;
      let dateDataCopy = {
        ...dateDataa,
        endsAt: newDate,
        msg,
        occurenceMsg,
        occurence,
      };
      oldStateData[index] = dateDataCopy;
      setNewAppointment(oldStateData);
      // setDateData({ ...dateDataa, msg, endsAt: newDate });
    }
  };

  const setOneTeamMultiday = (
    oldStateData,
    obj,
    startDate,
    dateDataa,
    index,
    endDate,
  ) => {
    const {
      date,
      occurence,
      interval_multiplier,
      interval_type,
      endType,
      occurenceMsg,
      specificDate,
      requiredday,
      createdOn,
      endsAt,
      appointmenttype,
      teammember,
    } = dateDataa;
    if (index === 0) {
      if (
        new Date(initialDateData.date).toLocaleDateString()
        !== new Date(startDate).toLocaleDateString()
      ) {
        oldStateData[0].date = moment(startDate).format(dateFormat);
        oldStateData[0].endsAt = moment(endDate).format(dateFormat);
        setNewAppointment(oldStateData);
      }
    }
    let addService = [];
    let nostep = parseInt(requiredDays, 10) || 2;
    let nextDate = startDate;
    let { length } = newAppointment;
    // console.log({ length, nostep });
    if (length === nostep) {
      oldStateData[index].date = moment(startDate).format(dateFormat);
      oldStateData[index].endsAt = moment(endDate).format(dateFormat);
      setNewAppointment(oldStateData);
    } else if (length > nostep) {
      oldStateData.length = nostep;
      setNewAppointment(oldStateData);
      calculateMutiCost(oldStateData, null);
      setappointmentIndex(0);
    } else {
      nextDate = new Date(oldStateData[oldStateData.length - 1].date);
      for (let step = length; step < nostep; step++) {
        nextDate.setDate(nextDate.getDate() + 1);
        let dateDataCopy = {
          ...dateDataa,
          date: moment(nextDate).format(dateFormat),
          createdOn: moment(createdOn).format(dateFormat),
          endsAt: moment(nextDate).format(dateFormat),
          requiredday: requiredday || 2,
          teammember: null,
          teamnotes: null,
          repeatAppt: false,
          msg: `${requiredDays || 2
          } days | Doesn't repeat`,
          service: addService,
        };
        oldStateData[step] = dateDataCopy;
      }
      for (let step = 0; step < nostep; step++) {
        oldStateData[step].msg = `${requiredDays || 2
        } days | Doesn't repeat`;
      }
      setNewAppointment(oldStateData);
      calculateMutiCost(oldStateData, index);
    }

    let msgIntervalType = 'days';
    if (interval_multiplier) {
      let newDate = new Date(
        occurenceMsg === 'On a Specific Date' ? specificDate : date,
      );
      newDate.setHours(12, 0, 0, 0);
      let interval = interval_multiplier;
      if (interval_type === 'weekly') {
        if (interval_multiplier > 1) {
          interval *= 7;
          msgIntervalType = 'weeks';
        } else {
          interval *= 7;
          msgIntervalType = 'week';
        }
        // interval *= 7;
        // msgIntervalType = 'weeks';
      } else if (interval_type === 'monthly') {
        // newDate.setDate(newDate.getDate() + daysToAdd);
        newDate = new Date(
          moment(newDate, 'DD-MM-YYYY')
            .add((occurence - 1) * parseInt(interval, 10), 'months')
            .format('L'),
        );

        msgIntervalType = 'months';
      }
      if (endType === 'ongoing') {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() - 1);
      } else if (
        occurenceMsg !== 'On a Specific Date'
        && interval_type !== 'monthly'
      ) {
        const daysToAdd = (occurence - 1) * interval;
        // console.log('daysToAdd', daysToAdd, occurence, interval);
        newDate.setDate(newDate.getDate() + daysToAdd);
        // console.log('date....', newDate, 'old date', date);
      }
      // console.log('newDat.....e', newDate);
      const msg = endType === 'ongoing'
        ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ongoing`
        : occurenceMsg !== 'On a Specific Date'
          ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends after ${occurence} times on ${moment(
            newDate,
          ).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`
          : `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends on ${moment(
            newDate,
          ).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`;
      let dateDataCopy = {
        ...dateDataa,
        endsAt: newDate,
        msg,
        occurenceMsg,
        occurence,
      };
      oldStateData[index] = dateDataCopy;
      setNewAppointment(oldStateData);
    }
  };

  const setMultiTeamOneday = (
    index,
    teammember,
    oldStateData,
    obj,
    startDate,
    createdOn,
    dateDataa,
  ) => {
    const {
      date,
      occurence,
      interval_multiplier,
      interval_type,
      endType,
      occurenceMsg,
      specificDate,
      requiredday,
      endsAt,
      appointmenttype,
    } = dateDataa;
    let addService = [];
    let nostep = parseInt(team, 10) || 2;
    let { length } = newAppointment;
    // console.log('index........', index, length, nostep);
    if (length === nostep) {
      for (let step = 0; step < nostep; step++) {
        oldStateData[step].date = moment(startDate).format(dateFormat);
        oldStateData[step].endsAt = moment(startDate).format(dateFormat);
      }
    } else if (length > nostep) {
      oldStateData.length = nostep;
      setNewAppointment(oldStateData);
      calculateMutiCost(oldStateData, null);
      setappointmentIndex(0);
    } else {
      for (let step = length; step < nostep; step++) {
        let dateDataCopy = {
          ...obj,
          date: moment(startDate).format(dateFormat),
          createdOn: moment(createdOn).format(dateFormat),
          endsAt: moment(startDate).format(dateFormat),
          teamnotes: null,
          teammember: teammember || 2,
          requiredday: null,
          repeatAppt: false,
          msg: 'Additional Team Member | Day Visit',
          service: addService,
        };
        oldStateData[step] = dateDataCopy;
      }
      for (let step = 0; step < nostep; step++) {
        oldStateData[step].date = moment(startDate).format(dateFormat);
        oldStateData[step].endsAt = moment(startDate).format(dateFormat);
      }
    }
    setNewAppointment(oldStateData);
    calculateMutiCost(oldStateData, appointmentIndex);
    let msgIntervalType = 'days';
    if (interval_multiplier) {
      let newDate = new Date(
        occurenceMsg === 'On a Specific Date' ? specificDate : date,
      );
      newDate.setHours(12, 0, 0, 0);
      let interval = interval_multiplier;
      if (interval_type === 'weekly') {
        if (interval_multiplier > 1) {
          interval *= 7;
          msgIntervalType = 'weeks';
        } else {
          interval *= 7;
          msgIntervalType = 'week';
        }
      } else if (interval_type === 'monthly') {
        // newDate.setDate(newDate.getDate() + daysToAdd);
        newDate = new Date(
          moment(newDate, 'DD-MM-YYYY')
            .add((occurence - 1) * parseInt(interval, 10), 'months')
            .format('L'),
        );

        msgIntervalType = 'months';
      }
      if (endType === 'ongoing') {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() - 1);
      } else if (
        occurenceMsg !== 'On a Specific Date'
        && interval_type !== 'monthly'
      ) {
        const daysToAdd = (occurence - 1) * interval;
        // console.log('daysToAdd', daysToAdd, occurence, interval);
        newDate.setDate(newDate.getDate() + daysToAdd);
        // console.log('date....', newDate, 'old date', date);
      }
      // console.log('newDat.....e', newDate);
      const msg = endType === 'ongoing'
        ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ongoing`
        : occurenceMsg !== 'On a Specific Date'
          ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends after ${occurence} times on ${moment(
            newDate,
          ).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`
          : `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends on ${moment(
            newDate,
          ).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`;
      let dateDataCopy = {
        ...dateDataa,
        endsAt: newDate,
        msg,
        occurenceMsg,
        occurence,
      };
      oldStateData[index] = dateDataCopy;
      setNewAppointment(oldStateData);
    }
  };

  const setMultiTeamMultiday = (
    index,
    teammember,
    requiredday,
    oldStateData,
    obj,
    startDate,
    createdOn,
    dateDataa,
  ) => {
    const {
      date,
      occurence,
      interval_multiplier,
      interval_type,
      endType,
      occurenceMsg,
      specificDate,
      endsAt,
      appointmenttype,
    } = dateDataa;
    let addService = [];
    let nextDate = startDate;
    let flag = 1;
    const t = parseInt(team, 10);
    const rd = parseInt(requiredDays, 10);
    const len = t * rd;
    const { length } = oldStateData;
   
    if (len === length) {
      const indexA = [];
      const indexDate = oldStateData[index].date;
      for (var j = 0; j < oldStateData.length; j++) {
        if (
          new Date(indexDate).toLocaleString()
          === new Date(oldStateData[j].date).toLocaleString()
        ) { indexA.push(j); }
      }
      for (let i = 0; i < indexA.length; i++) {
        oldStateData[indexA[i]].date = moment(startDate).format(dateFormat);
        oldStateData[indexA[i]].endsAt = moment(startDate).format(dateFormat);
      }
    } else if (length === 1) {
      for (let i = length; i < len; i++) {
        if (i !== 0) {
          if (i % t === 0) {
            nextDate.setDate(nextDate.getDate() + 1);
            flag++;
          }
        }
        let dateDataCopy = {
          ...obj,
          date: moment(nextDate).format(dateFormat),
          createdOn: moment(createdOn).format(dateFormat),
          endsAt: moment(nextDate).format(dateFormat),
          teamnotes: null,
          teammember: teammember || 2,
          requiredday: requiredday || 2,
          repeatAppt: false,
          msg: 'Additional Team Member | MultiVisit',
          service: addService,
        };
        oldStateData[i] = dateDataCopy;
      }
    } else if (length < len) {
      if (rd + length === len) {
        // console.log('increase members');
        const mergeTeamMember = mergeTeamMembers(
          nextDate,
          oldStateData,
          createdOn,
          obj,
          rd,
        );
        oldStateData = mergeTeamMember;
      } else {
        // console.log('increase required day');
        nextDate = new Date(oldStateData[oldStateData.length - 1].date);
        for (let i = length; i < len; i++) {
          if (i !== 0) {
            if (i % t === 0) {
              nextDate.setDate(nextDate.getDate() + 1);
              flag++;
            }
          }
          let dateDataCopy = {
            ...obj,
            date: moment(nextDate).format(dateFormat),
            createdOn: moment(createdOn).format(dateFormat),
            endsAt: moment(nextDate).format(dateFormat),
            teamnotes: null,
            teammember: teammember || 2,
            requiredday: requiredday || 2,
            repeatAppt: false,
            msg: 'Additional Team Member | MultiVisit',
            service: addService,
          };
          oldStateData[i] = dateDataCopy;
        }
      }
    }
    setNewAppointment(oldStateData);
    calculateMutiCost(oldStateData, appointmentIndex);
    let msgIntervalType = 'days';
    if (interval_multiplier) {
      let newDate = new Date(
        occurenceMsg === 'On a Specific Date' ? specificDate : date,
      );
      newDate.setHours(12, 0, 0, 0);
      let interval = interval_multiplier;
      if (interval_type === 'weekly') {
        interval *= 7;
        msgIntervalType = 'weeks';
      } else if (interval_type === 'monthly') {
        // newDate.setDate(newDate.getDate() + daysToAdd);
        newDate = new Date(
          moment(newDate, 'DD-MM-YYYY')
            .add((occurence - 1) * parseInt(interval, 10), 'months')
            .format('L'),
        );

        msgIntervalType = 'months';
      }
      if (endType === 'ongoing') {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() - 1);
      } else if (
        occurenceMsg !== 'On a Specific Date'
        && interval_type !== 'monthly'
      ) {
        const daysToAdd = (occurence - 1) * interval;
        // console.log('daysToAdd', daysToAdd, occurence, interval);
        newDate.setDate(newDate.getDate() + daysToAdd);
        // console.log('date....', newDate, 'old date', date);
      }
      // console.log('newDat.....e', newDate);
      const msg = endType === 'ongoing'
        ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ongoing`
        : occurenceMsg !== 'On a Specific Date'
          ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends after ${occurence} times on ${moment(
            newDate,
          ).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`
          : `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends on ${moment(
            newDate,
          ).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`;
      let dateDataCopy = {
        ...dateDataa,
        endsAt: newDate,
        msg,
        occurenceMsg,
        occurence,
      };
      oldStateData[index] = dateDataCopy;
      setNewAppointment(oldStateData);
    }
  };

  const mergeTeamMembers = (nextDate, oldStateData, createdOn, obj, rd) => {
    const arr = [];
    let mergeArray = [];
    for (let f = 0; f < rd; f++) {
      nextDate.setDate(nextDate.getDate() + f);
      let dateDataCopy = {
        ...obj,
        date: moment(nextDate).format(dateFormat),
        createdOn: moment(createdOn).format(dateFormat),
        endsAt: moment(nextDate).format(dateFormat),
        teamnotes: null,
        teammember: team || 2,
        requiredday: rd || 2,
        repeatAppt: false,
        msg: 'Additional Team Member | MultiVisit',
        service: [],
      };
      arr.push(dateDataCopy);
    }
    const groupDate = (key) => (result, current) => {
      const item = { ...current };
      if (typeof result[current[key]] === 'undefined') {
        result[current[key]] = [item];
      } else {
        result[current[key]].push(item);
      }
      return result;
    };
    const timespan = oldStateData.reduce(groupDate('date'), {});
    Object.entries(timespan).forEach((item) => {
      const findDate = arr.find(
        (el) => new Date(el.date).toLocaleString()
          === new Date(item[0]).toLocaleString(),
      );
      item[1].push(findDate);
      mergeArray = [...mergeArray, item[1]];
    });
    var merged = [].concat([], ...mergeArray);
    return merged;
  };

  const handleAddClient = (client) => {
    let full_Name = `${client.firstname} ${client.lastname}`;

    setBookingData((prev) => ({ ...prev, staffId:client._id, staffName:full_Name }));

    setAddedClients(client);

    let oldAppointment_ = JSON.parse(JSON.stringify(newAppointment));

    if (oldAppointment_.length > 0) {
      if (oldAppointment_[0].service && oldAppointment_[0].service.length > 0) {
        let serivces_ = oldAppointment_[0].service;
        let newServices_ = serivces_.map((ele) => {
          ele.staffId = client._id;
          ele.staffName = `${client.firstname} ${client.lastname}`;
          return ele;
        });
        oldAppointment_[0].service = newServices_;
        setNewAppointment(oldAppointment_);
      }
    }
    handleShowSearch();
  };
  const handleBookingData = (e) => {
    const { name, value } = e.target;
    const obj = { ...bookingData, [name]: value };
    if (name === 'staffName') {
      const staffId = staffList[e.target.selectedIndex]._id;
      obj[name] = value;
      obj.staffId = staffId;
      setStaff(value, staffId, appointmentType);
    }
    if (name === 'cost' && !bookingData.customCost) {
      const newval = addZero(value);
      obj[name] = newval;
    } else if (name === 'startTime') {
      obj.timeChange = true;
    } else if (name === 'duration') {
      obj.durationChange = true;
    } else if (name === 'quantity') {
      obj.quantityChange = true;
    } else if (name === 'pricingType') {
      obj.duration = bookingData.previousDuration;
      obj.quantity = '5';
      if (bookingData.customCost !== 'default') {
        obj.cost = '';
      }
    } else if (name === 'soldPer' && value !== 'Add Custom Measure') {
      obj.unit = '';
      obj.symbol = '';
    }
    
    setBookingData(obj);
  };

  const addSubcategoryToService = (value) => {
    console.log('okkkkk heloo', value);

    if (value.toLowerCase().includes('misc')) {
      setMisc(true);
    }
    setBookingData({
      ...bookingData,
      category: value,
      subcategory: value,
    });
    setSearchTxt(value);
  };
  const getCategories = async () => {
    try {
      const response = await userInstance().get('/service/getServices');
      const { services } = response.data;
      setCategories(services);
      const staffData = await getStaffList();
      const objToUpdate = {
        ...bookingData,
      };
      let { filterBlockDate } = staffData || {};
      if (filterBlockDate) {
        let {
          duration,
          interval_multiplier,
          interval_type,
          occurence,
          occurenceMsg,
          startdate,
          startTime,
          blockname,
          _id,
        } = filterBlockDate || {};
        setDbTimeZone(filterBlockDate?.timeZone);
        if (blocktypeCheck) {
          setBlocktypeCheck(false);
          setBlcoktimeName(blockname);
          setBlcokId(_id);
          if (occurence || occurenceMsg === 'Ongoing') {
            console.log('check true');
            setRepeatAppt(true);
          }
          objToUpdate.startTime = startTime;
          objToUpdate.date = startdate;
          if (interval_type) {
            objToUpdate.interval_multiplier = interval_multiplier;
            objToUpdate.interval_type = interval_type;
            objToUpdate.occurence = occurence;
            objToUpdate.occurenceMsg = occurenceMsg;
            setDatePopupData({
              ...datePopupData,
              interval_multiplier,
              interval_type,
            });
          }
          const initialCopy = { ...initialDateData };
          setDateData({
            ...initialCopy,
            interval_multiplier,
            interval_type,
            occurence,
            occurenceMsg,
            startdate,
          });
        }
        objToUpdate.duration = duration;
      }
      if (staffData) {
        objToUpdate.staffName = staffData.staffName;
        objToUpdate.staffId = staffData.staffId;
      }
      setBookingData(objToUpdate);
    } catch (error) {
      //   console.log(error);
    }
  };

  const getTimes = () => g();

  const g = () => {
    const x = {
      slotInterval: 5,
      openTime: '12:25 AM',
      closeTime: '23:30 PM',
    };

    // Format the time
    const startTime = moment(x.openTime, 'h:mm A');

    // Format the end time and the next day to it
    const endTime = moment(x.closeTime, 'h:mm A');

    // Times
    const allTimes = [];

    // Loop over the times - only pushes time with 30 minutes interval
    while (startTime <= endTime) {
      // Push times
      allTimes.push(startTime.format('h:mm A'));
      // Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    return allTimes;
  };

  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msg = hours && minutes
        ? `${hours}hr ${minutes} min`
        : hours && !minutes
          ? `${hours}hr`
          : `${minutes} min`;
      arr.push({ value: val, data: msg });
      val += 5;
    }
    return arr;
  };

  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };

  const checkstafftype = (info) => {
    const groupStaff = (key) => (result, current) => {
      const item = { ...current };
      if (typeof result[current[key]] === 'undefined') {
        result[current[key]] = [item];
      } else {
        result[current[key]].push(item);
      }
      return result;
    };
    const timespan = info.reduce(groupStaff('staffId'), {});
    return timespan;
  };

  const updateDateTimeZone = (obj, tz) => {
    if (tz !== obj?.timeZone) {
      obj.startdate = getTimeZone2(tz, obj.startdate);
      obj.enddate = getTimeZone2(tz, obj.enddate);
      obj.startTime = displayTime(tz, obj.startdate);
    }
    return obj;
  };

  const getStaffList = async () => {
    try {
      let filterBlockDate = '';
      const response = await userInstance().get('/staff/getStaff');
      const { staff } = response.data;
      setStaffList(staff);
      if (block_id) {
        const result = await userInstance().get(
          `/staff/getStaffBlockTime?id=${block_id}&staffId=${employee_id}`,
        );
        const { blocktimeData } = result.data || {};
        filterBlockDate = updateDateTimeZone(blocktimeData, timeZone);
        
        // const {
        //   blockname, date, duration, endType, interval_multiplier, interval_type, occurence, occurenceMsg, startTime,
        // } = blocktimeData || {};
      }
      let filterStaff = staff.filter((el) => el._id === employee_id);
      // let blocktimedata = filterStaff ? filterStaff[0].blocktime : {};
      // if (blocktimedata) {
      //   filterBlockDate = blocktimedata.filter((el) => el.repeates.filter((sub) => new Date(sub.startblocktime).toLocaleString() === new Date(startDateTime).toLocaleString()).length);
      // }
      return {
        staffName: `${filterStaff[0].firstname} ${filterStaff[0].lastname}`,
        staffId: filterStaff[0]._id,
        filterBlockDate,
      };
    } catch (error) {
      //   console.log(error);
      return null;
    }
  };

  const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad(`0${str}`, max) : str;
  };

  const convertHours = (mins) => {
    const hour = Math.floor(mins / 60);
    var mins = mins % 60;
    const converted = `${pad(hour, 2)}:${pad(mins, 2)}`;
    return converted;
  };

  const calculate_time_slot = (start_time, end_time, interval = '30') => {
    var i;
    let formatted_time;
    const time_slots = new Array();
    for (var i = start_time; i <= end_time; i += interval) {
      formatted_time = convertHours(i);
      time_slots.push(formatted_time);
    }
    return time_slots;
  };

  const getHourWithQuantity = (duration, multiplier) => {
    // console.log('multiplier', multiplier);
    let hourPart = duration.includes('hr')
      ? parseFloat(duration.split('hr')[0].trim())
      : 0;
    let minutePart = duration.includes('hr') && duration.includes('min')
      ? parseFloat(duration.split('hr')[1].split('min')[0])
      : duration.includes('min')
        ? parseFloat(duration.split('min')[0])
        : 0;

    let hourWithQuantity = hourPart * multiplier;
    // console.log('hourWithQuantity', hourWithQuantity);
    return { hourWithQuantity, hourPart, minutePart };
  };

  const calculateServiceLabour = (bookingDataCopy) => {
    // console.log('service ');
    const convertedDuration = bookingDataCopy.pricingType === 'hourly'
      ? bookingDataCopy.quantity
      : bookingDataCopy.duration;

    let hour = convertToReadableDuration(
      addQuantityDuration(convertedDuration, parseFloat('1')),
    );
    let { hourWithQuantity, minutePart } = getHourWithQuantity(
      hour,
      bookingDataCopy.cost,
    );

    minutePart = (minutePart / 60) * parseFloat(bookingDataCopy.cost);
    let finalDuration = parseFloat(hourWithQuantity + minutePart);

    // eslint-disable-next-line no-mixed-operators
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;
    // console.log('finalDuration-', finalDuration);

    return parseFloat(finalDuration).toFixed(2);
  };

  const addQuantityDuration = (duration, quantity) => {
    const updatedDuration = convertToReadableDuration(duration.toString());
    // console.log('updatedDuration', updatedDuration);
    let { hourWithQuantity, hourPart, minutePart } = getHourWithQuantity(
      updatedDuration,
      quantity,
    );

    let multipleMinute = parseFloat(
      `0.${hourWithQuantity.toString().split('.')[1]}`,
    );

    const updatedMinute = hourPart ? multipleMinute * (60 / hourPart) : 0;
    minutePart *= quantity;

    let finalDuration = parseFloat(hourWithQuantity.toString().split('.')[0]) * 60
      + updatedMinute
      + minutePart;
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return finalDuration.toString();
  };

  const calculateMarginLabour = (bookingDataCopy) => {
    // console.log('booking data copy', bookingDataCopy);
    // console.log('lenght', bookingData
    // .length);
    const {
      cost, buyCostProduct, quantity, type, renderCost,
    } = bookingDataCopy;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    let totalLabour = 0;
    // console.log('type', type);

    if (type === 'product') {
      totalBuy += parseFloat(buyCostProduct);
      totalSellPrice += parseFloat(renderCost);
      const totalLabourProduct = (
        totalSellPrice
        - totalBuy * parseFloat(quantity)
      ).toFixed(2);
      laborMargin = totalLabourProduct.toString();
    }
    if (type === 'service') {
      // console.log('service con', bookingDataCopy.pricingType);
      totalLabour = bookingDataCopy.pricingType === 'hourly'
        ? calculateServiceLabour(bookingDataCopy)
        : cost;
    }
    return { totalLabour, laborMargin };
  };

  const renderCostCalculation = (data) => {
    let quot = data / 5;

    let str = quot.toString();
    let splitData = str.split('.');

    let value;
    if (splitData.length > 1 && splitData[1] !== '' && splitData[1] !== '0') {
      value = parseFloat(splitData[0]) + 1;
    } else {
      value = quot;
    }
    let result = value * 5;

    return result;
  };

  const validateService = () => {
    let isValid = true;
    let errValue = '';
    let msg = '';
    const {
      category, subcategory, staffName, type, customMisc, quantity,
    } = bookingData;
    let { cost, buyCostProduct } = bookingData;
    cost = cost.replace(/,/g, '').replace('$', '');
    buyCostProduct = buyCostProduct.replace(/,/g, '').replace('$', '');

    if (!category) {
      isValid = false;
      errValue = 'category';
      msg = 'Please select service or product';
    } else if (!subcategory) {
      isValid = false;
      errValue = 'subcategory';
      msg = 'Please select subcategory';
    } else if (
      bookingData.category.toLowerCase().includes('misc')
      && !customMisc
    ) {
      isValid = false;
      errValue = 'customMisc';
      msg = 'Enter New Name';
    } else if (!quantity) {
      isValid = false;
      errValue = 'quantity';
      msg = 'Enter quantity';
    }
    return { isValid, msg, errValue };
  };
  function groupBy(collection, property) {
    let i = 0;
    let val;
    let index;
    let values = [];
    let result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  const handleAddService = async () => {
    const {
      subcategory,
      type,
      duration,
      previousDuration,
      previousQuantity,
      pricingType,
      customMisc,
      description,
    } = bookingData;
    let {
      cost, buyCostProduct, renderCost, quantity,
    } = bookingData;
    let oldStateCopy = [...newAppointment];
    quantity = quantity.replace(/,/g, '');
    cost = parseFloat(cost.replace(/,/g, '').replace('$', '')).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, '').replace('$', ''),
    ).toFixed(2);

    renderCost = type === 'service' && pricingType === 'hourly'
      ? (parseFloat(cost) * parseFloat(quantity)) / 60
      : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    // const output = renderCostCalculation(15.00);
    if (type === 'product') {
      console.log('ok1');
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === 'service' || pricingType === 'hourly') {
      console.log('ok2');
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }
    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      let exData = oldStateCopy[appointmentIndex];
      let exServices = oldStateCopy[appointmentIndex].service;
      let startTime = setDefaultTime; // changes by sanjana '9:30';
      let start = convertDateTime(exData.date);
      let time = bookingData.startTime.split(':');
      const bookingDataCopy = { ...bookingData };
      const lastService = exServices[exServices.length - 1];
      // console.log('lastService', lastService);
      if (lastService && newAppointment[appointmentIndex].service.length) {
        start = convertDateTime(lastService.end);
        time = start.format(timeFromDateFormat).split(':');
      }
      console.log('time', time);
      start.hour(time[0]);
      start.minute(time[1]);
      start.second(0);

      bookingDataCopy.cost = cost;
      bookingDataCopy.quantity = quantity;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.description = customMisc || description;

      if (bookingDataCopy.buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      const { totalLabour, laborMargin } = calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;
      let durationInMin = duration;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 || pricingType === 'hourly'
          ? quantity
          : duration;
      }
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      bookingDataCopy.duration = durationInMin;

      bookingDataCopy.start = start.format(dateFormat);
      startTime = start.format(timeFromDateFormat);

      bookingDataCopy.end = updateEndTime(start, bookingData.duration);

      // bookingDataCopy.end = '2024-05-10T14:05:00+10:00';

      bookingDataCopy.startTime = startTime;
      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00'
          ? addZero(renderCost)
          : addZero(renderCost),
      );

      bookingDataCopy.timeChange = false;
      if (!previousDuration) {
        // console.log('setting duration');
        bookingDataCopy.previousDuration = durationInMin;
      }
      if (!previousQuantity) {
        // console.log('setting quantity');
        bookingDataCopy.previousQuantity = quantity;
      }
     
      if (!editData.edit) {
        finalServices = [...addedServices, bookingDataCopy];
      }
      let obj = groupBy(finalServices, 'staffName');
      obj.forEach((el, i) => {
        const total = el.reduce(
          (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.renderCost ? next.renderCost : 0),
          0,
        );
        const totalduration = el.reduce(
          (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.duration ? next.duration : 0),
          0,
        );
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      let oldServices = [...newAppointment[appointmentIndex].service];
      oldServices.push(bookingDataCopy);
      oldStateCopy[appointmentIndex].service = oldServices;
      setNewAppointment(oldStateCopy);
      calculateMutiCost(oldStateCopy, appointmentIndex);
      handleShowService(appointmentIndex);
      setServices([...finalServices]);
      calculateSummary([...finalServices]);
      setMultiServices(obj);
      if (
        appointmentType === 'multiteam_oneday'
        || appointmentType === 'multiteam_multiday'
      ) {
        const findArray = mutiStaff.find((el) => el.index === appointmentIndex);
        if (!findArray) {
          mutiStaff.push({
            staffId: bookingDataCopy.staffId,
            index: appointmentIndex,
            staffName: bookingDataCopy.staffName,
            date: oldStateCopy[appointmentIndex].date,
          });
          setMutiStaff(mutiStaff);
        }
      }
    } else {
      setErrorValue(errValue);
      toastMessage('error', msg);
    }
    setTimeout(() => { setErrorValue(''); }, 1000);
  };

  const editService = () => {
    const {
      start,
      startTime,
      subcategory,
      type,
      previousDuration,
      previousQuantity,
      duration,
      pricingType,
    } = bookingData;
    let {
      cost, buyCostProduct, renderCost, quantity,
    } = bookingData;
    // console.log('booking data for edit', bookingData);
    // console.log('buyCostProduct', buyCostProduct.replace(/,/g, '').replace('$', ''));

    quantity = quantity.replace(/,/g, '');
    cost = parseFloat(cost.replace(/,/g, '').replace('$', '')).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, '').replace('$', ''),
    ).toFixed(2);

    renderCost = type === 'service' && pricingType === 'hourly'
      ? (parseFloat(cost) * parseFloat(quantity)) / 60
      : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === 'product') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === 'service' || pricingType === 'hourly') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }

    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      const bookingDataCopy = { ...bookingData };

      const startDate = convertDateTime(start);
      const time = startTime.split(':');

      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00'
          ? addZero(renderCost)
          : addZero(renderCost),
      );
      bookingDataCopy.cost = cost;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.quantity = quantity;

      if (buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }

      startDate.hour(time[0]);
      startDate.minute(time[1]);
      startDate.second(0);

      bookingDataCopy.start = startDate.format(dateFormat);

      const { totalLabour, laborMargin } = calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;

      let durationInMin = 0;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 || pricingType === 'hourly'
          ? quantity
          : duration;
        bookingDataCopy.duration = durationInMin;
      } else if (
        previousQuantity !== quantity
        || previousDuration !== duration
      ) {
        bookingDataCopy.previousDuration = durationInMin;
        bookingDataCopy.previousQuantity = bookingDataCopy.quantity;
      }

      bookingDataCopy.end = updateEndTime(startDate, bookingData.duration);

      const currentServices = [...addedServices];
      currentServices[editData.index] = bookingDataCopy;
      finalServices = currentServices;
      let obj = groupBy(finalServices, 'staffName');
      obj.forEach((el, i) => {
        const total = el.reduce(
          (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.renderCost ? next.renderCost : 0),
          0,
        );
        const totalduration = el.reduce(
          (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.duration ? next.duration : 0),
          0,
        );
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      calculateNewDurations(bookingDataCopy);
      bookingDataCopy.timeChange = false;
      setServices([...finalServices]);
      calculateSummary([...finalServices]);
      setMultiServices(obj);
      setShowService(!showService);
    } else {
      setErrorValue(errValue);
      toastMessage('error', msg);
    }
    setTimeout(() => { setErrorValue(''); }, 1000);
  };

  const calculateDurations = (services) => {
    services.forEach((el) => {
      let start = moment(el.date).toDate();
      const time = el.startTime.split(':');
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);

      el.start = moment(start).format(dateFormat);
      el.end = moment(
        new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
      ).format(dateFormat);
    });
    // setServices([...services]);
  };

  const calculateSummary = (finalServices) => {
    let totalDuration = 0;
    let totalCost = 0;
    let totalCostWithoutProduct = 0;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    finalServices.forEach((el) => {
      totalDuration += parseFloat(el.duration);
      totalCost += parseFloat(el.renderCost);
      // totalCostWithoutProduct += parseFloat(el.cost);

      // totalBuy += parseFloat(el.buyCostProduct);
      // totalSellPrice += parseFloat(el.cost);
      laborMargin += parseFloat(el.laborMargin) + parseFloat(el.totalLabour);
    });
    const totalLabour = laborMargin;
    setSummary({
      ...summary,
      totalCost: addZero(parseFloat(totalCost).toFixed(2)),
      totalDuration,
      totalLabour,
      multiTotal: totalCost,
    });
  };

  const handleEditService = ({
    service, index, subIndex, appIndex,
  }) => {
    if (appointmentType === 'multiteam_oneday') {
      let leftStaffs = [];
      const findIndex = mutiStaff.filter((el) => el.index !== appIndex);
      if (findIndex.length > 0) {
        findIndex.forEach((el) => {
          leftStaffs.push(el.staffId);
        });
        const filterProList = filterArray(staffList, leftStaffs);
        setStaffList(filterProList);
      } else {
        getStaffList();
      }
    }
    setStaffIndex(index);
    setappointmentIndex(appIndex);
    if (index === 0) {
      setnewlength(0);
    } else {
      setnewlength(addedServices.length);
    }
    const updateObj = { ...bookingData, ...service };
    setBookingData(updateObj);
    setShowService(!showService);
    setEditData({ edit: true, index });
    if (parseFloat(service.duration) > 960) {
      setExtras({
        ...extras,
        value: service.duration,
        data: convertToReadableDuration(service.duration),
      });
    }
    const subcategory = service.subcategory.replace(
      service.subcategory.split('$')[1],
      service.renderCost.split('.')[1] === '00'
        ? addZero(service.renderCost)
        : addZero(service.cost),
    );
    setSearchTxt(subcategory);
  };

  const handleDeleteService = () => {
    const oldState = [...newAppointment];
    if (
      appointmentType === 'oneteam_multiday'
      || appointmentType === 'oneteam_oneday'
    ) {
      const currentServices = oldState[appointmentIndex].service;
      currentServices.splice(editData.index, 1);
      oldState[appointmentIndex].service = currentServices;
      calculateMutiCost(oldState, appointmentIndex);
      setNewAppointment(oldState);
    } else if (
      appointmentType === 'multiteam_oneday'
      || appointmentType === 'multiteam_multiday'
    ) {
      const currentServices = oldState[appointmentIndex].service;
      currentServices.splice(editData.index, 1);
      oldState[appointmentIndex].service = currentServices;
      calculateMutiCost(oldState, appointmentIndex);
      setNewAppointment(oldState);
      const oldMulti = [...mutiStaff];
      if (currentServices.length === 0) {
        const findIndex = oldMulti.findIndex(
          (el) => el.index === appointmentIndex,
        );
        oldMulti.splice(findIndex, 1);
        setMutiStaff(oldMulti);
      }
    }
    setShowService(!showService);
  };

  const handleSearchCategory = async (searchTxt) => {
    try {
      setSearchTxt(searchTxt);
      if (searchTxt) {
        const response = await userInstance().get(
          `/service/searchCategory?value=${searchTxt.replace('$', '').trim()}&industryId=${industryId._id}`,
        );
        const { services } = response.data;
        setCategories(services);
        if (services.length) {
          const subCat = [];
          services.forEach((service) => {
            const reg = new RegExp(`${searchTxt.replace('$', '').trim()}`, 'i');
            const matchedSubcategories = service.subCategory.filter(
              (el) => reg.test(el.cost)
                || reg.test(el.name)
                || reg.test(el.retailCost)
                || reg.test(el.serviceDescription),
            );
            subCat.push(...matchedSubcategories);
          });
          if (subCat.length) {
            setSubcategories([...subCat]);
          } else {
            setSubcategories([]);
          }
        } else {
          setSubcategories([]);
        }
      }
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const duplicateElements = (data) => {
    const arr = [];
    let count = 1;
    let j = 0;
    for (let i = 0; i < data.length; i++) {
      const findArr = arr.find((el) => el.date === data[i].date);
      if (!findArr) {
        arr.push({ date: data[i].date, count });
        j++;
      } else {
        arr[j - 1].count = arr[j - 1].count + 1;
      }
    }
    const same = (array) => {
      var first = array[0].count;
      return array.every((element) => element.count === first);
    };
    const check = same(arr);
    return check;
  };

  const duplicateElementsTwo = (data) => {
    const arr = [];
    let count = 1;
    let j = 0;
    for (let i = 0; i < data.length; i++) {
      const findArr = arr.find((el) => el.date === data[i].date);
      if (!findArr) {
        arr.push({ date: data[i].date, count });
        j++;
      } else {
        arr[j - 1].count = arr[j - 1].count + 1;
      }
    }
    const same = (array) => {
      var first = new Date(array[0].date).toLocaleDateString();
      return array.every(
        (element) => new Date(element.date).toLocaleDateString() === first,
      );
    };
    const check = same(arr);
    return check;
  };
  const checkMultiDateBox = (arrr) => {
    const duplicates = arrr.reduce((acc, el, i, arr) => {
      if (arr.indexOf(el.date) !== i && acc.indexOf(el.date) < 0) { acc.push(el.date); }
      return acc;
    }, []);
    const data = duplicates;
    return data;
  };
  const handleEditAppointment = async (payloadAppData, upTimes) => {
    const response = await userInstance().put(
      '/appointment/updateExistAppointment',
      payloadAppData,
    );
    const { code } = response.data;
    if (code === 200) {
      history.push(`${getCalendarPath2(upTimes)}`);
      toastMessage('success', 'New Scheduled Maintenance Added Successfully.');
    }
  };

  const handleAddAppointment = async () => {
    try {
      const chemicalalist = JSON.parse(localStorage.getItem('chemicalalist'));

      // console.log('chemicalalist==>', chemicalalist);

      let validateServices;
      let isBoxValid = true;
      let valMessage = '';
      let isValid = true;
      let val = '';

      const old = JSON.parse(JSON.stringify(newAppointment));
      // console.log('old====>', old);
      let firstServiceTime;

      if (old[0]?.service.length > 0) {
        firstServiceTime = udpateTime(
          old[0].date,
          old[0]?.service[0]?.startTime,
        );
      } else {
        firstServiceTime = udpateTime(
          old[0].date,
          (old[0]?.chemicalTestArray[0]?.startTime || '9:30'),
        );
      }

      let evryLength = old.every((el) => el.service.length > 0);
      validateServices = evryLength;
      if (old.length === 1) {
        isBoxValid = false;
        if (appointmentType === 'oneteam_multiday') {
          valMessage = 'Atleast 2 days are required';
        } else if (appointmentType === 'multiteam_oneday') {
          valMessage = 'Atleast 2 members are required';
        } else if (appointmentType === 'multiteam_multiday') {
          valMessage = 'Atleast 2 members and 2 days are required';
        } else {
          isBoxValid = true;
        }
      } else if (old?.length > 1 && appointmentType === 'multiteam_multiday') {
        if (checkMultiDateBox(old).length === 1) {
          valMessage = 'Atleast 2 days are required';
          isBoxValid = false;
        } else if (!duplicateElements(old)) {
          valMessage = 'Same Members are required';
          isBoxValid = false;
        } else {
          isBoxValid = true;
        }
      } else if (old?.length > 1 && appointmentType === 'oneteam_multiday') {
        if (duplicateElementsTwo(old)) {
          valMessage = 'Same Dates not allowed in oneteam multivisit';
          isBoxValid = false;
        } else {
          isBoxValid = true;
        }
      } else {
        isBoxValid = true;
        valMessage = '';
      }

      if (!validateServices) {
        isValid = false;
        val = 'maintenance required';
      }
      if (isValid) {
        if (isBoxValid) {
          setChecksubmit(true);
          let data_array = [];
          let {
            date, createdOn, endsAt, requiredday, appointmenttype,
          } = dateData || {};

          if (addedClients) {
            let {
              firstname,
              lastname,
              streetAddress,
              suburb,
              _id,
              siteAddresses,
              mobileNo,
              note,
            } = addedClients || {};
            let payloadData = [];
            let waterStatus = false;
            if (waterTest === 'done') {
              waterStatus = true;
            }
            newAppointment.forEach((el, index) => {
              let chemicalTime = 0;
              if (
                chemicalalist
              && chemicalalist.length > 0
              && waterTest === 'done'
              ) {
                const partvisitTotal = parseFloat(
                  TotalPartVist(el.chemicalTestArray),
                );
                const partvisitDuration = parseFloat(
                  TotalPartduration(el.chemicalTestArray),
                );
                const totalPartLabours = parseFloat(
                  TotalPartLabour(el.chemicalTestArray),
                );
                chemicalTime = partvisitDuration;
                // console.log('partvisitDuration==>', partvisitDuration);

                let newchamicalobj = {
                  labourMargin: totalPartLabours.toFixed(2),
                  totalCost: partvisitTotal.toFixed(2),
                  totalDuration: partvisitDuration,
                  testResult: [
                    {
                      numericValue:
                      chemicalalist[0]?.testResult[index]?.numericValue,
                      sel: chemicalalist[0]?.testResult[index]?.sel,
                      days: chemicalalist[0]?.testResult[index]?.days,
                      // service: el.chemicalTestArray,
                      service: el?.AllchemicalTestArray,
                      summary: {
                        multiTotal: '',
                        totalCost: partvisitTotal.toFixed(2),
                        totalDuration: partvisitDuration,
                        totalLabour: '',
                        totalLabourAndMargin: totalPartLabours?.toFixed(2),
                        totalMargin: '',
                        days: '',
                      },
                      value: chemicalalist[0]?.testResult[index]?.value,
                    },
                  ],
                };
                payloadData.push(newchamicalobj);
              }
              const startDat = convertDateTime(el.date).format('YYYY-MM-DD');
             
              el.date = startDat;
              const servicetotalTime = el?.service.reduce(
                (pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0),
                0,
              );
              const totalAppTime = parseInt(servicetotalTime) + parseInt(chemicalTime);
              // console.log('servicetotalTime ==>', servicetotalTime);

              const addduration = 15 - totalAppTime;
              // console.log('addduration==>', addduration);

              const editServiceIndex = el?.service?.length - 1;
              if (totalAppTime < 15) {
                const updateServ = updateSingleServiceDateTime(el.service, editServiceIndex, addduration);
                el.service = updateServ;
                el.summary.totalDuration = 15;
              }
            });
            if (appIdMerge !== '') {
              const payloadEditAppData = {
                services: addedServices,
                client: _id,
                clientSiteAddressIndex: activeAdd,
                notes,
                dateData: newAppointment,
                totalData: mutiSummary,
                appointmenttype: appointmentType,
                requiredday: requiredDays,
                teammember: team,
                quoteId: quoteId || null,
                clientName: `${firstname} ${lastname}`,
                chemicalsArray: payloadData,
                waterTestStatus: waterStatus,
                appIdMerge,
                calculater_type,
                waterId,
                industryId: industryId._id,
              };
              handleEditAppointment(payloadEditAppData, firstServiceTime);
            } else {
              const bodyData = {
                services: addedServices,
                client: _id,
                clientSiteAddressIndex: activeAdd,
                notes,
                dateData: newAppointment,
                totalData: mutiSummary,
                appointmenttype: appointmentType,
                requiredday: requiredDays,
                teammember: team,
                quoteId: quoteId || null,
                clientName: `${firstname} ${lastname}`,
                chemicalsArray: payloadData,
                waterTestStatus: waterStatus,
                calculater_type,
                waterId,
                industryId: industryId._id,
              };
              console.log('test app=>', bodyData);
              const response = await notTimeOutUserInstance().post(
                '/appointment/addAppointment',
                {
                  services: addedServices,
                  client: _id,
                  clientSiteAddressIndex: activeAdd,
                  notes,
                  dateData: newAppointment,
                  totalData: mutiSummary,
                  appointmenttype: appointmentType,
                  requiredday: requiredDays,
                  teammember: team,
                  quoteId: quoteId || null,
                  clientName: `${firstname} ${lastname}`,
                  chemicalsArray: payloadData,
                  waterTestStatus: waterStatus,
                  calculater_type,
                  waterId,
                  industryId: industryId._id,
                  timeZone,
                },
              );
              toastMessage('success', 'New Scheduled Maintenance Added Successfully.');

              history.push(`${getCalendarPath2(addedServices[0].start)}`);
              setClients([]);
              setServices([]);
              setBookingData(initialBookingData);
              setNotes('');
              setAddedClients([]);
              setSummary({ totalCost: 0, totalDuration: 0 });
              setDateData(initialDateData);
              setMultiNotes(multiNotesState);
              setmultiAppointment(false);
              setMultiServices([]);
              setActiveAdd('');
              setCalculater_type('');
            }
          } else {
            setChecksubmit(false);
            toastMessage('error', 'Please add the User');
          }
        } else {
          toastMessage('error', `${valMessage}`);
        }
      } else {
        toastMessage('error', `Please add ${val}.`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateSingleServiceDateTime = (serArra, editServiceIndex, addduration) => {
    let compyserArray = [...serArra];
    const { startTime, start, duration } = serArra[editServiceIndex] || {};
    let newduration = '15';
    let start2 = udpateTime(start, startTime, true);
    // const time = startTime.split(':');
    // start2.setHours(time[0]);
    // start2.setMinutes(time[1]);
    // start2.setSeconds(0);
    if (editServiceIndex !== 0) {
      newduration = parseInt(duration) + addduration;
    }
  
    compyserArray[editServiceIndex].start = start2.format('YYYY-MM-DDTHH:mm:ssZ');
    compyserArray[editServiceIndex].end = updateEndTime(start2, newduration);
    compyserArray[editServiceIndex].duration = newduration;
    compyserArray[editServiceIndex].previousDuration = newduration;
    return compyserArray;
  };

  const calculateNewDurations = (data) => {
    let multiArray = [];
    const oldState = [...newAppointment];
    oldState[appointmentIndex].service[editData.index] = data;
    multiArray = oldState[appointmentIndex].service;
    const remainingServices = multiArray.filter((_el, i) => i > editData.index);
    const oldServices = multiArray.filter((_el, i) => i <= editData.index);

    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = '';
      if (i === 0) {
        end = data.end;
      } else {
        end = remainingServices[i - 1].end;
      }

      const endDate = convertDateTime(end);

      const startTime = endDate.format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = endDate.format(dateFormat);
      currentEl.end = updateEndTime(endDate, currentEl.duration);
    }
    oldState[appointmentIndex].service = [...oldServices, ...remainingServices];
    calculateMutiCost(oldState, appointmentIndex);
    setNewAppointment(oldState);
  };

  const recalculateServices = (data) => {
    // const selectedEntryIndex = addedServices.findIndex((x) => x.subcategory === data.subcategory);
    // console.log('selectedEntryIndex', selectedEntryIndex);
    const remainingServices = addedServices.filter(
      (_el, i) => i > editData.index,
    );
    const previousServices = addedServices.filter(
      (_el, i) => i < editData.index,
    );
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = '';
      if (i === 0) {
        end = new Date(data.start);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(
        new Date(
          end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
        ),
      ).format(dateFormat);
    }
    const arr = previousServices;
    // if (!isFirst) {
    //   arr.push(addedServices[0]);
    // }
    const newArray = arr.concat(remainingServices);
    setServices([...newArray]);
  };
  const handleAppointmentType = (type) => {
    setmultiAppointment(false);
    setAppointmentType(type);
    const old = [...newAppointment];
    old[0].repeatAppt = false;
    old[0].occurence = null;
    old[0].frequencyMsg = null;
    old[0].interval_type = null;
    old[0].msg = "One-off appointment | Doesn't repeat";
    old[0].endType = null;
    old[0].occurenceMsg = null;
    old[0].interval_multiplier = null;
    setNewAppointment([old[0]]);
    calculateMutiCost([old[0]], 0);
    setappointmentIndex(0);
  };
  const handleChangeNotes = (name, value, index) => {
    let val = [...multiNotes];
    val[index][name] = value;
    setMultiNotes(val);
  };

  const handleChangeNotesMulti = (name, value, index) => {
    let val = [...newAppointment];
    val[index][name] = value;
    setMultiNotes(val);
  };
  const checkAddress = (index, id) => {
    setActiveAdd(index);
    let oldState = [...searchedClients];
    oldState.forEach((el) => {
      if (el._id === id) {
        el.siteAddresses?.forEach((el2, i) => {
          if (index === i) {
            el2.activeSiteAdd = true;
          } else {
            el2.activeSiteAdd = false;
          }
        });
      } else {
        el.siteAddresses.forEach((el2, i) => {
          el2.activeSiteAdd = false;
        });
      }
    });
    setClients(oldState);
    // handleAddAppointmentPopup();
  };
  const getQuoteById = async () => {
    const response = await userInstance().get(
      `/quotes/getQuotesById?quoteId=${quoteId}`,
    );
    const { quotesList, code } = response.data;
    console.log(quotesList);
    setAppointmentType(quotesList.dateData[0].appointmenttype);
    setNewAppointment(quotesList.dateData);
    setAddedClients([quotesList.client]);
    setActiveAdd(quotesList?.clientSiteAddressIndex || 0);
    setServices(quotesList.services);
    calculateMutiCost(quotesList.dateData, null);
    calculateSummary(quotesList.services);
  };
  useEffect(() => {
    getCategories();
  }, [dateData]);

  useEffect(() => {
    if (appId) {
      getAppointmentById();
    }
  }, []);
  useEffect(() => {
    if (quoteId) {
      getQuoteById();
    }
  }, []);

  const TotalPartVist = (el) => {
    let totalcost = el.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.renderCost ? next.renderCost : 0),
      0,
    );
    return Number.isNaN(totalcost) ? 0 : totalcost;
  };
  const TotalPartduration = (el) => {
    let totalDuration = el.reduce(
      (pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0),
      0,
    );
    return totalDuration;
  };
  const TotalPartLabour = (el) => {
    let totalLabourOnly = el.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.totalLabour ? next.totalLabour : 0),
      0,
    );
    let totalMargin = el.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.laborMargin ? next.laborMargin : 0),
      0,
    );
    let totalLabour = totalLabourOnly + totalMargin;
    return Number.isNaN(totalLabour) ? 0 : totalLabour;
  };

  const calculateMutiCost = (item, index) => {
    if (waterTest === 'done') {
      const lastAppServ = item[index]?.service?.length;
      const lastServiceArr = item[index]?.service[lastAppServ - 1];
      if (lastServiceArr) {
        const updatedChemicalList = updateServiceDateTime(
          item[index]?.chemicalTestArray,
          lastServiceArr,
        );
        item[index].chemicalTestArray = updatedChemicalList;
        // console.log('updateServiceDateTime=>', updatedChemicalList);
      }
    }
    const totalCost = item
      .map((el2) => el2?.service.reduce(
        (pre, next) => parseFloat(pre || 0)
          + parseFloat(next?.renderCost ? next?.renderCost : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    const totalLabourAmount = item
      .map((el2) => el2.service.reduce(
        (pre, next) => parseFloat(pre || 0)
          + parseFloat(next?.totalLabour ? next?.totalLabour : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);

    const totalMargin = item
      .map((el2) => el2.service.reduce(
        (pre, next) => parseFloat(pre || 0)
          + parseFloat(next?.laborMargin ? next?.laborMargin : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    const totalLabour = totalLabourAmount + totalMargin;
    const totalDuration = item
      .map((el2) => el2.service.reduce(
        (pre, next) => parseFloat(pre || 0)
          + parseFloat(next?.duration ? next?.duration : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);

    if (index !== null) {
      const subSummary = item[index].service;
      let obj = {
        totalCost: addZero(parseFloat(TotalPartVist(subSummary)).toFixed(2)),
        totalDuration: TotalPartduration(subSummary),
        totalLabour: addZero(
          parseFloat(TotalPartLabour(subSummary)).toFixed(2),
        ),
        multiTotal: addZero(parseFloat(TotalPartVist(subSummary)).toFixed(2)),
        totalMargin: parseFloat(totalMargin).toFixed(2),
        totalLabourOnly: parseFloat(totalLabourAmount).toFixed(2),
      };
      item[index].summary = obj;
      setNewAppointment(item);
    }

    const validateCost = Number.isNaN(totalCost) ? 0 : totalCost;

    setMutiSummary({
      ...mutiSummary,
      totalCost: addZero(parseFloat(validateCost).toFixed(2)),
      totalDuration,
      totalLabour: addZero(parseFloat(totalLabour).toFixed(2)),
      totalLabourMargin: addZero(parseFloat(totalMargin).toFixed(2)),
      multiTotal: validateCost,
      totalLabourAmount: addZero(parseFloat(totalLabourAmount).toFixed(2)),
    });
  };
  useEffect(() => {
    if (blocktime === 'open') {
      setShowDate(true);
    }
  });
  const [isloader, setisloader] = useState(false);
  const closeBlockTimeModal = async (deltype) => {
    if (deltype === 'close') {
      history.push(`${getCalendarPath2(startDateTime)}`);
      return;
    }
    setisloader(true);
    const Id = block_id;
    const type = localStorage.getItem('checkapptype');
    const staffId = employee_id;
    const response = await userInstance().delete(`/staff/deleteStaffBlocktimePersonalapp/${Id}/${staffId}`);
    const { code } = response.data;
    if (code === 200) {
      setTimeout(() => {
        setisloader(false);
        const typeMsg = type === 'personalappointment'
          ? 'Personal Appointment Deleted Successfully.'
          : 'Blocked Time Deleted Successfully.';
        toastMessage('success', typeMsg);
        history.push(`${getCalendarPath2(startDateTime)}`);
      }, 1000);
    }
  };
  const saveBlockTime = async () => {
    if (blcoktimeName) {
      let {
        createdOn,
        date,
        interval_multiplier,
        interval_type,
        endType,
        occurence,
        specificDate,
        occurenceMsg,
      } = dateData || {};
      let {
        staffId, staffName, duration, startTime,
      } = bookingData || {};
      const { intervalSetp, eventEndDate } = timeIntervalDay(
        occurence,
        interval_multiplier,
        interval_type,
        occurenceMsg,
        date,
        specificDate,
      );
    
      // const blockTimeendDate = moment(eventEndDate).format('YYYY-MM-DD');
      // const blockTimeStartDate = moment(date).format('YYYY-MM-DD');

      const blockTimeStartDate = udpateTime(date, startTime);
      const blockTimeendDate = updateEndTime(convertDateTime(blockTimeStartDate), duration);

      const intervalTime = interval_type === 'monthly'
        ? interval_multiplier
        : parseInt(intervalSetp, 10);
      let dateArray = blockTimeRange(
        date,
        eventEndDate,
        parseInt(intervalTime, 10),
        duration,
        interval_type,
        startTime,
      );
      // const n = new Date(startDateTime);
      // const time = startTime.split(':');
      // n.setHours(time[0]);
      // n.setMinutes(time[1]);
      // const newDDDD = moment(n).format(dateFormat);
      // console.log('newDDD====>', { newDDDD, dbTimeZone });

      const newDDDD = blockTimeStartDate;

      if (blocktype === 'edit') {
        let payload = {
          createdOn,
          date: getTimeZone2(dbTimeZone, blockTimeStartDate),
          interval_multiplier,
          interval_type,
          endType,
          occurence,
          specificDate,
          occurenceMsg,
          staffId,
          staffName,
          duration,
          startTime:displayTime(dbTimeZone, blockTimeStartDate),
          blockname: blcoktimeName,
          repeates: dateArray,
          endDate: getTimeZone2(dbTimeZone, blockTimeendDate),
          blockid: blcokId,
          type: localStorage.getItem('checkapptype'),
        };
        
        const response = await userInstance().post(
          '/staff/updateStaffBlockTime',
          {
            payload,
          },
        );
        let { code, msg } = response.data;
        if (code === 200) {
          const typeMsg = localStorage.getItem('checkapptype') === 'personalappointment'
            ? 'Personal Appointment Edit Successfully'
            : 'Blocked Time Edit Successfully';
          toastMessage('success', typeMsg);
          localStorage.removeItem('checkapptype');
          history.push(`${getCalendarPath2(newDDDD)}`);
        }
      } else {
        let payload = {
          createdOn,
          date: blockTimeStartDate,
          interval_multiplier,
          interval_type,
          endType,
          occurence,
          specificDate,
          occurenceMsg,
          staffId,
          staffName,
          duration,
          startTime,
          blockname: blcoktimeName,
          repeates: dateArray,
          endDate: blockTimeendDate,
          type: localStorage.getItem('checkapptype'),
          timeZone,
        };
        const response = await userInstance().post('/staff/addStaffBlockTime', {
          payload,
        });
        let { code, msg } = response.data;
        if (code === 200) {
          const typeMsg = localStorage.getItem('checkapptype') === 'personalappointment'
            ? 'Personal Appointment Added Successfully'
            : 'Blocked Time Added Successfully';
          toastMessage('success', typeMsg);
          localStorage.removeItem('checkapptype');
          history.push(`${getCalendarPath2(newDDDD)}`);
        }
      }
    } 
    
    setErrorValue('name');
    toastMessage('error', 'Please Enter Name');
    setTimeout(() => {
      setErrorValue('');
    }, 1000);
  };

  const stafftype = (info) => {
    const groupStaff = (key) => (result, current) => {
      const item = { ...current };
      if (typeof result[current[key]] === 'undefined') {
        result[current[key]] = [item];
      } else {
        let { length } = result[current[key]];
        let end = moment(new Date(result[current[key]][length - 1].end)).format(
          dateFormat,
        );
        const startTime = moment(end).format(timeFromDateFormat);
        let DefaultTime = startTime;
        end = moment(end).format(dateFormat);
        let currentEnd = moment(
          new Date(end).setMinutes(
            new Date(end).getMinutes()
            + parseFloat(result[current[key]][length - 1].duration),
          ),
        ).format(dateFormat);
        result[current[key]].push({
          ...item,
          start: end,
          startTime: DefaultTime,
          end: currentEnd,
        });
      }
      return result;
    };
    const timespan = info.reduce(groupStaff('staffId'), {});
    return timespan;
  };

  const calculateSameStaffDurations = (services) => {
    if (
      !(Object.keys(services).length === 0 && services.constructor === Object)
    ) {
      Object.entries(services).forEach((item) => {
        item[1].forEach((el) => {
          let start = moment(el.date).toDate();
          const time = el.startTime.split(':');
          start.setHours(time[0]);
          start.setMinutes(time[1]);
          start.setSeconds(0);
          el.start = moment(start).format(dateFormat);
          el.end = moment(
            new Date(
              start.setMinutes(start.getMinutes() + parseFloat(el.duration)),
            ),
          ).format(dateFormat);
        });
      });
      return services;
    }
  };

  const handleRenderSort = (index5) => {
    const old = [...newAppointment];
    if (old[index5].renderSort) {
      old[index5].renderSort = false;
    } else {
      old[index5].renderSort = true;
    }
    setNewAppointment(old);
  };

  const handleEditDetails = (data, data2) => {
    const url = `/client?clientid=${data}&name=${data2}&typee=edit&back=appt&startDateTime=${startDateTime}&employee_id=${employee_id}`;
    if (quoteId) {
      history.push(`${url}&quoteId=${quoteId}`);
    } else {
      history.push(url);
    }
  };
  const calculateWatesrCost = (data, updateType, updateDayData) => {
    let allChemicalServList = [];
    if (waterTest === 'done') {
      if (updateType === 'deletedays') {
        updateDayData?.forEach((el, i) => {
          let serviceProTypearr = el?.chemicalTestArray;
          allChemicalServList.push(serviceProTypearr);
        });
        const { subtotalCost, subtotalDuration, subtotalLabour } = calculatChemicalProductCost(allChemicalServList);

        const sObj = {
          newTotalCost: addZero(parseFloat(subtotalCost).toFixed(2)),
          newTotalDuration:subtotalDuration,
          newTotalLabourMargin: addZero(
            parseFloat(subtotalLabour).toFixed(2),
          ),
        };
        setWaterServiceSummary(sObj);
      } else {
        data.testResult.forEach((el, i) => {
          let serviceProTypearr = el.service.filter(
            (el2) => el2.type === 'service'
              || (el2.type === 'product' && el2.status !== 'quantity') || (el2.type === '' && el2.status === ''),
          );
          allChemicalServList.push(serviceProTypearr);
        });
        const { subtotalCost, subtotalDuration, subtotalLabour } = calculatChemicalProductCost(allChemicalServList);
        let newTotalCost = parseFloat(data.totalCost) - parseInt(subtotalCost, 10);
        let newTotalDuration = data.totalDuration - parseInt(subtotalDuration, 10);
        let newTotalLabourMargin = parseFloat(data.labourMargin) - parseInt(subtotalLabour, 10);
        setWaterServiceSummary({
          ...waterServiceSummary,
          newTotalCost: addZero(parseFloat(newTotalCost).toFixed(2)),
          newTotalDuration,
          newTotalLabourMargin: addZero(
            parseFloat(newTotalLabourMargin).toFixed(2),
          ),
        });
      }
    }
  };
  useEffect(() => {
    if (waterTest === 'done') {
      const resulttChemical = JSON.parse(localStorage.getItem('chemicalalist'));
      const chemicalalist = resulttChemical || {};
      calculateWatesrCost(chemicalalist[0], '');
      if (chemicalalist[0]?.testResult.length >= 1) {
        const calcType = chemicalalist[0]?.testResult[0].serviceAnalysis;
        setCalculater_type(calcType);
        let testCount = chemicalalist[0]?.testResult?.length;
        let apptypes = 'oneteam_oneday';
        let apptypemsg = "One-off appointment | Doesn't repeat";
        if (testCount > 1) {
          apptypes = 'oneteam_multiday';
          // apptypemsg = `One Team Member Multi Visit | ${chemicalalist[0]?.testResult.length} days | Doesn't repeat`;
          apptypemsg = `${chemicalalist[0]?.testResult.length} days | Doesn't repeat`;
          setAppointmentType('oneteam_multiday');
        }
        let waterarr = [];
        let flag = new Date(getTodayTime(timeZone));
        chemicalalist[0].testResult.forEach((el, i) => {
          let copyinitialDateData = { ...initialDateData };
          const chemicalaTypeList = el.service.filter(
            (el2) => el2.type === 'product' && el2.status === 'quantity',
          );
          const serviceProTypeList = el.service.filter(
            (el2) => el2.type === 'service'
              || (el2.type === 'product' && el2.status !== 'quantity') || (el2.type === '' && el2.status === ''),
          );
          setServices(serviceProTypeList);
          copyinitialDateData.chemicalTestArray = chemicalaTypeList || [];
          copyinitialDateData.service = serviceProTypeList || [];
          copyinitialDateData.appointmenttype = apptypes;
          copyinitialDateData.msg = apptypemsg;
          copyinitialDateData.AllchemicalTestArray = el.service;
          if (i === 0) {
            copyinitialDateData.date = moment(flag).format(dateFormat);
          } else if (calcType === 'Black-Spot Algae' || calcType === 'Drain Pool') {
            copyinitialDateData.date = moment(flag)
              .add(2, 'days')
              .format(dateFormat);
          } else if (calcType === 'Green Pool') {
            copyinitialDateData.date = moment(flag)
              .add(4, 'days')
              .format(dateFormat);
          } else {
            copyinitialDateData.date = moment(flag)
              .add(1, 'days')
              .format(dateFormat);
          }
          flag = copyinitialDateData.date;
          waterarr.push(copyinitialDateData);
          setRequiredDays(parseInt(testCount, 10));

          calculateMutiCost(waterarr, i);
        });
        setNewAppointment(waterarr);
      }
    }
  }, [removeChemical]);

  const backToPreviousPage = () => {
    if (quoteId) {
      history.push('/quotes');
    } else {
      history.push(getCalendarPath());
    }
  };

  const removeWaterConfirm = async (e, index) => {
    e.stopPropagation();
    setWaterTestPopup(true);
    setRemoveIndex(index);
  };

  const handleWaterUpdate = async (index) => {
    try {
      if (waterTest) {
        const response = await userInstance().get(
          `/watertest/getWaterHistoryByWaterId?id=${waterId}`,
        );
        const { waterList, msg } = response.data;
        const copyClientAllData = { ...clientAllData };
        copyClientAllData.appointmentData.chemicalsArray = waterList?.chemicals;
        copyClientAllData.waterTestHistory = waterList?.history;
        localStorage.setItem('apptData', JSON.stringify(copyClientAllData));

        if (waterList?.history?.serviceAnalysis === 'Water Test') {
          history.push(`/calculator?startDateTime=${startDateTime}&employee_id=${employee_id}&editChemicalList=true&watTesId=${waterList?._id}`);
        } else if (waterList?.chemicals?.length > 0 && waterList?.history?.serviceAnalysis !== 'Water Test') {
          history.push(`/calculator?startDateTime=${startDateTime}&employee_id=${employee_id}&visitMessage=true&watId=${waterList?._id}&chemIndex=${index}`);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (waterTest) {
      clientAllData.appointmentData = {};
      clientAllData.addedServices = [];
      clientAllData.eventDate = '';
      clientAllData.activeStaff = null;
      clientAllData.customerDues = 0;
      clientAllData.clientAllDetails = null;
      clientAllData.waterTestHistory = {};
      clientAllData.appointmentData.chemicalsArray = [];
      clientAllData.waterTestHistory = {};
    }
  }, []);

  const removeWaterTest = async () => {
    let copynewAppointmentData = [...newAppointment];
    copynewAppointmentData[removeIndex].chemicalTestArray = [];
    setNewAppointment(copynewAppointmentData);
    setRemoveIndex('');
    calculateWatesrCost('', 'deletedays', copynewAppointmentData);
    setWaterTestPopup(false);
  };
  return (
    <>
      <AdminWrapper>
        {blocktime !== 'open' && (
          <div className="appointment-container">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="back-option" onClick={backToPreviousPage}>
                    <span />
                    {/* <Link to={getCalendarPath()}> */}
                    <i className="fa fa-times" aria-hidden="true" />
                    {/* </Link> */}
                  </div>
                  <div className="main-titles">
                    <h3> Schedule Pool Maintenance</h3>
                  </div>
                  <div className="add-appointment">
                    <div className="row">
                      <div className="col-md-4">
                        {!addedClients ? (
                          <div className="search-container">
                            <div className="search-title">
                              <h3> User </h3>
                              <span
                                className="plus-icon"
                                onClick={handleShowSearch}
                                role="presentation"
                              >
                                <i className="fas fa-plus" />
                              </span>
                            </div>
                            <div className="no-customer">
                              {/* <img src={search} alt={search} /> */}
                              <p>
                                Use the search to add a customers, or keep empty
                                to save as walk-in.
                              </p>
                            </div>
                            <div className="footer-btn view-btn">
                              <Button className="appointment-btn btn-save">
                                {' '}
                                Save/Update
                                {' '}
                              </Button>
                            </div>
                          </div>
                        ) : null}
                        {addedClients ? (
                          <div className="search-container">
                            <div className="search-title" key={addedClients?._id}>
                              <div className="client-profile">
                                <div className="">
                                  <h3>{`${addedClients?.firstname} ${addedClients?.lastname}`}</h3>
                                  <p>
                                    {`${addedClients?.streetAddress || ''}
                                    ${addedClients?.suburb || ''}
                                     ${addedClients?.state || ''}`}
                                  </p>
                                  <p>{addedClients?.mobileNo}</p>
                                </div>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    <img src={showmore} alt="show" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {/* <Dropdown.Item>
                                    <div
                                      className="action client"
                                      onClick={() => {
                                        handleEditDetails(
                                          client?._id,
                                          client?.firstname,
                                        );
                                      }}
                                    >
                                      <img src={edit} alt="edit" />
                                      Edit Client Details
                                    </div>
                                  </Dropdown.Item> */}
                                    <Dropdown.Item>
                                      <div
                                        className="action client"
                                        onClick={() => {
                                          handleShowSearch();
                                        }}
                                      >
                                        <img src={clientimg} alt="edit" />
                                        Change User
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <div
                                        className="action"
                                        onClick={() => setAddedClients()}
                                      >
                                        <img src={greydelete} alt="edit" />
                                        Delete
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>

                            {/* <div className="client-profile">
                            <div className="client-name-contact site-addresses">
                              {client?.siteAddresses?.length > 0 && (
                              <div className="client-left-details">
                                <h5 className="client-company-name">
                                  Site Address
                                </h5>
                                <p>
                                  {client.siteAddresses[activeAdd]
                                    .householderName
                                    ? client.siteAddresses[activeAdd]
                                      .householderName
                                    : ''}
                                </p>
                                <p>
                                  {client.siteAddresses[activeAdd].address
                                    ? client.siteAddresses[activeAdd]
                                      .address
                                    : ''}
                                  {' '}
                                </p>
                                <p>
                                  <a
                                    className="mobile"
                                    href={`tel:${
                                      client.siteAddresses[activeAdd]
                                        .mobileNo || ''
                                    }`}
                                  >
                                    {client?.siteAddresses[
                                      activeAdd
                                    ].mobileNo.includes('+61')
                                      ? client?.siteAddresses[activeAdd]
                                        .mobileNo
                                      : `+61 ${
                                        client?.siteAddresses[
                                          activeAdd
                                        ].mobileNo.replace(
                                          phoneRegex,
                                          '$1 $2 $3',
                                        ) || ''
                                      }`}
                                  </a>
                                </p>
                              </div>
                              )}
                            </div>
                          </div> */}
                            {/* <div className="appointyment-type">
                              <h5>Appointment Type</h5>
                              <div
                                className="appointment-type-one"
                                onClick={() => handleAppointmentType('oneteam_oneday')}
                              >
                                <div className="team-member">
                                  <h6>One Team Member - Day Visit</h6>
                                  <p>
                                    (appointments that can be completed on a
                                    day)
                                  </p>
                                </div>
                                <div className="open-date client-notes">
                                  <div className="basic">
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        appointmentType
                                          && appointmentType === 'oneteam_oneday'
                                          ? true
                                          : ''
                                      }
                                      onClick={() => handleAppointmentType('oneteam_oneday')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="appointment-type-one"
                                onClick={() => handleAppointmentType('oneteam_multiday')}
                              >
                                <div className="team-member">
                                  <h6>One Team Member - Multi Visit</h6>
                                  <p>
                                    (requires more than one day to complete)
                                  </p>
                                </div>
                                <div className="open-date client-notes">
                                  <div className="basic">
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        appointmentType
                                          && appointmentType === 'oneteam_multiday'
                                          ? true
                                          : ''
                                      }
                                      onClick={() => handleAppointmentType(
                                        'oneteam_multiday',
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="appointment-type-one"
                                onClick={() => handleAppointmentType('multiteam_oneday')}
                              >
                                <div className="team-member">
                                  <h6>Additional Team Members - Day Visit</h6>
                                  <p>
                                    (requires multiple team members on a day
                                    visit)
                                  </p>
                                </div>
                                <div className="open-date client-notes">
                                  <div className="basic">
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        appointmentType
                                          && appointmentType === 'multiteam_oneday'
                                          ? true
                                          : ''
                                      }
                                      onClick={() => handleAppointmentType(
                                        'multiteam_oneday',
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="appointment-type-one"
                                onClick={() => handleAppointmentType('multiteam_multiday')}
                              >
                                <div className="team-member">
                                  <h6>Additional Team Members - Multi Visit</h6>
                                  <p>
                                    (multiple team members & more than one day
                                    needed)
                                  </p>
                                </div>
                                <div className="open-date client-notes">
                                  <div className="basic">
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        appointmentType
                                          && appointmentType === 'multiteam_multiday'
                                          ? true
                                          : ''
                                      }
                                      onClick={() => handleAppointmentType(
                                        'multiteam_multiday',
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            {/* <p className="group-time">
                              <span className="require-icon">*</span>
                              Used to group time, materials & cost on to a
                              single invoice
                            </p> */}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-8">
                        <div className="appointment-view">
                          {newAppointment
                            && newAppointment.map((el4, index4) => (
                              <div className="appointmentbox">
                        
                                <div
                                  className="appointment-date"
                                  role="presentation"
                                  onClick={(e) => (appIdMerge !== '' && index4 === 0
                                    ? e.preventDefault()
                                    : handleDateModal('', index4))}
                                >
                                  
                                  {' '}
                                  <div className="">
                                    <h3>
                                      {appIdMerge !== ''
                                        && dateByApp !== ''
                                        && index4 === 0
                                        ? convertDateTime(dateByApp).format('dddd, D MMMM yyyy')
                                        : convertDateTime(el4.date).format('dddd, D MMMM yyyy') }
                                    </h3>
                                    
                                    <span>{el4.msg}</span>
                                  </div>
                                  <div className="open-date">
                                    <img src={arrowwhite} alt="arrow" />
                                  </div>
                                </div>
                                <div className="search-title service-head">
                                  <h3> Maintenance Required</h3>
                                  <div className="sorting-services">
                                    {el4.service.length > 1 ? (
                                      <div className="sorting">
                                        <img
                                          src={sort}
                                          alt="sorting"
                                          onClick={() => handleRenderSort(index4)}
                                        />
                                      </div>
                                    ) : null}
                                    <span
                                      className="plus-icon"
                                      onClick={() => handleShowService(index4)}
                                      role="presentation"
                                    >
                                      <i className="fas fa-plus" />
                                    </span>
                                  </div>
                                </div>
                                {el4.service.map((el, index) => (
                                  <>
                                    <div
                                      className={`search-title ${el4.renderSort && 'add-sortings'
                                      }`}
                                    >

                                      <div
                                        className="client-profile"
                                        onClick={() => handleEditService({
                                          service: el,
                                          index,
                                          appIndex: index4,
                                        })}
                                      >
                                        <h3>
                                          {`${el.category
                                            .toLowerCase()
                                            .includes('misc')
                                            ? `${el.customMisc}`
                                            : el.subcategory
                                          } 
                                         
                                          `}
                                        </h3>
                                        <p>
                                          <span>
                                            {moment(el?.startTime, [
                                              timeFromDateFormat,
                                            ]).format('h:mm a')}
                                          </span>
                                          <span> | </span>
                                          <span>
                                            {` ${convertToReadableDuration(
                                              el?.duration,
                                            )}`}

                                          </span>
                                          <span> | </span>
                                          <span>{` with ${el?.staffName}`}</span>
                                        </p>
                                        {/* <p>
                                          <span>
                                            {`${
                                              el.type === 'product'
                                                ? `Margin $${addZero(
                                                  el.laborMargin,
                                                )}`
                                                : el.type === 'service'
                                                  ? `Labour $${`${addZero(
                                                    el.totalLabour,
                                                  )}`}`
                                                  : null
                                            }`}
                                          </span>
                                        </p> */}
                                      </div>
                                      <div className="sorting-sub-services appointment-sort">
                                        <div className="service-edit-icon">
                                          {el4.renderSort ? (
                                            <div
                                              className={`sorting up-down ${el4.renderSort && 'active-sort'
                                              }`}
                                            >
                                              {index !== 0 ? (
                                                <img
                                                  src={upsort}
                                                  alt="sorting"
                                                  onClick={() => moveArrr(
                                                    index,
                                                    index - 1,
                                                    'minus',
                                                    index4,
                                                  )}
                                                />
                                              ) : null}
                                              {index
                                                !== el4.service.length - 1 ? (
                                                  <img
                                                    src={downsort}
                                                    alt="sorting"
                                                    onClick={() => moveArrr(
                                                      index,
                                                      index + 1,
                                                      'plus',
                                                      index4,
                                                    )}
                                                  />
                                                ) : null}
                                            </div>
                                          ) : null}
                                        </div>

                                        {/* <div
                                          className="content-dropdown"
                                          onClick={() => handleEditService({ service: el, index, appIndex: index4 })}
                                        >
                                          <Dropdown name="startingDate">
                                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                                              <img src={arrow} alt="arrow" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item>Option 1</Dropdown.Item>
                                              <Dropdown.Item>Option 2</Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div> */}

                                        {/* For the services an dproduct of calendar only not from calculator>  */}

                                        {/* {!el.edittype
                                          ? (
                                            <div
                                              className="content-dropdown"
                                              onClick={() => handleEditService({ service: el, index, appIndex: index4 })}
                                            >
                                              <Dropdown name="startingDate">
                                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                  <img src={arrow} alt="arrow" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  <Dropdown.Item>Option 1</Dropdown.Item>
                                                  <Dropdown.Item>Option 2</Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          ) : null} */}
                                        <div
                                          className="content-dropdown"
                                          onClick={() => handleEditService({
                                            service: el,
                                            index,
                                            appIndex: index4,
                                          })}
                                        >
                                          <Dropdown name="startingDate">
                                            <Dropdown.Toggle
                                              variant="link"
                                              id="dropdown-basic"
                                            >
                                              <img src={arrow} alt="arrow" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                Option 1
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                Option 2
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                                {!appId && el4.chemicalTestArray
                                  && el4.chemicalTestArray?.length > 0 && (
                                    <>
                                      <span className="sep" />
                                      <div className="finalize-cost-page view-appointment-details">
                                        <div className="added-chemicals-info">
                                          <div className="chemical-cross" onClick={() => handleWaterUpdate(index4)}>
                                            <h6>Chemicals added</h6>
                                            <i
                                              className="fa fa-times"
                                              aria-hidden="true"
                                              onClick={(e) => removeWaterConfirm(e, index4)}
                                            />
                                          </div>
                                          {el4.chemicalTestArray
                                            && el4.chemicalTestArray?.length > 0
                                            && el4.chemicalTestArray.map(
                                              (chem, index) => (
                                                <>

                                                  { (parseFloat(chem?.cost) > 0 || chem.cost === 'N/A')

                                                    && chem.type === 'product' ? (
                                                      <div className="chemical-description">
                                                        <div className="chemical-name">
                                                          -
                                                          {' '}
                                                          {chem.subcategory || ''}
                                                        </div>
                                                        <div className="chemical-rate">
                                                          $
                                                          <span>
                                                            {chem.cost === 'N/A' ? chem.renderCost : parseFloat(
                                                              chem.renderCost,
                                                            ).toFixed(2)}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                </>
                                              ),
                                            )}
                                        </div>
                                      </div>
                                    </>
                                )}
                                {/* {waterTest && waterTest === 'done' ? (
                                  <>
                                    <div className="staff-form view-add-appointment">
                                      {index4 === 0 ? (
                                        <div className="view-btn service-submit ">
                                          <Button className="add-btn water-test-btn water-test-done">
                                            Water Test Done
                                          </Button>
                                        </div>
                                      ) : null}
                                    </div>
                                    {' '}
                                  </>
                                ) : null} */}
                                {appointmentType === 'oneteam_oneday' && (
                                  <>
                                    <div className="appointment-form">
                                      <Form>
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                          <Form.Label>
                                            Notes
                                          </Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Will Display in Maintenance Scheduled"
                                            onChange={(e) => handleChangeNotesMulti(
                                              'teamnotes',
                                              e.target.value,
                                              index4,
                                            )}
                                            name="notes"
                                          />
                                        </Form.Group>
                                      </Form>
                                    </div>
                                    <div className="appointment-total">
                                      {/* <h6>
                                        Visit Total : $
                                        {waterTest === 'done'
                                          ? `${currencyComma(
                                            (
                                              parseFloat(
                                                removeComma(
                                                  waterServiceSummary.newTotalCost,
                                                ),
                                              )
                                                + parseFloat(
                                                  mutiSummary.multiTotal,
                                                )
                                            ).toFixed(2),
                                          )}`
                                          : `${mutiSummary.totalCost || '0'}`}
                                      </h6> */}
                                      <h6 className="duration-time DOK">
                                        Duration:
                                        <span>

                                          {waterTest === 'done'
                                            ? convertToReadableDuration(
                                              parseInt(
                                                waterServiceSummary.newTotalDuration,
                                                10,
                                              )
                                              + parseInt(
                                                mutiSummary.totalDuration
                                                || 0,
                                                10,
                                              ),
                                            )
                                            : mutiSummary.totalDuration
                                              ? convertToReadableDuration(
                                                mutiSummary.totalDuration,
                                              )
                                              : '0 min'}
                                        </span>
                                      </h6>
                                      {/* <h6 className="duration-time">
                                        {waterTest === 'done'
                                          ? `Labour & Margin: $${(
                                            parseFloat(
                                              removeComma(
                                                waterServiceSummary.newTotalLabourMargin,
                                              ),
                                            )
                                              + parseFloat(
                                                removeComma(
                                                  mutiSummary.totalLabour,
                                                ),
                                              )
                                          ).toFixed(2)}`
                                          : (parseFloat(
                                            mutiSummary.totalLabourAmount,
                                          ) > 0
                                              && parseFloat(
                                                mutiSummary.totalLabourMargin,
                                              ) > 0)
                                            || parseFloat(
                                              mutiSummary.totalLabour === 0,
                                            )
                                            ? `Labour & Margin: $${mutiSummary.totalLabour} `
                                            : parseFloat(
                                              mutiSummary.totalLabourMargin,
                                            ) > 0
                                              ? `Margin: $${mutiSummary.totalLabour}`
                                              : `Labour: $${mutiSummary.totalLabour}`}
                                      </h6> */}
                                    </div>
                                  </>
                                )}
                                {(appointmentType === 'oneteam_multiday'
                                  || appointmentType === 'multiteam_oneday'
                                  || appointmentType === 'multiteam_multiday') && (
                                    <>
                                      <div className="appointment-form">
                                        <Form>
                                          <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>
                                              Notes
                                            </Form.Label>
                                            <Form.Control
                                              as="textarea"
                                              rows={3}
                                              placeholder="Will Display in Maintenance Scheduled"
                                              // onChange={(e) => setNotes(e.target.value)}
                                              value={el4.notes}
                                              onChange={(e) => handleChangeNotesMulti(
                                                'teamnotes',
                                                e.target.value,
                                                index4,
                                              )}
                                              name="notes"
                                            />
                                          </Form.Group>
                                        </Form>
                                      </div>
                                      <div className="appointment-total">
                                        {waterTest === 'done' ? (
                                          <>
                                            <h6>
                                              {`Step-${index4 + 1} (of ${newAppointment.length
                                              }) Total: $${currencyComma(
                                                (
                                                  parseFloat(
                                                    TotalPartVist(el4.service),
                                                  )
                                                    + parseFloat(
                                                      TotalPartVist(
                                                        el4.chemicalTestArray,
                                                      ),
                                                    )
                                                ).toFixed(2),
                                              )}`}
                                            </h6>
                                            <h6 className="duration-time">
                                              Duration:
                                              <span>
                                                {convertToReadableDuration(
                                                  TotalPartduration(el4.service)
                                                  + TotalPartduration(
                                                    el4.chemicalTestArray,
                                                  ),
                                                )}
                                              </span>
                                            </h6>
                                            {/* <h6>
                                              labour & Margin: $
                                              {(
                                                parseFloat(
                                                  TotalPartLabour(el4.service),
                                                )
                                                + parseFloat(
                                                  TotalPartLabour(
                                                    el4.chemicalTestArray,
                                                  ),
                                                )
                                              ).toFixed(2)}
                                            </h6> */}
                                          </>
                                        ) : (
                                          <>
                                            <h6>
                                              {`Step-${index4 + 1} (of ${newAppointment.length
                                              }) Total: $${addZero(
                                                parseFloat(
                                                  TotalPartVist(el4.service),
                                                ).toFixed(2),
                                              )}`}

                                            </h6>
                                            <h6 className="duration-time">
                                              Duration:
                                              <span>
                                                {convertToReadableDuration(
                                                  TotalPartduration(el4.service),
                                                )}
                                              </span>
                                            </h6>
                                            {/* <h6>
                                              labour & Margin: $
                                              {parseFloat(
                                                TotalPartLabour(el4.service),
                                              ).toFixed(2)}
                                            </h6> */}
                                          </>
                                        )}
                                      </div>
                                    </>
                                )}
                              </div>
                            ))}
                          {(appointmentType === 'oneteam_multiday'
                            || appointmentType === 'multiteam_oneday'
                            || appointmentType === 'multiteam_multiday') && (
                              <div className="appointment-total">
                                <h6>
                                  Multi-Step Total: $
                                  {waterTest === 'done'
                                    ? `${currencyComma(
                                      (
                                        parseFloat(
                                          removeComma(
                                            waterServiceSummary.newTotalCost,
                                          ),
                                        ) + parseFloat(mutiSummary.multiTotal)
                                      ).toFixed(2),
                                    )}`
                                    // eslint-disable-next-line no-restricted-globals
                                    : `${isNaN(mutiSummary?.totalCost) ? 0 : mutiSummary?.totalCost}`}
                                </h6>
                                <h6 className="duration-time">
                                  Combined Duration:
                                  <span>
                                    {waterTest === 'done'
                                      ? convertToReadableDuration(
                                        parseInt(
                                          waterServiceSummary.newTotalDuration,
                                          10,
                                        )
                                        + parseInt(
                                          mutiSummary.totalDuration || 0,
                                          10,
                                        ),
                                      )
                                      : mutiSummary.totalDuration
                                        ? convertToReadableDuration(
                                          mutiSummary.totalDuration,
                                        )
                                        : '0 min'}
                                  </span>
                                </h6>
                                {/* <h6 className="duration-time">
                                  {waterTest === 'done'
                                    ? `Combined Labour & Margin: $${(
                                      parseFloat(
                                        removeComma(
                                          waterServiceSummary.newTotalLabourMargin,
                                        ),
                                      )
                                      + parseFloat(
                                        removeComma(mutiSummary.totalLabour),
                                      )
                                    ).toFixed(2)}`
                                    : (parseFloat(mutiSummary.totalLabourAmount)
                                      > 0
                                      && parseFloat(
                                        mutiSummary.totalLabourMargin,
                                      ) > 0)
                                      || parseFloat(mutiSummary.totalLabour === 0)
                                      ? `Combined Labour & Margin: $${mutiSummary.totalLabour} `
                                      : parseFloat(mutiSummary.totalLabourMargin)
                                        > 0
                                        ? `Margin: $${mutiSummary.totalLabour}`
                                        : `Combined Labour: $${mutiSummary.totalLabour}`}
                                </h6> */}

                                {/* <h6>{`Multi-Visit Total:$${mutiSummary.totalCost}`}</h6>
                                  <h6 className="duration-time">
                                    Combined Duration:
                                    <span>{convertToReadableDuration(mutiSummary.totalDuration)}</span>
                                  </h6> */}
                              </div>
                          )}
                          {/* {appointmentType === 'multiteam_oneday'
                              && (
                                <>
                                  <div className="appointment-form">
                                    <Form>
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Team Member Notes</Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          placeholder="Only Visible to Team Members"
                                          // onChange={(e) => setNotes(e.target.value)}
                                          // value={notes}
                                          onChange={(e) => handleChangeNotes('notes', e.target.value, 0)}
                                          name="notes"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </div>
                                  <div className="appointment-total">
                                    <h6>{`Multi-Visit Total:$${mutiSummary.totalCost}`}</h6>
                                    <h6 className="duration-time">
                                      Combined Duration:
                                      <span>{convertToReadableDuration(mutiSummary.totalDuration)}</span>
                                    </h6>
                                  </div>
                                </>
                              )} */}
                          {dateData.requiredday
                            && summary.multiTotal
                            && multiAppointment ? (
                              <div className="appointment-total">
                                {/* <h6>{`Multi-Visit Total: $${addZero((parseFloat(summary.totalCost.replace(',', '')) * parseFloat(dateData.requiredday)).toFixed(2))}`}</h6>
                            <h6 className="duration-time">
                              Combined Duration:
                              <span>{convertToReadableDuration(summary.totalDuration * parseInt(dateData.requiredday))}</span>
                            </h6> */}
                              </div>
                            ) : (
                              ''
                            )}
                          <div className="footer-btn view-btn">
                            {/* <Button className="appointment-btn btn-save" onClick={appIdMerge !== '' ? handleEditAppointment : handleAddAppointment}>
                                Save/Update
                              </Button> */}
                            <Button
                              className="appointment-btn btn-save"
                              onClick={
                                appIdMerge !== ''
                                  ? handleAddAppointment
                                  : handleAddAppointment
                              }
                              // disabled={checksubmit || ''}
                            >
                              Save/Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AdminWrapper>
      {showWaterSelectionPopup ? (
        <WaterSelectionDatePopup
          setShowWaterSelectionPopup={setShowWaterSelectionPopup}
          handleSelect={handleSelect}
          handleWaterTestApp={handleWaterTestApp}
          handleCancel={handleCancel}
          selectBoxes={selectBoxes}
        />
      ) : null}
      {showClientAppointment ? (
        <ClientAppointments
          handleCancel={handleCancel}
          setSkip={setSkip}
          limit={limit}
          pageCount={pageCount}
          appointmentClient={appointmentClient}
          handleSelect={handleSelect}
          addedClients={addedClients[0]}
          setShowClientAppoinment={setShowClientAppoinment}
          setShowWaterSelectionPopup={setShowWaterSelectionPopup}
          setAppIdMerge={setAppIdMerge}
          setBookingData={setBookingData}
          bookingData={bookingData}
          setEndTimeNew={setEndTimeNew}
          endTimeNew={endTimeNew}
          setDateByApp={setDateByApp}
          newAppointment={newAppointment}
          setNewAppointment={setNewAppointment}
        />
      ) : null}

      <SearchModal
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        handleShowSearch={handleShowSearch}
        searchClient={searchClient}
        searchedClients={searchedClients}
        handleAddClient={handleAddClient}
        clientTxt={clientTxt}
        setClientTxt={setClientTxt}
        checkAddress={checkAddress}
        setHideSearch={setHideSearch}
        isHideSearch={isHideSearch}
        getAllClients={getAllClients}
      />
      <DateModal
        showDate={showDate}
        setShowDate={setShowDate}
        handleDateModal={handleDateModal}
        handleBookingData={handleBookingData}
        bookingData={bookingData}
        setBookingData={setBookingData}
        dateData={dateData}
        handleChangeDateData={handleChangeDateData}
        setDateData={setDateData}
        repeatAppt={repeatAppt}
        setRepeatAppt={setRepeatAppt}
        handleFrequency={handleFrequency}
        repeatFrequency={repeatFrequency}
        setRepeatFrequency={setRepeatFrequency}
        handleChangeDate={handleChangeDate}
        datePopupData={datePopupData}
        timepickerRef={timepickerRef}
        handleSaveDate={handleSaveDate}
        handleRepeat={handleRepeat}
        appointmentType={appointmentType}
        appointmentIndex={appointmentIndex}
        blocktime={blocktime}
        closeBlockTimeModal={closeBlockTimeModal}
        handleDurationPicker={handleDurationPicker}
        convertToReadableDuration={convertToReadableDuration}
        handleTimePicker={handleTimePicker}
        staffList={staffList}
        blcoktimeName={blcoktimeName}
        setBlcoktimeName={setBlcoktimeName}
        saveBlockTime={saveBlockTime}
        blocktype={blocktype}
        CrossDate={CrossDate}
        newAppointment={newAppointment}
        requiredDays={requiredDays}
        setRequiredDays={SetRDays}
        team={team}
        setTeam={SetRteams}
        error={errorValue}
        isloader={isloader}
      />
      <ServiceModal
        showService={showService}
        setShowService={setShowService}
        handleShowService={handleShowService}
        newLength={newLength}
        categories={categories}
        subcategories={subcategories}
        handleBookingData={handleBookingData}
        getTimes={getTimes}
        bookingData={bookingData}
        renderOptions={renderOptions}
        staffList={staffList}
        handleAddService={handleAddService}
        editData={editData}
        handleDeleteService={handleDeleteService}
        handleSearchCategory={handleSearchCategory}
        setSubcategories={setSubcategories}
        addSubcategoryToService={addSubcategoryToService}
        editService={editService}
        setBookingData={setBookingData}
        staffIndex={staffIndex}
        misc={misc}
        addedServices={addedServices}
        servicesLength={addedServices.length}
        extras={extras}
        handleChangeCustom={handleChangeCustom}
        timepickerShow={timepickerShow}
        handleTimePicker={handleTimePicker}
        subcategoryTxt={subcategoryTxt}
        setSearchTxt={setSearchTxt}
        durationPicker={durationPicker}
        setDurationPicker={setDurationPicker}
        convertToReadableDuration={convertToReadableDuration}
        handleDurationPicker={handleDurationPicker}
        setnewlength={setnewlength}
        setHourPicker={setHourPicker}
        hourPicker={hourPicker}
        handleHourPicker={handleHourPicker}
        appointmentType={appointmentType}
        setDuration={setDuration}
        durationData={durationData}
        endTimeNew={endTimeNew}
        timeDisplay={timeDisplay}
        timeZone={timeZone}
        error={errorValue}
      />
      {timepickerShow ? (
        <TimePicker
          timepickerShow={timepickerShow}
          handleTimePicker={handleTimePicker}
          bookingData={bookingData}
          setBookingData={setBookingData}
          timeData={timeData}
          setTime={setTime}
          timeDisplay={timeDisplay}
          timeZone={timeZone}
        />
      ) : null}

      {chemicalAddSelected ? (
        <ChemicalsAddedPopUp
          chemicalAddSelected={chemicalAddSelected}
          setChemicalAddSelected={setChemicalAddSelected}
          chemicalOptionsSelected={chemicalOptionsSelected}
        />
      ) : null}
      {durationPicker && (
        <DurationPicker
          durationPicker={durationPicker}
          setDurationPicker={setDurationPicker}
          durationData={durationData}
          handleDurationPicker={handleDurationPicker}
          setDuration={setDuration}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      )}
      {hourPicker ? (
        <HourPicker
          setHourPicker={setHourPicker}
          handleHourPicker={handleHourPicker}
          hourPicker={hourPicker}
          hourData={hourData}
          handleDurationPicker={handleDurationPicker}
          setHourData={setHourData}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      ) : null}

      {waterTestPopup ? (
        <DeleteWaterTestPopup
          setWaterTestPopup={setWaterTestPopup}
          handleWaterTestPopuncheck={removeWaterTest}
        />
      ) : null}
    </>
  );
}

export default AddAppointment;
