/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useEffect, useRef, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form, Button } from 'react-bootstrap';
import moment from '../../functions/moment';
import showmore from '../../assets/home/showmoreblue.png';
import target from '../../assets/home/icon/target.png';
import './index.css';
import {
  billing_users, billing_pools,
  billing_rules,
  durationCheck,
  poolCheck,
  userCheck,
  checkRemainTime,
} from './utils';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const SubscriptionSelection = ({
  handleAction, user, setUser, error, userCopy, handleEditSubscription, setisisDowngraded,
}) => {
  const { userData } = useContext(AuthDataContext);

  const { userInformation } = userData || {};

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleAction('');
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // this function will handle all Edit Subscription Cases
  // this function will handle all Edit Subscription Cases
  function billing_edit_summary(durationTime, poolNum, userNum) {
  // Guard Pattern
  // check if duartion has been downgraded
    const checkDuration = durationCheck(Number(durationTime), userCopy.duration, user.endsubscriptionDate);
    if (!checkDuration) {
      return;
    }
    // check if Number of Pools has been downgraded
    const checkPools = poolCheck(Number(poolNum), userCopy.numberOfPools, user.endsubscriptionDate);
    if (!checkPools) {
      setisisDowngraded((prev) => ({ ...prev, pools: true }));
    }
    // check if Number of Users has been downgraded
    const checkUsers = userCheck(Number(userNum), userCopy.numberUsers, user.endsubscriptionDate);
    if (!checkUsers) {
      setisisDowngraded((prev) => ({ ...prev, users: true }));
    }

    const userCost = 5;
    const poolCost = 5;
    let extraUserCost = 0;
    let extraPoolCost = 0;
    let finalCost = 0;
    const billingDetails = billing_rules.find((el) => el.duration === Number(durationTime));
    const {
      includedUsers,
      includedPools,
      cost,
    } = billingDetails;

    const extraUsers = Number(userNum) - includedUsers;
    const extraPools = Number(poolNum) - includedPools;
    // check the remaining time left for the subscription to end and add in accordance to number of months left
    const timeRemain = checkRemainTime(user.endsubscriptionDate);

    if (extraUsers > 0) {
      extraUserCost = extraUsers * userCost * timeRemain;
    }

    if (extraPools > 0) {
      extraPoolCost = extraPools * poolCost * timeRemain;
    }

    finalCost = cost + extraPoolCost + extraUserCost;
    // check if the subscription if of trial basis using this if condition
    if (Number(durationTime) === 0) {
      setUser((prev) => ({
        ...prev, duration: 0, totalCost: 0, subscriptionStatus: 'trial', numberOfPools: 0, numberUsers: 0,
      }));
    } else {
    // this else condition is for paid subscribers
      let numberOfPools = Number(poolNum);
      let numberUsers = Number(userNum);
      if (Number(poolNum) === 0) {
        numberOfPools = 1;
      }

      if (Number(userNum) === 0) {
        numberUsers = 2;
      }
      // calculate this extra cost and if the extra cost will be greater than Zero then open the payment form
      const extraCost = parseFloat(finalCost) - parseFloat(userCopy.totalCost);
      setUser((prev) => ({
        ...prev, duration: Number(durationTime), totalCost: finalCost, subscriptionStatus: 'paid', numberOfPools, numberUsers, extraCost,
      }));
    }
  }

  const handleChangeSubscription = (type, e) => {
    // this if condition will take all necesary cases in editing case while all the else-if will work for the Add cases
    // selected client is a state which holds the mongoID of the subscriber in Edit case
    // for edit all cases starts
    if (type === 'duration') {
      billing_edit_summary(e.target.value, user.numberOfPools, user.numberUsers);
    } else if (type === 'pool') {
      billing_edit_summary(user.duration, e.target.value, user.numberUsers);
    } else if (type === 'user') {
      billing_edit_summary(user.duration, user.numberOfPools, e.target.value);
    }
    // for edit all cases ends
  };
  return (
    <>
      <div
        className="duration-picker time-picker-modal chat-bot-page billing-popup change-billing-popup subscription-selection-popup"
      >
        <div>
          <div className="time-keeper-popup duration-popup newContent_popup" ref={wrapperRef}>
            <div className="inner-content">
              <div className="back-popup" onClick={() => handleAction()}>
                <i className="las la-times" />
              </div>
              <div className="maincontent">

                <div className="main-titles-mob">
                  <h3>PoolWater-Pro Billing</h3>
                  <div className="content-dropdown">
                    <div className="client-option service-edit-icon">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img src={showmore} alt="show" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <div
                              className="action"
                              onClick={() => handleAction('Calculator-Pro Billing')}
                            >
                              <span>
                                <img src={target} alt="edit" />
                              </span>
                              <span>Current Subscription</span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div
                              className="action"
                              onClick={() => handleAction('Edit My Details')}
                            >
                              <span>
                                <img src={target} alt="edit" />
                              </span>
                              <span>Edit My Details</span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div
                              className="action"
                              onClick={() => handleAction('Change Billing Details')}
                            >
                              <span>
                                <img src={target} alt="edit" />
                              </span>
                              <span>Change Billing Details</span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div
                              className="action"
                              onClick={() => handleAction('Update Subscription')}
                            >
                              <span>
                                <img src={target} alt="edit" />
                              </span>
                              <span>Update Subscription</span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div
                              className="action"
                            >
                              <span>
                                <img src={target} alt="edit" />
                              </span>
                              <span>Cancel Subscription</span>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="target-heading">
                  <h6>Update Subscription</h6>
                </div>
                <div className="edit-my-details-content billing-content">
                  <div className="staff-form">
                    <div className="billing-details-section">
                      <h6>Current Subscription</h6>
                      <ul className="subscription-selection">
                        <li>
                          <h3>
                            Subscription Duration:
                          </h3>
                          <h5>
                            <span>
                              {userInformation?.duration ? `${userInformation?.duration} months` : '0 month'}
                            </span>
                          </h5>
                        </li>
                        {/* <span>
                          (
                          {moment(userInformation?.startsubscriptionDate).format('D MMM YYYY')}
                          {' '}
                          -
                          {' '}
                          {moment(userInformation?.endsubscriptionDate).format('D MMM YYYY')}
                          )
                        </span> */}
                      </ul>
                      <ul className="my-details-section">
                        <li>
                          <h3>Start Date:</h3>
                          <h5>
                            
                            {moment(userInformation?.startsubscriptionDate).format('D MMM YYYY')}
                            
                          </h5>
                        </li>
                        <li>
                          <h3>End Date:</h3>
                          <h5>
                                   
                            {moment(userInformation?.endsubscriptionDate).format('D MMM YYYY')}
                            
                          </h5>
                        </li>
                      </ul>
                      <ul className="my-details-section">
                        <li>
                          <h3>No. of Pools:</h3>
                          <h5>
                            {userInformation?.numberOfPools || 0}
                            {' '}
                            {' '}
                            Pools
                          </h5>
                        </li>
                        <li>
                          <h3>No. of Users:</h3>
                          <h5>
                            {userInformation?.numberUsers || 0}
                            {' '}
                            {' '}
                            {' '}
                            User
                          </h5>
                        </li>
                        <div className="sub-total">
                          <h3>
                            Total Cost:
                            {' '}
                            {' '}
                            {' '}
                            AUD$
                            {userInformation?.totalCost?.toFixed(2) || '0.00'}
                          </h3>
                        </div>
                      </ul>
                    </div>

                    <h6>Updated Subscription Details</h6>
                    <Form onSubmit={(e) => { e.preventDefault(); }}>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group controlId="formBasicInput-9" className="italic-text">
                            <Form.Label>Subscription Duration</Form.Label>
                            <div className="select-box">
                              <Form.Control
                                as="select"
                                name="duration"
                                onChange={(e) => handleChangeSubscription('duration', e)}
                                value={user.duration}
                                className={`${
                                  error === 'subscriptionduration'
                                    ? 'error-text'
                                    : ''
                                }`}
                              >
                                <option value="">Select</option>
                                {billing_rules.map((el) => <option value={el.duration}>{el.description}</option>)}

                              </Form.Control>
                            </div>
                            <span>
                              {' '}
                              {`(${moment(user.startsubscriptionDate).format('D MMM YYYY')}`}
                              {' '}
                              -
                              {' '}
                              {`${moment(user.endsubscriptionDate).format('D MMM YYYY')})`}

                              {' '}
                            </span>
                          </Form.Group>
                        </div>
                        {user?.subscriptionStatus !== 'trial' && (
                        <>
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicLocation">
                              <Form.Label>
                                No. of Pools
                              </Form.Label>
                              <div className="select-box">
                                <Form.Control
                                  as="select"
                                  value={user.numberOfPools}
                                  name="numberOfPools"
                                  onChange={(e) => handleChangeSubscription('pool', e)}
                                  className={`${
                                    error === 'numberofpools'
                                      ? 'error-text'
                                      : ''
                                  }`}
                                >
                                  <option value="">Select</option>
                                  {billing_pools.map((el) => (
                                    <option value={el.num}>
                                      {el.description}
                                    </option>
                                  ))}
                                </Form.Control>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicLocation">
                              <Form.Label>
                                No. of Users
                              </Form.Label>
                              <div className="select-box">
                                <Form.Control
                                  as="select"
                                  value={user.numberUsers}
                                  name="numberUsers"
                                  onChange={(e) => handleChangeSubscription('user', e)}
                                  className={`${
                                    error === 'numberofuser'
                                      ? 'error-text'
                                      : ''
                                  }`}
                                >
                                  <option value="">Select</option>
                                  {billing_users.map((el) => (
                                    <option value={el.num}>
                                      {el.description}
                                    </option>
                                  ))}
                                </Form.Control>
                              </div>
                            </Form.Group>
                          </div>
                        </>
                        )}
                        <div className="total-cost">
                          <h5>{`Total Cost : AUD$${user?.totalCost ? user?.totalCost?.toFixed(2) : '0.00'}`}</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="view-btn">
                            <Button type="button" className="btn btn-edit" onClick={() => handleAction('Calculator-Pro Billing')}> Cancel </Button>
                            <Button type="Submit" className="btn btn-save" onClick={handleEditSubscription}> Save/Update </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
SubscriptionSelection.propTypes = {
  handleAction: PropTypes.func.isRequired,
  user: PropTypes.objectOf.isRequired,
  setUser: PropTypes.func.isRequired,
  userCopy: PropTypes.objectOf.isRequired,
  handleEditSubscription: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  setisisDowngraded: PropTypes.func.isRequired,
};
export default SubscriptionSelection;
