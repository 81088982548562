/* eslint-disable operator-linebreak */
import toastMessage from '../../functions/toastMessage';

export const billing_data = {
  'United States': [
    {
      Option: 'Option 1',
      description: '10 Day Free Trial',
    },
    {
      Option: 'Option 2',
      description: '1 Year - US$179.52 (= $14.96/month x 12 months)',
    },
    {
      Option: 'Option 3',
      description: '2 Year - US$341.28 (= $14.22/month x 24 months)',
    },
    {
      Option: 'Option 4',
      description: '3 Year - US$484.92 (= $13.47/month x 36 months)',
    },
  ],
  Australia: [
    {
      Option: 'Option 1',
      description: ' 10 Day Free Trial',
    },
    {
      Option: 'Option 2',
      description: ' 1 Year - AU$264.00 (= $22.00/month x 12 months)',
    },
    {
      Option: 'Option 3',
      description: ' 2 Years - AU$462.00  (= $20.90/month x 24 months)',
    },
    {
      Option: 'Option 4',
      description: ' 3 Years - AU$594.00  (= $19.80/month x 36 months)',
    },
  ],
};
export const billing_rules = {
  'United States': [
    {
      duration: 0,
      includedPools: 1,
      includedUsers: 2,
      cost: 0,
      description: '10 Day Free Trial (= no cost)',
    },
    {
      duration: 12,
      includedPools: 1,
      includedUsers: 2,
      cost: 179.52,
      description: '1 Year - $179.52 (= 12 months pre-paid)',
    },
    {
      duration: 24,
      includedPools: 1,
      includedUsers: 2,
      cost: 341.28,
      description: '2 Year - $341.28 (= 24 months pre-paid)',
    },
    {
      duration: 36,
      includedPools: 1,
      includedUsers: 2,
      cost: 484.92,
      description: '3 Year - $484.92 (= 36 months pre-paid)',
    },
  ],
  Australia: [
    {
      duration: 0,
      includedPools: 1,
      includedUsers: 2,
      cost: 0,
      description: '10 Day Free Trial (= no cost)',
    },
    {
      duration: 12,
      includedPools: 1,
      includedUsers: 2,
      cost: 264.0,
      description: ' 1 Year - AU$264.00 (= 12 months pre-paid)',
    },
    {
      duration: 24,
      includedPools: 1,
      includedUsers: 2,
      cost: 462.0,
      description: ' 2 Years - AU$462.00  (= 24 months pre-paid)',
    },
    {
      duration: 36,
      includedPools: 1,
      includedUsers: 2,
      cost: 594.0,
      description: ' 3 Years - AU$594.00  (= 36 months pre-paid)',
    },
  ],
};

export const billing_pools = {
  Australia: [
    {
      num: 1,
      description: '1 Pool - Included',
      price: 0,
    },
    {
      num: 2,
      description: '2 Pools - AUD$5.00 per month',
      price: 5.0,
    },
    {
      num: 3,
      description: '3 Pools - AUD$10.00 per month',
      price: 10.0,
    },
    {
      num: 4,
      description: '4 Pools - AUD$15.00 per month',
      price: 15.0,
    },
    {
      num: 5,
      description: '5 Pools - AUD$20.00 per month',
      price: 20.0,
    },
  ],
  'United States': [
    {
      num: 1,
      description: '1 Pool - Included',
      price: 0,
    },
    {
      num: 2,
      description: '2 Pools - USD$5.00 per month',
      price: 5.0,
    },
    {
      num: 3,
      description: '3 Pools - USD$10.00 per month',
      price: 10.0,
    },
    {
      num: 4,
      description: '4 Pools - USD$15.00 per month',
      price: 15.0,
    },
    {
      num: 5,
      description: '5 Pools - USD$20.00 per month',
      price: 20.0,
    },
  ],
};

export const billing_users = {
  Australia: [
    {
      num: 2,
      description: '2 Users - Included',
      price: 0,
    },
    {
      num: 3,
      description: '3 Users - AUD$5.00 per month',
      price: 5.0,
    },
    {
      num: 4,
      description: '4 Users - AUD$10.00 per month',
      price: 10.0,
    },
    {
      num: 5,
      description: '5 Users - AUD$15.00 per month',
      price: 15.0,
    },
  ],
  'United States': [
    {
      num: 2,
      description: '2 Users - Included',
      price: 0,
    },
    {
      num: 3,
      description: '3 Users - USD$5.00 per month',
      price: 5.0,
    },
    {
      num: 4,
      description: '4 Users - USD$10.00 per month',
      price: 10.0,
    },
    {
      num: 5,
      description: '5 Users - USD$15.00 per month',
      price: 15.0,
    },
  ],
};

export const durationCheck = (durationTime, duration, endsubscriptionDate) => {
  const condition =
    durationTime !== duration && new Date(endsubscriptionDate) > Date.now();
  if (condition) {
    toastMessage(
      'error',
      'You cannot change the subscription before Expiration Date'
    );
    return false;
  }
  return true;
};

export const poolCheck = (pools, oldPools, endsubscriptionDate) => {
  const condition =
    pools < oldPools && new Date(endsubscriptionDate) > Date.now();
  if (condition) {
    toastMessage(
      'error',
      'You cannot downgrade the Number of Pools before Expiration Date'
    );
    return false;
  }
  return true;
};

export const userCheck = (user, oldUsers, endsubscriptionDate) => {
  const condition =
    user < oldUsers && new Date(endsubscriptionDate) > Date.now();
  if (condition) {
    toastMessage(
      'error',
      'You cannot downgrade the Number of Users before Expiration Date'
    );
    return false;
  }
  return true;
};

export const checkRemainTime = (endDate) => {
  const endDateObject = new Date(endDate);
  const currentDate = new Date();
  // +1 was added in order to include the current month also
  const monthsDiff =
    (endDateObject.getFullYear() - currentDate.getFullYear()) * 12 +
    (endDateObject.getMonth() - currentDate.getMonth());

  return monthsDiff;
};
