/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PreviewInvoiceDetails({ previewUrl, loading }) {
  const [pageNumber, setPageNumber] = useState(1);
  return (
    <div>
      {previewUrl && !loading ? (
        <>
          <TransformWrapper>
            <TransformComponent>
              <Document
                file={previewUrl}
                onLoadError={(error) => console.log(`Error while loading document! ${error.message}`)}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </TransformComponent>
          </TransformWrapper>
        </>
      ) : <p>Loading PDF...</p>}
    </div>
  );
}
PreviewInvoiceDetails.propTypes = {

  previewUrl: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
export default PreviewInvoiceDetails;
