import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';

// mineral changes
const ScheduleMaintanance = ({
  setShowMaintenancePopup, handleMaintenanceSupplyPopup,
}) => {
  const [maintenanacePopupChangeValue, setMaintenanacePopupChangeValue] = useState('waterTest');

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            setShowMaintenancePopup(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleChange = (e) => {
    setMaintenanacePopupChangeValue(e.target.name);
  };
  const handleChange1 = (value) => {
    setMaintenanacePopupChangeValue(value);
  };

  return (
    <div
      className="duration-picker time-picker-modal update-source-popup calcium--supply-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup" ref={wrapperRef}>
          <div className="appointment-view">
            <h5>Save Water Test with this Scheduled Maintanance Appointment?</h5>
            <div className="pending-water-test" onClick={() => handleChange1('waterTest')}>
              <h3>
                Save this Water Test
              </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="waterTest"
                    onChange={handleChange}
                    checked={maintenanacePopupChangeValue === 'waterTest'}
                  />
                </div>
              </div>
            </div>
            <div className="pending-water-test" onClick={() => handleChange1('retest')}>
              <h3>Retest Chemicals Later</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="retest"
                    onChange={handleChange}
                    checked={maintenanacePopupChangeValue === 'retest'}
                  />
                </div>
              </div>
            </div>
            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={() => { setShowMaintenancePopup(false); }}>
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={() => { handleMaintenanceSupplyPopup(maintenanacePopupChangeValue); }}>
                Select
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
ScheduleMaintanance.propTypes = {
  setShowMaintenancePopup: PropTypes.func.isRequired,
  handleMaintenanceSupplyPopup: PropTypes.func.isRequired,
};
export default ScheduleMaintanance;
