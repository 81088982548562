import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { FaTrashCan } from 'react-icons/fa6';
import { FaRegEdit } from 'react-icons/fa';
import AddButton from '../components/addButtons';
import {
  MARKETING_SCOPE,
  PUBLIC_SCOPE,
  WEB_APP_SCOPE,
} from '../contants';
import AddTextModal from './addTextModal';
import RemoveModal from './removeModal';
import toastMessage from '../../../functions/toastMessage';
import { userInstance } from '../../../config/axios';

const DataCard = (props) => {
  const { title, handleRemoveBtn, handleEditText } = props;
  const isMouseOver = false;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '4px 8px',
      }}
    >
      <div className="d-flex gap-2 align-items-center">
        <IoDocumentAttachOutline size={18} />
        <span style={{ fontSize: '14px' }}>
          {title}
        </span>
      </div>
      <div className="d-flex align-items-center">
        <button
          type="button"
          onClick={handleEditText}
          style={{ background: 'transparent', border: 'none' }}
        >
          {!isMouseOver && <FaRegEdit size={16} />}
        </button>
        <button
          type="button"
          onClick={handleRemoveBtn}
          style={{ background: 'transparent', border: 'none' }}
        >
          {!isMouseOver && <FaTrashCan size={16} />}
        </button>
      </div>
    </div>
  );
};

DataCard.propTypes = {
  title: PropTypes.string.isRequired,
  handleRemoveBtn: PropTypes.func.isRequired,
  handleEditText: PropTypes.func.isRequired,
};

const BlankDataCard = () => {
  const emptyContent = 'No Data';
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '4px 8px',
      }}
    >
      <span style={{ fontSize: '14px' }}>
        {emptyContent}
      </span>
    </div>
  );
};

const UploadText = (props) => {
  const {
    uploadedTexts,
    setUploadedTexts,
    getUploadedTexts,
  } = props;

  const [show, setShow] = useState(false);
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [selectedScope, setSelectedScope] = useState(WEB_APP_SCOPE);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleRemoveModalClose = () => setRemoveModalShow(false);
  const handleRemoveModalShow = (data) => {
    setSelectedItem(data);
    setRemoveModalShow(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteItem = async (id) => {
    try {
      setIsRemoving(true);

      let response = await userInstance().delete(`/chatbotSettings/deleteUploadedText?id=${id}`);
      if (response.status === 200) {
        let _uploadedTexts = uploadedTexts.slice();
        let findIndex = _uploadedTexts.findIndex((item) => item._id === id);
        if (findIndex !== -1) {
          _uploadedTexts.splice(findIndex, 1);
          setUploadedTexts(_uploadedTexts);
          handleRemoveModalClose();
          toastMessage('success', 'Successfully deleted!');
        } else {
          toastMessage('error', 'There is no uploaded file with this id.');
        }
      }
    } catch (error) {
      console.log('error', error);
    }
    setIsRemoving(false);
  };

  // Handle change event to update the selected scope.
  const handleScopeChange = (event) => {
    setSelectedScope(event.target.value);
  };

  const handleEditText = (data) => {
    setSelectedItem(data);
    setIsEditMode(true);
    handleShow();
  };

  const handleAddText = () => {
    setSelectedItem(null);
    setIsEditMode(false);
    handleShow();
  };

  let filteredUploadedTexts = uploadedTexts.filter((item) => item.scope === selectedScope);

  return (
    <>
      <div className="chatbot-settings-form-sub-container p-4">
        <div>
          <span className="chatbot-settings-form-sub-title">Upload Text</span>
        </div>
        <div className="d-flex gap-3 mt-3">
          <div className="d-flex align-self-center gap-2">
            <input
              type="radio"
              name="chatbot-settings-text-options-radio-btn"
              value={PUBLIC_SCOPE}
              checked={selectedScope === PUBLIC_SCOPE}
              onChange={handleScopeChange}
            />
            <span className="chatbot-settings-form-label">Public</span>
          </div>
          <div className="d-flex align-self-center gap-2">
            <input
              type="radio"
              name="chatbot-settings-text-options-radio-btn"
              value={MARKETING_SCOPE}
              checked={selectedScope === MARKETING_SCOPE}
              onChange={handleScopeChange}
            />
            <span className="chatbot-settings-form-label">Marketing</span>
          </div>
          <div className="d-flex align-self-center gap-2">
            <input
              type="radio"
              name="chatbot-settings-text-options-radio-btn"
              value={WEB_APP_SCOPE}
              checked={selectedScope === WEB_APP_SCOPE}
              onChange={handleScopeChange}
            />
            <span className="chatbot-settings-form-label">Web App</span>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div>
            <span>Text List</span>
          </div>
          <AddButton handleClickBtn={handleAddText} />
        </div>
        <div
          className="mt-2"
          style={{
            height: '412px',
            overflowX: 'hidden',
            overflowY: filteredUploadedTexts.length > 0 ? 'scroll' : 'hidden',
            paddingRight: filteredUploadedTexts.length > 0 ? '8px' : 0,
          }}
        >
          <div className="d-flex flex-column gap-2">
            {
              filteredUploadedTexts.length > 0 ? filteredUploadedTexts.map((item) => (
                <DataCard
                  className={item._id}
                  key={item._id}
                  title={item.title}
                  handleEditText={() => handleEditText(item)}
                  handleRemoveBtn={() => handleRemoveModalShow(item)}
                />
              )) : (
                <BlankDataCard />
              )
            }
          </div>
        </div>
      </div>
      <AddTextModal show={show} handleClose={handleClose} uploadedTexts={uploadedTexts} setUploadedTexts={setUploadedTexts} scope={selectedScope} isEditMode={isEditMode} selectedItem={selectedItem} getUploadedTexts={getUploadedTexts} />
      <RemoveModal show={removeModalShow} handleClose={handleRemoveModalClose} title="Are you sure to remove this text?" icon={<IoDocumentAttachOutline size={18} />} selectedItem={selectedItem} handleDeleteItem={handleDeleteItem} isRemoving={isRemoving} />
    </>
  );
};

UploadText.propTypes = {
  uploadedTexts: PropTypes.arrayOf.isRequired,
  setUploadedTexts: PropTypes.func.isRequired,
  getUploadedTexts: PropTypes.func.isRequired,
};

export default UploadText;
