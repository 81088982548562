import moment from 'moment';

// function setDefaultTimeZone(timeZone) {
//   moment.tz.setDefault(timeZone);
// }

// // Set the default time zone for the entire app
// setDefaultTimeZone('Etc/GMT-10');

export default moment;
