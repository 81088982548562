import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';

function EditOrderQunatity({ show, handleClose }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal addClient-modal"
    >
      <div className="staff-close">
        <i className="fa fa-times" aria-hidden="true" onClick={handleClose} />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Add Additional Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          <Form>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicCompanyType" className="italic-text">
                  <Form.Label>
                    Product Name
                  </Form.Label>
                  <div className="select-box">
                    <i className="fas fa-sort-down" />
                    <Form.Control
                      as="select"
                      name="customerType"

                    >
                      <option value="Architect's Company">Architect</option>
                      <option value="Builder's Company">Builder</option>
                      <option value="Company">Company</option>
                      <option value="Consultant's Company">Consultant</option>
                      <option value="Householder">Householder</option>
                      <option value="Insurance Company">Insurance Company</option>
                      <option value="Landlord">Landlord</option>
                      <option value="Real Estate Agent">Real Estate Agent</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                  </div>
                </Form.Group>

              </div>

              <div className="col-md-6 col-6 half">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Order Quantity</Form.Label>
                  <div className="select-box">
                    <i className="fas fa-sort-down" />
                    <Form.Control
                      as="select"
                      name="invoiceSendType"
                    >
                      {/* <option value="Select">Select</option> */}
                      <option value="Send Invoices by SMS">Send Invoices by SMS</option>
                      <option value="Send Invoices by Email"> Send Invoices by Email</option>
                      <option value="Send Invoices by SMS & Email"> Send Invoices by SMS & Email</option>
                      <option value="Don't Automatically send">Don&apos;t Automatically send</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-6 col-6 halfright">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Forecast Period</Form.Label>
                  <div className="select-box">
                    <i className="fas fa-sort-down" />
                    <Form.Control
                      as="select"
                      name="invoiceSendType"
                    >
                      {/* <option value="Select">Select</option> */}
                      <option value="Send Invoices by SMS">Send Invoices by SMS</option>
                      <option value="Send Invoices by Email"> Send Invoices by Email</option>
                      <option value="Send Invoices by SMS & Email"> Send Invoices by SMS & Email</option>
                      <option value="Don't Automatically send">Don&apos;t Automatically send</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6 col-6 half">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Unit Price (per 2 kg)</Form.Label>
                  <Form.Control
                    type="text"
                    name="postcode"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 col-6 halfright">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Total Cost</Form.Label>
                  <Form.Control
                    type="text"
                    name="postcode"
                  />
                </Form.Group>
              </div>

              <div className="col-md-12">
                <div className="view-btn">
                  <Button
                    type="Submit"
                    className="btn btn-edit"
                  >
                    {' '}
                    Cancel
                    {' '}
                  </Button>
                  <Button
                    type="Submit"
                    className="btn btn-save"
                  >
                    {' '}
                    Save/Update
                    {' '}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
EditOrderQunatity.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditOrderQunatity;
