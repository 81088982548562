/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-quotes */
/* eslint-disable react/prop-types */
import React from 'react';
import { formatDate } from '../../functions/timeDate';

function durationText(ele) {
  return `${formatDate(ele?.duration?.startsubscriptionDate)} - ${formatDate(
    ele?.duration?.endsubscriptionDate
  )}`;
}

const Payments = ({ allTransaction }) => (
  <div className='row'>
    <div className='col-md-12'>
      <div className='pool-setup'>
        <div className='pool-chemistry-box chemistry-target m-0'>
          <div className='pool-analysis'>
            <div className='right-pool-analysis payments-billing w-100'>
              <h6>Date</h6>
              <h6>Amount</h6>
              <h6>Subscription Duration</h6>
              <h6>Transaction ID</h6>
              <h6>Status</h6>
            </div>
          </div>
          {allTransaction &&
            allTransaction?.length > 0 &&
            allTransaction?.map((ele) => {
              return (
                <div className='pool-analysis'>
                  <div className='right-pool-analysis payments-billing w-100'>
                    <p>{formatDate(ele?.createdAt)}</p>
                    <p>{ele?.amount}</p>
                    <p>{durationText(ele)}</p>
                    <p>{ele?.subscriptionId}</p>
                    <p>
                      {'  '}
                      {ele?.status.charAt(0).toUpperCase() +
                        ele?.status.slice(1)}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  </div>
);

export default Payments;
