import config from './config.json';

const envData = config[process.env.REACT_APP_ENV];

export const {
  server, client, ocrPath, chatPath,
} = envData;
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// export const server = 'http://54.147.208.211:3000';
// export const client = 'http://54.147.208.211:3001';
// export const server = 'http://localhost:3000';
// export const client = 'http://localhost:3001';
