/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import './calculator.css';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { displayCacliPrices, displayDollar } from './calculatorUtils';

function GreenPoolCalculationPrinting(props) {
  const {
    poolInputs, greenLevelData, serviceAnalysis, greenSplitName,
  } = props;

  const totaSellCost = (data) => {
    let totalcost = data.filter((el) => el.type === 'product').reduce((pre, next) => parseFloat((pre === 'N/A' ? 0 : pre) || 0) + parseFloat(next.renderCost ? (next.renderCost === 'N/A' ? 0 : next.renderCost) : 0), 0);
    // console.log('totalCost', totalcost);
    return (totalcost).toFixed(2);
  };

  const totalBuyCost = (data) => {
    let totalcost = data.filter((el) => el.type === 'product').reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.buyCostProduct ? (next.buyCostProduct === 'N/A' ? 0 : next.buyCostProduct) : 0), 0);
    return (totalcost || 0).toFixed(2);
  };

  return (
    <div className="content-container cs-container green-pool-cscontainer">
      <div className="cs-box">
        <div className="">
          {greenLevelData?.length > 0 && greenLevelData.map((el1) => (
            <>
              {el1?.service?.length === 0 ? null : (
                <>
                  <div className="row">
                    <div className="col-sm-10 sm-offset-1">
                      <div className="cs-heading new-cs-heading">
                        <h3>
                          {`CALCULATION ${serviceAnalysis === 'Sand Filter Media Replacement' ? 'SAND FILTER MEDIA' : serviceAnalysis === 'Drain Pool' ? 'DRAIN POOL' : serviceAnalysis === 'Green Pool' ? 'GREEN POOL' : 'BLACK-SPOT ALGAE'} ${el1.numericValue} Step`}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-10 sm-offset-1">
                      <Table responsive className="pool-size">
                        <tbody>
                          <tr>
                            <td>Pool Size : </td>
                            <td>
                              {poolInputs.poolWidth}
                              <span>X</span>
                              {poolInputs.poolHeight}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-10 sm-offset-1">
                      <Table responsive className="total-cost">
                        <tbody>
                          <tr>
                            <td width="90%">Buy Cost Total</td>
                            <td width="10%">$</td>
                            <td width="10%" className="calc-pool-cost">{parseFloat(totalBuyCost(el1.service)).toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="quantity-table">
                        <Table bordered>
                          <thead>
                            <tr>
                              <th>
                                {el1.value}
                                {' '}
                                Quantity
                              </th>
                              <th>BUY COST</th>
                              {/* <th>EX GST</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {el1.service.map((el) => (
                              <>
                                {(el?.type === 'product' && el?.subcategory !== '') || (greenSplitName === 'Drain' && el.subcategory === 'Drain Time Required')
                                  ? (
                                    <tr>
                                      <td>
                                        <div className="quantity">
                                          {greenSplitName === 'Drain' && el?.subcategory === 'Drain Time Required' ? 'Drain 100% of water' : el?.subcategory}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="buy-cost">
                                          <span>{displayDollar(el?.subcategory === 'Drain Time Required' ? 'N/A' : el.buyCostProduct)}</span>
                                          <span>{displayCacliPrices(el?.subcategory === 'Drain Time Required' ? 'N/A' : el.buyCostProduct, '')}</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                  : null}
                              </>
                            ))}
                            <tr>
                              <td>
                                <div className="sub-total">
                                  <span>TOTAL</span>
                                </div>
                              </td>
                              <td>
                                <div className="buy-cost">
                                  <span>$</span>

                                  <span>{totalBuyCost(el1.service)}</span>

                                </div>
                              </td>

                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>

                </>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
GreenPoolCalculationPrinting.prototype = {
  poolInputs: PropTypes.objectOf.isRequired,
  greenLevelData: PropTypes.arrayOf.isRequired,
  serviceAnalysis: PropTypes.string.isRequired,
};
export default GreenPoolCalculationPrinting;
