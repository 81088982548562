import React, { useState, useEffect, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from '../../functions/moment';
import { userInstance } from '../../config/axios';
import arrow from '../../assets/home/arrowdown.png';
import PreviewQuoteSecond from './previewQuotesSecond';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const QuoteListing = ({
  selectedClient, quoteType, skip, setSkip, pageCounts, setPageCounts, setQuoteType, showQuote, setShowQuote, setsaveQuoteType, addClient,
}) => {
  const { setClientAllData, clientAllData } = useContext(AuthDataContext);
  const history = useHistory();
  const [allQuotes, setAllQuotes] = useState([]);
  const limit = 5;
  const [preview, setPreview] = useState();
  const [previewURL, setpreviewURL] = useState();
  const [success, setSuccess] = useState(false);
  const handlePreview = (data) => {
    const { previewUrl } = data || {};
    setpreviewURL(previewUrl);
    setSuccess(false);
    setShowQuote(data);
    setPreview(!preview);
  };
  const handlePreview2 = (data) => {
    setPreview(!preview);
  };
  const getQuotes = async () => {
    try {
      const newQuoteType = quoteType.split(' ')[0].toLowerCase().trim();
      const response = await userInstance().get(`/quotes/getQuotes?limit=${limit}&skip=${skip}&status=${newQuoteType}&id=${selectedClient}`);
      const { quotes, count } = response.data;
      setAllQuotes(quotes);
      setPageCounts(count);
    } catch (error) {
      console.log('err', error);
      //     console.log(error);
    }
  };

  useEffect(() => {
    getQuotes();
  }, [skip, limit, quoteType, selectedClient]);

  const pageCount = Math.ceil(pageCounts / limit);
  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
  };
  const handleEditQuote = () => {
    setPreview(!preview);
    setQuoteType('New Quote');
    setsaveQuoteType('editquote');
  };
  const handleAddQuotes = () => {
    const {
      _id, dateData, staff, quoteDate,
    } = showQuote || {};
    const copyClientData = showQuote.client;
    const copyAddClient = { ...clientAllData };
    copyAddClient.clientAllDetails = copyClientData;
    setClientAllData(copyAddClient);
    localStorage.setItem('apptData', JSON.stringify(copyAddClient));
    history.push(`/addappointment?startDateTime=${quoteDate}&employee_id=${staff}&quoteId=${_id}`);
    // window.location.href = `/addappointment?startDateTime=${quoteDate}&employee_id=${staff}&quoteId=${_id}`;
  };
  return (
    <div className={`saved-quote-listing ${quoteType === 'Pending Quotes' ? 'pending-quotes' : 'saved-quotes'}`}>
      {allQuotes && allQuotes.length > 0 ? (
        <>
          {allQuotes && allQuotes.map((el) => (
            <div className="table-section client-quotes mobile-comp" onClick={() => (quoteType === 'Pending Quotes' ? handlePreview(el) : undefined)}>
              <div className="invoices-table-left">
                <div className="invoices-head">
                  <h6 className={`quotes-status ${el.status}`}>
                    {moment(el.quoteDate).format('D')}
                    {' '}
                    <h6>{moment(el.quoteDate).format('MMM')}</h6>
                  </h6>
                  <h6>
                    Quote
                    <br />
                    No.
                  </h6>
                  <h6>Client</h6>
                  <h6>Description</h6>

                </div>
                <div className="invoice-body">
                  <p className={`quotes-status ${el.status}`}>
                    {/* <h6>{moment(el.quoteDate).format('MMM')}</h6> */}
                    {' '}
                    {moment(el.quoteDate).format('YYYY')}
                  </p>
                  <p>{el.quoteNo}</p>
                  <p>
                    {el?.client?.firstname}
                    {' '}
                    {el?.client?.lastname}
                  </p>
                  <p className="quote-desc">
                    {el.services && el.services.map((service) => (
                      <span>{service.description}</span>
                    ))}
                  </p>

                </div>
              </div>
              {quoteType === 'Pending Quotes'
                ? (
                  <div className="invoices-table-right" onClick={() => (quoteType === 'Pending Quotes' ? handlePreview(el) : undefined)}>
                    <img src={arrow} alt="arrow" />
                  </div>
                )
                : ''}
            </div>
          ))}
          <div className="table-section client-quotes desktop-comp">
            <div className="invoices-table-left">
              <div className="invoices-head">
                <h6>Date</h6>
                <h6>
                  Quote No.
                </h6>
                <h6>Client</h6>
                <h6>Description</h6>
                <h6>Status</h6>
              </div>
            </div>
          </div>
          {allQuotes && allQuotes.map((el) => (
            <div className="table-section client-quotes desktop-comp">
              <div className="invoices-table-left">
                <div className="invoice-body" onClick={() => (quoteType === 'Pending Quotes' ? handlePreview(el) : undefined)}>
                  <p className={`quotes-status ${el.status}`}>
                    <h6>{moment(el.quoteDate).format('MMM')}</h6>
                    {' '}
                    {moment(el.quoteDate).format('YYYY')}
                  </p>
                  <p>{el.quoteNo}</p>
                  <p>
                    {el?.client?.firstname}
                    {' '}
                    {el?.client?.lastname}
                  </p>
                  <p>
                    {el.services && el.services.map((service) => (
                      <span>{service.description}</span>
                    ))}
                  </p>
                  <p className={`quotes-status ${el.status}`}>
                    {el.status}
                    <span>{moment(el.quoteDate).format('DD MMM YYYY')}</span>
                  </p>

                </div>
              </div>
              {quoteType === 'Pending Quotes'
                ? (
                  <div className="invoices-table-right" onClick={() => (quoteType === 'Pending Quotes' ? handlePreview(el) : undefined)}>
                    <img src={arrow} alt="arrow" />
                  </div>
                )
                : ''}
            </div>

          ))}

        </>
      ) : (
        <p>No Quotes </p>
      )}

      <div className="quote-listing-color">
        <p className="blue-date">Blue Date = Pending Quotes</p>
        <p className="green-date">Green Date = Quote became Job</p>
        <p className="red-date">Red Date = Quote outcome unknown</p>
      </div>
      {allQuotes.length > 0 ? (
        <div className="user-pagination">
          <ReactPaginate
            previousLabel="← Previous"
            nextLabel="Next →"
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName="pagination-chk"
            previousLinkClassName="pagination__link"
            nextLinkClassName="pagination__link"
            disabledClassName="pagination__link--disabled"
            activeClassName="pagination__link--active"
            breakLabel="..."
            marginPagesDisplayed={-2}
            pageRangeDisplayed={0}
          />
        </div>
      ) : (
        null
      )}
      {showQuote ? (
        <PreviewQuoteSecond
          showquote={preview}
          handleQuote={handlePreview2}
          previewURL={previewURL}
          setSuccess={setSuccess}
          addClient={[showQuote.client]}
          addedServices={showQuote.services}
          quoteDate={showQuote.quoteDate}
          summary={showQuote.totalData}
          quotesTotal={showQuote.quotesTotal}
          gst={showQuote.gst}
          quoteNo={showQuote.quoteNo}
          handleEditQuote={handleEditQuote}
          handleAddQuotes={handleAddQuotes}
        />
      ) : null}
    </div>
  );
};

QuoteListing.propTypes = {
  quoteType: PropTypes.string.isRequired,
  selectedClient: PropTypes.string.isRequired,
  skip: PropTypes.string.isRequired,
  setSkip: PropTypes.func.isRequired,
  pageCounts: PropTypes.string.isRequired,
  setPageCounts: PropTypes.func.isRequired,
  setQuoteType: PropTypes.func.isRequired,
  showQuote: PropTypes.func.isRequired,
  setShowQuote: PropTypes.func.isRequired,
  setsaveQuoteType: PropTypes.func.isRequired,
  addClient: PropTypes.arrayOf.isRequired,
};
export default QuoteListing;
