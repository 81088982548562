import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { dateFormats, getCalendarPath } from '../../functions/index';
import './notification.css';
import Loader from '../loader/loader';
import { userInstance } from '../../config/axios';

const Notification = ({
  todayList, oldList, show, setNotify, loading, OpennotificationView, setCheckNotification, reminder, todayReminderList, upcomingReminderList, noCompletedReminderList,
}) => {
  // console.log('todayReminderList===>', todayReminderList);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const handleOpen = () => {
    setNotify(!show);
    setCheckNotification(false);
  };
  const handleViewApp = (id) => {
    setNotify(!show);
    let urls = `${getCalendarPath()}&chemicaltype=opennotification&appId=${id}`;
    history.push(urls);
  };
  const HandleClick = async (id) => {
    const response = await userInstance().get('/appointment/updateisread');

    console.log('id=>', id);
    setNotify(!show);
    if (history.location.pathname === '/calendar') {
      OpennotificationView(id);
    } else {
      handleViewApp(id);
    }
  };
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleOpen}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="service-modal notification-modal"
      >
        <div className="staff-close">
          <i
            className="fa fa-times"
            onClick={handleOpen}
            aria-hidden="true"
          />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Reminders
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {loading ? <Loader />

              : (
                todayReminderList?.length > 0 || upcomingReminderList.length > 0 || noCompletedReminderList.length > 0 ? (
                  <div className="col-md-12">

                    {todayReminderList.length > 0
                      ? (

                        <div className="modal-form">

                          <div className="notification-content">
                            {todayReminderList.length > 0 && <h5>Today Reminders</h5> }
                            {todayReminderList && todayReminderList.map((el) => {
                              let titleArr = el.dateData[0]?.service[0]?.category === 'drain' ? 'Drain pool' : el.dateData[0]?.service[0]?.subcategory?.split('$');

                              let appointmentTime = el.dateData[0].service[0].startTime;
                              let serviceTime = el.dateData[0].service[0].start;

                              let { appointment, _id } = el || {};

                              return (
                                <>
                                  <div className="content" onClick={() => HandleClick(_id)}>
                                    <div className="notify-img">
                                      <i className="fa fa-address-card" aria-hidden="true" />
                                    </div>
                                    <div className="noti-content">
                                      {/* <h6>Appointment cancelled</h6> */}
                                      <p>
                                        Your
                                        {' '}
                                        {titleArr}

                                        {' '}
                                        <b>{ dateFormats(el.appointmentdate, appointmentTime, serviceTime)}</b>
                                        {' '}

                                        {/* {firstname || ''} */}
                                        {' '}
                                        {/* {lastname || ''} */}
                                        {el.is_read ? <i className="fa fa-dot-circle" aria-hidden="true" /> : '' }

                                      </p>
                                    </div>
                                  </div>
                                </>

                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                    {upcomingReminderList.length > 0
                      ? (
                        <div className="modal-form">

                          <div className="notification-content">
                            {upcomingReminderList.length > 0 && <h5>Upcoming Reminders</h5> }
                            {upcomingReminderList && upcomingReminderList.map((el) => {
                              let titleArr = el.dateData[0]?.service[0]?.category === 'drain' ? 'Drain pool' : el.dateData[0]?.service[0]?.subcategory?.split('$');

                              let appointmentTime = el.dateData[0].service[0].startTime;
                              let serviceTime = el.dateData[0].service[0].start;

                              let { appointment, _id } = el || {};
                              return (
                                <>
                                  <div className="content" onClick={() => HandleClick(_id)}>
                                    <div className="notify-img">
                                      <i className="fa fa-address-card" aria-hidden="true" />
                                    </div>
                                    <div className="noti-content">
                                      {/* <h6>Appointment cancelled</h6> */}
                                      <p>
                                        Your
                                        {' '}
                                        {titleArr}

                                        {' '}
                                        <b>{ dateFormats(el.appointmentdate, appointmentTime, serviceTime)}</b>
                                        {' '}
                                        {el.is_read ? <i className="fa fa-dot-circle" aria-hidden="true" /> : '' }
                                        {/* {firstname || ''} */}
                                        {' '}
                                        {/* {lastname || ''} */}
                                      </p>
                                    </div>
                                  </div>
                                </>

                              );
                            })}

                          </div>
                        </div>
                      ) : null}
                    {noCompletedReminderList.length > 0
                      ? (
                        <div className="modal-form">

                          <div className="notification-content">
                            {noCompletedReminderList.length > 0 && <h5>Outstanding Maintenance</h5> }
                            {noCompletedReminderList && noCompletedReminderList.map((el) => {
                              let titleArr = el.dateData[0]?.service[0]?.category === 'drain' ? 'Drain pool' : el.dateData[0]?.service[0]?.subcategory?.split('$');

                              let appointmentTime = el.dateData[0]?.service[0]?.startTime;
                              let serviceTime = el.dateData[0]?.service[0]?.start;

                              let { appointment, _id } = el || {};

                              return (
                                <>
                                  <div className="content" onClick={() => HandleClick(_id)}>
                                    <div className="notify-img">
                                      <i className="fa fa-address-card" aria-hidden="true" />
                                    </div>
                                    <div className="noti-content">
                                      {/* <h6>Appointment cancelled</h6> */}
                                      <p>
                                        Your
                                        {' '}
                                        {titleArr}

                                        {' '}
                                        <b>{ dateFormats(el.appointmentdate, appointmentTime, serviceTime)}</b>
                                        {' '}
                                        {el.is_read ? <i className="fa fa-dot-circle" aria-hidden="true" /> : '' }
                                        {/* {firstname || ''} */}
                                        {' '}
                                        {/* {lastname || ''} */}
                                      </p>
                                    </div>
                                  </div>
                                </>

                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                  </div>
                ) : (
                  <div className="modal-form">
                    <div className="notification-content">
                      {/* <i className="fa fa-bell" aria-hidden="true" /> */}
                      <p>No reminders yet!</p>

                    </div>
                  </div>
                )
              )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
Notification.propTypes = {
  todayList: PropTypes.arrayOf.isRequired,
  oldList: PropTypes.arrayOf.isRequired,
  show: PropTypes.bool.isRequired,
  setNotify: PropTypes.func.isRequired,
  OpennotificationView: PropTypes.func.isRequired,
  setCheckNotification: PropTypes.func.isRequired,
  reminder: PropTypes.func.isRequired,
  todayReminderList: PropTypes.func.isRequired,
  upcomingReminderList: PropTypes.func.isRequired,
  noCompletedReminderList: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,

};
export default Notification;
