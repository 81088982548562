/* eslint-disable no-fallthrough */

import React, { useState, useContext, useEffect } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import showmore from '../../assets/home/showmoreblue.png';
import InventoryProductList from './inventoryProductList';
import SearchInventory from './searchinventory';
import DisplayInventory from './displayInventory';
import NonInventoryList from './NonInventoryList';
import InventoryAdjustment from './inventroyAdjustment';
import SearchResult from './searchResult';
import InventorySearchDetails from './inventorySearchDetails';
import PredictiveInventorySearch from './predictiveInventorySearch';
import PredictiveOrderingDetail from './predictiveOrderingDetail';
import Loader from '../loader/loader';
import './inventory.css';
import './web.css';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { showErrMsg } from '../../functions/index';
import { userInstance } from '../../config/axios';
import target from '../../assets/home/icon/target.png';
import OrderProduct from './orderProduct';
import OrderInventory from './orderInventory';
import AddProduct from './addProduct';
import RemoveProduct from './removeProduct';
import Histories from './histories';

const Inventory = () => {
  const { userData: { industryId, role } } = useContext(AuthDataContext);
  const [view, setView] = useState('InventoryProductList');
  const [searchData, setSearchData] = useState([]);
  const [productList, setProductList] = useState();
  const [searchDataPredective, setSearchDataPredective] = useState([]);
  const [singleProduct, setSingleProduct] = useState('');
  const [loading, setLoading] = useState(false);
  const [servicesList, setServices] = useState([]);
  const [nonInvProductList, setNonInvProductList] = useState([]);
  const [previousView, setPreviousView] = useState('InventoryProductList');
  const [chemicalsList, setChemicalsList] = useState();
  const [searchPopup, setsearchPopup] = useState(false);
  const [taxData, setTaxData] = useState({
    chemicalsTax: 1,
    labourTax: 1,
    actualLabourTax: 0,
    actualChemicalTax: 0,
  });

  const getServices = async () => {
    try {
      setLoading(true);
      const dataResponse = await Promise.allSettled([userInstance().get(`/inventory/getInventory?industryId=${industryId?._id}`),
        userInstance().get(
          '/inventory/getPreditiveOrderChemicals?view=taxApplicable',
        ),
      ]);
      const [response, responseTwo] = dataResponse.map((el) => el.value);

      if (responseTwo) {
        let taxApplicableData = responseTwo?.data?.poolData?.taxApplicable;

        if (taxApplicableData && role !== 'professional-user') {
          const { chemicalsTax, labourTax } = taxApplicableData || { chemicalsTax: 0, labourTax: 0 };
          const chemTax = chemicalsTax ? (parseFloat(chemicalsTax) + 100) / 100 : 1;
          const labTax = labourTax ? (parseFloat(labourTax) + 100) / 100 : 1;
          const actualChemicalTax = chemicalsTax || 0;
          const actualLabourTax = labourTax || 0;
          setTaxData({
            ...taxData, chemicalsTax: chemTax, labourTax: labTax, actualChemicalTax, actualLabourTax,
          });
        }
      }

      setLoading(false);
      const { products, msg } = response.data;
      if (msg === 'Success') {
        setServices(products);
        setChemicalsList(products.find((el) => el.category === 'Chemicals'));
      }
    } catch (error) {
      showErrMsg(error);
    }
  };

  const getNonInventory = async () => {
    const response = await userInstance().get(`/inventory/getNonInventory?industryId=${industryId?._id}`);
    const { status, data } = response;
    if (status === 200) {
      setNonInvProductList(data.data);
    }
  };

  const updateView = (key, type) => {
    if (type !== 'goBack') {
      setView(key);
    } else {
      setView(previousView);
    }
    if (key !== 'InventoryAdjustment') {
      setPreviousView(key);
    }
  };
  useEffect(() => {
    if (view === 'InventoryProductList' || view === 'NonInventoryProductList') {
      getServices();
      getNonInventory();
    }
  }, [view]);

  const renderView = () => {
    let componenettorender = null;
    switch (view) {
      case 'InventoryProductList':
        componenettorender = (
          <InventoryProductList servicesList={servicesList} getServices={getServices} setServices={setServices} />
        );
        break;
      case 'NonInventoryProductList':
        componenettorender = (
          <NonInventoryList updateView={updateView} getNonInventory={getNonInventory} nonInvProductList={nonInvProductList} setNonInvProductList={setNonInvProductList} />
        );
        break;
      case 'InventorySearch':
        componenettorender = (
          <SearchInventory updateView={updateView} setSearchData={setSearchData} />
        );
        break;
      case 'InventorySearchResult':
        componenettorender = (
          <SearchResult handleClose={handleClose} updateView={updateView} searchData={searchData} setProductList={setProductList} singleProductId={singleProduct} searchPopup={searchPopup} />
        );

        break;
      case 'PredictiveOrderingSummary':
        componenettorender = (
          <PredictiveInventorySearch updateView={updateView} setSearchDataPredective={setSearchDataPredective} setLoading={setLoading} />
        );
        break;
      case 'InventoryAdjustment':
        componenettorender = (
          <InventoryAdjustment updateView={updateView} />
        );
        break;

      case 'Predictive Ordering Detail':
        componenettorender = (
          <PredictiveOrderingDetail updateView={updateView} searchDataPredective={searchDataPredective} />
        );
        break;
      case 'orderProduct':
        componenettorender = (
          <OrderInventory updateView={updateView} taxData={taxData} displayPredictiveChemicals={chemicalsList?.subCategory || []} />
        );
        break;
      case 'addProduct':
        componenettorender = (
          <AddProduct updateView={updateView} />
        );
        break;
      case 'removeProduct':
        componenettorender = (
          <RemoveProduct updateView={updateView} />
        );
        break;
      case 'histories':
        componenettorender = (
          <Histories updateView={updateView} />
        );
        break;
      case 'Inventory Adjustment':
        componenettorender = (
          <InventoryAdjustment />
        );
      default:
    }
    return componenettorender;
  };
  const handleClose = () => {
    setsearchPopup(!searchPopup);
  };
  return (
    <AdminWrapper>
      { !loading ? (
        <>
          <div className="reports-page staff-container inventory-page">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-titles-mob">
                    <h3>Inventory</h3>
                    <div className="content-dropdown">
                      <div className="client-option service-edit-icon">
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <img src={showmore} alt="show" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('InventoryProductList')}>
                                <span>Inventory Product List</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('NonInventoryProductList')}>
                                <span>Non-Inventory Product List</span>
                              </div>
                            </Dropdown.Item>
                            {/* <Dropdown.Item>
                              <div className="action" onClick={() => updateView('orderProduct')}>
                                <span>Order Product</span>
                              </div>
                            </Dropdown.Item> */}
                            {/* <Dropdown.Item>
                              <div className="action" onClick={() => updateView('addProduct')}>
                                <span>Add Product in Stock</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('removeProduct')}>
                                <span>Remove Product in Stock</span>
                              </div>
                            </Dropdown.Item> */}
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('InventoryAdjustment')}>
                                <span>Inventory Adjustment</span>
                              </div>
                            </Dropdown.Item>
                            {/* <Dropdown.Item>
                              <div className="action" onClick={() => updateView('histories')}>
                                <span>Histories</span>
                              </div>
                            </Dropdown.Item> */}
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('InventorySearch')}>
                                <span>Inventory Search</span>
                              </div>
                            </Dropdown.Item>
                            {/* <Dropdown.Item>
                              <div className="action" onClick={() => updateView('PredictiveOrderingSummary')}>
                                <span>Predictive Ordering Summary</span>
                              </div>
                            </Dropdown.Item> */}

                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="main-titles-desktop">
                    <div className="dropdown-option-section">
                      <div className={`option-list ${view === 'InventoryProductList' ? 'active-menu' : ''}`} onClick={() => updateView('InventoryProductList')}>
                        <img src={target} alt="edit" />
                        Inventory Product List
                      </div>
                      <div className={`option-list ${view === 'NonInventoryProductList' ? 'active-menu' : ''}`} onClick={() => updateView('NonInventoryProductList')}>
                        <img src={target} alt="edit" />
                        Non-Inventory Product List
                      </div>
                      <div className={`option-list ${view === 'InventorySearch' ? 'active-menu' : ''}`} onClick={() => updateView('InventorySearch')}>
                        <img src={target} alt="edit" />
                        Inventory Search
                      </div>
                      <div className={`option-list ${view === 'PredictiveOrderingSummary' ? 'active-menu' : ''}`} onClick={() => updateView('PredictiveOrderingSummary')}>
                        <span>Predictive Ordering Summary</span>
                      </div>
                      <div className={`option-list ${view === 'InventoryAdjustment' ? 'active-menu' : ''}`} onClick={() => updateView('InventoryAdjustment')}>
                        <span>Inventory Adjustment</span>
                      </div>
                    </div>
                  </div>
                  { renderView()}
                </div>
              </div>
            </div>
          </div>
          <InventorySearchDetails updateView={updateView} productList={productList} setProductList={setProductList} setSingleProduct={setSingleProduct} searchPopup={searchPopup} handleClose={handleClose} />
        </>
      ) : <Loader />}
    </AdminWrapper>

  );
};
export default Inventory;
