/* eslint-disable max-len */
import React from 'react';
import {
  Modal, Form, Col, Row, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { onKeyDownNumber } from '../../functions/index';

const Chlorinator = ({
  chlorinator, handleChlorinator, chlorinatorData, handleChlModelData, handleAddModel, handleEditModel, selectedIndexModel, handleOrp, orpProbe,
  limitNumber, handleChlorinatorNumberChange, error,
}) => (
  <Modal
    size="lg"
    show={chlorinator}
    onHide={handleChlorinator}
    aria-labelledby="contained-modal-title-vcenter"
    top
    centered
    className="staff-modal miniral-popup"
  >
    <div className="staff-close">
      <i
        className="fa fa-times"
        onClick={handleChlorinator}
        aria-hidden="true"
      />
    </div>
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Chlorinator Model
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="staff-form">
        <Form>
          <div className="row">
            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-1" className="italic-text">
                <Form.Label>
                  {' '}
                  Chlorinator Model name
                  {' '}
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={chlorinatorData.name}
                  onChange={handleChlModelData}
                  placeholder="Enter Chlorinator Model Name"
                  className={`${
                    error === 'name'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-2" className="italic-text">
                <Form.Label>
                  Manufacturer Recommended Salt Level
                  {' '}
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="saltLevel"
                  value={chlorinatorData.saltLevel}
                  onChange={handleChlModelData}
                  onKeyPress={(e) => onKeyDownNumber(e)}
                  placeholder="Enter Manufacturer Recommended Salt Level"
                  className={`${
                    error === 'saltLevel'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-6" className="italic-text">
                <Form.Label>
                  OK - Level
                  {' '}
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <span className="cholinator-text">-</span>
                <NumberFormat
                  type="text"
                  name="okNegative"
                  value={chlorinatorData.okNegative}
                  onChange={handleChlorinatorNumberChange}
                  onKeyPress={(e) => onKeyDownNumber(e)}
                  placeholder="Enter Ok - Level"
                  isAllowed={limitNumber}
                  // className="form-control"
                  className={`form-control ${
                    error === 'okNegative'
                      ? 'error-text'
                      : ''
                  }`}
                />
                <span>%</span>
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-7" className="italic-text">
                <Form.Label>
                  OK + Level
                  {' '}
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <NumberFormat
                  type="text"
                  name="okPositive"
                  value={chlorinatorData.okPositive}
                  onChange={handleChlorinatorNumberChange}
                  onKeyPress={(e) => onKeyDownNumber(e)}
                  isAllowed={limitNumber}
                  placeholder="Enter Ok + Level"
                  // className="form-control"
                  className={`form-control ${
                    error === 'okPositive'
                      ? 'error-text'
                      : ''
                  }`}
                />
                <span>%</span>
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-7" className="italic-text">
                <Form.Label>
                  Drain Point
                  {' '}
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <span className="cholinator-text">+</span>
                <NumberFormat
                  type="text"
                  name="drainPoint"
                  value={chlorinatorData.drainPoint}
                  onChange={handleChlorinatorNumberChange}
                  onKeyPress={(e) => onKeyDownNumber(e)}
                  isAllowed={limitNumber}
                  placeholder="Enter Drain Point"
                  // className="form-control"
                  className={`form-control ${
                    error === 'drainPoint'
                      ? 'error-text'
                      : ''
                  }`}
                />
                <span>%</span>
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group controlId="formBasicHiddenEmployees">
                <Form.Label>
                  ORP Probe Installed
                </Form.Label>
                <div className="select-box">
                  {/* <i className="fas fa-sort-down" /> */}
                  <Form.Control
                    as="select"
                    name="probeInstallled"
                    onChange={(e) => handleOrp(e.target.value)}
                    value={chlorinatorData.orp}
                  >
                    <option label="No" name="No" value="No">
                      No
                    </option>
                    <option label="Yes" name="Yes" value="Yes">
                      Yes
                    </option>
                  </Form.Control>
                </div>
              </Form.Group>
            </div>
            {chlorinatorData.orp === 'Yes' && (
            <>
              <div className="col-md-6">
                <Form.Group controlId="formBasicHiddenEmployees" className="italic-text">
                  <Form.Label>
                    Manufacturer Recommended Stabiliser Level
                    {' '}
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="recmndStabiliserLvl"
                    value={chlorinatorData.recmndStabiliserLvl}
                    onChange={handleChlModelData}
                    placeholder="Enter Manufacturer Recommended Stabiliser Level"
                    className={`${
                      error === 'recmndStabiliserLvl'
                        ? 'error-text'
                        : ''
                    }`}
                    ss
                  />
                </Form.Group>
              </div>
            </>

            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn">
                <Button onClick={handleChlorinator} className="btn btn-edit"> Cancel </Button>
                <Button type="Submit" className="btn btn-save" onClick={selectedIndexModel !== null ? handleEditModel : handleAddModel}> Save/Update </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>

    </Modal.Body>
  </Modal>

);

Chlorinator.propTypes = {
  chlorinator: PropTypes.func.isRequired,
  handleChlorinator: PropTypes.func.isRequired,
  handleChlModelData: PropTypes.func.isRequired,
  chlorinatorData: PropTypes.func.isRequired,
  handleAddModel: PropTypes.func.isRequired,
  handleEditModel: PropTypes.func.isRequired,
  selectedIndexModel: PropTypes.func.isRequired,
  handleOrp: PropTypes.func.isRequired,
  orpProbe: PropTypes.bool.isRequired,
  limitNumber: PropTypes.bool.isRequired,
  handleChlorinatorNumberChange: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
};

export default Chlorinator;
