import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import arrow from '../../assets/home/arrowdown.png';
import './report.css';
import ReportsAppointments from './appoinments';
import ReportsListing from './reportsListing';
import CustomerPayments from './customerPayments';
import showmore from '../../assets/home/showmoreblue.png';
import DebtorsCreditors from './debtorsCreditors';
import Inventory from './inventory';
import Sales from './sales';
import Purchases from './purchases';
import Transaction from './transaction';
import Loader from '../loader/loader';

const Reports = () => {
  const [view, setView] = useState('Reports List');
  const [name, setName] = useState('Reports');
  const [loderStatus, setLoderStatus] = useState(false);
  console.log('view---', { view, name });
  const renderView = () => {
    let componenettorender = null;
    switch (view) {
      case 'Reports List':
        componenettorender = (
          <ReportsListing updateView={updateView} setLoderStatus={setLoderStatus} />
        );
        break;
      case 'Scheduled Maintenance':
        componenettorender = (
          <ReportsAppointments setLoderStatus={setLoderStatus} />
        );
        break;
      case 'Customer Payments':
        componenettorender = (
          <CustomerPayments setLoderStatus={setLoderStatus} />
        );
        break;
      case 'Debtors & Creditors':
        componenettorender = (
          <DebtorsCreditors setLoderStatus={setLoderStatus} />
        );
        break;
      case 'Inventory':
        componenettorender = (
          <Inventory />
        );
        break;
      case 'Sales':
        componenettorender = (
          <Sales />
        );
        break;
      case 'Purchases':
        componenettorender = (
          <Purchases />
        );
        break;
      case 'Transactions':
        componenettorender = (
          <Transaction />
        );
        break;
      default:
        break;
    }
    return componenettorender;
  };

  const updateView = (key) => {
    setView(key);
    setName(key);
    // setLoderStatus(true);
  };
  // console.log('this si the name', name);
  return (
    <AdminWrapper>
      {loderStatus
        ? <Loader />
        : (
          <div className="reports-page staff-container">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-titles-mob">
                    <h3>{name}</h3>

                    <div className="content-dropdown">
                      <div className="client-option service-edit-icon">
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <img src={showmore} alt="show" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('Reports List')}>
                                <span>Reports List</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('Scheduled Maintenance')}>
                                <span>Scheduled Maintenance</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('Purchases')}>
                                <span>Purchases</span>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  {renderView()}
                </div>
              </div>
            </div>
          </div>
        )}
    </AdminWrapper>
  );
};
export default Reports;
