import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from '../header/header';
import Footer from '../footer/footer';
import HeaderDataProvider from '../../contexts/HeaderContext';
import './layout.css';

const Layout = ({ children, header, footer }) => {
  const [addClass, setClass] = useState('');
  useEffect(() => {
    setClass(window.location.pathname);
  }, []);
  return (
    <div className={`wrapper ${addClass === '/' ? 'homepage' : ''}`}>

      <div className="header-wrapper">
        {header && (
        <HeaderDataProvider>
          <Header />
        </HeaderDataProvider>
        )}
      </div>

      <div className="main-content">
        {children}
      </div>

      <div className="footer-wrapper">
        {footer && <Footer />}
      </div>

    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
  header: PropTypes.bool,
  footer: PropTypes.bool,
};

Layout.defaultProps = {
  children: null,
  header: PropTypes.bool,
  footer: PropTypes.bool,
};
export default Layout;
