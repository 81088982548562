import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Button from '../components/button';
import LoadingButton from '../components/loadingButton';

const RemoveModal = (props) => {
  const {
    isRemoving,
    show,
    handleClose,
    title,
    icon,
    selectedItem,
    handleDeleteItem,
  } = props;

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="chatbot-settings-remove-modal-container"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeLabel="close" closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <span style={{ fontSize: '16px' }}>{title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-item-center align-self-center gap-2">
            <div>{icon}</div>
            <div><span style={{ fontSize: '14px' }}>{selectedItem !== null ? selectedItem.title : ''}</span></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button label="Cancel" handleClickBtn={handleClose} disabled={isRemoving} />
            <LoadingButton label="Remove" handleClickBtn={() => handleDeleteItem(selectedItem !== null ? selectedItem._id : '')} isLoading={isRemoving} />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

RemoveModal.propTypes = {
  isRemoving: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  selectedItem: PropTypes.objectOf.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
};

export default RemoveModal;
