/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { coverBlankField } from '../../functions';

const ChemicalCost = ({
  handleChange, state, handleRequest, handleRevertViewUpdate, handleNewRevertDefaultUpdate, defaultViewState, change, isUsImperial, removeInCaseOfImperial, checkBlankFields,
}) => (
  <div className="pool-setup mineral-pool chemical-setup">
    <div className="target-heading">
      <h6>Chemical Costs</h6>
    </div>
    <div className="pool-chemistry-box chemistry-target">
      {/* {console.log('defaultViewState', defaultViewState)} */}
      <div className="pool-analysis">
        <div className="left-pool-analysis"><h6>Chemical Costs</h6></div>
        <div className="right-pool-analysis">
          <h6>Unit</h6>
          <h6>Buy Cost</h6>
          {/* <h6>Sell Price</h6> */}
        </div>
      </div>

      {Array.isArray(state) ? (
        <>
          {state.map((chemicalCost, i) => (
            <>
              {/* {console.log('removeInCaseOfImperial?.includes(chemicalCost.renderName)', chemicalCost.renderName, removeInCaseOfImperial, removeInCaseOfImperial.includes(chemicalCost.renderName))} */}
              {(isUsImperial && removeInCaseOfImperial.includes(chemicalCost.renderName)) ? null
                : (
                  <div className="pool-analysis">
                    <div className="left-pool-analysis">
                      <h5>
                        {(chemicalCost?.renderName === 'Salt (20 kg)' ? isUsImperial ? 'Salt (40 lb)' : chemicalCost.renderName
                          : chemicalCost?.renderName === 'Sand Media (20 kg)' ? isUsImperial ? 'Sand Media (50 lb)' : chemicalCost.renderName : chemicalCost.renderName)}
                      </h5>
                    </div>
                    <div className="right-pool-analysis target-level-input">
                      <Form>
                        <Form.Group controlId="formBasicInput">
                          <h6>{isUsImperial ? chemicalCost.unit === 'per kg' ? 'per lb' : 'per fl oz' : chemicalCost.unit}</h6>
                        </Form.Group>
                      </Form>
                      <Form>
                        <Form.Group controlId="formBasicInput">
                          <Form.Control
                            type="number"
                            name="buycost"
                            // onChange={(e) => handleChange(e, i)}
                            value={chemicalCost.buycost}
                            className={coverBlankField(chemicalCost?.buycost, chemicalCost?.name, checkBlankFields)}
                          />
                        </Form.Group>
                      </Form>
                      {/* <Form>
                        <Form.Group controlId="formBasicInput">
                          <Form.Control
                            type="number"
                            name="sellprice"
                            onChange={(e) => handleChange(e, i)}
                            value={chemicalCost.sellprice}
                          />
                        </Form.Group>
                      </Form> */}
                    </div>
                  </div>
                )}

            </>
          ))}
        </>
      ) : null}
      <div className="view-btn">
        {/* <Button type="submit" className="btn btn-edit" onClick={(e) => handleRevertViewUpdate(e, state)}>Revert To Default</Button>
         */}
        <div className="client-option service-edit-icon">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-edi">
              View Other Settings
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => handleRevertViewUpdate(e, state)}>
                <div className="action">
                  Restore Original Settings
                </div>
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => handleNewRevertDefaultUpdate(e, state)}>
                <div className="action">
                  Revert to Previous Saved Default
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Button type="submit" className="btn btn-save" onClick={handleRequest}>
          Save as Default
        </Button>
      </div>
    </div>

  </div>
);
ChemicalCost.propTypes = {
  handleRequest: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  state: PropTypes.isRequired,
  handleRevertViewUpdate: PropTypes.func.isRequired,
  handleNewRevertDefaultUpdate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  removeInCaseOfImperial: PropTypes.arrayOf.isRequired,
  checkBlankFields: PropTypes.arrayOf.isRequired,
};

export default ChemicalCost;
