/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React, { useState } from 'react';
import {
  Form,
  Table,
  Button,
  Accordion,
  Card,
  Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
// import ReactExport from 'react-export-excel';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useHistory } from 'react-router-dom';
import whiteedit from '../../assets/home/white-edit.png';
import whitedelete from '../../assets/home/white-delete.png';
import showmore from '../../assets/home/showmoreblue.png';
import DeletePopUp from '../DeletePopUp/index';
import { queryString } from '../../functions';

function StaffTable(props) {
  // const { ExcelFile } = ReactExport;
  // const { ExcelSheet } = ReactExport.ExcelFile;
  // const { ExcelColumn } = ReactExport.ExcelFile;
  const { id: parID, skipValue } = queryString();
  const history = useHistory();
  const {
    staffList,
    HandleOpenCloseTable,
    handleFormModal,
    handleEditStaff,
    removeStaff,
    showHide,
    setShowHide,
    handleManageVisible,
    searchTxt,
    searchStaff,
    resendPassword,
    prUser,
  } = props;
  const [selectedNestedAccordion, setSelectedNestedAccordion] = useState([]);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [delData, setDelData] = useState();

  const PopUpText = 'Delete User?';

  const paraText = 'Are you sure to do this?';

  const handlePopUpChange = (val) => {
    if (val === 'Yes') {
      removeStaff(delData);
    }
    setDeletePopUp(false);
  };

  // const submitRemove = (id) => {
  //   confirmAlert({
  //     title: 'Delete Staff?',
  //     message: 'Are you sure to do this?',
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => removeStaff(id),
  //       },
  //       {
  //         label: 'No',
  //         onClick: () => ' No ',
  //       },
  //     ],
  //   });
  // };
  const submitRemove = (id) => {
    setDeletePopUp(true);
    setDelData(id);
  };

  const handleNestedAccordion = (e) => {
    if (selectedNestedAccordion && selectedNestedAccordion.includes(e)) {
      const data = selectedNestedAccordion.filter((el) => el !== e);
      return setSelectedNestedAccordion(data);
    }
    return setSelectedNestedAccordion([...selectedNestedAccordion, e]);
  };
  const camelCase = (str) => str.substring(0, 1).toUpperCase() + str.substring(1);

  const renderStaff = (staff, i) => (
    <Accordion className="mob-accordion users-page">
      <Card>
        <Card.Header>
          <div className="staff-main-heading">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey={staff._id.toString()}
              onClick={() => handleNestedAccordion(staff._id.toString())}
            >
              {/* <i className="fas fa-chevron-circle-down" /> */}
              <i
                className={`fas ${
                  selectedNestedAccordion.includes(staff._id.toString())
                    ? 'far fa-chevron-circle-down'
                    : 'far fa-chevron-circle-up'
                }`}
              />
              <span>{`${staff.firstname} ${staff.lastname}`}</span>
            </Accordion.Toggle>
            {/* {!parID ? ( */}
            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div
                      className="action"
                      onClick={() => resendPassword(staff._id)}
                    >
                      <span>
                        <i className="fa fa-info-circle" aria-hidden="true" />
                      </span>
                      <span> Resend Login Details</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* ) : null} */}
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey={staff._id.toString()}>
          <Card.Body>
            <ul className="staff-list">
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Mobile</h3>
                  <h5>
                    <a className="mobile" href={`tel:${staff.mobileNo}`}>
                      {`${staff.mobileNo}`}
                    </a>
                  </h5>
                </div>
              </li>
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Email</h3>
                  <h5>
                    <a className="email" href={`mailto:${staff.email}`}>
                      {staff.email}
                    </a>
                  </h5>
                </div>
              </li>
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Access Level</h3>
                  <h5>{camelCase(staff.accessLevel)}</h5>
                </div>
              </li>             
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Actions</h3>
                  <div className="action-btn">
                    <Button
                      className="edit-btn"
                      onClick={() => handleEditStaff(staff)}
                    >
                      <img src={whiteedit} alt="edit-img" />
                    </Button>
                    {staff._id !== localStorage.getItem('loginStaff') && (
                    <>
                      {!staff.hide ? (
                        <Button
                          className="eye-btn"
                          onClick={() => handleManageVisible({
                            _id: staff._id,
                            hide: true,
                          })}
                        >
                          <i className="fas fa-eye" />
                        </Button>
                      ) : staff.hide && showHide ? (
                        <Button
                          className="eyehide-btn"
                          onClick={() => handleManageVisible({
                            _id: staff._id,
                            hide: false,
                          })}
                        >
                          <i className="fas fa-eye-slash" />
                        </Button>
                      ) : (
                        <Button
                          className="eyehide-btn"
                          onClick={() => handleManageVisible({
                            _id: staff._id,
                            hide: false,
                          })}
                        >
                          <i className="fas fa-eye-slash" />
                        </Button>
                      )}
                    </>
                    )}
                    {!staff.isOwner
                      && staff._id !== localStorage.getItem('loginStaff') ? (
                        <Button
                          className="delete-btn"
                          onClick={() => submitRemove(staff._id)}
                        >
                          <img src={whitedelete} alt="edit-img" />
                        </Button>
                      ) : null}
                  </div>
                </div>
              </li>
              
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  const filterColumns = (data) => {
    if (staffList?.length) {
      // Get column names
      const columns = Object.keys(data[0]);
      // console.log('columns', columns);
      // console.log('columns', columns);

      // Remove by key (firstname)
      const filterColsByKey = columns.filter(
        (c) => c !== 'appointments'
          && c !== 'shift'
          && c !== 'userid'
          && c !== 'blocktime'
          && c !== 'hexToken'
          && c !== 'newGeneratedPassword'
          && c !== 'firstLogin'
          && c !== 'isOwner',
      );

      // OR use the below line instead of the above if you want to filter by index
      // columns.shift()

      return filterColsByKey; // OR return columns
    }
    return [];
  };

  const backToPreviousPage = () => {
    history.push(`/subscriptions?clientid=${parID}&skipValuee=${skipValue}`);
  };

  const [dropDownOption, setDropDownOption] = useState(parID ? 'Current Subscriber Users' : 'Current Users');

  const handleDropwDown = (name, value) => {
    setDropDownOption(name);
    setShowHide(value);
  };

  return (
    <>
      {/* {!parID ? null : ( */}
      <div className="back-option" onClick={backToPreviousPage}>
        <span />
        {/* <Link to={getCalendarPath()}> */}
        <i className="fa fa-times" aria-hidden="true" />
        {/* </Link> */}
      </div>
      {/* )}   */}
      <div className="main-titles-mob">
        {parID ? (
          <h3>
            Subscribers for
            {' '}
            {`${prUser?.firstname}  ${prUser?.lastname}`} 
          </h3>
        ) : <h3>Users</h3>}
        
        <div className="client-option service-edit-icon">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <img src={showmore} alt="show" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div
                className="action"
                onClick={() => handleDropwDown(parID ? 'Current Subscriber Users' : 'Current Users', null)}
              >
                <span>{parID ? 'Current Subscriber Users' : 'Current Users'}</span>
              </div>
              <div className="action" onClick={handleFormModal}>
                 
                <span>  
                  {' '}
                  {parID ? 'Add Subscriber User' : 'Add User'}
                </span>
              </div>
              <div
                className="action"
                onClick={() => handleDropwDown(parID ? 'Show Hidden Subscriber Users' : 'Show Hidden Users Only', false)}
              >
                <span>Show Hidden Users Only</span>
              </div>
              {/* <div className="action" onClick={handleDropwDown('Show All Users', true)}>
                  <span> Show All Users </span>
                </div> */}
              {/* <div className="action" onClick={HandleOpenCloseTable}>
                <span> Working Hours </span>
              </div> */}
              {/* <div className="action">
                <ExcelFile
                  filename="users-list"
                  element={(
                    <Button className="btn btn-dwnld">Download in Excel</Button>)}
                >
                  <ExcelSheet data={staffList} name="Test">
                    {(staffList) && filterColumns(staffList).map((col) => (
                      <ExcelColumn label={camelCase(col)} value={col} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
              </div> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
       
      </div>
      <div className="target-heading">
        <h6>{dropDownOption}</h6>
      </div>

      <div id="table-scroll" className="staff-table table-scroll">
        <div className="mob-table">
          {staffList ? (
            <>
              {staffList.length === 0 ? (
                <div className="placehoder-text">No Users Found</div>
              ) : (
                <>
                  {staffList.map((staff, i) => {
                    let jsxData = null;
                    if (
                      showHide === true
                      || (showHide === false && staff.hide === true)
                      || (showHide === null && staff.hide === false)
                    ) {
                      jsxData = renderStaff(staff, i);
                    }
                    return jsxData;
                  })}
                </>
              )}
            </>
          ) : null}
        </div>
        {deletePopUp ? (
          <DeletePopUp
            handlePopUpChange={handlePopUpChange}
            paraText={paraText}
            PopUpText={PopUpText}
          />
        ) : null}
      </div>
    </>
  );
}

StaffTable.propTypes = {
  staffList: PropTypes.func.isRequired,
  HandleOpenCloseTable: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  handleEditStaff: PropTypes.func.isRequired,
  removeStaff: PropTypes.func.isRequired,
  showHide: PropTypes.func.isRequired,
  setShowHide: PropTypes.func.isRequired,
  handleManageVisible: PropTypes.func.isRequired,
  searchTxt: PropTypes.func.isRequired,
  searchStaff: PropTypes.func.isRequired,
  resendPassword: PropTypes.func.isRequired,
  prUser: PropTypes.func.isRequired,
};

export default StaffTable;
