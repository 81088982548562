import React from 'react';
import Carousel from 'react-multi-carousel';
import PropTypes from 'prop-types';
import 'react-multi-carousel/lib/styles.css';
import calendaricon from '../../assets/home/week-logo.png';
import daysicon from '../../assets/home/days-logo.png';
import min1 from '../../assets/home/min1.png';
import min2 from '../../assets/home/min2.png';
import min3 from '../../assets/home/min3.png';
import day1 from '../../assets/home/day1.png';
import day3 from '../../assets/home/days3.png';
import weekdays from '../../assets/home/day-logo.png';

const CalendarOption = ({
  setCalendarColor, handleCustomeView, filterType, color, filterTime, secondcolor, handleTimeSlot,
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <div className="cal-options">
      <Carousel responsive={responsive} arrows={false} showDots>
        <div className="display-view">
          <h5>Display View</h5>
          <div className="display-section">
            <div className="total-option">
              <div className={`calendar-time ${filterType === 'timeGridDay' ? 'active' : ''}`} onClick={() => handleCustomeView('timeGridDay', 'timeGridDay')}>
                <div className="day-option">
                  <img src={day1} alt="icon" />
                </div>
                <p>Day</p>
              </div>
            </div>
            <div className="total-option">
              <div className={`calendar-time ${filterType === 'timeGridThreeDay' ? 'active' : ''}`} onClick={() => handleCustomeView('timeGridThreeDay', 'timeGridThreeDay')}>
                <div className="day-option">
                  <img src={day3} alt="icon" />
                </div>
                <p>3 Days</p>
              </div>
            </div>
            <div className="total-option ">
              <div className={`calendar-time ${filterType === 'timeGridWeek' ? 'active' : ''}`} onClick={() => handleCustomeView('timeGridWeek', 'timeGridWeek')}>
                <div className="day-option">
                  <img src={weekdays} alt="icon" />
                </div>
                <p>1 Week</p>
              </div>
            </div>
          </div>
        </div>

        <div className="apntment-colors-view">
          <h5>Appointment Times</h5>
          <div className="apt-section">
            <div className="total-option appnt-colors">
              <div className={`calendar-time ${filterTime === '15min' ? 'active' : ''}`} onClick={() => handleTimeSlot('15min', '00:15', '00:05:00')}>
                <div className="day-option">
                  <img src={min1} alt="icon" />
                </div>
                <p>15 Min</p>
              </div>
            </div>
            <div className="total-option appnt-colors">
              <div className={`calendar-time ${filterTime === '30min' ? 'active' : ''}`} onClick={() => handleTimeSlot('30min', '00:30', '00:10:00')}>
                <div className="day-option">
                  <img src={min2} alt="icon" />
                </div>
                <p>30 Min</p>
              </div>
            </div>
            <div className="total-option appnt-colors">
              <div className={`calendar-time ${filterTime === '1hour' ? 'active' : ''}`} onClick={() => handleTimeSlot('1hour', '01:00', '00:15:00')}>
                <div className="day-option">
                  <img src={min3} alt="icon" />
                </div>
                <p>1 Hour</p>
              </div>
            </div>
          </div>
        </div>
        <div className="apntment-colors-view">
          <h5>Appointment Colors</h5>
          <div className="apt-section">
            <div className="total-option appnt-colors">
              <div className={`calendar-time ${color === '#e2ca8e' ? 'active' : ''}`} onClick={() => setCalendarColor('#e2ca8e', '#f3ead5')}>
                <div className="day-option">
                  <div className="gold-color" />
                </div>
                <p>Gold</p>
              </div>
            </div>
            <div className="total-option appnt-colors">
              <div className={`calendar-time ${color === '#7ddf7d' ? 'active' : ''}`} onClick={() => setCalendarColor('#7ddf7d', '#dfe8d4')}>
                <div className="day-option">
                  <div className="green-color" />
                </div>
                <p>Green</p>
              </div>
            </div>
            <div className="total-option appnt-colors">
              <div className={`calendar-time ${color === '#97baff' ? 'active' : ''}`} onClick={() => setCalendarColor('#97baff', '#e5ecff')}>
                <div className="day-option">
                  <div className="blue-color" />
                </div>
                <p>Blue  </p>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>

  );
};
CalendarOption.propTypes = {
  setCalendarColor: PropTypes.func.isRequired,
  handleCustomeView: PropTypes.func.isRequired,
  filterType: PropTypes.func.isRequired,
  color: PropTypes.func.isRequired,
  filterTime: PropTypes.func.isRequired,
  secondcolor: PropTypes.func.isRequired,
  handleTimeSlot: PropTypes.func.isRequired,
};
export default CalendarOption;
