/* eslint-disable import/no-unresolved */
import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const EndDateModal = (props) => {
  const { showEndDate, setShowEndDate, handleShiftChange } = props;
  return (
    <Modal
      size="md"
      show={showEndDate}
      onHide={() => setShowEndDate(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="startdate-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Calendar onChange={(e) => handleShiftChange({ name: 'endRepeat', data: e })} />
      </Modal.Body>
    </Modal>
  );
};
EndDateModal.propTypes = {
  showEndDate: PropTypes.func.isRequired,
  setShowEndDate: PropTypes.func.isRequired,
  handleShiftChange: PropTypes.func.isRequired,
};
export default EndDateModal;
