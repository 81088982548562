import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import { onKeyDownNumberWithDecimcal } from '../../functions';

function AddProduct({
  show, handleClose, poolSetUpAllChemicals, foreCastPeriod, itemVolume, handleSelectNames, productDetails, handleItemVolume, handleOrderQuantityChange, handleAddPredictiveChemical, handleCloseProductForm,
}) {
  const { type, renderName } = productDetails || {};
  // console.log({ itemVolume });
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      top
      centered
      className="staff-modal addClient-modal add-predictive-product"
    >
      <div className="staff-close">
        <i className="fa fa-times" onClick={handleClose} aria-hidden="true" />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {type === 'edit' ? 'Edit' : 'Add'}
          {' '}
          Additional Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          <Form>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicCompanyType" className="italic-text">
                  <Form.Label>
                    Product Name
                  </Form.Label>
                  <div className={`select-box ${type === 'edit' ? 'removeSelectAfter' : ''}`}>
                    {/* <i className="fas fa-sort-down" /> */}
                    <Form.Control
                      as="select"
                      name="customerType"
                      onChange={(e) => handleSelectNames(e)}
                      value={productDetails?.renderName}
                      disabled={type === 'edit'}
                    >
                      <option value="">{type === 'edit' ? renderName : 'Select'}</option>
                      {poolSetUpAllChemicals?.length > 0 && poolSetUpAllChemicals.map((el) => <option key={el.renderName} value={el.renderName}>{el.renderName}</option>)}
                    </Form.Control>
                  </div>
                </Form.Group>

              </div>

              <div className="col-md-6 col-6 half">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Order Quantity</Form.Label>

                  <Form.Control
                    type="text"
                    name="orderQuantity"
                    value={productDetails?.orderQuantity}
                    onChange={(e) => handleOrderQuantityChange(e)}
                    onKeyDown={onKeyDownNumberWithDecimcal}
                    maxLength={8}
                  />

                </Form.Group>
              </div>

              <div className="col-md-6 col-6 halfright">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Item Volume</Form.Label>
                  <div className="select-box">
                    {/* <i className="fas fa-sort-down" /> */}
                    <Form.Control
                      as="select"
                      name="invoiceSendType"
                      onChange={(e) => handleItemVolume(e)}
                      value={productDetails?.itemVolume}
                    >
                      <option value="">Select</option>
                      {itemVolume?.length > 0 && itemVolume.map((el) => (
                        <option value={el.volume}>
                          {el.renderVolume}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6 col-6 half">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>
                    {`Unit Price ${!productDetails.prodUnit ? '' : `(${productDetails.prodUnit} ${productDetails.unitType === 'per bag' ? 'bag' : ''})`}`}

                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="postcode"
                    value={productDetails?.selectedItemCost ? Number(productDetails?.selectedItemCost).toFixed(2) : productDetails?.selectedItemCost}
                    disabled
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 col-6 halfright">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Total Cost</Form.Label>
                  <Form.Control
                    type="text"
                    name="postcode"
                    value={productDetails?.overAllCost}
                    disabled
                  />
                </Form.Group>
              </div>

              <div className="col-md-12">
                <div className="view-btn">
                  <Button
                    type="button"
                    className="btn btn-edit"
                    onClick={handleCloseProductForm}
                  >
                    {' '}
                    Cancel
                    {' '}
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-save"
                    onClick={() => handleAddPredictiveChemical()}
                  >
                    {' '}
                    Save/Update
                    {' '}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
          {/* ))} */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
AddProduct.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  poolSetUpAllChemicals: PropTypes.arrayOf.isRequired,
  foreCastPeriod: PropTypes.arrayOf.isRequired,
  itemVolume: PropTypes.arrayOf.isRequired,
  handleSelectNames: PropTypes.func.isRequired,
  productDetails: PropTypes.objectOf.isRequired,
  handleItemVolume: PropTypes.func.isRequired,
  handleOrderQuantityChange: PropTypes.func.isRequired,
  handleCloseProductForm: PropTypes.func.isRequired,
  handleAddPredictiveChemical: PropTypes.func.isRequired,
};

export default AddProduct;
